import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ncoa-leadership-grid',
  templateUrl: './leadership-grid.component.html',
  styleUrls: ['./leadership-grid.component.scss'],
})
export class LeadershipGridComponent implements OnInit {
  @Input() heading: string;
  @Input() members: [
    {
      url: string;
      image: string;
      name: string;
      description: string;
    }
  ];
  constructor() {}

  ngOnInit(): void {}
}
