<ncoa-template-default>
    <div class="sponsors-page" *ngIf="isLoaded">
        <div class="header">
            <div class="text">
                <span class="title">
                    {{content.title}}
                </span>
                <span class="description">
                    {{content.heroDescription}}
                </span>
                <a *ngIf="content.heroCTALink" [href]="content.heroCTALink" target="_blank">
                    <span class="cta">
                        {{content.heroCTA}}
                    </span>
                </a>

            </div>
            <div class="image">
                <span
                    [ngStyle]="{'background-image': 'url(' + content.heroImage.url + ')', 'background-size':'cover', 'background-position':'center'}">
                </span>
            </div>

        </div>

        <div *ngIf="content.platinumSponsors.length > 0" class="sponsors">
            <span class="section-header">
                <span>{{content.platinumLabel}}</span>
            </span>
            <div class="sponsor-list platinum-list">
                <ng-container *ngFor="let sponsor of content.platinumSponsors">
                    <a href="{{sponsor.link}}" target="_blank">
                        <div class="platinum">
                            <img src="{{sponsor.logo.url}}" alt="{{sponsor.logo.description}}">
                        </div>
                    </a>
                </ng-container>
            </div>
        </div>

        <div *ngIf="content.silverSponsors.length > 0" class="sponsors">
            <span class="section-header">
                <span>{{content.silverLabel}} </span>
            </span>
            <div class="sponsor-list ">
                <ng-container *ngFor="let sponsor of content.silverSponsors">
                    <a class="clickable" href="{{sponsor.link}}" target="_blank">
                        <div class="silver">
                            <img src="{{sponsor.logo.url}}" alt="{{sponsor.logo.description}}">
                        </div>
                    </a>
                </ng-container>
            </div>
        </div>

        <div *ngIf="content.bronzeSponsors.length > 0" class="sponsors">
            <span class="section-header">
                <span>{{content.bronzeLabel}} </span>
            </span>
            <div class="sponsor-list bronze-list">
                <ng-container *ngFor="let sponsor of content.bronzeSponsors">
                    <a class="clickable" href="{{sponsor.link}}" target="_blank">
                        <div class="bronze">
                            <img src="{{sponsor.logo.url}}" alt="{{sponsor.logo.description}}">
                        </div>
                    </a>
                </ng-container>
            </div>
        </div>

        <div *ngIf="content.friends.length > 0" class="friends">
            <span class="section-header">
                <span>{{content.friendsLabel}} </span>
            </span>
            <ng-container *ngFor="let sponsor of content.friends">
                <div class="friend">
                    <a href="{{sponsor.link}}" target="_blank">
                        {{sponsor.name}}
                    </a>
                </div>
            </ng-container>
        </div>

    </div>

</ncoa-template-default>