import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ComponentService } from '@services/component.service';
import { StandardService } from '@services/standard.service';
import { ErrorService } from '@services/error.service';
import { GlobalService } from '@services/global.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';


@Component({
  selector: 'ncoa-planner-standard',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class StandardComponent implements OnInit {

  components: (any)[];
  componentTypes: any;
  minimalPage: boolean = false;
  thanks: boolean = false;
  sponsors: boolean = false;
  smallHeader: boolean = false;
  partnerGrid: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private standardService: StandardService,
    private componentService: ComponentService,
    private router: Router,
    private errorService: ErrorService,
    private globalService: GlobalService,
    private toolsConfig: ToolsPrimaryConfigService,
  ) {
    this.componentTypes = this.componentService.componentTypes;
  }

  ngOnInit() {
    const path = window.location.pathname.split('/');
  

    if (path.indexOf('pages') > -1) {
      const routeIndex = path.indexOf('pages') + 1;
      const slug = window.location.pathname.split('/')[routeIndex];

      if ( slug === 'thanks' || slug === 'thank-you') {
        this.thanks = true;
      } 

      if(slug){
        let _pageData = this.standardService.getToolsPage( slug );
        _pageData.then( (data: any) => {
          this.usePageData( data );
          if(this.components.some(component => component.componentType === "sponsors")){
            this.sponsors = true;
          }
          if (data.item?.page_variation?.value?.find( v => v.codename === 'small_header' )) {
            this.smallHeader = true;
          }
          if (data?.item?.partner_grid?.value?.length > 0) {
            this.partnerGrid = this.toolsConfig.currentConfig.core.partnerGrids.find((grid) => { return data.item.partner_grid.value.includes(grid.system.codename) });
          }
        });
      } else {
        this.errorService.notFound();
      }
    }
  }

  usePageData(data) {
    if ( data ) {
      this.components = [
        {componentType: 'body', ...data.body},
        ...data.components
      ];

      // Prepend header for non-minimal page
      if ( !data.minimalPage ) {
        this.components.unshift({
          componentType: 'header',
          ...data.item
        });
      }

      this.minimalPage = data.minimalPage;
    }
  }
}