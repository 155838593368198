import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ncoa-bec-finder',
  templateUrl: './bec-finder.component.html',
  styleUrls: ['./bec-finder.component.scss']
})
export class BecFinderComponent implements OnInit {
  @Input() heading: string;
  @Input() intro: string;
  @Input() cta: {
    label: string,
    url: string
  };
  @Input() subheading: string;
  @Input() contact: string;
  @Input() address: string;
  @Input() website: string;
  @Input() photo: {
    sizes: [
      {
        media: string,
        src: string,
      },
    ],
    src: string,
    alt: string,
  };

  constructor() { }

  ngOnInit(): void {
  }

}
