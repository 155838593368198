<div class="sign-in-reminder" id="sign-in-reminder">
  <h3 class="text">{{ signInReminder }}</h3>
  <a [href]="signInUrl" title="Log In" class="sign-in-cta">Log In</a>
  <button type="button" class="close-btn" (click)="dismissReminder()">
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.8921 3.32357C21.504 2.71172 21.504 1.71971 20.8921 1.10786C20.2803 0.496005 19.2883 0.496004 18.6764 1.10786L11 8.78429L3.32357 1.10786C2.71172 0.496005 1.71971 0.496005 1.10786 1.10786C0.496005 1.71971 0.496004 2.71172 1.10786 3.32357L8.78429 11L1.10786 18.6764C0.496005 19.2883 0.496004 20.2803 1.10786 20.8921C1.71971 21.504 2.71172 21.504 3.32357 20.8921L11 13.2157L18.6764 20.8921C19.2883 21.504 20.2803 21.504 20.8921 20.8921C21.504 20.2803 21.504 19.2883 20.8921 18.6764L13.2157 11L20.8921 3.32357Z" fill="#0B4A5D"/>
    </svg>
    Close
  </button>
</div>
<ng-content></ng-content>
<ncoa-cookies></ncoa-cookies>
