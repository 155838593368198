<header class="vertical-landing-header">
  <div class="vertical-landing-header__wrapper">
    <div class="vertical-landing-header__background">
      <picture>
        <source *ngFor="let source of image.sizes" [src]="source.url" [media]="source.media" />
        <img [src]="image.src" [alt]="image.alt" />
      </picture>
    </div>
    <div [ngClass]="{'has-padding': !planSteps?.length}" class="vertical-landing-header__main {{ assessmentCTA ? 'has-assessment' : '' }}">
      <h1 class="title">{{ title }}</h1>
      <p class="desc">{{ desc }}</p>

      <ng-container *ngIf="planSteps?.length">

        <div class="plan-steps-infographic">

          <div class="plan-step" [ngClass]="{'step-reverse': (i + 1) % 2 === 0}" *ngFor="let step of planSteps; let i = index">


            <div class="step-image">
              <img [src]="step.infographic.url" [alt]="step.infographic.alt">
            </div>

            <div class="step-info">
              <div class="step-number">
                <span>{{i + 1}}</span>
              </div>
              <h3>{{step.title}}</h3>
            </div>

          </div>
          
        </div>



      </ng-container>

    </div>
  </div>
  <ul class="vertical-landing-header__menu">
    <li class="item {{ item.isActive ? 'is-active' : '' }}" *ngFor="let item of menu">
      <a [href]="item.url" [title]="item.text"  (click) = 'trackAnalytics($event)'>{{ item.text }}</a>
    </li>
  </ul>
</header>
