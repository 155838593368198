import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'ncoa-save-content',
  templateUrl: './save-content.component.html',
  styleUrls: ['./save-content.component.scss']
})
export class SaveContentComponent implements OnInit {

  @Input() heading: string;
  @Input() intro: string;
  @Input() create: { label: string, url: string };
  @Input() login: { label: string, url: string };

  constructor() { }

  ngOnInit(): void {
  }

}
