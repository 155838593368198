<div class="author-byline-planner">
  <div class="author-byline-planner__wrapper">
    <div class="authors-container">
      <div class="authors-container__author" *ngFor='let author of authors'>
        <div class="img-container">
          <img [src]='author.imgUrl' onerror='this.onerror=null;this.src="../../../assets/images/profile-placeholder.svg"'  class="img-container__author-image" alt='Author Image' />
        </div>
        <div class="info-container">
          <a *ngIf="author.link" [href]="author.link" [title]="author.name" class="info-container__name">{{author.name}}</a>
          <h3 *ngIf="!author?.link" class="info-container__name">{{author.name}}</h3>
          <p class="info-container__position">{{author.position}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
