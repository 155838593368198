import { Injectable } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { GlobalService } from '@services/global.service';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { SeoService } from '@services/seo.service';
import { take } from 'rxjs/operators';
import { AssessmentService } from './assessment.service';
import { CustomerActivityService } from './customer-activity.service';
import { ToolsPrimaryConfigService } from './tools-primary-config.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class VerticalService {
  constructor(
    private toolsConfig: ToolsPrimaryConfigService,
    private globalService: GlobalService,
    private cas: CustomerActivityService,
    private kontent: KontentDeliveryService,
    private utility: UtilityService,
    private assessment: AssessmentService,
    private dataLayerService: DataLayerService,
    private seoService: SeoService,
  ) { }

  async getData(contentType) {
    let data = {};

    const codename = this.toolsConfig.currentConfig.core.route.codename;

    let elements = null;
    let headerTitle = null;
    let subHeader = null;
    let todoList = null;
    let planType = null;
    let planDeletion = null;

    if (contentType === 'vertical' || contentType === 'vertical-benefits') {
      elements = this.toolsConfig.currentConfig.core.verticalData.find((page) => page.system.codename === codename).elements;
      headerTitle = elements.hero_title.value || elements.title.value;
      subHeader = elements.description.value;
    } else if (contentType === 'vertical-topic') {
      elements = this.toolsConfig.currentConfig.core.verticalTopicData.find((page) => page.system.codename === codename).elements;
      headerTitle = this.toolsConfig.currentConfig.core.route.parentVerticalName;
      subHeader = this.toolsConfig.currentConfig.core.route.parentVerticalDescription;
    }else if (contentType === 'vertical-plan'){
      planType = codename
      elements = this.toolsConfig.modular_content[codename].elements;
      const rawTodoList = this.toolsConfig.currentConfig.core.todoList.elements;
      todoList = Object.keys(rawTodoList).reduce((accum, key) => Object.assign(accum, {[key]: rawTodoList[key].value}), {});
      headerTitle = elements.title.value;
      subHeader = elements.description.value;
      data['savePlanCTA'] = elements?.awp_topic_save_cta?.value ?? 'Save My Plan';
      data['startOverCTA'] = elements?.awp_topic_start_over_cta?.value ?? 'Start Over';
      data['editPlanCTA'] = elements?.awp_topic_edit_answers_cta?.value ?? 'Edit My Answers';
      data['bannerText'] = elements?.banner_text?.value ?? 'Want to save your results? Create an account';
      data['bannerCta'] = elements?.banner_cta?.value ?? 'Sign Up';
      data['bannerSubtext'] = elements?.banner_subtext ?? null; // rich text, pass entire object to be resolved later
      data['logoutCTA'] = elements?.log_out?.value ?? '';

      planDeletion = {
        plan_delete_button_text: elements.plan_delete_button_text.value,
        plan_deletion_disclaimer_part_one: elements.plan_deletion_disclaimer_part_one.value,
        plan_deletion_disclaimer_part_two: elements.plan_deletion_disclaimer_part_two.value
      }

      todoList["view_answers"] = elements.view_answers.value;
    }



    this.dataLayerService.push({
      clickUrl: location.href,
      pageTitle: document.title || '',
      contentType: contentType,
      topic: this.toolsConfig.currentConfig.core.route.parentTopic || '',
      vertical: codename,
      pageType: 'vertical-page',
    },false);

    const title = `${headerTitle}`;
    const desc = subHeader;
    const image = {
      sizes: this.globalService.remodelImageSrc(elements.hero_image.value[0].url),
      src: elements.hero_image.value[0].url,
      alt: elements.hero_image.value[0].description || title,
    };

    let assessmentCTA =  {
      url: this.toolsConfig.currentConfig.core.primaryAssessment.url,
      text: this.toolsConfig.currentConfig.core.primaryAssessment.text,
    };
    const activity = await this.cas.getActivity().first().toPromise();

    if (activity && activity.basic && activity.basic.completedAssessments.length > 0) {
      const generalAssessment = this.toolsConfig.currentConfig.core.primaryAssessment.assessmentID;
      const completed = activity.basic.completedAssessments.find((key) => key === generalAssessment);

      if (completed) {
        assessmentCTA = null;
      }
    }

    if (contentType === 'vertical-benefits') {
      assessmentCTA = null;
    }

    const menu = [];
    let parentRoute = null;
    if (contentType === 'vertical') {
      parentRoute = this.toolsConfig.currentConfig.core.route.route;
      menu.push({
        url: parentRoute,
        text: 'Recommended',
        isActive: window.location.pathname === parentRoute,
      });

      this.toolsConfig.routes
        .filter((route) => (
          route.parentVertical &&
          route.type &&
          route.parentVertical === codename &&
          route.type === 'vertical-topic'
        ))
        .forEach((route) => {
          menu.push({
            url: route.route,
            text: route.text,
            isActive: window.location.pathname === route.route,
          });
        });


      const benefitRoute = this.toolsConfig.routes.find((route) => (
        route.type === 'vertical-benefits' &&
        route.taxonomy.codename === this.toolsConfig.currentConfig.core.route.taxonomy.codename
      ));

      if (benefitRoute) {
        menu.push({
          url: benefitRoute.route,
          text: benefitRoute.navigationName || title + ' Benefits',
          isActive: window.location.pathname === benefitRoute.route,
        });
      }

    } else if (contentType === 'vertical-topic') {
      const parentVertical = this.toolsConfig.routes
        .filter((route) => route.type && route.type === 'vertical-topic')
        .find((route) => route.codename && route.codename === codename)
        .parentVertical;

      parentRoute = this.toolsConfig.routes
        .filter((route) => route.type && route.type === 'vertical')
        .find((route) => route.codename === parentVertical);

      menu.push({
        url: parentRoute.route,
        text: 'Recommended',
        isActive: window.location.pathname === parentRoute.route,
      });

      this.toolsConfig.routes
        .filter((route) => (
          route.parentVertical &&
          route.type &&
          route.parentVertical === parentVertical &&
          route.type === 'vertical-topic'
        ))
        .forEach((route) => {
          menu.push({
            url: route.route,
            text: route.text,
            isActive: window.location.pathname === route.route,
          });
        });

      const benefitRoute = this.toolsConfig.routes.find((route) => (
        route.type === 'vertical-benefits' &&
        route.taxonomy.codename === parentRoute.taxonomy.codename
      ));

      if (benefitRoute) {
        menu.push({
          url: benefitRoute.route,
          text: benefitRoute.navigationName || title + ' Benefits',
          isActive: window.location.pathname === benefitRoute.route,
        });
      }
    } else if (contentType === 'vertical-benefits') {
      const parentVertical = this.toolsConfig.routes
        .filter((route) => route.type && route.type === 'vertical')
        .find((route) => route.codename && route.codename === codename);

      parentRoute = parentVertical.route;

      menu.push({
        url: parentVertical.route,
        text: 'Recommended',
        isActive: window.location.pathname === parentVertical.route,
      });


      this.toolsConfig.routes
        .filter((route) => route.parentVertical && route.type && route.type === 'vertical-topic' && route.parentVertical === parentVertical.codename)
        .forEach((route) => {
          menu.push({
            url: route.route,
            text: route.text,
            isActive: window.location.pathname === route.route,
          });
        });

      const benefitRoute = this.toolsConfig.routes.find((route) => (
        route.type === 'vertical-benefits' &&
        route.taxonomy.codename === parentVertical.taxonomy.codename
      ));

      if (benefitRoute) {
        menu.push({
          url: benefitRoute.route,
          text: benefitRoute.navigationName || title + ' Benefits',
          isActive: window.location.pathname === benefitRoute.route,
        });
      }
    }

    data['ncoaVerticalLandingHeader'] = {
      title,
      desc,
      image,
      assessmentCTA,
      menu: menu.length > 1 ? menu : [],
    }
    data['assessmentIsCompleted'] = false;

    if(elements.hide_sub_menu && elements.hide_sub_menu.value.length > 0 && elements.hide_sub_menu.value[0].codename === 'true'){
      data['ncoaVerticalLandingHeader'].menu = [];
    }

    if (elements.plan_steps){
      data['ncoaVerticalLandingHeader']['planSteps'] = elements.plan_steps.value.map((step) => {

        const elements = this.toolsConfig.modular_content[step].elements

        return {
          title: elements.title.value,
          infographic: {
            url: elements.infographic.value[0].url,
            alt: elements.infographic.value[0].description
          }
        }
      })
    }

    // vertical assessment
    if(elements.associated_tool?.value.length > 0){
      
      let toolCodeName = elements.associated_tool?.value[0];
      let toolData = this.toolsConfig.modular_content[toolCodeName];
      data['assessment'] = {
        submitText: elements.assessment_submit_text.value,
        assessmentCode:  toolData.elements.screening_code.value ?? toolData.elements.assessment_id.value,
        viewPlanText: toolData.elements.view_plan_text.value,
        bannerTextObject: toolData.elements?.sign_up_banner_text,
        // bannerCTA: toolData.elements?.banner_cta?.value,
        viewPlanCTA: toolData.elements.view_plan_cta.value,
        viewAssessmentText: toolData.elements.view_assessment_text.value,
        viewAssessmentCTA: toolData.elements.view_assessment_cta.value,
        viewAssessmentLink: this.toolsConfig.routes.find((route) => (
          route.type === 'vertical' && route.codename === codename))?.route,
         viewPlanLink: this.toolsConfig.routes.find((route) => (
          route.type === 'vertical-plan' && route.parentVertical === codename
        ))?.route
      }
      if (data['assessment']?.assessmentCode && activity.basic.completedScreenings.includes(data['assessment']?.assessmentCode)) {
        data['assessmentIsCompleted'] = true;
      }
    }

    if (contentType === 'vertical-benefits') {
      data['benefitsListing'] = {
        type: 'plain',
        header: {
          title: elements.benefits_title.value,
          eyebrow: elements.benefits_assessment.value.length > 0 ? elements.benefits_assessment_eyebrow.value : null,
          cta: elements.benefits_assessment.value.length > 0 ? {
            url: (this.toolsConfig.routes.find((route) => (
              route.type &&
              route.codename &&
              route.type === 'assessment' &&
              route.codename === elements.benefits_assessment.value[0])) || { route: null }).route,
            text: elements.benefits_assessment_link_text.value,
          } : null,
        },
        subHeaderCTA: {
          url: (this.toolsConfig.routes.find((route) => (
            route.type &&
            route.type === 'all-benefits')) || { route: null }).route,
          text: 'Explore All Benefits',
        },
        selectedCategory: elements.benefits_super_category.value,
      };
    }

    if (contentType === 'vertical-topic') {

      // topic listing
      const topicListing = this.getTopicListing(codename, elements, 'subtopics', 'subtopics_heading');

      if (topicListing.topics.length > 0) {
        data['ncoaTopicListing'] = topicListing;
      }

      if (elements.multi_card_3_col_grid.value.length > 0) {
        data = {
          ...data,
          ...await this.getRecommendedContent(elements, 'vertical-topic')
        };
      }

      if(elements?.feature_content_blocks?.value?.length > 0){
        data = {
          ...data,
          ...await this.getFeatureBlockContent(elements, 'vertical-topic')
        }
      }

      // vertical path
      const taxonomies = elements[this.toolsConfig.toolTaxonomyCodename].value;
      const verticals = taxonomies.map((taxonomy) => this.toolsConfig.getVertical(taxonomy.codename));
      const vertical = this.utility.arrayMode(verticals);
      const otherVerticalRoutes = this.toolsConfig.routes.filter((route) => (
        route.type === 'vertical' &&
        route.taxonomy.codename !== vertical.codename
      ));

      let verticalPaths = new Array(otherVerticalRoutes.length);
      otherVerticalRoutes.forEach((route) => {
        const index = this.toolsConfig.awpTaxonomyCatalog.findIndex((taxonomy) => (taxonomy.codename === route.taxonomy.codename));
        verticalPaths[index] = route;
      });
      verticalPaths = verticalPaths.filter((path) => path);

      const paths = [];

      verticalPaths.forEach((route) => {
        const links = [{ text: 'Explore More', url: route.route }];
        const benefitRoute = this.toolsConfig.routes.find((bRoute) => (
          bRoute.type === 'vertical-benefits' &&
          bRoute.codename === route.codename
        ));

        if (benefitRoute) {
          links.push({
            text: 'Find Benefits',
            url: benefitRoute.route,
          });
        }

        paths.push({
          title: route.text,
          links,
        });
      });

      paths.push({
        title: 'Explore All Benefits',
        links: [{
          text: 'Find Benefits',
          url: (this.toolsConfig.routes.find((route) => route.type === 'all-benefits') || { route: null }).route,
        }],
      });

      paths.push({
        title: 'My Planner',
        links: [{
          text: 'Go to My Planner',
          url: (this.toolsConfig.routes.find((route) => route.type === 'home') || { route: null }).route,
        }, {
          text: 'My Saved Items',
          url: (this.toolsConfig.routes.find((route) => route.type === 'saved-contents') || { route: null }).route,
        }],
      });

      data['verticalPathing'] = {
        heading: `Explore the ${this.toolsConfig.rawConfig.elements.tool_title.value}`,
        items: paths,
      };
    } else if (contentType === 'vertical') {
      const taxonomies = elements[this.toolsConfig.toolTaxonomyCodename].value;
      const verticals = taxonomies.map((taxonomy) => this.toolsConfig.getVertical(taxonomy.codename));
      const vertical = this.utility.arrayMode(verticals);

      if (elements.recommended_subtopics.value.length > 0) {
        // subtopic listing
        const subTopics = [];
        const recommendedSubTopicKeys = elements.recommended_subtopics.value;

        recommendedSubTopicKeys.forEach((key) => {
          const route = this.toolsConfig.routes.find((route) => (
            route.codename === key &&
            route.parentVertical === codename
          ));
          const data = this.toolsConfig.modular_content[key];

          if (route && data) {
            const parentTopic = this.toolsConfig.routes.find((topicRoute) => (
              topicRoute.type === 'vertical-topic' &&
              topicRoute.codename === route.parentTopic
            ));

            const parentVertical = this.toolsConfig.routes.find((verticalRoute) => (
              verticalRoute.type === 'vertical' &&
              verticalRoute.codename === codename
            ));

            if (parentTopic && parentVertical) {
              const { system, elements } = data;
              const flag = (this.toolsConfig.customerActivity.basic?.SavedContent || []).find((content) => content.ContentId === system.id);

              subTopics.push({
                id: system.id,
                eyebrow: `${parentVertical.text} / ${parentTopic.text}`,
                isSaved: !!flag,
                title: elements.title.value,
                desc: elements.description.value,
                cta: {
                  url: route.route,
                  text: 'Learn More',
                },
                image: {
                  src: elements.hero_image.value[0].url,
                  alt: elements.hero_image.value[0].description,
                },
              });
            }
          }
        });

        data['ncoaTopicListing'] = {
          heading: `Important ${headerTitle} Considerations`,
          topics: subTopics,
        };
      }

      if (elements.multi_card_3_col_grid.value.length > 0) {
        data = {
          ...data,
          ...await this.getRecommendedContent(elements, 'vertical')
        };
      }

      if(elements?.feature_content_blocks?.value?.length > 0){
        data = {
          ...data,
          ...await this.getFeatureBlockContent(elements, 'vertical')
        }
      }

      // vertical path
      const otherVerticalRoutes = this.toolsConfig.routes.filter((route) => (
        route.type === 'vertical' &&
        route.taxonomy.codename !== vertical.codename
      ));

      let verticalPaths = new Array(otherVerticalRoutes.length);
      otherVerticalRoutes.forEach((route) => {
        const index = this.toolsConfig.awpTaxonomyCatalog.findIndex((taxonomy) => (taxonomy.codename === route.taxonomy.codename));
        verticalPaths[index] = route;
      });
      verticalPaths = verticalPaths.filter((path) => path);

      const paths = [];

      verticalPaths.forEach((route) => {
        const links = [{ text: 'Explore More', url: route.route }];
        const benefitRoute = this.toolsConfig.routes.find((bRoute) => (
          bRoute.type === 'vertical-benefits' &&
          bRoute.codename === route.codename
        ));

        if (benefitRoute) {
          links.push({
            text: 'Find Benefits',
            url: benefitRoute.route,
          });
        }

        paths.push({
          title: route.text,
          links,
        });
      });

      paths.push({
        title: 'Explore All Benefits',
        links: [{
          text: 'Find Benefits',
          url: (this.toolsConfig.routes.find((route) => route.type === 'all-benefits') || { route: null }).route,
        }],
      });

      paths.push({
        title: 'My Planner',
        links: [{
          text: 'Go to My Planner',
          url: (this.toolsConfig.routes.find((route) => route.type === 'home') || { route: null }).route,
        }, {
          text: 'My Saved Items',
          url: (this.toolsConfig.routes.find((route) => route.type === 'saved-contents') || { route: null }).route,
        }],
      });

      data['verticalPathing'] = {
        heading: `Explore the ${this.toolsConfig.rawConfig.elements.tool_title.value}`,
        items: paths,
      };
    } else if (contentType === 'vertical-plan'){
      data['todoList'] = todoList;
      data['planType'] = planType;
      data['planDeletion'] = planDeletion
    }

    // seo metadata
    const SEOPayload = {
      metaTitle: elements.seo_metadata_example_to_include_in_any_type__meta_title.value,
      metaDesc: elements.seo_metadata_example_to_include_in_any_type__meta_description.value,
      ogSiteName: elements.seo_metadata_example_to_include_in_any_type__facebook_site_name.value,
      ogTitle: elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value,
      ogDesc: elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value,
      ogImage: elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value.length > 0
        ? elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value[0].url
        : null,
      twitterSiteName: elements.seo_metadata_example_to_include_in_any_type__twitter_site_name.value,
      twitterTitle: elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value,
      twitterDesc: elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value,
      twitterImage: elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value.length > 0
        ? elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value[0].url
        : null,
      pageType: 'page',
      fallbackImage: elements.hero_image.value.length > 0
        ? elements.hero_image.value[0].url
        : null,
      fallbackTitle: elements.title.value,
    };

    this.seoService.buildToolsSeoData(SEOPayload);

    return data;
  }

  async getRecommendedContent(elements, contentType) {
    let pageTaxonomies = [];
    if (contentType === 'vertical') {
      const currentRoute = this.toolsConfig.currentConfig.core.route;

      pageTaxonomies.push(currentRoute.taxonomy.codename);

      const topics = this.toolsConfig.routes.filter((route) => (
        route.type === 'vertical-topic' && route.parentVertical === currentRoute.codename
      )).map((route) => route.taxonomy.codename);

      pageTaxonomies = pageTaxonomies.concat(topics);

      const subTopics = this.toolsConfig.routes.filter((route) => (
        route.type === 'vertical-subtopic-learn' && route.parentVertical === currentRoute.codename
      )).map((route) => route.taxonomy.codename);

      pageTaxonomies = pageTaxonomies.concat(subTopics);
    } else {
      const currentRoute = this.toolsConfig.currentConfig.core.route;

      pageTaxonomies.push(currentRoute.taxonomy.codename);

      const subTopics = this.toolsConfig.routes.filter((route) => (
        route.type === 'vertical-subtopic-learn' && route.parentTopic === currentRoute.codename
      )).map((route) => route.taxonomy.codename);

      pageTaxonomies = pageTaxonomies.concat(subTopics);
    }

    const additionalData = {};

    // recommended 3 col grid
    const userProfileId = this.toolsConfig.rawConfig.elements.profile_assessment_id.value;
    const response = await this.assessment.getUserProfile(userProfileId)
      .pipe(take(1))
      .toPromise();

    if (elements.multi_card_3_col_grid.value.length > 0) {
      const linkedCardGrid = this.toolsConfig.modular_content[elements.multi_card_3_col_grid.value[0]];
      const heading = linkedCardGrid.elements.headline.value;
      const intro = linkedCardGrid.elements.subhead.value;
      const recommendedCards = [];
      const promoCards = [];

      const resArr = linkedCardGrid.elements.promo_cards.value.map((codename) => this.toolsConfig.modular_content[codename]);

      resArr.forEach((res) => {
        const background = {
          url: res.elements.background_image.value[0].url,
          alt: res.elements.background_image.value[0].description,
        };
        const eyebrow = res.elements.eyebrow.value;
        const heading = res.elements.headline.value;
        const cta = {
          label: res.elements.cta_text.value,
          url: res.elements.cta_manual_external_link.value,
        };

        if (res.elements.cta_internal_link.value.length > 0 &&
            this.toolsConfig.modular_content[res.elements.cta_internal_link.value[0]]) {
          const e = this.toolsConfig.modular_content[res.elements.cta_internal_link.value[0]].elements;
          const s = this.toolsConfig.modular_content[res.elements.cta_internal_link.value[0]].system;

          switch (s.type) {
            case 'ncoa_article_content': {
              cta.url = `${this.toolsConfig.rootURL}/resource/${e.url.value}`;
              break;
            }
            case 'awa_benefits_tool___vertical_topic': {
              cta.url = (this.toolsConfig.routes.find((route) => (
                route.type === 'vertical-topic' &&
                route.codename === s.codename
              )) || { route: null }).route;
              break;
            }
            case 'awa_benefits_tool___vertical': {
              cta.url = (this.toolsConfig.routes.find((route) => (
                route.type === 'vertical' &&
                route.codename === s.codename
              )) || { route: null }).route;
              break;
            }
            case 'standard_page': {
              cta.url = `${this.toolsConfig.rootURL}/pages/${e.url.value}`;
              break;
            }
            case 'awa_benefits_tool___vertical_subtopic': {
              cta.url = (this.toolsConfig.routes.find((route) => (
                route.type === 'vertical-subtopic-learn' &&
                route.codename === s.codename
              )) || { route: null }).route;
              break;
            }
          }
        }

        if (cta.url) {
          promoCards.push({
            type: 'promo',
            background,
            eyebrow,
            heading,
            cta,
          });
        }
      });

      const employmentStatus = this.toolsConfig.customerActivity?.finances?.employmentStatus;
      const areasTopOfMind = this.toolsConfig.customerActivity?.basic?.areasTopOfMind;
      let resourcesRelatedToRetirement = this.toolsConfig.customerActivity?.basic?.resourcesRelatedToRetirement;
          resourcesRelatedToRetirement = typeof resourcesRelatedToRetirement == 'string' ? [resourcesRelatedToRetirement] : resourcesRelatedToRetirement;
      const isCurrentlyReceivingBenefits = this.toolsConfig.customerActivity?.basic?.isCurrentlyReceivingBenefits;
      const financialConcerns = this.toolsConfig.customerActivity?.basic?.financialConcerns;
      const currentMedicarePlans = this.toolsConfig.customerActivity?.basic?.currentMedicarePlans;
      const currentlyEnrolledMedicare = response?.profile?.currentlyEnrolledMedicare;

      const assessment = (this.toolsConfig.customerActivity.basic?.completedAssessments || []).find((id) => (id === this.toolsConfig.currentConfig.core.primaryAssessment.assessmentID)) ? null : {
        eyebrow: this.toolsConfig.currentConfig.core.primaryAssessment.eyebrow,
        url: this.toolsConfig.currentConfig.core.primaryAssessment.url,
        text: this.toolsConfig.currentConfig.core.primaryAssessment.text,
      };

      const getManualCards = async () => {
        const resArr = linkedCardGrid.elements.cards.value.map((codename) => this.toolsConfig.modular_content[codename]);

        resArr.forEach((res) => {
          switch (res.system.type) {
            case 'ncoa_article_content': {
              // article
              if (res.elements[this.toolsConfig.toolTaxonomyCodename].value.length > 0) {
                const div = document.createElement('div');
                      div.innerHTML = res.elements.body_ckeditor.value;
                const text = div.innerText;

                const heading = res.elements.title.value;
                const intro = `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`;
                const url = `${this.toolsConfig.rootURL}/resource/${res.elements.url.value}`;

                const taxonomies = res.elements[this.toolsConfig.toolTaxonomyCodename].value.map((taxonomy) => taxonomy.codename);
                const verticals = taxonomies.map((taxonomy) => this.toolsConfig.getVertical(taxonomy).codename);
                let vertical = this.utility.arrayMode(verticals);
                    vertical = this.toolsConfig.awpTaxonomyCatalog.find((v) => v.codename === vertical);

                if (res.elements.awp_trusted_expert.value.length > 0) {
                  // trusted experts
                  recommendedCards.push({
                    id: res.system.id,
                    type: 'referral',
                    eyebrow: `${vertical.name} Expert`,
                    heading,
                    intro,
                    cta: {
                      label: 'View Details',
                      url,
                    },
                    photo: {
                      url: res.elements.expert_logo.value[0].url,
                      alt: res.elements.expert_logo.value[0].description,
                    },
                    bookmark: true,
                  });
                } else {
                  // resource
                  recommendedCards.push({
                    id: res.system.id,
                    type: 'article',
                    breadcrumb: `${vertical.name} / Resource`,
                    heading,
                    intro,
                    cta: {
                      label: 'Read Resource',
                      url,
                    },
                  });
                }
              }
              break;
            }
            case 'awa_benefits_tool___card__tool_': {
              // tool
              if (res.elements.tool.value.length > 0) {
                // const completedAssessments = localStorage.getItem('completedAssessments') ? JSON.parse(localStorage.getItem('completedAssessments')) : [];
                const completedAssessments = this.toolsConfig.customerActivity.basic?.completedAssessments || [];
                const { elements: e, system: s } = this.toolsConfig.modular_content[res.elements.tool.value[0]];

                const heading = res.elements.headline.value;
                const photo = {
                  url: res.elements.card_image.value[0].url,
                  alt: res.elements.card_image.value[0].description,
                };

                const cta = {
                  label: completedAssessments.find((assessment) => assessment === e.assessment_id.value) ? 'View Results' : 'Start Assessment',
                  url: (this.toolsConfig.routes.find((route) => (
                    route.assessmentID === e.assessment_id.value
                  )) || { route: null }).route,
                };

                switch (e.assessment_id.value) {
                  case 'generalAssessment':
                  case 'generalAssessmentAWP': {
                    recommendedCards.push({
                      id: s.id,
                      linkedID: s.id,
                      linkedCodename: s.codename,
                      type: 'tool',
                      heading,
                      cta,
                      photo,
                      variation: 'tools-retirement-single-col',
                    });
                    break;
                  }
                  default: {
                    recommendedCards.push({
                      id: s.id,
                      linkedID: s.id,
                      linkedCodename: s.codename,
                      type: 'tool',
                      heading,
                      cta,
                      photo,
                      variation: 'tools-medicare-single-col',
                      theme: 'dark',
                    });
                    break;
                  }
                }
              }
              break;
            }
            case 'awa_benefits_tool___vertical_subtopic': {
              // subtopic
              const subTopicRoute = this.toolsConfig.routes.find((route) => (
                route.type === 'vertical-subtopic-learn' &&
                route.codename === res.system.codename
              ));

              if (subTopicRoute) {
                const topicRoute = this.toolsConfig.routes.find((route) => (
                  subTopicRoute.parentTopic &&
                  route.codename === subTopicRoute.parentTopic
                ));

                if (topicRoute) {
                  const verticalRoute = this.toolsConfig.routes.find((route) => (
                    subTopicRoute.parentVertical &&
                    route.codename === subTopicRoute.parentVertical
                  ));

                  const eyebrow = `${verticalRoute.taxonomy.name} / ${topicRoute.text}`;
                  const heading = res.elements.title.value;
                  const cta = {
                    label: 'Learn More',
                    url: subTopicRoute.route,
                  };

                  recommendedCards.push({
                    id: res.system.id,
                    type: 'subtopic',
                    eyebrow,
                    heading,
                    cta,
                  });
                }
              }
              break;
            }
          }
        });

        promoCards.forEach((promoCard, index) => {
          const pos = (index + 1) * 3;
          if ((pos - 1) <= recommendedCards.length) {
            recommendedCards.splice((pos - 1), 0, promoCard);
          }
        });

        if (recommendedCards.length > 0) {
          return {
            location: false,
            heading,
            intro,
            cards: recommendedCards,
            assessment,
          };
        } else {
          return null;
        }
      };

      let personalizationParam;
      if (employmentStatus) {
        personalizationParam = `${employmentStatus.toLowerCase()}`;
      }

      if (areasTopOfMind) {
        const strings = areasTopOfMind.map((string) => string.toLowerCase());
        personalizationParam = `${personalizationParam},${strings.join(',')}`;
      }

      if (resourcesRelatedToRetirement) {
        const strings = resourcesRelatedToRetirement.map((string) => string.toLowerCase());
        personalizationParam = `${personalizationParam},${strings.join(',')}`;
      }

      if (financialConcerns) {
        const strings = financialConcerns.map((string) => string.toLowerCase());
        personalizationParam = `${personalizationParam},${strings.join(',')}`;
      }

      if (currentMedicarePlans) {
        personalizationParam = `${personalizationParam},${currentMedicarePlans}`;
      }

      if (typeof isCurrentlyReceivingBenefits !== 'undefined' || typeof currentlyEnrolledMedicare !== 'undefined') {
        if (isCurrentlyReceivingBenefits || currentlyEnrolledMedicare) {
          personalizationParam = `${personalizationParam},currentlyenrolledmedicare_true`;
        } else {
          personalizationParam = `${personalizationParam},currentlyenrolledmedicare_false`;
        }
      }

      if (personalizationParam) {
        const content = await this.kontent.getItem(null, {
          'system.type[in]': 'ncoa_article_content,awa_benefits_tool___card__tool_,awa_benefits_tool___vertical_subtopic',
          'elements.awp_personalization_terms[any]': personalizationParam,
          'limit': 500,
        });

        if (content && content?.items && content.items.length > 0) {
          const { items, modular_content } = content;
          const completedAssessments = this.toolsConfig.customerActivity.basic?.completedAssessments || [];

          items.forEach((item) => {
            switch (item.system.type) {
              case 'ncoa_article_content': {
                const vts = item.elements[this.toolsConfig.toolTaxonomyCodename].value.map(({ codename }) => codename);

                if (vts.some(item => pageTaxonomies.includes(item))) {
                  const taxonomies = item.elements[this.toolsConfig.toolTaxonomyCodename].value;
                  const verticals = taxonomies.map(({ codename }) => this.toolsConfig.getVertical(codename));
                  const vertical = this.utility.arrayMode(verticals.map(({ name }) => name));

                  const div = document.createElement('div');
                        div.innerHTML = item.elements.body_ckeditor.value;
                  const text = div.innerText;
                  const intro = `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`;

                  if (item.elements.awp_trusted_expert.value.length === 0) {
                    // resource page
                    recommendedCards.push({
                      id: item.system.id,
                      type: 'article',
                      heading: item.elements.title.value,
                      intro,
                      cta: {
                        url: `${this.toolsConfig.rootURL}/resource/${item.elements.url.value}`,
                        label: 'Read Resource',
                      },
                      breadcrumb: vertical,
                    })
                  } else {
                    // trusted expert page
                    recommendedCards.push({
                      id: item.system.id,
                      type: 'referral',
                      eyebrow: vertical,
                      heading: item.elements.title.value,
                      intro,
                      cta: {
                        label: 'View Details',
                        url: `${this.toolsConfig.rootURL}/resource/${item.elements.url.value}`,
                      },
                      bookmark: true,
                    });
                  }
                }

                break;
              }
              case 'awa_benefits_tool___card__tool_': {
                const linkedTool = modular_content[item.elements.tool.value[0]];
                const vts = linkedTool.elements[this.toolsConfig.toolTaxonomyCodename].value.map(({ codename }) => codename);
                if (vts.some(item => pageTaxonomies.includes(item))) {
                  recommendedCards.push({
                    id: item.system.id,
                    linkedID: linkedTool.system.id,
                    linkedCodename: linkedTool.system.codename,
                    type: 'tool',
                    heading: item.elements.headline.value,
                    intro: item.elements.description.value,
                    cta: {
                      label: completedAssessments.find((key) => key === linkedTool.elements.assessment_id.value)
                        ? 'View Results'
                        : 'Start Assessment',
                      url: (this.toolsConfig.routes.find((route) => (
                        route.assessmentID &&
                        route.assessmentID === linkedTool.elements.assessment_id.value
                      )) || { route: this.toolsConfig.rootURL }).route,
                    },
                    photo: item.elements.card_image.value.length > 0 ? {
                      url: item.elements.card_image.value[0].url,
                      alt: item.elements.card_image.value[0].description,
                    } : null,
                    variation: linkedTool.elements.assessment_id.value === 'generalAssessment' || linkedTool.elements.assessment_id.value === 'generalAssessmentAWP'
                      ? 'tools-retirement-single-col'
                      : 'tools-medicare-single-col',
                    theme: linkedTool.elements.assessment_id.value === 'generalAssessment' || linkedTool.elements.assessment_id.value === 'generalAssessmentAWP'
                      ? null
                      : 'dark',
                  });
                }

                break;
              }
              case 'awa_benefits_tool___vertical_subtopic': {
                const vts = item.elements[this.toolsConfig.toolTaxonomyCodename].value.map(({ codename }) => codename);

                if (vts.some(item => pageTaxonomies.includes(item))) {
                  const subTopic = this.toolsConfig.routes.find((route) => (
                    route.type === 'vertical-subtopic-learn' &&
                    route.codename === item.system.codename
                  ));

                  if (subTopic) {
                    const topic = this.toolsConfig.routes.find((route) => (
                      route.type === 'vertical-topic' &&
                      route.codename === subTopic.parentTopic
                    ));

                    if (topic) {
                      const vertical = this.toolsConfig.routes.find((route) => (
                        route.type === 'vertical' &&
                        route.codename === subTopic.parentVertical
                      ));

                      recommendedCards.push({
                        type: 'subtopic',
                        eyebrow: `${vertical.text} / ${topic.text}`,
                        heading: item.elements.title.value,
                        cta: {
                          label: 'Learn More',
                          url: subTopic.route,
                        },
                      });
                    }
                  }
                }

                break;
              }
            }
          });

          promoCards.forEach((promoCard, index) => {
            const pos = (index + 1) * 3;
            if ((pos - 1) <= recommendedCards.length) {
              recommendedCards.splice((pos - 1), 0, promoCard);
            }
          });

          if (recommendedCards.length > 0) {
            additionalData['recommended3ColGrid'] = {
              location: false,
              heading,
              intro,
              cards: recommendedCards,
              assessment,
            };
          }
        } else {
          const recommended3ColGrid: any = await getManualCards();
          if (recommended3ColGrid) {
            additionalData['recommended3ColGrid'] = recommended3ColGrid;
          }
        }
      } else {
        const recommended3ColGrid: any = await getManualCards();
        if (recommended3ColGrid) {
          additionalData['recommended3ColGrid'] = recommended3ColGrid;
        }
      }
    }

    return additionalData;
  }

  async getFeatureBlockContent(elements, contentType){

    const additionalData = {};
    if(contentType === 'vertical' || contentType === 'vertical-topic'){
      let featureContentBlockList = elements.feature_content_blocks.value;
      let blocks = [];
      featureContentBlockList.forEach((contentBlock, index) => {
        let rawFeatureContentBlock = this.toolsConfig.modular_content[contentBlock];
        if(rawFeatureContentBlock){
          blocks.push(this.processFeatureBlockComponent(rawFeatureContentBlock, index));
        }
      });
      additionalData['featureContentBlock'] = {
        blocks
      }
    }

    return additionalData
  }

  processFeatureBlockComponent(rawComponent: any, index: number) {
    const {
      title,
      description,
      image_asset,
      is_present,
      cta_text,
      cta_link,
      background_color
    }: any = this.kontent.extractItemElements(rawComponent, [
      'title',
      'description',
      'image_asset',
      'is_present',
      'cta_text',
      'cta_link',
      'background_color'
    ]);

    // Background Color
    const backgroundColor = background_color.length > 0
        ? background_color[0].codename
        : 'green';

    // Orientation
    const orientation = index % 2 !== 0  ? 'image-text' : 'text-image';

    // Button Configuration
    let isButtonPresent = (is_present[0].codename === 'true');
    let buttonCTALink = '', buttonCTAText = '';
    if (isButtonPresent) {
      buttonCTAText = cta_text;
      buttonCTALink = cta_link;
    }

    // Remodel Image
    let image = {
      url: image_asset[0].url,
      alt: image_asset[0].description,
      responsive: this.globalService.remodelImageSrc(image_asset[0].url),
    }

    return {
      componentType: 'featured_content_block',
      title,
      description,
      image,
      isButtonPresent,
      buttonCTAText,
      buttonCTALink,
      orientation,
      backgroundColor,
    }
  }

  getTopicListing(codename, elements, listingKey, listingHeadingKey) {
    const listingKeys = elements[listingKey].value;

    const topics = [];
    listingKeys.forEach((key) => {
      if (this.toolsConfig.modular_content[key]) {
        const { system: topicSystem, elements: topicElements } = this.toolsConfig.modular_content[key];

        const cta = {
          url: '#',
          text: 'Learn More',
        };

        const image = {
          src: '#',
          alt: '',
        };

        let eyebrow = elements.title.value;

        if (this.toolsConfig.currentConfig.core.route.type === 'vertical-topic') {
          const parentVerticalCodeName = this.toolsConfig.currentConfig.core.route.parentVertical;
          const parentVerticalTitle = this.toolsConfig.modular_content[this.toolsConfig.currentConfig.core.route.parentVertical].elements.title.value;

          eyebrow = `${parentVerticalTitle} / ${elements.title.value}`;

          const subtopic = this.toolsConfig.routes.find((route) => (
            route.codename === key &&
            ( 
              (route.type === 'vertical-subtopic-learn' && route.subTopicType === 'learn') || 
              (route.type === 'vertical-subtopic-landing' && route.subTopicType === 'landing') 
            )  &&
            route.parentTopic === codename &&
            route.parentVertical === parentVerticalCodeName
          ));

          cta.url = subtopic.route;
          image.src = topicElements.hero_image?.value[0].url;
          image.alt = topicElements.hero_image?.value[0].description;
        } else if (this.toolsConfig.currentConfig.core.route.type === 'vertical') {
          const topic = this.toolsConfig.routes.find((route) => (
            route.type === 'vertical-topic' &&
            route.codename === topicSystem.codename
          ));

          cta.url = topic.route;
          image.src = topicElements.card_image.value[0].url;
          image.alt = topicElements.card_image.value[0].description;
        }

        let isSaved = false;
        let savedContent: any = this.toolsConfig.customerActivity.basic?.SavedContent || [];
        if (savedContent) {
          const flag = savedContent.find((content) => content.ContentId === topicSystem.id);

          if (flag) {
            isSaved = true;
          }
        }

        topics.push({
          id: topicSystem.id,
          isSaved,
          eyebrow,
          title: topicElements.title.value,
          desc: topicElements.description.value,
          cta,
          image,
        });
      }
    });

    return {
      heading: elements[listingHeadingKey].value,
      topics,
    };
  }

  async getEmptyVerticalData(elements, vertical) {
    const additionalData = {};

    // text with image (promo)
    if (elements.promo.value.length > 0) {
      const promoKey = elements.promo.value[0];
      const promo = {
        item: this.toolsConfig.modular_content[promoKey],
      };

      if (promo.item) {
        const headline = promo.item.elements.headline.value;
        const description = promo.item.elements.description.value;
        const featured_image = promo.item.elements.featured_image.value.length > 0 ? {
          sizes: this.globalService.remodelImageSrc(promo.item.elements.featured_image.value[0].url),
          src: promo.item.elements.featured_image.value[0].url,
          alt: promo.item.elements.featured_image.value[0].description,
        } : null;
        const ctaLabel = promo.item.elements.cta_text__optional_.value;
        let ctaURL = promo.item.elements.cta_manual_external_link__optional_.value;

        if (promo.item.elements.cta_internal_link__optional_.value.length > 0) {
          let linkedContent = this.toolsConfig.modular_content[promo.item.elements.cta_internal_link__optional_.value[0]];

          switch (linkedContent.system.type) {
            case 'ncoa_article_content': {
              ctaURL = `${this.toolsConfig.rootURL}/resource/${linkedContent.elements.url.value}`;
              break;
            }
            case 'awa_benefits_tool___vertical_subtopic': {
              // there should be no subtopics
              break;
            }
          }
        }

        additionalData['emptyStatePromo'] = {
          alignment: 'left',
          heading: headline,
          intro: description,
          image: featured_image,
          cta: ctaLabel.trim().length > 0 && ctaURL.trim().length > 0 ? {
            label: ctaLabel,
            url: ctaURL,
          } : null,
        };
      }
    }

    // article card grouping
    if (elements[this.toolsConfig.toolTaxonomyCodename].value.length > 0) {
      if (vertical) {
        const verticalCodeName = vertical.codename;
        const params = {
          'system.type': 'ncoa_article_content',
          'elements': 'awa_how_to_s_designation,body_ckeditor,title,url',
          'elements.awp_trusted_expert[empty]': true,
          'limit': 500,
        };
        const _prop = `elements.${this.toolsConfig.toolTaxonomyCodename}[contains]`;

        params[_prop] = verticalCodeName;

        const articleRes = await this.kontent.getItem(null, params);

        if (articleRes.items.length > 0) {
          let howToArticles = articleRes.items.filter((article) => article.elements.awa_how_to_s_designation.value.length > 0);
          let otherArticles = articleRes.items.filter((article) => article.elements.awa_how_to_s_designation.value.length === 0);

          const remodelArticle = (article) => {
            const div = document.createElement('div');
                  div.innerHTML = article.elements.body_ckeditor.value;
            const text = div.innerText;

            return {
              id: article.system.id,
              type: 'article',
              heading: article.elements.title.value,
              intro: `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`,
              cta: {
                url: `${this.toolsConfig.rootURL}/resource/${article.elements.url.value}`,
                label: 'Read Resource',
              },
              breadcrumb: `${elements.title.value}`,
            };
          }

          howToArticles = howToArticles.map((article) => remodelArticle(article));
          otherArticles = otherArticles.map((article) => remodelArticle(article));

          additionalData['emptyArticleCardGrouping'] = [];

          if (howToArticles.length > 0) {
            additionalData['emptyArticleCardGrouping'].push({
              heading: `How To's`,
              cards: howToArticles,
            });
          }

          if (otherArticles.length > 0) {
            additionalData['emptyArticleCardGrouping'].push({
              heading: 'Helpful Tips & Guidance',
              cards: otherArticles,
            });
          }
        }
      }
    }

    // tool card 1-up
    if (elements.tool_card_1_up.value.length > 0) {
      const toolCard1UpKey = elements.tool_card_1_up.value[0];
      const toolCard1Up = {
        item: this.toolsConfig.modular_content[toolCard1UpKey],
      };

      if (toolCard1Up.item) {
        const headline = toolCard1Up.item.elements.headline.value;
        const toolCard = this.toolsConfig.modular_content[toolCard1Up.item.elements.tool_card.value[0]];
        const linkedTool = this.toolsConfig.modular_content[toolCard.elements.tool.value[0]];

        const card = {
          id: toolCard.system.id,
          linkedID: linkedTool.system.id,
          linkedCodename: linkedTool.system.codename,
          type: 'tool',
          heading: toolCard.elements.headline.value,
          intro: toolCard.elements.description.value,
          cta: {
            label: (this.toolsConfig.customerActivity.basic?.completedAssessments || []).find((key) => (
              key === linkedTool.elements.assessment_id.value
            )) ? 'View Results' : 'Start Assessment',
            url: (this.toolsConfig.routes.find((route) => (
              route.assessmentID &&
              route.assessmentID === linkedTool.elements.assessment_id.value
            )) || { route: null }).route,
          },
          photo: toolCard.elements.card_image.value.length > 0 ? {
            url: toolCard.elements.card_image.value[0].url,
            alt: toolCard.elements.card_image.value[0].description,
          } : null,
          full: true,
          variation: linkedTool.elements.assessment_id.value !== 'generalAssessment' || linkedTool.elements.assessment_id.value !== 'generalAssessmentAWP' ? 'tools-medicare-single-col' : 'tools-retirement-full-width',
          theme: linkedTool.elements.assessment_id.value !== 'generalAssessment' || linkedTool.elements.assessment_id.value !== 'generalAssessmentAWP' ? 'dark' : null,
        };

        additionalData['emptyToolCard1Up'] = {
          type: 'tool',
          heading: headline,
          card,
        };
      }
    }

    return additionalData;
  }
}
