<div class="component component--audience component--audience--{{ theme }}">
  <div class="wrapper">
    <h2 class="audience--heading" [innerHTML]="audience.heading"></h2>

    <nav class="audience--link">
      <ul>
        <li class="audience--link__item"
          *ngFor="let item of audience.links; let last = last"
          (mouseenter)="navHover($event)"
          (mouseleave)="navLeave($event)"
          [ngClass]="{'last' : last}"
        >
          <a (focus)="navFocus($event)" (focusout)="navFocusOut($event)" href="{{ item.url }}">
            <b>{{ item.text }}</b>
            <i></i>
            <span>Find Content <i></i></span>
          </a>
        </li>
      </ul>
      <span class="link-marker">&nbsp;</span>
    </nav>
  </div>
</div>
