import { Component, Input } from '@angular/core';
import { Partner } from 'src/app/typings/partners';

@Component({
  selector: 'ncoa-partners-grid',
  templateUrl: './partners-grid.component.html',
  styleUrls: ['./partners-grid.component.scss'],
})
export class PartnersGridComponent {
  @Input() heading: string;
  @Input() type: string;
  @Input() partners: Partner[];
}
