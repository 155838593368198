import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToDo, ToDoAction } from '@components/planner/plan/to-do/to-do.component';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { RebaDataService } from '@services/planner/reba-data.service';
import { UserService } from '@services/planner/user.service';
import { VerticalService } from '@services/planner/vertical.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { ToDoListComponent } from '@components/planner/plan/to-do-list/to-do-list.component';
import { take } from 'rxjs/operators';
import { queueScheduler } from 'rxjs';
import { UserPromptService } from '@services/planner/user-prompt.service';
import { LocationStrategy } from '@angular/common';
import { GlobalService } from '@services/global.service';
import { RichTextService } from '@services/rich-text-resolver.service';


@Component({
  selector: 'vertical-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
export class PlanComponent implements OnInit, AfterViewInit {
  @ViewChild('activeTodosTemplate', {static: false}) activeTodosRef: TemplateRef<any>;
  @ViewChild('completedTodosTemplate', {static: false}) completedTodosRef: TemplateRef<any>;
  @ViewChild('archivedTodosTemplate', {static: false}) archivedTodosRef: TemplateRef<any>;
  @ViewChild('header', {static: false}) header: ElementRef<any>;

  @Input() isSaved: boolean;

  data: any = null;

  activeTodos: ToDo[];    // !completed && !archived
  completedTodos: ToDo[]; // completed && !archived
  archivedTodos: ToDo[];  // archived

  // counts
  activeTodosTotal: number;
  completedTodosTotal: number;
  archivedTodosTotal: number;

  currentTodo?: ToDo;
  currentTodoSlug: string;
  loading: boolean = false;
  showScreeningAnswers: boolean = false;
  currentTodoList: 'activeTodosRef' | 'completedTodosRef' | 'archivedTodosRef';
  showInstructions: boolean;
  headerSticky: boolean;
  startOverOrEditCTA = "StartOver";
  bannerSubText;
  public screeningCode: string;
  public rect: any;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.loading = true;
    this.showScreeningAnswers = false;
    this.getTodos().then(() => this.loading = false);
  }

  constructor(
    private vertical: VerticalService,
    private cas: CustomerActivityService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private router: Router,
    private reba: RebaDataService,
    private user: UserService,
    private kontent: KontentDeliveryService,
    private modalService: UserPromptService,
    private location: LocationStrategy,
    private globalService: GlobalService,
    private richTextService: RichTextService
  ) {

    history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    }); 

    this.activeTodos = [];
    this.completedTodos = [];
    this.archivedTodos = [];
    this.showInstructions = true;
    this.headerSticky = false;

    this.vertical.getData('vertical-plan').then((data) => {
      this.data = data;
      this.startOverOrEditCTA = this.isSaved ? this.data.editPlanCTA : this.data.startOverCTA;
      if(this.data.bannerSubtext)
        this.bannerSubText = this.richTextService.resolveRichText(this.data.bannerSubtext);
      this.loading = true;
      this.getTodos().then(() => {
        this._updateCustomerActivity();
        const completedSurveys = JSON.parse(localStorage.getItem("screeningAnswers") ?? "{}");
        if (Object.keys(completedSurveys).indexOf(this.screeningCode) < 0){ 
          this.clickStartOver();}
        else this.loading = false;
      });

      this.route.queryParams.subscribe(({ todo }) => {
        if (!todo || !todo.length) {
          this.router.navigate([window.location.pathname], { queryParamsHandling: 'preserve' });
        } else {
          this.router.navigate([window.location.pathname], {queryParams: {todo: todo}})
        }

        this.currentTodoSlug = todo

        this.currentTodo = [
          ...this.activeTodos,
          ...this.completedTodos,
          ...this.archivedTodos,
        ].find((element) => element.slug === todo);
      });
    });
    this.globalService.loadFooter(true);
  }

  @HostListener('window:scroll', ['$event'])
  private scroll($event){
    if(this.rect){
      this.headerSticky = window.scrollY >= (this.rect.y);
    }
  }

  public viewAnswers(){
    window.scroll(0, 0)
    this.showScreeningAnswers = true;
  }

  public onUpdateListTotal(action: ToDoAction){
    const increment = action.action ? 1 : -1;

    switch(action.what){
      case 'active':{
        this.activeTodosTotal += increment;
        break;
      }
      case 'archived':{
        this.archivedTodosTotal += increment;
        break;
      }
      case 'completed':{
        this.completedTodosTotal += increment;
        break;
      }
    }
  }

  ngOnInit(): void {
    this.isSaved = false;
    localStorage.setItem('lastPage', window.location.href);
    this.user.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.isSaved = true;
      }
    });
  }

  ngAfterViewInit(): void {
    this.currentTodoList = 'activeTodosRef';
    setTimeout(() => {
      if(this.header){
        this.rect = this.header.nativeElement.getBoundingClientRect();
      }
    }, 1000)

  }

  clickSave(): void {
    this.modalService.isModalOpen(true);
  }

  private _extractQuestionsFromScreening(screening: any) {
    const questions = [];

    for (const section of screening.sections) {
      for (const group of section.questionGroups) {
        questions.push(...group.questions.map((q) => q.id));
      }
    }
    return questions;
  }
  async clickStartOver() {
      this.user.isLoggedIn()
        .pipe(take(1))
        .subscribe((isLoggedIn) => {
          if (isLoggedIn) {
            this.navigateBackDirectory({edit:'true'});
            this.cas.announcePlanReset().pipe(take(1)).subscribe();
          }
          else {
            this.user.logout();
            //navigate up one directory to assessment page
            window.location.replace(window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')))
          }
        });
  
  }

  logOut(){
    this.user.logout();
    this.clickStartOver();
  }

  navigateBackDirectory(params?: { [key: string]: string }) {
    const path = window.location.pathname;
    const segments = path.split('/');
    segments.pop();
    const newUrl = segments.join('/');

    const existingQueryParams = this.route.snapshot.queryParams;

    const queryParams = { ...existingQueryParams, ...params };

    this.router.navigate([newUrl], { queryParams: queryParams }).then(() => {
        window.location.reload();
    });
}
  
  private async _getAnswersInScreening(allAnswers: any) {
    const screeningAnswers: { [questionCode: string]: any } = {};
    let screenings: any[] = [];

    if (this.data.planType === "budget_calculator_plan"){ // issue here is we have two screenings we need to handle
      screenings = await Promise.all(['awp_budget_calculato', 'awp_budget_sub_calc'].map(async (code) => this.reba
      .getScreening(code, '23838')
      .take(1)
      .toPromise()))

      this.screeningCode = 'awp_budget_calculato'
    } else if (this.data.planType === "workforce_plan"){
      const screening  = await this.reba
      .getScreening('AWPworkforce', '23838')
      .take(1)
      .toPromise();

      screenings.push(screening);
      this.screeningCode = 'AWPworkforce';
    }


    for (const screening of screenings){
      for (const question of this._extractQuestionsFromScreening(screening)) {
        if (question in allAnswers) {
          screeningAnswers[question] = allAnswers[question];
        }
  
        if (this.user.authSet() && !screeningAnswers[question]) {
          const [dataItems] = await this.cas
            .getQuestionForID(question)
            .take(1)
            .toPromise();
  
          if (Object.keys(dataItems).length !== 0) {
            screeningAnswers[question] = dataItems[question];
            this.updateAllAnswers({[question]:dataItems[question]}) //load items into local storage
          }
        }
      }

    }

    return screeningAnswers;
  }
  private updateAllAnswers(data: Record<string, any>): void {
    const userId = this.user.CADId;
    const localStorageKey = 'allAnswers';
    let allAnswers = {};

    const localStorageData = localStorage.getItem(localStorageKey);
    // if we have data in local storage, use it
    if (localStorageData) {
      allAnswers = JSON.parse(localStorageData);
    }
    allAnswers[userId] = {...allAnswers[userId], ...data};
    localStorage.setItem(localStorageKey, JSON.stringify(allAnswers));
  }
  
  private _removeDuplicateTodos(todos: any[]) {
    return todos.filter(
      (todo, index) => todos.findIndex((t2) => todo.slug === t2.slug) === index
    );
  }

  private _buildQuery(answers: any){
    const query : string[] = [];
    for(const [code, answer] of Object.entries(answers)){
        Array.isArray(answer) ?   query.push(...answer.map((a) => `${code}_${a}`)) :  query.push(`${code}_${answer}`)
    }

    return query
  }

  private async getTodos() {
    this.completedTodos = [];
    this.archivedTodos = [];
    this.activeTodos = [];
    let CADId = this.user.CADId || '';
    const allAnswers =
      JSON.parse(localStorage.getItem('allAnswers') ?? '{}')[CADId] || {};
    const screeningAnswers = await this._getAnswersInScreening(allAnswers);

    // get calculated attributes
    const result = await this.reba
      .evaluateQuestions(screeningAnswers)
      .take(1)
      .toPromise();
  
    Object.assign(screeningAnswers, result);
    const client = this.kontent.client;
    const query = this._buildQuery(screeningAnswers)

    // add defaults
    switch(this.data.planType){
      case 'budget_calculator_plan':{
        query.push("budget_calculator_default")
        break;
      }
      case 'workforce_plan':{
        query.push("workforce_default")
      }
    }

    let relevantTodos = (
      await client
        .items()
        .type('awp___workforce___to_do')
        .anyFilter('elements.tags', query)
        .toPromise()
    ).items;
 
    //  we need this to work as an AND so frontend filtering is needed due to allFilter on kentico not being exclusive
    relevantTodos = relevantTodos.filter((todo) => {
      return todo.tags.value.every((tag) => query.includes(tag.codename))
    });

    let storedCompletedTodos = this._getTodosFromStorage('completedTodos');
    let storedArchivedTodos = this._getTodosFromStorage('archivedTodos');
    let storedActiveTodos = this._getTodosFromStorage('activeTodos');

    if (this.user.authSet()) {
      let {
        todos: { activeTodos = [], completedTodos = [], archivedTodos = [] } = {},
        basic: { completedScreenings = [] } = {}
      } = await this.cas.getActivity().take(1).toPromise();

      const diff = [...activeTodos, ...completedTodos, ...archivedTodos].filter(
        (todo) =>
          relevantTodos.findIndex(
            (relevant) => todo.slug === relevant.code.value
          ) === -1 && todo.source === this.data.planType
      );

      const query = diff.map((todo) => todo.slug);

      if (query.length) {
        const missingTodos = (
          await client
            .items()
            .type('awp___workforce___to_do')
            .inFilter('elements.code', query)
            .toPromise()
        ).items;

        relevantTodos.push(...missingTodos);
      }

      storedCompletedTodos = this._removeDuplicateTodos([
        ...storedCompletedTodos,
        ...completedTodos,
      ]);
      storedArchivedTodos = this._removeDuplicateTodos([
        ...storedArchivedTodos,
        ...archivedTodos,
      ]);
      storedActiveTodos = this._removeDuplicateTodos([
        ...storedActiveTodos,
        ...activeTodos,
      ]);

      let completedSurveys = JSON.parse(localStorage.getItem("screeningAnswers") ?? "{}");
      completedScreenings.forEach(screening => {
        if (Object.keys(completedSurveys).indexOf(screening) < 0) {
          completedSurveys[`${screening}`] = { completed: true };
        }
      });
      localStorage.setItem("screeningAnswers", JSON.stringify(completedSurveys));
    }

    const goalQuestionIds = ["awp_goals", "awp_jsc_goals"];

    let formattedTodos = relevantTodos.map((result) => {
      const goals = [];
      
      for(const tag of result.todo_goal.value){
        if(goalQuestionIds.some((id) => tag.codename.startsWith(id) && allAnswers?.[id]?.some(goal => tag.codename.endsWith(goal)))){ // is a goal and check if user has put that goal
          goals.push(tag.name)
        }
      }

      const goal = {
        title: result.title.value,
        slug: result.code.value,
        source: this.data.planType,
        goals,
        order: result.order.value,
        snippet: result.snippet.value,
        content: result.body.value,
        completed: false,
        archived: false,
        timestamp: new Date(),
      };

      return goal;
    }) as ToDo[];

    formattedTodos = formattedTodos.filter((todo) => todo.source === this.data.planType);

    for (const todo of formattedTodos) {
      const completed = storedCompletedTodos.find((td) => td.slug === todo.slug);
      const archived = storedArchivedTodos.find((td) => td.slug === todo.slug);
      const active = storedActiveTodos.find((td) => td.slug === todo.slug);

      todo.archived = archived !== undefined;
      todo.completed = completed !== undefined;

      if (completed && !archived) {
        todo.timestamp = new Date(completed.timestamp);
        this.completedTodos.push(todo);
        continue;
      }

      if (archived) {
        todo.timestamp = new Date(archived.timestamp);
        this.archivedTodos.push(todo);
        continue;
      }

      if (active) {
        todo.timestamp = new Date(active.timestamp);
      }

      this.activeTodos.push(todo);
    }

    this._dateSort(this.completedTodos);
    this._dateSort(this.archivedTodos);
    this._dateSort(this.activeTodos);

    this.currentTodo = [
      ...this.activeTodos,
      ...this.completedTodos,
      ...this.archivedTodos,
    ].find((element) => element.slug === this.currentTodoSlug);

    this.activeTodosTotal = this.activeTodos.length;
    this.completedTodosTotal = this.completedTodos.length;
    this.archivedTodosTotal = this.archivedTodos.length;

    localStorage.setItem("activeTodos", JSON.stringify(this.activeTodos));
    localStorage.setItem("completedTodos", JSON.stringify(this.completedTodos));
    localStorage.setItem("archivedTodos", JSON.stringify(this.archivedTodos));
  }

  private _dateSort(todos: ToDo[]) {
    todos.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
  }

  private _handleTodo(todos: ToDo[], todo: ToDo, list: string) {
    const todoIdx = todos.findIndex((td) => td.slug === todo.slug);

    if (todoIdx === -1) {
      return;
    }

    const [swappedTodo] = todos.splice(todoIdx, 1);

    this[list].push(swappedTodo);
    this.cd.detectChanges()
  }

  public async onTodoAction(todo: ToDo, action: string) {
    queueScheduler.schedule(async () => {
      todo.timestamp = new Date();

      switch (action) {
        case 'onComplete': {
          let todos = this.activeTodos;
          let storage = 'activeTodos';

          if (todo.archived) {
            todos = this.archivedTodos;
            storage = 'archivedTodos';
          }

          todo.completed = true;

          this._handleTodo(
            todos,
            todo,
            'completedTodos'
          );
          this._updateLocalStorage(todo, storage, 'completedTodos');
          this._dateSort(this.completedTodos);
          break;
        }
        case 'onIncomplete': {
          let todos = this.activeTodos;
          let storage = 'activeTodos';

          if (todo.archived) {
            todos = this.archivedTodos;
            storage = 'archivedTodos';
          }
          if (todo.completed) {
            todos = this.completedTodos;
            storage = 'completedTodos';
          }
          todo.completed = false;

          this._handleTodo(todos, todo, 'activeTodos');
          this._updateLocalStorage(todo, storage, 'activeTodos');
          this._handleTodo(todos, todo, 'activeTodos');
          this._updateLocalStorage(todo, storage, 'activeTodos');
          this._dateSort(this.activeTodos);

          break;
        }
        case 'onArchived': {
          let todos = this.completedTodos;
          let storage = 'completedTodos';

          if (todo.archived) {
            todos = this.archivedTodos;
            storage = 'archivedTodos';
          }
          if (!todo.completed && !todo.archived) {
            todos = this.activeTodos;
            storage = 'activeTodos';
          }

          todo.archived = true;
          this._handleTodo(todos, todo, 'archivedTodos');
          this._dateSort(this.archivedTodos);
          this._updateLocalStorage(todo, storage, 'archivedTodos');
          break;
        }
        case 'onUnarchived': {
          todo.archived = false;

          if (todo.completed) {
            this._handleTodo(
              this.archivedTodos,
              todo,
              'completedTodos'
            );
            this._updateLocalStorage(todo, 'archivedTodos', 'completedTodos');
          } else {
            this._handleTodo(
              this.archivedTodos,
              todo,
              'activeTodos'
            );
            this._updateLocalStorage(todo, 'archivedTodos', 'activeTodos');
          }
          this._dateSort(this.activeTodos);
          break;
        }
      }

      await this._updateCustomerActivity();
    });
  }

  private _getTodoByStatus(status: string) {
    const allTodos = [...this.activeTodos, ...this.archivedTodos, ...this.completedTodos];
    switch(status) {
      case 'active': // !completed and !archived
        return allTodos.filter(todo => !todo.completed && !todo.archived);
      case 'archived': // archived
        return allTodos.filter(todo => todo.archived);
      case 'completed': // completed
        return allTodos.filter(todo => todo.completed);
    }

    return []; // default empty
  }


  private cleanTodos(todos: ToDo[]){
    return todos.map((todo) => ({slug: todo.slug, source: todo.source, timestamp: todo.timestamp}))
  }

  private _updateCustomerActivity() {
    const activeTodos = this._getTodoByStatus('active');
    const archivedTodos = this._getTodoByStatus('archived');
    const completedTodos = this._getTodoByStatus('completed');

    return this.cas
      .updateActivity({activeTodos: this.cleanTodos(activeTodos), archivedTodos: this.cleanTodos(archivedTodos), completedTodos: this.cleanTodos(completedTodos)})
      .pipe(take(1)).toPromise()
  }

  private _updateLocalStorage(
    todo: ToDo,
    oldStorage: string,
    newStorage: string
  ) {
    const storedTodos = this._getTodosFromStorage(oldStorage);
    const filteredTodos = storedTodos.filter((st) => st.slug !== todo.slug);
    localStorage.setItem(oldStorage, JSON.stringify(filteredTodos));

    const newTodos = this._getTodosFromStorage(newStorage);
    newTodos.push({ slug: todo.slug, timestamp: todo.timestamp.toISOString() });
    localStorage.setItem(newStorage, JSON.stringify(newTodos));
  }

  private _getTodosFromStorage(
    todoList: string
  ): { slug: string; timestamp: string }[] {
    return JSON.parse(localStorage.getItem(todoList) ?? '[]');
  }

  public async onDeletePlan(){
    this.loading = true;
    if (this.user.authSet()) {
      this.cas.announcePlanReset().pipe(take(1)).subscribe();
      const completedSurveys = JSON.parse(localStorage.getItem("screeningAnswers") ?? "{}");
      delete completedSurveys[this.screeningCode];
      if (Object.keys(completedSurveys).length) localStorage.setItem("screeningAnswers", JSON.stringify(completedSurveys));
      else localStorage.removeItem("screeningAnswers");

      // clear local storage items for plan
      const fieldsToClearInStorage = ["archivedTodos", "activeTodos", "completedTodos"]
      this.archivedTodos = [];
      this.activeTodos = [];
      this.completedTodos = [];
      for (const field of fieldsToClearInStorage){
        localStorage.removeItem(field);
      }

      // clear answer values in CAD for user
      const allAnswers = JSON.parse(localStorage.getItem('allAnswers') ?? '{}') || {};
      const screeningAnswers = await this._getAnswersInScreening(allAnswers);
      await Promise.all(Object.keys(screeningAnswers).map(answer => {
        return this.cas.updateActivity({[`${answer}`]: null}).pipe(take(1)).toPromise();
      }));
      // clear screening event in user's CAD profile
      await this.cas.updateActivity({'removeEvent': `${this.screeningCode}ScreeningCompleted`}).pipe(take(1)).toPromise();

      const userAnswers = allAnswers[this.user.CADId || ''];
      for (const answer of Object.keys(screeningAnswers)) {
        if (answer in userAnswers){
          delete userAnswers[answer]
        }
      }
      allAnswers[this.user.CADId || ''] = userAnswers;
      localStorage.setItem("allAnswers", JSON.stringify(allAnswers));
      this._updateCustomerActivity();
    } else {
      // non-authenticated user -- create new session
      this.user.updateUserID();
    }
    window.location.replace(window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')))
  }

  navToSignIn(){
    this.router.navigate(['/sign-up']).then(() => {
      window.location.reload();
  });
  }
}

