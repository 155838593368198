<div class="benefits-listing {{ type === 'decorative' ? 'benefits-listing--decorative' : 'benefits-listing--plain' }}">
  <div class="benefits-listing__back-cta" *ngIf="type === 'decorative' && backCta">
    <a class="back-cta" (click) = 'goBack($event)' [title]="backCta.text">
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.86842 0.54771L0.315788 7.25111C0.105263 7.46562 0 7.73375 0 8.00189C0 8.27003 0.105263 8.53816 0.315788 8.75267L6.86842 15.4561C7.28947 15.8851 7.94737 15.8851 8.36842 15.4561C8.78947 15.0271 8.78947 14.3567 8.36842 13.9277L3.57895 9.07443L18.9474 9.07443C19.5263 9.07443 20 8.59179 20 8.00189C20 7.41199 19.5263 6.92935 18.9474 6.92935L3.57895 6.92935L8.34211 2.04927C8.76316 1.62025 8.76316 0.949914 8.34211 0.520628C7.94737 0.118424 7.28947 0.118424 6.86842 0.54771Z" fill="#0B4A5D"/>
      </svg>
      <div><span>{{ backCta.text }}</span></div>
    </a>
  </div>
  <div class="benefits-listing__header">
    <div class="background" *ngIf="type === 'decorative'" aria-hidden="true">
      <picture>
        <source [media]="size.media" [srcset]="size.url" *ngFor="let size of header.backgroundImage.sizes" />
        <img [src]="header.backgroundImage.src" [alt]="header.backgroundImage.alt" />
      </picture>
    </div>
    <div class="main">
      <div class="main__heading">
        <h1 class="title">{{ header.title }}</h1>
        <p class="desc" *ngIf="header.desc">{{ header.desc }}</p>
      </div>
    </div>
  </div>
  <div *ngIf="subHeaderCTA" class="benefits-listing__subheader-cta">
    <a [href]="subHeaderCTA.url" [title]="subHeaderCTA.text">
      <span aria-hidden="true">{{ subHeaderCTA.text }}</span>
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.3267 7.82279L19.3274 7.8235C19.4481 7.94651 19.5 8.08967 19.5 8.22406C19.5 8.35845 19.4481 8.5016 19.3274 8.62461L19.3267 8.62533L12.7747 15.328C12.7746 15.3281 12.7745 15.3282 12.7745 15.3283C12.5494 15.5573 12.2134 15.5572 11.9884 15.328C11.7584 15.0936 11.7582 14.735 11.988 14.5005C11.9882 14.5004 11.9883 14.5002 11.9884 14.5001L16.7769 9.64781L17.617 8.7966L16.4211 8.7966L1.05263 8.7966C0.758618 8.7966 0.499999 8.54669 0.499999 8.22406C0.499999 7.90143 0.758618 7.65151 1.05263 7.65151L16.4211 7.65151L17.6078 7.65151L16.7789 6.80227L12.0157 1.9222L12.0147 1.92121C11.7846 1.68673 11.7845 1.32781 12.0149 1.09291C12.2052 0.899108 12.5409 0.881989 12.7743 1.11969C12.7744 1.11979 12.7745 1.11989 12.7746 1.11999L19.3267 7.82279Z" fill="#0B4A5D" stroke="#0B4A5D"/>
      </svg>
    </a>
  </div>
  <div class="benefits-listing__filter" (window:resize)="onResize()">
    <div class="state">
      <label for="state">Select State:</label>
      <div class="dropdown-container">
        <select
          [(ngModel)]="selectedState"
          (change)="onStateChange()"
          name="state"
          id="state"
        >
          <option value="">Select State</option>
          <option [value]="stateOption.value" *ngFor="let stateOption of stateOptions">
            {{ stateOption.text }}
          </option>
        </select>
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.67406 7.70909C7.48101 7.90303 7.23967 8 6.99835 8C6.75703 8 6.51571 7.90303 6.32265 7.70909L0.289587 1.67273C-0.096529 1.28485 -0.096529 0.678788 0.289587 0.290909C0.675703 -0.0969697 1.27901 -0.0969697 1.66513 0.290909L6.99835 5.82562L12.3557 0.315152C12.7418 -0.0727268 13.3451 -0.0727268 13.7315 0.315152C14.0935 0.678788 14.0935 1.28485 13.7071 1.67273L7.67406 7.70909Z" fill="#0B4A5D"/>
        </svg>
      </div>
    </div>
    <div class="filter">
      <div class="filter__head">
        <h3 class="title">Filter by Category:</h3>
        <button type="button" class="filter-toggle-btn" (click)="toggleFilter()">
          {{ isFilterOpen ? '- Hide Filters' : '+ Show Filters' }}
        </button>
      </div>
      <div class="filter__head--desktop">
        <h3 class="title">Filter by Category:</h3>
        <button type="button" class="filter-toggle-btn" (click)="toggleFilter()">
          Filters Applied ({{ selectedCategories.length }})
          <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1555 13.4909C12.8246 13.8303 12.4109 14 11.9972 14C11.5835 14 11.1698 13.8303 10.8388 13.4909L0.496435 2.92727C-0.165478 2.24848 -0.165478 1.18788 0.496435 0.509091C1.15835 -0.169697 2.1926 -0.169697 2.85451 0.509091L11.9972 10.1948L21.1812 0.551514C21.8431 -0.127274 22.8774 -0.127274 23.5397 0.551514C24.1602 1.18788 24.1602 2.24848 23.4979 2.92727L13.1555 13.4909Z" fill="#0B4A5D"/>
          </svg>
        </button>
      </div>
      <div class="filter__body">
        <div class="filter__body-wrapper">
          <div class="indicator">
            <h4 *ngIf="preSelectedCategories.length > 0" class="counter">{{ preSelectedCategories.length }} selected</h4>
            <div class="btn-group">
              <button tabindex="-1" type="button" class="option-toggle-btn" (click)="selectAll()">Select All</button>
              <button tabindex="-1" type="button" class="option-toggle-btn" (click)="unSelectAll()">Unselect All</button>
            </div>
            <button tabindex="-1" type="button" class="apply-filter-btn" (click)="applyFilter()">Apply Filters</button>
          </div>
          <div class="category">
            <div class="category__head">
              <button tabindex="-1" type="button" class="cat-filter-toggle-btn" (click)="toggleCategoryFilter()">
                {{ isCategoryFilterOpen ? '- Categories' : '+ Categories' }}
              </button>
            </div>
            <div class="category__body">
              <label class="field" *ngFor="let category of categoryOptions; let i = index">
                <input
                  tabindex="-1"
                  type="checkbox"
                  [name]="'category_'+i"
                  id="{{ category.value }}"
                  value="{{ category.value }}"
                  [(ngModel)]="category.isSelected"
                  [attr.aria-label]="category.text"
                  (change)="onCheckboxChange($event)"
                />
                <div class="checkbox" aria-hidden="true">
                  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.09961 13.166C6.45117 13.5176 7.04883 13.5176 7.40039 13.166L17.7363 2.83008C18.0879 2.47852 18.0879 1.88086 17.7363 1.5293L16.4707 0.263672C16.1191 -0.0878906 15.5566 -0.0878906 15.2051 0.263672L6.76758 8.70117L2.79492 4.76367C2.44336 4.41211 1.88086 4.41211 1.5293 4.76367L0.263672 6.0293C-0.0878906 6.38086 -0.0878906 6.97852 0.263672 7.33008L6.09961 13.166Z" fill="#0B4A5D"/>
                  </svg>
                </div>
                <span aria-hidden="true">
                  {{ category.text }}
                </span>
              </label>
            </div>
            <button tabindex="-1" type="button" class="apply-filter-btn" (click)="applyFilter()">
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="subHeaderCTA" class="benefits-listing__subheader-cta">
      <a [href]="subHeaderCTA.url" [title]="subHeaderCTA.text">
        {{ subHeaderCTA.text }}
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.3267 7.82279L19.3274 7.8235C19.4481 7.94651 19.5 8.08967 19.5 8.22406C19.5 8.35845 19.4481 8.5016 19.3274 8.62461L19.3267 8.62533L12.7747 15.328C12.7746 15.3281 12.7745 15.3282 12.7745 15.3283C12.5494 15.5573 12.2134 15.5572 11.9884 15.328C11.7584 15.0936 11.7582 14.735 11.988 14.5005C11.9882 14.5004 11.9883 14.5002 11.9884 14.5001L16.7769 9.64781L17.617 8.7966L16.4211 8.7966L1.05263 8.7966C0.758618 8.7966 0.499999 8.54669 0.499999 8.22406C0.499999 7.90143 0.758618 7.65151 1.05263 7.65151L16.4211 7.65151L17.6078 7.65151L16.7789 6.80227L12.0157 1.9222L12.0147 1.92121C11.7846 1.68673 11.7845 1.32781 12.0149 1.09291C12.2052 0.899108 12.5409 0.881989 12.7743 1.11969C12.7744 1.11979 12.7745 1.11989 12.7746 1.11999L19.3267 7.82279Z" fill="#0B4A5D" stroke="#0B4A5D"/>
        </svg>
      </a>
    </div>
  </div>
  <div class="benefits-listing__list">
    <div *ngIf="!isLoaded" class="benefits-listing__loader benefits-listing__loader--full">
      <img src="../../../../assets/images/loader.gif" alt="Loading" />
    </div>
    <h2 *ngIf="isLoaded" class="title" role="status">Showing {{ list.length }} Benefits & Programs</h2>
    <ul *ngIf="isLoaded && list.length > 0" class="listing">
      <li *ngFor="let item of list.slice(0, shownItems); let i = index;">
        <benefit-card
          [index]="i"
          [title]="item.title"
          [eyebrow]="item.eyebrow"
          [desc]="item.desc"
          (showModal)="showModal(i)"
        ></benefit-card>
      </li>
    </ul>
    <div *ngIf="isLoaded && list.length === 0 && selectedState==='' " class="empty-list">
      <p>Please select a state to find local benefits</p>
    </div>    
    <div *ngIf="isLoaded && list.length === 0 && selectedState!== '' " class="empty-list">
      <p> No benefits found for {{selectedState}}</p>
    </div>
    <div class="load-more" *ngIf="isLoaded && list.length > shownItems">
      <button
        *ngIf="!isLoadingMore"
        type="button"
        class="load-more__btn"
        (click)="loadMore()"
      >
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0Z" fill="#0B4A5D"/>
          <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="12" y="10" width="16" height="20">
          <path d="M12.5 10.5H27.0556V29.5H12.5V10.5Z" fill="white" stroke="#0B4A5D"/>
          </mask>
          <g mask="url(#mask0)">
          <path d="M20.1772 29.3267L20.1765 29.3274C20.0535 29.4481 19.9103 29.5 19.7759 29.5C19.6416 29.5 19.4984 29.4481 19.3754 29.3274L19.3747 29.3267L12.672 22.7747C12.6719 22.7746 12.6718 22.7745 12.6717 22.7745C12.4427 22.5494 12.4428 22.2134 12.672 21.9884C12.9064 21.7584 13.265 21.7582 13.4995 21.988C13.4996 21.9882 13.4998 21.9883 13.4999 21.9884L18.3522 26.7769L19.2034 27.617V26.4211V11.0526C19.2034 10.7586 19.4533 10.5 19.7759 10.5C20.0986 10.5 20.3485 10.7586 20.3485 11.0526V26.4211V27.6078L21.1977 26.7789L26.0778 22.0157L26.0788 22.0147C26.3133 21.7846 26.6722 21.7845 26.9071 22.0149C27.1009 22.2052 27.118 22.5409 26.8803 22.7743C26.8802 22.7744 26.8801 22.7745 26.88 22.7746L20.1772 29.3267Z" fill="white" stroke="white"/>
          </g>
        </svg>
        <div>Load More Resources</div>
      </button>
      <img *ngIf="isLoadingMore" src="../../../../assets/images/loader.gif" alt="Loading" />
    </div>
  </div>
</div>

<ncoa-benefit-card-modal
  [title]="modalData.title"
  [eyebrow]="modalData.eyebrow"
  [desc]="modalData.desc"
  [cta]="modalData.cta"
  (onModalClose) = 'onModalClose()'
></ncoa-benefit-card-modal>
