import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { AssessmentService } from '@services/planner/assessment.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UtilityService } from '@services/planner/utility.service';
import { RebaDataService } from '@services/planner/reba-data.service';
import { Observable } from 'rxjs';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { take } from 'rxjs/operators';
import { SeoService } from '@services/seo.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@services/planner/user.service';
@Component({
  selector: 'tools-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Assessment implements OnInit, AfterViewInit {

  @Input() isLeadGen: boolean = false;
  @Input() assessmentID: string;
  @Input() version: number;
  @Input() fullData: any = null;
  @Input() data: any = null;
  @Output() toggleLoadingOverlay: EventEmitter<any> = new EventEmitter();
  @Output() onFinish: EventEmitter<any> = new EventEmitter();

  activeIndex: number = 0;
  lastActiveIndex: number = 0;
  errorIndex: number = -1;
  activeQuestionCount: number = 0;
  activeQuestionText: any;
  activeID: any;
  isCurrentFinancial: boolean;
  activeAnswerText: any;
  passedQuestions: number = 1;
  drugOptions: any[] = [];
  selectedDrugs: any[] = [];
  questionResponse: any[] = [];
  preSelectedDrug: any = null;
  dependentQuestions: any[] = [];
  bannerText: any;

  drugQuantity: string = '';
  drugFrequency: string = '';

  singlePageExperience: boolean;
  hasSections: boolean = false;
  passedSections: number = 1;
  activeSectionCount: number = 0;

  isMobile: boolean = false;
  canSubmit: boolean = false;
  showBackCTA: boolean = true;
  isLoggedIn: boolean = false;

  activeElementTooltip: Element;

  language$ : Observable<string> = this.toolsConfig.language$;
  selectedLanguage = localStorage.getItem('language') || 'en';
  showLanguageToogle = false;
  baseLanguageOptions = {
    en : [
      {
        value: 'en',
        label: 'English'
      },
    {
      value: 'es',
        label: 'Spanish'
      }
    ],
    es: [
      {
        value: 'en',
        label: 'Inglés'
      },
      {
        value: 'es',
        label: 'Español'
      }
    ]
    };
  languageOptions = [];
  fullBackCTA: any;
  chooseDefault = 'Choose one';
  fullChooseDefault = { en: 'Choose one', es: 'Elige uno' };

  answers = {};
  editMode = ''

  @Input() backCTA: {
    text: any;
    url: string;
  } = {
    text: 'Exit',
    url: '/',
  };

  constructor(
    private assessmentService: AssessmentService,
    private utility: UtilityService,
    private dataLayerService: DataLayerService,
    private toolsConfig: ToolsPrimaryConfigService,
    private reba: RebaDataService,
    private cas: CustomerActivityService,
    private seoService: SeoService,
    private route: ActivatedRoute,
    private user: UserService
  ) { }

  ngOnInit(): void {
    if (this.assessmentID.includes('fallsRiskAssessment')) {
      //this.showBackCTA = false;

      // NAM-440
      this.showLanguageToogle = true;
      this.selectedLanguage = this.toolsConfig.language || 'en';
      this.languageOptions = this.baseLanguageOptions[`${this.selectedLanguage}`];

      if (localStorage.getItem('language') == undefined) localStorage.setItem('language', 'en');
    } 
    else {
      this.selectedLanguage = 'en';
      localStorage.setItem('language', 'en')
    }

    this.data = this.fullData[`${this.selectedLanguage}`] || this.fullData['en']; // default

	  this.fullBackCTA = { text: { en: 'Exit', es: 'Salida' }, url: this.backCTA.url };
    this.bannerText = this.data.bannerText;

    if (this.data === undefined) this.data = this.fullData; // for lead gen component that uses assessment but does not have Spanish

    this.singlePageExperience = this.data.isSinglePageAssessment;

    function getFallsRiskAssessment() {
      //item stored from db response upon login
      const data = JSON.parse(localStorage.getItem('fallsRisk'));
      let result = [];
      if (data) {
        // format it to match the assessment response
        result = Object.values(data.answers).map(value => ({ answer: { value } }));
        // add empty for whose info.. question dropdown
        result = [{answer:{value:0}}, ...result];
      return result;
      }
    }
    this.editMode = this.route.snapshot.queryParamMap.get('edit');
    if (this.editMode  === 'true') {
      //item stored from current session completed assessment
      const storedAssessment = localStorage.getItem('fallsRiskAssessment')
      // get item from recently completed assessment first then try from db response
      this.answers = storedAssessment ? JSON.parse(storedAssessment) : getFallsRiskAssessment();
      this.data.questions.forEach((q, index) => {
        q.userAnswer = this.answers[index].answer.value;
      });
      //fall free is single page experience
      this.singlePageExperience = true;
    };

    // Check for Sections in Single Page Experience
    if(this.singlePageExperience){
      this.hasSections = this.data.sections?.length > 0;
      if(this.hasSections){
        this.activeSectionCount = this.data.sections.length;
      }
    }

    this.activeQuestionCount = this.data.questions.filter(({ condition }) => !condition).length;

    this.isMobile = window.matchMedia("only screen and (max-width: 1024px)").matches;

    this.dependentQuestions = this.data.questions.filter((dQuestion) => (
      dQuestion.condition &&
      dQuestion.condition.dependentId
    )).map(q => { return q.id });

    const requiredQuestions = this.data.questions.filter(question => {
      return question?.answerGroup?.validation?.isRequired;
    });
    this.canSubmit = !(requiredQuestions.length > 0);

    this.language$.subscribe((lang: string) => {
      if (lang) this.selectedLanguage = lang;
      if (this.fullData && this.selectedLanguage) {
        this.data = this.fullData[`${this.selectedLanguage}`] || this.fullData['en'];
        if (this.data === undefined) this.data = this.fullData; // for lead gen component that uses assessment but does not have Spanish
        this.backCTA.text = this.fullBackCTA.text[this.selectedLanguage];
        this.chooseDefault = this.fullChooseDefault[this.selectedLanguage];
        this.bannerText = this.data.bannerText;
        this.seoService.extractAndRender(this.data.assessmentData, {});
      }
    });

    this.user.isLoggedIn().subscribe((isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  onLanguageToggle(event: any) {
    const language = event.currentTarget.value;
    this.toolsConfig.setLanguage(language);

    this.cas.updateActivity({'fallsLanguage':language || 'en'}).subscribe(() => {});
    this.languageOptions = this.baseLanguageOptions[`${language}`];
  }

  goBack(e){
    if(!document.referrer){
      const lastUrl = window.sessionStorage.getItem("last_url");
      const lastUrlIndex = window.sessionStorage.getItem("last_url_index");
      if((history.length - 1) === parseInt(lastUrlIndex)){
        window.location.href = lastUrl;
      }else{
        window.location.href = this.backCTA.url;
      }
    }else{
      if (document.referrer.indexOf(window.location.host) !== -1){
        e.preventDefault();
        history.back();
      }else{
        window.location.href = this.backCTA.url;
      }
    }
  }

  ngAfterViewInit(): void {
    if (!this.singlePageExperience) {

      const progressBar: HTMLDivElement = document.querySelector('.step__progress .active');
      const progress = (this.passedQuestions / this.activeQuestionCount) * 100;
      progressBar.style.width = `${progress}%`;

      this.adjustComponentHeight(document.querySelector('.step__track > div > div'));

    } else {

      if(this.hasSections){
        const progressBar: HTMLDivElement = document.querySelector('.step__progress .active');
        const progress = (this.passedSections / this.activeSectionCount) * 100;
        progressBar.style.width = `${progress}%`;

      }
    }

    this.addToAnalytics('assessment begin');
    this.addToAnalytics('question begin');
    if (this.editMode) {
      this.canSubmit = this.data.questions.filter(q => { return q.answerGroup.validation?.isRequired && !q.disabled }).filter(q => { return q.userAnswer === null || q.userAnswer === '' || q.userAnswer.length === 0 || q.error !== null }).length === 0;
    }

  }

  async submitSinglePageAssessment() {
    if (this.assessmentID.includes('fallsRiskAssessment')) { // use English version of assessment answers
      this.data.questions = this.fullData.en.questions;
      this.assessmentID = 'fallsRiskAssessment'; // reset in case user submits in Spanish
    }

    this.questionResponse = [];
    window.scrollTo(0, 0);

    this.data.questions.forEach((q) => {
      if (q.userAnswer !== null) {
        const r = {
          answer: {
            text: `${q.userAnswer}`,
            value: q.userAnswer,
          },
          questionId: q.id,
          questionVersion: q.version,
        };
        this.activeAnswerText = q.userAnswer;
        const a = q.answerGroup.answers.find(({ value }) => value === q.userAnswer);

        if (a) {
          r.answer = a;
        } else {
          // prescription drug answer
          if (q.id === 'prescriptionDrugs') {
            r.answer = { text: 'drugSearch', value: q.userAnswer };
          }

          // multi select answer
          if (q.answerGroup.answerType === 'multiSelect') {
            r.answer = q.userAnswer;
          }

          // currency answer
          if (q.answerGroup?.validation?.isCurrency &&
            !!q.userAnswer.match(/^\$?[0-9][0-9,]*[0-9]\.[0-9]{0,2}$/) === false) {
            r.answer = {
              text: q.id,
              value: q.userAnswer != '0' ? `${q.userAnswer}.00` : q.userAnswer,
            };
          }
        }

        this.questionResponse.push(r);
      }
      else if (q.id === 'whoseInfo') { // NAM-458 fix for defaulting optional question answer
        const r = {
          answer: {
            text: `own`,
            value: `own`,
          },
          questionId: q.id,
          questionVersion: q.version,
        };
        this.activeAnswerText = q.userAnswer;

        this.questionResponse.push(r);
      }
    });

    this.toggleLoadingOverlay.emit(true);

    const slideWidth = (<HTMLDivElement> document.querySelector('.step__slide')).getBoundingClientRect().width;
    let offset = 0;

    let newIndex = this.activeIndex + 1;

    if(this.hasSections){
      if (this.passedSections !== this.activeSectionCount) {
        this.assessmentService.respondToQuestions(this.assessmentID, this.version, this.questionResponse)
        .subscribe(() => {
          this.toggleLoadingOverlay.emit(false);

          offset = (slideWidth * newIndex) * -1;

          this.passedSections++;
          this.lastActiveIndex = this.activeIndex;
          this.activeIndex = newIndex;
          const progressBar: HTMLDivElement = document.querySelector('.step__progress .active');
          const progress = (this.passedSections / this.activeSectionCount) * 100;
          progressBar.style.width = `${progress}%`;

          this.data.questions = this.data.sections[this.activeIndex].questions;
          this.canSubmit = this.data.questions.filter(q => { return q.answerGroup.validation?.isRequired && !q.disabled }).filter(q => { return q.userAnswer === null || q.userAnswer === '' || q.userAnswer.length === 0 || q.error !== null}).length === 0;

          const dependentQuestions = this.data.questions.filter((dQuestion) => (
            dQuestion.condition &&
            dQuestion.condition.dependentId
          ));

          dependentQuestions.forEach(dependentQuestion => {
            this.dependentQuestions.push(dependentQuestion.id)
          });
        });
      } else {
        // this.addToAnalytics('question save');
        // this.addToAnalytics('assessment submit');
        if (this.assessmentID === 'fallsRiskAssessment' && this.selectedLanguage === 'es') { // force es-fallsRiskAssessmentCompleted event
          await this.cas.announceEvent('es-fallsRiskAssessmentCompleted', {}, true, window.location).pipe(take(1)).toPromise();
        }

        this.canSubmit = false;
        this.assessmentService.respondToQuestions(this.assessmentID, this.version, this.questionResponse)
          .subscribe(() => {
            this.onFinish.emit(this.questionResponse);
        });
      }
    } else {
      this.canSubmit = false;
      this.assessmentService.respondToQuestions(this.assessmentID, this.version, this.questionResponse)
        .subscribe(() => {
          this.onFinish.emit(this.questionResponse);
      });
    }

  }

  async onSubmit() {
    let question = this.data.questions[this.activeIndex];

    // require validation
    if (question.answerGroup.validation && question.answerGroup.validation.hasOwnProperty('isRequired') &&
        question.answerGroup.validation.isRequired === true && ( question.userAnswer === null || question.userAnswer === '' ) ) {

      await this.setError(question, `${question.label || 'Field'} is required`, 1000);
      return;
    } else {
      question = {...question, error: null};
      this.errorIndex = -1;
    }

    // date validation
    if (question.id === 'dateOfBirth' && question.userAnswer) {

      const datePattern = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;
      const minAge = 18;
      const maxAge = 100;
      const userDate = new Date(question.userAnswer);
      const now = new Date();
      const bornBefore = new Date(new Date().setFullYear(now.getFullYear() - minAge)); // user must be at least 18 years old: current date - 18 years
      const maxDate = new Date(new Date(userDate).setFullYear(userDate.getFullYear() + maxAge)); // user must be at most 100 years old: birth date + 100 years
      if (!(question.userAnswer.match(datePattern))) {
        // check date is MM/DD/YYYY
        await this.setError(question, 'Invalid date format. Format should be MM/DD/YYYY');
        return;
      } else if (userDate > bornBefore || now > maxDate) {
        // check user's age
        await this.setError(question, `Invalid date format or value. Format should be MM/DD/YYYY and fall within age range of ${minAge} to ${maxAge}.`);
        return;
      } // else date is valid
    } else {
      question = {...question, error: null};
      this.errorIndex = -1;
    }

    // Phone Number
    if (question.answerGroup.validation &&
        question.answerGroup.validation.hasOwnProperty('isPhoneNumber') &&
        question.id === "phoneNumber" ) {

      var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      var _test = phoneNumberPattern.test( question.userAnswer );

      if( _test || !question.userAnswer) {
        question = {...question, error: null};
        this.errorIndex = -1;
      } else {
        question = {...question, error: 'Invalid Phone Input. Please provide valid format xxx-xxx-xxxx'};
        this.data.questions[this.activeIndex] = question;
        this.errorIndex = this.activeIndex;

        setTimeout(() => {
          if (this.errorIndex >= 0) {
            const slide = Array.from(document.querySelectorAll('.step__slide'))[this.errorIndex];
            if (slide) {
              const interactive: any = slide.querySelector('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');
              if (interactive) {
                interactive.focus();
              }
            }
          }
        }, 2700);

        return;
      }

    } else {
      question = {...question, error: null};
      this.errorIndex = -1;
    }

    // zip code validation
    if (question.answerGroup.validation && question.answerGroup.validation.hasOwnProperty('isZipcode') &&
        question.answerGroup.validation.isZipcode) {
      const res: any = await this.utility.getZipCode(question.userAnswer);
      if (!res || (res && res.zipCode === '')) {
        question = {...question, error: 'Invalid Zip Code'};
        this.data.questions[this.activeIndex] = question;
        this.errorIndex = this.activeIndex;

        setTimeout(() => {
          if (this.errorIndex >= 0) {
            const slide = Array.from(document.querySelectorAll('.step__slide'))[this.errorIndex];
            if (slide) {
              const interactive: any = slide.querySelector('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');
              if (interactive) {
                interactive.focus();
              }
            }
          }
        }, 1000);

        return;
      } else {
        this.errorIndex = -1;
      }
    } else {
      question = {...question, error: null};
      this.errorIndex = -1;
    }

    // currency validation
    if (question.answerGroup.validation && question.answerGroup.validation.hasOwnProperty('isCurrency') && question.answerGroup.validation.isCurrency === true) {
      if(question.answerGroup.validation.hasOwnProperty('isRequired') &&
          question.answerGroup.validation.isRequired === true &&
          !(/^[0-9]*[.]?[0-9]*$/.test(question.userAnswer))) {
          question = {...question, error: `Field must be a valid amount`};
          this.data.questions[this.activeIndex] = question;
          this.errorIndex = this.activeIndex;

          setTimeout(() => {
            if (this.errorIndex >= 0) {
              const slide = Array.from(document.querySelectorAll('.step__slide'))[this.errorIndex];
              if (slide) {
                const interactive: any = slide.querySelector('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');
                if (interactive) {
                  interactive.focus();
                }
              }
            }
          }, 1000);

          return;
      } else if (question.userAnswer && !(/^[0-9]*[.]?[0-9]*$/.test(question.userAnswer))) {
          question = {...question, error: `Field must be a valid amount`};
          this.data.questions[this.activeIndex] = question;
          this.errorIndex = this.activeIndex;

          setTimeout(() => {
            if (this.errorIndex >= 0) {
              const slide = Array.from(document.querySelectorAll('.step__slide'))[this.errorIndex];
              if (slide) {
                const interactive: any = slide.querySelector('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');
                if (interactive) {
                  interactive.focus();
                }
              }
            }
          }, 1000);
          return;
      } else {
        question = {...question, error: null};
        this.errorIndex = -1;
      }
    }

    if (question.answerGroup.validation && question.answerGroup.validation.hasOwnProperty('isEmail') &&
        question.answerGroup.validation.isEmail === true &&
        question.userAnswer && !(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(question.userAnswer))) {
      question = {...question, error: 'Invalid Email'};
      this.data.questions[this.activeIndex] = question;
      this.errorIndex = this.activeIndex;

      setTimeout(() => {
        if (this.errorIndex >= 0) {
          const slide = Array.from(document.querySelectorAll('.step__slide'))[this.errorIndex];
          if (slide) {
            const interactive: any = slide.querySelector('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');
            if (interactive) {
              interactive.focus();
            }
          }
        }
      }, 1000);

      return;
    } else {
      question = {...question, error: null};
      this.errorIndex = -1;
    }

    const dependentQuestions = this.data.questions.filter((dQuestion) => (
      dQuestion.condition &&
      dQuestion.condition.dependentId &&
      dQuestion.condition.dependentId === question.id
    ));

    if (dependentQuestions.length > 0) {
      dependentQuestions.forEach((dQuestion) => {
        const { index, condition } = dQuestion;
        const { value, operator } = condition;

        let disable = false;

        switch (operator) {
          case '=': {
            disable = !(question.userAnswer === value);
            break;
          }

          case '!=': {
            let userAnswer = question.userAnswer;
            if(userAnswer === null) { // make case for null instance
              userAnswer = '';
            }
            disable = userAnswer === value;
            break;
          }

          case 'in': {
            //disable = !Array(...question.userAnswer).map(a => { return a.value || a }).includes(value);
            if (question.answerGroup.answerType === 'multiSelect') disable = !Array(...question.userAnswer).map(a => { return a.value || a }).includes(value);
            else disable = !Array(...value).includes(question.userAnswer);
            break;
          }
        }

        this.data.questions[index].disabled = disable;
      });

      this.activeQuestionCount = this.data.questions.filter(({ disabled }) => !disabled).length;
    }

    const slideWidth = (<HTMLDivElement> document.querySelector('.step__slide')).getBoundingClientRect().width;
    let offset = 0;

    let newIndex = this.activeIndex + 1;
    while (this.data.questions[newIndex] && this.data.questions[newIndex].disabled) {
      newIndex++;
    }

    this.questionResponse = [];
    this.data.questions.forEach((q) => {
      if (q.userAnswer !== null) {
        const r = {
          answer: {
            text: q.userAnswer,
            value: q.userAnswer,
          },
          questionId: q.id,
          questionVersion: q.version,
        };
        this.activeAnswerText = q.userAnswer;
        const a = q.answerGroup.answers.find(({ value }) => value === q.userAnswer);

        if (a) {
          r.answer = a;
        } else {
          // prescription drug answer
          if (q.id === 'prescriptionDrugs') {
            r.answer = { text: 'drugSearch', value: q.userAnswer };
          }

          // multi select answer
          if (q.answerGroup.answerType === 'multiSelect') {
            r.answer = q.userAnswer;
          }

          // currency answer
          if (q.answerGroup?.validation?.isCurrency &&
            !!q.userAnswer.match(/^\$?[0-9][0-9,]*[0-9]\.[0-9]{0,2}$/) === false) {
            r.answer = {
              text: q.id,
              value: q.userAnswer != '0' ? `${q.userAnswer}.00` : q.userAnswer,
            };
          }
        }

        this.questionResponse.push(r);
      }
    });

    this.toggleLoadingOverlay.emit(true);

    if (this.passedQuestions !== this.activeQuestionCount) {
      this.assessmentService.respondToQuestions(this.assessmentID, this.version, this.questionResponse)
      .subscribe(() => {
        this.toggleLoadingOverlay.emit(false);

        offset = (slideWidth * newIndex) * -1;

        let newSlide: any = Array.from(document.querySelectorAll('.step__slide'))[newIndex];
        newSlide = newSlide.querySelector('.slide-wrapper');
        newSlide.style.visibility = 'visible';

        const track: HTMLDivElement = document.querySelector('.step__track');
              track.style.transform = `translateX(${offset}px)`;

        let prevSlide: any = Array.from(document.querySelectorAll('.step__slide'))[this.activeIndex];
            prevSlide = prevSlide.querySelector('.slide-wrapper');

        this.adjustComponentHeight(newSlide);

        this.passedQuestions++;
        this.lastActiveIndex = this.activeIndex;
        this.activeIndex = newIndex;
        const progressBar: HTMLDivElement = document.querySelector('.step__progress .active');
        const progress = (this.passedQuestions / this.activeQuestionCount) * 100;
        progressBar.style.width = `${progress}%`;

        this.addToAnalytics('question save');
        this.addToAnalytics('question begin');

        setTimeout(() => {
          prevSlide.style.visibility = 'hidden';

          const interactive = newSlide.querySelector('.question');
          if (interactive) {
            interactive.focus();
          }
        }, 300);
      });
    } else {
      this.addToAnalytics('question save');
      this.addToAnalytics('assessment submit');
      this.onFinish.emit(this.questionResponse);
    }
  }

  async setError(question: any, errorMessage: string, timeout: number = 2700) {
    question = {...question, error: errorMessage};
    this.data.questions[this.activeIndex] = question;
    this.errorIndex = this.activeIndex;

    return new Promise<void>(resolve => {
      setTimeout(() => {
        if (this.errorIndex >= 0) {
          const slide = Array.from(document.querySelectorAll('.step__slide'))[this.errorIndex];
          if (slide) {
            const interactive: any = slide.querySelector('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');
            if (interactive) {
              interactive.focus();
            }
          }
        }
        resolve();
      }, timeout);
    });
  }

  adjustComponentHeight(newSlide: HTMLDivElement) {
    if(newSlide.offsetHeight){
      const stepContainer = document.querySelector('.step__container') as HTMLDivElement;
      if(stepContainer){
        const isMobileView = window.matchMedia("all and (max-width: 767px)").matches;
        stepContainer.style.height = `${newSlide.offsetHeight + 100}px`
        document.querySelectorAll('.step__slide').forEach((item :HTMLDivElement) =>{
          if(newSlide.parentElement.classList.contains('prescriptionDrugGroup')){
            if(isMobileView)
              {
                stepContainer.style.height = `660px`
                item.style.height = `660px`
                item.style.overflowY = `scroll`
              }
            else{
              stepContainer.style.height = `600px`
              item.style.height = `600px`
              item.style.overflowY = `scroll`
            }
          }
          else{
            item.style.height = `${newSlide.offsetHeight + 100}px`
          }
        })
      }
    }
  }

  toggleCheck(index, option) {
    const question = this.data.questions[index];
    const { answerGroup } = question;
    const { answers } = answerGroup;

    const flagNoneIndex = answers.findIndex((answer) => answer.value === 'none');
    if (flagNoneIndex !== -1) {
      // if the user selected an answer besides none
      // uncheck none
      if (option.isSelected && option.value !== 'none') {
        this.data.questions[index].answerGroup.answers[flagNoneIndex].isSelected = false;
      }
      // if the user selected none
      // uncheck all except none
      if (option.isSelected && option.value === 'none') {
        this.data.questions[index].answerGroup.answers.forEach((loopData, loopIndex) => {
          if (loopData.value !== 'none') {
            this.data.questions[index].answerGroup.answers[loopIndex].isSelected = false;
          }
        });
      }
    }

    const selectedAnswers = answers.filter(({ isSelected }) => isSelected).map(({ text, value }) => ({ text, value }));
    this.data.questions[index] = {
      ...question,
      userAnswer: selectedAnswers,
    };

    if(this.singlePageExperience){
      this.canSubmit = this.data.questions.filter(q => { return q.answerGroup.validation?.isRequired && !q.disabled }).filter(q => { return q.userAnswer === null || q.userAnswer === '' || q.userAnswer.length === 0 || q.error !== null}).length === 0;
    }
  }

  onShortTextChange(questionIndex, id, event) {
    if (id === 'dateOfBirth') {
      const userAnswer = this.data.questions[questionIndex].userAnswer;

      let error;

      if (userAnswer && userAnswer !== '') {
        // date validation
        const datePattern = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;
        const minAge = 18;
        const maxAge = 100;
        const userDate = new Date(userAnswer);
        const now = new Date();
        const bornBefore = new Date(new Date().setFullYear(now.getFullYear() - minAge)); // user must be at least 18 years old: current date - 18 years
        const maxDate = new Date(new Date(userDate).setFullYear(userDate.getFullYear() + maxAge)); // user must be at most 100 years old: birth date + 100 years
        if (!(userAnswer.match(datePattern))) {
          // check date is MM/DD/YYYY
          error = 'Invalid date format. Format should be MM/DD/YYYY';
          return;
        } else if (userDate > bornBefore || now > maxDate) {
          // check user's age
          error = `Invalid date format or value. Format should be MM/DD/YYYY and fall within age range of ${minAge} to ${maxAge}.`;
          return;
        } // else date is valid
      } else {
        error = 'Field is required';
      }
      this.data.questions[questionIndex].error = error;
    }

    this.onChangeValidation(event, questionIndex);

  }

  onPrevious(): void {

    let newIndex = this.activeIndex - 1;

    if(this.hasSections && this.singlePageExperience){

      this.passedSections--;

      this.data.questions = this.data.sections[newIndex].questions;

    } else {
      this.passedQuestions--;

      let previousQuestion = this.data.questions[newIndex];

      while (previousQuestion.disabled) {
        newIndex = newIndex - 1;
        previousQuestion = this.data.questions[newIndex];
      }
    }

    if(!this.singlePageExperience){
      const slideWidth = (<HTMLDivElement> document.querySelector('.step__slide')).getBoundingClientRect().width;
      const offset = (slideWidth * newIndex) * -1;

      let newSlide: any = Array.from(document.querySelectorAll('.step__slide'))[newIndex];
          newSlide = newSlide.querySelector('.slide-wrapper');
          newSlide.style.visibility = 'visible';

      const track: HTMLDivElement = document.querySelector('.step__track');
            track.style.transform = `translateX(${offset}px)`;

      let prevSlide: any = Array.from(document.querySelectorAll('.step__slide'))[this.activeIndex];
          prevSlide = prevSlide.querySelector('.slide-wrapper');

      this.adjustComponentHeight(newSlide);

      setTimeout(() => {
        prevSlide.style.visibility = 'hidden';

        const drugInput: HTMLInputElement = document.querySelector('.drug-input');
        if (drugInput && drugInput.value.trim().length > 0) {
          drugInput.value = '';
          this.drugOptions = [];
        }
      }, 300);
    } else {
      window.scrollTo(0, 0);
    }


    this.lastActiveIndex = this.activeIndex;
    this.activeIndex = newIndex;
    this.canSubmit = this.data.questions.filter(q => { return q.answerGroup.validation?.isRequired && !q.disabled }).filter(q => { return q.userAnswer === null || q.userAnswer === '' || q.userAnswer.length === 0 || q.error !== null}).length === 0;

    const progressBar: HTMLDivElement = document.querySelector('.step__progress .active');

    if(this.hasSections && this.singlePageExperience){
      const progress = (this.passedSections / this.activeSectionCount) * 100;
      progressBar.style.width = `${progress}%`;
    } else {
      const progress = (this.passedQuestions / this.activeQuestionCount) * 100;
      progressBar.style.width = `${progress}%`;
    }
  }

  toString(str: string){
    return str.toString();
  }

  onChangeValidation($event, index) {
    if (this.assessmentID.includes('fallsRiskAssessment')) { // update both lang variants of answers
      this.fullData.en.questions[index].userAnswer = $event;
      this.fullData.es.questions[index].userAnswer = $event;
    }

    if ($event !== '') {
      this.data.questions[index].error = null;
    }

    if (this.singlePageExperience) {

      this.canSubmit = this.data.questions.filter(q => { return q.answerGroup.validation?.isRequired && !q.disabled }).filter(q => { return q.userAnswer === null || q.userAnswer === '' || q.userAnswer.length === 0 || q.error !== null}).length === 0;

      // let question = this.data.questions[index];
      // Zip Code Validation
      // if (question.answerGroup.validation && question.answerGroup.validation.hasOwnProperty('isZipcode') &&
      //     question.answerGroup.validation.isZipcode && $event?.length === 5) {
      //       this.onBlur($event, index);
      // }

    }

    // if (this.singlePageExperience) {
    //   let question = this.data.questions[index];
    //   if (question.answerGroup.validation && question.answerGroup.validation.hasOwnProperty('isZipcode') &&
    //       question.answerGroup.validation.isZipcode && $event?.length === 5) {
    //     this.utility.getZipCode(question.userAnswer).then(res => {
    //       if (!res || (res && res['zipCode'] === '')) {
    //         question = {...question, error: 'Invalid Zip Code'};
    //         this.data.questions[index] = question;

    //         console.log(question);
    //         console.log(this.data.questions);
    //         // return;
    //       } else {
    //         this.errorIndex = -1;
    //       }
    //     });
    //   }
    // }
  }

  checkShowQuestion(dependentQuestion) {

    if (this.dependentQuestions.includes(dependentQuestion.id)) {
      let hideQuestion = false;
      const operator = dependentQuestion.condition.operator;
      const value = dependentQuestion.condition.value;
      const question = this.data.questions.find(q => { return q.id === dependentQuestion.condition.dependentId; });

      switch (operator) {
        case '=': {
          hideQuestion = (question.userAnswer === value);
          break;
        }
        case '!=': {
          let userAnswer = question.userAnswer;
          if(userAnswer === null) { // make case for null instance
            userAnswer = '';
          }
          hideQuestion = userAnswer !== value;
          break;
        }
        case 'in': {
          if (question.answerGroup.answerType === 'multiSelect' && question.userAnswer) hideQuestion = Array(...question.userAnswer).map(a => { return a.value || a }).includes(value);
          else hideQuestion = Array(...value).includes(question.userAnswer);
          break;
        }
      }
      this.data.questions[dependentQuestion.index].disabled = !hideQuestion;
      this.canSubmit = this.data.questions.filter(q => { return q.answerGroup.validation?.isRequired && !q.disabled }).filter(q => { return q.userAnswer === null || q.userAnswer === '' || q.userAnswer.length === 0 || q.error !== null}).length === 0;
      return hideQuestion;
    }

    return true;
  }

  onBlur($event, index): void {
    let question = this.data.questions[index];
    const userAnswer = this.data.questions[index].userAnswer;
    if(!userAnswer || userAnswer === ''){

      if(question.answerGroup.validation.isRequired){
        this.data.questions[index].error = 'Field is required.';
        return
      } else {
        this.data.questions[index].error = null;
        return
      }

    }

    if (question.answerGroup.validation && question.answerGroup.validation.hasOwnProperty('isZipcode') &&
        question.answerGroup.validation.isZipcode) {
        //check if zip code is valid
      const zipCodeRegex = /^\d{5}$/;
      if (question.userAnswer.toString().match(zipCodeRegex) == null) {
        question = {...question, error: 'Invalid Zip Code'};
        this.data.questions[index] = question;
      } else {
      this.utility.getZipCode(question.userAnswer).then(res => {
        if (!res || (res && res['zipCode'] === '')) {
          question = {...question, error: 'Invalid Zip Code'};
          this.data.questions[index] = question;
        } else {
          this.data.questions[index].userAnswer = res['zipCode'] ?? question.userAnswer;
        }
      })
      }
    } else if (question.id === 'dateOfBirth') {

      // validate date is valid, not more than 100 years ago, not less than 18 years ago
      // const dateRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
      const dateRegex = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;
      if (question.userAnswer.toString().match(dateRegex) == null || question.userAnswer.length < 10) {
        question = {...question, error: 'Invalid date format or value. Format should be MM/DD/YYYY'};
        this.data.questions[index] = question;
      } else {
        const d = new Date(question.userAnswer);
        const now = new Date();
        const tooYoung = d > new Date(new Date().setFullYear(now.getFullYear() - 18));
        const tooOld = now > new Date(new Date(d).setFullYear(d.getFullYear() + 100));
        if (tooYoung || tooOld) {
          question = {...question, error: 'Age range must fall between 18 to 100'};
          this.data.questions[index] = question;
        } else {
          this.data.questions[index].userAnswer = question.userAnswer;
        }
      }
    } else if (question.id === 'email') {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (question.userAnswer.toString().toLowerCase().match(emailRegex) == null) {
        question = {...question, error: 'Please enter a valid email address'};
        this.data.questions[index] = question;
      } else {
        this.data.questions[index].userAnswer = question.userAnswer;
      }
    } else if (question.id === 'phoneNumber') {
      const phoneRegex = /^\d{10}$/;
      if (question.userAnswer?.length > 0 && question.userAnswer.toString().replace(/[^\w]|[\s]/gi, '').match(phoneRegex) == null) {
        question = {...question, error: 'Please enter a valid phone number'};
        this.data.questions[index] = question;
      } else {
        this.data.questions[index].userAnswer = question.userAnswer;
      }
    }
  }

  onDrugKeyUp(e): void {
    if (e.target.value.trim().length >= 4) {
      this.assessmentService.searchDrug(e.target.value)
        .then((res) => {
          this.drugOptions = res;
        });
    } else if (e.target.value.trim().length === 0) {
      this.drugOptions = [];
    }
  }

  clickDrug(data): void {
    this.preSelectedDrug = {
      form: data.form,
      frequency: null,
      name: data.name,
      ndc: data.ndc,
      quantity: null,
      strength: data.strength,
    };

    this.drugOptions = [];
  }

  clickAddDrug(qIndex?: any): void {
    this.preSelectedDrug.frequency = this.drugFrequency;
    this.preSelectedDrug.quantity = this.drugQuantity;

    this.drugFrequency = '';
    this.drugQuantity = '';

    this.drugOptions = [];

    this.selectedDrugs.push(this.preSelectedDrug);

    this.preSelectedDrug = null;

    let questionIndex = this.singlePageExperience ? qIndex : this.activeIndex;
    this.data.questions[questionIndex].userAnswer = this.selectedDrugs;

    this.canSubmit = this.data.questions.filter(q => { return q.answerGroup.validation?.isRequired && !q.disabled }).filter(q => { return q.userAnswer === null || q.userAnswer === '' || q.userAnswer.length === 0 || q.error !== null}).length === 0;

  }

  clearSelectedDrug(index, qIndex?: any): void {
    this.selectedDrugs.splice(index, 1);

    let questionIndex = this.singlePageExperience ? qIndex : this.activeIndex;

    if (this.selectedDrugs.length === 0) {
      this.data.questions[questionIndex].userAnswer = null;
    } else {
      this.data.questions[questionIndex].userAnswer = this.selectedDrugs;
    }

    this.canSubmit = this.data.questions.filter(q => { return q.answerGroup.validation?.isRequired && !q.disabled }).filter(q => { return q.userAnswer === null || q.userAnswer === '' || q.userAnswer.length === 0 || q.error !== null}).length === 0;

  }

  clearPreSelectedDrug(): void {
    this.preSelectedDrug = null;

    this.drugOptions = [];
  }

  addToAnalytics(formStatus){
    let index = 0;
    let needAnswer = true;
    if(formStatus === 'assessment begin' || formStatus === 'question begin'){
      needAnswer = false;
    }
    if(formStatus === 'question save'){
      index = this.lastActiveIndex;
    }else{
      index = this.activeIndex
    }
    this.dataLayerService.push({
      'event': 'assessment',
      'formStatus': formStatus,
      'assessment': this.assessmentID,
      'questionNumber': index + 1,
      'question': this.getActiveQuestionIndex(index),
      'answer': this.checkIfPPI(needAnswer)
    });
      this.activeQuestionText = '';
  }

  getActiveQuestionIndex(index){
    this.getActiveQuestionID(index);
    this.isCurrentQuestionFinancial(index);
    let question = this.data.questions[index];
    this.activeQuestionText = question.text || question.label;
    return this.activeQuestionText;
  }

  getActiveQuestionID(index){
    let question = this.data.questions[index];
    this.activeID = question.id;
  }

  isCurrentQuestionFinancial(index){
    let question = this.data.questions[index];
    if(this.assessmentID === 'retirementIncomeBreakdown' && typeof question?.answerGroup?.validation?.isCurrency === 'boolean'){
      this.isCurrentFinancial = true;
    }else{
      this.isCurrentFinancial = false;
    }
  }

  processAnswer(isNeedAnswer){
    if(isNeedAnswer){
      let answers: any = '';
      if(Array.isArray(this.activeAnswerText)){
        let answersArray = [];
        this.activeAnswerText.forEach(ans =>{
          if('text' in ans){
            answersArray.push(ans.text);
          }else if('form' in ans || 'name' in ans){//pres drug
            answersArray.push(ans.name + " - " + ans.strength + " " + ans.form);
          }
        })
        answers = answersArray.join(', ');
      }else{
        if(this.activeAnswerText !== undefined || this.activeAnswerText !== null)
            answers = this.activeAnswerText;
      }

      return answers;
    }else{
      return '';
    }
  }

  checkIfPPI(answer: any){
    if(['zipCode','dateOfBirth','email','firstName','lastName'].includes(this.activeID) || this.isCurrentFinancial){
      if(!answer){
        return ''
      } else{
        if(this.processAnswer(answer) !== '')
          return this.activeID + ' entered';
        else return ''
      }
    }else
      {
        return this.processAnswer(answer)
      }
  }

  onAnswer(question: any, answer: any) {
    if (this.assessmentID.includes('fallsRiskAssessment')) { // update both lang variants of answers
      this.fullData.en.questions[question.index].userAnswer = answer ?? question.userAnswer;
      this.fullData.es.questions[question.index].userAnswer = answer ?? question.userAnswer;
    }

    this.data.questions[question.index].userAnswer = answer ?? question.userAnswer;
    //question.answered = true; // to change background color
    this.canSubmit = this.data.questions.filter(q => { return q.answerGroup.validation?.isRequired && !q.disabled }).filter(q => { return q.userAnswer === null || q.userAnswer === '' || q.userAnswer.length === 0 || q.error !== null}).length === 0;
  }

  toggleEdCard( event: Event ): void {
    let _toggleTrigger = event.currentTarget as Element | any;
    let _arrow = _toggleTrigger.children[1];
    let _parentContainer = _toggleTrigger;
    let _childElement = _parentContainer.children[2];
    _parentContainer.classList.toggle("is-active");
    _parentContainer.classList.toggle("open-panel");
    _childElement.classList.toggle("expand-info");
    _arrow.classList.toggle("open");


    // let _toggleTrigger = event.currentTarget as Element | any;
    // let _parentContainer = _toggleTrigger.parentElement;
    // let _arrow = _parentContainer.children[1];

    // _toggleTrigger.classList.toggle("info-list-hide");
    // _parentContainer.classList.toggle("expand-info");
    // _arrow.classList.toggle("open");
  }

  openTooltip( event: Event ): void {
    let _currentTarget = event.currentTarget as Element | any;
    _currentTarget.classList.add('is-open');
    this.activeElementTooltip = _currentTarget;
    this.fillCurrentTooltip(true);
    event.stopPropagation();
  }

  closeTooltip( event: Event ): void {
    let _currentTarget = event.currentTarget as Element;

    _currentTarget.parentElement.parentElement.classList.remove('is-open');

    this.fillCurrentTooltip(false);
    event.stopPropagation();
  }

  fillCurrentTooltip(toFill) {
    if (toFill) {
      let _temp = document.querySelectorAll(`icon-is-active`);
      _temp.forEach(i => {
        i.classList.remove('icon-is-active');
      });
      this.activeElementTooltip.classList.add('icon-is-active');
    }
    else {
      this.activeElementTooltip.classList.remove('icon-is-active');
    }
  }
}
