<div *ngIf='isOpen' [ngClass]='{"isOpen": isOpen, "user-prompt-modal":true}'>
  <div class="user-prompt-modal__wrapper">
    <h2 class='title'>{{title}}</h2>
    <div class='description' [innerHTML] = 'desc'></div>
    <div class='cta-container'>
      <a class="btn btn-sign-in" [href]='cta.first.url'>{{cta.first.label}}</a>
      <a class="btn btn-sign-up" [href]='cta.second.url'>{{cta.second.label}}</a>
    </div>
    <div class="image-placeholder">
      <img class="image-placeholder__img" [src]='imageUrl' alt='Modal Image'
      aria-hidden="true">
      </div>
    <a class="closeBtn" aria-label="Close Button" role="button" (click)='closeModal()'>
      <svg width="40" height="49" viewBox="0 0 40 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.5927 41.158L9.06671 40.304C8.74471 41.774 7.61071 42.628 6.11271 42.628C4.15271 42.628 2.78071 41.172 2.78071 38.89C2.78071 36.622 4.13871 35.166 6.11271 35.166C7.59671 35.166 8.74471 36.02 9.06671 37.504L10.5927 36.636C9.94871 34.774 8.22671 33.626 6.12671 33.626C3.17271 33.626 1.03071 35.81 1.03071 38.904C1.03071 41.998 3.14471 44.168 6.05671 44.168C8.15671 44.168 9.94871 43.02 10.5927 41.158ZM13.6521 44V33.626H12.0841V44H13.6521ZM22.8961 39.996C22.8961 37.49 21.3141 35.824 18.9481 35.824C16.5961 35.824 15.0001 37.49 15.0001 39.996C15.0001 42.502 16.5961 44.168 18.9481 44.168C21.3141 44.168 22.8961 42.502 22.8961 39.996ZM16.6101 39.996C16.6101 38.316 17.5761 37.21 18.9481 37.21C20.3341 37.21 21.3001 38.316 21.3001 39.996C21.3001 41.662 20.3341 42.782 18.9481 42.782C17.5761 42.782 16.6101 41.662 16.6101 39.996ZM30.4821 41.676C30.4821 38.708 25.6661 39.59 25.6661 38.064C25.6661 37.518 26.2961 37.168 27.0941 37.168C28.1021 37.168 28.8301 37.7 28.9141 38.512L30.3841 37.854C30.0061 36.65 28.7461 35.852 27.1221 35.852C25.3161 35.852 24.0981 36.762 24.0981 38.162C24.0981 41.074 28.8861 40.164 28.8861 41.802C28.8861 42.404 28.2281 42.852 27.3041 42.852C26.1841 42.852 25.2321 42.18 25.0921 41.186L23.6921 41.83C24.1401 43.342 25.4421 44.168 27.3041 44.168C29.1941 44.168 30.4821 43.202 30.4821 41.676ZM38.8833 41.788L37.4833 41.172C37.2313 42.208 36.4613 42.81 35.3833 42.81C34.0113 42.81 33.0313 41.816 33.0173 40.402H39.0793C39.2613 37.602 37.7913 35.824 35.3133 35.824C32.9333 35.824 31.3653 37.49 31.3653 39.996C31.3653 42.502 33.0033 44.168 35.3693 44.168C37.0493 44.168 38.3233 43.3 38.8833 41.788ZM35.3133 37.168C36.4893 37.168 37.3293 37.938 37.4413 39.128H33.0733C33.1993 37.952 34.0813 37.168 35.3133 37.168Z"
          fill="#0B4A5D" />
        <path
          d="M29.8921 3.32357C30.504 2.71172 30.504 1.71971 29.8921 1.10786C29.2803 0.496005 28.2883 0.496004 27.6764 1.10786L20 8.78429L12.3236 1.10786C11.7117 0.496005 10.7197 0.496005 10.1079 1.10786C9.496 1.71971 9.496 2.71172 10.1079 3.32357L17.7843 11L10.1079 18.6764C9.496 19.2883 9.496 20.2803 10.1079 20.8921C10.7197 21.504 11.7117 21.504 12.3236 20.8921L20 13.2157L27.6764 20.8921C28.2883 21.504 29.2803 21.504 29.8921 20.8921C30.504 20.2803 30.504 19.2883 29.8921 18.6764L22.2157 11L29.8921 3.32357Z"
          fill="#0B4A5D" />
      </svg>
    </a>
  </div>
