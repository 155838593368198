import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {


  @Input() url: string;

  @Input() cta = 'Back'; // TODO: make default text dependent on selected application language e.g. EN/ES
  constructor(
    private location: Location,
    private router: Router,
  ) { 
  }

  ngOnInit(): void {
   }

  goBack(): void {
    if(this.url) {this.router.navigateByUrl(this.url);}
    //if location history is less than 3, only the current page is saved in history
    else if(this.location.getState()['navigationId'] < 3){
      //this button only appears on urls with query strings
      const path = this.location.path().split('?')
      this.router.navigate([path[0]])
    }
    else{
      this.location.back();
    }


  }

}
