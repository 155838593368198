<div class="partner-block">

    <div *ngIf="groupHeader" class="partner-block__header">{{groupHeader}} </div>

        <ng-container *ngFor="let partner of partners">
            <div class="cards">
            <div class="card-header"> 
                <div class="card-header__badge" > <span class="badge"  [ngClass]="color"> {{badgeText}} </span>  </div>
                <div class="card-header__logo">  
                    <img src="{{partner.logo}}" alt="{{partner.name}}">   
                </div>
            </div>

            <div class="card-body">
                <div class="card-body__partner"> {{partner.name}} </div>
                <div class="card-body__text" [innerHTML]="partner.description"> </div>
            </div>
            <div class="card-footer">
                <a [href]="partner.url" style="text-decoration: none;">
                    <div class="card-footer__button hoverable"  [ngClass]="color"> {{partner.cta}}</div>
                </a>
            </div>
        </div>

        </ng-container>



</div>