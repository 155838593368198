export const locale = "en_US";
export const type = "website";
export const title = "Age Well America | Improving the Lives of Older Adults";
export const description = "Age Well America, a division of the Nation Council on Aging, is a respected national leader and trusted partner to help people aged 60+ meet the challenges of aging. Explore everything NCOA has to offer for professionals, older adults, caregivers, and advocates.";
export const url = "https://www.agewellamerica.org/";
export const site_name = "Age Well America";
export const image = "/assets/images/temporary-page-image.png"; // To be updated
export const image_width = "1200";
export const image_height = "628";

export const facebook = 'https://www.facebook.com/NCOAging/';
export const twitter = 'https://twitter.com/NCOAging?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor';
export const instagram = '';
export const linkedin = 'https://www.linkedin.com/company/national-council-on-aging';
export const pinterest = ' https://www.pinterest.com/ncoaging/boards/';
export const youtube = 'https://www.youtube.com/user/ncoaging';
