<div class="component home-hero home-hero--{{ theme }}">
  <div class="home-hero__content">
    <div class="home-hero__background" style="background: none;" aria-hidden="true"></div>

    <div class="home-hero__body" data-aos="fade-up" data-aos-delay="300" data-aos-offset="0">
      <h1 class="home-hero__title home-hero__title--{{titleScale}}">{{ title }}</h1>
      <a href="{{ cta.url }}" [target]="cta.newTab ? '_blank': ''" class="home-hero__cta">{{ cta.text || 'Read Article' }}</a>
    </div>

    <div class="home-hero__image">
      <img *ngIf="!image.responsive" src="{{ image.url }}" alt="{{ image.alt }}">
      <picture *ngIf="image.responsive">
        <source *ngFor="let item of image.responsive" [srcset]="item.url" [media]="item.media" />
        <img [src]="image.responsive[0].url" [alt]="image.alt" [width]="image.width" [height]="image.height"/>
      </picture>
    </div>
  </div>
</div>
