import { Component, HostListener, Input, OnInit } from '@angular/core';
import { CustomerContactService } from '@services/planner/customer-contact.service';
import { stateOptions } from '../../../components/planner/benefits-listing/benefits-listing.variables';
import { UtilityService } from '@services/planner/utility.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-contact-form-page-planner',
  templateUrl: './contact-form-page.component.html',
  styleUrls: ['./contact-form-page.component.scss']
})
export class ContactFormPageComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth <= 767){
        //mobile
        this.label.comment = 'Message';
    }else{
      this.label.comment = "Your Question or Comment"
    }
  }

  showLoadingOverlay = false;
  @Input() label = {
    headerTitle: 'Contact Us',
    exit: 'Exit',
    insTitle: 'Send us a message.',
    insSubtitle: 'Have a question about aging? Let us know below.',
    successInsTitle:  "Thank You! Your Message was received.",
    successInsSubtitle: " ",
    email: 'Email Address *',
    selectState: 'Select State',
    comment: 'Your Question or Comment',
    submit: 'Submit'
  }

  backCTA = {
    url: ''
  }

  isSuccessfullySent = false;

  stateOptions: any[] = stateOptions;
  selectedState: string = '';
  email: {
    value: string;
    status: {
      code: string;
      message: string;
    };
  } = {
      value: '',
      status: {
        code: '',
        message: '',
      },
    };


  comment: {
    value: string;
    status: {
      code: string;
      message: string;
    };
  } = {
      value: '',
      status: {
        code: '',
        message: '',
      },
    };

  constructor(private customerContactService: CustomerContactService,
    private utilityService: UtilityService,
    private toolsConfig: ToolsPrimaryConfigService,
    private dataLayerService: DataLayerService,) {
      this.backCTA = {
        url: '/',
      }
     }

  ngOnInit(): void {

  }

  goBack(e){
    if(!document.referrer){
      const lastUrl = window.sessionStorage.getItem("last_url");
      const lastUrlIndex = window.sessionStorage.getItem("last_url_index");
      if((history.length - 1) === parseInt(lastUrlIndex)){
        window.location.href = lastUrl;
      }else{
        window.location.href = this.backCTA.url;
      }
    }else{
      if (document.referrer.indexOf(window.location.host) !== -1){
        e.preventDefault();
        history.back();
      }else{
        window.location.href = this.backCTA.url;
      }
    }
  }

  ngAfterContentInit(){
    if(window.screen.width <= 767){
      this.label.comment = 'Message';
    }
  }

  onStateChange() {

  }

  onSubmitClick() {
    this.showLoadingOverlay = true;
    let isFormValid = false;
    //Email Validation
    if (this.email.value.trim().length === 0 ||
        (this.email.value.trim().length > 0 &&
        !this.utilityService.isEmailValid(this.email.value))) {
          this.showLoadingOverlay = false;
          const elEmail: HTMLInputElement = document.querySelector('._input-email');
          this.email = {
            ...this.email,
            status: {
              code: '',
              message: '',
            },
         };
          elEmail.focus();
          setTimeout(()=>{
            this.email = {
              ...this.email,
              status: {
                code: 'error',
                message: 'Please enter a valid email address',
              },
            };
          },100)
    } else if (this.email.value.trim().length > 0 &&
        this.utilityService.isEmailValid(this.email.value)) {
          isFormValid = true;
          this.email = {
            ...this.email,
            status: {
              code: '',
              message: '',
            },
         };
    }
    //when form is valid
    if(isFormValid)
      this.submitFeedbackToAPI();

  }

  submitFeedbackToAPI() {
    // submission
    this.customerContactService.sendFeedback(this.email.value, this.comment.value, this.selectedState).subscribe(() => {
      this.showSuccessMessage();
      this.showLoadingOverlay = false;

      this.dataLayerService.push({
        'event': 'contactForm',
        'formStatus': 'success' ,
        clickUrl: location.href,
        pageTitle: document.title || '',
        contentType: 'contact-form',
        pageType: 'contact-form-page',
      },false);
    }, (error) => {
      console.log(error);

      this.showLoadingOverlay = false;

      this.dataLayerService.push({
        'event': 'contactForm',
        'formStatus': 'fail',
        clickUrl: location.href,
        pageTitle: document.title || '',
        contentType: 'contact-form',
        pageType: 'contact-form-page',
      },false);
    })
  }

  showSuccessMessage() {
    this.isSuccessfullySent = true;
    this.label.insTitle = this.label.successInsTitle;
    this.label.insSubtitle  = this.label.successInsSubtitle;
  }
}
