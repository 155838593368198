import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'ncoa-edit-account-info',
  templateUrl: './edit-account-info.component.html',
  styleUrls: ['./edit-account-info.component.scss']
})
export class EditAccountInfoComponent implements OnInit {

  @Input() firstname = 'First Name';
  @Input() lastname = 'Last Name';
  @Input() email = 'address@email.com';
  @Input() password = 'password';

  componentLabels = {
    title: 'Edit Account',
    firstname: 'First Name',
    lastname: 'Last Name',
    email: 'Email',
    password: 'Password',
    changePassword: 'Change Password',
    save: 'Save',
  };

  constructor() { }

  ngOnInit(): void {
  }

  onChangePasswordClick() {

  }
  onSaveClick() {

  }

}
