<section class="component load-more">
  <div class="load-more__container">
    <div class="load-more__wrapper">
      <h2 class="headline">More Stories</h2>
      <div class="entries-container">
        <div class="entries">
          <div class="group" style="display: grid; opacity: 1; transform: translateY(0)">
            <div class="entry" *ngFor="let entry of firstItems; let i=index">
              <div class="section" *ngIf="entry.image.url; else elseBlock">
                <img *ngIf="!entry.image.responsive" [attr.src]="entry.image.url" [attr.alt]="entry.image.alt" [attr.title]="entry.image.alt" width="100%" height="100%" />
                <picture *ngIf="entry.image.responsive">
                  <source *ngFor="let item of entry.image.responsive" [srcset]="item.url" [media]="item.media" />
                  <img [src]="entry.image.responsive[0].url" [alt]="entry.image.alt" width="100%" height="100%" />
                </picture>
              </div>
              <ng-template #elseBlock class="section">
                <div class="section default">
                  <img *ngIf="entry.event_date_time" src="/assets/images/content-package-green-mobile.png" [alt]="entry.title" width="100%" height="100%" />
                  <img *ngIf="!entry.event_date_time" src="/assets/images/content-package-coral-mobile.png" [alt]="entry.title" width="100%" height="100%" />
                </div>
              </ng-template> 
              <p class="eyebrow">{{ entry.eyebrow }}</p>
              <p class="title">{{ entry.title }}</p>
              <a href="{{ entry.url }}" class="cta-link">
                Read Article
              </a>
            </div>
          </div>
          <div
            class="group"
            *ngFor="let group of remainingItems | chunk: 6"
          >
            <div class="entry" *ngFor="let entry of group">
              <div class="section" *ngIf="entry.image.url">
                <img *ngIf="!entry.image.responsive" [attr.src]="entry.image.url" [attr.alt]="entry.image.alt" [attr.title]="entry.image.alt" width="100%" height="100%" />
                <picture *ngIf="entry.image.responsive else elseBlock">
                  <source *ngFor="let item of entry.image.responsive" [srcset]="item.url" [media]="item.media" />
                  <img [src]="entry.image.responsive[0].url" [alt]="entry.image.alt" width="100%" height="100%" />
                </picture>
              </div>
              <ng-template #elseBlock class="section">
                <div class="section default">
                  <img *ngIf="entry.event_date_time" src="/assets/images/content-package-green-mobile.png" [alt]="entry.title" width="100%" height="100%" />
                  <img *ngIf="!entry.event_date_time" src="/assets/images/content-package-coral-mobile.png" [alt]="entry.title" width="100%" height="100%" />
                </div>
              </ng-template> 
              <p class="eyebrow">{{ entry.eyebrow }}</p>
              <p class="title">{{ entry.title }}</p>
              <a href="{{ entry.url }}" class="cta-link">
                Read Article
              </a>
            </div>
          </div>
          <button *ngIf="shownItems !== totalItems" class="load-more" [ngClass]="isLoading ? 'is-loading': ''" (click)="loadMore()">
            <div class="main">
              <div class="icon">
                <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2301 13.1316L8.52672 19.6842C8.31221 19.8947 8.04408 20 7.77594 20C7.50781 20 7.23967 19.8947 7.02516 19.6842L0.321763 13.1316C-0.107254 12.7105 -0.107254 12.0526 0.321763 11.6316C0.750781 11.2105 1.42112 11.2105 1.85014 11.6316L6.7034 16.4211V1.05263C6.7034 0.473684 7.18604 0 7.77594 0C8.36584 0 8.84849 0.473684 8.84849 1.05263V16.4211L13.7286 11.6579C14.1576 11.2368 14.8279 11.2368 15.2572 11.6579C15.6594 12.0526 15.6594 12.7105 15.2301 13.1316Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.52672 19.6842L15.2301 13.1316C15.6594 12.7105 15.6594 12.0526 15.2572 11.6579C14.8279 11.2368 14.1576 11.2368 13.7286 11.6579L8.84849 16.4211V1.05263C8.84849 0.473684 8.36584 0 7.77594 0C7.18604 0 6.7034 0.473684 6.7034 1.05263V16.4211L1.85014 11.6316C1.42112 11.2105 0.750781 11.2105 0.321763 11.6316C-0.107254 12.0526 -0.107254 12.7105 0.321763 13.1316L7.02516 19.6842C7.23967 19.8947 7.50781 20 7.77594 20C8.04408 20 8.31221 19.8947 8.52672 19.6842ZM1.02221 12.4179C1.02234 12.418 1.02209 12.4178 1.02221 12.4179L7.72561 18.9705C7.74301 18.9876 7.75603 18.9942 7.76236 18.9969C7.76897 18.9996 7.7733 19 7.77594 19C7.77859 19 7.78292 18.9996 7.78953 18.9969C7.79585 18.9942 7.80888 18.9876 7.82627 18.9705L7.8277 18.9691L14.5299 12.4177C14.53 12.4175 14.5298 12.4178 14.5299 12.4177C14.5433 12.4045 14.5493 12.3937 14.5519 12.3875C14.5548 12.3807 14.5555 12.3754 14.5556 12.3722C14.5556 12.3715 14.5555 12.3709 14.5555 12.3704C14.5314 12.3474 14.5097 12.3421 14.4928 12.3421C14.4755 12.3421 14.4535 12.3476 14.429 12.3716L14.427 12.3735L7.84849 18.7945V1.05263C7.84849 1.04751 7.84742 1.03708 7.83298 1.02291C7.81827 1.00848 7.7989 1 7.77594 1C7.75299 1 7.73362 1.00848 7.71891 1.02291C7.70447 1.03708 7.7034 1.04751 7.7034 1.05263V18.8129L1.14969 12.3453C1.14951 12.3451 1.14986 12.3454 1.14969 12.3453C1.12543 12.3217 1.10303 12.3158 1.08595 12.3158C1.06874 12.3158 1.04668 12.3213 1.02221 12.3453C1.00864 12.3586 1.00387 12.3682 1.00219 12.3722C1.00032 12.3766 1 12.3796 1 12.3816C1 12.3835 1.00032 12.3865 1.00219 12.391C1.00385 12.3949 1.00893 12.4048 1.02221 12.4179Z" fill="white"/>
                </svg>
              </div>
              Load More Articles
            </div>
            <div class="loader" aria-hidden="true">
              <img src="/assets/images/loader.gif" alt="Loading..." />
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
