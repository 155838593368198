<div class="component component-breakdown-steps">
  <div class="wrapper">
    <div class="steps">
      <h2>{{ heading }}</h2>
      <ul>
        <li *ngFor="let step of steps">
          <h3>{{ step.headline }}</h3>
          <div [innerHTML]="step.intro"></div>
        </li>
      </ul>
    </div>
  </div>
</div>
