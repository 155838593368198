<div class="topics-content" *ngIf="content && content[scenarioText]">



    <ng-container>

        <section class="page-content" *ngIf="scenarioText === 'questions_submitted_text'">
            <div class="page-content__wrapper">
            <div class="page-content__inner_wrapper" [innerHTML]="renderContent(content[scenarioText]?.value)"></div>
            </div>
        </section>

        <section class="topics-table">

            <table *ngIf="!isMobile; else mobileView">
                <thead>
                    <tr class="table-headers">
                        <td *ngFor="let header of headers">{{header}}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" *ngFor="let row of rows">
                        <td>{{row.item}}</td>
                        <td>{{row.annualExpense | currency}}</td>
                        <td>{{row.nationalAverage | currency}}</td>
                        <td [ngClass]="{'negative': row.variance < 0, 'positive': row.variance >= 0}">{{row.variance | currency}}</td>
                    </tr>
                    <tr class="total-row">
                        <td class="total">{{content.total_column_label.value}}</td>
                        <td>{{totalAnnualExpense | currency}}</td>
                        <td>{{totalAverageExpense | currency}}</td>
                        <td [ngClass]="{'negative': totalVariance < 0, 'positive': totalVariance >= 0}">{{totalVariance | currency}}</td>
                    </tr>
                </tbody>

            </table>


            <ng-template #mobileView>
                <div class="mobile-rows">
                    <div class="mobile-row" *ngFor="let row of rows">
                        <div class="row">
                            <h3>{{headers[0]}}</h3>
    
                            <span>{{row.item}}</span>
                        </div>
                        <div class="row">
                            <h3>{{headers[1]}}</h3>
    
                            <span>{{row.annualExpense | currency}}</span>
                        </div>
                        <div class="row">
                            <h3>{{headers[2]}}</h3>
    
                            <span>{{row.nationalAverage | currency}}</span>
                        </div>
                        <div class="row">
                            <h3>{{headers[3]}}</h3>
    
                            <span [ngClass]="{'negative': row.variance < 0, 'positive': row.variance >= 0}">{{row.variance | currency}}</span>
                        </div>
                    </div>

                    <div class="mobile-row total">
                        <div class="row">
                            <h3>{{content.total_column_label.value}}</h3>
                        </div>
                        <div class="row">
                            <h3>{{headers[1]}}</h3>
    
                            <span>{{totalAnnualExpense | currency}}</span>
                        </div>
                        <div class="row">
                            <h3>{{headers[2]}}</h3>
    
                            <span>{{totalAverageExpense | currency}}</span>
                        </div>
                        <div class="row">
                            <h3>{{headers[3]}}</h3>
    
                            <span [ngClass]="{'negative': totalVariance < 0, 'positive': totalVariance >= 0}">{{totalVariance | currency}}</span>
                        </div>
                    </div>
                </div>


            </ng-template>
    
    
        </section>

        <section class="topics-income-line">
            <h2 class="income-line-headline">{{content.annualized_income_headline.value}} <span>{{annualizedIncome | currency}}</span>.</h2>
        </section>

        <section class="topics-edit-line">
            <span (click)="editAnswers()">{{content.edit_answers_label.value}}</span>
        </section>
        
        <section class="page-content" *ngIf="scenarioText !== 'questions_submitted_text'">
            <h1>{{content.results_and_recommendations_headline.value}}</h1>
            <div class="page-content__wrapper">
            <div class="page-content__inner_wrapper" [innerHTML]="renderContent(content[scenarioText]?.value)"></div>
            </div>

            <app-back-button *ngIf="scenarioText === 'scenario_3_text'" [cta]="'Back to My Plan'"></app-back-button>
        </section>
    
        <section class="screening" *ngIf="subScreening" [ngClass]="{'show': showScreening}">
            <app-screening [onlySaveOnSubmit]="true" [redirect]="false" (onScreeningSubmit)="onScreeningSubmit($event)" [isVerticalScreening]="true" [screening]="subScreening"></app-screening>
        </section>
    </ng-container>



</div>