<div class="account-profile-header">
  <div class="account-profile-header__wrapper">
    <a class="back-cta" (click) = 'goBack($event)' [title]="backCTA.text">
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.86842 0.547222L0.315788 7.25062C0.105263 7.46513 0 7.73326 0 8.0014C0 8.26954 0.105263 8.53767 0.315788 8.75218L6.86842 15.4556C7.28947 15.8846 7.94737 15.8846 8.36842 15.4556C8.78947 15.0266 8.78947 14.3562 8.36842 13.9272L3.57895 9.07395L18.9474 9.07395C19.5263 9.07395 20 8.5913 20 8.0014C20 7.4115 19.5263 6.92886 18.9474 6.92886L3.57895 6.92886L8.34211 2.04878C8.76316 1.61977 8.76316 0.949426 8.34211 0.52014C7.94737 0.117936 7.28947 0.117936 6.86842 0.547222Z" fill="#0B4A5D"/>
      </svg>
      <div>{{ backCTA.text }}</div>
    </a>
    <header class="header">
      <div class="header__main">
        <h1 class="headline">{{ headline }}</h1>
        <p class="subhead">{{ subhead }}</p>
        <div class="account-type">
          <select
            [name]="headerQuestion.id"
            [id]="headerQuestion.id"
            [(ngModel)]="headerQuestion.userAnswer"
            (change)="onChange()"
          >
            <option
              *ngFor="let option of headerQuestion.answerGroup.answers"
              [value]="option.value"
            >
              {{ option.displayText }}
            </option>
          </select>
          <div class="account-type__main" aria-hidden="true">
            {{ displayText }}
          </div>
          <div class="account-type__copy">
            {{ accountTypeUpdateCopy }}
          </div>
        </div>
        <img class="icon" *ngIf="image" [src]="image.src" [alt]="image.alt" aria-hidden="true" />
      </div>
      <div class="header__menu" *ngIf="menu && menu.length > 0">
        <ul>
          <li [ngClass]="item.isActive ? 'is-active' : ''" *ngFor="let item of menu; let itemIndex = index">
            <button type="button" (click)="navigationOnClick(itemIndex,item.id)">{{ item.text }}</button>
          </li>
        </ul>
      </div>
    </header>
  </div>
</div>

<div class="loading-overlay" *ngIf="showLoadingOverlay">
  <img src="../../../../assets/images/loader.gif" alt="Loading" />
</div>
