<section class="component inline-table">

  <div class="inline-table__container">
    <div class="inline-table__wrapper">

      <table id="usertable" class="table table-striped table-bordered table-hover" (scroll)="onScroll($event)">
        <thead>
          <tr>
            <td *ngFor="let header of headers let i=index">
              <span class="username">{{header.title}}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of rows let i=index">
            <td *ngFor="let item of row.content let i=index">
              <span class="username">{{item.text}}</span>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</section>