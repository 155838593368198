<age-well-planner *ngIf="data">
  <div class="article-resource-page">
    <article-detail-header
      [eyebrow]="data.articleDetailHeader.eyebrow"
      [title]="data.articleDetailHeader.title"
      [readingTime]="data.articleDetailHeader.readingTime"
      [trustedExpertCta]="data.articleDetailHeader.trustedExpertCta"
      [leadGenShow]="data.leadGen && !data.leadGenConfirmed?.show"
    ></article-detail-header>

    <ncoa-author-byline-planner
      [authors]="data.authorByline"
    ></ncoa-author-byline-planner>

    <div class="lead-gen-confirmed" *ngIf="data?.leadGenConfirmed && data?.leadGenConfirmed?.show">
      <div class="lead-gen-confirmed__wrapper">
        <div class="lead-gen-confirmed__col">
          <div class="lead-gen-confirmed__headline" *ngIf="data.leadGenConfirmed.headline">
            <h3>{{ data.leadGenConfirmed.headline }}</h3>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf='!data.leadGenConfirmed.noCheck'>
              <path d="M8.06641 14.1113C8.30078 14.3457 8.69922 14.3457 8.93359 14.1113L15.8242 7.2207C16.0586 6.98633 16.0586 6.58789 15.8242 6.35352L14.9805 5.50977C14.7461 5.27539 14.3711 5.27539 14.1367 5.50977L8.51172 11.1348L5.86328 8.50977C5.62891 8.27539 5.25391 8.27539 5.01953 8.50977L4.17578 9.35352C3.94141 9.58789 3.94141 9.98633 4.17578 10.2207L8.06641 14.1113Z" fill="#0B4A5D"/>
              <circle cx="10" cy="10" r="9" stroke="#0B4A5D" stroke-width="2"/>
            </svg>
          </div>
          <div class="lead-gen-confirmed__body" *ngIf="data.leadGenConfirmed.body" [innerHTML]="data.leadGenConfirmed.body"></div>
        </div>
        <div class="lead-gen-confirmed__col">
          <div class="lead-gen-confirmed__info" *ngIf="data.leadGenConfirmed.phone_number">
            <div class="lead-gen-confirmed__label">{{ data.leadGenConfirmed.phone_number.eyebrow }}</div>
            <div class="lead-gen-confirmed__content">{{ data.leadGenConfirmed.phone_number.value }}</div>
          </div>
          <div class="lead-gen-confirmed__info" *ngIf="data.leadGenConfirmed.schedule">
            <div class="lead-gen-confirmed__label">{{ data.leadGenConfirmed.schedule.eyebrow }}</div>
            <div class="lead-gen-confirmed__content">
              <a [href]="data.leadGenConfirmed.schedule.value.url" [title]="data.leadGenConfirmed.schedule.value.text" target="_blank">{{ data.leadGenConfirmed.schedule.value.text }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="article-body-page-container"  id = 'main-content'>
      <ncoa-article-body
        *ngIf="data.articleBody"
        [content]="data.articleBody.content"
        [url]="data.articleBody.url"
        [requiredFieldError]="data.articleBody.requiredFieldError"
        [saveToContent] = '{isSaved: isSaved}'
        (saveToContentClick) = 'onSaveContentClick()'
      >
        <app-takeaways
          *ngIf="data.takeAwaysComponent"
          [heading]="data.takeAwaysComponent.headings"
          [takeaways]="data.takeAwaysComponent.takeaways"
        ></app-takeaways>
      </ncoa-article-body>
    </div>

    <app-author-byline
      *ngIf="data.otherContributors"
      [authors]="data.otherContributors"
      variant='others'
    ></app-author-byline>

    <app-partners
      *ngIf="data.partnersGrid"
      [heading]="data.partnersGrid.heading"
      [partners]="data.partnersGrid.partners"
    ></app-partners>

    <ncoa-article-feedback
      *ngIf="data.articleFeedback"
      [votes]="data.articleFeedback.votes"
      [article]="data.articleFeedback.article"
      [slug]="data.articleFeedback.slug"
      [id]="data.articleFeedback.id"
    ></ncoa-article-feedback>

    <ncoa-article-share-mobile
      [url]="data.articleBody.url"
      [saveToContent] = '{isSaved: isSaved}'
      (saveToContentClick) = 'onSaveContentClick()'
    ></ncoa-article-share-mobile>

    <ncoa-lead-gen
      *ngIf="data.leadGen && !data.leadGenConfirmed?.show"
      [heading]="data.leadGen.heading"
      [eyebrow]="data.leadGen.eyebrow"
      [intro]="data.leadGen.intro"
      [cta]="data.leadGen.cta"
      [photo]="data.leadGen.photo"
      [icon]="data.leadGen.icon"
    ></ncoa-lead-gen>

    <ncoa-medicare-lead-gen
      *ngIf="data.medicareLeadGen"
      [awp]="true"
      [eyebrow]="data.medicareLeadGen.eyebrow"
      [headline]="data.medicareLeadGen.headline"
      [image]="data.medicareLeadGen.image"
      [branding]="data.medicareLeadGen.branding"
      [data]="data.medicareLeadGen.form"
    ></ncoa-medicare-lead-gen>

    <ncoa-breakdown-plans
      *ngIf="data.planCompare"
      [showOnlyConsiderations]="true"
      [considerationHeading]="data.planCompare.considerationHeading"
      [considerations]="data.planCompare.considerations"
      [leadGenConfirmed]="false"
    ></ncoa-breakdown-plans>

    <ncoa-one-up
      *ngIf="data.toolCard1Up"
      [type]="data.toolCard1Up.type"
      [heading]="data.toolCard1Up.heading"
      [card]="data.toolCard1Up.card"
      [cta]="data.toolCard1Up.cta"
    ></ncoa-one-up>

    <ncoa-three-up
      *ngIf="data.ncoaThreeUp && data.ncoaThreeUp.cards.length === 3"
      [heading]="data.ncoaThreeUp.heading"
      [cards]="data.ncoaThreeUp.cards"
      [moreLink]="data.ncoaThreeUp.moreLink"
      [isAnimationOn]="true"
    ></ncoa-three-up>

    <ncoa-text-with-image
      *ngIf="data.promo"
      [alignment]="data.promo.alignment"
      [heading]="data.promo.heading"
      [intro]="data.promo.intro"
      [image]="data.promo.image"
      [cta]="data.promo.cta"
    ></ncoa-text-with-image>
  </div>

</age-well-planner>
