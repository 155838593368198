import { Component, OnInit, Input } from '@angular/core';
import { missionEntry, cta } from './our-mission.types';

@Component({
  selector: 'ncoa-our-mission',
  templateUrl: './our-mission.component.html',
  styleUrls: ['./our-mission.component.scss']
})
export class OurMissionComponent implements OnInit {
  @Input() headline: string;
  @Input() description: string;
  @Input() missionEntry: missionEntry[];
  @Input() cta: cta;
  @Input() ctaText: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.desktopLoaded();
    this.mobileAnimation();
  }

  mobileAnimation() {
    const _entries = document.querySelectorAll('.our-mission__entry');

    window.addEventListener('scroll', () => {

      _entries.forEach( _entry => {

        if( this.isInView( _entry, ( window.innerHeight / 2 ) ) ) {
          _entry.classList.add('in-view');
        }
        
      });

    });
  }

  desktopLoaded() {
    const _components = document.querySelectorAll('.component.our-mission');
    let initialPos = window.pageYOffset;
    let ctr1 = 0;
    let ctr2 = 0;

    _components.forEach( _component => {

      document.addEventListener('scroll', () => {
        if( this.isInView( _component, ((window.innerHeight/4)*3) ) ) {
          _component.classList.add('in-view');
        } 

        const _entries = _component.querySelector('.our-mission__entries');

        if( this.isInView( _entries, (window.innerHeight / 2) ) ) {
          _component.classList.add('show-entries');
        } 

        const _rect = _component.getBoundingClientRect();

        if( _rect.top < ((window.innerHeight / 4) * 3) && _rect.bottom > 0 ) {
          let _currentPos = window.pageYOffset;

          const _coral = <HTMLElement>_component.querySelector('.coral-circle');
          const _pale = <HTMLElement>_component.querySelector('.pale-coral-circle');

          if( initialPos > _currentPos ){
            _coral.style.setProperty('--coralCircleTop', `${ctr1 += 2}px`);
            _pale.style.setProperty('--paleCircleTop', `${ctr2 += 2}px`);
          } else {
            _coral.style.setProperty('--coralCircleTop', `${ctr1 -= 2}px`);
            _pale.style.setProperty('--paleCircleTop', `${ctr2 -= 2}px`);
          }

          initialPos = _currentPos;
        }

        if( (window.scrollY || window.pageYOffset) < _rect.top ) {
          ctr1 = 1;
          ctr2 = 1;
        }



      });

    });
  }

  isInView( _element, _offset ) {
    let _componentPosition = _element.getBoundingClientRect();

    if( _componentPosition.top <= _offset ) {
      return true;
    } else {
      return false;
    }
  }

}
