<div class="component category-hero component--category">
  <div class="wrapper">
    <div class="category">
      <div class="category--content">
        <div class="category--breadcrumb">
          <ul *ngIf="category.breadcrumbs.length > 0">
            <li *ngFor="let breadcrumb of category.breadcrumbs">
              <a [attr.href]="breadcrumb.url" [attr.title]="breadcrumb.text">{{ breadcrumb.text }}</a>
              <span>/</span>
            </li>
          </ul>
          <h1 class="category--heading" [innerHTML]="category.headline"></h1>
          <div class="category--description" [innerHTML]="category.description"></div>
        </div>
      </div>
      <div class="category--photo">
        <div class="category--photo__wrapper">
          <img *ngIf="category.photo && category.photo.url && !category.photo.responsive" [attr.src]="category.photo.url" [attr.alt]="category.photo.alt" [attr.title]="category.photo.alt" width="100%" height="100" />
          <picture *ngIf="category.photo?.responsive">
            <source *ngFor="let item of category.photo.responsive" [srcset]="item.url" [media]="item.media" />
            <img [src]="category.photo.responsive[0].url" [alt]="category.photo.alt" width="100%" height="100" />
          </picture>
          <picture *ngIf="!category.photo?.url">
            <source srcset="/assets/images/category-hero-mobile.png" media="all and (max-width: 1023px)" />
            <source srcset="/assets/images/category-hero-desktop.png" media="all and (min-width: 1024px)" />
            <img src="/assets/images/category-hero-desktop.png" alt="Image placeholder" width="100%" height="100" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
