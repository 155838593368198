<div *ngIf="!data" class="loading-page">
  <img src="../../../../assets/images/loader.gif" alt="Loading" />
</div>

<age-well-planner *ngIf="data">
  <div class="subtopic subtopic--learn">
    <ncoa-subtopic-header
      *ngIf="data.subtopicHeader && data.subtopicHeader.type === 'subtopic-header'"
      [pageID]="pageID"
      [heading]="data.subtopicHeader.heading"
      [photo]="data.subtopicHeader.photo"
      [menu]="data.subtopicHeader.menu"
      [backCTA]="data.subtopicHeader.backCTA"
    ></ncoa-subtopic-header>

    <ncoa-vertical-landing-header
      *ngIf="data.subtopicHeader && data.subtopicHeader.type === 'vertical-header'"
      [title]="data.subtopicHeader.title"
      [desc]="data.subtopicHeader.desc"
      [image]="data.subtopicHeader.image"
      [assessmentCTA]="data.subtopicHeader.assessmentCTA"
      [menu]="data.subtopicHeader.menu"
    ></ncoa-vertical-landing-header>

    <div id="main-content">
      <ncoa-text-with-image
        *ngIf="data.promoTop"
        [alignment]="data.promoTop.alignment"
        [heading]="data.promoTop.heading"
        [intro]="data.promoTop.intro"
        [image]="data.promoTop.image"
        [cta]="data.promoTop.cta"
      ></ncoa-text-with-image>

      <ncoa-one-up
        *ngIf="data.toolCard1up"
        [type]="data.toolCard1up.type"
        [heading]="data.toolCard1up.heading"
        [card]="data.toolCard1up.card"
        [cta]="data.toolCard1up.cta"
      ></ncoa-one-up>

      <div class="article-group-parent-container">
        <div class="pattern-bg-container">
          <div class="firstBgPattern"></div>
          <div class="secondBgPattern"></div>
        </div>
        <ncoa-article-grouping-grid
          *ngFor="let grouping of data.articleCardGrouping"
          [heading]="grouping.heading"
          [cards]="grouping.cards"
        ></ncoa-article-grouping-grid>
      </div>

      <ncoa-one-up
        *ngIf="data.trustedExperts && data.trustedExperts.cards.length === 1"
        [type]="data.trustedExperts.type"
        [heading]="data.trustedExperts.heading"
        [card]="data.trustedExperts.cards[0]"
      ></ncoa-one-up>

      <ncoa-text-with-image
        *ngIf="data.trustedExpertPromo && data.trustedExperts && data.trustedExperts.cards.length > 1"
        [alignment]="data.trustedExpertPromo.alignment"
        [heading]="data.trustedExpertPromo.heading"
        [intro]="data.trustedExpertPromo.intro"
        [image]="data.trustedExpertPromo.image"
        [cta]="data.trustedExpertPromo.cta"
      ></ncoa-text-with-image>

      <ncoa-three-up
        *ngIf="data.benefitCard3Up"
        [heading]="data.benefitCard3Up.heading"
        [moreLink]="data.benefitCard3Up.moreLink"
        [cards]="data.benefitCard3Up.cards"
      ></ncoa-three-up>

      <ncoa-topic-listing
        *ngIf="data.singleColCardList"
        [heading]="data.singleColCardList.heading"
        [topics]="data.singleColCardList.topics"
      ></ncoa-topic-listing>

      <ncoa-lead-gen
        *ngIf="data.leadGen"
        [heading]="data.leadGen.heading"
        [eyebrow]="data.leadGen.eyebrow"
        [intro]="data.leadGen.intro"
        [cta]="data.leadGen.cta"
        [photo]="data.leadGen.photo"
        [icon]="data.leadGen.icon"
      ></ncoa-lead-gen>

      <ncoa-vertical-pathing
        *ngIf="data.verticalPath"
        [heading]="data.verticalPath.heading"
      [items]="data.verticalPath.items"
      ></ncoa-vertical-pathing>
    </div>
  </div>
</age-well-planner>
