<div class="todo-page-content" *ngIf="content && todo">
  <div class="page-top-controls">
    <app-back-button [cta]="'Back to My Plan'"></app-back-button>
  </div>

  <section class="page-header">
    <h1>{{ todo.title }}</h1>

    <section class="header-controls">

      <button
      class="todo-button-complete"
      (click)="onCompleteClick()"
      [ngClass]="{
        complete_todo_page:
          !todo.completed,
          incomplete_todo_page: todo.completed
      }"
    >
      {{
        !todo.completed
          ? content.mark_complete_cta
          : content.mark_incomplete_cta
      }}
      </button>

      <button
        class="todo-button archive-btn"
        (click)="onArchiveClick()"
      >
        {{
          !todo.archived
            ? content.archive_cta
            : content.unarchive_cta
        }}
      </button>

    </section>


  </section>



  <section class="page-content" *ngIf="!hideContent" #pageContent>
    <div class="page-content__wrapper">
      <div class="page-content__inner_wrapper" [innerHTML]="todo.content"></div>
    </div>
  </section>



  <section class="page-additional-content">

    <ng-container [ngSwitch]="todo.slug">

      <app-analyze-your-budget (hideContent)="onHideContent($event)" *ngSwitchCase="'analyze-your-budget'"></app-analyze-your-budget>
      
    </ng-container>
  

  </section>



  <section class="footer" *ngIf="showFooter" >

    <div class="footer-controls">

      <button
      class="todo-button-complete"
      (click)="onCompleteClick()"
      [ngClass]="{
        complete_todo_page:
          !todo.completed,
          incomplete_todo_page: todo.completed
      }"
    >
      {{
        !todo.completed
          ? content.mark_complete_cta
          : content.mark_incomplete_cta
      }}
      </button>
  
      <button
        class="todo-button archive-btn"
        (click)="onArchiveClick()"
      >
        {{
          !todo.archived
            ? content.archive_cta
            : content.unarchive_cta
        }}
      </button>
  
  
    </div>


  </section>
  <div class="page-bottom-controls">
    <app-back-button [cta]="'Back to My Plan'"></app-back-button>
  </div>

</div>
