<div class="component component-single-column">
  <div class="wrapper">
    <h3 class="component--heading">{{ heading }}</h3>
    <div class="single-column">
      <div class="single-column--item" *ngFor="let card of cards">
        <ncoa-planner-card
          [id]="card.id"
          [linkedID]="card.linkedID"
          [linkedCodename]="card.linkedCodename"
          [type]="card.type"
          [eyebrow]="card.eyebrow"
          [heading]="card.heading"
          [intro]="card.intro"
          [cta]="card.cta"
          [full]="card.full"
          [photo]="card.photo"
          [theme]="card.theme"
        >
        </ncoa-planner-card>
      </div>
    </div>
  </div>
</div>
