<form class="account-profile-edit-info">

  <div class="title-container">
    <h2 class="title">{{ componentLabels.title }}</h2>
    <button class="save-btn">{{ componentLabels.save }}</button>
  </div>
  <label for="zipcode" class="label-text">{{ componentLabels.location }}</label>
  <input type="number" name="zipcode" class="input-text" [(ngModel)]='zipcode' />
  <p class="input-info">{{ componentLabels.locationsub }}</p>


  <label for="zipcode" class="label-text">{{ componentLabels.employee }}</label>
  <p class="input-info">{{ componentLabels.employeeSub }}</p>
  <div class="radio-container" *ngFor="let selection of employeeStatusSelection">
    <input type="radio" name="employeeStatus" [id]="selection.id" class="radio-btn" [value]='selection.value'
      [checked]='selection.value === selectedEmployee' (click)='selectedEmployee = selection.value'>
    <label [for]="selection.id" class="radio-text">{{ selection.text }}</label>
  </div>


  <label for="zipcode" class="label-text">{{ componentLabels.gross }}</label>
  <p class="input-info">{{ componentLabels.grossSub }}</p>
  <div class="radio-container" *ngFor="let selection of grossIncomeSelection">
    <input type="radio" name="grossIncome" [id]="selection.id" class="radio-btn" [value]='selection.value'
      [checked]='selection.value === selectedGross' (click)='selectedGross = selection.value'>
    <label [for]="selection.id" class="radio-text">{{ selection.text }}</label>
  </div>


  <button class="save-btn" (click)='onSaveClick()'>{{ componentLabels.save }}</button>
</form>
