import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.scss']
})
export class AccordianComponent implements OnInit {

  @Input() public expanded: boolean;

  constructor() { 
    this.expanded = false;
  }

  ngOnInit(): void {
  }

  public expand(){
    this.expanded = true;
  }

  public collapse(){
    this.expanded = false;
  }

}
