<div class="partnership {{ container }}">
  <div class="partnership__container">
    <div class="partnership__text">{{ eyebrow }}</div>
    <div class="partnership__logo">
      <a *ngIf="url; else elseBlock2" [href]="url" target="_blank">
        <img [src]="src" [alt]="alt" />
      </a>
      <ng-template #elseBlock2><img [src]="src" [alt]="alt" /></ng-template>
    </div>
  </div>
</div>
