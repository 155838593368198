import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { GlobalService } from '@services/global.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotFoundComponent implements OnInit {

  contentType: string = 'standard_page';
  components: any[];

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.kontentDeliveryService.getItem(null, {
      'system.type': this.contentType,
      'elements.url': '404',
      limit: 1,
    })
      .then(data => {
        this.components = [
          { componentType: 'header', ...data.items[0].elements }
        ];

        this.globalService.loadFooter( true );
      });
  }
}
