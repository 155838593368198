import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ToDo, ToDoAction } from '@components/planner/plan/to-do/to-do.component';
import { ClientInfo } from '@services/planner/case-manager.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';


@Component({
  selector: 'app-client-todo-page',
  templateUrl: './todo-page.component.html',
  styleUrls: ['./todo-page.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ClientTodoPageComponent implements OnInit {
  @ViewChild('pageContent') pageContent: ElementRef;
  @Input() todo: ToDo;
  @Input() content: any;
  @Input() header: any;
  @Input() client: ClientInfo;

  @Output() onBackButton: EventEmitter<any> = new EventEmitter();

  public showFooter: boolean;
  public hideContent: boolean;



  constructor(private router: Router, private renderer: Renderer2, private cas: CustomerActivityService) {
    this.showFooter = true;
    this.hideContent = false;
  }

  ngOnInit(): void {
    
    switch(this.todo.slug){
      case 'analyze-your-budget':{
        this.showFooter = false;
      }
    }

  }
  ngAfterViewInit() {
  }

     
  public onArchiveClick(){
  }

  public onCompleteClick(){
  }

  public onHideContent($event: boolean){
    this.hideContent = $event;
  }
  backToList(){
    // this.router.navigate([], {
    //   queryParams: {}
    // });
    //emit event to parent
    this.onBackButton.emit({
      action: 'backToList',
    });
  }



}
