import { Injectable } from '@angular/core';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { BehaviorSubject, Observable } from "rxjs/Rx";
import { UrlService } from './url.service';

@Injectable({ providedIn: 'root' })

export class ModalService {

    private readonly _currentModalSource = new BehaviorSubject<any>(null);

    // Exposed observable (read-only).
    readonly modalsDataObservable = this._currentModalSource.asObservable();

    private modals: any[] = [];
    private activeModalCodename: string = '';
    private dismissedModals: string[] = [];
    generalModalCodeName: string;

    constructor(
      private kontentDeliveryService: KontentDeliveryService,
      private urlService: UrlService,
    ) {
      const userDimissedModals = (window as any).localStorage.getItem('userDimissedModals');
      this.dismissedModals = userDimissedModals ? userDimissedModals.split(',') : [];
    }

    subscribe(callback) {
      this.modalsDataObservable.subscribe(callback);
    }

    // Get last value without subscribing to the puppies$ observable (synchronously).
    getCurrentModalData(): Observable<any> {
      return this._currentModalSource.getValue();
    }

    async showAvailableModal(audience, categories = [], type?: string) {
      const modals = await this.getModals(audience, categories, type);

      const modalSessionFlag = this.getSession('modal-session')

      if (modalSessionFlag) {
        return
      }

      for(const modal of (modals || [])){
        if (!this.isUserDismissedModal(modal.id)) {
          this.generalModalCodeName = modal.id;
          this._setCurrentModal(modal);
          break;
        } 
      }
      
    }

    showModal(codename) {
      if ( this.activeModalCodename || this.isUserDismissedModal(codename) ) {
        return;
      }

      this.activeModalCodename = codename;

      const modal = this.modals.find(modal => modal.id === codename);

      if ( modal ) {
        modal.isOpen = true;
        this._setCurrentModal(modal);
      }
      else {
        this.getModalData(codename)
        .then(modalData => this._setCurrentModal({
          ...modalData,
        }));
      }
    }

    getModalData(codename) {
      return this.kontentDeliveryService.getItemAndCache(codename)
        .then(rawModalData => this.formatRawModalData(rawModalData.item));
    }

    async formatRawModalData(rawModalItemData, excludedPage = [], modularData = null) {
      const {
        audiences,
        categories,
        location,
        display_only_once,
        delay,
        modal_type,
        cta_link,
        title,
        description,
        cta_copy,
        exclusion,
        image_asset,
        soe_description,
        soe_list,
        excluded_categories
      }: any = this.kontentDeliveryService.extractItemElements(rawModalItemData);

      const type = this.formatModalType(modal_type[0]?.codename);
      let shouldOpen = true;

      if (excludedPage.length > 0 && excludedPage.indexOf(window.location.pathname) >= 0) {
        shouldOpen = false;
      }

      let ctaInfo = {
        type: "",
        url: ""
      }

      if(type !== 'soe'){
        const ctaLinkPrefix = (type) => {
          switch(type) {
            case 'ncoa_article_content':
              return '/article/';
            case 'standard_page':
              return '/page/';
  
            // Category or Special Page, show '/'
            // taxonomy_custom_content
            // standard_page__special
            default:
              return '/';
          }
        }

        if (modularData) {
          const page = modularData[cta_link[0]];

          if (page) {
            const { system, elements } = page;
            const url = system.type === 'taxonomy_custom_content'
              ? elements.category_page_url.value
              : elements.url.value;
            ctaInfo = {
              type: system.type,
              url: system.type !== 'link_type___standard_link' ? ctaLinkPrefix(system.type) : '' + url,
            };
          }
        }
      }

      const commonData = {
        id: rawModalItemData.system.codename,
        type,
        heading: title,
        exclusions: exclusion,
        description: description,
        cta: {
          url: ctaInfo.url,
          text: cta_copy
        },
        backgroundImage: {
          src: image_asset[0].url,
          caption: image_asset[0].name,
          width: image_asset[0].width,
          height: image_asset[0].height
        },
        location: location[0]?.codename,
        audience: audiences.map(audience=>audience.codename),
        categories: categories.map(category=>category.codename),
        excludedCategories: excluded_categories,
        displayOnce: display_only_once[0]?.codename === 'yes' ? true : false,
        formSubmitHandler: () => (type !== 'newsletter' ? this.close(rawModalItemData.system.codename) : null),
        delay,
        isOpen: shouldOpen,
        soeData: {
          soe_description: soe_description,
          soe_list: soe_list
        }
      };

      let additionalData = {};

      if ( type === 'donate' ) {
        additionalData = {
          donateData: {
            heading: commonData.heading,
            description: commonData.description,
            form_desc: '',
            background: commonData.backgroundImage.src,
            backgroundCaption: commonData.backgroundImage.caption
          }
        }
      }
      else if ( type === 'newsletter' ) {
        additionalData = {
          newsletterData: {
            heading: commonData.heading,
            description: commonData.description,
            background: commonData.backgroundImage.src,
            backgroundCaption: commonData.backgroundImage.caption,
            newsletterLink: ctaInfo.url,
            width: commonData.backgroundImage.width,
            height: commonData.backgroundImage.height
          }
        }
      } 
      return {
        ...commonData,
        ...additionalData
      }
    }

    add(modal: any) {
        // add modal to array of active modals
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string) {
      // open modal specified by id
      const modal = this.modals.find(x => x.id === id);
      if (modal) {
        modal.openModal();
      }
    }

    close(id: string) {
      // close modal specified by id
      const modal = this.modals.find(x => x.id === id);
      if (modal) {
        modal.isOpen = false;

        this._setCurrentModal(null);
        this.setSession('modal-session', { flag: true }, 600000);

        if ( modal.displayOnce ) {
          this.addToDismissedModals(id);
        }
      }
    }

    isUserDismissedModal(codename) {
      return this.dismissedModals.includes(codename);
    }

    async getModals(audience, categories = [], type?: string) {

      const modalsRequest = [];

      const excludeModals = this.dismissedModals;
      if (this.generalModalCodeName) {
        excludeModals.push(this.generalModalCodeName);
      }
      // Fetch modal for specific audience first, if any
    if (audience) {
        const params = {
          'system.type': 'modal_configuration',
          'system.codename[nin]': excludeModals.join(','),
          'elements.audiences[contains]': audience,
          'elements.location[any]': 'linked_audience',
          'elements.modal_type[nin]': 'soe_pop_up',
          'system.workflow_step[neq]': 'archived'
        }

        // this request gets all modals linked to this page audience
        modalsRequest.push(this.kontentDeliveryService.getItemAndCache(null, params))
        if (categories?.length > 0) {
          params['elements.location[any]']='linked_audience_and_categories',
          params['elements.categories[any]'] = categories.join(',')
          //this request gets modals linked to audience and category
          modalsRequest.push(this.kontentDeliveryService.getItemAndCache(null, params))
        }

    }
    if (categories?.length>0){
      //if this page has categories, find modals linked to any of these categories.
      const params = {
        'system.type': 'modal_configuration',
        'system.codename[nin]': excludeModals.join(','),
        'elements.categories[any]': categories.join(','),
        'elements.location[any]': 'linked_categories',
        'elements.modal_type[nin]': 'soe_pop_up',
        'system.workflow_step[neq]': 'archived'
      }
      
      modalsRequest.push(this.kontentDeliveryService.getItemAndCache(null, params))

    }
      //Fetch all-page modal
      modalsRequest.push(this.kontentDeliveryService.getItemAndCache(null, {
        'system.type': 'modal_configuration',
        'system.codename[nin]': excludeModals.join(','),
        'elements.location[contains]': 'all_pages',
        'elements.modal_type[nin]': 'soe_pop_up',
        'elements.excluded_categories[nin]': categories.join(','),
        'system.workflow_step[neq]': 'archived'
      }));

      // check for inclusions

      if(type?.length){
        modalsRequest.push(this.kontentDeliveryService.getItemAndCache(null, {
          'system.type': 'modal_configuration',
          'elements.inclusion[contains]': type,
          'system.workflow_step[neq]': 'archived'
        }));
      }
      
      let modalsRawData: any = await Promise.all(modalsRequest);

      modalsRawData.forEach((data, dataIndex) => {
        const filteredItems = data.items.filter((item) => item.elements.modal_type.value[0].codename !== 'soe_pop_up');

        modalsRawData[dataIndex].items = filteredItems;
      });

      // mark as promise all
      const reducedData = await Promise.all(
        modalsRawData.map(async rawData => {
          const { items, modular_content } = rawData;
          // mark as promise all
          let processedItems = await Promise.all(
            items.map(async item => {
              if (item.elements) {
                const { value: exclusions } = item.elements.exclusion;

                const excludedPage = await Promise.all((exclusions || []).map(async codeName => {
                  const contentData = modular_content[codeName];
                  const contentType = contentData.system.type;

                 

                  if (/article/ig.test(contentType)) {
                    return this.urlService.buildArticleURL(contentData.elements.url.value);
                  } else if (/standard_page/ig.test(contentType)) {
                    const parentPage = contentData.elements.parent_page.value;
                    let url = contentData.elements.url.value;

                    if (parentPage.length > 0) {
                      const data = await this.getParentPageUrl(parentPage[0]);
                      url = `${data.item.elements.url.value}/${url}`;
                    }

                    return this.urlService.buildPageURL(url);
                  }else if (/homepage_layout/ig.test(contentType)) {
                    return "/"
                  } else {
                    const audience = contentData.elements.audiences.value[0].codename;
                    const category = contentData.elements.categories.value[0].codename;
                    const url = `/${audience}/${category}`;
                    return url.replace(/([a-z])([A-Z])/g, '$1').replace(/[\s_]+/g, '-').toLowerCase();
                  }
                }));

                const processedItem = this.formatRawModalData(item, excludedPage, modular_content);
                return processedItem;
              }
            })
          );
          return processedItems || null;
        })
      );

      let flatData: any[] = [].concat.apply([], reducedData);
      flatData = flatData.filter(item => item.isOpen);

      //manually remove items because kentico doesn't have 'not any' 
      flatData.forEach((item,index)=>{
        const excludedCategories = item.excludedCategories.map(categoryItem => categoryItem.codename)
        categories.forEach(pageCategory=>{
          if(excludedCategories.includes(pageCategory)){
            flatData.splice(index,1);
          }
        })
      })


      return flatData.filter((val, idx) => flatData.findIndex((v) => val.id === v.id) === idx);
    }

    async getParentPageUrl(codename) {
      const data = await Promise.all([this.kontentDeliveryService.getItemAndCache(codename)]);
      return data[0];
    }

    private _setCurrentModal(modalData) {
      const displayModal = Array.isArray(modalData) ? modalData[0] : modalData;
      if(displayModal?.delay > 0 ){
        const delayInMS = displayModal.delay ? 1000 * 60 * Number(displayModal.delay) : 0;
        setTimeout(() => {
          this._currentModalSource.next(displayModal);
        }, delayInMS);
      }
      else{
      this._currentModalSource.next(displayModal);}
    }

    private addToDismissedModals(id) {
      this.dismissedModals.push(id);
      (window as any).localStorage.setItem('userDimissedModals', this.dismissedModals.join(','));
    }

    private formatModalType(modalType = '') {
      switch (true) {
        case /general/i.test(modalType):
          return 'general';

        case /donate/i.test(modalType):
          return 'donate';

        case /newsletter/i.test(modalType):
          return 'newsletter';

          case /soe/i.test(modalType):
            return 'soe';

        default:
          return modalType;
      }
    }

    private setSession(key, value, ttl) {
      const now = new Date();

      const item = {
        value,
        expiry: now.getTime() + ttl,
      };

      localStorage.setItem(key, JSON.stringify(item));
    }

    private getSession(key) {
      const itemStr = localStorage.getItem(key);

      if (!itemStr) {
        return null;
      }

      const item = JSON.parse(itemStr);
      const now = new Date();
      if (isNaN(item.expiry)|| now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    }
}
