<div class="component component-backlink">
  <div class="wrapper">
    <a (click) = 'goBack($event)'>
      <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.86842 1.04771L0.315788 7.75111C0.105263 7.96562 0 8.23375 0 8.50189C0 8.77003 0.105263 9.03816 0.315788 9.25267L6.86842 15.9561C7.28947 16.3851 7.94737 16.3851 8.36842 15.9561C8.78947 15.5271 8.78947 14.8567 8.36842 14.4277L3.57895 9.57443L18.9474 9.57443C19.5263 9.57443 20 9.09179 20 8.50189C20 7.91199 19.5263 7.42935 18.9474 7.42935L3.57895 7.42935L8.34211 2.54927C8.76316 2.12025 8.76316 1.44991 8.34211 1.02063C7.94737 0.618424 7.28947 0.618424 6.86842 1.04771Z" fill="#0B4A5D"/>
      </svg>
      {{ backCTA.text }}
    </a>

    <button type="button" (click)="toggleSave()" class="js-save">
      <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 2.23226V23.4647C1 24.4929 2.18505 25.0689 2.99357 24.4336L11 18.1429L19.0064 24.4336C19.8149 25.0689 21 24.4929 21 23.4647V2.23226C21 1.5517 20.4483 1 19.7677 1H2.23226C1.5517 1 1 1.5517 1 2.23226Z" stroke="#0B4A5D" [attr.fill]="isSaved ? '#0B4A5D' : '#FFFFFF'" stroke-width="2"/>
      </svg>
      {{ isSaved ? 'Saved' : 'Save' }}
    </button>
  </div>
</div>
<div class="component component--subtopic-header">
  <div class="wrapper">
    <div class="subtopic--photo">
      <img src="{{ photo.url }}" alt="{{ photo.alt }}" />
    </div>

    <h2 class="subtopic--heading">
      {{ heading }}
    </h2>
    <nav class="subtopic--menu">
      <ul>
        <li *ngFor="let link of menu" [ngClass]="{ 'active': link.active }">
          <a href="{{ link.url }}" (click) = 'trackAnalytics($event)'>{{ link.label }}</a>
        </li>
      </ul>
    </nav>
  </div>
</div>
