import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VerticalAssessmentComponent } from '@components/planner/vertical-assessment/vertical-assessment.component';
import { SubTopicService } from '@services/planner/subtopic.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ncoa-subtopic-landing',
  templateUrl: './subtopic-landing.component.html',
  styleUrls: ['./subtopic-landing.component.scss']
})
export class SubtopicLandingComponent implements OnInit {


  editMode:boolean = false;
  @ViewChild('verticalAssessment', {static: false}) set verticalAssessment(assessment: VerticalAssessmentComponent) {
    const urlParams = new URLSearchParams(window.location.search);
    this.editMode = urlParams.get('edit') === 'true';
    if (!assessment){
      return;
    }

    this.assessmentIsComplete = !this.editMode && assessment.isComplete();
    this.cd.detectChanges();

    if (this.assessmentIsComplete){
      this.routeToPlan()
    }
  };

  data: {
    subtopicHeader: any;
    assessment: any;
  } = null;

  isSavedPage: boolean = false;
  isViewAssessment: boolean = false;
  assessmentIsComplete: boolean = false;

  constructor(
    private subTopicService: SubTopicService,
    private toolsConfig: ToolsPrimaryConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {
    this.subTopicService.getData().then(async (data: any) => {
      this.data = data;
      const screeningsCompleted = this.toolsConfig.customerActivity.basic.completedScreenings;

      if(screeningsCompleted.includes(this.data?.assessment['assessmentCode']) && !this.editMode){
        this.routeToPlan()
      }
    });
  }

  async ngOnInit() {
    let screeningAnswers = JSON.parse(localStorage.getItem('screeningAnswers'));
    localStorage.setItem('lastPage', window.location.href);
 
    if (screeningAnswers && screeningAnswers[this.data?.assessment['assessmentCode']]){
      if (this.isViewAssessment){
        this.isSavedPage = true;
      }
    }
    // force reload to fix saved issue
    this.router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        window.location.reload();
      }
    });
  }


  private routeToPlan(){
    const route = this.toolsConfig.routes.find((route) => (
      route.assessmentCode === this.data?.assessment['assessmentCode'] &&  (route.type === 'vertical-plan' || route.type === 'vertical-subtopic-plan')
    ))?.route;

    window.location.href = route;
  }

}
