<div class="component component--header-landing">
  <div class="wrapper">
    <div class="background">
      <img *ngIf="!background.responsive" [attr.src]="background.url" [attr.alt]="background.alt" [attr.title]="background.alt">
      <picture *ngIf="background.responsive">
        <source *ngFor="let item of background.responsive" [srcset]="item.url" [media]="item.media" />
        <img [src]="background.responsive[0].url" [alt]="background.alt" />
      </picture>
    </div>
    <div class="main">
      <img [src]="logo.url" [alt]="logo.alt" class="logo">
      <h1 class="title">{{ headline }}</h1>
      <div *ngIf="intro" class="intro" [innerHTML]="intro"></div>
      <a *ngIf="cta && cta.url" [href]="cta.url" class="cta" (click) = 'trackAnalytics($event)'>{{ cta.label }}</a>
    </div>
  </div>
</div>
