import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import fetch from 'node-fetch';
import { RebaDataService } from './reba-data.service';

@Injectable({ providedIn: 'root' })
export class BenefitsService {
  apiBaseUrl: string = (environment as any)?.benefitsBaseUrl;
   categories = [];

  constructor(
    private kontent: KontentDeliveryService,
    private rebaDataService: RebaDataService,
  ) {
  }

  async getBenefits(state, superCategoryCode) {
    if(this.categories.length === 0){
      await this.getCategories;
    }
    const categoryName = this.categories.find(category=>{
      return category.codename === superCategoryCode;
    })

    const res = await this.rebaDataService.getProgramsBySuperCatState(superCategoryCode,state).toPromise();
    res.programs.forEach(program=>program.category = categoryName);
    return res.programs;

  }

  async getCategories() {
    return await this.kontent.getTaxonomies('awp_benefit_super_categories').then((data: any) => {
      let terms = data.terms;
      this.categories = data.terms;
      return terms;
    })
  }
};
