import { Component, OnInit, ViewChild, ElementRef, HostListener, Inject, AfterViewInit, Renderer2, RendererFactory2  } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { GlobalService } from '@services/global.service';
import { NavigationService } from '@services/navigation.service';
import { BaseLink, Link, Logo, MenuLink, Social } from './global-nav.types';
@Component({
  selector: 'ncoa-global-nav',
  templateUrl: './global-nav.component.html',
  styleUrls: ['./global-nav.component.scss']
})
export class GlobalNav implements OnInit, AfterViewInit {
  logo: Logo;
  donate: BaseLink;
  links: Link[];
  menu: MenuLink[];
  socials: Social[];
  skipLinkPath: string;
  isSearchInputFocused = false;
  headerHeight = 0;
  cookieName = 'fontsize';
  toggleMenuLabel = "Open Mobile Menu";

  @ViewChild('headerRef') headerRef: ElementRef;
  @ViewChild('searchOverlayRef') searchOverlayRef: ElementRef;

  focusableElementSelector: string = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
  parentLastItem: any;
  activeParentMenu: any;
  keyDownEvent: any;
  firstItem: any;
  lastItem: any;


  constructor(
    private navigationService: NavigationService,
    private globalService: GlobalService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {

    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if( ! this.router.url.endsWith('#main')) {
        this.skipLinkPath = `${this.router.url}#main`;
      } else {
        this.skipLinkPath = this.router.url;
      }
    });

    this.keyDownEvent = (e: any) => {
      if (e.keyCode === 9 && !e.shiftKey && e.target === this.lastItem) {
        e.preventDefault();
        this.firstItem.focus();
      }

      if (e.keyCode === 9 && e.shiftKey && e.target === this.firstItem) {
        e.preventDefault();
        this.lastItem.focus();
      }
    };
  }
  private fragment: string;

  ngOnInit(): void {
    const navData = this.globalService.navData;

    this.logo = navData.logo;
    this.donate = navData.donate;
    this.links = navData.links;
    this.menu = navData.menu;
    this.socials = navData.socials;

    this.activeRoute.fragment.subscribe(fragment => { 
      this.fragment = fragment; });
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.headerRef.nativeElement.classList.add('is-loaded');
      this.scrollToFragment();

    }, 1000);

    // Set the height of the header
    this.headerHeight = this.headerRef.nativeElement.clientHeight || 0;

    // Cookie check
    if (this.readCookie(this.cookieName) && this.readCookie(this.cookieName) === 'increased') {
      this.document.body.classList.add('font-increased');
    }
  }

  // @NOTE: Use HostListener decorator to handle DOM events.
  // Handle scroll event
  @HostListener('window:scroll')
  onScroll() {
    if (typeof window === 'undefined') {
      return;
    }

    // get the scroll Y position
    const scrollY = window.scrollY || window.pageYOffset;

    if ( scrollY > this.headerHeight && scrollY < ( this.headerHeight + 100 ) ) {
      this.headerRef.nativeElement.classList.add('is-fixed');
      this.headerRef.nativeElement.classList.add('is-scrolled');
    } else if ( scrollY > ( this.headerHeight + 100 ) ) {
      this.headerRef.nativeElement.classList.remove('is-fixed');
      this.headerRef.nativeElement.classList.add('is-sticky');
      this.document.body.classList.add('is-sticky');
    } else {
      if (window.getComputedStyle(document.body).getPropertyValue('position') != 'fixed') {
        this.headerRef.nativeElement.classList.remove('is-fixed');
        this.headerRef.nativeElement.classList.remove('is-sticky');
        this.document.body.classList.remove('is-sticky');
      }
    }
  }

  // @NOTE: Use the HostListener decorator to handle DOM events.
  // Handle keydown event
  @HostListener('document:keydown.escape', ['$event'])
  onKeyDownEscape(event: KeyboardEvent) {
    this.closeDesktopNav();
    this.closeSearch( document.querySelector('.js-overlay--close') );

    if( window.innerWidth < 1024 ) {
      this.toggleMobileMenu( event );
    }
  }

  closeLoop() {
    const _mobileMenuTrigger = <HTMLElement>document.querySelector('.header--logo');

    setTimeout( () => {
      _mobileMenuTrigger.focus();
    }, 250);
  }

  closeDesktopNav() {
    const menu = this.document.querySelector('.nav--main__links');
    const menuWrapper = this.document.querySelector('.nav--main');
    const li = menu.querySelectorAll('li');

    menu.classList.remove('is-open');
    menuWrapper.classList.remove('nav-active');

    li.forEach( item => {
      item.classList.remove('is-open');
    });
  }

  useNavigationData({ logo, links, donate, menu, socials }) {
    this.logo = logo;
    this.links = links;
    this.donate = donate;
    this.menu = menu;
    this.socials = socials;
    
  }

  scrollToFragment() {
    if (this.fragment) {
      const element = document.querySelector(`#${this.fragment}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center'});
      }
      return;
    }
  }
  toggleDesktopMenu( _element ) {
    _element.preventDefault();

    const _parent = _element.srcElement.parentElement;

    this.activateMenu( _parent );

    const _marker = document.querySelector('.nav--marker') as HTMLElement;
    const root = document.documentElement;
    const _id = _element.srcElement.id;

    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      _marker.style.transform = `translateX(${ ( parseInt(_id)) * 100 }%)`;
    } else {
      root.style.setProperty('--navMarkerPos', `${ ( parseInt(_id)) * 100 }%`);
    }
  }

  keepDesktopMenu( _element ) {

    const _parent = _element.srcElement.parentElement.parentElement.parentElement.parentElement;

    this.activateMenu( _parent );

  }

  activateMenu( _parent ) {
    const _menuWrapper = document.querySelector('.nav--main ');
    const _menu = document.querySelector('.nav--main__links');
    const _subMenu = document.querySelectorAll('.nav--main__links li');
    _subMenu.forEach( _item => {
      _item.classList.remove('is-open');
      _item.classList.remove('is-active');
    });

    _menu.classList.add('is-open');
    _menuWrapper.classList.add('nav-active');

    _parent.classList.add('is-open');
    _parent.classList.add('is-active');
  }

  openSearch( _element ) {
    this.searchOverlayRef.nativeElement.classList.add('is-open');

    setTimeout(() => {
      this.searchOverlayRef.nativeElement.querySelector('input').focus();
    }, 1200);
  }

  closeSearch( _element ) {
    this.searchOverlayRef.nativeElement.classList.remove('is-open');
  }

  closeSubMenu( _element ) {
    _element.stopPropagation()

    const _menu = document.querySelector('.nav--main__links');
    const _menuWrapper = document.querySelector('.nav--main ');
    const _this = _element.srcElement.parentElement;
    _this.classList.remove('is-open');
    _menu.classList.remove('is-open');
    _menuWrapper.classList.remove('nav-active');
  }

  toggleMobileMenu( _element ) {
    _element.stopPropagation()

    const _body = document.querySelector('html');
    const _this = document.querySelector('.js-menu--trigger');
    const _mainMenu = document.querySelector('.header--global');
    const menuContent = document.querySelector('.header--global__content');
    const _mobileMenu: HTMLElement = document.querySelector('.header--nav__mobile');

    _mainMenu.classList.remove('is-sub-open');

    _this.classList.toggle('is-open');
    _body.classList.toggle('is-open');
    _mainMenu.classList.toggle('is-open');
    _mainMenu.classList.add('animate-once');
    _this.parentElement.classList.toggle('is-open');

    if( this.toggleMenuLabel === 'Open Mobile Menu' ) {
      this.toggleMenuLabel = 'Close Mobile Menu';

      _mobileMenu.setAttribute('aria-hidden', 'false');
      _mobileMenu.style.removeProperty('visibility');
      setTimeout(() => {
        //(document.querySelector('.search--form input') as HTMLElement).focus();

        (document.querySelector('.main') as HTMLElement).style.visibility = 'hidden';
        (document.querySelector('.global-footer') as HTMLElement).style.visibility = 'hidden';
        (document.querySelector('ncoa-modal-container') as HTMLElement).style.visibility = 'hidden';

        let container = document.querySelector('.header--nav__mobile');
        this.lastItem = Array.from(container.querySelectorAll(this.focusableElementSelector));
        this.lastItem = this.lastItem[(this.lastItem.length - 1)];
        this.parentLastItem = this.lastItem;

        this.firstItem = document.querySelector('.header--logo');
        this.lastItem.addEventListener('keydown', this.keyDownEvent);
        this.firstItem.addEventListener('keydown', this.keyDownEvent);
      }, 750);
    } else {
      this.toggleMenuLabel = 'Open Mobile Menu';

      (document.querySelector('.main') as HTMLElement).style.removeProperty('visibility');
      (document.querySelector('.global-footer') as HTMLElement).style.removeProperty('visibility');
      (document.querySelector('ncoa-modal-container') as HTMLElement).style.removeProperty('visibility');

      setTimeout(() => {
        _mobileMenu.setAttribute('aria-hidden', 'true');
        _mobileMenu.style.visibility = 'hidden';

        this.firstItem.removeEventListener('keydown', this.keyDownEvent);
        this.lastItem.removeEventListener('keydown', this.keyDownEvent);
        this.firstItem = this.lastItem = this.parentLastItem = null;
      }, 500);
    }

    _mobileMenu.classList.toggle('is-open');
    _mobileMenu.classList.add('animate-once');

    _mobileMenu.classList.remove('is-open-sub');

  }

  toggleSubMenu( _element ) {
    _element.stopPropagation();

    (document.querySelector('.global-nav__nav') as HTMLElement).style.removeProperty('display');
    (document.querySelector('.global-nav__menu') as HTMLElement).style.removeProperty('display');
    (document.querySelector('.global--social') as HTMLElement).style.removeProperty('display');

    (document.querySelector('.header--global') as HTMLElement).classList.remove('is-sub-open');

    const _mobileMenu = document.querySelector('.header--nav__mobile');
    _mobileMenu.classList.remove('is-open-sub');

    const _grandParent = _element.srcElement.parentElement.parentElement;
    _grandParent.classList.remove('is-open-sub');

    if (this.activeParentMenu) {
      setTimeout(() => {
        this.activeParentMenu.focus();
        this.activeParentMenu = null;

        this.lastItem.removeEventListener('keydown', this.keyDownEvent);
        this.lastItem = this.parentLastItem;
        this.lastItem.addEventListener('keydown', this.keyDownEvent);
      }, 650);
    }
  }

  openSubMenu( _element ) {
    _element.stopPropagation();

    const _mobileMenu = document.querySelector('.header--nav__mobile');
    const _parent = _element.srcElement.parentElement;
    const _subMenu = document.querySelectorAll('.global-nav__links-item');
    const _subs = document.querySelectorAll('.global-nav__sub');

    _mobileMenu.classList.add('is-open-sub');

    _subMenu.forEach( _item => {
      _item.classList.remove('is-open-sub');
    });

    _subs.forEach( _item => {
      _item.classList.remove('is-active');
    });

    const _subID = _element.srcElement.id;
    const _subActive = document.querySelector('.global-nav__sub.' + _subID );

    setTimeout(() => {
      const backBtn: HTMLElement = _subActive.querySelector('.js--back');
      backBtn.focus();

      (document.querySelector('.global-nav__nav') as HTMLElement).style.display = 'none';
      (document.querySelector('.global-nav__menu') as HTMLElement).style.display = 'none';
      (document.querySelector('.global--social') as HTMLElement).style.display = 'none';

      this.lastItem.removeEventListener('keydown', this.keyDownEvent);
      this.lastItem = Array.from(_subActive.querySelectorAll(this.focusableElementSelector));
      this.lastItem = this.lastItem[(this.lastItem.length - 1)];

      this.lastItem.addEventListener('keydown', this.keyDownEvent);
    }, 500);

    this.activeParentMenu = _element.srcElement;
    _parent.classList.add('is-open-sub');
    _subActive.classList.add('is-active');

    (document.querySelector('.header--global') as HTMLElement).classList.add('is-sub-open');

  }

  onFontSizeToggleClick(event: Event) {
    event.stopPropagation()

    const link = event.currentTarget as HTMLElement;
    const { effect } = link.dataset;

    if ( effect === 'increase' ) {
      document.body.classList.add('font-increased');
      this.createCookie('fontsize', 'increased');
    }
    else {
      document.body.classList.remove('font-increased');
      this.createCookie('fontsize', '');
    }
  }

  onSearchFocus(): void {
    this.isSearchInputFocused = true;
  }

  onSearchBlur(): void {
    this.isSearchInputFocused = false;
  }

  createCookie(key, value) {
    const cookie = `${escape(key)}=${escape(value)};`;
    document.cookie = cookie;
  }

  // @TODO: Create a service that will handle cookies
  readCookie(name): any {
    const key = `${name}=`;
    const cookies = document.cookie ? document.cookie.split(';') : [];

    for (let cookie of cookies) {
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(key) === 0) {
        return cookie.substring(key.length, cookie.length);
      }
    }
    return null;
  }

}
