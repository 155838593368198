import { Directive, Input, HostListener } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';

@Directive({
  selector: '[ncoa-share-link]'
})
export class ShareLinkDirective {
  @Input('ncoa-share-link') shareType: string;

  constructor(private dataLayerService: DataLayerService) { }

  @HostListener('click') onClick() {
    this.dataLayerService.push({
      shareType: this.shareType,
      url: location.href
    }, false);
  }

}
