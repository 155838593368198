import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { VerticalService } from '@services/planner/vertical.service';

@Component({
  selector: 'vertical-page-benefits-look-up',
  templateUrl: './benefits-look-up.component.html',
  styleUrls: ['./benefits-look-up.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalPageBenefitsLookUp implements OnInit {

  @Input() data: any = null;

  constructor(
    private verticalService: VerticalService,
  ) {
    this.verticalService.getData('vertical-benefits')
      .then((data) => this.data = data);
  }

  ngOnInit(): void { }
}
