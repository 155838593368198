import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LandingPageService } from '@services/landing-page.service';
import { UtilityService } from '@services/planner/utility.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { zipCodeValidator } from '../../../validators/zip-validator';
import { ErrorMessageProcessor } from 'src/app/validators/error-message-processor';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-annuities',
  templateUrl: './annuities.component.html',
  styleUrls: ['./annuities.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnnuitiesComponent implements OnInit, AfterViewInit {

  contactForm: FormGroup;
  hasLoadedContent: BehaviorSubject<boolean>;

  @ViewChild('headerBodyText') headerBodyText: ElementRef;

  data: any;
  activeElementTooltip: Element;
  errorMessageProcessor: ErrorMessageProcessor;
  errorMessages: any;

  open: string;
  heading: string;
  description: string;
  soe_data: any;
  backgroundImage: {
    src: string,
    caption: string
  };
  cta: {
    url: string,
    text: string
  };
  disableSubmit = false;
  ageRangeMap: any;
  annuitiesSituationMap: any;


  constructor(
    private landingService: LandingPageService,
    private cas: CustomerActivityService,
    private fb: FormBuilder,
    private modalService: ModalService,
    private router: Router,
    private elementRef: ElementRef,
    private utilService: UtilityService,
  ) { }

  ngOnInit(): void {
    
    localStorage.setItem('broker', 'withoutBroker');
    this.createForm();
    this.hasLoadedContent = new BehaviorSubject(false);
    this.landingService.annuityData.subscribe(data => {
      this.mapKontent(data);
      this.hasLoadedContent.next(true);
    });

    this.utilService.getQuestion('ageRange').then(data => {
      this.ageRangeMap = data['question']['answerGroup'].answers;
    });
    
    this.utilService.getQuestion('annuitiesSituation').then(data => {
      this.annuitiesSituationMap = data['question']['answerGroup'].answers;
    });
  }

  ngAfterViewInit(): void {
    this.hasLoadedContent.subscribe(res => {
      if (res) {
        // bind event listener to SoE link in header
        this.elementRef.nativeElement.querySelector('.soe-header-link').addEventListener("click", () => {
          this.soeModal();
        });
      }
    })
  }

  createForm() {
    this.contactForm = this.fb.group({
      firstName: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
      lastName: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
      email: ['', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur',
      }],
      zipCode: this.fb.control('', [Validators.required], zipCodeValidator(this.utilService)),
      phoneNumber: ['', {
        updateOn: 'blur',
      }],
      ageRange: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
      annuitySituation: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
    });

    const validationMessages = {
      zipCode: {
        required: 'Required',
        invalidZip: 'Invalid Zip Code'
      }
    };

    this.errorMessageProcessor = new ErrorMessageProcessor(validationMessages);
    this.errorMessages = this.errorMessageProcessor.processMessages(this.contactForm);

    this.contactForm.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.errorMessages = this.errorMessageProcessor.processMessages(this.contactForm);
    });
  }

  onSubmit() {
    this.disableSubmit = true;

    if (this.contactForm.valid) {

      let formValues = JSON.parse(JSON.stringify(this.contactForm.value));

      this.ageRangeMap.forEach(element => {
        if(element.text === this.contactForm.controls['ageRange'].value){
          formValues['ageRange'] = element.value;
        }
      });

      this.annuitiesSituationMap.forEach(element => {
        if(element.text === this.contactForm.controls['annuitySituation'].value){
          formValues['annuitySituation'] = element.value;
        }
      });
      
      this.cas.submitConsentForAnnuities(formValues).subscribe(() => {
          localStorage.setItem('landing_page', 'annuities');
          window.location.href = '/age-well-planner/pages/thank-you';
      }, err => {
        console.log(err);  
      });
    } else {
      this.contactForm.markAllAsTouched();
      this.contactForm.updateValueAndValidity();
      this.disableSubmit = false;
    }
  }

  soeModal(){
    this.modalService.getModalData(this.data.standards_of_excellence_modal[0])
    .then(data => {
      this.heading = data.heading;
      this.description = data.description;
      this.backgroundImage = data.backgroundImage;
      this.soe_data = data.soeData;
    })
    .then(() => {
      this.open = 'true';
      this.modalService.open('soe_modal');
    });
  }

  openTooltip( event: Event ): void {
    let _currentTarget = event.currentTarget as Element | any;
    _currentTarget.classList.add('is-open');
    this.activeElementTooltip = _currentTarget;
    this.fillCurrentTooltip(true);
    event.stopPropagation();
  }

  fillCurrentTooltip(toFill) {
    if (toFill) {
      let _temp = document.querySelectorAll(`icon-is-active`);
      _temp.forEach(i => {
        i.classList.remove('icon-is-active');
      });
      this.activeElementTooltip.classList.add('icon-is-active');
    }
    else {
      this.activeElementTooltip.classList.remove('icon-is-active');
    }
  }

  closeTooltip( event: Event ): void {
    let _currentTarget = event.currentTarget as Element;
    _currentTarget.parentElement.parentElement.classList.remove('is-open');
    this.fillCurrentTooltip(false);
    event.stopPropagation();
  }

  mapKontent(data) {
    this.data = data;
    this.headerBodyText.nativeElement.innerHTML = this.data.page_header_body_text;
  }

  get firstName() { return this.contactForm.get('firstName'); }
  get lastName() { return this.contactForm.get('lastName'); }
  get zipCode() { return this.contactForm.get('zipCode'); }
  get email() { return this.contactForm.get('email'); }
  get phoneNumber() { return this.contactForm.get('phoneNumber'); }
  get ageRange() { return this.contactForm.get('ageRange');}
  get annuitySituation() { return this.contactForm.get('annuitySituation'); }

}
