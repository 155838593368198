<section class="component featured-tool-breaker component--benefits">
  <div class="featured-tool-breaker__container">
    <div class="featured-tool-breaker__wrapper form">
      <div class="image-section">
        <p class="eyebrow">&nbsp;</p>
        <div class="border">
          <img *ngIf="!image.responsive" [attr.src]="image.url" [attr.alt]="image.alt" [attr.title]="image.alt">
          <picture *ngIf="image.responsive">
            <source *ngFor="let item of image.responsive" [srcset]="item.url" [media]="item.media" />
            <img [src]="image.responsive[0].url" [alt]="image.alt" />
          </picture>
        </div>
      </div>
      <div class="texts-section">
        <div class="headline headline-form" [innerHTML]="headline"></div>
        <div class="description" [innerHTML]="description"></div>

        <form class="benefits">
          <ul>
            <li *ngFor="let checkbox of selection">
              <label [for]="checkbox.value">
                <input type="checkbox" name="verticals" [id]="checkbox.value" [value]="checkbox.isSelected" [(ngModel)]="checkbox.isSelected" />
                <span class="decorator"></span>
                {{ checkbox.name }}
              </label>
            </li>
            <li><button role="button" aria-label="Select all Options for Benefits" (click)="selectAll()">Select All</button></li>
          </ul>

          <div class="benefits--submit">
            <select [ngClass]="hasError ? 'error' : ''" name="state" class="btn-state" [(ngModel)]="state" aria-label="Select State">
              <option value="">Select State</option>
              <option *ngFor="let option of stateOptions" [value]="option.value">
                {{ option.text }}
              </option>
            </select>
            <div *ngIf="hasError" class="error">Please select a state</div>
            <input type="submit" value="Find Benefits" (click)="submit($event)">
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
