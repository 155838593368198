import { Component, Input, OnInit } from '@angular/core';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-global-footer-planner',
  templateUrl: './global-footer-planner.component.html',
  styleUrls: ['./global-footer-planner.component.scss']
})
export class GlobalFooterPlannerComponent implements OnInit {

  footerData: {
    logo: {
      src: string;
      alt: string;
    };
    mainNavigation: { url: string, label: string }[];
    subNavigation: { url: string, label: string }[];
    socialHeader: string;
    socialNavigation: { url: string, label: string, iconClass: string }[];
    copyright: string;
  } = null;

  copyright: string;

  constructor(
    private tools: ToolsPrimaryConfigService,
    private tracking: DataLayerService,
  ) {
    if (this.tools.currentConfig) {
      this.footerData = this.tools.currentConfig.footerData;
    }
  }

  ngOnInit(): void { }

  trackEvent( item: Event ) {
    const _element = item.currentTarget as HTMLAnchorElement;
    const _clickUrl = _element.href || _element.getAttribute('href');

    if(_element.classList.contains('noneUrlLink')) {
      item.preventDefault();
      return false;
    }


    this.tracking.push({
      'event': 'footerClick',
      'url': _clickUrl
    },false);
  }

  trackSocial( item: Event ) {
    const _element = item.currentTarget as HTMLElement;
    const _componentType = 'Global Footer';
    const _socialNetwork = _element.dataset.social;

    this.tracking.push({
      'event': 'socialFollow',
      'socialNetwork': _socialNetwork,
      'componentType': _componentType
    });
  }
}
