import { Component, OnInit, Input } from '@angular/core';
import { paths } from './special-pathing.types';

@Component({
  selector: 'ncoa-special-pathing',
  templateUrl: './special-pathing.component.html',
  styleUrls: ['./special-pathing.component.scss']
})
export class SpecialPathingComponent implements OnInit {
  @Input() paths: paths[];

  constructor() { }

  ngOnInit(): void {
  }

  clickCTA(path) {
    if (path.external) window.open(path.url, "_blank");
    else window.open(path.url, '_self');
  }

}
