import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ncoa-single-col-card',
  templateUrl: './single-col-card.component.html',
  styleUrls: ['./single-col-card.component.scss']
})
export class SingleColCardComponent implements OnInit {

  @Input() heading: string;
  @Input() cards: [any]
  
  constructor() { }

  ngOnInit(): void {
  }

}
