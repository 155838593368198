import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { EventHeroImage } from './event-hero.types';

@Component({
  selector: 'ncoa-event-hero',
  templateUrl: './event-hero.component.html',
  styleUrls: ['./event-hero.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EventHeroComponent implements OnInit {
  @Input() image: EventHeroImage;
  @Input() title: string;
  @Input() event_start: any;
  @Input() event_end: any;
  @Input() registration_link: string;
  @Input() isPast: boolean;
  @Input() disregardEndTime: boolean;
  @Input() partnership: any;
  @Input() sponsorship_indication_text: '';
  @Input() width: number;
  @Input() height: number;

  startDateString: string;
  startTime: string;
  endDateString: string;
  endTime: string;
  titleScale: string = '';

  constructor(private dataLayerService: DataLayerService) { }

  ngOnInit(): void {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    this.startDateString = `${months[this.event_start.month]} ${this.event_start.day.toString().padStart(2, '0')}, ${this.event_start.year}`;
    this.endDateString = `${months[this.event_end.month]} ${this.event_end.day.toString().padStart(2, '0')}, ${this.event_end.year}`;

    this.startTime = `${((this.event_start.hours % 12) || 12).toString().padStart(2, '0')}:${this.event_start.minutes.toString().padStart(2, '0')} ${this.event_start.hours < 12 || this.event_start.hours === 24 ? 'AM' : 'PM'}`;
    this.endTime = `${((this.event_end.hours % 12) || 12).toString().padStart(2, '0')}:${this.event_end.minutes.toString().padStart(2, '0')} ${this.event_end.hours < 12 || this.event_end.hours === 24 ? 'AM' : 'PM'}`;

    const titleLength = this.title?.length || 0;

    switch (true) {

      case (titleLength < 30):
        this.titleScale = 'larger';
        break;

      case (titleLength >= 30 && titleLength <= 50):
        this.titleScale = 'large';
        break;

      default:
        this.titleScale = 'default';
    }
  }

  onPrintClick(event: MouseEvent) {
    event.preventDefault();

    // Show invisible components first
    const bylines = document.querySelectorAll('.component--authors-byline');
    bylines.forEach(byline => {
      byline.setAttribute('style', 'opacity: 1');
    });

    (window as any).print();
  }

  onAddToCalendarClick() {
    const uuidv4 = (): string => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    };

    const convertDate = (date, dateReady = false): string => {
      let iso: any = dateReady
        ? date.toISOString()
        : new Date(date).toISOString();

      return iso.replace(/[.|:|-]/g,'').replace('000Z','Z');
    };

    const startDate = new Date(
      this.event_start.year,
      this.event_start.month,
      this.event_start.day,
      this.event_start.hours,
      this.event_start.minutes
    );

    const endDate = new Date(
      this.event_end.year,
      this.event_end.month,
      this.event_end.day,
      this.event_end.hours,
      this.event_end.minutes,
    );

    const eventData =
      'BEGIN:VCALENDAR\n' +
      'VERSION:2.0\n' +
      'CALSCALE:GREGORIAN\n' +
      'PRODID:NCOA\n' +
      'METHOD:PUBLISH\n' +
      'X-PUBLISHED-TTL:PT1H\n' +
      'BEGIN:VEVENT\n' +
      `UID:${uuidv4()}\n` +
      `SUMMARY:${this.title}\n` +
      `DTSTAMP:${convertDate(new Date(), true)}\n` +
      `DTSTART:${convertDate(startDate, true)}\n` +
      `DTEND:${convertDate(endDate, true)}\n` +
      `${this.registration_link ? `URL:${this.registration_link}\n` : ''}` +
      'END:VEVENT\n' +
      'END:VCALENDAR\n';

    const data = new Blob([eventData], { type: 'text/plain;charset=utf-8' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(data, `${this.title.replace(/ /g, '').toLowerCase()}.ics`);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';

      const url = window.URL.createObjectURL(data);

      a.href = url;
      a.download = `${this.title.replace(/ /g, '').toLowerCase()}.ics`;
      a.target = '_blank';
      a.click();

      window.URL.revokeObjectURL(a.href);

      a.remove();
    }

    const url = location.href;

    this.dataLayerService.push({
      addToCalendar: url,
      eventTitle: this.title,
      eventDate: this.startDateString,
    });
  }

}
