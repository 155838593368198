<div class="loading-overlay" *ngIf="loading">
    <img src="../../../../assets/images/loader.gif" alt="Loading" />
</div>

<ng-container *ngIf="data && !loading">

    <age-well-planner>
        <app-cm-banner ></app-cm-banner>

        <ng-container *ngIf="!showScreeningAnswers; else showSavedScreeningAnswers">

            <div *ngIf="!currentTodo; else todoPage" class="vertical-plan-content">
                <section class="plan-header">

                    <div class="header-title-group">
                        <div class="header-title-group-first">
                            <h1>{{data.ncoaVerticalLandingHeader.title}}
                                <svg (click)="showInstructions = !showInstructions" width="20" height="20"
                                viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="9" stroke="#0B4A5D" stroke-width="2" />
                                <path
                                    d="M10.8491 12.1873H8.97768C9.01578 11.5986 9.19197 11.1174 9.50626 10.7436C9.83007 10.3699 10.1634 10.0382 10.5063 9.74849C10.8301 9.46817 11.111 9.18784 11.3491 8.90752C11.5967 8.61785 11.7205 8.27212 11.7205 7.87032C11.7205 7.44049 11.5777 7.10411 11.292 6.86116C11.0063 6.60887 10.6205 6.48272 10.1348 6.48272C9.47768 6.48272 8.97768 6.71165 8.63483 7.16952C8.29197 7.62738 8.15387 8.24876 8.22054 9.03367L6.40625 8.12262C6.41577 7.63672 6.51577 7.19288 6.70625 6.79108C6.89673 6.37994 7.15387 6.02953 7.47768 5.73987C7.81101 5.44085 8.20149 5.21192 8.64911 5.05307C9.10625 4.88488 9.60149 4.80078 10.1348 4.80078C10.6396 4.80078 11.1063 4.87553 11.5348 5.02504C11.9634 5.1652 12.3301 5.3661 12.6348 5.62774C12.9396 5.88937 13.1777 6.20707 13.3491 6.58084C13.5205 6.9546 13.6063 7.36107 13.6063 7.80024C13.6063 8.1927 13.5539 8.5431 13.4491 8.85146C13.3444 9.15981 13.2063 9.43546 13.0348 9.67841C12.8634 9.91201 12.6729 10.1269 12.4634 10.3232C12.2634 10.5194 12.0634 10.7016 11.8634 10.8698C11.6063 11.0847 11.3777 11.2949 11.1777 11.5005C10.9872 11.7061 10.8777 11.935 10.8491 12.1873ZM11.0205 15.2008H8.77768V13.1124H11.0205V15.2008Z"
                                    fill="#0B4A5D" />
                            </svg>
                        </h1>

                      
                        </div>
                        <div class="header-title-group-second">

                            <button class="plan-svg" (click)="clickStartOver()" [attr.aria-label]="startOverOrEditCTA">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M28 14.6666C27.6463 14.6666 27.3072 14.8071 27.0572 15.0572C26.8071 15.3072 26.6666 15.6463 26.6666 16C26.6825 18.4973 25.827 20.9221 24.2475 22.8566C22.668 24.7911 20.4633 26.1142 18.0132 26.5981C15.5632 27.082 13.021 26.6964 10.8246 25.5077C8.62826 24.3189 6.91517 22.4015 5.98039 20.0856C5.04562 17.7697 4.94768 15.2004 5.70345 12.8201C6.45923 10.4397 8.02139 8.39747 10.1209 7.045C12.2204 5.69252 14.7258 5.11451 17.2056 5.41051C19.6855 5.7065 21.9844 6.85798 23.7066 8.66663H20.5066C20.153 8.66663 19.8139 8.8071 19.5638 9.05715C19.3138 9.3072 19.1733 9.64634 19.1733 9.99996C19.1733 10.3536 19.3138 10.6927 19.5638 10.9428C19.8139 11.1928 20.153 11.3333 20.5066 11.3333H26.5466C26.9003 11.3333 27.2394 11.1928 27.4894 10.9428C27.7395 10.6927 27.88 10.3536 27.88 9.99996V3.99996C27.88 3.64634 27.7395 3.3072 27.4894 3.05715C27.2394 2.8071 26.9003 2.66663 26.5466 2.66663C26.193 2.66663 25.8539 2.8071 25.6038 3.05715C25.3538 3.3072 25.2133 3.64634 25.2133 3.99996V6.35996C22.9929 4.23737 20.1076 2.94656 17.0453 2.70573C13.983 2.4649 10.9315 3.28882 8.40652 5.03821C5.88158 6.78761 4.03811 9.35517 3.18773 12.3069C2.33736 15.2586 2.53224 18.4134 3.73944 21.238C4.94664 24.0626 7.0921 26.3838 9.81316 27.8091C12.5342 29.2344 15.664 29.6765 18.6734 29.0606C21.6827 28.4447 24.3872 26.8086 26.3295 24.4289C28.2718 22.0491 29.3329 19.0717 29.3333 16C29.3333 15.6463 29.1928 15.3072 28.9428 15.0572C28.6927 14.8071 28.3536 14.6666 28 14.6666Z" fill="#0B4A5D"/>
                                  </svg>

                                <div>
                                    <span>
                                        {{startOverOrEditCTA}} </span>
                                </div>
                            </button>

                            <button *ngIf="isSaved" class="plan-svg" (click)="logOut()"
                                [attr.aria-label]='data.savePlanCTA'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                    <path d="M23.1667 9.52181L21.2867 11.4018L24.7267 14.8551H11.1667V17.5218H24.7267L21.2867 20.9618L23.1667 22.8551L29.8334 16.1885M5.83341 6.85514H16.5001V4.18848H5.83341C4.36675 4.18848 3.16675 5.38848 3.16675 6.85514V25.5218C3.16675 26.9885 4.36675 28.1885 5.83341 28.1885H16.5001V25.5218H5.83341V6.85514Z" fill="#0B4A5D"/>
                                  </svg>
                                <div>
                                    <span>
                                        {{data?.logoutCTA}}
                                    </span>
                                </div>
                                </button>
<!-- 
                            <button *ngIf="!isSaved" class="plan-btn" (click)="navToSignIn()"
                                [attr.aria-label]='data?.savePlanCTA'>

                                <div>
                                    <span>
                                        {{data.savePlanCTA}}
                                    </span>
                                </div>
                            </button> -->
                        </div>
                    </div>

                    <div *ngIf="(data.bannerText || bannerSubText) && !isSaved" class="plan-header-banner">
                        <div *ngIf="data.bannerText" class="banner">
                            {{data.bannerText}}
                                        <button (click)="navToSignIn()" class="plan-btn">
                                            {{data.bannerCta}}
                                        </button>
                        </div>
                        <span *ngIf="bannerSubText" [innerHTML]="bannerSubText"></span>

                    </div>


                    <div class="plan-header-instructions" *ngIf="showInstructions">
                        <span>{{data.ncoaVerticalLandingHeader.desc}}</span>

                        <span class="close-button" (click)="showInstructions = false">&#x2715;</span>

                    </div>

                </section>

                <section #header class="plan-to-do-lists-headers" [ngClass]="{'sticky': headerSticky}">

                    <div (click)="currentTodoList = 'activeTodosRef'" class="lists-header selected"
                        [ngClass]="{'selected': currentTodoList === 'activeTodosRef'}">
                        <h1>{{data.todoList.active_todos_title}}</h1>

                        <div class="list-header-number">
                            <span>{{activeTodosTotal}}</span>
                        </div>

                    </div>

                    <div (click)="currentTodoList = 'completedTodosRef'" class="lists-header"
                        [ngClass]="{'selected': currentTodoList === 'completedTodosRef'}">
                        <h1>{{data.todoList.completed_todos_title}}</h1>

                        <div class="list-header-number">
                            <span>{{completedTodosTotal}}</span>
                        </div>

                    </div>

                    <div (click)="currentTodoList = 'archivedTodosRef'" class="lists-header"
                        [ngClass]="{'selected': currentTodoList === 'archivedTodosRef'}">
                        <h1>{{data.todoList.archived_todos_title}}</h1>

                        <div class="list-header-number">
                            <span>{{archivedTodosTotal}}</span>
                        </div>

                    </div>

                </section>

                <section class="plan-to-do-lists" [ngClass]="
                {'active': currentTodoList === 'activeTodosRef', 
                'completed': currentTodoList === 'completedTodosRef', 
                'archived': currentTodoList === 'archivedTodosRef',
                'no-to-dos': (currentTodoList === 'activeTodosRef' && !activeTodos.length) ||
                             (currentTodoList === 'completedTodosRef' && !completedTodos.length) || 
                             (currentTodoList === 'archivedTodosRef' && !archivedTodos.length)
                }">

                    <div class="list">
                        <ng-container [ngTemplateOutlet]="this[currentTodoList]"></ng-container>
                    </div>


                    <div class="plan-to-do-lists-no-to-dos"
                        *ngIf="!activeTodos.length || !completedTodos.length || !archivedTodos.length">

                        <ng-container [ngSwitch]="currentTodoList">

                            <ng-container *ngSwitchCase="'activeTodosRef'">
                                <h3 *ngIf="!activeTodos.length">{{data.todoList.no_active_to_dos}}</h3>
                            </ng-container>

                            <ng-container *ngSwitchCase="'completedTodosRef'">
                                <h3 *ngIf="!completedTodos.length">{{data.todoList.no_completed_to_dos}}</h3>
                            </ng-container>

                            <ng-container *ngSwitchCase="'archivedTodosRef'">
                                <h3 *ngIf="!archivedTodos.length">{{data.todoList.no_archived_to_dos}}</h3>
                            </ng-container>


                        </ng-container>

                    </div>

                </section>
                <!--  Removed 
                <button *ngIf="!isSaved" class="save-btn" (click)="clickSave()" [attr.aria-label]="data.savePlanCTA"> 
                    {{data.savePlanCTA}}
                </button> -->

                <section class="plan-view-saved-answers">
                    <button (click)="viewAnswers()">{{data.todoList.view_answers}}</button>
                </section>

            </div>

            <ng-template #todoPage>
                <app-todo-page (onComplete)="onTodoAction($event, 'onComplete')"
                    (onUnarchived)="onTodoAction($event, 'onUnarchived')"
                    (onIncomplete)="onTodoAction($event, 'onIncomplete')"
                    (onArchived)="onTodoAction($event, 'onArchived')" (onUpdateListTotal)="onUpdateListTotal($event)"
                    [content]="data.todoList" [todo]="currentTodo"></app-todo-page>
            </ng-template>


        </ng-container>


        <ng-template #showSavedScreeningAnswers>
            <div class="deletion-options-container">
                <section class="page-content">
                    <div class="page-content__wrapper">
                        <div class="page-content__inner_wrapper"
                            [innerHTML]="data.planDeletion.plan_deletion_disclaimer_part_one"></div>

                        <div class="deletion-button-container">
                            <button (click)="onDeletePlan()">{{data.planDeletion.plan_delete_button_text}}</button>
                        </div>


                        <div class="page-content__inner_wrapper"
                            [innerHTML]="data.planDeletion.plan_deletion_disclaimer_part_two"></div>
                    </div>
                </section>



                <!-- <section class="page-content">
                    <div class="page-content__wrapper">
                  
                    </div>
                  </section> -->
            </div>


            <ncoa-vertical-assessment [assessmentCode]="screeningCode">



            </ncoa-vertical-assessment>
        </ng-template>


    </age-well-planner>


</ng-container>




<ng-template #activeTodosTemplate>

    <app-to-do-list [content]="data.todoList" [source]="data.planType" (onComplete)="onTodoAction($event, 'onComplete')"
        (onArchived)="onTodoAction($event, 'onArchived')" (onUpdateListTotal)="onUpdateListTotal($event)"
        [todos]="activeTodos">
    </app-to-do-list>

</ng-template>

<ng-template #completedTodosTemplate>

    <app-to-do-list [content]="data.todoList" [source]="data.planType"
        (onIncomplete)="onTodoAction($event, 'onIncomplete')" (onArchived)="onTodoAction($event, 'onArchived')"
        (onUpdateListTotal)="onUpdateListTotal($event)" [todos]="completedTodos">
    </app-to-do-list>

</ng-template>

<ng-template #archivedTodosTemplate>

    <app-to-do-list [content]="data.todoList" [source]="data.planType" (onComplete)="onTodoAction($event, 'onComplete')"
        (onIncomplete)="onTodoAction($event, 'onIncomplete')" (onUnarchived)="onTodoAction($event, 'onUnarchived')"
        (onUpdateListTotal)="onUpdateListTotal($event)" [todos]="archivedTodos">
    </app-to-do-list>

</ng-template>