import { Component, Input, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-vertical-pathing',
  templateUrl: './vertical-pathing.component.html',
  styleUrls: ['./vertical-pathing.component.scss']
})
export class VerticalPathing implements OnInit, AfterViewInit {

  @ViewChildren('paths') paths:QueryList<any>;

  @Input() heading: string;
  @Input() items: {
    title: string;
    links: {
      text: string;
      url: string;
    }[];
  }[];

  constructor(private dataLayerService: DataLayerService) { }


  ngOnInit(): void {
  }

  onResize(): void {
    if (this.paths && this.paths.length > 0) {
      setTimeout(() => {
        const max = Math.max.apply(Math, this.paths.map((pathTitle) => {
          pathTitle.nativeElement.style.removeProperty('height');
          return pathTitle.nativeElement.getBoundingClientRect().height;
        }));

        this.paths.map((pathTitle) => {
          pathTitle.nativeElement.style.height = `${max}px`;
        });
      }, 500);

    }
  }

  ngAfterViewInit(): void {
    this.onResize();
  }


  trackAnalytics(ev){
    const _element = ev.currentTarget as HTMLAnchorElement;
    const _clickUrl = _element.href || _element.getAttribute('href');
    this.dataLayerService.push({ 
      'event': 'recirc', 
      'url': _clickUrl,
      'componentType': 'vertical pathing' },false); 
  }
}
