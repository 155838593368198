import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { AuthorService } from '@services/author.service';
import { GlobalService } from '@services/global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorResolverService {

  constructor(
    private authorService: AuthorService,
    private globalService: GlobalService
  ) { }

  async resolve(activeRoute: ActivatedRouteSnapshot) {
    this.globalService.saveCurrentURL(`/${activeRoute.url.join('/')}`);

    const [ data ] = await Promise.all([
      this.authorService.getAuthorData(activeRoute.params.author),
      this.globalService.loadGlobalComponentsData()
    ]);

    return data;
  }
}
