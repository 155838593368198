<age-well-planner #pageContent *ngIf="!thanks && !sponsors && !smallHeader">
  <ng-container *ngFor="let component of components; index as order" [ngSwitch]="component?.componentType">
  
    <ncoa-standard-page-header *ngSwitchCase="'header'" [image]="component.primary_image.value[0]"
      [headline]="component.title.value" [description]="component.description.value" ncoa-engageable={{order}}>
    </ncoa-standard-page-header>
    <div id = 'main-content' class = 'standardPageBody'>
      <ncoa-article-body *ngSwitchCase="'body'" [content]="component.value" 
      [requiredFieldError]="component.requiredFieldError" ncoa-engageable={{order}}></ncoa-article-body>
    </div>
    
  </ng-container>
</age-well-planner>

<age-well-planner #pageContent *ngIf="sponsors">
  <ng-container *ngFor="let component of components; index as order" [ngSwitch]="component?.componentType">
  
    <ncoa-standard-page-header *ngSwitchCase="'header'" [image]="component.primary_image.value[0]"
      [headline]="component.title.value" [description]="component.description.value" ncoa-engageable={{order}}>
    </ncoa-standard-page-header>
    <div id = 'main-content' class = 'standardPageBody'>
      <ncoa-sponsors *ngSwitchCase="'sponsors'" [sponsors]="component.sponsors" [sponsorHeader]="component.sponsorHeader" [sponsorFooter]="component.sponsorFooter" ncoa-engageable={{order}}></ncoa-sponsors>
    </div>
    
  </ng-container>
</age-well-planner>

<age-well-planner #pageContent *ngIf="thanks">
  <ng-container *ngFor="let component of components; index as order" [ngSwitch]="component?.componentType">
  
    <landing-page-header *ngSwitchCase="'header'" [image]="component.primary_image.value[0]"
      [headline]="component.title.value" [description]="component.description.value" ncoa-engageable={{order}}>
    </landing-page-header>
    <div id = 'main-content' class = 'standardPageBody'>
      <app-thank-you *ngSwitchCase="'body'" ncoa-engageable={{order}}></app-thank-you>
    </div>
    <landing-page-content-package *ngSwitchCase="componentTypes.content_package" [title]="component.title"
      [articles]="component.articles" ncoa-engageable={{order}}></landing-page-content-package>
  </ng-container>
</age-well-planner>

<div #pageContent *ngIf="smallHeader">
  <ng-container *ngFor="let component of components; index as order" [ngSwitch]="component?.componentType">
    <article-detail-header *ngSwitchCase="'header'"
      [title]="component.title.value"
      [eyebrow]="component.description.value"
    ></article-detail-header>
    <div id = 'main-content' class = 'standardPageBody'>
      <ncoa-article-body *ngSwitchCase="'body'" [content]="component.value" 
      [requiredFieldError]="component.requiredFieldError" ncoa-engageable={{order}}></ncoa-article-body>
    </div>
  </ng-container>
  <div class='partner-grid'>
    <awp-partner-grid
        *ngIf="partnerGrid"
        [heading]="partnerGrid.elements.title.value"
        [images]="partnerGrid.elements.images.value"
        [blurb]="partnerGrid.elements.content_blurb.value"
    ></awp-partner-grid>
  </div>
</div>