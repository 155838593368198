import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, AfterViewInit, ElementRef, ViewChild, ChangeDetectorRef, ComponentFactoryResolver, Renderer2, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicFormsComponent } from '@components/dynamic-forms/dynamic-forms.component';
import { EmbeddedJsComponent } from '@components/embedded-js/embedded-js.component';
import { FormsService } from '@services/forms.service';
import { PdfService } from '@services/pdf.service';
import 'mdn-polyfills/Node.prototype.replaceWith';

@Component({
  selector: 'ncoa-article-body',
  templateUrl: './article-body.component.html',
  styleUrls: ['./article-body.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ArticleBodyComponent implements OnInit, AfterViewInit {

  @Input() content: string;
  @Input() styleConfig: any;
  @Input() url: {
    facebook: string,
    twitter: string,
    linkedin: string,
    pinterest: string,
    mailto: string
  }
  @Input() articleType: 'article' | 'event' = 'article';

  @Input() readingTime?: number;
  @Output() updateReadingTime: EventEmitter<any> = new EventEmitter();
  @Input() requiredFieldError: string = 'This field is required';

  //Save to Content for tools
  @Input() saveToContent?: {
    isSaved: false,
  };

  @Output() saveToContentClick?: EventEmitter<any> = new EventEmitter();

  @ViewChild('embeddedJS') embeddedJS: EmbeddedJsComponent;

  @Input() styleVersion: 'v1' | 'v2' = 'v1';

  scriptSections: string;
  scriptBeforeBody: boolean = true;
  scriptAfterBody: boolean = true;

  embeddedClass: string = 'beforeBody';

  constructor(
    private sanitizer: DomSanitizer,
    private formsService: FormsService,
    private pdfService: PdfService,
    private elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.extractEmbeddedScriptTags();
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.content) as string;
  }

  extractEmbeddedScriptTags() {
    let scriptSections = [];
    let cleanContent = this.content;
    if (!cleanContent) {
      return;
    }
    while (cleanContent.includes('<script ')) {
      const start = cleanContent.indexOf('<script');
      const end = cleanContent.indexOf('</script>') + 9;

      const st = cleanContent.substring(start, end);
      scriptSections.push(st);

      cleanContent = cleanContent.replace(st, '');
    }
    this.content = cleanContent;
    this.scriptSections = scriptSections.join('');
  }

  ngAfterViewInit() {
    if (this.embeddedJS) {
      this.embeddedJS.scriptTags = this.scriptSections;
      this.embeddedJS.convertToScript();
      this.embeddedJS.setStyle(this.styleConfig);
    }

    if (this.styleConfig?.position?.value?.length > 0) {
      if (this.styleConfig.position.value[0].name === 'after')
        this.scriptBeforeBody = false;
      else
        this.scriptAfterBody = false;
    }

    setTimeout(() => {
      this.checkInView();
      this.checkContent();
      this.findFirstParagraph();
      this.decoratePullQuote();
      this.decorateImage();
      this.decorateTable();
      this.processDownloads();
      this.processLeaderShip();
      this.processVoiterVoice();
      this.processAnchorLinks();

      if (!this.readingTime) {
        this.computeReadingTime();
      }

      this.pdfService.processPDFs();
      const everyActionUrls = this.formsService.processForms()
      everyActionUrls.forEach(url => {
        DynamicFormsComponent.createDynamicForm(url, this.requiredFieldError, this.componentFactoryResolver, this.renderer, this.changeDetectorRef, this.viewContainerRef);
      });

      this.removeEmptyTags();
    }, 0);
  }

  checkInView() {
    const _articleBody = <HTMLElement>document.querySelector('.article-query-selector');

    if (!_articleBody) {
      return;
    }

    const _articleShare = <HTMLElement>document.querySelector('.article--share');
    const _pullQuotes = _articleBody.querySelectorAll('blockquote');

    let initialPos = window.pageYOffset;
    let ctr1 = 1;
    let ctr2 = 1;

    window.addEventListener('scroll', () => {

      if (_articleShare) {
        let _articleBodyBottom = _articleBody.getBoundingClientRect().bottom;
        let _articleShareBottom = _articleShare.getBoundingClientRect().bottom;
        let _articleShareLeft = _articleShare.getBoundingClientRect().left + _articleShare.clientWidth;

        if (this.isInView(_articleShare, window.innerHeight)) {
          _articleShare.classList.add('in-view');
        }

        if (this.isInView(_articleBody, 0 && _articleBodyBottom > window.innerHeight / 2)) {
          _articleShare.classList.add('is-sticky');

          if (_articleBodyBottom > _articleShareBottom) {
            _articleShare.classList.remove('is-absolute');
          } else if (_articleBodyBottom > window.innerHeight) {
            _articleShare.classList.remove('is-absolute');
          } else {
            _articleShare.classList.add('is-absolute');
          }

        } else {
          _articleShare.classList.remove('is-sticky');
          _articleShare.classList.remove('is-absolute');
        }
      }

      _pullQuotes?.forEach(_quote => {
        if (this.isInView(_quote, (window.innerHeight / 2))) {
          _quote.classList.add('in-view');
        }


        const _rect = _quote.getBoundingClientRect();

        if (_rect.top < window.innerHeight && _rect.bottom > 0) {
          let _currentPos = window.pageYOffset;

          const _greenOval = <HTMLElement>_quote.querySelector('.green-oval');
          const _coralOval = <HTMLElement>_quote.querySelector('.coral-oval');

          if (initialPos > _currentPos) {
            _greenOval.style.setProperty('--greenOvalPosY', `${ctr1 -= 4}px`);
            _coralOval.style.setProperty('--coralOvalPosY', `${ctr2 -= 4}px`);
          } else {
            _greenOval.style.setProperty('--greenOvalPosY', `${ctr1 += 4}px`);
            _coralOval.style.setProperty('--coralOvalPosY', `${ctr2 += 4}px`);
          }

          initialPos = _currentPos;
        }

        if ((window.scrollY || window.pageYOffset) < _rect.top) {
          ctr1 = 1;
          ctr2 = 1;
        }

      });

    });
  }

  isInView(_element, _offset) {
    let _componentPosition = _element.getBoundingClientRect();

    if (_componentPosition.top <= _offset) {
      return true;
    } else {
      return false;
    }
  }

  checkContent() {
    const componentWrapper = this.elementRef.nativeElement;

    if (componentWrapper) {
      const bodyContainer = componentWrapper.querySelector('.article-body__container');
      const iframeContent = componentWrapper.querySelector('iframe');
      const imageContent = componentWrapper.querySelector('img');
      const textContent = componentWrapper.textContent.trim();

      if (bodyContainer && (iframeContent || imageContent || textContent)) {
        bodyContainer.classList.add('has-content');
      }
    }
  }

  findFirstParagraph(): void {
    if (document.querySelector('.article-body__wrapper')) {

      const bodyContainer = document.querySelector('.article-body__inner-wrapper');

      if (bodyContainer.firstChild && (bodyContainer.firstChild.nodeName === '#text' || bodyContainer.firstChild.nodeName === 'P')) {
        bodyContainer.classList.add('has-content');
      }

      const firstParagraph = <HTMLElement>document.querySelector('.article-body__wrapper > p');
      const firstChild = <HTMLElement>document.querySelector('.article-body__wrapper').firstChild;

      if (firstParagraph) {
        const isParagraphFirstChild = firstParagraph == firstChild;

        if (isParagraphFirstChild) {
          firstParagraph.classList.add('first-paragraph');
        }
      }
    }
  }

  decoratePullQuote(): void {
    if (document.querySelector('.article-body__wrapper blockquote')) {
      const pullQuotes = Array.from(document.querySelectorAll('.article-body__wrapper blockquote'));

      pullQuotes.forEach(element => {
        const greenOval = `
          <svg class="green-oval" width="62" height="124" viewBox="0 0 62 124" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M-2.16808e-05 124C34.2417 124 62 96.242 62 62.0003C62 27.7585 34.2418 5.98702e-06 0 0" fill="#E1F4EB"/>
          </svg>`;

        const coralOval = `
          <svg class="coral-oval" width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="43" cy="43" r="43" fill="#FEECEC"/>
          </svg>`;

        const quote = `
          <svg width="144" height="124" viewBox="0 0 144 124" fill="none" xmlns="http://www.w3.org/2000/svg" class="quote">
            <path d="M0.00012207 124H67.0001V0H50.0001C22.3859 0 0.00012207 22.3858 0.00012207 50V124Z" fill="#FA6264"/>
            <path d="M77.0001 124H144V0H127C99.3859 0 77.0001 22.3858 77.0001 50V124Z" fill="#FA6264"/>
          </svg>
          `;

        element.innerHTML = `
          ${greenOval}
          <div class="main">
            ${quote}
            ${element.innerHTML}
          </div>
          ${coralOval}
        `;
      });
    }
  }

  decorateImage(): void {
    if (document.querySelector('.article-body__wrapper img')) {
      const images = Array.from(document.querySelectorAll('.article-body__wrapper img'));

      images.forEach(image => {
        if (image.classList.contains('partners-grid__img') || image.classList.contains('leadership-grid__img')) {
          return;
        }

        const parent: any = image.parentNode;
        if (parent.tagName === 'FIGURE') {
          parent.classList.add('inline-image');

          const imgNode = parent.querySelector('img');
          if (!imgNode.parentNode.classList.contains('image-container') && imgNode.parentNode.tagName === 'FIGURE') {
            const imageContainer = document.createElement('div');
            imageContainer.classList.add('image-container');

            const imageWrapper = document.createElement('div');
            imageWrapper.classList.add('image-wrapper');
            imageWrapper.appendChild(imgNode.cloneNode(true));

            imageContainer.appendChild(imageWrapper);

            imgNode.parentNode.replaceChild(imageContainer, imgNode);
          }

          const figCaption = parent.querySelector('figcaption');
          if (figCaption) {
            figCaption.removeAttribute('contenteditable');
          }
        } else {
          if (!parent.classList.contains('inline-image')) {
            const wrapper = document.createElement('div');
            wrapper.classList.add('inline-image');

            const imageContainer = document.createElement('div');
            imageContainer.classList.add('image-container');

            const imageWrapper = document.createElement('div');
            imageWrapper.classList.add('image-wrapper');
            imageWrapper.appendChild(image.cloneNode(true));

            imageContainer.appendChild(imageWrapper);

            wrapper.appendChild(imageContainer);

            image.parentNode.replaceChild(wrapper, image);
          }
        }
      });
    }

    if (document.querySelector('.inline-image')) {
      const inlineImages = Array.from(document.querySelectorAll('.inline-image'));

      inlineImages.forEach(inlineImage => {
        const image = inlineImage.querySelector('img');

        if (image) {
          image.onload = () => {
            const width = image.naturalWidth;
            const height = image.naturalHeight;

            if (width > height) {
              inlineImage.classList.add('landscape');
            } else if (height > width) {
              inlineImage.classList.add('portrait');
            } else if (height === width) {
              inlineImage.classList.add('square');
            }
          }
        }
      });
    }
  }

  computeReadingTime(): void {
    const body: HTMLDivElement = document.querySelector('.article-body__wrapper');

    if (body?.innerText) {
      const numberOfWords: number = body.innerText.match(/\b[-?(\w+)?]+\b/gi).length;
      const wordsPerMin = 180;
      const newReadingTime = Math.ceil(numberOfWords / wordsPerMin);

      this.updateReadingTime.emit(newReadingTime);
    }
  }

  removeEmptyTags(): void {
    const wrapper = document.querySelector('.article-body__wrapper');

    if (!wrapper) {
      return;
    }

    const ps = Array.from(wrapper.querySelectorAll('p'));

    ps.forEach((p) => {
      if (p.innerHTML.replace(/\&nbsp;/g, '').length === 0) {
        p.parentNode.removeChild(p);
      }
    });
  }




  decorateTable(): void {
    if (document.querySelector('.article-body__wrapper table')) {
      const tables = Array.from(document.querySelectorAll('.article-body__wrapper table'));

      tables.forEach(table => {
        table.classList.add('table');
        table.classList.add('table-striped');
        table.classList.add('table-bordered');
        table.classList.add('table-hover');

        table.removeAttribute('style');
        table.removeAttribute('border');
        table.removeAttribute('cellpadding');
        table.removeAttribute('cellspacing');

        let captionData = null;
        if (table.querySelector('caption')) {
          captionData = table.querySelector('caption').textContent;
          table.querySelector('caption').remove();
        }

        const newTable: any = table.cloneNode(true);

        const wrapper = document.createElement('div');
        wrapper.classList.add('inline-table__wrapper');
        wrapper.appendChild(newTable);

        const container = document.createElement('div');
        container.classList.add('inline-table__container');
        container.appendChild(wrapper);

        if (captionData) {
          const caption = document.createElement('div');
          caption.classList.add('inline-table__caption');
          caption.innerHTML = captionData;
          container.appendChild(caption);
        }

        const main = document.createElement('div');
        main.classList.add('component');
        main.classList.add('inline-table');
        main.appendChild(container);

        table.parentNode.replaceChild(main, table);

        const tableWidth = newTable.getBoundingClientRect().width;
        const containerWidth = main.getBoundingClientRect().width;

        if (tableWidth > containerWidth) {
          container.classList.remove('show-start');
          container.classList.add('show-end');

          // Create scroller buttons
          const scroller: any = document.createElement('button');
          scroller.classList.add('inline-table__scroller');

          const leftScroller: any = scroller.cloneNode();
          const rightScroller: any = scroller.cloneNode();

          leftScroller.classList.add('inline-table__scroller--left');
          rightScroller.classList.add('inline-table__scroller--right');

          leftScroller.setAttribute('aria-label', 'Click to scroll the table to left');
          rightScroller.setAttribute('aria-label', 'Click to scroll the table to right');

          main.prepend(leftScroller);
          main.appendChild(rightScroller);

          leftScroller.addEventListener('click', e => {
            e.preventDefault();
            wrapper.scrollLeft -= 30;
          });

          rightScroller.addEventListener('click', e => {
            e.preventDefault();
            wrapper.scrollLeft += 30;
          });

          wrapper.addEventListener('mousewheel', (e: any) => {
            e = window.event || e;
            const delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
            wrapper.scrollLeft -= (delta * 40);
            e.preventDefault();
          }, false);

          wrapper.addEventListener('DOMMouseScroll', (e: any) => {
            e = window.event || e;
            const delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
            wrapper.scrollLeft -= (delta * 40);
            e.preventDefault();
          }, false);

          wrapper.addEventListener('scroll', () => {
            if (wrapper.offsetWidth + wrapper.scrollLeft >= wrapper.scrollWidth) {
              container.classList.add('show-start');
              container.classList.remove('show-end');
            } else {
              container.classList.add('show-end');
              container.classList.add('show-start');
            }

            if (wrapper.scrollLeft === 0) {
              container.classList.remove('show-start');
              container.classList.add('show-end');
            }
          });
        }
      });
    }
  }

  processDownloads() {
    if (document.querySelector('.article-body__wrapper .download')) {
      const downloads = Array.from(document.querySelectorAll('.article-body__wrapper .download'));

      const getFileSize = (url, callback) => {
        const xhr = new XMLHttpRequest();

        xhr.open('HEAD', url, true);
        xhr.onreadystatechange = function () {
          if (this.readyState === this.DONE) {
            callback(parseInt(xhr.getResponseHeader('Content-Length')));
          }
        };
        xhr.send();
      };

      downloads.forEach(download => {
        const meta = download.querySelector('.file__meta');
        const btn = download.querySelector('.download__btn');

        if (meta && btn) {
          const url = btn.getAttribute('href');

          btn.setAttribute('role', 'button');

          getFileSize(url, size => {
            const kb = (size / Math.pow(1024, 1));
            const mb = (size / Math.pow(1024, 2));
            const sizeInString = mb >= 1 ? `${mb.toFixed(0)}Mb` : `${kb.toFixed(0)}Kb`;

            meta.innerHTML = `${meta.innerHTML.trim()} - ${sizeInString}`;
          });
        }
      });
    }
  }

  processLeaderShip() {
    if (document.querySelector('.article-body__wrapper .leadership-grid__img[src="profile-placeholder.svg"]')) {
      const placeholderImg = Array.from(document.querySelectorAll('.article-body__wrapper .leadership-grid__img[src="profile-placeholder.svg"]'));

      placeholderImg.forEach(img => img.setAttribute('src', '/assets/images/profile-placeholder.png'));
    }

    if (document.querySelector('.article-body__wrapper img[src="profile-placeholder.svg"]')) {
      const placeholderImg = Array.from(document.querySelectorAll('.article-body__wrapper img[src="profile-placeholder.svg"]'));

      placeholderImg.forEach(img => img.setAttribute('src', '/assets/images/profile-placeholder.svg'));
    }
  }

  processVoiterVoice() {
    const voterVoice = document.querySelector('.kontentvotervoice');
    const pageContainer = document.querySelector('body');

    if (voterVoice) {
      const url = voterVoice.querySelector('.kontentvotervoice__url')?.textContent.trim();
      const id = voterVoice.querySelector('.kontentvotervoice__id')?.textContent.trim();

      if (!id) {
        return;
      }

      const script = document.createElement('script');
      script.src = url + id;

      // Insert <vv:main></vv:main>
      voterVoice.insertAdjacentHTML('afterend', '<vv:main class="vv"></vv:main>');
      // Insert script
      voterVoice.parentElement.insertBefore(script, voterVoice.nextSibling);
      // Remove placeholder
      voterVoice.parentElement.removeChild(voterVoice);
      // Add width for desltop container
      pageContainer.classList.add('vv');
    }
  }

  onSaveContentClick() {
    this.saveToContentClick.emit();
  }

  processAnchorLinks() {
    const articleBodyElement = this.elementRef.nativeElement;

    const [...anchorLinks] = articleBodyElement.querySelectorAll('a[href^="#"]');

    anchorLinks.forEach(anchorLink => {
      const href = anchorLink.getAttribute('href');
      const targetID = href.substr(1);
      const targetElement = articleBodyElement.querySelector(`[id="${targetID}"]`);

      // Just prepend the URL into the anchor link
      anchorLink.href = window.location.pathname + href;

      if (!targetElement) {
        return;
      }

      // Scroll to target on click
      anchorLink.addEventListener('click', e => {
        e.preventDefault();

        const targetScroll = targetElement.getBoundingClientRect().top + window.pageYOffset;

        // Scroll to target with allowance for sticky navigation
        window.scrollTo(0, targetScroll - 100);
      });

    });
  }
}
