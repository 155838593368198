<div class="results-list">
  <div class="results-list__content">
    <p class="results-list__title" [innerHTML]="title"></p>
    <div class="results-list__list">
      <div class="group shown" *ngFor="let group of items | chunk: 6">
        <ncoa-result-card *ngFor="let item of group"
          [type]="item.type"
          [eyebrow]="item.eyebrow"
          [title]="item.title"
          [image]="item.image"
          [cta]="item.cta"
          [listType]="type"
        ></ncoa-result-card>
      </div>
    </div>
    <div *ngIf="hasMoreItems && items.length > 0" class="results-list__control">
      <button
        class="results-list__load-more"
        [ngClass]="loading ? 'is-loading' : ''"
        (click)="onLoadMoreClick($event)"
      >
        <svg [ngClass]="loading ? 'hide' : ''" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0Z" fill="#0B4A5D"/>
          <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="12" y="10" width="16" height="20">
          <path d="M12.5 10.5H27.0556V29.5H12.5V10.5Z" fill="white" stroke="#0B4A5D"/>
          </mask>
          <g mask="url(#mask0)">
          <path d="M20.1772 29.3267L20.1765 29.3274C20.0535 29.4481 19.9103 29.5 19.7759 29.5C19.6416 29.5 19.4984 29.4481 19.3754 29.3274L19.3747 29.3267L12.672 22.7747C12.6719 22.7746 12.6718 22.7745 12.6717 22.7745C12.4427 22.5494 12.4428 22.2134 12.672 21.9884C12.9064 21.7584 13.265 21.7582 13.4995 21.988C13.4996 21.9882 13.4998 21.9883 13.4999 21.9884L18.3522 26.7769L19.2034 27.617V26.4211V11.0526C19.2034 10.7586 19.4533 10.5 19.7759 10.5C20.0986 10.5 20.3485 10.7586 20.3485 11.0526V26.4211V27.6078L21.1977 26.7789L26.0778 22.0157L26.0788 22.0147C26.3133 21.7846 26.6722 21.7845 26.9071 22.0149C27.1009 22.2052 27.118 22.5409 26.8803 22.7743C26.8802 22.7744 26.8801 22.7745 26.88 22.7746L20.1772 29.3267Z" fill="white" stroke="white"/>
          </g>
        </svg>
        <span class="results-list__load-more-text" [ngClass]="loading ? 'hide' : ''">Load More</span>
        <img src="../../../assets/images/loader.gif" alt="Loading..." />
      </button>
    </div>
  </div>
</div>
