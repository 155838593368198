<div *ngIf='title' [ngClass]='{"isOpen": title, "benefit-card-modal":true}'>
  <div class="benefit-card-modal__wrapper">
    <div class="header">
      <h3 class="heading">{{title}}</h3>
      <img class="close-icon" (click)="closeModal()" src='../../../../assets/images/modal-close-icon-text.svg' />
      <p class='eyebrow'>{{eyebrow}}</p>

    </div>
    <div class="body">
      <div class="desc" [innerHTML]="desc"></div>
      <div class="cta-container">
        <a class="ctaLink" [href]='cta.url' target="_blank" (click)='trackAnalytics($event)'><span>{{cta.text}}</span>
          <svg width="20" height="15.56" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M13.1316 1.04527L19.6842 7.74867C19.8947 7.96318 20 8.23131 20 8.49945C20 8.76758 19.8947 9.03572 19.6842 9.25023L13.1316 15.9536C12.7105 16.3826 12.0526 16.3826 11.6316 15.9536C11.2105 15.5246 11.2105 14.8543 11.6316 14.4253L16.4211 9.57199L1.05263 9.57199C0.473684 9.57199 0 9.08935 0 8.49945C0 7.90955 0.473684 7.4269 1.05263 7.4269L16.4211 7.4269L11.6579 2.54683C11.2368 2.11781 11.2368 1.44747 11.6579 1.01819C12.0526 0.615983 12.7105 0.615983 13.1316 1.04527Z"
              fill="#0B4A5D" />
          </svg>
        </a>
      </div>
    </div>
  </div>
