import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'article-detail-header',
  templateUrl: './article-detail-header.component.html',
  styleUrls: ['./article-detail-header.component.scss']
})
export class ArticleDetailHeader implements OnInit {

  @Input() showCTA?: boolean = false;
  @Input() eyebrow?: string;
  @Input() title?: string;
  @Input() ctaPrintText?: string = 'Print this page';
  @Input() ctaArticleText?: string;
  @Input() readingTime?: string;
  @Input() leadGenShow?: boolean;
  @Input() trustedExpertCta: {
    url: string;
    text: string;
  };

  constructor() { }

  ngOnInit(): void {
  }

  clickPrint(): void {
    window.print();
  }

}
