import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RichTextService } from '@services/rich-text-resolver.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
/**
 * Banner component
 * @description holds rich text in a green banner
 * @example <app-banner [richTextObject]="richTextObject"></app-banner>
 * 
 */
export class BannerComponent implements OnInit, OnChanges {

  @Input() richTextObject: any;
  @Input() padding: string;
  bannerHtml: string;
  
  constructor(private richTextService: RichTextService) { }

  ngOnInit(): void {
    this.updateBannerHtml();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.richTextObject) {
      this.updateBannerHtml();
    }
  }

  private updateBannerHtml(): void {
    if (this.richTextObject?.value) {
      this.bannerHtml = this.richTextService.resolveRichText(this.richTextObject);
    }
  }
}