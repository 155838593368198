<section class="event-hero">
  <div class="event-hero__content">
    <div class="event-hero__image">
      <img *ngIf="image && image.url" [src]="image.url" [alt]="image.alt" aria-hidden="true"  width="{{width}}" height="{{height}}" >
      <img *ngIf="!image || !image.url" src="../../../assets/images/article-hero-green-desktop.png" [alt]="title" aria-hidden="true"  width="{{width}}" height="{{height}}" >
    </div>
    <div class="event-hero__body">
      <div class="event-hero__eyebrow">Event</div>
      <h1 class="event-hero__title event-hero__title--{{ titleScale }}">{{ title }}</h1>
      <div class="event-hero__details">
        <div class="event-hero__datetime">
          <div class="event-hero__datetime--same-day" *ngIf="startDateString == endDateString">
            <div class="date">{{ startDateString }}</div>
            <div class="divider" *ngIf="startDateString == endDateString"></div>
            <div class="time">
              <span>{{ startTime }}</span>
              <span *ngIf="!disregardEndTime && (startDateString == endDateString)"> - {{ endTime }}</span>
            </div>
          </div>

          <div class="event-hero__datetime--diff-day" *ngIf="startDateString != endDateString">
            <div class="start">
              <div class="date">{{ startDateString }}</div>
              <div class="time">
                <span>{{ startTime }}</span>
              </div>
            </div>
            <div class="divider"></div>
            <div class="end">
              <div class="date">{{ endDateString }}</div>
              <div class="time">
                <span>{{ endTime }}</span>
              </div>
            </div>
          </div>
        </div>
        <span *ngIf="sponsorship_indication_text" class="event-hero__sponsored">{{ sponsorship_indication_text }}</span>
        <button class="event-hero__print" (click)="onPrintClick($event)">
          <span class="event-hero__print-icon" aria-hidden="true"></span>
          <span class="event-hero__print-text">Print this page</span>
        </button>
      </div>
      <div class="event-hero__controls" *ngIf="!isPast; else pastEvent">
        <button (click)="onAddToCalendarClick()" class="event-hero__control event-hero__control--calendar">
          <span class="event-hero__calendar-icon" aria-hidden="true"></span>
          <span class="event-hero__control-text">Add to calendar</span>
        </button>
        <a *ngIf="registration_link" href="{{ registration_link }}" class="event-hero__control event-hero__control--register">
          <span class="event-hero__control-text">Register Now</span>
        </a>
      </div>
      <ng-template #pastEvent >
        <div class="event-hero__ended">This event has ended.</div>
      </ng-template>
    </div>
  </div>
</section>

<ncoa-partnership
  *ngIf="partnership"
  container="hero"
  [eyebrow]="partnership.eyebrow"
  [url]="partnership.url"
  [src]="partnership.src"
  [alt]="partnership.alt"
></ncoa-partnership>
