import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ncoa-form-assembly',
  templateUrl: './form-assembly.component.html',
  styleUrls: ['./form-assembly.scss']
})

export class FormAssembly implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
