<form class="edit-account-info">
  <div class="title-container">
    <h2 class="title">{{ componentLabels.title }}</h2>
    <button class="save-btn">{{ componentLabels.save }}</button>
  </div>
  <label for="firstname" class="label-text">{{ componentLabels.firstname }}</label>
  <input type="text" id="firstname" name="firstname" class="input-text" [(ngModel)]='firstname' />

  <label for="lastname" class="label-text">{{ componentLabels.lastname }}</label>
  <input type="text" id="lastname" name="lastname" class="input-text" [(ngModel)]='lastname' />

  <label for="email" class="label-text">{{ componentLabels.email }}</label>
  <input type="email" id="email" name="email" class="input-text" [(ngModel)]='email' />

  <label for="password" class="label-text">{{ componentLabels.password }}</label>
  <div class='change-pass-container'>
    <input type="password" id="password" name="password" class="input-text" [(ngModel)]='password' />

    <button class="change-pass-btn" (click)='onChangePasswordClick()'>{{ componentLabels.changePassword }}</button>
  </div>

  <button class="save-btn" (click)='onSaveClick()'>{{ componentLabels.save }}</button>
</form>
