import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { KontentDeliveryService } from './kontent-delivery.service';
import { UrlService } from './url.service';
import { DataLayerService } from '@services/data-layer.service';
import * as utc from 'dayjs/plugin/utc';
import { RichTextService } from './rich-text-resolver.service';
import { SeoService } from './seo.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ComponentService } from './component.service';
import { ModulesService } from './modules.service';
import { DataService } from './planner/data.service';
import { ThrowStmt } from '@angular/compiler';

// Add utc
dayjs.extend(utc);
export const PROJECTION_ELEMENTS = [ 'event_date_time', 'url', 'display_date',
  'primary_image', 'title',  'author_url_slug', 'author_headshot', 'author_name',
  'category_page_url', 'header_image', 'parent_page', 'other',
]
export const SEARCH_TAXONOMIES=[
  'program_area', 'administration_for_community_living_grantees', 'audience', 'topic', 'format', 'programs_of_excellence',
  'audiences', 'categories', 'program_type', 'program_format', 'program_length',
  'program_training', 'program_characteristics','program_topic','press_release_taxonomy'

]
@Injectable({
  providedIn: 'root'
})

export class CustomSearchService {

  originalItems: any[] = [];
  displayedItems: any[] = [];
  articles: any[] = [];
  usedArticles: any[] = [];
  components: any[] = [];
  modularContent = {};
  // totalItems: number;
  totalItems = new Subject<number>();

  apiLimit: number = 1000;
  limit: number = 42;

  taxonomyCodeNames: any[] = [];
  contentTypes: any[] = [];

  filterGroups: any[] = [];
  permanentFilters: any[] = [];

  selectedYear: number|null;
  selectedMonth: number|null;
  sortType: string; // current 'year' or ''


  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private urlService: UrlService,
    private dataLayerService: DataLayerService,
    private richTextService: RichTextService,
    private seoService: SeoService,
    private componentService: ComponentService,    
    private modulesService: ModulesService,
    private dataService: DataService,
  ) {}

  getFilterGroup(data) {
    if (data) {
      const { elements } = data;

      const values = this.taxonomyCodeNames.map(codename => {
        if (elements[codename]) {
          const { value } = elements[codename];
          return {
            title: elements.title.value,
            codename,
            value,
          };
        }
        return {
          title: elements.title.value,
          codename,
          value: [],
        };
      });


      const targetValuesIndex = values.reduce((p, c, i, a) => a[p].value.length > c.value.length ? p : i, 0);
      const targetValues = values[targetValuesIndex];

      return targetValues;
    }

    return null;
  }
  async getList(term) {

    const wait = (ms = 500) => {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    };

    const response = await this.getResultPage()
  
    if (response) {
      this.processRawList(response);
    }

    this.discardContent();


    this.originalItems = this.sortType === 'year' ? this.originalItems.sort((a,b)=> new Date(b.eyebrow).getTime() - new Date(a.eyebrow).getTime()  )  :  this.originalItems.sort((a, b) => a.title.localeCompare(b.title));
    const filterYears = new Set<number>();
      this.originalItems.forEach((item) => {
        const date = new Date(item.eyebrow).getFullYear()
        if(!isNaN(date))
        filterYears.add(date);
      });
    

    if (term.replace(/\s+/g, '').length > 0) {
      this.displayedItems = this.filterFeedData(this.originalItems, term);
    } else {
      this.displayedItems = this.originalItems;
    }
    this.totalItems.next(this.displayedItems.length);

    return {
      items: this.displayedItems.slice(0, this.limit),
      displayed: this.limit,
      yearList:filterYears,
    };
  }

  async getResultPage(limit:number = 1000, page:number=0){


    const response = await this.kontentDeliveryService.getItem(null, {
      'system.type[in]': this.contentTypes.join(','),
      'elements': PROJECTION_ELEMENTS.concat(SEARCH_TAXONOMIES).join(','),
      'limit': limit,
      'skip': page * limit,
      'depth': 1,
    });
    // preview sdk does not have pagination property on response object??
    if(response.items.length === limit){
      const nextPage = await this.getResultPage(limit, page + 1);
      response.items = response.items.concat(nextPage.items);
    }

    return response;

  }

  async getOtherRequests(otherRequest) {
    return await Promise.all(otherRequest).then(data => {
      data.forEach(result => this.processRawList(result));
      return this.originalItems;
    });
  }

  discardContent() {
    const filters = this.filterGroups.map(({ codename }) => codename);
    let permanentFilterSet = new Set<string>();
    this.permanentFilters.forEach(taxo => { 
      filters.push(taxo.codename);
      taxo.value.forEach(value => permanentFilterSet.add(`${taxo.codename}-${value.codename}`))
    });

    const hasProperty = (object) => {
     return  filters.some(codename => {
      if(object[codename] && object[codename].length > 0){
        if(permanentFilterSet.size>0){
          return object[codename].some((item) => permanentFilterSet.has(`${codename}-${item}`));
        }
      }
        return (object.hasOwnProperty(codename) && object[codename].length > 0) 
      });

    };
    
    this.originalItems = this.originalItems.filter(item => hasProperty(item));
  }
  getMoreData(skip) {
    return {
      items: this.displayedItems.slice(skip, skip+this.limit),
      displayed: skip + this.limit,
    };
  }
  search(query, filterOptions, applyAllFilters) {
    // Fire analytics
    const terms = filterOptions ? filterOptions.reduce((all, term) => ({
      ...all,
      [term.taxonomy]: term.values
    }), {}) : '';

    this.dataLayerService.push({ query, terms }, false);

    let filteredList = this.originalItems;
    if(this.selectedYear && this.selectedYear !==null) {
      filteredList = filteredList.filter((item) => {
        if(!item.eyebrow) { return false;}
        return new Date(item?.eyebrow).getFullYear() === this.selectedYear;
      });
    }
    if(this.selectedMonth && this.selectedMonth !==null) {
      filteredList = filteredList.filter((item) => {
        if(!item.eyebrow) {return false;}
        return new Date(item?.eyebrow).getMonth() === this.selectedMonth;
      });
    }

    if (query.replace(/\s+/g, '').length > 0) {
      filteredList = this.filterFeedData(filteredList, query);
    }

    if (filterOptions) {
      filterOptions.concat(this.permanentFilters)
      if (!applyAllFilters)  {
        filterOptions.forEach(({ taxonomy, values }) => {
          const options = values.map(option => option.replace(`${taxonomy}-`, ''));
          filteredList = filteredList.filter(item => {
              return item[taxonomy]?.some(x => options.includes(x))
            });
          
        });
      } else {
        filteredList = filteredList.filter(item => {
          return filterOptions.filter(({ taxonomy, values }) => {
            return values.every(x => item[taxonomy].includes(`${x}`.replace(`${taxonomy}-`, '')));
          }).length === filterOptions.length;
        });
      }
    }

    this.displayedItems = filteredList;
    this.totalItems.next(this.displayedItems.length);

    return {
      items: this.displayedItems.slice(0, this.limit),
      displayed: this.limit,
    };
  }
  sortBy(filterByDate: string) {
    this.sortType = filterByDate;
    return this;
  }
  updateYearFilter(year:number|null) {
    this.selectedYear = year;
  }
  updaterMonthFilter(month:number|null) {
    this.selectedMonth = month;
  }

  filterFeedData(list, term) {
    return list.filter(item => item.title.toLowerCase().includes(term.toLowerCase()));
  }

  // page route functions
  getCustomSearchPageData(pageData, pageLibraryModularContents) {
    const image = pageData.elements.hero_image.value.length > 0 ? {
      url: pageData.elements.hero_image.value[0].url,
      alt: pageData.elements.hero_image.value[0].description,
    } : {
      url: '/assets/images/content-package-midnight-tablet.png',
      alt: 'Placeholder Image',
    };

    const seoMetaData = this.seoService.buildSeoData(pageData);
    this.seoService.applyMetaData(seoMetaData);
    const resultLabel = pageData.elements?.result_type_label?.value ?? '';
    const eyebrow = pageData.elements.page_eyebrow.value;
    const title = pageData.elements.page_title.value;
    let description_rich = pageData.elements?.page_description_rich?.value;
    // if default value is set, set to empty string
    if(description_rich ==='\u003Cp\u003E\u003Cbr\u003E\u003C/p\u003E') description_rich = ''
    // if rich text is empty , use plain text, if plain text is empty, use empty string
    const description = description_rich?.length > 0 ?  this.richTextService.resolveRichText(pageData.elements?.page_description_rich): pageData.elements?.page_description?.value ? pageData.elements?.page_description?.value : '';
    const placeholder = pageData.elements.search_placeholder.value;
    const filterGroupCodeNames = pageData.elements?.filter_groups?.value ?? [];
    let filterGroups = filterGroupCodeNames.map((codename) => pageLibraryModularContents[codename]);
    const hideSearchBar = pageData.elements.search_bar_options.value.map(opt => { return opt.codename }).includes('hide_search_bar');
    const showFilterOption = pageData.elements.show_filter_option.value?.map(opt => { return opt.codename }).includes('true');
    const searchBarLink = this.richTextService.resolveRichText(pageData.elements.search_bar_link);
    const filterTaxonomies = pageLibraryModularContents[pageData.elements?.filter_group_to_show_as_tags?.value] ?? null; 
    let filterGroupTags = pageData.elements?.filter_group_to_show_as_tags?.value ?? [];
    const filterByDate = pageData.elements?.filter_by_date?.value?.map((selection)=>selection.codename) ?? '';
    const itemsPerPage = pageData.elements?.number_of_items_per_page?.value ?? 42;

    const specialPaths = this.setSpecialPathing(pageData,pageLibraryModularContents);
    this.limit = itemsPerPage;
    // iterate over every key in filterGrouptags.elements and concat value arrays
    filterGroupTags = Object.keys(filterTaxonomies?.elements ?? []).reduce((acc, key) => {
      if (key !== 'title') {
        return acc.concat(filterTaxonomies?.elements[key].value);
      }
      return acc;
    }, []);


    this.contentTypes = pageData.elements.content_types.value.map(({ codename }) => codename);

    filterGroups = this.processFilterGroup(filterGroups)

    this.filterGroups = filterGroups;

    const permGroupCodeNames = pageData.elements?.permanent_filter?.value ?? [];
    let permGroups = permGroupCodeNames.map((codename) => pageLibraryModularContents[codename]);
    this.permanentFilters = this.processFilterGroup(permGroups);



    

    // additional components
    if(pageData?.elements?.components?.value?.length > 0){
      this.setAdditionalComponents(pageData.elements.components.value, pageLibraryModularContents);
    }
    let additionalComponents = this.components;
    return {
      image,
      eyebrow,
      title,
      description,
      filterGroups,
      placeholder,
      hideSearchBar,
      showFilterOption,
      filterGroupTags,
      filterByDate,
      searchBarLink,
      resultLabel,
      itemsPerPage,
      additionalComponents,
      specialPaths
    };
  }

  processFilterGroup(filterGroups) {
    return filterGroups.map((filterGroup) => {
      const title = filterGroup.elements.title.value;

      // delete filterGroup.elements.title;

      let property = null;

      Object.keys(filterGroup.elements).filter(key=> key!=='title').map((key) => {
        if (!property) {
          property = filterGroup.elements[key];
        } else {
          if (filterGroup.elements[key].value.length > property.value.length) {
            property = filterGroup.elements[key];
          }
        }
      });

      return {
        codename: property.taxonomy_group,
        title,
        value: property.value,
      };
    });
  }

  processRawList(data) {
    const { items: list, modular_content } = data;
    list.forEach(item => {
      const id = item.system.id;
      const audiences = item.elements.audiences ? item.elements.audiences.value.map(({ codename }) => codename) : [];
      const categories = item.elements.categories ? item.elements.categories.value.map(({ codename }) => codename) : [];
      const program_type = item.elements.program_type ? item.elements.program_type.value.map(({ codename }) => codename) : [];
      const program_format = item.elements.program_format ? item.elements.program_format.value.map(({ codename }) => codename) : [];
      const program_length = item.elements.program_length ? item.elements.program_length.value.map(({ codename }) => codename) : [];
      const program_training = item.elements.program_training ? item.elements.program_training.value.map(({ codename }) => codename) : [];
      const program_topic = item.elements.program_topic ? item.elements.program_topic.value.map(({ codename }) => codename) : [];
      const program_characteristics = item.elements.program_characteristics ? item.elements.program_characteristics.value.map(({ codename }) => codename) : [];
      const other = item.elements.other ? item.elements.other.value.map(({ codename }) => codename) : [];
      const program_area = item.elements.program_area ? item.elements.program_area.value.map(({ codename }) => codename) : [];
      const administration_for_community_living_grantees = item.elements.administration_for_community_living_grantees ? item.elements.administration_for_community_living_grantees.value.map(({ codename }) => codename) : [];
      const audience = item.elements.audience ? item.elements.audience.value.map(({ codename }) => codename) : [];
      const topic = item.elements.topic ? item.elements.topic.value.map(({ codename }) => codename) : [];
      const format = item.elements.format ? item.elements.format.value.map(({ codename }) => codename) : [];
      const formatNames = item.elements.format ? item.elements.format.value.map(({ name }) => name) : [];
      const programs_of_excellence = item.elements.programs_of_excellence ? item.elements.programs_of_excellence.value.map(({ codename }) => codename) : [];
      const press_release_taxonomy = item.elements.press_release_taxonomy ? item.elements.press_release_taxonomy.value.map(({ codename }) => codename) : [];
      const cta = {
        text: null,
        url: null,
      };
      let eyebrow = '';
      let image = null;
      let title = '';
      const type = {
        codename: null,
        name: null,
      };

      if (/article/ig.test(item.system.type)) {
        // if item is an article
        cta.text = item.elements.event_date_time.value ? 'View Event Details' : 'Read Article';
        cta.url = this.urlService.buildArticleURL(item.elements.url.value);
        eyebrow = item.elements.display_date.value ? dayjs.utc(item.elements.display_date.value).format('MMM DD, YYYY') : '';

        if (item.elements.primary_image.value.length > 0) {
          image = {
            url: item.elements.primary_image.value[0].url,
            alt: item.elements.primary_image.value[0].description,
          };
        }

        title = item.elements.title.value;

        type.codename = item.elements.event_date_time.value ? 'event' : 'article';
        type.name = item.elements.event_date_time.value ? 'Event' : 'Article';
      } else if (/author/ig.test(item.system.type)) {
        // if item is an author page
        cta.text = 'Explore More',
        cta.url = this.urlService.buildAuthorLink(item.elements.author_url_slug.value);
        eyebrow = '';

        if (item.elements.author_headshot.value.length > 0) {
          image = {
            url: item.elements.author_headshot.value[0].url,
            alt: item.elements.author_headshot.value[0].description,
          };
        }

        title = item.elements.author_name.value;

        type.codename = 'author';
        type.name = 'Author';
      } else if (/taxonomy_custom_content/ig.test(item.system.type)) {
        // if item is a category page
        cta.text = 'Explore More',
        cta.url = `${item.elements.category_page_url.value}`;
        eyebrow = '';

        if (item.elements.header_image.value.length > 0) {
          image = {
            url: item.elements.header_image.value[0].url,
            alt: item.elements.header_image.value[0].description,
          };
        }

        title = item.system.name;

        type.codename = 'topic';
        type.name = 'Topic';
      } else if (item.system.type === 'standard_page') {
        cta.text = 'Explore More';
        cta.url = this.urlService.buildPageURL(item.elements.url.value);

        if (item.elements.parent_page.value.length > 0) {
          const parentPage = modular_content[item.elements.parent_page.value[0]];
          if (parentPage) {
            const { value: parentUrl } = parentPage.elements.url;
            cta.url = this.urlService.buildPageURL(`${parentUrl}/${item.elements.url.value}`);
          }
        }

        if (item.elements.primary_image.value.length > 0) {
          image = {
            url: item.elements.primary_image.value[0].url,
            alt: item.elements.primary_image.value[0].description,
          };
        }

        title = item.elements.title.value;

        type.codename = 'landing';
        type.name = 'Landing';
      } else if (item.system.type === 'standard_page__special') {
        cta.text = 'Explore More';
        cta.url = `/${item.elements.url.value}`;

        if (item.elements.primary_image.value.length > 0) {
          image = {
            url: item.elements.primary_image.value[0].url,
            alt: item.elements.primary_image.value[0].description,
          };
        }

        title = item.elements.title.value;

        type.codename = 'landing';
        type.name = 'Landing';
      }

      this.originalItems.push({
        id,
        audiences,
        categories,
        program_type,
        program_format,
        program_length,
        program_training,
        program_characteristics,
        program_topic,
        programs_of_excellence,
        other,
        program_area,
        press_release_taxonomy,
        administration_for_community_living_grantees,
        audience,
        topic,
        format,
        formatNames,
        cta,
        eyebrow,
        image,
        title,
        type,
      });
    });
  }
  // Additional Component Functions
  setAdditionalComponents(componentList,modularContent) {
    // additional components
    (componentList|| []).forEach(
      (codename) => {
        const component =modularContent[codename];
        switch (component.system.type) {
          // featured page block
          case this.modulesService.moduleTypes.featured_page_block: {
            this.components.push(
              this.modulesService.featurePageBlock(
                component,
               modularContent
              )
            );
            break;
          }

          // featured tool breaker
          case this.modulesService.moduleTypes.featured_tool_breaker: {
            this.components.push(
              this.modulesService.featuredToolBreaker(component)
            );
            break;
          }

          // donate block
          case this.modulesService.moduleTypes.call_to_donate: {
            this.components.push(this.modulesService.donateBlock(component));
            break;
          }

          // newsletter block
          case this.modulesService.moduleTypes.newsletter_signup_block: {
            this.components.push(
              this.modulesService.newsLetterBlock(component)
            );
            break;
          }

          // category pathing standard
          case this.modulesService.moduleTypes.category_pathing_std: {
            this.components.push(
              this.modulesService.categoryPathingStandard(
                component,
               modularContent
              )
            );
            break;
          }

          // content package
          case this.modulesService.moduleTypes.content_package: {
            this.contentPackage(component,modularContent);
            break;
          }

          // partner grid
          case this.modulesService.moduleTypes.partner_grid: {
            this.components.push(
              this.modulesService.partnerGrid(component,modularContent)
            );
            break;
          }

          // content package
          case this.modulesService.moduleTypes.content_package: {
            this.contentPackage(component, modularContent);
            break;
          }

          // secondary content package
          case this.modulesService.moduleTypes.content_package_secondary: {
            this.secondaryContentPackage();
            break;
          }

          // external form package
          case this.modulesService.moduleTypes.external_form: {
            this.components.push(this.modulesService.externalForm(component));
            break;
          }
        }
      }
    );
  }
  contentPackage(component, modularContent) {
    let contentPackageArticles = [];
    let selectedArticles = [];
    const contentPopulationMethod =
      component.elements.content_population_method.value[0].codename;

    if (contentPopulationMethod === 'dynamically') {
      selectedArticles = this.getDynamicArticles(component);
    } else if (contentPopulationMethod === 'dynamic_with_manual_additions') {
      selectedArticles = this.getManualArticles(component,modularContent).concat(
        this.getDynamicArticles(component)
      );
      selectedArticles.sort((a, b) => (a.date > b.date ? -1 : 1));
      const max = selectedArticles.length > 7 ? 7 : selectedArticles.length;
      selectedArticles = selectedArticles.slice(0, max);
    } else {
      selectedArticles = this.getManualArticles(component,modularContent);
    }

    if (selectedArticles.length > 0) {
      contentPackageArticles = selectedArticles.map((article) => {
        this.usedArticles.push(article);

        const index = this.articles.findIndex(
          (libArticle) => libArticle.system.id === article.system.id
        );

        if (index >= 0) {
          this.articles.splice(index, 1);
        }

        const type = article.elements.display_date.value ? 'event' : 'article';
        const title = article.elements.title.value;
        const cta = {
          url: `/article/${article.elements.url.value}`,
          text: type === 'event' ? 'View Event Details' : 'Read Article',
        };
        const image =
          article.elements.primary_image.value.length > 0
            ? {
                url: article.elements.primary_image.value[0].url,
                alt: article.elements.primary_image.value[0].description,
                responsive: this.dataService.remodelImageSrc(
                  article.elements.primary_image.value[0].url
                ),
              }
            : {};
        const eyebrow = dayjs.utc(article.display_date).format('MMM D, YYYY');

        return {
          type,
          title,
          cta,
          image,
          eyebrow,
        };
      });

      this.components.push({
        componentType: this.modulesService.moduleTypes.content_package,
        articles: contentPackageArticles,
      });
    }
  }
  secondaryContentPackage() {
    const max = this.articles.length > 4 ? 4 : this.articles.length;
    let contentPackageArticles = this.articles.slice(0, max);

    contentPackageArticles = contentPackageArticles.map((article) => {
      const index = this.articles.findIndex(
        (libArticle) => libArticle.system.id === article.system.id
      );

      if (index >= 0) {
        this.articles.splice(index, 1);
      }

      const type = article.elements.date.value ? 'event' : 'article';
      const title = article.elements.title.value;
      const cta = {
        url: `/article/${article.elements.url.value}`,
        text: type === 'event' ? 'View Event Details' : 'Read Article',
      };
      const image =
        article.elements.primary_image.value.length > 0
          ? {
              url: article.elements.primary_image.value[0].url,
              alt: article.elements.primary_image.value[0].description,
              responsive: this.dataService.remodelImageSrc(
                article.elements.primary_image.value[0].url
              ),
            }
          : {};
      const eyebrow = dayjs.utc(article.date).format('MMM D, YYYY');

      return {
        type,
        title,
        cta,
        image,
        eyebrow,
      };
    });

    if (contentPackageArticles.length > 0) {
      this.components.push({
        componentType: this.componentService.componentTypes.content_package,
        articles: contentPackageArticles,
      });
    }
  }
  getManualArticles(component,modularContent) {
    let selectedArticles = [];
    const articleCodeNames = component.elements.content.value;

    articleCodeNames.forEach((codename) => {
        const article = this.modularContent[codename];
        if (article) {
          selectedArticles.push({
            ...article,
            date: new Date(article.elements.display_date.value || article.system.last_modified),
          });
        }
    });

    return selectedArticles;
  }
  getDynamicArticles(component) {
    const categories = component.elements.categories.value.map(({ codename }) => codename);
    const audiences = component.elements.audiences.value.map(({ codename }) => codename);
    const specialType = component.elements.other_categories.value.map(({ codename }) => codename);
    let articles = this.articles;

    let filteredArticles = articles.filter((article) => {
      const primaryCategoryFound = article.elements.categories.value.some((x) => categories.includes(x.codename));
      const secondaryCategoryFound = article.elements.secondary_categories.value.some((x) => categories.includes(x.codename));

      const primaryAudienceFound = article.elements.audiences.value.some((x) => audiences.includes(x.codename));
      const secondaryAudienceFound = article.elements.secondary_audiences.value.some((x) => audiences.includes(x.codename));

      const specialTypeFound = article.elements.other.value.some((x) => specialType.includes(x.codename));

      return primaryCategoryFound || secondaryCategoryFound || primaryAudienceFound || secondaryAudienceFound || specialTypeFound;
    });

    const max = filteredArticles.length > 7 ? 7 : filteredArticles.length;

    return filteredArticles.slice(0, max);
  }

  setSpecialPathing(components, modularContent) {
    if (components.elements.special_pathing.value.length > 0) {
      const pageData = components;
      const codeNames = pageData.elements.special_pathing.value;
      const paths = codeNames.map((codename) => {
        const path = modularContent[codename];
        switch (path.system.type) {
          case 'template___other_taxonomy': {
            return {
              url: `/${path.system.name
                .trim()
                .toLowerCase()
                .replace(/\s+/g, '-')
                .replace(/[^\w\-]+/g, '')
                .replace(/\-\-+/g, '-')
                .replace(/^-+/, '')
                .replace(/-+$/, '')}`,
              title: path.system.name,
            };
          }
          case 'ncoa_article_content': {
            return {
              url: `/article/${path.elements.url.value}`,
              title: path.elements.title.value,
            };
          }
          case 'standard_page': {
            let url = null;
            const parentPageCodename =
              path.elements.parent_page.value[0] || null;

            if (parentPageCodename) {
              const parentPage = modularContent[parentPageCodename];

              if (parentPage) {
                url = `/page/${parentPage.elements.url.value}/${path.elements.url.value}`;
              }
            } else {
              url = `/page/${path.elements.url.value}`;
            }

            return {
              url,
              title: path.elements.title.value,
            };
          }
          case 'template___custom_search': {
            return {
              url: `/${path.elements.page_slug.value}`,
              title: path.elements.page_title.value,
            };
          }
          case 'navigation_item_0ae4ab8': {
            let url,
              external = false;
            if (path.elements.linked_content.value.length > 0) {
              const linkedContentItem =
              modularContent[path.elements.linked_content.value[0]];
              if (linkedContentItem.system.type === 'ncoa_article_content') {
                // article
                if (linkedContentItem.system.collection === 'default') {
                  // ncoa article
                  url = `article/${linkedContentItem.elements.url.value}`;
                } else {
                  // awp article
                  url = `age-well-planner/resource/${linkedContentItem.elements.url.value}`;
                }
              } else {
                // category/standard/audience page
                url =
                  modularContent[path.elements.linked_content.value[0]]
                    .elements.category_page_url?.value ||
                  `page/${
                    modularContent[path.elements.linked_content.value[0]]
                      .elements.url?.value
                  }`;
              }
            } else {
              url = path.elements.link_url.value;
              if (url.indexOf('http://') == 0 || url.indexOf('https://') == 0)
                external = true;
            }
            return {
              title:
                path.elements.link_name.value ||
                modularContent[path.elements.linked_content.value[0]]
                  .system.name,
              url,
              external,
            };
          }
          case 'taxonomy_custom_content': {
            return {
              title: path.system.name,
              url: this.buildTaxonomyPageURL(path),
            };
          }
        }
      });

      return {
        componentType: this.modulesService.moduleTypes.special_pathing,
        paths,
      };
    }
  }

  buildTaxonomyPageURL(itemData) {
    const type = itemData.system.type;

    switch (true) {
      case /taxonomy_custom_content/.test(type):
        const { categories, audiences, category_page_url }: any =
          this.kontentDeliveryService.extractItemElements(itemData);
        const category = categories[0];
        const audience = audiences[0];

        // Use manual URL if any
        if (category_page_url) {
          return category_page_url;
        }
        return '';

      case /template___other_taxonomy/.test(type):
        const { other_taxonomy }: any =
          this.kontentDeliveryService.extractItemElements(itemData);
        return other_taxonomy[0].codename.replace(/_/g, '-');
    }
  }



}
