import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent {

  public _show: boolean;

  @Input() header: string;

  constructor(private cd : ChangeDetectorRef) { 
    this._show = false;
  }

  public show(){
    this._show = true;
    this.cd.detectChanges();
  }

  public hide(){
    this._show = false;
    this.cd.detectChanges();
  }

}
