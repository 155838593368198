<div class="component article-card-group article-card-group--{{ type }}" [ngSwitch]="type">
  <div class="article-card-group__content" *ngSwitchCase="'1up'">
    <ncoa-article-card
      [type]="'featured'"
      [title]="articles[0].title"
      [eyebrow]="articles[0].eyebrow"
      [image]="articles[0].image"
      [cta]="articles[0].cta"
      [articleType]="articles[0].type"
    ></ncoa-article-card>
  </div>
  <div class="article-card-group__content" *ngSwitchCase="'2up'">
    <ncoa-article-card
      *ngFor="let article of articles"
      [title]="article.title"
      [eyebrow]="article.eyebrow"
      [image]="article.image"
      [cta]="article.cta"
      [articleType]="article.type"
    ></ncoa-article-card>
  </div>
  <div class="article-card-group__content" *ngSwitchCase="'3up'">
    <ncoa-article-card
      *ngFor="let article of articles"
      [title]="article.title"
      [eyebrow]="article.eyebrow"
      [image]="article.image"
      [cta]="article.cta"
      [articleType]="article.type"
    ></ncoa-article-card>
  </div>
  <div class="article-card-group__content" *ngSwitchCase="'4up'">
    <ncoa-article-card
      [title]="articles[0].title"
      [eyebrow]="articles[0].eyebrow"
      [image]="articles[0].image"
      [cta]="articles[0].cta"
      [articleType]="articles[0].type"
    ></ncoa-article-card>
    <div class="article-card-group__list">
      <div class="coral-circle"></div>
      <ncoa-article-card
        *ngFor="let article of articles.slice(1, 4)"
        type="minimal"
        [title]="article.title"
        [eyebrow]="article.eyebrow"
        [image]="article.image"
        [cta]="article.cta"
        [articleType]="article.type"
      ></ncoa-article-card>
  </div>
  </div>
</div>
