import { Injectable } from '@angular/core';
import { KontentDeliveryService } from './kontent-delivery.service';
import { ComponentService } from './component.service';
import { UrlService } from './url.service';
import { SeoService } from './seo.service';

@Injectable({
  providedIn: 'root'
})
export class GetInvolvedService {
  contentType: string = 'standard_page__special';
  relatedTypes: string[] = [
    'ncoa_article_content',
    'standard_page__special',
    'taxonomy_custom_content'
  ];

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private componentService: ComponentService,
    private urlService: UrlService,
    private seoService: SeoService,
  ) {}

  getPage(slug: any) {
    return Promise.all([
      this.getPageData(slug),
    ])
    .then((data) => {
      return {
        ...data,
      }
    });
  }

  getPageData(slug: any) {
    return Promise.all([
      this.fetchPageData(slug),
      this.fetchRelatedItems(slug),
    ])
    .then(([rawAPIData, rawItemsData]) => {
      this.seoData(rawAPIData.items[0]);
      return this.processAPIData(rawAPIData, rawItemsData);
    });
  }

  fetchPageData(slug) {
    return this.kontentDeliveryService.getItem(null, {
      'system.type': this.contentType,
      'elements.url': slug,
      limit: 1,
      depth: 4
    })
  }

  fetchRelatedItems(slug) {
    const codename = slug.replace('-', '_');

    return this.kontentDeliveryService.getItem(null, {
      'system.type[in]': this.relatedTypes.join(','),
      'elements.url[neq]': slug,
      'elements.other[contains]': codename,
      limit: 12,
      depth: 4
    });
  }

  processAPIData(rawAPIData, rawItemsData) {
    return {
      item: rawAPIData.items[0].elements,
      special_pathing: this.extractSpecialPathing(rawAPIData),
      components: this.extractAdditionalComponents(rawAPIData, rawItemsData)
    }
  }

  extractAdditionalComponents(rawAPIData, rawItemsData) {
    const { additional_modules }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.items[0], ['additional_modules']);
    const rawComponentItems = this.kontentDeliveryService.extractModularContent(rawAPIData, additional_modules);

    // Prepare for dynamic content package
    const contentPackageItems = this.componentService.processContentPackageComponentArticles(rawItemsData.items);

    // Remove cross pathing module
    const filteredRawComponentItems = rawComponentItems.filter(itemData => (
      this.componentService.componentTypes.cross_pathing_module !==  itemData.system.type
    ));

    const processedComponents = this.componentService.processRawComponents(filteredRawComponentItems, rawAPIData.modular_content)

    // Populate content packages that are dynamic
    processedComponents.forEach(component => {
      if (component !== null) {
        if (
          component.componentType === this.componentService.componentTypes.content_package
          && !component.articles.length
          && contentPackageItems.length
        ) {
          component.articles.push(...contentPackageItems.splice(0, 6));
        }
      }
    });

    return processedComponents;
  }

  extractSpecialPathing(rawAPIData) {
    const { modular_content } = rawAPIData
    const { special_pathing }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.items[0], ['special_pathing']);

    const links = [];
    special_pathing.map(path => {
      const {
        title,
        url
      }: any = this.kontentDeliveryService.extractItemElements(modular_content[path], [
        'title', 'url'
      ]);

      links.push({
        title,
        url: this.urlService.buildPageURL(url)
      })
    });

    return links;
  }

  seoData(rawAPIData) {
    const seoData = {
      title: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_title.value
        : rawAPIData.elements.title.value,
      description: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_description.value
        : rawAPIData.elements.description.value || '',
      image: rawAPIData.elements.primary_image.value.length > 0
        ? rawAPIData.elements.primary_image.value[0].url
        : '/assets/images/article-hero-share-midnight-desktop.png',
      ogTitle: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value
        : rawAPIData.elements.title.value,
      ogDescription: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value
        : rawAPIData.elements.description.value || '',
      ogImage: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value.length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value[0].url
        : rawAPIData.elements.primary_image.value.length > 0
          ? rawAPIData.elements.primary_image.value[0].url
          : '/assets/images/article-hero-share-midnight-desktop.png',
      ogType: 'page',
      twitterTitle: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value
        : rawAPIData.elements.title.value,
      twitterDescription: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value
        : rawAPIData.elements.description.value || '',
      twitterImage: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value.length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value[0].url
        : rawAPIData.elements.primary_image.value.length > 0
          ? rawAPIData.elements.primary_image.value[0].url
          : '/assets/images/article-hero-share-midnight-desktop.png',
    };

    this.seoService.applyMetaData(seoData);
  }
}
