import { Injectable } from '@angular/core';
import { KontentDeliveryService } from './kontent-delivery.service';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { DataService } from './planner/data.service';
import { ModulesService } from './modules.service';
import { ModalService } from './modal.service';
import { DataLayerService } from './data-layer.service';
import { SeoService } from './seo.service';

dayjs.extend(utc);

@Injectable({
  providedIn: 'root'
})
export class AudienceService {

  audience: string = null;
  audiencePageData: any = null;
  modularContent: any = null;
  articles: any[] = [];
  usedArticles: any[] = [];
  components: any[] = [];

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private dataService: DataService,
    private modulesService: ModulesService,
    private modalService: ModalService,
    private dataLayerService: DataLayerService,
    private seoService: SeoService,
  ) { }

  async getAudiencePageData(codename, categoryTaxonomy, audienceTaxonomy) {
    this.modalService.showAvailableModal(codename);

    let audiencePageData = await this.kontentDeliveryService.getItem(null, {
      'system.type': 'template___audience_page',
      'order': 'system.last_modified[desc]',
      'elements.audiences[contains]': codename,
      'depth': 5,
    });

    this.audience = codename;
    this.modularContent = audiencePageData.modular_content;
    this.audiencePageData = audiencePageData.items.length > 0 ? audiencePageData.items[0] : null;

    if (this.audiencePageData) {
      // audience page articles library
      const primaryArticles = await this.kontentDeliveryService.getItem(null, {
        'system.type': 'ncoa_article_content',
        'elements': 'primary_image,title,display_date,url,event_date_time,categories,audiences,secondary_audiences,secondary_categories,spanish_subcategory,other',
        'elements.audiences[any]': codename,
        'order': 'elements.display_date[desc]',
        'limit': 200,
      });

      const secondaryArticles = await this.kontentDeliveryService.getItem(null, {
        'system.type': 'ncoa_article_content',
        'elements': 'primary_image,title,display_date,url,event_date_time,categories,audiences,secondary_audiences,secondary_categories,spanish_subcategory,other',
        'elements.secondary_audiences[any]': codename,
        'order': 'elements.display_date[desc]',
        'limit': 200,
      });

      let articles = [...primaryArticles.items, ...secondaryArticles.items];
          articles = articles.map((article) => ({
            elements: article.elements,
            system: article.system,
            date: new Date(article.elements.display_date.value || article.system.last_modified),
          }));
          articles = articles.sort((a, b) => b.date - a.date);
          articles = articles.filter((v, i, a) => a.findIndex(t => (t.system.id === v.system.id)) === i);

      this.articles = articles;

      // hero
      const headline = audienceTaxonomy.terms.find((audience) => audience.codename === codename).name;
      this.components.push({
        componentType: this.modulesService.moduleTypes.category_page_hero,
        category: {
          breadcrumbs: [],
          headline,
          description: this.audiencePageData.elements.taxonomy_description.value,
          photo: this.audiencePageData.elements.header_image.value.length > 0
            ? {
              responsive: this.dataService.remodelImageSrc(this.audiencePageData.elements.header_image.value[0].url),
              url: this.audiencePageData.elements.header_image.value[0].url,
              alt: this.audiencePageData.elements.header_image.value[0].description,
            } : null,
        },
      });

      // feature articles (content packages)
      const featuredArticles = [];

      if (this.audiencePageData.elements.featured_articles.value.length > 0) {
        this.audiencePageData.elements.featured_articles.value.map(codename => {
          const article = this.modularContent[codename];

          if (article) {
            this.usedArticles.push(article);

            const index = this.articles.findIndex((libArticle) => libArticle.system.id === article.system.id);

            if (index >= 0) {
              this.articles.splice(index, 1);
            }

            featuredArticles.push({
              type: article.elements.event_date_time.value ? 'event' : 'article',
              title: article.elements.title.value,
              eyebrow: article.elements.display_date.value
                ? dayjs.utc(article.elements.display_date.value).format('MMM D, YYYY')
                : dayjs.utc(article.system.last_modified).format('MMM D, YYYY'),
              image: article.elements.primary_image.value.length > 0 ? {
                url: article.elements.primary_image.value[0].url,
                alt: article.elements.primary_image.value[0].description,
                responsive: this.dataService.remodelImageSrc(article.elements.primary_image.value[0].url),
              } : article.elements.event_date_time.value ? {
                url: '/assets/images/content-package-green-tablet.png',
                alt: article.elements.title.value,
              } : {
                url: '/assets/images/content-package-coral-tablet.png',
                alt: article.elements.title.value,
              },
              cta: {
                url: `/article/${article.elements.url.value}`,
                text: article.elements.event_date_time.value ? 'View Event Details' : 'Read Article',
              },
            });
          }
        });
      } else {
        const max = this.articles.length > 6 ? 6 : this.articles.length;
        const articles = this.articles.slice(0, max);

        articles.forEach((article) => {
          this.usedArticles.push(article);

          const index = this.articles.findIndex((libArticle) => libArticle.system.id === article.system.id);

          if (index >= 0) {
            this.articles.splice(index, 1);
          }

          featuredArticles.push({
            type: article.elements.event_date_time.value ? 'event' : 'article',
            title: article.elements.title.value,
            eyebrow: article.elements.display_date.value
              ? dayjs.utc(article.elements.display_date.value).format('MMM D, YYYY')
              : dayjs.utc(article.system.last_modified).format('MMM D, YYYY'),
            image: article.elements.primary_image.value.length > 0 ? {
              url: article.elements.primary_image.value[0].url,
              alt: article.elements.primary_image.value[0].description,
              responsive: this.dataService.remodelImageSrc(article.elements.primary_image.value[0].url),
            } : article.elements.event_date_time.value ? {
              url: '/assets/images/content-package-green-tablet.png',
              alt: article.elements.title.value,
            } : {
              url: '/assets/images/content-package-coral-tablet.png',
              alt: article.elements.title.value,
            },
            cta: {
              url: `/article/${article.elements.url.value}`,
              text: article.elements.event_date_time.value ? 'View Event Details' : 'Read Article',
            },
          });
        });
      }
      
      this.components.push({
        componentType: this.modulesService.moduleTypes.content_package,
        articles: featuredArticles,
      });

      // additional components
      (this.audiencePageData.elements.additional_modules.value || []).forEach((codename) => {
        const component = this.modularContent[codename];
        switch (component.system.type) {
          // featured page block
          case this.modulesService.moduleTypes.featured_page_block: {
            this.components.push(this.modulesService.featurePageBlock(component, this.modularContent));
            break;
          }

          // featured tool breaker
          case this.modulesService.moduleTypes.featured_tool_breaker: {
            this.components.push(this.modulesService.featuredToolBreaker(component));
            break;
          }

          // donate block
          case this.modulesService.moduleTypes.call_to_donate: {
            this.components.push(this.modulesService.donateBlock(component));
            break;
          }

          // newsletter block
          case this.modulesService.moduleTypes.newsletter_signup_block: {
            this.components.push(this.modulesService.newsLetterBlock(component));
            break;
          }

          // category pathing standard
          case this.modulesService.moduleTypes.category_pathing_std: {
            this.components.push(this.modulesService.categoryPathingStandard(component, this.modularContent));
            break;
          }

          // content package
          case this.modulesService.moduleTypes.content_package: {
            this.contentPackage(component);
            break;
          }
          // external form package
          case this.modulesService.moduleTypes.external_form: {
            this.components.push(this.modulesService.externalForm(component));
            break;
          }
        }
      });

      // special components
      // category pathing
      await this.categoryPathing(codename, categoryTaxonomy);
      // end category pathing

      // more stories
      this.moreStories();
      // end more stories

      // analytics
      this.dataLayerService.push({
        url: window.location.href,
        pageTitle: headline,
        contentType: 'taxonomy',
        category: null,
        audience: codename,
      });

      // seo service
      const seo = {
        title: headline,
        description:
          this.audiencePageData.elements.seo_metadata_example_to_include_in_any_type__meta_description.value
          || this.audiencePageData.elements.taxonomy_description.value,
        image: this.audiencePageData.elements.header_image.value[0]?.url,
        ogTitle: this.audiencePageData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value
          || headline,
        ogDescription: this.audiencePageData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value
          || this.audiencePageData.elements.taxonomy_description.value,
        ogImage: this.audiencePageData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value[0]?.url
          || this.audiencePageData.elements.header_image.value[0]?.url,
        twitterTitle: this.audiencePageData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value
          || headline,
        twitterDescription: this.audiencePageData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value
          || this.audiencePageData.elements.taxonomy_description.value,
        twitterImage: this.audiencePageData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value[0]?.url
          || this.audiencePageData.elements.header_image.value[0]?.url,
      };

      this.seoService.applyMetaData(seo);

      return this.components;
    }

    return null;
  }

  getManualArticles(component) {
    let selectedArticles = [];
    const articleCodeNames = component.elements.content.value;

    articleCodeNames.forEach((codename) => {
        const article = this.modularContent[codename];
        if (article) {
          selectedArticles.push({
            ...article,
            date: new Date(article.elements.display_date.value || article.system.last_modified),
          });
        }
    });

    return selectedArticles;
  }

  getDynamicArticles(component) {
    const categories = component.elements.categories.value.map(({ codename }) => codename);
    const audiences = component.elements.audiences.value.map(({ codename }) => codename);
    const specialType = component.elements.other_categories.value.map(({ codename }) => codename);
    let articles = this.articles;

    let filteredArticles = articles.filter((article) => {
      const primaryCategoryFound = article.elements.categories.value.some((x) => categories.includes(x.codename));
      const secondaryCategoryFound = article.elements.secondary_categories.value.some((x) => categories.includes(x.codename));

      const primaryAudienceFound = article.elements.audiences.value.some((x) => audiences.includes(x.codename));
      const secondaryAudienceFound = article.elements.secondary_audiences.value.some((x) => audiences.includes(x.codename));

      const specialTypeFound = article.elements.other.value.some((x) => specialType.includes(x.codename));

      return primaryCategoryFound || secondaryCategoryFound || primaryAudienceFound || secondaryAudienceFound || specialTypeFound;
    });

    const max = filteredArticles.length > 7 ? 7 : filteredArticles.length;

    return filteredArticles.slice(0, max);
  }

  async contentPackage(component) {
    let contentPackageArticles = [];
    let selectedArticles = [];
    const contentPopulationMethod = component.elements.content_population_method.value[0].codename;

    if (contentPopulationMethod === 'dynamically') {
      selectedArticles = this.getDynamicArticles(component);
    } else if (contentPopulationMethod === 'dynamic_with_manual_additions') {
      selectedArticles = this.getManualArticles(component).concat(await this.getDynamicArticles(component));
      selectedArticles.sort((a, b) => (a.date > b.date) ? -1 : 1);
      const max = selectedArticles.length > 7 ? 7 : selectedArticles.length;
      selectedArticles = selectedArticles.slice(0, max);
    } else {
      selectedArticles = this.getManualArticles(component);
    }

    if (selectedArticles.length > 0) {
      contentPackageArticles = selectedArticles.map((article) => {
        this.usedArticles.push(article);

        const index = this.articles.findIndex((libArticle) => libArticle.system.id === article.system.id);

        if (index >= 0) {
          this.articles.splice(index, 1);
        }

        const type = article.elements.event_date_time.value ? 'event' : 'article';
        const title = article.elements.title.value;
        const cta = {
          url: `/article/${article.elements.url.value}`,
          text: type === 'event' ? 'View Event Details' : 'Read Article',
        };
        const image = article.elements.primary_image.value.length > 0 ? {
          url: article.elements.primary_image.value[0].url,
          alt: article.elements.primary_image.value[0].description,
          responsive: this.dataService.remodelImageSrc(article.elements.primary_image.value[0].url),
        } : {};
        const eyebrow = dayjs.utc(article.date).format('MMM D, YYYY');

        return {
          type,
          title,
          cta,
          image,
          eyebrow,
        };
      });

      this.components.push({
        componentType: this.modulesService.moduleTypes.content_package,
        articles: contentPackageArticles,
      });
    }
  }

  async categoryPathing(codename, categoryTaxonomy) {
    const categories = categoryTaxonomy.terms;
    const categoryPages = await this.kontentDeliveryService.getItem(null, {
      'system.type': 'taxonomy_custom_content',
      'elements.audiences[any]': codename,
      'elements.categories[any]': categories.map(term => term.codename).join(','),
      'order': 'system.last_modified[desc]',
    });
    const paths = [];

    categories.forEach((category) => {
      const path = categoryPages.items.find((page) => {
        const pageCategories = page.elements.categories.value;
        return pageCategories.find((pageCategory) => pageCategory.codename === category.codename);
      });

      if (path) {
        paths.push({
          ...path,
          category,
        });
      }
    });

    const pathLinks = paths.map(path => ({
      url: `/${path.elements.category_page_url.value}`,
      title: path.category.name,
      linkType: path.system.type,
    }));

    this.components.push({
      componentType: this.modulesService.moduleTypes.category_pathing,
      page: 'category',
      categories: pathLinks,
    });
  }

  moreStories() {
    const moreStories = this.articles.map((article) => {
      const type = article.elements.event_date_time.value ? 'event' : 'article';
      const title = article.elements.title.value;
      const cta = {
        url: `/article/${article.elements.url.value}`,
        text: type === 'event' ? 'View Event Details' : 'Read Article'
      };
      const image = article.elements.primary_image.value.length > 0 ? {
        url: article.elements.primary_image.value[0].url,
        alt: article.elements.primary_image.value[0].description,
        responsive: this.dataService.remodelImageSrc(article.elements.primary_image.value[0].url),
      } : type === 'event' ? {
        url: '/assets/images/content-package-green-tablet.png',
        alt: title,
      } : {
        url: '/assets/images/content-package-coral-tablet.png',
        alt: title,
      };
      const eyebrow = dayjs.utc(article.date).format('MMM D, YYYY');

      return {
        type,
        title,
        url: cta.url,
        image,
        eyebrow,
      };
    });

    if (moreStories.length > 0) {
      this.components.push({
        componentType: this.modulesService.moduleTypes.category_load_more,
        entries: moreStories,
      });
    }
  }
}
