import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@services/planner/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'sign-in-banner',
  templateUrl: './sign-in-banner.component.html',
  styleUrls: ['./sign-in-banner.component.scss']
})
export class SignInBanner implements OnInit {

  @Input() title: string;
  @Input() desc: string;
  @Input() signUpCTA: {
    url: string;
    text: string;
  };

  @Input() signInCTA: {
    url: string;
    text: string;
  };

  isLoggedIn: boolean = false;

  constructor(
    private userService: UserService,
  ) {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.isLoggedIn = true;
      }
    });
  }

  ngOnInit(): void {
  }

}
