import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '@services/search.service';
import { ArticleCardType } from '@components/article-card/article-card.types';
import { TimelineLite, TweenLite } from 'gsap';
import { GlobalService } from '@services/global.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {
  items: any[] = [];
  recentArticles: ArticleCardType[] = [];
  audienceOptions: any[] = [];
  searchKey: string;
  resultTitle: string;
  totalItems: number = 0;
  filteredAudience: string;
  filteredType: string;
  shownItems: number = 0;
  groupDisplayed: number = 0;
  groupTotal: number = 0;

  constructor(
    private activeRoute: ActivatedRoute,
    private searchService: SearchService,
    private router: Router,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    const searchKey = this.activeRoute.snapshot.queryParams.q;
    const filteredAudience = this.activeRoute.snapshot.queryParams.audience;
    const filteredType = this.activeRoute.snapshot.queryParams.type;

    this.activeRoute.data.subscribe(({ searchPageData }: any) => {
      const [ listData, audienceData ] = searchPageData;

      this.totalItems = this.searchService.totalItems;
      this.resultTitle = this.searchService.totalItems > 0 ? 'Most Relevant' : '';
      this.items = listData;
      this.searchKey = searchKey;
      this.filteredAudience = filteredAudience || 'all';
      this.filteredType = filteredType || 'all';
      this.audienceOptions = audienceData.map(({ codename, name }) => ({
        codename,
        name,
      }));

      this.shownItems = this.totalItems >= 18 ? 18 : this.totalItems;
      this.groupTotal = Math.ceil(this.totalItems / 6);
      this.groupDisplayed = Math.ceil(this.shownItems / 6);

      this.globalService.loadFooter(true);
    });
  }

  getMoreArticles() {
    const newGroupDisplayed = this.groupDisplayed + 1;

    if (newGroupDisplayed <= this.groupTotal) {
      const topOffset = window.scrollY;

      document.body.style.top = `-${topOffset}px`;
      document.body.style.position = 'fixed';
      document.body.style.width = `${window.innerWidth}px`;
      document.querySelector('.header--global').classList.add('is-scrolled');
      document.querySelector('.header--global').classList.add('is-sticky');

      const data = this.searchService.getMoreData(this.items.length);

      this.items = [...this.items, ...data];
      this.shownItems = this.items.length;
      this.groupDisplayed = Math.ceil(this.shownItems / 6);

      setTimeout(() => {
        document.body.style.removeProperty('top');
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('width');
        window.scrollTo(0, topOffset);

        const targetGroup = document.querySelector(`.results-list__list .group:nth-child(${newGroupDisplayed})`);

        if (targetGroup) {
          const firstLink: HTMLElement = targetGroup.querySelector('a');

          if (firstLink) {
            firstLink.focus();
          }
        }
      }, 320);
    }
  }

  searchArticle(keyword): void {
    this.searchKey = keyword;

    const queryParams = {
      q: keyword && keyword.trim().length > 0 ? keyword : null,
      audience: this.filteredAudience && this.filteredAudience.trim().length > 0 ? this.filteredAudience : null,
      type: this.filteredType && this.filteredType.trim().length > 0 ? this.filteredType : null,
    };

    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });

    new TimelineLite()
      .to('.results-list', {
        y: 200,
        opacity: 0,
        duration: 0.4,
      }, 'stage1')
      .to('.no-result', {
        position: 'absolute',
        opacity: 0,
        duration: 0.4,
      }, 'stage1')
      .eventCallback('onComplete', () => {
        const data = this.searchService.searchData(this.searchKey, this.filteredAudience, this.filteredType);

        this.totalItems = this.searchService.totalItems;
        this.resultTitle = this.searchService.totalItems > 0 ? 'Most Relevant' : '';
        this.shownItems = this.searchService.totalItems > 6 ? 6 : this.searchService.totalItems;
        this.items = data;

        if (this.totalItems > 0) {
          new TweenLite('.results-list', { y: 0, opacity: 1, duration: 0.4 });
        } else {
          new TweenLite('.no-result', { position: 'relative', opacity: 1, duration: 0.4 });
        }
      });
  }

  filterArticle(selectedFilters): void {
    this.filteredAudience = selectedFilters.audience;
    this.filteredType = selectedFilters.content_type;

    const queryParams = {
      q: this.searchKey && this.searchKey.trim().length > 0 ? this.searchKey : null,
      audience: this.filteredAudience && this.filteredAudience.trim().length > 0 ? this.filteredAudience : null,
      type: this.filteredType && this.filteredType.trim().length > 0 ? this.filteredType : null,
    };

    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });

    new TimelineLite()
      .to('.results-list', {
        y: 200,
        opacity: 0,
        duration: 0.4,
      }, 'stage1')
      .to('.no-result', {
        position: 'absolute',
        opacity: 0,
        duration: 0.4,
      }, 'stage1')
      .eventCallback('onComplete', () => {
        const data = this.searchService.searchData(this.searchKey, this.filteredAudience, this.filteredType);

        this.totalItems = this.searchService.totalItems;
        this.resultTitle = this.searchService.totalItems > 0 ? 'Most Relevant' : '';
        this.shownItems = this.searchService.totalItems > 6 ? 6 : this.searchService.totalItems;
        this.items = data;

        if (this.totalItems > 0) {
          new TweenLite('.results-list', { y: 0, opacity: 1, duration: 0.4 });
        } else {
          new TweenLite('.no-result', { position: 'relative', opacity: 1, duration: 0.4 });
        }
      });
  }
}
