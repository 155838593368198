import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { image } from './featured-tool-breaker.types';

@Component({
  selector: 'ncoa-featured-tool-breaker',
  templateUrl: './featured-tool-breaker.component.html',
  styleUrls: ['./featured-tool-breaker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeaturedToolBreakerComponent implements OnInit {
  @Input() variation: string; // form or link (benefits checkup or age well planner)
  @Input() eyebrow: string;
  @Input() headline: string;
  @Input() description: string;
  @Input() image: image;
  @Input() isError: boolean;
  @Input() isSubmitted: boolean;
  @Input() cta: {
    text: string;
    url: string;
  };

  constructor() { }

  ngOnInit(): void {
  }

}
