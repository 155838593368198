<div class="category-search-page">
  <ncoa-author-header
    [image]="image"
    [eyebrow]="eyebrow"
    [authorName]="title"
    [authorBio]="description"
    [isInCategorySearch]="true"
    ncoa-engageable="0"
  ></ncoa-author-header>
  <ncoa-special-pathing
  *ngIf="specialPaths?.paths?.length > 0"
  [paths]="specialPaths?.paths"
></ncoa-special-pathing>
  <ncoa-custom-list-header
    [filters]="filters"
    [totalItems]="totalItems"
    [searchKey]="searchKey"
    (searchContent)="searchContent($event)"
    [placeholder]="placeholder"
    [hideSearchBar]="hideSearchBar"
    [showFilterOption]="showFilterOption"
    [searchBarLink]="searchBarLink"
    (updateSelectedFilters)="updateSelectedFilters($event)"
    [searchTitle]="searchTitle"
    [filterByDate]="filterByDate"
    [filterYears]="filterYears"
    (updateFilterYear)="updateFilterYear($event)"
    (updateFilterMonth)="updateFilterMonth($event)"
    [marginTop]="marginTop"
    ncoa-engageable="1"
  ></ncoa-custom-list-header>
  <ncoa-results-list
    [items]="items"
    [title]="resultTitle"
    [hasMoreItems]="hasMoreItems"
    [getMoreItems]="getMoreContents.bind(this)"
    ncoa-engageable="2"
    ncoa-engageable-name="custom-search-results-list"
    [type]="(filterGroupTags.length > 0)? 'categorySearch':'default'"
  ></ncoa-results-list>
  <ng-container *ngIf="totalItems === 0">
    <div class="no-result" [ngClass]="totalItems === 0 ? 'is-active' : ''"
      [attr.aria-hidden]="totalItems === 0 ? false : true">
      <svg width="93" height="106" viewBox="0 0 93 106" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M55.5496 66.7615C73.321 66.7615 87.7276 52.2559 87.7276 34.3622C87.7276 16.4686 73.321 1.96295 55.5496 1.96295C37.7782 1.96295 23.3716 16.4686 23.3716 34.3622C23.3716 52.2559 37.7782 66.7615 55.5496 66.7615Z"
          fill="#DAE4E7" />
        <path
          d="M7.06838 83.3395C4.65038 80.9048 4.65038 76.7847 7.06838 74.3501L29.2148 52.4336C29.2148 52.4336 30.432 54.8597 32.8666 57.3134C35.3013 59.7672 38.2227 61.3424 38.2227 61.3424L15.9964 83.3395C13.3924 85.7741 9.48638 85.7741 7.06838 83.3395Z"
          fill="#DAE4E7" />
        <ellipse cx="55.5077" cy="34.3519" rx="24.3456" ry="24.537" fill="#F3F6F7" />
        <path
          d="M51.0807 23.3015C50.8437 20.806 52.8062 18.6481 55.313 18.6481C57.7843 18.6481 59.7348 20.7481 59.5527 23.2127L58.3852 39.0141C58.2728 40.5358 57.0054 41.7129 55.4796 41.7129C53.9773 41.7129 52.7213 40.5706 52.5792 39.075L51.0807 23.3015Z"
          fill="#0B4A5D" stroke="#0B4A5D" stroke-width="2" />
        <ellipse cx="55.5078" cy="47.1112" rx="2.43456" ry="2.4537" fill="#0B4A5D" stroke="#0B4A5D" stroke-width="2" />
      </svg>
      <h3>No results found. Please try again!</h3>
      <div *ngIf="!hideSearchBar">
        <h4>
          <span>Try these tips to expand your search:</span>
        </h4>
        <p>
          Check the spelling of your words. Try using fewer, different or more
          general keywords.
        </p>
      </div>
      <div *ngIf="hideSearchBar">
        <h4>
          <span>We don't have any content matching those filters yet.</span>
        </h4>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="components.length > 0 ">
  <ng-container *ngFor="let component of components; index as order" [ngSwitch]="component?.componentType">
    <ncoa-content-package *ngSwitchCase="componentTypes.content_package" [title]="component.title"
      [articles]="component.articles" ncoa-engageable="{{ order }}"></ncoa-content-package>
    <ncoa-feature-page-block *ngSwitchCase="componentTypes.featured_page_block" [image]="component.image"
      [headline]="component.headline" [description]="component.description" [type]="component.type"
      [background]="component.background" [audiences]="component.audiences" [url]="component.url"
      [blockCTA]="component.blockCTA"
      [orientation]="component.orientation" ncoa-engageable="{{ order }}"></ncoa-feature-page-block>
    <ncoa-featured-tool-breaker *ngSwitchCase="componentTypes.featured_tool_breaker" [variation]="component.variation"
      [eyebrow]="component.eyebrow" [headline]="component.headline" [description]="component.description"
      [image]="component.image" [cta]="component.cta" ncoa-engageable="{{ order }}"></ncoa-featured-tool-breaker>
    <ncoa-donate *ngSwitchCase="componentTypes.call_to_donate" [heading]="component.heading"
      [description]="component.description" [form_desc]="component.form_desc" [background]="component.photo"
      [backgroundCaption]="component.caption" ncoa-engageable="{{ order }}"></ncoa-donate>
    <ncoa-external-form *ngSwitchCase="componentTypes.external_form" [component]="component"
      ncoa-engageable="{{ order }}"></ncoa-external-form>
    <ncoa-newsletter *ngSwitchCase="componentTypes.newsletter_signup_block" [heading]="component.heading"
    [everyActionFormUrl]="component.everyActionFormUrl"
      [description]="component.description" [background]="component.background"
      [backgroundCaption]="component.backgroundCaption" [mobilebackground]="component.mobilebackground"
      [width]="component.width" [height]="component.height" ncoa-engageable="{{ order }}"></ncoa-newsletter>
    <app-partners *ngSwitchCase="componentTypes.partner_grid" [heading]="component.heading"
      [partners]="component.partners" ncoa-engageable="{{ order }}"></app-partners>
    <ncoa-subcategory-pathing *ngSwitchCase="componentTypes.category_pathing" [headline]="component.headline"
      [categories]="component.categories" [ncoa-engageable]="order" page="standard"></ncoa-subcategory-pathing>
    <ncoa-topic *ngSwitchCase="componentTypes.topic_different_audience" [theme]="component.theme"
      [topic]="component.topic" ncoa-engageable="{{ order }}"></ncoa-topic>
    <ncoa-audience *ngSwitchCase="componentTypes.cross_pathing_module" [audience]="component.audience"
      ncoa-engageable="{{ order }}"></ncoa-audience>
    <ncoa-special-pathing *ngSwitchCase="'specialPathing'" [paths]="component.paths"></ncoa-special-pathing>
  </ng-container>
</ng-container>
</div>
