import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataLayerService } from '@services/data-layer.service';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { AssessmentService } from '@services/planner/assessment.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserPromptService } from '@services/planner/user-prompt.service';
import { UserService } from '@services/planner/user.service';
import { UtilityService } from '@services/planner/utility.service';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Component({
  selector: 'ncoa-breakdown-header',
  templateUrl: './breakdown-header.component.html',
  styleUrls: ['./breakdown-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreakdownHeaderComponent implements OnInit {

  @Input() backLink: {
    url: string;
    text: string;
  };
  @Input() profileLink: {
    url: string;
    text: string;
  };
  @Input() headline: string;
  @Input() id: string;
  @Input() codename: string;
  @Input() isSaved: boolean;
  @Input() variationType: string = '';

  @Input() showBtns: boolean = true;

  // NAM-440
  language$ : Observable<string> = this.tools.language$;
  selectedLanguage = localStorage.getItem('language') || 'en';
  showLanguageToogle = false;
  baseLanguageOptions = {
    en : [
      {
        value: 'en',
        label: 'English'
      },
      {
        value: 'es',
        label: 'Spanish'
      }
    ],
    es: [
      {
        value: 'en',
        label: 'Inglés'
      },
      {
        value: 'es',
        label: 'Español'
      }
    ]
  };
  languageOptions = [];
  printPageText = 'Print this page';
  fullPrintPageText = { en: 'Print this page', es: 'imprime esta página' };
  resultsLabel = 'Results';
  fullResultsLabel = { en: 'Results', es: 'Resultados' };

  constructor(
    private tools: ToolsPrimaryConfigService,
    private userService: UserService,
    private utility: UtilityService,
    private modalService: UserPromptService,
    private dataLayerService: DataLayerService,
    private kontent: KontentDeliveryService,
    private router: Router,
    private route: ActivatedRoute,
    private cas: CustomerActivityService,
  ) { 
    // NAM-440: Only show language toggle option on the Falls pages.
    if (this.router.url.indexOf('/age-well-planner/assessment/falls-free-checkup') === 0) {
      this.language$.subscribe((lang: string) => {
        this.selectedLanguage = lang;
        this.languageOptions = this.baseLanguageOptions[`${this.selectedLanguage}`];
        this.printPageText = this.fullPrintPageText[this.selectedLanguage];
        this.resultsLabel = this.fullResultsLabel[this.selectedLanguage];
      });

      this.showLanguageToogle = true;
      this.selectedLanguage = this.tools.language || 'en';
      this.languageOptions = this.baseLanguageOptions[`${this.selectedLanguage}`];
      this.printPageText = this.fullPrintPageText[this.selectedLanguage];
      this.resultsLabel = this.fullResultsLabel[this.selectedLanguage];
    }
  }

  ngOnInit(): void { }

  onLanguageToggle(event: any) {
    const language = event.currentTarget.value;
    this.tools.setLanguage(language);

    this.cas.updateActivity({'fallsLanguage':language || 'en'}).subscribe(() => {});
    this.languageOptions = this.baseLanguageOptions[`${language}`];
  }

  clickSave(): void {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.kontent.getItem(null, {
          'system.type': 'awa_benefits_tool___card__tool_',
          'elements.tool[any]': this.codename,
        }).then((response) => {
          if (response.items.length > 0) {
            this.utility.getSavedContent().then((savedContent) => {
              let newSavedContent;
              let isSaved;

              const found = savedContent.find((content) => content.ContentId === this.id);

              if (found) {
                // un-save tool
                newSavedContent = savedContent.filter((content) => content.ContentId !== this.id);

                // un-save tool cards
                response.items.forEach((item) => {
                  newSavedContent = newSavedContent.filter((content) => content.ContentId !== item.system.id);
                });

                isSaved = false;
              } else {
                // save tool
                newSavedContent = savedContent.concat({ ContentId: this.id });

                // save tool cards
                response.items.forEach((item) => {
                  newSavedContent = newSavedContent.concat({ ContentId: item.system.id });
                });

                isSaved = true;
              }

              // analytics
              if (this.variationType === 'retirement') {
                this.dataLayerService.push({
                  'event': 'retirementResults',
                  'componentType': 'header click',
                  'clickText': isSaved? 'Save Results':'Results Saved',
                  'pageType': 'income-breakdown-page'
                },false);
              } else if (this.variationType === 'estimator') {
                this.dataLayerService.push({
                  'event': 'medicareResults',
                  'componentType': 'header click',
                  'clickText': isSaved? 'Save Results':'Results Saved',
                  'pageType': 'cost-estimator-page'
                },false);
              }

              // fire save content update
              this.utility.updateSavedContent(newSavedContent).then(() => {
                this.isSaved = isSaved;
              });
            });
          }
        });
      } else {
        this.modalService.isModalOpen(true);
      }
    });
  }

  removeExcessText(str: string){
     return str.replace('Results:','');
  }


  clickProfile(): void {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        window.location.href = this.tools.routes.find(({ type }) => type === 'profile').route;
      }else{
        this.modalService.isModalOpen(true);
      }
    });

    if(this.variationType === 'retirement'){
      this.dataLayerService.push({
        'event': 'retirementResults',
        'componentType': 'header click',
        'clickText': 'Edit Profile',
        'pageType': 'income-breakdown-page'
      },false);
    }else if(this.variationType === 'estimator'){
      this.dataLayerService.push({
        'event': 'medicareResults',
        'componentType': 'header click',
        'clickText': 'Edit Profile',
        'pageType': 'cost-estimator-page'
      },false);
    }
  }

  clickPrint(): void {

    if(this.variationType === 'retirement'){
      this.dataLayerService.push({
        'event': 'retirementResults',
        'componentType': 'header click',
        'clickText': 'Print this page',
        'pageType': 'income-breakdown-page'
      },false);
    }else if(this.variationType === 'estimator'){
      this.dataLayerService.push({
        'event': 'medicareResults',
        'componentType': 'header click',
        'clickText': 'Print this page',
        'pageType': 'cost-estimator-page'
       },false);
    }
    const considerationToggle: HTMLDivElement = document.querySelector('.consideration--toggle');
    if(considerationToggle){
      const childredToogle: HTMLAnchorElement = considerationToggle.querySelector('a');
      if(childredToogle.innerText === 'Expand'){
        childredToogle.click();
      }
    }
    const comparison: any = document.querySelectorAll('.plan--detail__breakdown');
    comparison.forEach(element => {
      element.classList.add('is-open-print');
    });
    window.print();
  }

  async startOver(): Promise<void> {
    // clear query params
    await this.router.navigate([], { queryParams: {} });
    //auth'd {
    this.userService.isLoggedIn()
      .pipe(take(1))
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.editAssessment();
        }
        else {
          //clear data and reload
          const index = this.tools.customerActivity.basic.completedAssessments.findIndex((assessment) => assessment === 'fallsRiskAssessment');
          if (index > -1) {
            this.userService.logout();
          }
          //reload window
          window.location.reload();
        }
      });


  }

  editAssessment(): void {
    // get query parameters
    let queryParams = this.route.snapshot.queryParams;
    // add the edit parameter
    queryParams = { ...queryParams, edit: 'true' };
    // navigate
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' }).then(() => {
      window.location.reload();
    });
    return;
  }
}
