import { Injectable } from '@angular/core';
import { GlobalService } from '@services/global.service';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { ToolsPrimaryConfigService } from './tools-primary-config.service';
import { stateOptions } from '../../components/planner/benefits-listing/benefits-listing.variables';
import { BenefitsService } from './benefits.service';
import { UtilityService } from './utility.service';
import { DataLayerService } from '@services/data-layer.service';
import { CustomerActivityService } from './customer-activity.service';
import { SeoService } from '@services/seo.service';

@Injectable({ providedIn: 'root' })
export class SubTopicService {
  parentTopicName: string;
  parentVerticalName: string;
  completedAssessments: any;

  constructor(
    private tools: ToolsPrimaryConfigService,
    private globalService: GlobalService,
    private kontent: KontentDeliveryService,
    private benefitsService: BenefitsService,
    private utility: UtilityService,
    private dataLayerService: DataLayerService,
    private cas: CustomerActivityService,
    private seoService: SeoService,
  ) { }

  async getData() {
    this.completedAssessments = this.tools.customerActivity.basic?.completedAssessments || [];

    const raw = this.tools.modular_content[this.tools.currentConfig.core.route.codename];
    const { elements, system } = raw;
    const { codename } = system;

    let data = {};

    // subtopicHeader
    if (!this.tools.singularVerticalTopic) {
      const parentTopic = this.tools.currentConfig.core.route.parentTopic;
      const parentRoute = this.tools.routes
        .filter((route) => route.type && route.type === 'vertical-topic')
        .find((route) => route.codename === parentTopic);

      const subTopicRoutes = this.tools.routes
        .filter((route) => route.codename && route.codename === codename);

      // page id
      data['id'] = system.id;

      const menu = [];
      // learn tab
      const learnTab = subTopicRoutes.find((route) => route.type === 'vertical-subtopic-learn');
      if (learnTab) {
        menu.push({
          label: elements.learn_tab_title.value,
          url: learnTab.route,
          active: learnTab.route === window.location.pathname ? 1 : 0
        });
      }

      // tools tab
      const toolsTab = subTopicRoutes.find((route) => route.type === 'vertical-subtopic-tools');
      if (toolsTab) {
        menu.push({
          label: elements.tools_tab_title.value,
          url: toolsTab.route,
          active: toolsTab.route === window.location.pathname ? 1 : 0
        });
      }

      // experts tab
      const expertsTab = subTopicRoutes.find((route) => route.type === 'vertical-subtopic-experts');
      if (expertsTab) {
        menu.push({
          label: elements.experts_tab_title.value,
          url: expertsTab.route,
          active: expertsTab.route === window.location.pathname ? 1 : 0
        });
      }

      // landing page
      const landingPage = subTopicRoutes.find((route) =>  route.type === 'vertical-subtopic-landing');

      if(landingPage && landingPage.subTopicType === 'landing'){
        data['subtopicHeader'] = {
          type: 'vertical-header',
          title: elements.title.value,
          desc: elements.description.value,
          savedTitle: elements.saved_title.value,
          savedDesc: elements.saved_description.value,
          image: {
            sizes: this.globalService.remodelImageSrc(elements.hero_image.value[0].url),
            src: elements.hero_image.value[0].url,
            alt: elements.hero_image.value[0].description,
        },
        };



        if (elements.plan_steps){
          data['subtopicHeader']['planSteps'] = elements.plan_steps.value.map((step) => {
    
            const elements = this.tools.modular_content[step].elements
    
            return {
              title: elements.title.value,
              infographic: {
                url: elements.infographic.value[0].url,
                alt: elements.infographic.value[0].description
              }
            }
          })
        }



      } else {
        data['subtopicHeader'] = {
          type: 'subtopic-header',
          heading: elements.title.value,
          backCTA: {
            url: parentRoute.route,
            text: parentRoute.text,
          },
          photo: {
            url: elements.hero_image?.value[0].url,
            alt: elements.hero_image?.value[0].description,
          },
          menu,
        };
      }
    } else {
      let assessmentCTA =  {
        url: this.tools.currentConfig.core.primaryAssessment.url,
        text: this.tools.currentConfig.core.primaryAssessment.text,
      };

      const activity = await this.cas.getActivity().first().toPromise();
      if (activity && activity.basic && activity.basic.completedAssessments.length > 0) {
        const generalAssessment = this.tools.currentConfig.core.primaryAssessment.assessmentID;
        const completed = activity.basic.completedAssessments.find((key) => key === generalAssessment);

        if (completed) {
          assessmentCTA = null;
        }
      }

      data['subtopicHeader'] = {
        type: 'vertical-header',
        title: this.tools.currentConfig.core.route.text,
        desc: elements.description.value,
        image: {
          sizes: this.globalService.remodelImageSrc(elements.hero_image.value[0].url),
          src: elements.hero_image.value[0].url,
          alt: elements.hero_image.value[0].description,
        },
        assessmentCTA,
      };
    }

    // trusted expert promo
    if (elements.trusted_expert_promo?.value.length > 0) {
      const trustedExpertPromo = this.tools.modular_content[elements.trusted_expert_promo.value[0]];
      const promoElements = trustedExpertPromo.elements;
      const alignment = 'right';

      let cta;

      if (promoElements.cta_text__optional_.value.trim().length > 0) {
        let url = promoElements.cta_manual_external_link__optional_.value;

        if (promoElements.cta_internal_link__optional_.value.length > 0 &&
            this.tools.modular_content[promoElements.cta_internal_link__optional_.value[0]]) {
          const linkedContent = this.tools.modular_content[promoElements.cta_internal_link__optional_.value[0]];

          switch (linkedContent.system.type) {
            case 'ncoa_article_content': {
              const slug = linkedContent.elements.url.value;
              url = `${this.tools.rootURL}/resource/${slug}`;
              break;
            }
            case 'awa_benefits_tool___vertical_subtopic': {
              url = !this.tools.singularVerticalTopic ? this.tools.routes.find((route) => (
                route.type === 'vertical-subtopic-experts' &&
                route.codename === system.codename
              )).route : null;
              break;
            }
          }
        }

        cta = url ? {
          label: promoElements.cta_text__optional_.value,
          url,
        } : null;
      }

      data['trustedExpertPromo'] = {
        alignment,
        heading: promoElements.headline.value,
        intro: promoElements.description.value,
        image: {
          sizes: this.globalService.remodelImageSrc(promoElements.featured_image.value[0].url),
          src: promoElements.featured_image.value[0].url,
          alt: promoElements.featured_image.value[0].description,
        },
        cta,
      };
    }

    // trusted experts (article)
    this.parentTopicName = (this.tools.routes.find((route) => (
        route.type === 'vertical-topic' &&
        route.codename === this.tools.currentConfig.core.route.parentTopic
      )) || { text: this.tools.currentConfig.core.route.parentVerticalName }).text;

    this.parentVerticalName = this.tools.routes.find((route) => (
      route.type === 'vertical' &&
      route.codename === this.tools.currentConfig.core.route.parentVertical
    )) ? this.tools.routes.find((route) => (
      route.type === 'vertical' &&
      route.codename === this.tools.currentConfig.core.route.parentVertical
    )).taxonomy.name : this.tools.currentConfig.core.route.parentVerticalName;

    const taxonomy = elements[this.tools.toolTaxonomyCodename].value[0];
    const params = {
      'system.type': 'ncoa_article_content',
      'elements': 'awa_how_to_s_designation,body_ckeditor,title,url,expert_logo,card_eyebrow',
      'elements.awp_trusted_expert[nempty]': true,
      'limit': 500,
    };
    const _prop = `elements.${this.tools.toolTaxonomyCodename}[contains]`;

    params[_prop] = taxonomy.codename;

    const request = await this.kontent.getItem(null, params);

    const remodelTrustedExpert = (article) => {
      const div = document.createElement('div');
            div.innerHTML = article.elements.body_ckeditor.value;
      const text = div.innerText;

      return {
        id: article.system.id,
        type: 'referral',
        eyebrow: article.elements.card_eyebrow.value || `${this.parentVerticalName} Expert`,
        heading: article.elements.title.value,
        intro: article.elements.body_ckeditor.value ? `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...` : '',
        cta: {
          label: 'View Details',
          url: `${this.tools.rootURL}/resource/${article.elements.url.value}`,
        },
        photo: article.elements.expert_logo.value.length > 0 ? {
          url: article.elements.expert_logo.value[0].url,
          alt: article.elements.expert_logo.value[0].description,
        } : null,
        full: true,
        bookmark: true,
      };
    };

    let trustedExperts = request.items;
        trustedExperts = trustedExperts.map((article) => remodelTrustedExpert(article));

    data['trustedExperts'] = {
      type: 'expert',
      heading: elements.trusted_expert_card___1_up?.value || 'Speak with an Expert',
      cards: trustedExperts,
    };

    const otherSubTopics = this.tools.routes.filter((route) => (
      route.type === this.tools.currentConfig.core.route.type &&
      route.parentTopic === this.tools.currentConfig.core.route.parentTopic &&
      route.route !== this.tools.currentConfig.core.route.route
    ));

    if (!this.tools.singularVerticalTopic) {
      // single col card list
      if (otherSubTopics.length > 0) {
        const otherSubTopicKeys = otherSubTopics.map(({ codename }) => codename);
        const topics = [];

        otherSubTopicKeys.forEach((key) => {
          if (this.tools.modular_content[key] && this.parentTopicName) {
            const { system: topicSystem, elements: topicElements } = this.tools.modular_content[key];

            const match = otherSubTopics.find((route) => route.codename === key);
            const learnMatch = this.tools.routes.find((route) => (
              route.type === 'vertical-subtopic-learn' &&
              route.codename === match.codename
            ));

            topics.push({
              id: topicSystem.id,
              eyebrow: !this.tools.singularVerticalTopic ? `${this.parentVerticalName} / ${this.parentTopicName}` : this.parentVerticalName,
              title: topicElements.title.value,
              desc: topicElements.description.value,
              cta: {
                url: learnMatch.route,
                text: 'Learn More',
              },
              image: {
                src: topicElements.hero_image.value[0].url,
                alt: topicElements.hero_image.value[0].description,
              },
            });
          }
        });

        if (topics.length > 0) {
          data['singleColCardList'] = {
            heading: `More on ${this.parentTopicName}`,
            topics,
          };
        }
      }
    } else {
      // vertical pathing
      if (otherSubTopics.length > 0) {
        const items = [];

        otherSubTopics.forEach((subTopic) => {
          const title = subTopic.text;
          const links = [{
            text: 'Explore More',
            url: subTopic.route,
          }];

          items.push({
            title,
            links,
          });
        });

        if (!this.tools.singularVerticalTopic) {
          items.push({
            title: 'Explore All Benefits',
            links: [{
              text: 'Find Benefits',
              url: this.tools.routes.find((route) => route.type === 'all-benefits').route,
            }],
          });
        }

        data['verticalPath'] = {
          heading: `Explore ${this.tools.rawConfig.elements.tool_title.value}`,
          items,
        };
      }
    }

    if (this.tools.currentConfig.core.route.type === 'vertical-subtopic-learn') {
      // for learn page
      data = {
        ...data,
        ...await this.getLearnPageData(elements, system),
      };

      this.dataLayerService.push({
        clickUrl: location.href,
        pageTitle: document.title || '',
        topic: this.tools.currentConfig.core.route.parentTopic || '',
        vertical: this.tools.currentConfig.core.route.parentVertical || '',
        subtopic: this.tools.currentConfig.core.route.subTopicType || '',
        contentType: 'vertical-subtopic-learn',
        pageType: 'vertical-subtopic-page',
      },false);
    } else if (this.tools.currentConfig.core.route.type === 'vertical-subtopic-tools') {
      // for tools page
      data = {
        ...data,
        ...await this.getToolsPageData(elements),
      };


      this.dataLayerService.push({
        clickUrl: location.href,
        pageTitle: document.title || '',
        topic: this.tools.currentConfig.core.route.parentTopic || '',
        vertical: this.tools.currentConfig.core.route.parentVertical || '',
        subtopic: this.tools.currentConfig.core.route.subTopicType || '',
        contentType: 'vertical-subtopic-tools',
        pageType: 'vertical-subtopic-page',
      },false);
    } else if (this.tools.currentConfig.core.route.type === 'vertical-subtopic-experts') {
      data = {
        ...data,
        ...await this.getExpertsPageData(elements, system),
      }

      this.dataLayerService.push({
        pageTitle: document.title || '',
        topic: this.tools.currentConfig.core.route.parentTopic || '',
        vertical: this.tools.currentConfig.core.route.parentVertical || '',
        subtopic: this.tools.currentConfig.core.route.subTopicType || '',
        contentType: 'vertical-subtopic-experts',
        pageType: 'vertical-subtopic-page',
      },false);
    } else if(this.tools.currentConfig.core.route.type === 'vertical-subtopic-landing'){
      data = {
        ...data,
        ...await this.getLandingPageData(elements, system),
      }
    }

    // seo metadata
    const SEOPayload = {
      metaTitle: elements.seo_metadata_example_to_include_in_any_type__meta_title?.value || elements.title?.value,
      metaDesc: elements.seo_metadata_example_to_include_in_any_type__meta_description?.value,
      ogSiteName: elements.seo_metadata_example_to_include_in_any_type__facebook_site_name?.value,
      ogTitle: elements.seo_metadata_example_to_include_in_any_type__facebook_og_title?.value,
      ogDesc: elements.seo_metadata_example_to_include_in_any_type__facebook_og_description?.value,
      ogImage: elements.seo_metadata_example_to_include_in_any_type__facebook_og_image?.value.length > 0
        ? elements.seo_metadata_example_to_include_in_any_type__facebook_og_image?.value[0].url
        : null,
      twitterSiteName: elements.seo_metadata_example_to_include_in_any_type__twitter_site_name?.value,
      twitterTitle: elements.seo_metadata_example_to_include_in_any_type__twitter_card_title?.value,
      twitterDesc: elements.seo_metadata_example_to_include_in_any_type__twitter_card_description?.value,
      twitterImage: elements.seo_metadata_example_to_include_in_any_type__twitter_card_image?.value.length > 0
        ? elements.seo_metadata_example_to_include_in_any_type__twitter_card_image?.value[0].url
        : null,
      pageType: 'page',
      fallbackImage: elements.hero_image?.value.length > 0
        ? elements.hero_image?.value[0].url
        : null,
      fallbackTitle: elements.title?.value,
    };

    this.seoService.buildToolsSeoData(SEOPayload);

    return data;
  }

  async getLearnPageData(elements, system) {
    const additionalData = {};

    this.completedAssessments = this.tools.customerActivity.basic?.completedAssessments || [];

    // promo top
    if (elements.promo__top_.value.length > 0) {
      const promoTop = this.tools.modular_content[elements.promo__top_.value[0]];
      const promoElements = promoTop.elements;
      const alignment = 'left';
      let cta;

      if (promoElements.cta_text__optional_.value.trim().length > 0) {
        let url = promoElements.cta_manual_external_link__optional_.value;

        if (promoElements.cta_internal_link__optional_.value.length > 0 &&
            this.tools.modular_content[promoElements.cta_internal_link__optional_.value[0]]) {
          const linkedContent = this.tools.modular_content[promoElements.cta_internal_link__optional_.value[0]];

          switch (linkedContent.system.type) {
            case 'ncoa_article_content': {
              const slug = linkedContent.elements.url.value;
              url = `${this.tools.rootURL}/resource/${slug}`;
              break;
            }
            case 'awa_benefits_tool___vertical_subtopic': {
              url = this.tools.routes.find((route) => (
                route.type === 'vertical-subtopic-learn' &&
                route.codename === system.codename
              )).route;
              break;
            }
          }
        }

        cta = {
          label: promoElements.cta_text__optional_.value,
          url,
        };
      }

      additionalData['promoTop'] = {
        alignment,
        heading: promoElements.headline.value,
        intro: promoElements.description.value,
        image: {
          sizes: this.globalService.remodelImageSrc(promoElements.featured_image.value[0].url),
          src: promoElements.featured_image.value[0].url,
          alt: promoElements.featured_image.value[0].description,
        },
        cta,
      };
    }

    // tool card 1-up
    if (elements.tool_card_1_up.value.length > 0) {
      const toolCards = elements.tool_cards.value;

      const toolCard1Up = this.tools.modular_content[elements.tool_card_1_up.value[0]];
      const linkedToolCard = this.tools.modular_content[toolCard1Up.elements.tool_card.value[0]];
      const linkedTool = this.tools.modular_content[linkedToolCard.elements.tool.value[0]];
      let card = null;

      if (linkedTool.elements.assessment_id.value === 'generalAssessment' || linkedTool.elements.assessment_id.value === 'generalAssessmentAWP') {
        card = {
          id: linkedToolCard.system.id,
          linkedID: linkedTool.system.id,
          linkedCodename: linkedTool.system.codename,
          type: 'tool',
          heading: linkedToolCard.elements.headline.value,
          intro: linkedToolCard.elements.description.value,
          cta: {
            label: this.completedAssessments.find((key) => key === linkedTool.elements.assessment_id.value) ? 'View Results' : 'Start Assessment',
            url: this.tools.routes.find((route) => (
              route.assessmentID &&
              route.assessmentID === linkedTool.elements.assessment_id.value
            )).route,
          },
          photo: {
            url: linkedToolCard.elements.card_image.value.length > 0 ? linkedToolCard.elements.card_image.value[0].url : '',
            alt: linkedToolCard.elements.card_image.value.length > 0 ? linkedToolCard.elements.card_image.value[0].description : '',
          },
          full: true,
          variation: 'tools-retirement-full-width',
        };
      } else {
        card = {
          id: linkedToolCard.system.id,
          linkedID: linkedTool.system.id,
          linkedCodename: linkedTool.system.codename,
          type: 'tool',
          heading: linkedToolCard.elements.headline.value,
          intro: linkedToolCard.elements.description.value,
          cta: {
            label: this.completedAssessments.find((key) => key === linkedTool.elements.assessment_id.value) ? 'View Results' : 'Start Assessment',
            url: this.tools.routes.find((route) => (
              route.assessmentID &&
              route.assessmentID === linkedTool.elements.assessment_id.value
            )).route,
          },
          photo: {
            url: linkedToolCard.elements.card_image.value.length > 0 ? linkedToolCard.elements.card_image.value[0].url : '',
            alt: linkedToolCard.elements.card_image.value.length > 0 ? linkedToolCard.elements.card_image.value[0].description : '',
          },
          full: true,
          variation: 'tools-medicare-single-col',
          theme: 'dark',
        };
      }

      let toolCard1UpCta = null;
      if (!this.tools.singularVerticalTopic) {
        toolCard1UpCta = toolCards.length > 1 ? {
          label: 'View More Tools',
          url: this.tools.routes.find((route) => (
            route.codename && route.type &&
            route.codename === this.tools.currentConfig.core.route.codename &&
            route.type === 'vertical-subtopic-tools'
          ))?.route || null,
        } : null;
      }

      additionalData['toolCard1up'] = {
        type: 'tool',
        heading: toolCard1Up.elements.headline.value,
        cta: toolCard1UpCta,
        card,
      };
    }

    // article card grouping
    const taxonomy = elements[this.tools.toolTaxonomyCodename].value[0];
    if (taxonomy) {
      const articles = [];
      const params = {
        'system.type': 'ncoa_article_content',
        'elements': 'awa_how_to_s_designation,body_ckeditor,title,url',
        'elements.awp_trusted_expert[empty]': true,
        'limit': 500,
      };
      const _prop = `elements.${this.tools.toolTaxonomyCodename}[contains]`;

      params[_prop] = taxonomy.codename;

      const initialRequest = await this.kontent.getItem(null, params);

      articles.push(...initialRequest.items);

      if (initialRequest.pagination && initialRequest.pagination.next_page) {
        let hasNextPage = true;

        while (hasNextPage) {
          const params = {
            'system.type': 'ncoa_article_content',
            'elements': 'awa_how_to_s_designation,body_ckeditor,title,url',
            'elements.awp_trusted_expert[empty]': true,
            'limit': 500,
            'skip': articles.length,
          };
          const _prop = `'elements.${this.tools.toolTaxonomyCodename}[contains]`;

          params[_prop] = taxonomy.codename;

          const nextRequest = await this.kontent.getItem(null, params);

          articles.push(...nextRequest.items);

          if (nextRequest.pagination && nextRequest.pagination.next_page) {
            hasNextPage = true;
          } else {
            hasNextPage = false;
          }
        }
      }

      let howToArticles = articles.filter((article) => article.elements.awa_how_to_s_designation.value.length > 0);
      let otherArticles = articles.filter((article) => article.elements.awa_how_to_s_designation.value.length === 0);

      const remodelArticle = (article) => {
        const div = document.createElement('div');
              div.innerHTML = article.elements.body_ckeditor.value;
        const text = div.innerText;

        return {
          id: article.system.id,
          type: 'article',
          heading: article.elements.title.value,
          intro: `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`,
          cta: {
            url: `${this.tools.rootURL}/resource/${article.elements.url.value}`,
            label: 'Read Resource',
          },
          breadcrumb: this.parentVerticalName ? `${this.parentVerticalName} / ${this.parentTopicName}` : 'Resource',
        };
      }

      howToArticles = howToArticles.map((article) => remodelArticle(article));
      otherArticles = otherArticles.map((article) => remodelArticle(article));

      additionalData['articleCardGrouping'] = [];

      if (howToArticles.length > 0) {
        additionalData['articleCardGrouping'].push({
          heading: 'How To\'s',
          cards: howToArticles,
        });
      }

      if (otherArticles.length > 0) {
        additionalData['articleCardGrouping'].push({
          heading: 'Helpful Tips & Guidance',
          cards: otherArticles,
        });
      }
    }

    // benefit card 3-up
    if (!this.tools.singularVerticalTopic) {
      const benefitCategories = elements.benefits_category.value;
      const benefitPage = this.tools.routes.find((route) => (
        route.type === 'vertical-benefits' &&
        route.codename === this.tools.currentConfig.core.route.parentVertical
      ));
      if (benefitCategories.length > 0) {
        let state = null;
        let requests = [];

        const userZipCode = this.tools.customerActivity.household?.zipCode;
        const zipCodeData: any = await this.utility.getZipCode(userZipCode);

        if (userZipCode && zipCodeData && zipCodeData.county) {
          state = stateOptions.find(({ text }) => text.toLowerCase() === zipCodeData.county.toLowerCase());

          if (state) {
            state = state.value;

            let categories = benefitCategories.map((category) => category.codename.replace('category_', ''));

            if (categories.find(category => category === '161')) {
              categories = ['161'];
            }

            requests = categories.map(category => {
              return this.benefitsService.getBenefits(state, category);
            });
          }
        } else {
          let categories = benefitCategories.map((category) => category.codename.replace('category_', ''));

          if (categories.find(category => category === '161')) {
            categories = ['161'];
          }

          stateOptions.forEach(({ value: state }) => {
            categories.forEach((category) => {
              requests.push(this.benefitsService.getBenefits(state, category));
            });
          });
        }

        if (requests.length > 0) {
          const processRawData = (arr) => {
            const cards = [];

            if ((arr || []).length > 0) {
              (arr || []).forEach((data) => {
                let body = '';

                data.post_content.forEach((content) => {
                  if (content.header && content.header.trim().length > 0) {
                    body += `<h4>${content.header}</h4>`;
                  }

                  if (content.body && content.body.trim().length > 0) {
                    body += `<p>${content.body}</p>`;
                  }
                });

                Object.keys(data['faqs-list']).forEach((key) => {
                  if (data['faqs-list'][key].question &&
                      (!data['faqs-list'][key].question.toLowerCase().match(/who should i contact/g) &&
                      !data['faqs-list'][key].question.toLowerCase().match(/what will i need/g))) {
                    if (data['faqs-list'][key].question && data['faqs-list'][key].question.trim().length > 0) {
                      body += `<h4>${data['faqs-list'][key].question}</h4>`;
                    }

                    if (data['faqs-list'][key].answer && data['faqs-list'][key].answer.trim().length > 0) {
                      body += `<p>${data['faqs-list'][key].answer}</p>`;
                    }
                  }
                });

                const div = document.createElement('div');
                      div.innerHTML = data.program_short_summary;
                const text = div.textContent;

                const card = {
                  type: 'benefit',
                  heading: data.post_title,
                  breadcrumb: data['fact-sheet-category'],
                  intro: text ? `${text.substr(0, 121)}...` : '',
                  full_desc: body,
                  program_cta: {
                    url: data['program-url'],
                    text: data['program-title'],
                  },
                };

                cards.push(card);
              });
            }

            return cards;
          };

          await Promise.all(requests).then((res: any) => {
            if (res) {
              const raw = res.flat();

              const cards = processRawData(raw);
              const selectedCards = [];

              while (selectedCards.length !== 3) {
                const index = Math.round(Math.random() * (cards.length - 1));
                const targetCard = cards[index];
                const identifier = targetCard.program_cta.url;

                const alreadyInCards = selectedCards.find((card) => card.program_cta.url === identifier);

                if (!alreadyInCards) {
                  selectedCards.push(targetCard);
                }
              }

              additionalData['benefitCard3Up'] = {
                heading: elements.benefits_card_3_up_headline.value,
                moreLink: !this.tools.singularVerticalTopic ? {
                  label: 'Find More Benefits',
                  url: benefitPage.route,
                } : {
                  label: 'Find More Benefits',
                  url: this.tools.routes.find((route) => route.type === 'all-benefits').route,
                },
                cards: selectedCards,
              };
            }
          });
        }
      }
    }

    // lead gen
    if (this.tools.singularVerticalTopic && elements.lead_gen.value.length > 0) {
      let isLeadGenAssessment = false;
      const leadGenKey = elements.lead_gen.value[0];
      let leadGen = this.tools.modular_content[leadGenKey];

      if (leadGen) {
        let cta;

        if (leadGen.elements.assessment_id.value && leadGen.elements.cta_text.value) {
          isLeadGenAssessment = true;

          cta = {
            label: leadGen.elements.cta_text.value,
            url: `${this.tools.rootURL}/lead-gen?assessment=${leadGen.elements.assessment_id.value}&broker=${leadGen.elements.broker_type.value[0].codename}&url=${window.location.href}`,
          };
        }

        if (leadGen.elements.external_manual_cta_link.value && leadGen.elements.cta_text.value) {
          cta = {
            label: leadGen.elements.cta_text.value,
            url: leadGen.elements.external_manual_cta_link.value,
          };
        }

        if (leadGen.elements.internal_cta_link.value.length > 0 && leadGen.elements.cta_text.value) {
          const articleKey = leadGen.elements.internal_cta_link.value[0];
          const article = this.tools.modular_content[articleKey];

          if (article) {
            cta = {
              label: leadGen.elements.cta_text.value,
              url: `${this.tools.rootURL}/resource/${article.elements.url.value}`,
            };
          }
        }

        let icon;

        if (leadGen.elements.branding_image.value.length > 0) {
          icon = {
            src: leadGen.elements.branding_image.value[0].url,
            alt: leadGen.elements.branding_image.value[0].description
          };
        }

        const rawURL = new URL(window.location.href);
        let isLeadSent = rawURL.searchParams.get('lead') && rawURL.searchParams.get('lead') === 'sent';

        if (isLeadGenAssessment) {
          const assessmentID = leadGen.elements.assessment_id.value;
          const completedAssessments = this.tools.customerActivity?.basic?.completedAssessments || [];

          const flag = completedAssessments.find((id) => id === assessmentID);
          isLeadSent = !!flag;
        }

        additionalData['leadGen'] = {
          eyebrow: leadGen.elements.eyebrow.value,
          heading: leadGen.elements.headline.value,
          intro: leadGen.elements.description.value,
          cta,
          photo: {
            src: leadGen.elements.left_image.value[0].url,
            alt: leadGen.elements.left_image.value[0].description,
          },
          icon,
        };
      }
    }

    return additionalData;
  }

  async getToolsPageData(elements) {
    const additionalData = {};

    // tool card(s)
    const toolCards = elements.tool_cards.value;
    if (toolCards.length > 0) {
      const toolCardHeadline = elements.tool_cards_headline.value;

      const cards = [];
      toolCards.forEach((key) => {
        if (this.tools.modular_content[key]) {
          const toolCard = this.tools.modular_content[key];
          const linkedTool = this.tools.modular_content[toolCard.elements.tool.value[0]];

          cards.push({
            id: toolCard.system.id,
            linkedID: linkedTool.system.id,
            linkedCodename: linkedTool.system.codename,
            type: 'tool',
            heading: toolCard.elements.headline.value,
            intro: toolCard.elements.description.value,
            cta: {
              label: this.completedAssessments.find((key) => key === linkedTool.elements.assessment_id.value) ? 'View Results' : 'Start Assessment',
              url: this.tools.routes.find((route) => (
                route.assessmentID &&
                route.assessmentID === linkedTool.elements.assessment_id.value
              )).route,
            },
            photo: {
              url: toolCard.elements.card_image.value.length > 0 ? toolCard.elements.card_image.value[0].url : '',
              alt: toolCard.elements.card_image.value.length > 0 ? toolCard.elements.card_image.value[0].description : ''
            },
            full: true,
            variation: linkedTool.elements.assessment_id.value !== 'generalAssessment' || linkedTool.elements.assessment_id.value !== 'generalAssessmentAWP' ? 'tools-medicare-single-col' : 'tools-retirement-full-width',
            theme: linkedTool.elements.assessment_id.value !== 'generalAssessment' || linkedTool.elements.assessment_id.value !== 'generalAssessmentAWP' ? 'dark' : null,
          });
        }
      });

      additionalData['toolCards'] = {
        heading: toolCardHeadline,
        cards,
      };
    }

    // resource 3-up
    const taxonomy = elements[this.tools.toolTaxonomyCodename].value[0].codename;
    const params = {
      'system.type': 'ncoa_article_content',
      'elements': 'title,body_ckeditor,url',
    };
    const _prop = `elements.${this.tools.toolTaxonomyCodename}[contains]`;

    params[_prop] = taxonomy;

    let resources = await this.kontent.getItem(null, params);

    if (resources && resources.items) {
      resources = resources.items.map((rawResource) => {
        const div = document.createElement('div');
              div.innerHTML = rawResource.elements.body_ckeditor ? rawResource.elements.body_ckeditor.value : '';
        const text = div.innerText;

        return {
          id: rawResource.system.id,
          type: 'article',
          heading: rawResource.elements.title.value,
          intro: `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`,
          cta: {
            url: `${this.tools.rootURL}/resource/${rawResource.elements.url.value}`,
            label: 'Read Resource',
          },
          breadcrumb: elements[this.tools.toolTaxonomyCodename].value[0].name,
        };
      });
    }

    resources = resources.slice(0, 3);

    const learnRoute = this.tools.routes
      .filter((route) => route.codename && route.codename === this.tools.currentConfig.core.route.codename)
      .find((route) => route.type && route.type === 'vertical-subtopic-learn');

    const moreLink = {
      label: 'View More',
      url: learnRoute.route,
    };

    additionalData['ncoaThreeUp'] = {
      heading: elements.related_resource_heading.value,
      cards: resources,
      moreLink,
    };

    return additionalData;
  }

  async getLandingPageData(elements, system){
    const additionalData = {};

    let toolCodeName = elements.associated_tool.value[0];
    let toolData = this.tools.modular_content[toolCodeName];
    additionalData['assessment'] = {
      assessmentCode:toolData.elements.screening_code.value,
      bannerTextObject: toolData.elements?.sign_up_banner_text, //pass along entire rich text object to be processed later
      // bannerCTA: toolData.elements?.banner_cta?.value,
      logoutCTA: toolData.elements?.log_out?.value,
      viewPlanText: toolData.elements.view_plan_text.value,
      viewPlanCTA: toolData.elements.view_plan_cta.value,
      viewAssessmentText: toolData.elements.view_assessment_text.value,
      viewAssessmentCTA: toolData.elements.view_assessment_cta.value,
      viewPlanLink: this.tools.routes.find((route) => (
        route.type === 'vertical-subtopic-plan' && route.parentVertical === system.codename
      ))?.route,
    }

    return additionalData;
  }

  async getExpertsPageData(elements, system) {
    const additionalData = {};

    // promo top
    if (elements.promo.value.length > 0) {
      const promoTop = this.tools.modular_content[elements.promo.value[0]];
      const promoElements = promoTop.elements;
      const alignment = 'right';
      let cta;

      if (promoElements.cta_text__optional_.value.trim().length > 0) {
        let url = promoElements.cta_manual_external_link__optional_.value;

        if (promoElements.cta_internal_link__optional_.value.length > 0 &&
            this.tools.modular_content[promoElements.cta_internal_link__optional_.value[0]]) {
          const linkedContent = this.tools.modular_content[promoElements.cta_internal_link__optional_.value[0]];

          switch (linkedContent.system.type) {
            case 'ncoa_article_content': {
              const slug = linkedContent.elements.url.value;
              url = `${this.tools.rootURL}/resource/${slug}`;
              break;
            }
            case 'awa_benefits_tool___vertical_subtopic': {
              url = this.tools.routes.find((route) => (
                route.type === 'vertical-subtopic-experts' &&
                route.codename === system.codename
              )).route;
              break;
            }
          }
        }

        cta = {
          label: promoElements.cta_text__optional_.value,
          url,
        };
      }

      additionalData['promoTop'] = {
        alignment,
        heading: promoElements.headline.value,
        intro: promoElements.description.value,
        image: {
          sizes: this.globalService.remodelImageSrc(promoElements.featured_image.value[0].url),
          src: promoElements.featured_image.value[0].url,
          alt: promoElements.featured_image.value[0].description,
        },
        cta,
      };
    }

    // experts listing
    additionalData['expertListingHeadline'] = elements.experts_listing_headline.value || '';

    // tool card 1-up
    if (elements.tool_card_1_up_experts.value.length > 0) {
      const toolCards = elements.tool_cards.value;

      const toolCard1Up = this.tools.modular_content[elements.tool_card_1_up_experts.value[0]];
      const linkedToolCard = this.tools.modular_content[toolCard1Up.elements.tool_card.value[0]];
      const linkedTool = this.tools.modular_content[linkedToolCard.elements.tool.value[0]];

      const card = {
        id: linkedToolCard.system.id,
        linkedID: linkedTool.system.id,
        linkedCodename: linkedTool.system.codename,
        type: 'tool',
        heading: linkedToolCard.elements.headline.value,
        intro: linkedToolCard.elements.description.value,
        cta: {
          label: this.completedAssessments.find((key) => key === linkedTool.elements.assessment_id.value) ? 'View Result' : 'Start Assessment',
          url: this.tools.routes.find((route) => (
            route.assessmentID &&
            route.assessmentID === linkedTool.elements.assessment_id.value
          )).route,
        },
        photo: {
          url: linkedToolCard.elements.card_image.value.length > 0 ? linkedToolCard.elements.card_image.value[0].url : '',
          alt: linkedToolCard.elements.card_image.value.length > 0 ? linkedToolCard.elements.card_image.value[0].description : '',
        },
        full: true,
        variation: linkedTool.elements.assessment_id.value !== 'generalAssessment' || linkedTool.elements.assessment_id.value !== 'generalAssessmentAWP' ? 'tools-medicare-single-col' : 'tools-retirement-full-width',
        theme: linkedTool.elements.assessment_id.value !== 'generalAssessment' || linkedTool.elements.assessment_id.value !== 'generalAssessmentAWP' ? 'dark' : null,
      };

      additionalData['toolCard1up'] = {
        type: 'tool',
        heading: toolCard1Up.elements.headline.value,
        cta: toolCards.length > 1 ? {
          label: 'View More Tools',
          url: this.tools.routes.find((route) => (
            route.codename && route.type &&
            route.codename === this.tools.currentConfig.core.route.codename &&
            route.type === 'vertical-subtopic-tools'
          )).route,
        } : null,
        card,
      };
    }

    return additionalData;
  }
}
