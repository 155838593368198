<div
  [ngClass]="( full == true ) ? 'card card--full card--' + type + ' '+ variation:'card card--' + type + ' '+ variation"
  [attr.data-theme]="theme ? theme : 'default'"
  [attr.linked-id]="linkedID">

  <div *ngIf="background" class="card--background">
    <img src="{{ background.url }}" alt="{{ background.alt }}">
  </div>

  <div class="card--wrapper">
    <div class="card--header">
      <h3 class="card--heading"
        *ngIf="type != 'promo' && type != 'referral' && type != 'tool' && type != 'subtopic' && !titleAriaLabel"
      >
        {{ heading }}
      </h3>
      <h3 class="card--heading"
        *ngIf="type != 'promo' && type != 'referral' && type != 'tool' && type != 'subtopic' && titleAriaLabel"
        [attr.aria-label]="titleAriaLabel"
      >
        {{ heading }}
      </h3>
      <p class="card--eyebrow" *ngIf="eyebrow">{{ eyebrow }}</p>

      <div class="card--breadcrumb" *ngIf="breadcrumb">
        {{ breadcrumb }}
      </div>
    </div>

    <div class="card--body">
      <div class="card--photo" *ngIf="photo">
        <img src="{{ photo.url }}" alt="{{ photo.alt }}">
      </div>
      <h3 class="card--heading" *ngIf="type == 'promo' || type == 'referral' || type == 'tool' || type == 'subtopic'">{{ heading }}</h3>
      <p *ngIf="intro">{{ intro }}</p>
    </div>

    <div class="card--cta" *ngIf="type !== 'benefit' && type !== 'promo'">
      <a href="{{ cta.url }}">
        {{ cta.label }}
        <span>
          <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M13.2117 1.04771L19.7643 7.75111C19.9748 7.96562 20.0801 8.23375 20.0801 8.50189C20.0801 8.77003 19.9748 9.03816 19.7643 9.25267L13.2117 15.9561C12.7906 16.3851 12.1327 16.3851 11.7117 15.9561C11.2906 15.5271 11.2906 14.8567 11.7117 14.4277L16.5011 9.57443L1.13271 9.57443C0.553762 9.57443 0.0800781 9.09179 0.0800781 8.50189C0.0800781 7.91199 0.553762 7.42935 1.13271 7.42935L16.5011 7.42935L11.738 2.54927C11.3169 2.12025 11.3169 1.44991 11.738 1.02063C12.1327 0.618424 12.7906 0.618424 13.2117 1.04771Z"
              fill="#0B4A5D" />
          </svg>
        </span>
      </a>
    </div>
    <div class="card--cta" *ngIf="type === 'promo'">
      <a href="{{ cta.url }}">
        <span class = 'cta-label'>{{ cta.label }}</span>
        <span class = 'cta-arrow-icon'>
          <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M13.2117 1.04771L19.7643 7.75111C19.9748 7.96562 20.0801 8.23375 20.0801 8.50189C20.0801 8.77003 19.9748 9.03816 19.7643 9.25267L13.2117 15.9561C12.7906 16.3851 12.1327 16.3851 11.7117 15.9561C11.2906 15.5271 11.2906 14.8567 11.7117 14.4277L16.5011 9.57443L1.13271 9.57443C0.553762 9.57443 0.0800781 9.09179 0.0800781 8.50189C0.0800781 7.91199 0.553762 7.42935 1.13271 7.42935L16.5011 7.42935L11.738 2.54927C11.3169 2.12025 11.3169 1.44991 11.738 1.02063C12.1327 0.618424 12.7906 0.618424 13.2117 1.04771Z"
              fill="#0B4A5D" />
          </svg>
        </span>
      </a>
    </div>
    <div class="card--cta" *ngIf="type === 'benefit'">
      <button type="button" (click)="showBenefitModal()">
        View Program Details
        <span>
          <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M13.2117 1.04771L19.7643 7.75111C19.9748 7.96562 20.0801 8.23375 20.0801 8.50189C20.0801 8.77003 19.9748 9.03816 19.7643 9.25267L13.2117 15.9561C12.7906 16.3851 12.1327 16.3851 11.7117 15.9561C11.2906 15.5271 11.2906 14.8567 11.7117 14.4277L16.5011 9.57443L1.13271 9.57443C0.553762 9.57443 0.0800781 9.09179 0.0800781 8.50189C0.0800781 7.91199 0.553762 7.42935 1.13271 7.42935L16.5011 7.42935L11.738 2.54927C11.3169 2.12025 11.3169 1.44991 11.738 1.02063C12.1327 0.618424 12.7906 0.618424 13.2117 1.04771Z"
              fill="#0B4A5D" />
          </svg>
        </span>
      </button>
    </div>

    <div class="card--bookmark" *ngIf="(type != 'promo' || bookmark) && id">
      <button type="button" (click)="clickSave()">
        <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 2.23226V23.4647C1 24.4929 2.18505 25.0689 2.99357 24.4336L11 18.1429L19.0064 24.4336C19.8149 25.0689 21 24.4929 21 23.4647V2.23226C21 1.5517 20.4483 1 19.7677 1H2.23226C1.5517 1 1 1.5517 1 2.23226Z"
            stroke="white" [attr.fill]="isSaved ? '#ffffff' : 'none'" stroke-width="2" />
        </svg>
        <span class="save--text">
          {{ isSaved ? 'Saved' : 'Save' }}
        </span>
      </button>
    </div>
  </div>
</div>
