<ncoa-global-nav></ncoa-global-nav>

<main class="main" id="main" tabindex="-1">
  <ng-content></ng-content>
</main>

<ncoa-global-footer></ncoa-global-footer>
<ncoa-cookies></ncoa-cookies>

<ncoa-modal-container></ncoa-modal-container>
<!-- <ncoa-modal *ngIf="!hasDisplayOnce" [id]="'general_modal'" [type]="'general'" [isOpen]="open" [heading]="heading"
  	  [description]="description" [cta]="cta" [backgroundImage]="backgroundImage">
	</ncoa-modal>
-->

