<div class="topic-listing">
  <h3 class="topic-listing__heading">{{ heading }}</h3>
  <ul class="topic-listing__main">
    <li class="topic" [ngClass] = '{"topic-last-item": i === topics.length - 1}' *ngFor="let item of topics; let i = index;">
      <div class="topic__wrapper">
        <section class="main">
          <div class="eyebrow">{{ item.eyebrow }}</div>
          <h4 class="title">{{ item.title }}</h4>
          <p class="desc">{{ item.desc }}</p>
          <a [href]="item.cta.url" [title]="item.cta.text" class="cta">
            <div><span>{{ item.cta.text }}</span></div>
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1316 0.545757L19.6842 7.24916C19.8947 7.46366 20 7.7318 20 7.99994C20 8.26807 19.8947 8.53621 19.6842 8.75072L13.1316 15.4541C12.7105 15.8831 12.0526 15.8831 11.6316 15.4541C11.2105 15.0251 11.2105 14.3548 11.6316 13.9257L16.4211 9.07248L1.05263 9.07248C0.473684 9.07248 0 8.58984 0 7.99994C0 7.41004 0.473684 6.92739 1.05263 6.92739L16.4211 6.92739L11.6579 2.04732C11.2368 1.6183 11.2368 0.947961 11.6579 0.518675C12.0526 0.116471 12.7105 0.116471 13.1316 0.545757Z" fill="#0B4A5D"/>
            </svg>
          </a>
        </section>
        <section class="image">
          <div class="media">
            <img [src]="item.image.src" [alt]="item.image.alt" />
          </div>
        </section>
        <button type="button" class="save-btn" (click)="clickSave(i)">
          <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 2.23226V23.4647C1 24.4929 2.18505 25.0689 2.99357 24.4336L11 18.1429L19.0064 24.4336C19.8149 25.0689 21 24.4929 21 23.4647V2.23226C21 1.5517 20.4483 1 19.7677 1H2.23226C1.5517 1 1 1.5517 1 2.23226Z" stroke="#0B4A5D" [attr.fill]="item.isSaved ? '#0B4A5D' : 'none'" stroke-width="2"/>
          </svg>
          {{ item.isSaved ? 'Saved' : 'Save' }}
        </button>
      </div>
    </li>
  </ul>
</div>
