import { AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Author } from '@components/author-byline/author-byline.types';
import { TakeawaysComponent } from '@components/takeaways/takeaways.component';

@Component({
  selector: 'ncoa-trusted-expert-page',
  templateUrl: './trusted-expert-page.component.html',
  styleUrls: ['./trusted-expert-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TrustedExpertPageComponent implements OnInit, AfterViewInit {

  @Input() takeAwaysComponent: any;
  @Input() articleBody: any;
  @Input() partnersComponent?: any;
  @Input() threeUpComponent: any;
  @Input() otherContributors?: any;
  @Input() feedback?: any;
  @Input() leadgen?: any;
  @Input() wasThisHelpful?: any;
  @Input() authorByline?: any;
  @Input() articleHeaderComponent?: any;
  @ViewChild('saveBookmarkDesktop') saveBookmarkDesktopE: ElementRef;
  @ViewChild('saveBookmarkMobile') saveBookmarkMobileE: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkInView2();
    }, 20);
  }



  checkInView2() {
    const articleParentBody = document.querySelector('.article-body') as HTMLElement;
    const articlePreContentBody = document.querySelector('.article-body__pre-content') as HTMLElement;
    const componentTakeAway = document.querySelector('.component--takeaways') as HTMLElement;
    const articleBody = document.querySelector('.article-body-page-container') as HTMLElement;

    if (!articleBody) {
      return;
    }

    articlePreContentBody.style.width = 'auto';
    articlePreContentBody.style.marginLeft = '0px';
    if (componentTakeAway)
      componentTakeAway.style.paddingLeft = '0px';


    const articleShare = document.querySelector('.article--share') as HTMLElement;
    const articleShareMobile = document.querySelector('.article--share-mobile') as HTMLElement;

    if (articleShare) {
      //Desktop
      articleShare.classList.add('share-container-planner');
      articleShare.querySelector('.wrapper').prepend(this.getSaveBookmarkMarkup());

      // mobile
      articleShareMobile.classList.add('share-container-planner-mobile');
      const savebookmarkMobile = this.getSaveBookmarkMarkupMobile().childNodes[0];
      articleShareMobile.querySelector('.wrapper').prepend(savebookmarkMobile.firstChild);
      articleShareMobile.querySelector('.wrapper').prepend(savebookmarkMobile.lastChild);
    }

    window.addEventListener('scroll', () => {

      if (articleShare) {
        const articleBodyBottom = articleBody.getBoundingClientRect().bottom;
        const articleParentBodyBottom = articleParentBody.getBoundingClientRect().bottom;
        const articleShareBottom = articleShare.getBoundingClientRect().bottom + (articleBodyBottom - articleParentBodyBottom);

        if (this.isInView(articleShare, window.innerHeight)) {
          articleShare.classList.add('in-view');
        }

        if (this.isInView(articleBody, 0 && articleBodyBottom > window.innerHeight / 2)) {
          articleShare.classList.add('is-sticky');
          if (articleBodyBottom > articleShareBottom) {
            articleShare.classList.remove('is-absolute');
            articleShare.style.bottom = 'auto';
            articleParentBody.style.overflow = 'hidden';

          } else if (articleBodyBottom > window.innerHeight) {
            articleShare.classList.remove('is-absolute');
            articleShare.style.bottom = 'auto';
            articleParentBody.style.overflow = 'hidden';

          } else {
            articleShare.classList.remove('is-sticky');
            articleShare.classList.add('is-absolute');

            articleShare.style.bottom = -(((articleBodyBottom - articleParentBodyBottom) - articleShare.offsetHeight) + 170) + 'px';
            articleParentBody.style.overflow = 'inherit';
          }

        } else {
          articleShare.classList.remove('is-sticky');
          articleShare.classList.remove('is-absolute');

        }
      }
    });
  }

  isInView(_element, _offset) {
    let _componentPosition = _element.getBoundingClientRect();

    if (_componentPosition.top <= _offset) {
      return true;
    } else {
      return false;
    }
  }

  getSaveBookmarkMarkup() {
    return this.saveBookmarkDesktopE.nativeElement;
  }

  getSaveBookmarkMarkupMobile() {
    return this.saveBookmarkMobileE.nativeElement;
  }

  onSaveContentClick() {

  }

}
