
<section class="componet thank-you">

  <div *ngIf = "brokerName === 'mcg_broker' && broker" class="thank-you__cta">
    <a class="cta-btn" [href]="data?.cta_button_link" target="_blank">
      {{ data?.cta_button_text}}
    </a>
  </div>

  <div class="thank-you__container">
    <div *ngIf = "broker" class="thank-you__wrapper">
      <div class="thank-you__info" [innerHTML]="content"></div>
      <div class="thank-you__image">
        <img class="thank-you__image--soe" [src]="soe_logo?.url" [alt]="soe_logo?.alt">
        <img class="thank-you__image--partner" [src]="partner_logo?.url" [alt]="partner_logo?.alt">
      </div>
    </div>
    <div *ngIf = "!broker" class="thank-you__wrapper">
      <div class="thank-you__info" [innerHTML]="content">
      </div>
    </div>
  </div>
  
</section>