import { Component, OnInit, Input } from '@angular/core';

import { ModalService } from '@services/modal.service';

@Component({
  selector: 'ncoa-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss']
})
export class ModalContainerComponent {

  modalData: any = null;

  constructor(private modalService: ModalService) {
    const modalData = this.modalService.getCurrentModalData();

    // Listen to modal changes
    this.modalService.subscribe(this.onModalChange.bind(this));
  }

  onModalChange(modalData) {
    this.modalData = modalData;
  }

}
