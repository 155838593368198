<div class="leadership-grid">
  <div class="leadership-grid__container">
    <h2 class="leadership-grid__heading">{{ heading }}</h2>
    <div class="leadership-grid__items">
      <div class="leadership-grid__item" *ngFor="let member of members">
        <div class="leadership-grid__image">
          <img src="{{ member.image }}" [attr.alt]="'Member ' + member.name" class="leadership-grid__img">
        </div>
        <div class="leadership-grid__content">
          <a href="{{ member.url }}">{{ member.name }}</a>
          <div class="leadership-grid__description" [innerHTML]="member.description"></div>
        </div>
      </div>
    </div>
  </div>
</div>
