<footer class="global-footer-planner">
  <div class="logo-container">
    <img [src]="footerData.logo.src" [alt]="footerData.logo.alt" class="img-logo" />
  </div>
  <nav *ngIf="footerData.mainNavigation.length > 0" class="main-navigation" role='navigation'>
    <a 
      [ngClass]="{'main-navigation__link': true, 'noneUrlLink': nav.url === '' || nav.url === null || nav.url === undefined}" 
      [href] = 'nav.url'
      [title]="nav.label" 
      *ngFor="let nav of footerData.mainNavigation" 
      (click)="trackEvent( $event )">{{ nav.label }}</a>
  </nav>
  <nav *ngIf="footerData.subNavigation.length > 0" class="sub-navigation" role='navigation' title="Sub Navigation">
    <div class="sub-navigation__container" *ngFor="let nav of footerData.subNavigation">
      <a 
        *ngIf="nav.url" 
        class="sub-navigation__link" 
        [href]="nav.url" 
        [title]="nav.label" 
        (click)="trackEvent( $event )">{{ nav.label }}</a>
      <span *ngIf="!nav.url" class="sub-navigation__link">{{ nav.label }}</span>
    </div>
  </nav>
  <div class="social-link-container" *ngIf="footerData.socialNavigation.length > 0">
    <p class="social-link-container--title">{{ footerData.socialHeader }}</p>
    <div class="social-links">
      <a class="social-links--link" 
        [href]="nav.url" 
        [title]="nav.label" 
        [attr.aria-label] = 'nav.label' 
        [attr.data-social] = 'nav.label.replace("Visit us on ", "")'
        target="_blank" 
        *ngFor="let nav of footerData.socialNavigation"  
        (click)="trackSocial( $event )">
        <i [class]='nav.iconClass' aria-hidden="true"></i>
      </a>
    </div>

    <div class="footnote" [innerHTML]="footerData.copyright">&copy;</div>
  </div>
</footer>
