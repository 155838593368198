<div class="question-label">

    <p class="optional" *ngIf="!question.required">{{kenticoKontent.optional}}</p>
  
    <label [for]="question.key">
        <ng-container *ngFor="let content of labelContent">
            <ng-container [ngSwitch]="content.type">
                <span *ngSwitchCase="'text'" [innerHTML]="content.text"></span>
                <span *ngSwitchCase="'definition'" class="definition" [innerHTML]="content.text" [tooltip]="content.definition"></span>
            </ng-container>
        </ng-container>
        <span *ngIf="question.required"> *</span>
        
        <!-- <svg (click)="showHint = !showHint" *ngIf="question.hint" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="9" stroke="#0B4A5D" stroke-width="2"/>
            <path d="M10.8491 12.1873H8.97768C9.01578 11.5986 9.19197 11.1174 9.50626 10.7436C9.83007 10.3699 10.1634 10.0382 10.5063 9.74849C10.8301 9.46817 11.111 9.18784 11.3491 8.90752C11.5967 8.61785 11.7205 8.27212 11.7205 7.87032C11.7205 7.44049 11.5777 7.10411 11.292 6.86116C11.0063 6.60887 10.6205 6.48272 10.1348 6.48272C9.47768 6.48272 8.97768 6.71165 8.63483 7.16952C8.29197 7.62738 8.15387 8.24876 8.22054 9.03367L6.40625 8.12262C6.41577 7.63672 6.51577 7.19288 6.70625 6.79108C6.89673 6.37994 7.15387 6.02953 7.47768 5.73987C7.81101 5.44085 8.20149 5.21192 8.64911 5.05307C9.10625 4.88488 9.60149 4.80078 10.1348 4.80078C10.6396 4.80078 11.1063 4.87553 11.5348 5.02504C11.9634 5.1652 12.3301 5.3661 12.6348 5.62774C12.9396 5.88937 13.1777 6.20707 13.3491 6.58084C13.5205 6.9546 13.6063 7.36107 13.6063 7.80024C13.6063 8.1927 13.5539 8.5431 13.4491 8.85146C13.3444 9.15981 13.2063 9.43546 13.0348 9.67841C12.8634 9.91201 12.6729 10.1269 12.4634 10.3232C12.2634 10.5194 12.0634 10.7016 11.8634 10.8698C11.6063 11.0847 11.3777 11.2949 11.1777 11.5005C10.9872 11.7061 10.8777 11.935 10.8491 12.1873ZM11.0205 15.2008H8.77768V13.1124H11.0205V15.2008Z" fill="#0B4A5D"/>
          </svg> -->
    </label>
  
    <div *ngIf="question.hint" [innerHTML]="question.hint['en']" class="help-text"></div>
  
  </div>
  