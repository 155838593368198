<div class="partners-grid">
  <div class="partners-grid__container partners-grid--{{ type }}">
    <h2 class="partners-grid__heading">{{ heading }}</h2>
    <div class="partners-grid__items">
      <div class="partners-grid__item" *ngFor="let partner of partners">
        <div class="partners-grid__image">
          <a href="{{ partner.url }}">
            <img src="{{ partner.image }}" [attr.alt]="'Partner ' + partner.name" class="partners-grid__img">
          </a>
        </div>
        <div class="partners-grid__content">
          <a href="{{ partner.url }}">{{ partner.name }}</a>
          <div class="partners-grid__description">
            {{ partner.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
