import { Component, Input } from '@angular/core';

@Component({
  selector: 'ncoa-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.scss']
})
export class PartnershipComponent {
  @Input() container?: 'hero'|'default';
  @Input() eyebrow: string;
  @Input() url?: string;
  @Input() src: string;
  @Input() alt: string;
}
