import { Component, OnInit, Input, ViewEncapsulation, ElementRef} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LandingPageService } from '@services/landing-page.service';
import { Logo } from '@components/global-nav/global-nav.types';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThankYouComponent implements OnInit {

  data: any;
  content: string;
  partner_logo: Logo;
  soe_logo: Logo;
  broker: boolean;
  brokerName: string;
  withoutBroker: boolean = false;

  constructor( 
    private landingService: LandingPageService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef
    ) { }

  ngOnInit(): void {
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.content) as string;

    if(localStorage.getItem('broker') === "true"){
      this.broker = true
      this.brokerName = localStorage.getItem('broker_name');
      this.landingService.getBrokerData(localStorage.getItem('broker_name')).then(data => {
        this.mapKontent(data);
      });
    } else if(localStorage.getItem('broker') === "false") {
      this.broker = false;
      this.landingService.getShipData().then(data => {
        this.mapKontent(data);
      });
    } else  if(localStorage.getItem('broker') === "withoutBroker"){
      this.broker = true;
      this.landingService.getBrokerData(localStorage.getItem('landing_page')).then(data => {
        this.mapKontent(data);
      });
    }
    
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkContent();
      this.findFirstParagraph();
      this.removeEmptyTags();
    }, 0);
  }

  mapKontent(data){

    this.data = data;
    this.content = data.partner_body
    this.partner_logo = data.partner_logo
    this.soe_logo = data.standards_of_excellence_logo
    
  }

  findFirstParagraph(): void {
    if (document.querySelector('.thank-you__wrapper')) {

      const bodyContainer = document.querySelector('.thank-you__inner-info');

      if (bodyContainer && bodyContainer.firstChild && (bodyContainer.firstChild.nodeName === '#text' || bodyContainer.firstChild.nodeName === 'P')) {
        bodyContainer.classList.add('has-content');
      }

      const firstParagraph = <HTMLElement> document.querySelector('.thank-you__wrapper > p');
      const firstChild = <HTMLElement> document.querySelector('.thank-you__wrapper').firstChild;

      if (firstParagraph) {
        const isParagraphFirstChild = firstParagraph == firstChild;

        if (isParagraphFirstChild) {
          firstParagraph.classList.add('first-paragraph');
        }
      }
    }
  }

  checkContent() {
    const componentWrapper = this.elementRef.nativeElement;

    if ( componentWrapper ) {
      const bodyContainer = componentWrapper.querySelector('.thank-you__container');
      const iframeContent = componentWrapper.querySelector('iframe');
      const imageContent = componentWrapper.querySelector('img');
      const textContent = componentWrapper.textContent.trim();

      if ( bodyContainer && (iframeContent || imageContent || textContent) ) {
        bodyContainer.classList.add('has-content');
      }
    }
  }

  removeEmptyTags(): void {
    const wrapper = document.querySelector('.thank-you__wrapper');

    if ( !wrapper ) {
      return;
    }

    const ps = Array.from(wrapper.querySelectorAll('p'));

    ps.forEach((p) => {
      if (p.innerHTML.replace(/\&nbsp;/g, '').length === 0) {
        p.parentNode.removeChild(p);
      }
    });
  }

}
