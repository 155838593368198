<section class="component featured-tool-breaker">
  <div class="featured-tool-breaker__container">
    <div class="featured-tool-breaker__wrapper {{ variation }}">
      <div class="image-section">
        <p class="eyebrow">{{ eyebrow }}</p>
        <div class="border">
          <img *ngIf="!image.responsive" src="{{ image.url }}" alt="{{ image.alt }}" title="{{ image.alt }}" loading="lazy">
          <picture *ngIf="image.responsive">
            <source *ngFor="let item of image.responsive" [srcset]="item.url" [media]="item.media" />
            <img [src]="image.responsive[0].url" [alt]="image.alt" loading="lazy" />
          </picture>
        </div>
      </div>
      <div class="texts-section">
        <div class="headline headline-{{ variation }}" [innerHTML]="headline"></div>
        <div class="description" [innerHTML]="description"></div>
        <a [title]="cta.text" role="button" [href]="cta.url" target="_blank" class="link">{{ cta.text }}</a>
      </div>
    </div>
  </div>
</section>
