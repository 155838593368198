<div class="component component-bec-finder">
  <div class="wrapper">
    <div class="bec-finder">
      <div class="content--main">
        <picture>
          <source *ngFor="let source of photo.sizes" media="{{ source.media }}" srcset="{{ source.src }}">
          <img src="{{ photo.src }}" alt="{{ photo.alt }}">
        </picture>

        <div class="content--main__texts">
          <h2>{{ heading }}</h2>
          <p>{{ intro }}</p>
          <a [href]="cta.url" *ngIf="cta">{{ cta.label }} 
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1316 0.547222L19.6842 7.25062C19.8947 7.46513 20 7.73326 20 8.0014C20 8.26954 19.8947 8.53767 19.6842 8.75218L13.1316 15.4556C12.7105 15.8846 12.0526 15.8846 11.6316 15.4556C11.2105 15.0266 11.2105 14.3562 11.6316 13.9272L16.4211 9.07395L1.05263 9.07395C0.473684 9.07395 0 8.5913 0 8.0014C0 7.4115 0.473684 6.92886 1.05263 6.92886L16.4211 6.92886L11.6579 2.04878C11.2368 1.61977 11.2368 0.949426 11.6579 0.52014C12.0526 0.117936 12.7105 0.117936 13.1316 0.547222Z" fill="white"/>
            </svg>              
          </a>
        </div>
      </div>
      <div class="content--sub">
        <h3>{{ subheading }}</h3>
        <p class="sub-address" [innerHTML]="address">{{ address }}</p>
        <div class="sub-group">
          <p class="sub-contact"><a [href]="'tel:' + contact">{{contact}}</a></p>
          <p class="sub-website"><a [href]="website">Visit Website</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
