import { Component, OnInit, Input } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist/es5/build/pdf';

@Component({
  selector: 'ncoa-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  @Input() pdfData: any;

  constructor() { }

  ngOnInit(): void {}

}
