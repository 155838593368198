import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { ArticleService } from '@services/article.service';
import { GlobalService } from '@services/global.service';
import { KontentDeliveryService } from '@services/kontent-delivery.service';

@Injectable({
  providedIn: 'root'
})
export class OurSponsorsResolverService implements Resolve<any> {
  constructor(
    private globalService: GlobalService,
    private kontent: KontentDeliveryService
  ) { }

  async resolve(activeRoute: ActivatedRouteSnapshot)  {

    const [page, x] = await Promise.all([this.kontent.getItemAndCache('awp_our_sponsors'), 
    this.globalService.loadGlobalComponentsData()]);
    return page;
  }
}
