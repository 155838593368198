import { Component, Input } from '@angular/core';
import { Author } from './author-byline.types';

@Component({
  selector: 'app-author-byline',
  templateUrl: './author-byline.component.html',
  styleUrls: ['./author-byline.component.scss']
})
export class AuthorBylineComponent {
  @Input() variant?: string;
  @Input() authors: Author[];

  // @TODO: Add as a separate helper function
  kebabCase(url: string): string {
    return url && url.replace(/([a-z])([A-Z])/g, '$1').replace(/[\s_]+/g, '-').toLowerCase();
  }

  ngAfterViewInit() {
    const _authorByLine = document.querySelectorAll('.component--authors-byline');

    window.addEventListener('scroll', () => {
      _authorByLine.forEach( _component => {

        if( this.isInView( _component, ( window.innerHeight / 2 ) ) ) {
          _component.classList.add('in-view');
        }
      });
    });
  }

  isInView( _element, _offset ) {
    let _componentPosition = _element.getBoundingClientRect();

    if( _componentPosition.top <= _offset ) {
      return true;
    } else {
      return false;
    }
  }
}
