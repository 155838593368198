import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { AssessmentService } from '@services/planner/assessment.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lead-gen-assessment',
  templateUrl: './lead-gen.component.html',
  styleUrls: ['./lead-gen.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LeadGen implements OnInit {
  isLoaded: boolean = false;
  showLoadingOverlay: boolean = false;
  pageData: any = null;
  data: any = null;

  assessmentID: string = null;
  version: number = null;

  backCTA: {
    text: string;
    url: string;
  } = null;

  broker: string;

  constructor(
    private assessment: AssessmentService,
    private toolsConfig: ToolsPrimaryConfigService,
    private cas: CustomerActivityService,
    private dataLayerService: DataLayerService,
    private kontentDeliveryService: KontentDeliveryService,
  ) {
   
  }

  async ngOnInit() { 
    const url = new URL(window.location.href);
    const assessmentID = url.searchParams.get('assessment');
    this.broker = url.searchParams.get('broker');
    const backUrl = url.searchParams.get('url');

    if (!assessmentID || !this.broker || !backUrl) {
      window.location.href = this.toolsConfig.rootURL;
    }

    const params = {
      'system.type': 'age_well_planner___broker_lead_gen',
      'elements.assessment_id': assessmentID,
      'depth': 1,
      'limit': 1,
    };
    const result = await this.kontentDeliveryService.getItem(null, params);

    if (result.items[0] === undefined) {
      window.location.href = this.toolsConfig.rootURL;
    }
    const assessmentContent = result.items[0];

    const assessmentData = {
      isSinglePageAssessment: assessmentContent.elements.single_page.value.find(v => { return v.codename === 'true' }) !== undefined
    }

    this.assessment.startAssessmentResponse(assessmentID, 0)
      .subscribe(({ assessment }) => {
        const questions = [];
        const { id, version, sections } = assessment;

        this.assessmentID = id;
        this.version = version;

        sections.forEach((section) => {
          const { questionGroups } = section;

          questionGroups.forEach((questionGroup) => {
            const { condition, questions: _questions } = questionGroup;

            _questions.forEach((question) => {
              questions.push({
                index: questions.length,
                disabled: false,
                ...question.question,
                condition,
                userAnswer: null,
                error: null,
              });
            });
          });
        });

        this.data = {
          title: this.broker === 'medicare' ? 'Get Connected With An Enrollment Specialist' : 'Get Connected With A Financial Specialist',
          questions,
          ...assessmentData
        };

        this.pageData = {
          navCTA: this.toolsConfig.currentConfig.navData.mainLogo,
        };

        this.backCTA = {
          url: backUrl,
          text: 'Exit',
        };

        this.isLoaded = true;
      });

    this.dataLayerService.push({
      clickUrl: location.href,
      pageTitle: document.title || '',
      contentType: 'lead-gen',
      pageType: 'lead-gen-page',
    },false);
  }

  toggleLoadingOverlay(toggle: boolean): void {
    this.showLoadingOverlay = toggle;
  }

  async pollingFunc() {
    const response: any = await this.cas.getActivity()
      .take(1)
      .toPromise();

    if (this.broker === 'medicare') {
      if (response && response?.healthcare && ((response.healthcare?.broker && response.healthcare?.broker != 'waiting') || response.healthcare?.medLeadDuplicate || response.healthcare?.medicareIsShip === true || response.healthcare?.medicareBrokerContactInfoShareConsent === false)) {
        this.showLoadingOverlay = false;
        window.location.href = `${this.backCTA.url}?lead=sent&broker=${this.broker}`;
      } else {
        this.pollingFunc();
      }
    } else {
      if (response && response?.finances && response.finances?.annuitiesConsent) {
        this.showLoadingOverlay = false;
        window.location.href = `${this.backCTA.url}?lead=sent&broker=${this.broker}`;
      } else {
        this.pollingFunc();
      }
    }
  }

  onFinish(questionResponse): void {
    this.showLoadingOverlay = true;

    this.assessment.respondToQuestions(this.assessmentID, this.version, questionResponse)
      .pipe(take(1))
      .subscribe(() => {
        if (this.broker === 'medicare') {
          this.cas.submitConsentForBroker()
            .subscribe(async () => {
              await this.pollingFunc();
            });
        } else {
          this.cas.submitConsentForAnnuities()
            .subscribe(async () => {
              await this.pollingFunc();
            })
        }
      });
  }
}
