import { Component, Input } from '@angular/core';

@Component({
  selector: 'ncoa-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss']
})
export class TopicComponent {

  @Input() theme: string;

  @Input() topic: {
    heading: string,
    links: Array<any>
  }

  constructor() { }
}
