import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Input, TemplateRef, ViewChild, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToDo, ToDoAction } from '@components/planner/plan/to-do/to-do.component';
import { ContentItem } from '@kentico/kontent-delivery';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { CaseManagerService, ClientInfo, CmTodo } from '@services/planner/case-manager.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { RebaDataService } from '@services/planner/reba-data.service';


@Component({
  selector: 'app-client-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
export class ClientPlanComponent implements OnInit {

  onUpdateListTotal($event: any) {
    throw new Error('Method not implemented.');
  }

  @ViewChild('header', { static: false }) header: ElementRef<any>;

  @Input() client: ClientInfo;

  data: any = null;

  allTodos: Map<string, any>;

  activeTodos: ToDo[] = [];    // !completed && !archived
  completedTodos: ToDo[]= [];   // completed && !archived
  archivedTodos: ToDo[]= [];   // archived

  // counts
  activeTodosTotal: number = 0;
  completedTodosTotal: number = 0;
  archivedTodosTotal: number = 0;

  planType: string = 'budget_calculator_plan'

  currentTodo?: ToDo;
  currentTodoSlug: string;
  loading: boolean = false;
  showScreeningAnswers: boolean = false;
  currentTodoList: 'activeTodosRef' | 'completedTodosRef' | 'archivedTodosRef' = 'activeTodosRef';
  showInstructions: boolean;
  headerSticky: boolean;
  public screeningCode: string;

  clientQuestionsAnswers: Map<string, QuestionAnswer> = new Map<string, QuestionAnswer>();



  constructor(
    private cmService: CaseManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private reba: RebaDataService,
    private cas: CustomerActivityService,
  ) {

    this.activeTodos = [];
    this.completedTodos = [];
    this.archivedTodos = [];


  }




  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.allTodos = await this.cmService.getTodos() ;
    this.activeTodos = this.mapTodos(this.client.activeTodos, 'active');
    this.activeTodosTotal = this.activeTodos?.length ?? 0;
    this.completedTodos = this.mapTodos(this.client.completedTodos, 'complete');
    this.completedTodosTotal = this.completedTodos?.length ?? 0 ;
    this.archivedTodos = this.mapTodos(this.client.archivedTodos, 'archived');
    this.archivedTodosTotal = this.archivedTodos?.length ?? 0;

    this.cmService.getClientScreening(this.client.pk).then(async (screening) => {
      await this.getScreeningAnswers(screening);
      this.loading = false;

    });


    this.route.queryParams.subscribe(params => {
      if (params['todo']) {
        const todoSlug = params['todo'];
        const todo = this.cmService.getTodoBySlug(todoSlug);
        if (todo) {
          this.viewTodo(todo);
        }
      }
      this.loading = false;
    });



  }

  async getScreeningAnswers(screening) {
    const questions = this._extractQuestionsFromScreening(screening);

    for (const question of questions) {
      
      const answer = this.client[question];
      if (answer !== null && answer !== undefined) {
        if (!this.clientQuestionsAnswers.has(question)) {
          console.warn('missing', question, 'from', this.clientQuestionsAnswers, 'and', answer);
        }
        const questionData = this.clientQuestionsAnswers.get(question)
        questionData.answer = answer;
        this.clientQuestionsAnswers.set(question, questionData);
      }
    }
  }

  viewTodo(todo) {
    this.currentTodo = todo;
    this.currentTodoSlug = todo.slug;
    this.showScreeningAnswers = false;
  }

  public viewAnswers() {
    this.showScreeningAnswers = true;
    
  }

  mapTodos(todos: CmTodo[], status?: 'active' | 'complete' | 'archived'): ToDo[] {
    return todos?.map(todo => {
      if (!this.allTodos.has(todo.slug)) {
        console.error('missing todo', todo);
        return null;
      }
      return {
        ...todo,
        timestamp: new Date(todo.timestamp),
        source: this.planType,
        completed: status === 'complete',
        archived: status === 'archived',
        snippet: this.allTodos.get(todo.slug).snippet,
        title: this.allTodos.get(todo.slug).title,
        content: this.allTodos.get(todo.slug).content,
        goals: this.allTodos.get(todo.slug).goals
      }
    })
  }



  public async onTodoAction(todo: ToDo, action: string) {
  }

  public onBackButton($event: any) {
    this.currentTodo = null;
    this.currentTodoSlug = null;
    this.showScreeningAnswers = false;
    this.router.navigate([], {
      queryParams: {}
    });
  }


  private _extractQuestionsFromScreening(screening: any) {
    const questions = [];

    for (const section of screening.sections) {
      for (const group of section.questionGroups) {
        questions.push(...group.questions.map((q) => q.id));
        group.questions.forEach((question) => {
          this.clientQuestionsAnswers.set(question.id, {
            questionId: question.id,
            questionText: question.text.en,
            answerGroup: question.answerGroup,
            answer: null
          });
        });
      }
    }
    return questions;
  }

}

export type QuestionAnswer = {
  questionId: string;
  answerGroup?: any;
  answer?: string | number | boolean | string[];
  questionText: string;
}

