import { Component, OnInit, Input } from '@angular/core';
import { entries } from './related-articles.types';

@Component({
  selector: 'ncoa-related-articles',
  templateUrl: './related-articles.component.html',
  styleUrls: ['./related-articles.component.scss']
})
export class RelatedArticlesComponent {
  @Input() entries: entries[];
}
