import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ncoa-feature-content-block',
  templateUrl: './feature-content-block.component.html',
  styleUrls: ['./feature-content-block.component.scss']
})
export class FeatureContentBlockComponent implements OnInit {

  @Input() image: any;
  @Input() title: string;
  @Input() description: string;
  @Input() buttonCTAText: string;
  @Input() buttonCTALink: string;
  @Input() isButtonPresent: boolean;
  @Input() orientation: string;
  @Input() background: string;

  isExternalLink: boolean;
  public isMobile: boolean;
  public isReversed: boolean;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {

    this.checkIfExternalLink(this.buttonCTALink)
    this.isMobile = window.innerWidth < 1024;
  }

  onResize(event) {
    this.isMobile = window.innerWidth < 1024;
  }

  onClick() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([this.buttonCTALink])
    );
  }


  checkIfExternalLink(url) {
    try {
      this.isExternalLink = new URL(url).origin !== window.location.origin;
    }
    catch {
      this.isExternalLink = false;
    }
  }

}
