import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '@services/global.service';
import { SeoService } from '@services/seo.service';

@Component({
  selector: 'app-our-sponsors',
  templateUrl: './our-sponsors.component.html',
  styleUrls: ['./our-sponsors.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OurSponsorsComponent implements OnInit {

  data: any;
  content: {
    title,
    heroDescription,
    heroCTA,
    heroCTALink,
    heroImage : {url, description}
    platinumLabel,
    platinumSponsors: {name, logo : {url, description}, link}[],
    silverLabel,
    silverSponsors: {name, logo: {url, description}, link}[],
    bronzeLabel,
    bronzeSponsors: {name, logo: {url, description}, link}[],
    friendsLabel: {name, link}[],
    friends,
  } 
  isLoaded: boolean = false;
  constructor(
    private activeRoute: ActivatedRoute,
    private globalService: GlobalService,
    private SeoService: SeoService
  ) { }

  async ngOnInit() {
    this.data = this.activeRoute.snapshot.data.pageData;
    this.content = this.parseData();
    this.isLoaded = true;
    this.SeoService.extractAndRender(this.data.item);

    this.globalService.loadFooter(true);


  }

  getImageFromItem(item, field) {
    if(item.elements[field].value[0])
    return {
      url: item.elements[field].value[0].url ?? '',
      description: item.elements[field].value[0].description ?? ''
    }
    else{
      return {
        url: '',
        description: ''
      }
    }

  }

  parseData() {
    const item = this.data.item;
    const title = item.elements.title.value ?? '';
    const heroDescription = item.elements.description.value ?? '';
    const heroImage = this.getImageFromItem(item, 'primary_image');
    const heroCTA = item.elements.sponsors_hero_cta.value?? '';

    let heroCTALink = ''
    if(item.elements.sponsors_cta_link?.value[0])
      heroCTALink = item.elements.sponsors_cta_link.value[0].url ?? '';

    const platinumLabel = item.elements.platinum_sponsors_label.value ?? '';

    const platinumSponsors = item.elements.platinium_sponsors.value.map(sponsor => {
      const sponsorItem = this.data.modular_content[sponsor];
      return {
        name: sponsorItem.elements.sponsor_name.value ?? '',
        logo: this.getImageFromItem(sponsorItem, 'sponsor_logo'),
  
        link: sponsorItem.elements.external_link.value ?? ''
      };
    });

    const silverLabel = item.elements.silver_sponsors_label.value ?? '';

    const silverSponsors = item.elements.silver_sponsors.value.map(sponsor => {
      const sponsorItem = this.data.modular_content[sponsor];
      return {
        name: sponsorItem.elements.sponsor_name.value ?? '',
        logo: this.getImageFromItem(sponsorItem, 'sponsor_logo'),
        link: sponsorItem.elements.external_link.value ?? '',
      };
    });

    const bronzeLabel = item.elements.bronze_sponsors_label.value ?? '';

    const bronzeSponsors = item.elements.bronze_sponsors.value.map(sponsor => {
      const sponsorItem = this.data.modular_content[sponsor];
      return {
        name: sponsorItem.elements.sponsor_name.value ?? '',
        logo: this.getImageFromItem(sponsorItem, 'sponsor_logo'),
        link: sponsorItem.elements.external_link.value ?? '',
      };
    });

    const friendsLabel = item.elements.friends_of_ncoa_label.value ?? '';
    const friends = item.elements.friends_of_ncoa.value.map(sponsor => {
      const sponsorItem = this.data.modular_content[sponsor];
      return {
        name: sponsorItem.elements.sponsor_name.value ?? '',
        link: sponsorItem.elements.external_link.value ?? '',
      };
    });


    return {
      title,
      heroDescription,
      heroImage,
      heroCTA,
      heroCTALink,
      platinumLabel,
      platinumSponsors,
      silverLabel,
      silverSponsors,
      bronzeLabel,
      bronzeSponsors,
      friendsLabel,
      friends,
    }
  }
}

