import { Component, OnInit, Input } from '@angular/core';
import { entries } from './load-more.types';
import { TimelineLite } from 'gsap';

@Component({
  selector: 'ncoa-load-more',
  templateUrl: './load-more.component.html',
  styleUrls: ['./load-more.component.scss']
})
export class LoadMoreComponent implements OnInit {
  @Input() entries: entries[];

  firstItems: any[] = [];
  remainingItems: any[] = [];
  isLoading: boolean = false;
  endingGroupIndex: number = 0;
  shownItems: number = 0;
  totalItems: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.firstItems = this.entries.slice(0, 3);
    this.remainingItems = this.entries.slice(3, this.entries.length);
    this.totalItems = this.entries.length;
    this.shownItems = this.totalItems <= 3 ? this.totalItems : 3;
  }

  loadMore() {
    this.isLoading = true;

    const newEndingGroupIndex = this.endingGroupIndex + 1;
    const shownItems = this.shownItems + 6;

    const targetGroup: HTMLElement = document.querySelector(`.load-more .entries .group:nth-child(${newEndingGroupIndex + 1})`);
    const firstLink: HTMLElement = targetGroup.querySelector('a');

    const topOffset = window.scrollY;

    document.body.style.top = `-${topOffset}px`;
    document.body.style.position = 'fixed';
    document.body.style.width = `${window.innerWidth}px`;
    document.querySelector('.header--global').classList.add('is-scrolled');
    document.querySelector('.header--global').classList.add('is-sticky');

    setTimeout(() => {
      new TimelineLite()
        .to(targetGroup, { display: 'grid' })
        .to(targetGroup, { opacity: 1, y: 0, duration: 0.4 })
        .eventCallback('onComplete', () => {
          document.body.style.removeProperty('top');
          document.body.style.removeProperty('position');
          document.body.style.removeProperty('width');
          window.scrollTo(0, topOffset);
          firstLink.focus();

          this.endingGroupIndex = newEndingGroupIndex;
          this.shownItems = shownItems <= this.totalItems ? shownItems : this.totalItems;

          this.isLoading = false;
        });
    }, 1500);

  }
}
