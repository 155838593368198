import { Component, Input } from '@angular/core';
import { PartnerLogo } from 'src/app/typings/partners';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent {
  @Input() heading: string;
  @Input() partners: PartnerLogo[];
}
