<ncoa-modal
  *ngIf="modalData"
  [id]="modalData.id"
  [type]="modalData.type"
  [isOpen]="modalData.isOpen"
  [heading]="modalData.heading"
  [description]="modalData.description"
  [cta]="modalData.cta"
  [backgroundImage]="modalData.backgroundImage"
  [audience]="modalData.audience"
  [location]="modalData.location"
  [displayOnce]="modalData.displayOnce"
  [formSubmitHandler]="modalData.formSubmitHandler"
  [donateData]="modalData.donateData"
  [newsletterData]="modalData.newsletterData"
></ncoa-modal>

<!-- <ncoa-modal *ngIf="!hasDisplayOnce" [id]="'general_modal'" [type]="'general'" [isOpen]="open" [heading]="heading"
  [description]="description" [cta]="cta" [backgroundImage]="backgroundImage">
</ncoa-modal>
 -->
