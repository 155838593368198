<div class="breakdown-header">
  <div class="breakdown-header__wrapper">
    <a [href]="backLink.url" [title]="backLink.text" class="back-cta" *ngIf="backLink.url !== ''">
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.86842 0.547222L0.315788 7.25062C0.105263 7.46513 0 7.73326 0 8.0014C0 8.26954 0.105263 8.53767 0.315788 8.75218L6.86842 15.4556C7.28947 15.8846 7.94737 15.8846 8.36842 15.4556C8.78947 15.0266 8.78947 14.3562 8.36842 13.9272L3.57895 9.07395L18.9474 9.07395C19.5263 9.07395 20 8.5913 20 8.0014C20 7.4115 19.5263 6.92886 18.9474 6.92886L3.57895 6.92886L8.34211 2.04878C8.76316 1.61977 8.76316 0.949426 8.34211 0.52014C7.94737 0.117936 7.28947 0.117936 6.86842 0.547222Z" fill="#0B4A5D"/>
      </svg>
      <div><span>{{ backLink.text }}</span></div>
    </a>

    <header class="breakdown-header__main">
      <div class="background">
        <div class="background--mobile">
          <svg viewBox="0 0 327 297" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.1">
            <path d="M297.153 50.0699C310.058 50.2003 322.407 55.375 331.541 64.4952C340.676 73.6151 345.859 85.9427 345.99 98.826L297.153 98.826L297.153 50.0699Z" stroke="#DAE4E7"/>
            <path d="M296.597 98.7348C283.692 98.6044 271.343 93.4297 262.209 84.3095C253.074 75.1896 247.891 62.8619 247.76 49.9787L296.597 49.9787L296.597 98.7348Z" stroke="#DAE4E7"/>
            <path d="M296.792 197.509C283.887 197.379 271.539 192.204 262.404 183.084C253.269 173.964 248.086 161.636 247.956 148.753L296.792 148.753L296.792 197.509Z" stroke="#DAE4E7"/>
            <path d="M296.591 49.4871C296.46 36.6039 291.278 24.2762 282.143 15.1563C273.008 6.03616 260.66 0.861445 247.755 0.73104L247.755 49.4871L296.591 49.4871Z" stroke="#DAE4E7"/>
            <path d="M247.191 196.944C234.285 196.813 221.937 191.639 212.802 182.518C203.668 173.399 198.485 161.071 198.354 148.188L247.191 148.188L247.191 196.944Z" stroke="#DAE4E7"/>
            <path d="M148.081 246.44C135.176 246.309 122.828 241.135 113.693 232.015C104.558 222.895 99.3755 210.567 99.2447 197.684L148.081 197.684L148.081 246.44Z" stroke="#DAE4E7"/>
            <path d="M197.911 246.703C210.816 246.572 223.164 241.397 232.299 232.277C241.434 223.157 246.617 210.83 246.747 197.946L197.911 197.946L197.911 246.703Z" stroke="#DAE4E7"/>
            <path d="M148.579 197.949C161.484 198.079 173.832 203.254 182.967 212.374C192.102 221.494 197.285 233.822 197.415 246.705L148.579 246.705L148.579 197.949Z" stroke="#DAE4E7"/>
            <path d="M198.22 98.9615C211.125 99.0919 223.473 104.267 232.608 113.387C241.742 122.507 246.925 134.834 247.056 147.718L198.22 147.718L198.22 98.9615Z" stroke="#DAE4E7"/>
            <path d="M197.599 295.853C197.468 282.97 192.285 270.642 183.151 261.523C174.016 252.402 161.668 247.228 148.763 247.097L148.762 295.853L197.599 295.853Z" stroke="#DAE4E7"/>
            <path d="M148.768 196.946C148.899 184.063 154.082 171.735 163.216 162.615C172.351 153.495 184.699 148.32 197.605 148.19L197.605 196.946L148.768 196.946Z" stroke="#DAE4E7"/>
            <path d="M297.007 197.949C284.102 198.079 271.754 203.254 262.619 212.374C253.484 221.494 248.301 233.822 248.171 246.705L297.007 246.705L297.007 197.949Z" stroke="#DAE4E7"/>
            <path d="M297.411 246.703C310.316 246.572 322.664 241.397 331.799 232.277C340.934 223.157 346.117 210.83 346.247 197.946L297.411 197.946L297.411 246.703Z" stroke="#DAE4E7"/>
            <path d="M297.222 197.475C297.352 184.592 302.535 172.265 311.67 163.145C320.804 154.024 333.153 148.85 346.058 148.719L346.058 197.475L297.222 197.475Z" stroke="#DAE4E7"/>
            </g>
          </svg>
        </div>
        <div class="background--desktop">
          <svg viewBox="0 0 690 216" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
            <g opacity="0.1">
            <path d="M650.991 48.1329C674.116 48.3666 696.243 57.6393 712.612 73.9818C728.98 90.324 738.267 112.414 738.502 135.5L650.991 135.5L650.991 48.1329Z" stroke="#DAE4E7" stroke-width="1.79192"/>
            <path d="M562.475 -40.2802C585.6 -40.0465 607.727 -30.7738 624.096 -14.4312C640.465 1.91093 649.752 24.001 649.986 47.0868L562.475 47.0868L562.475 -40.2802Z" stroke="#DAE4E7" stroke-width="1.79192"/>
            <path d="M739.298 -40.2802C716.173 -40.0465 694.046 -30.7738 677.677 -14.4312C661.309 1.91093 652.022 24.001 651.787 47.0868L739.298 47.0868L739.298 -40.2802Z" stroke="#DAE4E7" stroke-width="1.79192"/>
            <path d="M561.478 46.9267C538.353 46.693 516.226 37.4203 499.857 21.0777C483.488 4.73557 474.201 -17.3545 473.967 -40.4403L561.478 -40.4403L561.478 46.9267Z" stroke="#DAE4E7" stroke-width="1.79192"/>
            <path d="M561.829 223.921C538.705 223.687 516.577 214.414 500.209 198.072C483.84 181.73 474.553 159.64 474.318 136.554L561.829 136.554L561.829 223.921Z" stroke="#DAE4E7" stroke-width="1.79192"/>
            <path d="M472.95 222.905C449.826 222.672 427.699 213.399 411.33 197.056C394.961 180.714 385.674 158.624 385.44 135.538L472.95 135.538L472.95 222.905Z" stroke="#DAE4E7" stroke-width="1.79192"/>
            <path d="M385.198 47.3273C408.323 47.5609 430.45 56.8336 446.819 73.1762C463.187 89.5183 472.474 111.608 472.709 134.694L385.198 134.694L385.198 47.3273Z" stroke="#DAE4E7" stroke-width="1.79192"/>
            <path d="M296.577 222.912C296.811 199.826 306.098 177.736 322.466 161.394C338.835 145.051 360.962 135.779 384.087 135.545L384.088 222.912L296.577 222.912Z" stroke="#DAE4E7" stroke-width="1.79192"/>
            <path d="M738.97 136.35C715.845 136.583 693.718 145.856 677.349 162.199C660.981 178.541 651.694 200.631 651.459 223.717L738.97 223.717L738.97 136.35Z" stroke="#DAE4E7" stroke-width="1.79192"/>
            <path d="M562.6 223.863C562.834 200.777 572.121 178.687 588.49 162.345C604.859 146.003 626.986 136.73 650.111 136.496L650.111 223.863L562.6 223.863Z" stroke="#DAE4E7" stroke-width="1.79192"/>
            </g>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="690" height="216" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div class="content">
        <div class="">
        <h1><span>{{resultsLabel}}:</span> {{ removeExcessText(headline) }}</h1>
      </div>
        <div class="button-group">
          <button type="button" class="print-btn" (click)="clickPrint()">
            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.96552 22V18.7468C4.96552 16.2662 6.9931 14.2736 9.51724 14.2736H19.0345V22H4.96552ZM2.02759 17.1202H2.89655V13.8669C2.89655 13.1756 3.47586 12.647 4.13793 12.647H19.8621C20.5655 12.647 21.1034 13.2163 21.1034 13.8669V17.1202H21.9724C23.0897 17.1202 24 16.2255 24 15.1276V7.80779C24 6.22184 22.6759 4.87988 21.0207 4.87988H2.97931C1.36552 4.87988 0 6.18118 0 7.80779V15.1276C0 16.1849 0.910345 17.1202 2.02759 17.1202Z" fill="white"/>
              <path d="M4.96484 3.25323C4.96484 1.46396 6.4545 0 8.27519 0H19.0338V3.25323H4.96484Z" fill="white"/>
            </svg>
            <div><span>{{printPageText}}</span></div>
          </button>
          <div class="cta-group"  *ngIf="showBtns">
            <button type="button" class="cta" (click)="clickSave()">
              <svg viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 2.23226V23.4647C1 24.4929 2.18505 25.0689 2.99357 24.4336L11 18.1429L19.0064 24.4336C19.8149 25.0689 21 24.4929 21 23.4647V2.23226C21 1.5517 20.4483 1 19.7677 1H2.23226C1.5517 1 1 1.5517 1 2.23226Z" stroke="white" stroke-width="2" [attr.fill]="isSaved ? 'white' : 'none'"/>
              </svg>
              {{ isSaved ? 'Results Saved' : 'Save Result' }}
            </button>
            <button type="button" class="cta" (click)="clickProfile()">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3469 8.10478L14.7152 7.69932L15.2102 7.16081V14.0924C15.2102 15.1441 14.485 15.9993 13.5987 15.9993H2.61728C1.72519 15.9993 1 15.1441 1 14.0924V3.69608C1 2.64441 1.72519 1.78913 2.61728 1.78913H9.75788L8.89456 2.73944H2.61728C2.20289 2.73944 1.86332 3.17024 1.86332 3.69608V14.0924C1.86332 14.6182 2.20289 15.049 2.61728 15.049H13.5987C14.0131 15.049 14.3469 14.6182 14.3469 14.0924V8.10478Z" fill="white"/>
                <path d="M15.8189 2.06202L14.7684 3.11764C14.7684 3.12401 14.7621 3.13038 14.7557 3.13674L13.99 3.89982L7.84202 10.0856L6.52576 10.4735L6.96242 9.20162L13.8761 2.25279V2.24643L14.9392 1.17808C15.0595 1.05725 15.2177 1 15.3759 1C15.5404 1 15.6986 1.05725 15.8189 1.17808C16.0594 1.41973 16.0594 1.82036 15.8189 2.06202Z" fill="white"/>
                <path d="M14.3469 8.10478L14.7152 7.69932L15.2102 7.16081V14.0924C15.2102 15.1441 14.485 15.9993 13.5987 15.9993H2.61728C1.72519 15.9993 1 15.1441 1 14.0924V3.69608C1 2.64441 1.72519 1.78913 2.61728 1.78913H9.75788L8.89456 2.73944H2.61728C2.20289 2.73944 1.86332 3.17024 1.86332 3.69608V14.0924C1.86332 14.6182 2.20289 15.049 2.61728 15.049H13.5987C14.0131 15.049 14.3469 14.6182 14.3469 14.0924V8.10478Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.8189 2.06202L14.7684 3.11764C14.7684 3.12401 14.7621 3.13038 14.7557 3.13674L13.99 3.89982L7.84202 10.0856L6.52576 10.4735L6.96242 9.20162L13.8761 2.25279V2.24643L14.9392 1.17808C15.0595 1.05725 15.2177 1 15.3759 1C15.5404 1 15.6986 1.05725 15.8189 1.17808C16.0594 1.41973 16.0594 1.82036 15.8189 2.06202Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{ profileLink.text }}
            </button>
          </div>
          <!-- reuse lang toggle logic for falls-only restart button -->
          <button *ngIf="showLanguageToogle" type="button" (click)="startOver()" class="print-btn start-over-btn">
            <img class="icon" src="assets/icons/start-over.svg" alt="start over" />
            <div><span>Start over</span></div>
          </button>
          <div *ngIf="showLanguageToogle" class="header-global__nav-desktop_languageToggle">
            <div>
              <select name="language-toggle" (change)="onLanguageToggle($event)">
                <option *ngFor="let option of languageOptions" [value]="option.value" [selected]="option.value === selectedLanguage">{{option.label}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</div>
