import { Injectable } from '@angular/core';
import { ArticleService } from '@services/article.service';
import { GlobalService } from '@services/global.service';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { ToolsPrimaryConfigService } from './tools-primary-config.service';
import { UtilityService } from './utility.service';

@Injectable({ providedIn: 'root' })
export class ResourceService {
  constructor(
    private articleService: ArticleService,
    private kontent: KontentDeliveryService,
    private tools: ToolsPrimaryConfigService,
    private utility: UtilityService,
    private global: GlobalService,
  ) { }

  async getArticle(slug) {
    const rawData: any = await this.articleService.getArticle(slug,true);
    const { components, id, takeaways, raw } = rawData;
    const { elements } = raw.item;
    const { modular_content } = raw;

    const data = {};

    if (components) {
      // article id
      data['id'] = id;

      // hero
      let flag = components.find((component) => component.componentType === 'articleHero');

      if (flag) {
        data['articleDetailHeader'] = {
          eyebrow: flag.eyebrow,
          title: flag.articleTitle,
          readingTime: flag.readingTime ? `${flag.readingTime} min read` : null,
        };
      } else {
        flag = components.find((component) => component.componentType === 'eventHero');

        data['articleDetailHeader'] = {
          eyebrow: 'Event',
          title: flag.title,
          readingTime: null,
        };
      }

      // author by line
      flag = components.find((component) => component.componentType === 'authorByLine');
      data['authorByline'] = flag.authors.map((author) => ({
        link: this.tools.rawConfig.elements.tool_type.value[0].codename === 'for_awa' ? author.link : null,
        imgUrl: author.photo,
        name: author.name,
        position: author.position,
      }));

      // takeaways
      data['takeAwaysComponent'] = {
        headings: takeaways.heading,
        takeaways: takeaways.takeaways,
      };

      // body
      flag = components.find((component) => component.componentType === 'body');
      if (flag) {
        data['articleBody'] = {
          content: flag.content,
          url: flag.url,
        };
      }

      // partners grid
      flag = components.find((component) => component.componentType === 'partners_grid');
      if (flag) {
        data['partnersGrid'] = {
          heading: flag.heading,
          partners: flag.partners,
        };
      }

      // tool card
      if (elements.tool_card_1_up.value.length > 0) {
        const toolCard1UpCodeName = elements.tool_card_1_up.value[0];
        const toolCard1Up = raw.modular_content[toolCard1UpCodeName];

        if (toolCard1Up && (toolCard1Up?.elements?.tool_card || []).value.length > 0) {
          const headline = toolCard1Up.elements.headline.value;
          const toolCardCodeName = toolCard1Up.elements.tool_card.value[0];
          const toolCard = { item: modular_content[toolCardCodeName] };

          if (toolCard && (toolCard?.item?.elements?.tool).value.length > 0) {
            const toolCodeName = toolCard.item.elements.tool.value[0];
            const tool = modular_content[toolCodeName];

            const card = {
              id: toolCard.item.system.id,
              linkedID: tool.system.id,
              linkedCodename: tool.system.codename,
              type: 'tool',
              heading: toolCard.item.elements.headline.value,
              intro: toolCard.item.elements.description.value,
              cta: {
                label: (this.tools.customerActivity.basic?.completedAssessments || []).find((key) => (
                  key === tool.elements.assessment_id.value
                )) ? 'View Results' : 'Start Assessment',
                url: this.tools.routes.find((route) => (
                  route.assessmentID &&
                  route.assessmentID === tool.elements.assessment_id.value
                )).route,
              },
              photo: toolCard.item.elements.card_image.value.length > 0 ? {
                url: toolCard.item.elements.card_image.value[0].url,
                alt: toolCard.item.elements.card_image.value[0].description,
              } : null,
              full: true,
              variation: tool.elements.assessment_id.value !== 'generalAssessment' || tool.elements.assessment_id.value !== 'generalAssessmentAWP' ? 'tools-medicare-single-col' : 'tools-retirement-full-width',
              theme: tool.elements.assessment_id.value !== 'generalAssessment' || tool.elements.assessment_id.value !== 'generalAssessmentAWP' ? 'dark' : null,
            };

            data['toolCard1Up'] = {
              type: 'tool',
              heading: headline,
              card,
            };
          }
        }
      }

      if (elements) {
        // medicare lead gen
        if (elements.medicare_lead_gen.value.length > 0) {
          const leadGenCodeName = elements.medicare_lead_gen.value[0];
          const contactCodeName = modular_content[leadGenCodeName].elements.medicare_contact_landing_page.value[0];
          const { elements: medicareLeadGenElements } = modular_content[leadGenCodeName];
          const { elements: medicareContactElements } = modular_content[contactCodeName];

          const getValue = Object.entries({...medicareContactElements, ...medicareLeadGenElements}).reduce((acc, [key, value]) => {
            acc[key] = value['value'];
            return acc;
          }, {});

          const {
            eyebrow,
            headline,
            left_image,
            branding_image,
            form_instructions,
            form_required_text,
            form_optional_label,
            form_tooltip_label,
            first_name_label,
            first_name_required_error,
            interest_reason_label,
            last_name_label,
            last_name_required_error,
            email_label,
            email_required_error,
            email_invalid_error,
            zip_code_label,
            zip_code_required_error,
            zip_code_invalid_error,
            zip_code_tooltip_text,
            phone_number_label,
            phone_number_invalid_error,
            phone_number_tooltip_text,
            date_of_birth_label,
            date_of_birth_required_error,
            date_of_birth_invalid_error,
            date_of_birth_tooltip_text,
            form_cta_text,
            form_agreement_text,
          }: any = getValue;

          const form = {
            form_instructions,
            form_required_text,
            form_optional_label,
            form_tooltip_label,
            first_name_label,
            first_name_required_error,
            interest_reason_label,
            last_name_label,
            last_name_required_error,
            email_label,
            email_required_error,
            email_invalid_error,
            zip_code_label,
            zip_code_required_error,
            zip_code_invalid_error,
            zip_code_tooltip_text,
            phone_number_label,
            phone_number_invalid_error,
            phone_number_tooltip_text,
            date_of_birth_label,
            date_of_birth_required_error,
            date_of_birth_invalid_error,
            date_of_birth_tooltip_text,
            form_cta_text,
            form_agreement_text,
          };

          let image: object|null = null;
          if (left_image.length > 0) {
            image = {
              sizes: this.global.remodelImageSrc(left_image[0].url),
              src: left_image[0].url,
              alt: left_image[0].description,
            }
          }

          let branding: object|null = null;
          if (branding_image.length > 0) {
            branding = {
              src: branding_image[0].url,
              alt: branding_image[0].description,
            }
          }

          data['medicareLeadGen'] = {
            eyebrow,
            headline,
            image,
            branding,
            form,
          };
        }

        // medicare plan compare
        if (elements.plan_compare.value.length > 0) {
          const planCareCodename = elements.plan_compare.value[0];
          const { elements: planCompare } = modular_content[planCareCodename];
          const bulletpoints = planCompare.plan_breakdown_bulletpoints.value;
          const considerationHeading = planCompare.plan_breakdown_header.value;
          const considerations = bulletpoints.reduce((acc, curr) => {
            const raw = modular_content[curr];
            acc.push({
              heading: raw.elements.bulletpoint_header.value,
              tooltip: raw.elements.bulletpoint_tooltip.value,
              title: {
                typeA: raw.elements.column_a_title.value,
                typeB: raw.elements.column_b_title.value,
              },
              description: {
                typeA: raw.elements.column_a_body.value,
                typeB: raw.elements.column_b_body.value,
              },
            })
            return acc;
          }, []);

          data['planCompare'] = {
            considerationHeading,
            considerations,
          };
        }

        // related resources
        if (elements[this.tools.toolTaxonomyCodename].value.length > 0) {
          const resourceTaxonomies = elements[this.tools.toolTaxonomyCodename].value.map((taxonomy) => taxonomy.codename);
          let taxonomies = [];
          let moreLink = null;

          if (!this.tools.singularVerticalTopic) {
            const resourceVerticals = resourceTaxonomies.map((taxonomy) => this.tools.getVertical(taxonomy).codename);
            const vertical = this.utility.arrayMode(resourceVerticals);

            this.tools.updateActiveNav('taxonomy-codename', vertical);

            const flag = this.tools.awpTaxonomyCatalog.filter((catalog) => (
              catalog.topics.find((topic) => topic.codename === vertical)
            ));

            flag.forEach((catalog) => {
              catalog.topics.forEach((topic) => {
                taxonomies.push(topic.codename);
              });
            });

            const parentRoute = this.tools.routes
              .filter((route) => route.taxonomy)
              .find((route) => route.taxonomy.codename === vertical);

            if (parentRoute) {
              moreLink = {
                label: `Explore ${parentRoute.taxonomy.name}`,
                url: parentRoute.route,
              };
            }
          } else {
            if (resourceTaxonomies.length > 1) {
              this.tools.updateActiveNav('home');
            } else {
              this.tools.updateActiveNav('taxonomy-codename', resourceTaxonomies[0]);
            }

            taxonomies = resourceTaxonomies;
          }

          const params = {
            'system.type': 'ncoa_article_content',
            'elements': 'title,body_ckeditor,url',
          };

          const _prop = `elements.${this.tools.toolTaxonomyCodename}[any]`;

          params[_prop] = taxonomies.join(',');

          let relatedResources = await this.kontent.getItem(null, params);

          if (relatedResources && relatedResources.items && relatedResources.items.length >= 4) {
            relatedResources = relatedResources.items.map((rawResource) => {
              const div = document.createElement('div');
                    div.innerHTML = rawResource.elements.body_ckeditor ? rawResource.elements.body_ckeditor.value : '';
              const text = div.innerText;

              return {
                id: rawResource.system.id,
                type: 'article',
                heading: rawResource.elements.title.value,
                intro: `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`,
                cta: {
                  url: `${this.tools.rootURL}/resource/${rawResource.elements.url.value}`,
                  label: 'Read Resource',
                },
                breadcrumb: elements[this.tools.toolTaxonomyCodename].value[0].name,
              }
            });
          } else {
            relatedResources = [];
          }

          relatedResources = relatedResources.filter((resource) => resource.id !== id).slice(0, 3);
          data['ncoaThreeUp'] = {
            heading: elements.related_resource_heading.value || 'Related Resources',
            cards: relatedResources,
            moreLink,
          };
        }

        // article is a resource
        if (elements.awp_trusted_expert.value.length === 0) {
          // text with image (promo)
          if (elements.promo.value.length > 0) {
            const promoKey = elements.promo.value[0];
            const promo = raw.modular_content[promoKey];
            let cta;

            if (promo.elements.cta_text__optional_.value.trim().length > 0) {
              let url = promo.elements.cta_manual_external_link__optional_.value;

              if (promo.elements.cta_internal_link__optional_.value.length > 0) {
                let linkedContent = modular_content[promo.elements.cta_internal_link__optional_.value[0]];

                switch (linkedContent.system.type) {
                  case 'ncoa_article_content': {
                    const slug = linkedContent.elements.url.value;
                    url = `${this.tools.rootURL}/resource/${slug}`;
                    break;
                  }
                  case 'awa_benefits_tool___vertical_subtopic': {
                    url = (this.tools.routes.find((route) => (
                      route.type === 'vertical-subtopic-learn' &&
                      route.codename === linkedContent.system.codename
                    )) || { route: null }).route;
                    break;
                  }
                }
              }

              cta = {
                label: promo.elements.cta_text__optional_.value,
                url,
              };
            }

            data['promo'] = {
              alignment: 'right',
              heading: promo.elements.headline.value,
              intro: promo.elements.description.value,
              image: {
                sizes: this.global.remodelImageSrc(promo.elements.featured_image.value[0].url),
                src: promo.elements.featured_image.value[0].url,
                alt: promo.elements.featured_image.value[0].description,
              },
              cta,
            };
          }

          data['leadGenConfirmed'] = {
            show: false
          }

          // article feedback
          flag = components.find((component) => component.componentType === 'articleFeedback');
          data['articleFeedback'] = {
            votes: flag.votes,
            article: flag.article,
            slug: flag.slug,
            id: flag.id,
          };
        } else {
          const brokers = [];
          this.tools.rawConfig?.elements.brokers.value.forEach(item => {
              brokers[this.tools.modular_content[item].elements.broker_code.value.toLowerCase()] = this.tools.modular_content[item].elements;
          });

          // lead gen
          if (elements.lead_gen.value.length > 0) {
            let isLeadGenAssessment = false;
            const leadGenKey = elements.lead_gen.value[0];
            const leadGen = raw.modular_content[leadGenKey];

            let cta;

            if (leadGen.elements.assessment_id.value && leadGen.elements.cta_text.value) {
              isLeadGenAssessment = true;

              cta = {
                label: leadGen.elements.cta_text.value,
                url: `${this.tools.rootURL}/lead-gen?assessment=${leadGen.elements.assessment_id.value}&broker=${leadGen.elements.broker_type.value[0].codename}&url=${window.location.href}`,
              };
            }

            if (leadGen.elements.external_manual_cta_link.value && leadGen.elements.cta_text.value) {
              cta = {
                label: leadGen.elements.cta_text.value,
                url: leadGen.elements.external_manual_cta_link.value,
              };
            }

            if (leadGen.elements.internal_cta_link.value.length > 0 && leadGen.elements.cta_text.value) {
              const articleKey = leadGen.elements.internal_cta_link.value[0];
              const article = modular_content[articleKey];

              if (article) {
                cta = {
                  label: leadGen.elements.cta_text.value,
                  url: `${this.tools.rootURL}/resource/${article.elements.url.value}`,
                };
              }
            }

            let icon;
            if (leadGen.elements.branding_image.value.length > 0) {
              icon = {
                src: leadGen.elements.branding_image.value[0].url,
                alt: leadGen.elements.branding_image.value[0].description
              };
            }

            const rawURL = new URL(window.location.href);
            let isLeadSent = rawURL.searchParams.get('lead') && rawURL.searchParams.get('lead') === 'sent';
            let leadGenBrokerType = rawURL.searchParams.get('broker');

            if (isLeadGenAssessment) {
              const assessmentID = leadGen.elements.assessment_id.value;
              const completedAssessments = this.tools.customerActivity?.basic?.completedAssessments || [];

              const flag = completedAssessments.find((id) => id === assessmentID);
              isLeadSent = !!flag;

              data['articleDetailHeader']['trustedExpertCta'] = {
                url: cta.url,
                text: cta.label,
              };
            }

            const leadGenType = leadGen.elements.broker_type.value[0].codename;
            const healthcare = this.tools.customerActivity?.healthcare;
            const finances = this.tools.customerActivity?.finances;

            if ((leadGenType === 'medicare' && !healthcare?.hasOwnProperty('medicareBrokerContactInfoShareConsent')) || (leadGenType === 'annuities' && !finances?.hasOwnProperty('annuitiesConsent'))) {
              data['leadGen'] = {
                eyebrow: leadGen.elements.eyebrow.value,
                heading: leadGen.elements.headline.value,
                intro: leadGen.elements.description.value,
                cta,
                photo: {
                  src: leadGen.elements.left_image.value[0].url,
                  alt: leadGen.elements.left_image.value[0].description,
                },
                icon,
              };
            }

            // lead gen confirmed
            const rawBody = elements.expert_contact_description.value;
            const div = document.createElement('div');
                  div.innerHTML = rawBody;

            const links = Array.from(div.querySelectorAll('a[data-item-id]'));

            const urls = await Promise.all(links.map((link) => {
              const id = link.getAttribute('data-item-id');
              return this.kontent.getItem(null, { 'system.id': id });
            })).then(responses => {
              return responses.map((response) => {
                const slug = response.items[0].elements.url.value;
                return `${this.tools.rootURL}/resource/${slug}`;
              });
            });

            links.forEach((link, index) => {
              const text = link.innerHTML;
              link.setAttribute('href', urls[index]);
              link.innerHTML = `<span>${text}</span>`;
            });

            // breakdown healthcare
            data['broker'] = healthcare.broker ? healthcare.broker : '';
            data['isShip'] = healthcare.medicareIsShip;

            if (leadGenBrokerType === 'medicare') {
              if (healthcare.broker || healthcare.medLeadDuplicate) {
                let brokerType = healthcare.broker ? healthcare.broker.toLowerCase() : healthcare.medLeadDuplicate.toLowerCase();
                switch(brokerType) {
                  case "no broker":
                    // If user declines information consent
                    if(!healthcare.medicareBrokerContactInfoShareConsent) {
                      let rawBodyNoConsent = this.tools.rawConfig?.elements.no_consent_description?.value;
                      let divNoConsent = document.createElement('div');
                          divNoConsent.innerHTML = rawBodyNoConsent;
                      let bodyNoConsent = divNoConsent.innerHTML;

                      data['leadGenConfirmed'] = {
                        show: isLeadSent,
                        noCheck: true,
                        headline: this.tools.rawConfig?.elements.no_consent_headline?.value,
                        body:   bodyNoConsent
                      }
                    } else {
                      let rawBodyNoBroker = elements.no_broker_description.value;
                      let divNoBroker = document.createElement('div');
                          divNoBroker.innerHTML = rawBodyNoBroker;
                      let bodyNoBroker = divNoBroker.innerHTML;

                      data['leadGenConfirmed'] = {
                        show: isLeadSent,
                        noCheck: true,
                        headline: elements.no_broker_headline?.value,
                        body:   bodyNoBroker,
                        phone_number: {
                          eyebrow: elements.no_broker_phone_number_eyebrow?.value,
                          value: elements.no_broker_phone_number?.value,
                        },
                        schedule: {
                          eyebrow: elements.no_broker_phone_schedule_eyebrow?.value,
                          value: {
                            text: elements.no_broker_schedule_link_text?.value,
                            url: elements.no_broker_schedule_link_url?.value,
                          },
                        },
                      };
                    }
                    break;

                  case "waiting":
                    if(!healthcare.medicareBrokerContactInfoShareConsent) {
                      let rawBodyNoConsent = this.tools.rawConfig?.elements.no_consent_description?.value;
                      let divNoConsent = document.createElement('div');
                          divNoConsent.innerHTML = rawBodyNoConsent;
                      let bodyNoConsent = divNoConsent.innerHTML;

                      data['leadGenConfirmed'] = {
                        show: isLeadSent,
                        noCheck: true,
                        headline: this.tools.rawConfig?.elements.no_consent_headline?.value,
                        body:   bodyNoConsent
                      }
                    } else {
                      data['leadGenConfirmed'] = {
                        show: isLeadSent,
                      }
                    }
                    break;

                  default:
                    // If user declines information consent
                    if(!healthcare.medicareBrokerContactInfoShareConsent) {
                      let rawBodyNoConsent = this.tools.rawConfig?.elements.no_consent_description?.value;
                      let divNoConsent = document.createElement('div');
                          divNoConsent.innerHTML = rawBodyNoConsent;
                      let bodyNoConsent = divNoConsent.innerHTML;

                      data['leadGenConfirmed'] = {
                        show: isLeadSent,
                        noCheck: true,
                        headline: this.tools.rawConfig?.elements.no_consent_headline?.value,
                        body:   bodyNoConsent
                      }
                    } else {
                      let brokerInfo = brokers[brokerType];
                      let rawBodyBroker = brokerInfo.expert_contact_description.value;
                      let divBroker = document.createElement('div');
                          divBroker.innerHTML = rawBodyBroker;
                      let bodyBroker = divBroker.innerHTML;

                      data['leadGenConfirmed'] = {
                        show: isLeadSent,
                        noCheck: false,
                        headline: brokerInfo.expert_contact_headline?.value,
                        body: bodyBroker,
                        phone_number: {
                          eyebrow: brokerInfo.expert_contact_phone_number_eyebrow?.value,
                          value: brokerInfo.expert_contact_phone_number?.value,
                        },
                        schedule: {
                          eyebrow: brokerInfo.expert_contact_phone_schedule_eyebrow?.value,
                          value: {
                            text: brokerInfo.expert_contact_schedule_link_text?.value,
                            url: brokerInfo.expert_contact_schedule_link_url?.value,
                          },
                        },
                      };
                    }
                }
              } else if (!healthcare.broker) {
                let rawBodyNoConsent = this.tools.rawConfig?.elements.no_consent_description?.value;
                let divNoConsent = document.createElement('div');
                    divNoConsent.innerHTML = rawBodyNoConsent;
                let bodyNoConsent = divNoConsent.innerHTML;

                data['leadGenConfirmed'] = {
                  show: isLeadSent,
                  noCheck: true,
                  headline: this.tools.rawConfig?.elements.no_consent_headline?.value,
                  body:   bodyNoConsent
                };
              } else if (healthcare.medicareIsShip) {
                let brokerInfo = brokers['ship'];
                let rawBodyBroker = brokerInfo.expert_contact_description.value;
                let divBroker = document.createElement('div');
                    divBroker.innerHTML = rawBodyBroker;
                let bodyBroker = divBroker.innerHTML;

                data['leadGenConfirmed'] = {
                  show: isLeadSent,
                  noCheck: false,
                  headline: brokerInfo.expert_contact_headline?.value,
                  body: bodyBroker,
                  phone_number: {
                    eyebrow: brokerInfo.expert_contact_phone_number_eyebrow?.value,
                    value: brokerInfo.expert_contact_phone_number?.value,
                  },
                  schedule: {
                    eyebrow: brokerInfo.expert_contact_phone_schedule_eyebrow?.value,
                    value: {
                      text: brokerInfo.expert_contact_schedule_link_text?.value,
                      url: brokerInfo.expert_contact_schedule_link_url?.value,
                    },
                  },
                };
              } else {
                let rawBodyBroker = elements.expert_contact_description.value;
                let divBroker= document.createElement('div');
                    divBroker.innerHTML = rawBodyBroker;
                let bodyBroker = divBroker.innerHTML;

                data['leadGenConfirmed'] = {
                  show: isLeadSent,
                };
              }
            } else if (leadGenBrokerType === 'annuities') {
              let rawBodyNoConsent = this.tools.rawConfig?.elements.no_consent_description?.value;
              let divNoConsent = document.createElement('div');
                  divNoConsent.innerHTML = rawBodyNoConsent;
              let bodyNoConsent = divNoConsent.innerHTML;

              if (finances.hasOwnProperty('annuitiesConsent')) {
                if (finances.annuitiesConsent) {
                  // true
                  data['leadGenConfirmed'] = {
                    show: isLeadSent,
                    noCheck: false,
                    headline: elements.expert_contact_headline.value,
                    body: elements.expert_contact_description.value,
                    phone_number: {
                      eyebrow: elements.expert_contact_phone_number_eyebrow.value,
                      value: elements.expert_contact_phone_number.value,
                    },
                    schedule: {
                      eyebrow: elements.expert_contact_phone_schedule_eyebrow.value,
                      value: {
                        text: elements.expert_contact_schedule_link_text.value,
                        url: elements.expert_contact_schedule_link_url.value,
                      },
                    },
                  };
                } else {
                  // false (no consent)
                  data['leadGenConfirmed'] = {
                    show: isLeadSent,
                    noCheck: true,
                    headline: this.tools.rawConfig?.elements.no_consent_headline?.value,
                    body:   bodyNoConsent
                  };
                }
              } else {
                // false (empty) (no api response)
                data['leadGenConfirmed'] = {
                  show: isLeadSent,
                  noCheck: true,
                  headline: this.tools.rawConfig?.elements.no_consent_headline?.value,
                  body:   bodyNoConsent
                };
              }
            }
          }
        }
      }
    }

    return data;
  }
}
