import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataLayerService } from '@services/data-layer.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { RebaDataService } from '@services/planner/reba-data.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserService } from '@services/planner/user.service';
import { UtilityService } from '@services/planner/utility.service';

@Component({
  selector: 'ncoa-account-login',
  templateUrl: './account-login.component.html',
  styleUrls: ['./account-login.component.scss']
})
export class AccountLoginComponent implements OnInit {

  @Output() showErrorModal: EventEmitter<any> = new EventEmitter();
  @Output() showLoadingModal: EventEmitter<any> = new EventEmitter();

  @Input() backCTA: {
    url: string;
    text: string;
  };

  @Input() eyebrow: string;
  @Input() title: string;
  @Input() desc: string;

  errorMessage: string = '';

  email: {
    value: string;
    status: {
      code: string;
      message: string;
    };
  } = {
    value: '',
    status: {
      code: '',
      message: '',
    },
  };

  password: {
    value: string;
    status: {
      code: string;
      message: string;
    };
  } = {
    value: '',
    status: {
      code: '',
      message: '',
    },
  };

  @Input() forgotPassword: {
    url: string;
    text: string;
  };

  @Input() signUp: {
    url: string;
    text: string;
  };

  constructor(
    private utilityService: UtilityService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private cas: CustomerActivityService,
    private toolsService: ToolsPrimaryConfigService,
    private dataLayerService: DataLayerService,
    private reba: RebaDataService,
  ) {
    // this.toolsService.getConfig();
  }

  ngOnInit(): void {
  }

  // either go back to a checkup or to the home page
  goBack(e){
    if(localStorage.getItem('lastPage')){
      window.location.href = localStorage.getItem('lastPage');
      return;
    }
    else{
      window.location.href = this.backCTA.url;
    }
  }

  onSubmit(e): void {
    e.preventDefault();

    if (this.email.value.trim().length === 0 ||
        (this.email.value.trim().length > 0 &&
          !this.utilityService.isEmailValid(this.email.value))) {
      this.email = {
        ...this.email,
        status: {
          code: 'error',
          message: 'Please enter a valid email address',
        },
      };
      this.accessibilityPrompt();
    } else if (this.email.value.trim().length > 0 &&
      this.utilityService.isEmailValid(this.email.value)) {
        this.email = {
          ...this.email,
          status: {
            code: '',
            message: '',
          },
        };
    }

    if (this.password.value.trim().length === 0) {
      this.password = {
        ...this.password,
        status: {
          code: 'error',
          message: 'Your password is incorrect',
        },
      };
      this.accessibilityPrompt();
    } else {
      this.password = {
        ...this.password,
        status: {
          code: '',
          message: '',
        },
      };
    }

    if (this.email.status.code !== 'error' &&
        this.password.status.code !== 'error') {

      this.showLoadingModal.emit();

      this.userService.signIn(this.email.value, this.password.value)
          .subscribe(() => {
            if (this.userService.authSet()) {

              this.reba.getScreeningHistory().subscribe(resp => {
                const screeningAnswers = JSON.stringify(resp.screeningAnswers ?? {});
                localStorage.setItem('screeningAnswers', screeningAnswers);
              });



              this.cas.updateActivity({'userAccountEmail':this.email.value}).subscribe(() => {});
              this.cas.announceLogin().subscribe(() => {
                this.cas.getActivity().subscribe((data) => {
                  // save falls risk score to local storage
                  localStorage.setItem('fallsRisk', JSON.stringify(data.fallsRisk));
                  const isCaseManager = data.basic.isCaseManager;
                  localStorage.setItem('isCaseManager', isCaseManager ? 'true' : 'false');
                  let url = this.route.snapshot.queryParamMap.get('url');

                  this.dataLayerService.push({
                    'event': 'userAccount',
                    'formAction': 'login',
                    'formStatus': 'success'
                  });

                  this.goBack(e);
                });

              });
            }
          }, (error) => {
            this.showLoadingModal.emit();

            const errorMsg = 'Your username or password is incorrect. Your account will be locked after 5 incorrect attempts. Please contact AgeWellPlanner@ncoa.org if further assistance is required.';

            if (error.status === 401) {
              if (error.error.message !== 'Incorrect username or password.') {
                this.showErrorModal.emit();
              } else {
                this.email = {
                  ...this.email,
                  status: {
                    code: 'error',
                    message: '',
                  },
                };

                this.password = {
                  ...this.password,
                  status: {
                    code: 'error',
                    message: errorMsg,
                  },
                };

                this.accessibilityPrompt();
              }
            }else if (error.status === 404) {
              if (error.error.name !== 'UserNotFoundException') {
                this.showErrorModal.emit();
              } else {
                this.email = {
                  ...this.email,
                  status: {
                    code: 'error',
                    message: '',
                  },
                };

                this.password = {
                  ...this.password,
                  status: {
                    code: 'error',
                    message: errorMsg,
                  },
                }
                this.accessibilityPrompt();
              }
            }else{
              this.dataLayerService.push({
                'event': 'userAccount',
                'formAction': 'login',
                'formStatus': 'fail'
              });
            }
          })
    }
  }

  accessibilityPrompt(){
    const isEmailError = this.email.status.code === 'error';
    const isPasswordError = this.password.status.code === 'error';

    if(isEmailError && isPasswordError){
      if(this.email.status.message === this.password.status.message){
        this.handleErrorMessageChange(this.email.status.message,'email')
      }else{
        this.handleErrorMessageChange(this.email.status.message + ' and ' + this.password.status.message,'email')
      }
    }else if(isEmailError){
      this.handleErrorMessageChange(this.email.status.message,'email')
    }else if(isPasswordError){
      this.handleErrorMessageChange(this.password.status.message,'password')
    }else{
      this.errorMessage = '';
    }
  }

  handleErrorMessageChange(newMessage, focusTo){
    document.getElementById(focusTo).focus();
    setTimeout(()=>{
      if(this.errorMessage !== ''){
        this.errorMessage = newMessage;
        setTimeout(()=>{
          this.errorMessage = ''
        },1000);
      }else{
        this.errorMessage = newMessage;
      }
    },100);
  }

}
