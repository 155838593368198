import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TimelineLite } from 'gsap';
import dayjs from 'dayjs';

@Component({
  selector: 'ncoa-custom-list-header',
  templateUrl: './custom-list-header.component.html',
  styleUrls: ['./custom-list-header.component.scss'],
  encapsulation: ViewEncapsulation.None
  // host: {
  //   '(window:resize)': 'onResize($event)',
  // }
})
export class CustomListHeaderComponent implements OnInit, OnChanges {
  @Input() searchKey?: string;
  @Input() totalItems ?: number;
  @Input() placeholder?: string;
  @Input() hideSearchBar: boolean;
  @Input() filters: any[];
  @Input() filterType: string = 'default';
  @Input() showFilterOption: boolean = false;
  @Input() dateFilterChangeHandler?: (any);
  @Input() clearQueryHandler?: (any);
  @Input() searchBarLink?: string;
  @Input() searchTitle?: string;
  @Input() filterByDate?: string[] = [''];
  @Input() filterYears?: Set<number>;
  @Input() resourceTitle?: string = 'Resources';
  @Input() marginTop?: number = null; 

  @ViewChild('dateFilterSelect') dateFilterSelect?: any;

  @Output() updateSelectedFilters: EventEmitter<any> = new EventEmitter();
  @Output() updateFilterYear: EventEmitter<number> = new EventEmitter();
  @Output() updateFilterMonth: EventEmitter<number> = new EventEmitter();
  @Output() searchContent: EventEmitter<any> = new EventEmitter();

  input: string;
  searchAction: string = '/search';
  searchMethod: string = 'POST';

  isFilterOpen: boolean = false;
  filterCount: number = 0;
  filterIndicator: string = '+ ';
  filterTextToggle: string = 'Show Filters';
  filtersApplied: any[] = [];
  filterMonth : number = null;

  applyAllFilters: boolean = false;

  previousSize: number = 0;

  constructor() {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if( changes.totalItems ) {
      const _searchHeading = <HTMLElement> document.querySelector('.list-header--heading');
      if ( _searchHeading ) {
        _searchHeading.focus();
      }
    }
  }

  toggleFilters( _element ): void {
    _element.preventDefault();

    const _filters: HTMLElement = document.querySelector('.list-filter--body');
    _filters.classList.toggle('is-open');

    if (_filters.classList.contains('is-open')) {
      // show filters
      _filters.style.overflow = 'visible';

      new TimelineLite()
        .to('.list-filter--body', {
          display: 'block',
          duration: 0.1,
        })
        .to('.list-filter--body', {
          height: 'auto',
          paddingTop: window.innerWidth < 1024 ? 25 : 29,
          paddingBottom: window.innerWidth < 1024 ? 25 : 20,
          paddingLeft: window.innerWidth < 1024 ? 0 : 20,
          paddingRight: window.innerWidth < 1024 ? 0 : 20,
          marginTop: window.innerWidth < 0 ? 0 : 15,
          pointerEvents: 'all',
          borderBottomWidth: 1,
          duration: 0.4,
        })
        .to([
          '.list-filter--body .button-group',
          '.list-filter--body .filter-group',
          '.list-filter--body .list-filter--body__item',
        ], {
          opacity: 1,
          duration: 0.2,
        });

      this.filterTextToggle = 'Hide Filters';
      this.filterIndicator = '- ';
      this.isFilterOpen = true;
    } else {
      // hide filters
      new TimelineLite()
        .to([
          '.list-filter--body .button-group',
          '.list-filter--body .filter-group',
          '.list-filter--body .list-filter--body__item',
        ], {
          opacity: 0,
          duration: 0.2,
        })
        .to('.list-filter--body', {
          height: 0,
          paddingTop: 0,
          paddingBottom: 0,
          marginTop: 0,
          pointerEvents: 'none',
          borderBottomWidth: 0,
          overflow: 'hidden',
          duration: 0.4,
        })
        .to('.list-filter--body', { display: 'none', duration: 0.1 });

      this.filterTextToggle = 'Show Filters';
      this.filterIndicator = '+ ';
      this.isFilterOpen = false;
    }
  }

  toggleCheck(index, subindex, event): void {
    this.filters[index].options[subindex].isChecked = !this.filters[index].options[subindex].isChecked;

    if (this.filters[index].options[subindex].isChecked) {
      this.filterCount += 1;
    } else {
      this.filterCount -= 1;
      this.filterCount = this.filterCount < 0 ? 0 : this.filterCount;
    }
  }

  toggleFilterCheckbox(event): void {
    this.applyAllFilters = !this.applyAllFilters;
  }

  onYearFilterSelect($event){
    if(!isNaN($event)){
    const year = parseInt($event);
    this.updateFilterYear.emit(year);
    return;
    }
    this.updateFilterYear.emit(null);
  }

  onMonthFilterSelect($event){
    if(!isNaN($event)){
    const month = parseInt($event);
    this.updateFilterMonth.emit(month);
    return;
    }
    this.updateFilterMonth.emit(null);
  }

  toggleAllFilters(value, event): void {
    this.filterCount = 0;

    this.filters.forEach((filter, index) => {
      if (window.innerWidth < 1024) {
        const filterGroup: HTMLElement = document.querySelector(`#filter-accordion-${index}`);
        const fieldGroups: any[] = Array.from(filterGroup.querySelectorAll('.field-group'));

        if (value) {
          new TimelineLite()
            .to(filterGroup, {
              height: 'auto',
              marginTop: 20,
              pointerEvents: 'all',
              duration: 0.2,
            })
            .to(fieldGroups, { opacity: 1, duration: 0.2 })
            .eventCallback('onComplete', () => {
              this.filters[index].isExpanded = true;
            });
        } else {
          new TimelineLite()
            .to(fieldGroups, { opacity: 0, duration: 0.2 })
            .to(filterGroup, {
              height: 0,
              marginTop: 0,
              pointerEvents: 'none',
              duration: 0.2,
            })
            .eventCallback('onComplete', () => {
              this.filters[index].isExpanded = false;
            });
        }
      }

      filter.options.forEach((option, subindex) => {
        this.filters[index].options[subindex].isChecked = value;

        if (this.filters[index].options[subindex].isChecked) {
          this.filterCount += 1;
        } else {
          this.filterCount -= 1;
          this.filterCount = this.filterCount < 0 ? 0 : this.filterCount;
        }
      });
    });
  }

  toggleGroupFilter(index, event): void {
    if (window.innerWidth < 1024) {
      const { isExpanded } = this.filters[index];
      const filterGroup: HTMLElement = document.querySelector(`#filter-accordion-${index}`);
      const fieldGroups: any[] = Array.from(filterGroup.querySelectorAll('.field-group'));

      if (!isExpanded) {
        new TimelineLite()
          .to(filterGroup, {
            height: 'auto',
            marginTop: 20,
            pointerEvents: 'all',
            duration: 0.2,
          })
          .to(fieldGroups, { opacity: 1, duration: 0.2 })
          .eventCallback('onComplete', () => {
            this.filters[index].isExpanded = true;
          });
      } else {
        new TimelineLite()
          .to(fieldGroups, { opacity: 0, duration: 0.2 })
          .to(filterGroup, {
            height: 0,
            marginTop: 0,
            pointerEvents: 'none',
            duration: 0.2,
          })
          .eventCallback('onComplete', () => {
            this.filters[index].isExpanded = false;
          });
      }
    }
  }

  onDateFilterChange(date) {
    if ( typeof this.dateFilterChangeHandler === 'function' ) {
      this.dateFilterChangeHandler(dayjs(date).format('YYYY-MM-DD'));
    }
  }

  onInputChange(input = null) {
    this.input = input;

    if ( !this.input && typeof this.clearQueryHandler === 'function' ) {
      // Clear date filter select
      if ( this.dateFilterSelect?.nativeElement ) {
        this.dateFilterSelect.nativeElement.value = 'forwards';
      }

      this.clearQueryHandler();
    }
  }

  onSubmit(): void {
    this.searchContent.emit(this.input);
  }

  applyFilter(event): void {
    this.filtersApplied = [];

    const _filters: HTMLElement = document.querySelector('.list-filter--body');
    _filters.classList.toggle('is-open');

    if (_filters.classList.contains('is-open')) {
      // show filters
      _filters.style.overflow = 'visible';

      new TimelineLite()
        .to('.list-filter--body', { display: 'block', duration: 0.1 })
        .to('.list-filter--body', {
          height: 'auto',
          paddingTop: window.innerWidth < 1024 ? 25 : 29,
          paddingBottom: window.innerWidth < 1024 ? 25 : 0,
          marginTop: window.innerWidth < 1024 ? 0 : 15,
          pointerEvents: 'all',
          borderBottomWidth: 1,
          duration: 0.4,
        })
        .to([
          '.list-filter--body .button-group',
          '.list-filter--body .filter-group',
          '.list-filter--body .list-filter--body__item',
        ], {
          opacity: 1,
          duration: 0.2,
        });

      this.filterTextToggle = 'Hide Filters';
      this.filterIndicator = '- ';
      this.isFilterOpen = true;
    } else {
      // hide filters

      new TimelineLite()
        .to([
          '.list-filter--body .button-group',
          '.list-filter--body .filter-group',
          '.list-filter--body .list-filter--body__item',
        ], {
          opacity: 0,
          duration: 0.2,
        })
        .to('.list-filter--body', {
          height: 0,
          paddingTop: 0,
          paddingBottom: 0,
          marginTop: 0,
          pointerEvents: 'none',
          borderBottomWidth: 0,
          overflow: 'hidden',
          duration: 0.4,
        })
        .to('.list-filter--body', { display: 'none', duration: 0.1 });

      this.filterTextToggle = 'Show Filters';
      this.filterIndicator = '+ ';
      this.isFilterOpen = false;
    }

    this.filters.forEach(group => {
      const dummy = group.options.filter(option => option.isChecked);
      if (dummy.length > 0) {
        this.filtersApplied.push({
          taxonomy: group.taxonomy,
          values: dummy.map(({ value }) => value),
        });
      }
    });

    this.updateSelectedFilters.emit({ filtersApplied: this.filtersApplied, applyAllFilters: this.applyAllFilters });
  }

  toggleFocus(element): void {
    const parent = element.parentElement;
    parent.classList.add('focus');
  }

  toggleBlur(element): void {
    const parent = element.parentElement;
    parent.classList.remove('focus');
  }

  onDateFilterSelect(dateFilter) {
    if ( typeof this.dateFilterChangeHandler === 'function' ) {
      this.dateFilterChangeHandler(dateFilter);
    }
  }
  filterMonths = [
    { value: 0, label: 'January' },
    { value: 1, label: 'February' },
    { value: 2, label: 'March' },
    { value: 3, label: 'April' },
    { value: 4, label: 'May' },
    { value: 5, label: 'June' },
    { value: 6, label: 'July' },
    { value: 7, label: 'August' },
    { value: 8, label: 'September' },
    { value: 9, label: 'October' },
    { value: 10, label: 'November' },
    { value: 11, label: 'December' },
  ];
}
