import { Directive, ElementRef, Input } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';

const SELECTOR = '[ncoa-engageable]';

/**
 * Manages component engagement for tracking (AWAWR-632)
 */
@Directive({
  selector: SELECTOR
})
export class EngageableDirective {
  intersectionObserver: any;
  intersected: boolean = false;
  element: Element;

  @Input('ncoa-engageable') order: any;
  @Input('ncoa-engageable-name') name: any;

  constructor(
    private dataLayerService: DataLayerService,
    el: ElementRef
  ) {
    this.element = el.nativeElement;

    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      this.onLoadIE();
      this.bindScrollIE();
    } else {
      this.intersectionObserver = new (window as any).IntersectionObserver(this.onIntersect.bind(this));
      this.intersectionObserver.observe(this.element);
    }
  }

  isInViewport(element): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  onLoadIE() {
    window.addEventListener('load', () => {
      if (this.isInViewport(this.element)) {
        this.intersected = true;
        this.fireEngagementTracking();
      }
    })
  }

  bindScrollIE() {
    window.addEventListener('scroll', () => {
      if (this.isInViewport(this.element)) {
        this.intersected = true;
        this.fireEngagementTracking();
      }
    });
  }

  onIntersect(intersections) {
    intersections.some(intersection => {
      if ( intersection.isIntersecting ) {
        this.intersected = true;
        this.intersectionObserver.disconnect();

        this.fireEngagementTracking();
        return true;
      }
    });
  }

  fireEngagementTracking() {
    const firstChildClasses = this.element.firstElementChild !== null ? this.element.firstElementChild.classList : [];
    const className = (this.name !== '' && this.name !== undefined)
      ? this.name
      : Array.from(firstChildClasses).find(className => className !== 'component');
    const order = parseInt(this.order);

    this.dataLayerService.push({
      componentName: className,
      componentOrder: order
    });
  }
}
