import { Injectable } from '@angular/core';
import { Resolve, ActivatedRoute } from '@angular/router';
import { HomepageService } from '@services/homepage.service';
import { GlobalService } from '@services/global.service';

@Injectable({
  providedIn: 'root'
})
export class HomeResolverService implements Resolve<any> {

  constructor(
    private homepageService: HomepageService,
    private globalService: GlobalService,
    private activeRoute: ActivatedRoute
  ) { }

  async resolve()  {
    const homepagePreviewSlug = this.getPreviewSlug();
    this.globalService.saveCurrentURL('/');

    const [ homepageData ] = await Promise.all([
      this.homepageService.getData(homepagePreviewSlug), // planned giving shit request
      this.globalService.loadGlobalComponentsData(),
    ]);
    return homepageData;
  }

  getPreviewSlug() {
    return (/^preview/.test(this.activeRoute.routeConfig?.path) && this.activeRoute.snapshot.params.slug) ?
      this.activeRoute.snapshot.params.slug :
      null;
  }
}
