<div class="component component--topic component--topic--{{ theme }}" *ngIf="topic.links.length !== 0">
  <div class="wrapper">
    <h2 class="topic--heading"><b>More on</b> {{ topic.heading }}:</h2>
    <nav class="topic--link">
      <ul>
        <li class="topic--link__item" *ngFor="let item of topic.links">
          <div class="topic--link__item--link">
            {{ item.text }}
            <a [href]="item.url" attr.title="Explore more about {{ item.text }}">
              <span [attr.aria-hidden]="true">Explore More</span>
              <i [attr.aria-hidden]="true"></i>
            </a>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</div>
