<ncoa-template-default>
  <div
    *ngFor="let component of components; index as order"
    [ngSwitch]="component?.componentType"
  >
    <ncoa-standard-page-header
      *ngSwitchCase="'header'"
      [image]="component.primary_image.value[0]"
      [headline]="component.title.value"
      [description]="component.description.value"
      ncoa-engageable="{{ order }}"
    ></ncoa-standard-page-header>

    <ncoa-special-pathing
      *ngSwitchCase="'specialPathing'"
      [paths]="component.specialPaths"
      ncoa-engageable="{{ order }}"
    ></ncoa-special-pathing>

    <ncoa-feature-page-block
      *ngSwitchCase="componentTypes.featured_page_block"
      [image]="component.image"
      [headline]="component.headline"
      [description]="component.description"
      [type]="component.type"
      [background]="component.background"
      [audiences]="component.audiences"
      [url]="component.url"
      [orientation]="component.orientation"
      [blockCTA]="component.blockCTA"
      ncoa-engageable="{{ order }}"
    ></ncoa-feature-page-block>

    <ncoa-featured-tool-breaker
      *ngSwitchCase="componentTypes.featured_tool_breaker"
      [variation]="component.variation"
      [eyebrow]="component.eyebrow"
      [headline]="component.headline"
      [description]="component.description"
      [image]="component.image"
      [cta]="component.cta"
      ncoa-engageable="{{ order }}"
    ></ncoa-featured-tool-breaker>

    <ncoa-content-package
      *ngSwitchCase="componentTypes.content_package"
      [title]="component.title"
      [articles]="component.articles"
      ncoa-engageable="{{ order }}"
    ></ncoa-content-package>

    <ncoa-donate
      *ngSwitchCase="componentTypes.call_to_donate"
      [heading]="component.heading"
      [description]="component.description"
      [form_desc]="component.form_desc"
      [background]="component.photo"
      [backgroundCaption]="component.caption"
      ncoa-engageable="{{ order }}"
    ></ncoa-donate>

    <ncoa-external-form 
    [component]="component" 
    *ngSwitchCase="componentTypes.external_form" 
    ncoa-engageable="{{ order }}" 
    ></ncoa-external-form>
    
    <ncoa-newsletter
      *ngSwitchCase="componentTypes.newsletter_signup_block"
      [heading]="component.heading"
      [description]="component.description"
      [background]="component.background"
      [backgroundCaption]="component.backgroundCaption"
      [mobilebackground]="component.mobilebackground"
      [width]="component.width"
      [height]="component.height"
      ncoa-engageable="{{ order }}"
    ></ncoa-newsletter>

    <ncoa-subcategory-pathing
      *ngSwitchCase="componentTypes.category_pathing"
      [headline]="component.headline"
      [page]="'standard'"
      [categories]="component.links"
      ncoa-engageable="{{ order }}"
    ></ncoa-subcategory-pathing>
  </div>
</ncoa-template-default>
