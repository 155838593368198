import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Input, TemplateRef, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToDo, ToDoAction } from '@components/planner/plan/to-do/to-do.component';
import { ContentItem } from '@kentico/kontent-delivery';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { CaseManagerService, ClientInfo, CmTodo } from '@services/planner/case-manager.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { RebaDataService } from '@services/planner/reba-data.service';
import { QuestionAnswer } from '../plan/plan.component';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-client-screening',
  templateUrl: './client-screening.component.html',
  styleUrls: ['./client-screening.component.scss'],
})
export class ClientScreeningComponent implements OnInit {

  @Input() questionsAnswers: Map<string, QuestionAnswer> = new Map<string, QuestionAnswer>();
  private throttler: Subject<string> = new Subject<string>();
  questionsArray: any[] = [];

  isCompleted: any;

  @Output() onBackButton: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private cmService: CaseManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private reba: RebaDataService,
    private cas: CustomerActivityService,
  ) {


  }

  ngOnInit(): void {
    this.convertQuestionMapToArray();
  }

  convertQuestionMapToArray() {
    const arr = Array.from(this.questionsAnswers.values());
    arr.forEach((item : QuestionValue) => {
      const options: any = item.answerGroup.answers;
      if (!item.answerGroup.answers) {
        return;
      }
      for (const option of options) {

        if (option.value === item.answer) {
          option.checked = true;
        }
        // check if answer is string[]
        if (Array.isArray(item.answer)) {
          for (const answer of item.answer) {
            if (option.value === answer) {
              option.checked = true;
            }
          }
        }

      }
      this.questionsArray.push({
        key: item.questionId,
        answerType: item.answerGroup.answerType,
        questionText: item.questionText,
        answer: item.answer ?? '',
        options: options,
      }
      )

    });

  }
  answerChanged(value: string) {
    this.throttler.next(value);
  }

  booleanOption(userInput: string|boolean, answer: string) {
    if(userInput === null || userInput === undefined) {
     return false;
    }
    if(typeof userInput === 'boolean') {
      userInput = userInput === true ? 'true' : 'false';

    }
    return userInput?.toLowerCase() === answer?.toLowerCase();
  }

  backToList(){
    this.onBackButton.emit({
      action: 'backToList',
    });
  }


}

type QuestionValue ={
  answer?: string | string[] | boolean | number;
  answerGroup: AnswerGroup;
  questionId: string;
  questionText: string;
}
type AnswerGroup={
  answerType: string;
  answers: Answer[]
}
type Answer = {
  answerText: string;
  value: string
}
