<div  class="question" [id]="question.key" [formGroup]="group" #div>

    <app-question-label [kenticoKontent]="kenticoKontent" [question]="question"></app-question-label>

    <div [ngSwitch]="question.answerType" *ngIf="{isInvalid: (questionControl?.touched || questionControl?.touched) && questionControl?.errors} as form" >

        <ng-container *ngSwitchCase="'date'" [ngSwitch]="question.key">
            <app-month-and-year-picker *ngSwitchCase="'dob'" [formControlName]="question.key" (dateChangeEvent)="answerChanged(question.key)"></app-month-and-year-picker>
            <input  [readOnly]="question.readonly || isCompleted"  [ngClass]="{'invalid': form.isInvalid}" class="form-field" *ngSwitchDefault [attr.min]="$any(question).min" [attr.max]="$any(question).max" [formControlName]="question.key" [id]="question.key" (ngModelChange)="answerChanged(question.key)" type="date">
        </ng-container>

        <select  [disabled]="isCompleted"  [ngClass]="{'invalid': form.isInvalid}" class="form-field" *ngSwitchCase="'select'" [formControlName]="question.key" (change)="answerChanged(question.key)"  >
            <option class="select-default" [ngValue]="null" >{{kenticoKontent.select_placeholder}}</option>
            <option [disabled]="isCompleted" [value]="option.value" *ngFor="let option of question.options">{{option.text['en'] }}</option>
        </select>

        <!-- <section class="radio-group" *ngSwitchCase="'boolean'">
            <label>
                <input type="radio" [value]="booleanOption(question.options[0].value)" [formControlName]="question.key" (ngModelChange)="answerChanged(question.key)">
                <span>{{question.options[0].text['en'] }}</span>
            </label>
            <label>
                <input type="radio" [value]="booleanOption(question.options[1].value)" [formControlName]="question.key" (ngModelChange)="answerChanged(question.key)">
                <span>{{question.options[1].text['en'] }}</span>
            </label>
        </section> -->

        <section class="radio-group" *ngSwitchCase="'boolean'">
                <div class="container">
                    <input type="radio" [value]="booleanOption(question.options[0].value)" [formControlName]="question.key" [attr.disabled]="isCompleted"
                        (ngModelChange)="answerChanged(question.key)"  />
                    <label>{{question.options[0].text['en'] }}</label>
                </div>
        
                <div class="container">
                    <input type="radio" [value]="booleanOption(question.options[1].value)" [formControlName]="question.key" [attr.disabled]="isCompleted"
                        (ngModelChange)="answerChanged(question.key)" />
                    <label>{{question.options[1].text['en'] }}</label>
                </div>
        </section>

        <app-multi-select [header]="" [min]="$any(question).min" [max]="$any(question).max" *ngSwitchCase="'multiSelect'" [question]="question" [items]="question.options" [formControlName]="question.key" (checkBoxChangeEvent)="answerChanged(question.key)" [readonly]="isCompleted"></app-multi-select>

        <input [readOnly]="question.readonly || isCompleted" [ngClass]="{'invalid': form.isInvalid}" [mask]="$any(question).mask" class="form-field" *ngSwitchCase="'shortText'" type="text" [attr.maxLength]="$any(question).maxLength" [formControlName]="question.key" (blur)="answerChanged(question.key)">

        <textarea [readOnly]="question.readonly || isCompleted" [ngClass]="{'invalid': form.isInvalid}" class="form-field" *ngSwitchCase="'longText'"  [attr.maxLength]="$any(question).maxLength" [formControlName]="question.key" (blur)="answerChanged(question.key)"></textarea>

        <input [readOnly]="question.readonly || isCompleted" [ngClass]="{'invalid': form.isInvalid}" class="form-field" (keydown)="$event.keyCode !== 190 && $event.keyCode !== 189" *ngSwitchCase="'number'" step="1" type="number" [attr.min]="$any(question).min || '0'" [attr.max]="$any(question).max" [id]="question.key" [formControlName]="question.key"  (blur)="answerChanged(question.key)">

        <input [readOnly]="question.readonly || isCompleted" [ngClass]="{'invalid': form.isInvalid}" class="form-field" *ngSwitchCase="'decimal'" step="0.01" type="number" [attr.min]="$any(question).min" [attr.max]="$any(question).max" [id]="question.key" [formControlName]="question.key"  (blur)="answerChanged(question.key)">

        <input [readOnly]="question.readonly || isCompleted" [ngClass]="{'invalid': form.isInvalid}" class="form-field" *ngSwitchCase="'currency'" type="number" [id]="question.key" [formControlName]="question.key" (blur)="answerChanged(question.key)">

    </div>

    <div *ngIf="(questionControl?.touched || questionControl?.touched) && questionControl?.errors" class="question-errors">

        <div *ngFor="let error of questionControl?.errors | keyvalue">

            <ng-container [ngSwitch]="error.key">

                <span *ngSwitchCase="'required'">{{kenticoKontent.required }}</span>

                <span *ngSwitchCase="'max'">{{renderValidationError(kenticoKontent.max , error.value.max)}}</span>

                <span *ngSwitchCase="'min'">{{renderValidationError(kenticoKontent.min , error.value.min)}}</span>

                <span *ngSwitchCase="'maxLength'">{{renderValidationError(kenticoKontent.max_length , error.value.maxLength)}}</span>

                <span *ngSwitchCase="'minLength'">{{renderValidationError(kenticoKontent.min_length , error.value.minLength)}}</span>

                <span *ngSwitchCase="'minSelect'">{{renderValidationError(kenticoKontent.min_select , error.value.minSelect)}}</span>

                <span *ngSwitchCase="'maxSelect'">{{renderValidationError(kenticoKontent.max_select, error.value.maxSelect)}}</span>

                <span *ngSwitchCase="'isPhoneNumber'">{{kenticoKontent.phone_number }}</span>

                <span *ngSwitchCase="'isCurrency'">{{kenticoKontent.currency }}</span>

                <span *ngSwitchCase="'isEmail'">{{kenticoKontent.email }}</span>

                <span *ngSwitchCase="'isZipcode'">{{kenticoKontent.zipcode }}</span>

                <span *ngSwitchCase="'maxDate'">{{renderValidationError(kenticoKontent.max_date , error.value.maxDate)}}</span>

                <span *ngSwitchCase="'minDate'">{{renderValidationError(kenticoKontent.min_date , error.value.minDate)}}</span>

            </ng-container>

        </div>

    </div>

</div>
