import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, Inject, Renderer2 } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-one-up',
  templateUrl: './one-up.component.html',
  styleUrls: ['./one-up.component.scss']
})
export class OneUpComponent implements OnInit {

  @Input() type: string;
  @Input() heading: string;
  @Input() card: any;
  @Input() cta: {
    label: string,
    url: string
  };

  constructor(private dataLayerService: DataLayerService,
    private renderer: Renderer2, ) { }


  trackAnalytics(ev){
    const _element = ev.currentTarget as HTMLAnchorElement;
    const _clickUrl = _element.href || _element.getAttribute('href');

    this.dataLayerService.push({ 
      'event': 'recirc', 
      'url': _clickUrl,
      'componentType': '1-up' },false); 
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
      const self= this;
      if(!document.querySelector('.card .card--cta a').classList.contains('one-up-cta-analytics')){
        document.querySelector('.card .card--cta a').addEventListener('click', function(ev){
          const _element = ev.currentTarget as HTMLAnchorElement;
          const _clickUrl = _element.href || _element.getAttribute('href');
          self.dataLayerService.push({ 
            'event': 'recirc', 
            'url': _clickUrl,
            'componentType': '1-up' },false); 
      })}
      document.querySelector('.card .card--cta a').classList.add('one-up-cta-analytics');
  }

}
