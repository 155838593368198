import { Component, OnInit, Input  } from '@angular/core';
import { Router } from '@angular/router';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss'],
})
export class DonateComponent implements OnInit {

  @Input() heading: string;
  @Input() description: string;
  @Input() form_desc: string;
  @Input() background: string;
  @Input() backgroundCaption: string;
  @Input() formSubmitHandler?: any;
  @Input() responsive?: any;

  donateData = {
    frequency: 'one_time',
    amount: '50'
  };

  customAmount = '';

  constructor(
    private router: Router,
    private dataLayerService: DataLayerService
  ) {}

  handleSelectOption({ target }) {
    const group = target.getAttribute('name');

    // Clear Custom Amount value, if any
    this.customAmount = '';

    // Set Data
    this.donateData[group] = target.value;

    // Fire analytics
    const fieldName = group === 'frequency' ? 'donationFrequency' : 'donationAmountPreselected';
    const value = `${ group === 'amount' ? '$' : ''}` + target.value;

    this.dataLayerService.push({
      event: "gtm.onChange",
      [fieldName]: value
    });
  }

  handleCustomAmount( e: any ) {
    this.donateData.amount = e.target.value;
    this.customAmount = e.target.value;

    const elements = Array.from(document.querySelectorAll('input[name="amount"]'));
    elements.forEach((element: HTMLInputElement) => {
      element.checked = false;
    });

    // Fire analytics
    this.dataLayerService.push({
      event: "gtm.onBlur",
      donationAmountCustom: `$${this.customAmount}`
    });
  }

  handleSubmit() {
    const formErrors = this.validateFormData();

    // Fire analytics
    if ( formErrors.length ) {
      this.dataLayerService.push({
        event: "gtm.onSubmit",
        formErrors: formErrors
      });
    }
    else {
      const amountFieldType = this.customAmount ? 'donationAmountCustom' : 'donationAmountPreselected';
      this.dataLayerService.push({
        event: "gtm.onSubmit",
        [amountFieldType]: `$${this.donateData.amount}`,
        donationFrequency: this.donateData.frequency,
        donateCTA: 'donate now'
      });

      if ( typeof this.formSubmitHandler === 'function') {
        this.formSubmitHandler();
      }

      // Redirect to Donate Page
      // Pass the data
      this.router.navigate(['/page/donate'], { queryParams: { amount: this.donateData.amount, recur: this.donateData.frequency === 'monthly' && 'true' } });
      window.scrollTo(0, 0);

    }
  }

  validateFormData() {
    const formErrors = []

    // Should be a valid amount
    // e.g.: 123.00    123.45   12345.00   123
    if ( !/^[0-9]+(\.[0-9]+)?/.test(this.donateData.amount) || Number(this.donateData.amount) <= 0 ) {
      formErrors.push('Please provide a valid amount');
    }

    return formErrors;
  }

  ngOnInit(): void {
  }

}
