import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { AbstractQuestion } from 'src/app/typings/questions/AbstractQuestion';
import { BooleanQuestion } from 'src/app/typings/questions/BooleanQuestion';
import { CurrencyQuestion } from 'src/app/typings/questions/CurrencyQuestion';
import { DateQuestion } from 'src/app/typings/questions/DateQuestion';
import { DecimalQuestion } from 'src/app/typings/questions/DecimalQuestion';
import { LongTextQuestion } from 'src/app/typings/questions/LongTextQuestion';
import { MultiSelectQuestion } from 'src/app/typings/questions/MultiSelectQuestion';
import { NCOAValidators } from 'src/app/typings/questions/NCOAValidators';
import { NumberQuestion } from 'src/app/typings/questions/NumberQuestion';
import { ShortTextQuestion } from 'src/app/typings/questions/ShortTextQuestion';
import { SingleSelectQuestion } from 'src/app/typings/questions/SingleSelectQuestion';
import { Question } from 'src/app/typings/Screening';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {


  private questionTypes = {
    'number': NumberQuestion,
    'currency': CurrencyQuestion,
    'select': SingleSelectQuestion,
    'date': DateQuestion,
    'boolean': BooleanQuestion,
    'shorttext': ShortTextQuestion,
    'shortText': ShortTextQuestion,
    'longText': LongTextQuestion,
    'decimal': DecimalQuestion,
    'multiSelect': MultiSelectQuestion
  }


  constructor() { }



  public getQuestion(screeningQuestion: Question, disabled: boolean = false) : AbstractQuestion<any>{

    const {answers, validation, answerType} = screeningQuestion.answerGroup;

    if(!(answerType in this.questionTypes)){
      throw new Error(`Unknown type ${answerType}`)
    }

    const question = new this.questionTypes[answerType]() as AbstractQuestion<any>;

    question.options = answers;

    if (screeningQuestion.id === "dob") {
      let date = new Date()
      date.setFullYear(new Date().getFullYear() - 65);
      date.setMonth(0);
      date.setDate(1);
      question.defaultAnswer = date ;
    } else if (screeningQuestion.id === "zipcode") {
       question.readonly = true;
       question.defaultAnswer = screeningQuestion.defaultAnswer;
    } else {
      question.defaultAnswer = screeningQuestion.defaultAnswer;
    }

    question.value = screeningQuestion.defaultAnswer;
    question.hint = screeningQuestion.hint;
    question.required = screeningQuestion.required ?? true;
    question.label = screeningQuestion.text;
    question.key = screeningQuestion.id;
    question.definitions = screeningQuestion.definitions;
    question.fullCondition = screeningQuestion.fullCondition;
    question.disabled = disabled;


    if(question.required){
      question.validators.push(Validators.required)
    }

    // check validations


    if(validation){

      for(const [type, value] of Object.entries(validation) as [string, any]){

        switch(type){
          case 'minDate':{
            question.validators.push(NCOAValidators.minDate(value));
            (question as DateQuestion).min = this.formatDate(value); // force date format to YYYY-MM-DD
            break;
          }
          case 'maxDate':{
            question.validators.push(NCOAValidators.maxDate(value));
            (question as DateQuestion).max = this.formatDate(value); // force date format to YYYY-MM-DD
          }
          case 'maxValue':{
            question.validators.push(Validators.max(value));
            (question as NumberQuestion | DecimalQuestion).max = value;
            break;
          }
          case 'minValue':{
            question.validators.push(Validators.min(value));
            (question as NumberQuestion | DecimalQuestion).min = value;
            break;
          }
          case 'maxLength':{
            question.validators.push(Validators.maxLength(value));
            (question as ShortTextQuestion | LongTextQuestion).maxLength = value;
            break;
          }
          case 'isZipcode':{
            question.asyncValidators.push(NCOAValidators.isZipcode())
            break;
          }
          case 'isEmail':{
            question.validators.push(Validators.email)
            break;
          }
          case 'minSelect':{
            question.validators.push(NCOAValidators.minSelect(value));
            (question as MultiSelectQuestion).min = value;
            break;
          }
          case 'maxSelect':{
            question.validators.push(NCOAValidators.maxSelect(value));
            (question as MultiSelectQuestion).max = value;
            break;
          }
          case 'isPhoneNumber':{
            (question as ShortTextQuestion).mask = '(000) 000-0000';
            break;
          }
          case 'isCurrency':{
            break;
          }
        }

      }

    }


    return question;
  }


  private formatDate(date: string){
    return new Date(date).toISOString().slice(0, 10);
  }


}
