import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectComponent),
    multi: true
  }]
})
export class MultiSelectComponent implements ControlValueAccessor {

  @Input() lang: string = 'en';
  @Input() items: any[];
  @Input() header: string;
  @Input() displayField: string = 'text';
  @Input() dataField: string = 'value';
  @Input() disabled: boolean;
  @Input() readonly: boolean = false;
  @Input() question: any;
  @Input() max: number;
  @Input() min: number;
  @Output() checkBoxChangeEvent = new EventEmitter<string>();

  get selected(): any[]{
    return this.items.filter((item) => item.checked).map((item) => item[this.dataField]);
  }

  private _selected: any[];

  public hitMax: boolean;

  constructor() {
    this.hitMax = false;
    this._selected = [];
  }


  public onChange = (selected: any) => {
    this.items.forEach(item => {
      if(item.value === 'answer_prefer_not_to_answer' || item.value === 'a_prefer_not' || item.value === 'answer_none') {
        if(item.checked) {
          this.disabled = true;
        }
      }
    })
  }

  public onTouched = () => {

  }

  public onCheckboxClicked(item, value){
    this.onTouched();
    item.checked = value;

    if(item.value === 'answer_prefer_not_to_answer' || item.value === 'a_prefer_not' || item.value === 'answer_none') {
      this.disabled = !this.disabled;

      if(item.checked) {
        this.items.forEach(item => {
          if(!(item.value === 'answer_prefer_not_to_answer' || item.value === 'a_prefer_not' || item.value === 'answer_none')) {
            item.checked = false;
          }
        })
      }
    }

    if(this.selected.length > this.max){
      this.hitMax = true;
      item.checked = false;
      return;
    }

    this.hitMax = false;

    this.onChange(this.selected);
    this.checkBoxChangeEvent.emit('');

  }

  writeValue(selected: any[] = []): void {
    if (!this.items) return; // FIX for 'cannot read properties of undefined' on instantiation
    this.items.forEach(item => {
      if((selected ?? []).includes(item[this.dataField])){
        item.checked = true;
        return;
      }
      item.checked = false;
    })
    this.onChange(this.selected);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
