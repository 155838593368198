import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { ArticleService } from '@services/article.service';
import { GlobalService } from '@services/global.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleResolverService implements Resolve<any> {
  constructor(
    private articleService: ArticleService,
    private globalService: GlobalService,
  ) { }

  async resolve(activeRoute: ActivatedRouteSnapshot)  {
    this.globalService.saveCurrentURL(`/${activeRoute.url.join('/')}`);

    const [ data ] = await Promise.all([
      this.articleService.getArticle(activeRoute.params.slug),
      this.globalService.loadGlobalComponentsData(),
    ]);

    return data;
  }
}
