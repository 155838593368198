<div class="component component-recommended-heading">
  <div class="wrapper">
    <div class="recomended-header">
      <div class="recomended-header__content">
        <h3 class="component--heading" *ngIf="heading">{{ heading }}</h3>
        <div class="subheading">
          <h4>{{ subheading}}</h4>
          <a href="#">Edit <span>Answers</span></a>
          <a href="#">Save <span>Answers</span></a>
        </div>
        <p>{{ tags }}</p>
      </div>
      <div class="recomended-header__recent">
        <h2>{{ recentViews.heading }}</h2>
        <ul>
          <li *ngFor="let link of recentViews.links">
            <a [href]="link.url" [attr.aria-label]="'Link to ' + link.label">{{ link.label }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>