import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { image, cta, audiences } from './landing-page-header.types';

@Component({
  selector: 'landing-page-header',
  templateUrl: './landing-page-header.component.html',
  styleUrls: ['./landing-page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageHeaderComponent implements OnInit {
  @Input() image: image;
  @Input() headline: string;
  @Input() description: string;
  @Input() cta: cta;
  @Input() audiences: audiences[];

  constructor() { }

  ngOnInit(): void {
  }

}
