import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { environment } from '@environment';
import { Home } from '@pages/home/home.component';
import { TaxonomyComponent } from '@pages/taxonomy/taxonomy.component';
import { TemporaryLandingPage } from '@pages/temporary-landing/temporary-landing.component';
import { AuthorComponent } from '@pages/author/author.component';
import { SearchComponent } from '@pages/search/search.component';
import { ArticleComponent } from '@pages/article/article.component';
import { ProgramsComponent } from '@pages/programs/programs.component';
import { EventCustomSearchComponent } from '@pages/event-custom-search/event-custom-search.component';
import { StandardPageComponent } from '@pages/standard/standard.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ServerErrorComponent } from './pages/server-error/server-error.component';
import { HomeResolverService } from '@services/home-resolver.service';
import { ArticleResolverService } from '@services/article-resolver.service';
import { AuthorResolverService } from '@services/author-resolver.service';
import { StandardResolverService } from '@services/standard-resolver.service';
import { TaxonomyResolverService } from '@services/taxonomy-resolver.service';
import { SearchResolverService } from '@services/search-resolver.service';
import { GenericResolverService } from '@services/generic-resolver.service';
import { OurSponsorsComponent } from '@pages/our-sponsors/our-sponsors.component';
import { OurSponsorsResolverService } from '@pages/our-sponsors/our-sponsors-resolver.service';


export const routes: Routes =
  (environment as any).prerender === 'landing' ?
  [ { path: '', component: TemporaryLandingPage } ] :
  [
    { path: '', component: Home, resolve: { homeData: HomeResolverService } },
    { path: 'preview/:slug', component: Home, resolve: { homeData: HomeResolverService } },
    { path: 'temporary-landing', component: TemporaryLandingPage },
    { path: 'author/:author', component: AuthorComponent, resolve: { authorData: AuthorResolverService } },
    { path: 'article/:slug', component: ArticleComponent, resolve: { articleData: ArticleResolverService } },
    { path: 'search', component: SearchComponent, resolve: { searchPageData: SearchResolverService } },
    { path: 'ncoa-map', component: ProgramsComponent, resolve: { data: GenericResolverService } },
    { path: 'event-custom-search', component: EventCustomSearchComponent, resolve: { data: GenericResolverService } },
    {
      path: 'page/:slug',
      component: StandardPageComponent,
      resolve: { pageData: StandardResolverService },
      children: [
        {
          path: ':child',
          component: StandardPageComponent
        }
      ]
    },
    { path:'our-sponsors', component: OurSponsorsComponent, resolve: { pageData: OurSponsorsResolverService }},
    { path:'tools/:slug', component: TaxonomyComponent, resolve: { taxonomyData: TaxonomyResolverService } },
    { path: '404', component: NotFoundComponent, resolve: { data: GenericResolverService } },
    { path: '500', component: ServerErrorComponent, resolve: { data: GenericResolverService } },
    { path: '**', component: TaxonomyComponent, resolve: { taxonomyData: TaxonomyResolverService } }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
