<div class="component component-three-up">
  <div class="wrapper">
    <h3 class="component--heading">{{ heading }}</h3>
    <a class="component--link no-print" href="{{ moreLink.url }}" *ngIf="moreLink" (click) = 'trackAnalytics($event)'>
      {{ moreLink.label }}
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1316 0.54771L19.6842 7.25111C19.8947 7.46562 20 7.73375 20 8.00189C20 8.27003 19.8947 8.53816 19.6842 8.75267L13.1316 15.4561C12.7105 15.8851 12.0526 15.8851 11.6316 15.4561C11.2105 15.0271 11.2105 14.3567 11.6316 13.9277L16.4211 9.07443L1.05263 9.07443C0.473684 9.07443 0 8.59179 0 8.00189C0 7.41199 0.473684 6.92935 1.05263 6.92935L16.4211 6.92935L11.6579 2.04927C11.2368 1.62025 11.2368 0.949914 11.6579 0.520628C12.0526 0.118424 12.7105 0.118424 13.1316 0.54771Z" fill="#0B4A5D"/>
      </svg>
    </a>

    <div class="cards" #cardContainer>
      <div class="card-wrapper" *ngFor="let card of cards; let i = index;">
        <ncoa-planner-card
          [index]="i"
          [id]="card.id"
          [linkedID]="card.linkedID"
          [linkedCodename]="card.linkedCodename"
          [eyebrow]="card.eyebrow"
          [type]="card.type"
          [heading]="card.heading"
          [intro]="card.intro"
          [breadcrumb]="card.breadcrumb"
          [cta]="card.cta"
          [variation]="card.variation"
          [theme]="card.theme"
          [photo]="card.photo"
          [background]="card.background"
          [full_desc]="card.full_desc"
          [program_cta]="card.program_cta"
          [full]="card.full"
          (showModal)="showModal($event)"
          [titleAriaLabel]="'Slide ' + (i + 1) + ' of ' + cards.length + '. ' + card.heading"
        >
      </ncoa-planner-card>
      </div>
    </div>
  </div>
</div>

<ncoa-benefit-card-modal
  *ngIf="modalData"
  [title]="modalData.title"
  [eyebrow]="modalData.eyebrow"
  [desc]="modalData.desc"
  [cta]="modalData.cta"
  (onModalClose) = 'onModalClose()'
></ncoa-benefit-card-modal>
