<div role="dialog" id="{{ id }}" class="modal modal--{{type}}" [class.modal--is-open]="isOpen && canShowModal"
  [attr.aria-hidden]="isOpen ? 'false' : 'true'">
  <div class="modal__wrapper" tabindex="0">

    <div class="modal__body" *ngIf="type === 'general'">
      <h1 class="modal__heading">{{ heading }}</h1>
      <div class="modal__description" [innerHtml]="description"></div>
      <div class="modal__cta" *ngIf="cta">
        <a href="{{ cta.url }}" class="modal__cta-btn button" (click)="closeModal()">{{ cta.text }}</a>
      </div>
    </div>

    <div class="modal__container" *ngIf="type === 'newsletter'">
      <ncoa-newsletter [heading]="newsletterData.heading" [description]="newsletterData.description"
        [background]="newsletterData.background" [backgroundCaption]="newsletterData.backgroundCaption"
        [formSubmitHandler]="formSubmitHandler" [newsletterLink]="newsletterData.newsletterLink" 
        [width]="newsletterData.width" [height]="newsletterData.height" [everyActionFormUrl]="newsletterData.everyActionFormUrl" 
         [fieldErrorText]="newsletterData.fieldErrorText"[emailErrorText]="newsletterData.emailErrorText" >
        
      </ncoa-newsletter>
    </div>

    <div class="modal__container" *ngIf="type === 'donate'">
      <ncoa-donate [heading]="donateData.heading" [description]="donateData.description"
        [background]="donateData.background" [backgroundCaption]="donateData.backgroundCaption"
        [form_desc]="donateData.form_desc" [formSubmitHandler]="formSubmitHandler">
      </ncoa-donate>
    </div>


    <div class="modal__container" *ngIf="type === 'courses'">
      <div class="courses">
        <div class="courses--wrapper">
          <h3 class="courses--heading">Heading</h3>
          <div class="courses--content">
            <p>Sample Content</p>
          </div>
        </div>
      </div>
    </div>

    <div class="modal__container" *ngIf="type === 'soe'">
      <div class="modal__body">
        <h1 class="modal__heading">{{ heading }}</h1>
        <div class="modal__flex-container">
          <div class="modal__description">
            <div [innerHtml]="description"></div>
            <br>
            <div [innerHtml]="soeData.soe_description"></div>
          </div>
          <div class="modal__logo" aria-hidden="true">
            <img class= "modal__img" src="{{ backgroundImage.src }}" alt="{{ backgroundImage.caption }}"/>
          </div>
        </div>
        <div class="modal__list" >
          <div class="list-1" 
             [innerHtml] = "soeData.soe_list">
          </div>
        </div>
      </div>
    </div>

    <div class="modal__bg" aria-hidden="true" *ngIf="type === 'general'">
      <img src="{{ backgroundImage.src }}" alt="{{ backgroundImage.caption }}" />
    </div>

    <button class="modal__close" (click)="closeModal()">
      <span>Close</span>
    </button>

  </div>

  <div class="modal__overlay"></div>
</div>
