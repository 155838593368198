import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { GlobalService } from '@services/global.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserService } from '@services/planner/user.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'age-well-planner-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignIn implements OnInit {

  accountLoginComponent: any = null;

  showErrorModal: boolean = false;
  showLoadingModal: boolean = false;

  errorTitle: string = 'Your account has not yet been verified.';
  errorDesc: string = 'Please check your email inbox for the confirmation email we sent. Remember to check your spam if you don’t see it.';
  resendCTA: {
    url: string;
    text: string;
  } = {
    url: '#',
    text: 'Didn\'t receive an email?',
  };

  signInCTA: {
    url: string;
    text: string;
  } = {
    url: '#',
    text: 'Log In',
  };

  constructor(
    private userService: UserService,
    private toolsConfig: ToolsPrimaryConfigService,
    private dataLayerService: DataLayerService,
    private globalService: GlobalService,
  ) {
    this.accountLoginComponent = {
      navCTA: {},
      logo: '',
      backCTA: {},
      eyebrow: '',
      title: '',
      desc: '',
      forgotPassword: {},
      signUp: {}
    };

    if (this.toolsConfig.currentConfig) {
      this.accountLoginComponent = {
        navCTA: this.toolsConfig.currentConfig.navData.mainLogo.cta,
        logo: this.toolsConfig.currentConfig.navData.mainLogo,
        backCTA: {
          url: '/',
          text: 'Exit',
        },
        eyebrow: this.toolsConfig.rawConfig.elements.sign_in_eyebrow.value,
        title: this.toolsConfig.rawConfig.elements.heading.value,
        desc: this.toolsConfig.rawConfig.elements.description.value,
        forgotPassword: {
          url: this.toolsConfig.routes.find(({ type }) => type === 'forgot-password').route,
          text: this.toolsConfig.rawConfig.elements.forgot_password_link_text.value,
        },
        signUp: {
          url: this.toolsConfig.routes.find(({ type }) => type === 'sign-up').route,
          text: this.toolsConfig.rawConfig.elements.sign_up_link_text.value,
        },
      };
    }
  }

  ngOnInit(): void {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        window.location.href = this.toolsConfig.rootURL;
      }
    });

    this.dataLayerService.push({
      clickUrl: location.href,
      pageTitle: document.title || '',
      contentType: 'sign-in',
      pageType: 'sign-in-page',
    },false);


  this.globalService.loadFooter(true);
  }

  toggleErrorModal(): void {
    this.showErrorModal = !this.showErrorModal;

    if (this.showErrorModal) {
      setTimeout(() => {
        const dismissBtn: HTMLButtonElement = document.querySelector('.dismiss-btn');
        if (dismissBtn) {
          dismissBtn.focus();
        }
      }, 400);
    }
  }

  toggleLoadingModal(): void {
    this.showLoadingModal = !this.showLoadingModal;
  }

}
