import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { KontentDeliveryService } from './kontent-delivery.service';
import { UrlService } from './url.service';
import { DataLayerService } from '@services/data-layer.service';
import * as utc from 'dayjs/plugin/utc';
import { RichTextService } from './rich-text-resolver.service';

// Add utc
dayjs.extend(utc);

const API_LIMIT = 1000;
const dateToday = dayjs().format('YYYY-MM-DD');

@Injectable({
  providedIn: 'root'
})
export class EventCustomSearchService {
  // Will store all events (unfiltered)
  originalItems: any[] = [];

  // Will store filtered events (with date and query)
  filteredEvents: any[] = [];

  apiLimit: number = API_LIMIT;
  limit: number = 6;

  contentTypes: any[] = ['ncoa_article_content'];

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private urlService: UrlService,
    private dataLayerService: DataLayerService,
    private router: Router,
    private richTextService: RichTextService,
  ) {}

  async storeAllEvents() {
    // Will contain filtered raw items
    const fetchedItems = [];

    // Will be used in API call iteration whether next iteration is necessary
    let hasMoreItems = true;

    const projection = [
      'audiences', 'categories', 'program_type', 'program_format', 'program_length',
      'program_training', 'program_characteristics', 'event_date_time', 'url', 'display_date',
      'primary_image', 'title', 'program_topic', 'author_url_slug', 'author_headshot', 'author_name',
      'category_page_url', 'header_image', 'parent_page', 'other',
    ];

    while ( hasMoreItems ) {
      // Build out API params
      const params = {
        'system.type[in]': this.contentTypes.join(','),
        'limit': this.apiLimit,
        'includeTotalCount': true,
        'elements': projection.join(','),
        'elements.event_date_time[nempty]': '', // Include only articles with event date
        'order': 'elements.event_date_time[desc]', // Order by event date, descending
        'depth': 1,
        'skip': fetchedItems.length,
      };

      const result = await this.kontentDeliveryService.getItem(null, params);

      // Process raw list
      fetchedItems.push(...this.processRawList(result));

      // Update values for the next iteration
      hasMoreItems = result.pagination.next_page ? true : false;
    }

    // Store all events
    this.originalItems = fetchedItems;
  }

  async getPageData() {
    const currentRpouteSlug = this.router.url.substr(1);

    // // Build out API params
    const params = {
      'system.type': 'template___custom_search',
      'limit': 1,
      'elements.page_slug': currentRpouteSlug,
    };

    // Fetch from API
    const result = await this.kontentDeliveryService.getItem(null, params);

    // Extract necessary data
    const {
      page_title, page_description, hero_image, search_placeholder, page_eyebrow, 
    }: any = this.kontentDeliveryService.extractItemElements(result.items[0]);

    const richText = result.items[0].elements.page_description_rich;
    let description = page_description;
    if(richText.value !=='\u003Cp\u003E\u003Cbr\u003E\u003C/p\u003E') {
      description = richText.value?.length > 0 ?  this.richTextService.resolveRichText(richText): page_description ? page_description : '';
   
    }
    return {
      image: hero_image ? hero_image[0] : null,
      title: page_title,
      description: description,
      placeholder: search_placeholder,
      eyebrow: page_eyebrow,
    }
  }

  processRawList(data) {
    const { items, modular_content } = data;

    return items.map(item => {
      const id = item.system.id;
      const audiences = item.elements.audiences ? item.elements.audiences.value.map(({ codename }) => codename) : [];
      const categories = item.elements.categories ? item.elements.categories.value.map(({ codename }) => codename) : [];
      const program_type = item.elements.program_type ? item.elements.program_type.value.map(({ codename }) => codename) : [];
      const program_format = item.elements.program_format ? item.elements.program_format.value.map(({ codename }) => codename) : [];
      const program_length = item.elements.program_length ? item.elements.program_length.value.map(({ codename }) => codename) : [];
      const program_training = item.elements.program_training ? item.elements.program_training.value.map(({ codename }) => codename) : [];
      const program_topic = item.elements.program_topic ? item.elements.program_topic.value.map(({ codename }) => codename) : [];
      const program_characteristics = item.elements.program_characteristics ? item.elements.program_characteristics.value.map(({ codename }) => codename) : [];
      const other = item.elements.other ? item.elements.other.value.map(({ codename }) => codename) : [];
      const event_date_time = item.elements.event_date_time.value;

      const cta = {
        text: item.elements.event_date_time.value ? 'View Event Details' : 'Read Article',
        url: this.urlService.buildArticleURL(item.elements.url.value),
      };
      const eyebrow = item.elements.event_date_time.value ? dayjs.utc(item.elements.event_date_time.value).format('MMM DD, YYYY') : '';
      const title = item.elements.title.value;
      const type = {
        codename: item.elements.event_date_time.value ? 'event' : 'article',
        name: item.elements.event_date_time.value ? 'Event' : 'Article',
      };
      let image = null;

      if (item.elements.primary_image.value.length > 0) {
        image = {
          url: item.elements.primary_image.value[0].url,
          alt: item.elements.primary_image.value[0].description,
        };
      }

      return{
        id,
        audiences,
        categories,
        program_type,
        program_format,
        program_length,
        program_training,
        program_characteristics,
        program_topic,
        other,
        cta,
        eyebrow,
        image,
        title,
        type,
        event_date_time
      };
    })
    // Sort by event date descending
    .sort((a, b) => a.event_date_time > b.event_date_time ? -1 : 1);
  }

  searchEventsStore(query = '', dateFilter = 'forwards', skip = 0) {
    const queryRegExp = new RegExp(query, 'gi');

    const dateTodayFull = {
      min: dateToday + 'T00:00:00Z',
      max: dateToday + 'T23:59:59Z',
    };

    // Filter according to query and dateFilter
    this.filteredEvents = this.originalItems.filter(item => {
      let queryMatch = queryRegExp.test(item.title);

      if ( !queryMatch ) {
        return false;
      }

      if ( dateFilter === 'forwards' ) {
        return item.event_date_time >= dateTodayFull.min;
      }
      else {
        return item.event_date_time <= dateTodayFull.max;
      }
    });

    // Sort according to dateFilter
    // forwards - ascending
    // backwards - descending
    this.filteredEvents.sort((eventA, eventB) => {
      if ( dateFilter === 'forwards' ) {
        return eventA.event_date_time > eventB.event_date_time ? 1 : -1;
      }
      else {
        return eventB.event_date_time > eventA.event_date_time ? 1 : -1;
      }
    });

    return {
      items: this.filteredEvents.slice(skip, this.limit),
      totalCount: this.filteredEvents.length
    };
  }

}
