<div [ngClass]="{
    'date-picker__widget': true,
    'date-picker__widget--visible': visible
  }" #datePickerContainer>
  <!-- Tooltip SVG -->
  <svg class="date-picker__tooltip-graphic" preserveAspectRatio="none" width="300" height="358" viewBox="0 0 300 358" fill="none" xmlns="http://www.w3.org/2000/svg" #tooltipSVG>
    <g filter="url(#filter0_d)">
      <mask id="path-1-inside-1" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M50.9171 314L59 328L67.0829 314L270 314C275.523 314 280 309.523 280 304L280 20C280 14.4771 275.523 9.99997 270 9.99997L30 9.99999C24.4771 9.99999 20 14.4771 20 20L20 304C20 309.523 24.4772 314 30 314L50.9171 314Z" />
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M50.9171 314L59 328L67.0829 314L270 314C275.523 314 280 309.523 280 304L280 20C280 14.4771 275.523 9.99997 270 9.99997L30 9.99999C24.4771 9.99999 20 14.4771 20 20L20 304C20 309.523 24.4772 314 30 314L50.9171 314Z" fill="white" />
      <path d="M59 328L57.2679 329L59 332L60.7321 329L59 328ZM50.9171 314L52.6492 313L52.0718 312L50.9171 312L50.9171 314ZM67.0829 314L67.0829 312L65.9282 312L65.3508 313L67.0829 314ZM280 304L282 304L280 304ZM280 20L278 20L280 20ZM20 20L18 20L20 20ZM20 304L22 304L20 304ZM60.7321 327L52.6492 313L49.185 315L57.2679 329L60.7321 327ZM65.3508 313L57.2679 327L60.7321 329L68.815 315L65.3508 313ZM270 312L67.0829 312L67.0829 316L270 316L270 312ZM278 304C278 308.418 274.418 312 270 312L270 316C276.627 316 282 310.627 282 304L278 304ZM278 20L278 304L282 304L282 20L278 20ZM270 12C274.418 12 278 15.5817 278 20L282 20C282 13.3725 276.627 7.99997 270 7.99997L270 12ZM30 12L270 12L270 7.99997L30 7.99999L30 12ZM22 20C22 15.5817 25.5817 12 30 12L30 7.99999C23.3726 7.99999 18 13.3726 18 20L22 20ZM22 304L22 20L18 20L18 304L22 304ZM30 312C25.5818 312 22 308.418 22 304L18 304C18 310.627 23.3726 316 30 316L30 312ZM50.9171 312L30 312L30 316L50.9171 316L50.9171 312Z" fill="#0B4A5D" mask="url(#path-1-inside-1)" />
    </g>
    <defs>
      <filter id="filter0_d" x="0" y="0" width="300" height="358" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0431373 0 0 0 0 0.290196 0 0 0 0 0.364706 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>

  <button class="date-picker__today" (click)="onTodayClick($event)" >Today</button>
</div>
