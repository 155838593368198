import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MyPlannerService } from '@services/planner/my-planner.service';
@Component({
  selector: 'my-planner-page',
  templateUrl: './my-planner-page.component.html',
  styleUrls: ['./my-planner-page.component.scss'],
})
export class MyPlannerPage implements OnInit {
  data: {
    myPlannerHeader: any;
    signInBanner: any;
    manualMultiCard3up: any;
    verticalPathing: any;
    recommended3ColGrid: any;
  }

  constructor(
    private myPlannerService: MyPlannerService,
  ) {

    this.myPlannerService.getData().then((data: any) => {
      this.data = data;
    });
  }

  ngOnInit(): void { }
}
