import { Injectable } from '@angular/core';

// Allows for a more readable variable to use within the codebase,
// and matches against the actual GTM variable
const VARIABLE_MAP = {
  url: 'cd1',
  pageTitle: 'cd2',
  contentType: 'cd3',
  category: 'cd4',
  audience: 'cd5'
}

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  dataLayer: any;

  constructor() {
    (window as any).dataLayer = (window as any).dataLayer || [];

    this.dataLayer = (window as any).dataLayer;
  }

  push(data, mapVariables = true) {
    const mappedData = Object.entries(data).reduce((all, [key, value]) => {
      all[ mapVariables && key in VARIABLE_MAP ? VARIABLE_MAP[key] : key ] = value;
      return all;
    }, {});

    this.dataLayer.push(mappedData);
  }
}
