import { Component, OnInit, OnDestroy, Input, ViewEncapsulation, ElementRef } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { GlobalService } from '@services/global.service';
import { FocusTrapService } from '@services/focus-trap.service';

@Component({
  selector: 'ncoa-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {

  @Input() id: string;
  private element: any;

  @Input() location?: string;
  @Input() audience?: string;
  @Input() displayOnce?: boolean;
  @Input() type: string;
  @Input() isOpen: any;
  @Input() heading: string;
  @Input() description: string;
  @Input() cta: {
    url: string,
    text: string
  };
  @Input() backgroundImage: any;
  
  @Input() formSubmitHandler?: any;
  
  @Input() newsletterData: {
    heading: string,
    description: string,
    background: string,
    backgroundCaption: string,
    newsletterLink: string, 
    width: number,
    height: number,
    everyActionFormUrl: string,
    emailErrorText: string,
    fieldErrorText: string,
  };

  @Input() donateData: {
    heading: string,
    description: string,
    form_desc: string,
    background: string,
    backgroundCaption: string
  };

  @Input() soeData: {
    soe_list: string,
    soe_description: string;
  }

  @Input() coursesData: {
    amp: Array<any>
  }

  scrollY: any = 0;
  canShowModal: boolean;

  constructor(
    private modalService: ModalService,
    private focusTrapService: FocusTrapService,
    private el: ElementRef,
    private globalService: GlobalService,
  ) {
      this.element = el.nativeElement;
      this.canShowModal = this.globalService.isBrowser;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
        console.error('modal must have an id');
        return;
    }

    if ( this.globalService.isBrowser ) {
      // move element to bottom of page (just before </body>) so it can be displayed above everything else
      document.body.appendChild(this.element);

      // set focus to the modal dialog window
      this.element.firstChild.focus();

      // close modal on background click
      this.element.addEventListener('click', el => {
          if (el.target.className === 'modal') {
            this.closeModal();
          }
      });
    }

    // close modal on `esc` key
    document.addEventListener("keydown", e => this.checkTabKey(e));

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);

    // hide elements beneath the modal
    document.querySelector('.page')?.setAttribute('aria-hidden', 'true');

    // make sure page view is at the top/current position
    window.addEventListener('scroll', () => this.scrollY = window.scrollY);
    document.querySelector('body').setAttribute('style', `overflow-y: hidden; position: fixed; width: 100%; top: ${this.scrollY}`);

    this.focusTrapService.trapFocus(this.element, document.activeElement);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();

    document.removeEventListener("keydown", e => this.checkTabKey(e));

    // unhide elements beneath the modal
    document.querySelector('.page')?.setAttribute('aria-hidden', 'false');

    // remove added inline styles
    document.querySelector('body').setAttribute('style', '');
    window.scrollTo(0, parseInt(this.scrollY || '0') * -1);
  }

  // check tab key
  checkTabKey(e: any) {
    if (e.key !== undefined && e.key === 'Escape') { // for modern browsers
      this.closeModal();
    } else if (e.keyCode !== undefined && e.keyCode === 27) { // hell yeah, for IE
      this.closeModal();
    }
  }

  openModal() {
    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);

    // hide elements beneath the modal
    document.querySelector('.page')?.setAttribute('aria-hidden', 'true');

    // make sure page view is at the top/current position
    window.addEventListener('scroll', () => this.scrollY = window.scrollY);
    document.querySelector('body').setAttribute('style', `overflow-y: hidden; position: fixed; width: 100%; top: ${this.scrollY}`);

    this.focusTrapService.trapFocus(this.element, document.activeElement);

    this.isOpen = true;
  }

  // close modal
  closeModal() {
    this.modalService.close(this.id);

    this.focusTrapService.unTrapFocus();
    document.querySelector('body').removeAttribute('style');

    this.isOpen = false;
  }
}
