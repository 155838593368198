import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ncoa-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.scss']
})
export class AudienceComponent implements OnInit {
  @Input() theme: string;

  @Input() audience: {
    heading: string,
    links: Array<any>
  }

  constructor() {}

  ngOnInit(): void {
  }

  navLeave( _element ) {
    const _target = _element.srcElement;
    const _leftPos = _target.offsetLeft;
    let _bottomPos = 0;
    const _marker = <HTMLElement>document.querySelector('.link-marker');

    _marker.classList.add('is-active');
    let root = document.documentElement;
    _marker.style.transform = `translateX(${_leftPos - 10}px) translateY(${_bottomPos}px) scaleY(0)`;


  }

  navHover( _element ) {

    const _target = _element.srcElement;
    const _leftPos = _target.offsetLeft;
    let _bottomPos = 0;
    const _marker = <HTMLElement>document.querySelector('.link-marker');

    if( ( window.innerWidth >= 1024 && window.innerWidth <= 1290 ) && _target.className.includes('last')  ) {
      _bottomPos = 128;
    }

    _marker.classList.add('is-active');
    let root = document.documentElement;
    _marker.style.transform = `translateX(${_leftPos - 10}px) translateY(${_bottomPos}px) scaleY(1)`;
  }

  navFocus( _element ) {
    const _parent = _element.target.parentNode;
    const _leftPos = _parent.offsetLeft;
    let _bottomPos = 0;
    const _marker = <HTMLElement>document.querySelector('.link-marker');

    if( ( window.innerWidth >= 1024 && window.innerWidth <= 1290 ) && _parent.className.includes('last')  ) {
      _bottomPos = 128;
    }

    _parent.classList.add('focused');
    _marker.classList.add('is-active');
    let root = document.documentElement;
    _marker.style.transform = `translateX(${_leftPos - 10}px) translateY(${_bottomPos}px) scaleY(1)`;
  }

  navFocusOut( _element ) {
    const _parent = _element.target.parentNode;
    const _leftPos = _parent.offsetLeft;
    let _bottomPos = 0;
    const _marker = <HTMLElement>document.querySelector('.link-marker');

    _parent.classList.remove('focused');
    _marker.classList.add('is-active');
    let root = document.documentElement;
    _marker.style.transform = `translateX(${_leftPos - 10}px) translateY(${_bottomPos}px) scaleY(0)`;
  }

}
