import { AfterViewInit, ChangeDetectorRef, Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { StepComponent } from './step/step.component';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements AfterViewInit {

  @ContentChildren(StepComponent) steps: QueryList<StepComponent>;

  @Input() headerColor: 'dim-gray' | 'midnight-green'

  @Input() showHeader: boolean;

  public currentStep: number;

  public totalSteps: number;

  public progressWidth: string;

  public header: string;

  constructor(private cd : ChangeDetectorRef) { 
    this.currentStep = 0;
    this.totalSteps = 0;
    this.progressWidth = '0%';
    this.headerColor = 'dim-gray';
    this.header = '';
    this.showHeader = true;
  }

  ngAfterViewInit(): void {
      this.totalSteps = this.steps.length;

      this.steps.first.show();
      this.header = this.steps.first.header;
      this.updateWidth();
      this.cd.detectChanges();
  }

  public nextStep(){

    if(this.currentStep + 1 <= this.totalSteps - 1){

      const arr = this.steps.toArray();

      arr[this.currentStep].hide();
      arr[++this.currentStep].show();
      this.header = arr[this.currentStep].header ?? '';
      this.updateWidth();
    }

  }

  public previousStep(){

    if(this.currentStep - 1 >= 0){


      const arr = this.steps.toArray();

      arr[this.currentStep].hide();
      arr[--this.currentStep].show();
      this.header = arr[this.currentStep].header ?? '';
      this.updateWidth();
    }

  }

  public updateWidth(){
   
    this.progressWidth = `${Math.ceil(((this.currentStep + 1) / this.totalSteps) * 100)}%`;
  }

  public gotoStep(step: number){
    if(step <= this.totalSteps && step >= 0){
      this.currentStep = step;
    }
  }

}
