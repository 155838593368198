<header [ngClass]="{'showCTA': showCTA, 'article-detail-header': true}">
  <div class="article-detail-header__wrapper">
    <div class="eyebrow">{{eyebrow}}</div>
    <h1 class="title">{{title}}</h1>
    <a *ngIf="trustedExpertCta && !!leadGenShow" [href]="trustedExpertCta.url" [title]="trustedExpertCta.text" class="trusted-expert-cta">{{ trustedExpertCta.text }}</a>
    <div class="meta" *ngIf='!showCTA'>
      <div class="reading-time">{{readingTime}}</div>
      <button type="button" class="print-btn" (click)="clickPrint()">
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.96552 22V18.7468C4.96552 16.2662 6.9931 14.2736 9.51724 14.2736H19.0345V22H4.96552ZM2.02759 17.1202H2.89655V13.8669C2.89655 13.1756 3.47586 12.647 4.13793 12.647H19.8621C20.5655 12.647 21.1034 13.2163 21.1034 13.8669V17.1202H21.9724C23.0897 17.1202 24 16.2255 24 15.1276V7.80779C24 6.22184 22.6759 4.87988 21.0207 4.87988H2.97931C1.36552 4.87988 0 6.18118 0 7.80779V15.1276C0 16.1849 0.910345 17.1202 2.02759 17.1202Z"
            fill="#0B4A5D" />
          <path d="M4.96558 3.25323C4.96558 1.46396 6.45523 0 8.27592 0H19.0345V3.25323H4.96558Z" fill="#0B4A5D" />
        </svg>
        <div>
          <span>{{ctaPrintText}}</span>
        </div>
      </button>
    </div>
    <div class="cta" *ngIf="showCTA">
      <button>{{ctaArticleText}}</button>
    </div>
  </div>
</header>
