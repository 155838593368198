import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'grid-overlay',
  templateUrl: './grid-overlay.component.html',
  styleUrls: ['./grid-overlay.component.scss']
})
export class GridOverlay implements OnInit {
  items = Array.from({ length: 12 });
  @Input() gridVisible = true;

  constructor() { }

  ngOnInit(): void {
    window.addEventListener('keypress', this.onKeyPress.bind(this));
  }

  onKeyPress(event: KeyboardEvent) {
    if ( /^g$/i.test(event.key) && event.ctrlKey ) {
      this.toggleGrid();
    }
  }

  toggleGrid() {
    this.gridVisible = !this.gridVisible;
  }

}
