<div class="form-modal">
    <div class="modal-container">
      <h2 class="title">{{modalContent.title.value}}</h2>
      <button type="button" class="dismiss-btn" (click)="doCloseModal()">
        {{modalContent.close_label.value}}
      </button>
      <div class="intro" [innerHtml]="modalContent.intro.value" *ngIf="!showThankYou"></div>
      <section class="modal-form" *ngIf="modalForm && !showThankYou">
        <form [formGroup]="modalForm" (ngSubmit)="onSubmit()">
            <div class="field-container">
              <label for="firstName">{{ modalContent.first_name.value }}*</label>
              <input type="text" value="" aria-required="true" name="firstName" id="firstName" formControlName="firstName" required>
              <div *ngIf="firstName.errors && firstName.touched">
                <div class="error" *ngIf="firstName.errors.required">{{ modalContent.required_error.value }}</div>
              </div>
            </div>
            <div class="field-container">
              <label for="lastName">{{ modalContent.last_name.value }}*</label>
              <input type="text" aria-required="true" name="lastName" id="lastName" formControlName="lastName"  required>
              <div *ngIf="lastName.errors && lastName.touched">
                <div class="error" *ngIf="lastName.errors.required">{{ modalContent.required_error.value }}</div>
              </div>
            </div>
            <div class="field-container">
              <label for="email">{{ modalContent.email.value }}*</label>
              <input type="email" aria-required="true" name="email" id="email" formControlName="email"  required>
              <div *ngIf="email.errors && email.touched">
                <div class="error" *ngIf="email.errors.required">{{ modalContent.required_error.value }}</div>
                <div class="error" *ngIf="email.errors.email">{{ modalContent.invalid_input.value }}</div>
              </div>
            </div>
            <div class="field-container" *ngIf="modalContent.birth_year">
              <label for="birthYear">{{ modalContent.birth_year.value }}</label>
              <input type="text" aria-required="true" name="birthYear" id="birthYear" formControlName="birthYear"  mask="0000" [validation]="true">
              <div *ngIf="(birthYear.errors && birthYear.touched) || errorMessages.birthYear">
                <div class="error" *ngIf="birthYear?.errors?.mask || birthYear?.errors.minAge || birthYear?.errors.maxAge">{{ modalContent.invalid_input.value }}</div>
              </div>
            </div>
            <div class="field-container" *ngIf="modalContent.gender">
              <label for="gender">{{ modalContent.gender.value }}</label>
              <select  aria-required="true"  name="gender" id="gender" formControlName="gender"> 
                <option *ngFor="let option of genderValues" [ngValue]="option.value">{{option.label[selectedLanguage]}}</option>
              </select>
            </div>
            <div class="field-container" *ngIf="modalContent.race_ethnicity">
              <label for="race">{{ modalContent.race_ethnicity.value }}</label>
              <select name="race" id="race" formControlName="race">
                <option *ngFor="let option of raceEthnicityValues" [ngValue]="option.value">{{option.label[selectedLanguage]}}</option>
              </select>
            </div>
            <div class="field-container toggle-container"  *ngIf="modalContent.send_report_in_email">
              <input type="checkbox" aria-required="true" name="sendReportEmail" id="sendReportEmail" formControlName="sendReportEmail">
              <label for="sendReportEmail">{{ modalContent.send_report_in_email.value }}</label>
            </div>
            <!-- <div class="field-container toggle-container">
              <input type="checkbox" aria-required="true" name="communicationsConsent" id="communicationsConsent" formControlName="communicationsConsent">
              <label for="communicationsConsent">{{ modalContent.receive_ncoa_communications.value }}</label>
            </div> -->
            <div class="submit-container">
              <button type="submit" aria-label="Submit" [disabled]="disableSubmit" [ngClass]="ctaClass">{{ modalContent.submit.value }}</button>
            </div>
          </form>
      </section>
      <div class="thank-you-container" [innerHtml]="modalContent.thank_you_message.value" *ngIf="showThankYou">
      </div>
    </div>
</div>