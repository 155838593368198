import { Component, OnInit, Input } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserPromptService } from '@services/planner/user-prompt.service';
import { UserService } from '@services/planner/user.service';
import { UtilityService } from '@services/planner/utility.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ncoa-subtopic-header',
  templateUrl: './subtopic-header.component.html',
  styleUrls: ['./subtopic-header.component.scss']
})
export class SubtopicHeaderComponent implements OnInit {

  @Input() pageID: string;
  @Input() backCTA: {
    url: string;
    text: string;
  };
  @Input() heading: string;
  @Input() photo: {
    url: string,
    alt: string
  };

  @Input() menu: [
    {
      url: string,
      label: string,
      active: boolean
    }
  ];

  isSaved: boolean = false;

  constructor(
    private utility: UtilityService,
    private tools: ToolsPrimaryConfigService,
    private userService: UserService,
    private modalService: UserPromptService,
    private dataLayerService: DataLayerService,
  ) { }


  trackAnalytics(ev){
    const _element = ev.currentTarget as HTMLAnchorElement;
    const _clickUrl = _element.href || _element.getAttribute('href');

    this.dataLayerService.push({ 
      'event': 'recirc', 
      'url': _clickUrl,
      'componentType': 'header' },false); 
  }

  goBack(e){
      window.location.href = this.backCTA.url;
  }


  ngOnInit(): void {
    let savedContent: any = this.tools.customerActivity.basic?.SavedContent || [];
    if (savedContent && this.pageID) {
      const flag = savedContent.find((content) => content.ContentId === this.pageID);

      if (flag) {
        this.isSaved = true;
      }
    }
  }

  toggleSave(): void {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
      this.utility.getSavedContent().then((savedContent) => {
        let newSavedContent;
        let isSaved;

        const found = savedContent.find((content) => content.ContentId === this.pageID);

        if (found) {
          newSavedContent = savedContent.filter((content) => content.ContentId !== this.pageID);
          isSaved = false;
        } else {
          newSavedContent = savedContent.concat({ ContentId: this.pageID });
          isSaved = true;
        }

        this.utility.updateSavedContent(newSavedContent).then(() => {
          this.isSaved = isSaved;
        });
      });
    }else{
      this.modalService.isModalOpen(true);
    }
  });
  }
}
