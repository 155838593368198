import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.scss']
})
export class CTAButtonComponent {

  @Input() label;
  @Input() type;
  @Input() link;
  @Input() configClass;
  @Input() contextClass;

  @Output() click: EventEmitter<any> = new EventEmitter();

  constructor() { }

  onClick($event): void {
    $event.stopPropagation();
    if (this.configClass) {
      if (this.configClass === 'print-link') this.click.emit('print');
      if (this.configClass === 'save-link') this.click.emit('save');
      if (this.configClass === 'reminder') this.click.emit('reminder');
      if (this.configClass === 'email-report') this.click.emit('email-report');
    } else if (this.link) {
      window.open(this.link, '_blank');
    }
  }

}
