import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ncoa-partner-card-block',
  templateUrl: './partner-card-block.component.html',
  styleUrls: ['./partner-card-block.component.scss']
})
export class PartnerCardBlockComponent implements OnInit {

  @Input() partners: any;
  @Input() groupHeader: string;
  @Input() color: any = 'light';
  @Input() badgeText: string;

  constructor() { }

  ngOnInit(): void {
    this.processPartners();

  }

  processPartners(){
    if(this.partners){
      this.partners.forEach((partner: any) => {
        const item = partner.elements
        partner.logo = item.partner_logo?.value[0].url ?? '';
        partner.logoAlt = item.partner_logo?.value[0].description ?? '';
        partner.name = item.partner_name.value ?? '';
        partner.description = item.description.value ?? '';
        partner.cta = item.cta_text.value ?? '';
        partner.url = item.cta_url.value ?? '';
       
      });
    }
  }
  

}
