<div class="breakdown-healthcare">
  <div class="breakdown-healthcare__wrapper">
    <h2 class="headline">{{ headline }}</h2>
    <div class="body" [innerHTML]="body"></div>
    <div class="breakdown">
      <div class="breakdown__toggle">
        <label for="health-status">Select Health Status</label>
        <div class="select-container">
          <select name="health-status" id="health-status" [(ngModel)]="healStatus" (change) = 'onHealthStatusChange()'>
            <option value="none" selected="selected">Choose One</option>
            <option value="good">Good Health</option>
            <option value="poor">Poor Health</option>
          </select>
          <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99546 11.8237C9.744 12.1096 9.42967 12.2526 9.11534 12.2526C8.80102 12.2526 8.48671 12.1096 8.23525 11.8237L0.377187 2.92338C-0.125729 2.35147 -0.125729 1.45787 0.377187 0.885961C0.880102 0.314055 1.66592 0.314055 2.16883 0.885961L9.11534 9.04659L16.0933 0.921704C16.5962 0.349798 17.382 0.349798 17.8852 0.921704C18.3567 1.45787 18.3567 2.35147 17.8535 2.92338L9.99546 11.8237Z" fill="#0B4A5D"/>
          </svg>
        </div>
      </div>
      <div class="breakdown__result--top">
        <div class="breakdown__col">
          <h3>Medicare Advantage (Part C)</h3>
          <p>Includes Part A & B Coverage and drug coverage</p>
        </div>
        <div class="breakdown__col">
          <h3>Medigap + Part D</h3>
          <p>Includes Original Medicare + Medigap Policy + Drug Plan (Part D)</p>
        </div>
      </div>
      <div class="breakdown__result--bottom">
        <div class="breakdown__col">
          <div class="breakdown__col--cover">
            <p>
              <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="11" r="9" fill="#0B4A5D" stroke="#0B4A5D" stroke-width="2"/>
                <path d="M10.88 12.576L10.992 8.64V4.448H8.864V8.64L8.992 12.576H10.88ZM11.024 16V13.888H8.8V16H11.024Z" fill="white"/>
              </svg>
              
              Select Health Status To Show Estimate
            </p>  
          </div>
          <h4>{{ pricing[healStatus].lowPrice }}</h4>
          <p>Premiums + All Out-of-Pocket Costs</p>
        </div>
        <div class="breakdown__col">
          <div class="breakdown__col--cover">
            <p>
              <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="11" r="9" fill="#0B4A5D" stroke="#0B4A5D" stroke-width="2"/>
                <path d="M10.88 12.576L10.992 8.64V4.448H8.864V8.64L8.992 12.576H10.88ZM11.024 16V13.888H8.8V16H11.024Z" fill="white"/>
              </svg>
              
              Select Health Status To Show Estimate
            </p>  
          </div>
          <h4>{{ pricing[healStatus].highPrice }}</h4>
          <p>Premiums + All Out-of-Pocket Costs</p>
        </div>
      </div>
    </div>
  </div>
</div>
