<div class="multi-select-container">
    <h3>{{header}}</h3>
    <section class="multi-select-items">
      <ng-container *ngFor="let item of items">
        <div class="multi-select-item"  [ngClass]="{'disabled': (hitMax && !item.checked) || item?.hide || (readonly) || (disabled && !(item.value === 'answer_prefer_not_to_answer' || item.value === 'a_prefer_not' || item.value === 'answer_none'))}">
          <app-checkbox  [ngModel]="item.checked" [disabled]="(hitMax && !item.checked) || item?.hide || (disabled && !(item.value === 'answer_prefer_not_to_answer' || item.value === 'a_prefer_not' || item.value === 'answer_none')) || (readonly)" (ngModelChange)="onCheckboxClicked(item, $event)">{{item[displayField]['en']}}</app-checkbox>
        </div>
      </ng-container>
    </section>
</div>
