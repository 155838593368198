<section class="component special-pathing">
  <div class="special-pathing__container">
    <div class="special-pathing__wrapper">
      <ul class="special-pathing__items">
        <li class="item" *ngFor="let path of paths">
          <a class="cta" (click)="clickCTA(path)" [attr.aria-label]="'Click here to go to ' + path.title" >
            <p><span>{{ path.title }}</span></p><div class="icon"><span class="icons__icon--arrow-right"></span></div>
          </a>
        </li>
      </ul>
      </div>
  </div>
</section>
