<section class="component standard-page-header">
  <div class="standard-page-header__container">
    <svg class="half-circle" width="229" height="458" viewBox="0 0 229 458" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M-8.00793e-05 458C126.473 458 229 355.475 229 229.001C229 102.527 126.474 2.21133e-05 0 0" fill="#F3F6F7" />
    </svg>
    <div class="standard-page-header__wrapper">
      <svg class="ellipse" width="164" height="164" viewBox="0 0 164 164" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="82" cy="82" r="82" fill="#E1F4EB" />
      </svg>
      <div class="texts">
        <p class="standard-page-headline">{{ headline }} <span *ngIf="audiences?.length > 0">for <div
              *ngFor="let audience of audiences">{{ audience.name }}</div></span></p>
        <div class="standard-page-description">
          <p [innerHtml]="description"></p>
          <a *ngIf="cta?.url" href="{{ cta.url }}" class="optional-cta">{{ cta.text }}</a>
        </div>
      </div>
      <div class="image" *ngIf="image; else elseBlock">
        <img src="{{ image.url }}" alt="{{ image.name }}" title="{{ image.name }}">
      </div>
      <ng-template #elseBlock class="image">
        <div class="image default">
        </div>
      </ng-template>
    </div>
  </div>
</section>
