<ng-container *ngIf="data">
    <div class="assessment-falls-risk" [ngClass]="data.category.categoryCode">
      <!-- <ncoa-breakdown-header
        *ngIf="data.breakdownHeader"
        [id]="data.breakdownHeader.id"
        [isSaved]="isLoggedIn"
        [codename]="data.breakdownHeader.codename"
        [backLink]="data.breakdownHeader.backLink"
        [profileLink]="data.breakdownHeader.profileLink"
        [headline]="data.breakdownHeader.headline"
        [variationType] = '"estimator"'
        [showBtns]="false"
      ></ncoa-breakdown-header> -->
      <!-- <div class="assessment-header">
        <h1>{{data.breakdownHeader.headline}}</h1>
      </div> -->
      <section class="falls-plan-header">

        <div class="header-title-group">
            <div class="header-title-group-first">
                <h1>{{data.breakdownHeader.headline}}</h1>

            </div>
            <div class="header-title-group-second">
              <button class="plan-svg" (click)="clickPrint()"> 
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                  <path d="M25.5 7.18848H6.625C5.66359 7.18848 4.74156 7.5704 4.06174 8.25021C3.38192 8.93003 3 9.85207 3 10.8135V20.6885C3 21.6167 3.36875 22.507 4.02513 23.1634C4.6815 23.8197 5.57174 24.1885 6.5 24.1885H7V26.6685C7 27.3368 7.2655 27.9778 7.73809 28.4504C8.21068 28.923 8.85166 29.1885 9.52 29.1885H22.48C23.1483 29.1885 23.7893 28.923 24.2619 28.4504C24.7345 27.9778 25 27.3368 25 26.6685V24.1885H25.5C26.4283 24.1885 27.3185 23.8197 27.9749 23.1634C28.6313 22.507 29 21.6167 29 20.6885V10.6885C29 9.76022 28.6313 8.86998 27.9749 8.2136C27.3185 7.55723 26.4283 7.18848 25.5 7.18848ZM23 26.6685C22.9995 26.8062 22.9446 26.9382 22.8471 27.0356C22.7497 27.133 22.6178 27.188 22.48 27.1885H9.52C9.38224 27.188 9.25026 27.133 9.15285 27.0356C9.05544 26.9382 9.00049 26.8062 9 26.6685V16.7085C9.00049 16.5707 9.05544 16.4387 9.15285 16.3413C9.25026 16.2439 9.38224 16.189 9.52 16.1885H22.48C22.6178 16.189 22.7497 16.2439 22.8471 16.3413C22.9446 16.4387 22.9995 16.5707 23 16.7085V26.6685ZM24.625 13.1835C24.3191 13.2081 24.0129 13.1382 23.748 12.9833C23.483 12.8285 23.2719 12.596 23.1431 12.3174C23.0144 12.0388 22.9742 11.7274 23.0281 11.4252C23.0819 11.1231 23.2271 10.8447 23.4442 10.6276C23.6612 10.4106 23.9396 10.2654 24.2417 10.2115C24.5439 10.1577 24.8553 10.1979 25.1339 10.3266C25.4126 10.4553 25.645 10.6664 25.7999 10.9314C25.9547 11.1964 26.0246 11.5025 26 11.8085C25.9715 12.1636 25.8174 12.497 25.5655 12.749C25.3135 13.0009 24.9802 13.1549 24.625 13.1835ZM21.5 3.18848H10.5C9.65875 3.18973 8.84601 3.49345 8.21012 4.04421C7.57422 4.59497 7.15758 5.35603 7.03625 6.18848H24.9638C24.8424 5.35603 24.4258 4.59497 23.7899 4.04421C23.154 3.49345 22.3412 3.18973 21.5 3.18848Z" fill="#0B4A5D"/>
                </svg>
                <div>
                    <span>
                        {{data.print}} 
                      </span>
                </div>
              </button>
                <button class="plan-svg" (click)="clickStartOver()" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path d="M28 14.6666C27.6463 14.6666 27.3072 14.8071 27.0572 15.0572C26.8071 15.3072 26.6666 15.6463 26.6666 16C26.6825 18.4973 25.827 20.9221 24.2475 22.8566C22.668 24.7911 20.4633 26.1142 18.0132 26.5981C15.5632 27.082 13.021 26.6964 10.8246 25.5077C8.62826 24.3189 6.91517 22.4015 5.98039 20.0856C5.04562 17.7697 4.94768 15.2004 5.70345 12.8201C6.45923 10.4397 8.02139 8.39747 10.1209 7.045C12.2204 5.69252 14.7258 5.11451 17.2056 5.41051C19.6855 5.7065 21.9844 6.85798 23.7066 8.66663H20.5066C20.153 8.66663 19.8139 8.8071 19.5638 9.05715C19.3138 9.3072 19.1733 9.64634 19.1733 9.99996C19.1733 10.3536 19.3138 10.6927 19.5638 10.9428C19.8139 11.1928 20.153 11.3333 20.5066 11.3333H26.5466C26.9003 11.3333 27.2394 11.1928 27.4894 10.9428C27.7395 10.6927 27.88 10.3536 27.88 9.99996V3.99996C27.88 3.64634 27.7395 3.3072 27.4894 3.05715C27.2394 2.8071 26.9003 2.66663 26.5466 2.66663C26.193 2.66663 25.8539 2.8071 25.6038 3.05715C25.3538 3.3072 25.2133 3.64634 25.2133 3.99996V6.35996C22.9929 4.23737 20.1076 2.94656 17.0453 2.70573C13.983 2.4649 10.9315 3.28882 8.40652 5.03821C5.88158 6.78761 4.03811 9.35517 3.18773 12.3069C2.33736 15.2586 2.53224 18.4134 3.73944 21.238C4.94664 24.0626 7.0921 26.3838 9.81316 27.8091C12.5342 29.2344 15.664 29.6765 18.6734 29.0606C21.6827 28.4447 24.3872 26.8086 26.3295 24.4289C28.2718 22.0491 29.3329 19.0717 29.3333 16C29.3333 15.6463 29.1928 15.3072 28.9428 15.0572C28.6927 14.8071 28.3536 14.6666 28 14.6666Z" fill="#0B4A5D"/>
                      </svg>

                    <div>
                        <span>
                            {{data.startOver}} 
                          </span>
                    </div>
                </button>

                <button *ngIf="isLoggedIn" class="plan-svg" (click)="logOut()">
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                        <path d="M23.1667 9.52181L21.2867 11.4018L24.7267 14.8551H11.1667V17.5218H24.7267L21.2867 20.9618L23.1667 22.8551L29.8334 16.1885M5.83341 6.85514H16.5001V4.18848H5.83341C4.36675 4.18848 3.16675 5.38848 3.16675 6.85514V25.5218C3.16675 26.9885 4.36675 28.1885 5.83341 28.1885H16.5001V25.5218H5.83341V6.85514Z" fill="#0B4A5D"/>
                      </svg>
                    <div>
                        <span>
                            {{data?.logOut}}
                        </span>
                    </div>
                    </button>

                <!-- <button *ngIf="!isLoggedIn" class="plan-btn" (click)="navToSignIn()">

                    <div>
                        <span>
                            {{data?.logIn}}
                        </span>
                    </div>
                </button> -->
            </div>
     
        </div>

        <div *ngIf="data.bannerText && !isLoggedIn" class="falls-plan-header-banner">
            <div class="banner">
                {{data.bannerText}}
                    <button (click)="navToSignUp()" class="plan-btn">
                        {{data.bannerCta}}
                    </button>
            </div>
            <app-banner *ngIf="bannerSubText" [richTextObject]="bannerSubText" padding="0px"></app-banner>

        </div>
        <div class="header-title-group">
          <select name="language-toggle" (change)="onLanguageToggle($event)">
            <option *ngFor="let option of languageOptions" [value]="option.value" [selected]="option.value === selectedLanguage">{{option.label}}</option>
          </select>
          </div>

    </section>
      <div class="falls-risk-results" [ngClass]="{'modal-open' : showModal}">
        <div class="falls-risk-results__wrapper">
          <div class="falls-risk-results__date">
            <p>{{data.screeningDate.label}} {{data.screeningDate.date}}</p>
          </div>

          <div class="falls-risk-results__category">
            <h2>{{data.category.label}}</h2>
            <div class="category-name" [ngClass]= "data.category.categoryName === 'Normal'? 'normal-risk' : 'high-risk'"><p>{{data.category.categoryName}}</p></div>
          </div>

          <div class="falls-risk-results__category-description" [innerHtml]="data.category.description | safe: 'html'"></div>

          <!-- Next Steps -->
          <div class="falls-risk-results__recommendations">
            <h2>{{data.recommendations.title}}</h2>
            <div class="recommendations-content">
                <next-step class="next-step" *ngFor="let step of data.recommendations.nextSteps; let stepIndex = index;"
                  [content]="step.content"
                  [ctaButton]="step.ctaButton"
                  [modalData]="step.modalData"
                  [icon]="step.icon"
                  [contextClass]="data.category.categoryCode + '-' + step.ctaButton.configClass"
                  (print)="onClickReportLink($event)"
                  (save)="clickSave()"
                  (showModal)="toggleModal($event)">
                </next-step>
            </div>
          </div>

          <!-- What can you do to prevent falls -->
          <div class="falls-risk-results__prevention">
            <h2>{{data.prevention.title}}</h2>
            <div class="prevention-content" *ngFor="let tip of data.prevention.content" [innerHtml]="tip.elements.tip_content.value"></div>
          </div>

          <!-- Resources -->
          <div class="falls-risk-results__resources">
            <h2>{{data.resources.title}}</h2>
            <div class="resources-content" [innerHtml]="data.resources.content"></div>
          </div>

          <awp-partner-grid
            *ngIf="data.partnerGrid"
            [heading]="data.partnerGrid.elements.title.value"
            [images]="data.partnerGrid.elements.images.value"
            [blurb]="data.partnerGrid.elements.content_blurb.value"
          ></awp-partner-grid>
        </div>
      </div>

      <div *ngIf="showModal" class="form-modal">
        <ncoa-form-modal *ngIf="showModal"
          [modalContent]="modalContent"
          [contextClass]="categoryCode"
          (closeModal)="toggleModal($event)"
          (sendEmail)="sendEmailAction($event)">
        </ncoa-form-modal>
      </div>
    </div>
  </ng-container>
  