import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { environment } from 'src/environments/environment';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerContactService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
  ) {
    this.baseUrl = (environment as any).ncoaApiBaseUrl + '/contact'
  }

  public sendFeedback(email: string, comment: string, state?: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const body = {
      "email": email,
      "comment": comment,
      "state": state,
    }
    return this.http.post(this.baseUrl + '/contactUs', body, httpOptions);
  }
}
