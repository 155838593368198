import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { BehaviorSubject } from 'rxjs';
import { CustomerActivityService } from './customer-activity.service';
import { take } from 'rxjs/operators';
import { KontentDeliveryService } from '@services/kontent-delivery.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private readonly baseUrl: string;
  private readonly url: string;

  private messageSource = new BehaviorSubject(null);
  currentMessage = this.messageSource.asObservable();

  constructor(
    private http: HttpClient,
    private cas: CustomerActivityService,
    private kontent: KontentDeliveryService,
  ) {
    this.baseUrl = (environment as any).utilityBaseUrl  || (environment as any).ncoaApiBaseUrl + '/location';
    this.url = (environment as any).ncoaApiBaseUrl;

  }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }),
    responseType: 'text' as any
  };

  generatePdf(data) {
    return this.http.post(this.baseUrl + '/pdf', data, this.httpOptions)
  }

  getZipCode(zip) {
    return this.http.get(`${this.baseUrl}/zip/${zip}`).toPromise();
  }

  getQuestion(questionId) {
    return this.http.get(`${this.url}/questions/${questionId}`).toPromise();
  }

  sendMessage(message: string) {
    this.messageSource.next(message);
  }

  isEmailValid(email: string): boolean {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  }

  isPasswordValid(password: string): boolean {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(String(password));
  }

  updateSavedContent(savedContent) {
    return this.cas.updateActivity({ SavedContent: savedContent })
      .pipe(take(1))
      .toPromise()
      .then(() => {
        const savedContentCounterDOM = document.querySelector('#saved-content-counter');
        if (savedContentCounterDOM) {
          let savedContentCount = 0;

          Promise.all(savedContent.map(({ ContentId }) => {
            return this.kontent.getItem(null, { 'system.id': ContentId });
          })).then(responses => {
            responses.forEach((response: any) => {
              if (response.items.length > 0) {
                const systemType = response.items[0].system.type;

                if (systemType !== 'awa_benefits_tool___tool') {
                  savedContentCount++;
                }
              }
            });

            savedContentCounterDOM.innerHTML = savedContentCount.toString();
          });
        }
      });
  }

  getSavedContent() {
    return this.cas.getActivity()
      .pipe(take(1))
      .toPromise()
      .then((data) => {
        if (data.basic?.SavedContent) {
          return data.basic.SavedContent;
        } else {
          return [];
        }
      });
  }

  arrayMode(array) {
    if (array.length === 0) {
      return null;
    }

    const modeMap = {};

    let maxEl = array[0];
    let maxCount = 1;

    for (let i = 0; i < array.length; i++) {
      const el = array[i];

      if (modeMap[el] === null) {
        modeMap[el] = 1;
      } else {
        modeMap[el]++;
      }

      if (modeMap[el] > maxCount) {
        maxEl = el;
        maxCount = modeMap[el];
      }
    }

    return maxEl;
  }
}
