import { UtilityService } from '@services/planner/utility.service';
import { AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
export const zipPattern: RegExp = new RegExp(/^\d{5}/);


export function zipCodeValidator(utilService: UtilityService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    if(control.value.length !== 5){
      return of({invalidZip: true})
    } else {
      return utilService.getZipCode(control.value)
      .then (
        res => {
           //console.log('res',res);
          return (res && res['zipCode'] === '') ? {invalidZip: true} : null;
        }
      )
      .catch (
        err => {
           //console.log('error calling zipcode:', err);
          return err;
        }
      );
    };
    }
} 