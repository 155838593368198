import { Component, Input } from '@angular/core';
import { PartnerLogo } from 'src/app/typings/partners';

@Component({
  selector: 'ncoa-partners-category',
  templateUrl: './partners-category.component.html',
  styleUrls: ['./partners-category.component.scss']
})
export class PartnersCategoryComponent {
  @Input() heading: string;
  @Input() partners: PartnerLogo[];
  @Input() colored = false;
}
