import { Component, Input } from '@angular/core';
import { Image, Audience, Headline } from './feature-page-block.types';

@Component({
  selector: 'ncoa-feature-page-block',
  templateUrl: './feature-page-block.component.html',
  styleUrls: ['./feature-page-block.component.scss']
})
export class FeaturePageBlockComponent {
  @Input() headline: Headline;
  @Input() audiences: Audience[];
  @Input() description: string;
  @Input() type: string;
  @Input() image: Image;
  @Input() background: string;
  @Input() url: string;
  @Input() blockCTA: string = '';
  @Input() orientation = 'text-image';

  getCTATitle(type: string): string {
    if(this.blockCTA) {
      return this.blockCTA;
    }
    switch (type) {
        case 'page':
            return 'Explore More';
        case 'article':
            return 'Read Article';
        case 'event':
            return 'View Event Details';
        default:
            return 'Explore More';
    }
}
}
