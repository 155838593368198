import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ncoa-breakdown-partnership',
  templateUrl: './breakdown-partnership.component.html',
  styleUrls: ['./breakdown-partnership.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class BreakdownPartnershipComponent implements OnInit {

  @Input() text: string;
  @Input() icon: {
    sizes: [
      {
        media: string,
        src: string,
      },
    ],
    src: string,
    alt: string,
  };

  constructor() { }

  ngOnInit(): void {
  }

}
