import { Injectable } from '@angular/core';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { ToolsPrimaryConfigService } from './tools-primary-config.service';
import { UtilityService } from './utility.service';

@Injectable({ providedIn: 'root' })
export class CostEstimatorService {
  constructor(
    private tools: ToolsPrimaryConfigService,
    private kontent: KontentDeliveryService,
    private utility: UtilityService,
  ) { }

  async getData() {
    const data = {};
    let codename = this.tools.currentConfig.core.route.codename;

    if (this.tools.singularVerticalTopic) {
      const _route = this.tools.routes.find((route) => route.route === this.tools.currentConfig.core.primaryAssessment.url);
      codename = _route.codename;
    }

    const tool = this.tools.modular_content[codename];
    const resultCodename = tool.elements.summar_page_copy.value[0];
    const brokers = [];
    this.tools.rawConfig?.elements.brokers.value.forEach(item => {
        brokers[this.tools.modular_content[item].elements.broker_code.value.toLowerCase()] = this.tools.modular_content[item].elements;
    });

    const resultPage = await this.kontent.getItem(resultCodename, { depth: 4 });

    if (resultPage) {
      const { item, modular_content } = resultPage;
      const { elements } = item;

      // breakdown header
      data['breakdownHeader'] = {
        id: tool.system.id,
        codename: this.tools.currentConfig.core.route.codename,
        isSaved: (this.tools.customerActivity?.basic?.SavedContent || []).find(({ ContentId }) => ContentId === tool.system.id),
        backLink: {
          url: this.tools.routes.find(({ type }) => type === 'home').route,
          text: 'My Planner',
        },
        profileLink: {
          url: this.tools.routes.find(({ type }) => type === 'profile').route,
          text: 'Edit Answers',
        },
        headline: elements.summary_header.value,
      };

      // breakdown healthcare
      const healthcare = this.tools.customerActivity?.healthcare;
      const finances = this.tools.customerActivity?.finances;
      let goodLowPrice = Math.round(healthcare.forecast.medicareAdvantage.lowEstimate);
      let goodHighPrice = Math.round(healthcare.forecast.medigapPartD.lowEstimate);
      let poorLowPrice = Math.round(healthcare.forecast.medicareAdvantage.highEstimate);
      let poorHighPrice = Math.round(healthcare.forecast.medigapPartD.highEstimate);

      data['broker'] = healthcare.broker ? healthcare.broker : '';
      data['isShip'] = healthcare.medicareIsShip;
      data['isPrivateLabel'] = {
        value: this.tools.rawConfig.elements.verticals_listing.value.length
      }

      const pricing = {
        none: {
          lowPrice: '$0',
          highPrice: '$0',
        },
        good: {
          lowPrice: `$${goodLowPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          highPrice: `$${goodHighPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        },
        poor: {
          lowPrice: `$${poorLowPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          highPrice: `$${poorHighPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        },
      };

      data['breakdownHealthcare'] = {
        headline: elements.top_section_header.value,
        body: elements.top_section_body.value,
        pricing,
      };

      // breakdown plans
      const household = this.tools.customerActivity?.household;
      const zipcode = household?.zipCode;

      const planCount = healthcare?.planCount;

      const planSpread = healthcare?.planSpread;
      const medicareAdvantage = planSpread?.medicareAdvantage;
      const medigapPartD = planSpread?.medigapPartD;

      const planA = {
        lowCost: [
          {
            heading: Math.round(medicareAdvantage.low.premium),
            text: `per year in Premiums or $${Math.round(medicareAdvantage.low.partDPremium)}`,
          },
          {
            heading: Math.round(medicareAdvantage.low.outOfPocket),
            text: 'Your estimated Out-Of-Pocket Costs',
          },
          {
            heading: Math.round(medicareAdvantage.low.total),
            text: 'Your Estimated Total Annual Cost',
          },
        ],
        medianCost: [
          {
            heading: Math.round(medicareAdvantage.med.premium),
            text: `per year in Premiums or $${ Math.round(medicareAdvantage.med.partDPremium) }/month`
          },
          {
            heading: Math.round(medicareAdvantage.med.outOfPocket),
            text: 'Your estimated Out-Of-Pocket Costs'
          },
          {
            heading: Math.round(medicareAdvantage.med.total),
            text: 'Your Estimated Total Annual Cost'
          },
        ],
        highCost: [
          {
            heading: Math.round(medicareAdvantage.high.premium),
            text: `per year in Premiums or $${ Math.round(medicareAdvantage.high.partDPremium) }/month`
          },
          {
            heading: Math.round(medicareAdvantage.high.outOfPocket),
            text: 'Your estimated Out-Of-Pocket Costs'
          },
          {
            heading: Math.round(medicareAdvantage.high.total),
            text: 'Your Estimated Total Annual Cost'
          }
        ]
      };

      const planB = {
        lowCost: [
          {
            heading: Math.round(medigapPartD.low.premium),
            text: `per year in Premiums or $${ Math.round(medigapPartD.low.partDPremium) }/month`
          },
          {
            heading: Math.round(medigapPartD.low.outOfPocket),
            text: 'Your estimated Out-Of-Pocket Costs'
          },
          {
            heading: Math.round(medigapPartD.low.total),
            text: 'Your Estimated Total Annual Cost'
          }
        ],
        medianCost: [
          {
            heading: Math.round(medigapPartD.med.premium),
            text: `per year in Premiums or $${ Math.round(medigapPartD.med.partDPremium) }/month`
          },
          {
            heading: Math.round(medigapPartD.med.outOfPocket),
            text: 'Your estimated Out-Of-Pocket Costs'
          },
          {
            heading: Math.round(medigapPartD.med.total),
            text: 'Your Estimated Total Annual Cost'
          }
        ],
        highCost: [
          {
            heading: Math.round(medigapPartD.high.premium),
            text: `per year in Premiums or $${ Math.round(medigapPartD.high.partDPremium) }/month`
          },
          {
            heading: Math.round(medigapPartD.high.outOfPocket),
            text: 'Your estimated Out-Of-Pocket Costs'
          },
          {
            heading: Math.round(medigapPartD.high.total),
            text: 'Your Estimated Total Annual Cost'
          }
        ]
      };

      const considerations = [];
      elements.plan_breakdown_bulletpoints.value.forEach((key) => {
        const raw = modular_content[key];
        considerations.push({
          heading: raw.elements.bulletpoint_header.value,
          tooltip: raw.elements.bulletpoint_tooltip.value,
          description: {
            typeA: raw.elements.column_a_body.value,
            typeB: raw.elements.column_b_body.value,
          },
        });
      });

      data['breakdownPlan'] = {
        heading: elements.plan_summary_header.value,
        description: elements.plan_summary_body.value,
        zipcode,
        tooltip: elements.plan_summary_tooltip.value,
        disclaimer: elements.plan_summary_disclaimer.value,
        planCount,
        noteHeading: elements.enrollment_specialist_header.value,
        noteText: elements.enrollment_specialist_body.value,
        noteCTA: {
          url: elements.enrollment_specialist_cta_link_url.value,
          label: elements.enrollment_specialist_cta_label.value,
        },
        planA,
        planB,
        considerationHeading: elements.plan_breakdown_header.value,
        considerations,
      };

      // temporary solution for hard-coded resource link in Kentico
      if (this.tools.toolTaxonomyCodename !== 'awp_verticals__topics__and_subtopics') {
        // not in AWP.. remove the age-well-planner piece of the CTA URL if present
        data['breakdownPlan'].noteCTA.url = data['breakdownPlan'].noteCTA.url.replace('/age-well-planner', '');
      }

      // breakdown next
      const steps = [];
      elements.next_steps_items.value.forEach((key) => {
        const raw = modular_content[key];

        steps.push({
          headline: raw.elements.heading.value,
          intro: raw.elements.body.value,
        });
      });

      data['breakdownNext'] = {
        heading: elements.next_steps_header.value,
        steps,
      };

      // lead gen
      const leadGenKey = elements.lead_gen.value[0];
      const leadGenRaw = modular_content[leadGenKey];

      let cta;
      let isLeadGenAssessment = false;

      if (leadGenRaw.elements.assessment_id.value && leadGenRaw.elements.cta_text.value) {
        isLeadGenAssessment = true;

        cta = {
          label: leadGenRaw.elements.cta_text.value,
          url: `${this.tools.rootURL}/lead-gen?assessment=${leadGenRaw.elements.assessment_id.value}&broker=${leadGenRaw.elements.broker_type.value[0].codename}&url=${window.location.href}`,
        };
      }

      if (leadGenRaw.elements.external_manual_cta_link.value && leadGenRaw.elements.cta_text.value) {
        cta = {
          label: leadGenRaw.elements.cta_text.value,
          url: leadGenRaw.elements.external_manual_cta_link.value,
        };
      }

      if (leadGenRaw.elements.internal_cta_link.value.length > 0 && leadGenRaw.elements.cta_text.value) {
        const articleKey = leadGenRaw.elements.internal_cta_link.value[0];
        const article = await this.kontent.getItem(articleKey);

        cta = {
          label: leadGenRaw.elements.cta_text.value,
          url: `${this.tools.rootURL}/resource/${article.item.elements.url.value}`,
        };
      }

      const rawURL = new URL(window.location.href);
      let isLeadSent = rawURL.searchParams.get('lead') && rawURL.searchParams.get('lead') === 'sent';
      let leadGenBrokerType = rawURL.searchParams.get('broker');

      if (isLeadGenAssessment) {
        const assessmentID = leadGenRaw.elements.assessment_id.value;
        const completedAssessments = this.tools.customerActivity?.basic?.completedAssessments || [];

        const flag = completedAssessments.find((id) => id === assessmentID);
        isLeadSent = !!flag;
      }

      const leadGenType = leadGenRaw.elements.broker_type.value[0].codename;

      if ((leadGenType === 'medicare' && !healthcare?.hasOwnProperty('medicareBrokerContactInfoShareConsent')) || (leadGenType === 'annuities' && !finances?.hasOwnProperty('annuitiesConsent'))) {
        data['leadGen'] = {
          eyebrow: leadGenRaw.elements.eyebrow.value,
          heading: leadGenRaw.elements.headline.value,
          intro: leadGenRaw.elements.description.value,
          cta,
          photo: {
            src: leadGenRaw.elements.left_image.value[0].url,
            alt: leadGenRaw.elements.left_image.value[0].description,
          },
          icon: {
            src: leadGenRaw.elements.branding_image.value[0].url,
            alt: leadGenRaw.elements.branding_image.value[0].description,
          },
        };
      }

      // lead gen confirmed
      const rawBody = elements.expert_contact_description.value;
      const div = document.createElement('div');
            div.innerHTML = rawBody;

      const links = Array.from(div.querySelectorAll('a[data-item-id]'));

      const urls = await Promise.all(links.map((link) => {
        const id = link.getAttribute('data-item-id');
        return this.kontent.getItem(null, { 'system.id': id });
      })).then(responses => {
        return responses.map((response) => {
          const slug = response.items[0].elements.url.value;
          return `${this.tools.rootURL}/resource/${slug}`;
        });
      });

      links.forEach((link, index) => {
        const text = link.innerHTML;
        link.setAttribute('href', urls[index]);
        link.innerHTML = `<span>${text}</span>`;
      });

      if (leadGenBrokerType === 'medicare') {
        if (healthcare.broker || healthcare.medLeadDuplicate) {
          let brokerType = healthcare.broker ? healthcare.broker.toLowerCase() : healthcare.medLeadDuplicate.toLowerCase();
          switch(brokerType) {
            case "no broker":
              // If user declines information consent
              if(!healthcare.medicareBrokerContactInfoShareConsent) {
                let rawBodyNoConsent = this.tools.rawConfig?.elements.no_consent_description?.value;
                let divNoConsent = document.createElement('div');
                    divNoConsent.innerHTML = rawBodyNoConsent;
                let bodyNoConsent = divNoConsent.innerHTML;

                data['leadGenConfirmed'] = {
                  show: isLeadSent,
                  noCheck: true,
                  headline: this.tools.rawConfig?.elements.no_consent_headline?.value,
                  body:   bodyNoConsent
                }
              } else {
                let rawBodyNoBroker = elements.no_broker_description.value;
                let divNoBroker = document.createElement('div');
                    divNoBroker.innerHTML = rawBodyNoBroker;
                let bodyNoBroker = divNoBroker.innerHTML;

                data['leadGenConfirmed'] = {
                  show: isLeadSent,
                  noCheck: true,
                  headline: elements.no_broker_headline?.value,
                  body:   bodyNoBroker,
                  phone_number: {
                    eyebrow: elements.no_broker_phone_number_eyebrow?.value,
                    value: elements.no_broker_phone_number?.value,
                  },
                  schedule: {
                    eyebrow: elements.no_broker_phone_schedule_eyebrow?.value,
                    value: {
                      text: elements.no_broker_schedule_link_text?.value,
                      url: elements.no_broker_schedule_link_url?.value,
                    },
                  },
                };
              }
              break;

            case "waiting":
              if(!healthcare.medicareBrokerContactInfoShareConsent) {
                let rawBodyNoConsent = this.tools.rawConfig?.elements.no_consent_description?.value;
                let divNoConsent = document.createElement('div');
                    divNoConsent.innerHTML = rawBodyNoConsent;
                let bodyNoConsent = divNoConsent.innerHTML;

                data['leadGenConfirmed'] = {
                  show: isLeadSent,
                  noCheck: true,
                  headline: this.tools.rawConfig?.elements.no_consent_headline?.value,
                  body:   bodyNoConsent
                }
              } else {
                data['leadGenConfirmed'] = {
                  show: isLeadSent,
                }
              }
              break;

            default:
              // If user declines information consent
              if(!healthcare.medicareBrokerContactInfoShareConsent) {
                let rawBodyNoConsent = this.tools.rawConfig?.elements.no_consent_description?.value;
                let divNoConsent = document.createElement('div');
                    divNoConsent.innerHTML = rawBodyNoConsent;
                let bodyNoConsent = divNoConsent.innerHTML;

                data['leadGenConfirmed'] = {
                  show: isLeadSent,
                  noCheck: true,
                  headline: this.tools.rawConfig?.elements.no_consent_headline?.value,
                  body:   bodyNoConsent
                }
              } else {
                let brokerInfo = brokers[brokerType];
                let rawBodyBroker = brokerInfo.expert_contact_description.value;
                let divBroker = document.createElement('div');
                    divBroker.innerHTML = rawBodyBroker;
                let bodyBroker = divBroker.innerHTML;

                data['leadGenConfirmed'] = {
                  show: isLeadSent,
                  noCheck: false,
                  headline: brokerInfo.expert_contact_headline?.value,
                  body: bodyBroker,
                  phone_number: {
                    eyebrow: brokerInfo.expert_contact_phone_number_eyebrow?.value,
                    value: brokerInfo.expert_contact_phone_number?.value,
                  },
                  schedule: {
                    eyebrow: brokerInfo.expert_contact_phone_schedule_eyebrow?.value,
                    value: {
                      text: brokerInfo.expert_contact_schedule_link_text?.value,
                      url: brokerInfo.expert_contact_schedule_link_url?.value,
                    },
                  },
                };
              }
          }
        } else if (!healthcare?.broker) {
          let rawBodyNoConsent = this.tools.rawConfig?.elements.no_consent_description?.value;
          let divNoConsent = document.createElement('div');
              divNoConsent.innerHTML = rawBodyNoConsent;
          let bodyNoConsent = divNoConsent.innerHTML;

          data['leadGenConfirmed'] = {
            show: isLeadSent,
            noCheck: true,
            headline: this.tools.rawConfig?.elements.no_consent_headline?.value,
            body:   bodyNoConsent
          };
        } else if (healthcare.medicareIsShip) {
          let brokerInfo = brokers['ship'];
          let rawBodyBroker = brokerInfo.expert_contact_description.value;
          let divBroker = document.createElement('div');
              divBroker.innerHTML = rawBodyBroker;
          let bodyBroker = divBroker.innerHTML;

          data['leadGenConfirmed'] = {
            show: isLeadSent,
            noCheck: true,
            headline: brokerInfo.expert_contact_headline?.value,
            body: bodyBroker,
            phone_number: {
              eyebrow: brokerInfo.expert_contact_phone_number_eyebrow?.value,
              value: brokerInfo.expert_contact_phone_number?.value,
            },
            schedule: {
              eyebrow: brokerInfo.expert_contact_phone_schedule_eyebrow?.value,
              value: {
                text: brokerInfo.expert_contact_schedule_link_text?.value,
                url: brokerInfo.expert_contact_schedule_link_url?.value,
              },
            },
          }
        } else {
          data['leadGenConfirmed'] = {
            show: isLeadSent
          }
        }
      } else if (leadGenBrokerType === 'annuities') {
        let rawBodyNoConsent = this.tools.rawConfig?.elements.no_consent_description?.value;
        let divNoConsent = document.createElement('div');
            divNoConsent.innerHTML = rawBodyNoConsent;
        let bodyNoConsent = divNoConsent.innerHTML;

        if (finances.hasOwnProperty('annuitiesConsent')) {
          if (finances.annuitiesConsent) {
            // true
            data['leadGenConfirmed'] = {
              show: isLeadSent,
              noCheck: false,
              headline: elements.expert_contact_headline.value,
              body: elements.expert_contact_description.value,
              phone_number: {
                eyebrow: elements.expert_contact_phone_number_eyebrow.value,
                value: elements.expert_contact_phone_number.value,
              },
              schedule: {
                eyebrow: elements.expert_contact_phone_schedule_eyebrow.value,
                value: {
                  text: elements.expert_contact_schedule_link_text.value,
                  url: elements.expert_contact_schedule_link_url.value,
                },
              },
            };
          } else {
            // false (no consent)
            data['leadGenConfirmed'] = {
              show: isLeadSent,
              noCheck: true,
              headline: this.tools.rawConfig?.elements.no_consent_headline?.value,
              body:   bodyNoConsent
            };
          }
        } else {
          // false (empty) (no api response)
          data['leadGenConfirmed'] = {
            show: isLeadSent,
            noCheck: true,
            headline: this.tools.rawConfig?.elements.no_consent_headline?.value,
            body:   bodyNoConsent
          };
        }
      }

      // multi-card 3-up
      const multiCardKey = elements.recommended_topics.value[0];
      const multiCardRaw = modular_content[multiCardKey];

      const heading = multiCardRaw.elements.multi_card_heading.value;
      const cardKeys = multiCardRaw.elements.cards.value;

      let multiCardCTA;

      if (elements.recommended_topics_cta_text.value && elements.recommended_topics_cta_link.value.length > 0) {
        const multiCardCTAKey = elements.recommended_topics_cta_link.value[0];
        const multiCardCTARaw = modular_content[multiCardCTAKey];

        switch (multiCardCTARaw.system.type) {
          case 'ncoa_article_content': {
            multiCardCTA = {
              url: `${this.tools.rootURL}/resource/${multiCardCTARaw.elements.url.value}`,
              label: elements.recommended_topics_cta_text.value,
            };
            break;
          }
          case 'awa_benefits_tool_template___standard_page': {
            multiCardCTA = {
              url: `${this.tools.rootURL}/pages/${multiCardCTARaw.elements.url.value}`,
              label: elements.recommended_topics_cta_text.value,
            };
            break;
          }
          case 'awa_benefits_tool___vertical_subtopic': {
            if (this.tools.routes.find(({ type, codename }) => type === 'vertical-subtopic-learn' && codename === multiCardCTARaw.system.codename)) {
              multiCardCTA = {
                url: this.tools.routes.find(({ type, codename }) => type === 'vertical-subtopic-learn' && codename === multiCardCTARaw.system.codename).route,
                label: elements.recommended_topics_cta_text.value,
              };
            }
            break;
          }
          case 'awa_benefits_tool___vertical_topic': {
            if (this.tools.routes.find(({ type, codename }) => type === 'vertical-topic' && codename === multiCardCTARaw.system.codename)) {
              multiCardCTA = {
                url: this.tools.routes.find(({ type, codename }) => type === 'vertical-topic' && codename === multiCardCTARaw.system.codename).route,
                label: elements.recommended_topics_cta_text.value,
              };
            }
            break;
          }
          case 'awa_benefits_tool___vertical': {
            if (this.tools.routes.find(({ type, codename }) => type === 'vertical' && codename === multiCardCTARaw.system.codename)) {
              multiCardCTA = {
                url: this.tools.routes.find(({ type, codename }) => type === 'vertical' && codename === multiCardCTARaw.system.codename).route,
                label: elements.recommended_topics_cta_text.value,
              };
            }
            break;
          }
        }
      }

      if (cardKeys.length === 3) {
        const cards = await this.getManualMultiCards(cardKeys);

        data['threeUp'] = {
          heading,
          cards,
          moreLink: multiCardCTA,
        };
      }
    }
    return data;
  }

  async getManualMultiCards(cardKeys) {
    const completedAssessments = this.tools.customerActivity.basic?.completedAssessments || [];

    const requests = cardKeys.map((key) => {
      return this.kontent.getItem(key);
    });

    const cards = [];

    await Promise.all(requests)
      .then(responses => {
        responses.forEach((response) => {
          const { item, modular_content } = response as any;

          switch (item.system.type) {
            case 'ncoa_article_content': {
              const div = document.createElement('div');
                    div.innerHTML = item.elements.body_ckeditor?.value || '';
              const text = div.innerText;

              const taxonomies = item.elements[this.tools.toolTaxonomyCodename].value.map((taxonomy) => taxonomy.codename);
              const verticals = taxonomies.map((taxonomy) => this.tools.getVertical(taxonomy).codename);
              let vertical = this.utility.arrayMode(verticals);
                  vertical = this.tools.awpTaxonomyCatalog.find((v) => v.codename === vertical);

              if (item.elements.awp_trusted_expert.value.length > 0) {
                cards.push({
                  id: item.system.id,
                  type: 'referral',
                  eyebrow: `${vertical.name} Expert`,
                  heading: item.elements.title.value,
                  intro: `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`,
                  cta: {
                    label: 'View Details',
                    url: `${this.tools.rootURL}/resource/${item.elements.url.value}`,
                  },
                  photo: item.elements.expert_logo.value.length > 0 ? {
                    url: item.elements.expert_logo.value[0].url,
                    alt: item.elements.expert_logo.value[0].description,
                  } : null,
                  bookmark: true,
                  full: false,
                });
              } else {
                cards.push({
                  id: item.system.id,
                  type: 'article',
                  heading: item.elements.title.value,
                  intro: `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`,
                  cta: {
                    url: `${this.tools.rootURL}/resource/${item.elements.url.value}`,
                    label: 'Read Resource',
                  },
                  breadcrumb: item.elements[this.tools.toolTaxonomyCodename].value.length > 0
                    ? item.elements[this.tools.toolTaxonomyCodename].value[0].name
                    : 'Resource',
                });
              }
              break;
            };
            case 'awa_benefits_tool___vertical_subtopic': {
              const currentRoute = this.tools.routes.find((route) => route.codename && route.codename === item.system.codename);
              const parentTopicRoute = this.tools.routes.find((route) => route.codename && route.codename === currentRoute.parentTopic);
              const parentVerticalRoute = this.tools.routes.find((route) => route.codename && route.codename === parentTopicRoute.parentVertical);

              cards.push({
                id: item.system.id,
                type: 'subtopic',
                eyebrow: `${parentVerticalRoute.taxonomy.name} / ${parentTopicRoute.text}`,
                heading: item.elements.title.value,
                cta: {
                  url: currentRoute.route,
                  label: 'Learn More',
                },
              });

              break;
            }
            case 'awa_benefits_tool___card__tool_': {
              const tool = modular_content[item.elements.tool.value[0]];

              if (tool.elements.assessment_id.value === 'generalAssessment' || tool.elements.assessment_id.value === 'generalAssessmentAWP') {
                cards.push({
                  id: item.system.id,
                  linkedID: tool.system.id,
                  linkedCodename: tool.system.codename,
                  type: 'tool',
                  heading: item.elements.headline.value,
                  cta: {
                    label: completedAssessments.find((key) => key === tool.elements.assessment_id.value) ? 'View Results' : 'Start Assessment',
                    url: this.tools.routes.find((route) => (
                      route.assessmentID &&
                      route.assessmentID === tool.elements.assessment_id.value
                    )).route,
                  },
                  photo: {
                    url: item.elements.card_image.value.length > 0 ? item.elements.card_image.value[0].url : '',
                    alt: item.elements.card_image.value.length > 0 ? item.elements.card_image.value[0].description || '' : '',
                  },
                  variation: 'tools-retirement-single-col',
                });
              } else {
                cards.push({
                  id: item.system.id,
                  linkedID: tool.system.id,
                  linkedCodename: tool.system.codename,
                  type: 'tool',
                  heading: item.elements.headline.value,
                  cta: {
                    label: completedAssessments.find((key) => key === tool.elements.assessment_id.value) ? 'View Results' : 'Start Assessment',
                    url: this.tools.routes.find((route) => (
                      route.assessmentID &&
                      route.assessmentID === tool.elements.assessment_id.value
                    )).route,
                  },
                  photo: {
                    url: item.elements.card_image.value.length > 0 ? item.elements.card_image.value[0].url : '',
                    alt: item.elements.card_image.value.length > 0 ? item.elements.card_image.value[0].description || '' : '',
                  },
                  variation: 'tools-medicare-single-col',
                  theme: 'dark',
                });
              }

              break;
            }
            case 'awa_benefits_tools___promo_card': {
              let url = item.elements.cta_manual_external_link.value;

              if (item.elements.cta_internal_link.value.length > 0) {
                const linkedContent = modular_content[item.elements.cta_internal_link.value[0]];

                switch (linkedContent.system.type) {
                  case 'ncoa_article_content': {
                    url = `${this.tools.rootURL}/resource/${linkedContent.elements.url.value}`;
                    break;
                  }
                  case 'standard_page': {
                    url = `${this.tools.rootURL}/page/${linkedContent.elements.url.value}`;
                    break;
                  }
                  case 'awa_benefits_tool___vertical_topic': {
                    url = (this.tools.routes.find((route) => (
                      route.type && route.codename &&
                      route.type === 'vertical-topic' &&
                      route.codename === linkedContent.system.codename
                    )) || { route: null }).route;
                    break;
                  }
                  case 'awa_benefits_tool___vertical': {
                    url = (this.tools.routes.find((route) => (
                      route.type && route.codename &&
                      route.type === 'vertical' &&
                      route.codename === linkedContent.system.codename
                    )) || { route: null }).route;
                    break;
                  }
                  case 'awa_benefits_tool___vertical_subtopic': {
                    url = (this.tools.routes.find((route) => (
                      route.type && route.codename &&
                      route.type === 'vertical-subtopic-learn' &&
                      route.codename === linkedContent.system.codename
                    )) || { route: null }).route;
                    break;
                  }
                }
              }

              if (url) {
                cards.push({
                  type: 'promo',
                  background: {
                    url: item.elements.background_image.value.length > 0 ? item.elements.background_image.value[0].url : '',
                    alt: item.elements.background_image.value.length > 0 ? item.elements.background_image.value[0].description : '',
                  },
                  eyebrow: item.elements.eyebrow.value,
                  heading: item.elements.headline.value,
                  cta: {
                    label: item.elements.cta_text.value,
                    url,
                  }
                });
              }

              break;
            }
          }
        });
      });

    return cards;
  }
}
