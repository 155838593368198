import { Component, OnInit, Input, ElementRef, ViewEncapsulation } from '@angular/core';
import { ArticleCardImageType, ArticleCardCTAType } from './article-card.types';

@Component({
  selector: 'ncoa-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleCard implements OnInit {
  @Input() type: string = null;
  @Input() title: string;
  @Input() eyebrow: string;
  @Input() image: ArticleCardImageType;
  @Input() cta: ArticleCardCTAType;
  @Input() articleType: string;

  constructor(private element: ElementRef) { }

  ngOnInit(): void {
    this.element.nativeElement.style.display = 'contents';
  }

}
