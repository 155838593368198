import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ncoa-category-hero',
  templateUrl: './category-hero.component.html',
  styleUrls: ['./category-hero.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CategoryHeroComponent {

  @Input() category: {
    breadcrumbs: Array<any>
    headline: string,
    description: string,
    photo: {
      responsive: any,
      url: string,
      alt: string
    },
  };
}
