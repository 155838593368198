import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { ActivatedRoute } from '@angular/router';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { SeoService } from '@services/seo.service';
import { UrlService } from '@services/url.service';
import { DataLayerService } from '@services/data-layer.service';
import { ErrorService } from '@services/error.service';
import * as utc from 'dayjs/plugin/utc';
import { ComponentService } from './component.service';
import { ModalService } from './modal.service';

// Add utc
dayjs.extend(utc);

@Injectable({
  providedIn: 'root'
})
export class AuthorService {
  articlesPerPage: number = 6;

  limit: number = 42;
  originalItems: any[] = [];
  displayedItems: any[] = [];
  totalItems: number = 0;

  constructor(
    private activeRoute: ActivatedRoute,
    private kontentDeliveryService: KontentDeliveryService,
    private seoService: SeoService,
    private urlService: UrlService,
    private dataLayerService: DataLayerService,
    private errorService: ErrorService,
    private componentService: ComponentService,
    private modalService: ModalService,
  ) { }

  getAuthorData(authorURLSlug) {
    return this.kontentDeliveryService.getItem(null, {
      'elements.author_url_slug': authorURLSlug,
      limit: 1,
      depth: 5
    }).then(rawAPIData => {
      if ( !rawAPIData.items.length ) {
        this.errorService.notFound();
        return;
      }

      const authorData = { item: rawAPIData.items[0], ...rawAPIData };
      const authorCodename = authorData.item.system.codename;

      return Promise.all([
        authorData,
        this.getAuthorArticles(authorCodename),
      ]).then(([ authorData, articles ]) => (
        this.processAPIData(authorData, articles)
      ));
    });
  }

  async getAuthorArticles(authorCodename) {
    const deDupe = (array) => {
      return array.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    };

    let result = await this.kontentDeliveryService.getItem(null, {
      'system.type[in]': 'ncoa_article_content',
      'elements.author[contains]': authorCodename,
      'limit': this.limit,
      'skip': 0,
      'includeTotalCount': true,
      'order': 'elements.display_date[desc]',
    });
    if(result.items && result.items.length > 0) {
    this.processRawList(result.items);
    }
    if(result.pagination && result.pagination.total_count && result.pagination.total_count > this.limit) {
    let otherRequest = Array.apply(null, Array(Math.round(result.pagination.total_count / this.limit))).map((_, index) => (
      this.kontentDeliveryService.getItem(null, {
        'system.type[in]': 'ncoa_article_content',
        'elements.author[contains]': authorCodename,
        'limit': this.limit,
        'skip': (this.limit * index),
        'includeTotalCount': true,
        'order': 'elements.display_date[desc]',
      })
    ));

    otherRequest.shift();

    await this.getOtherRequests(otherRequest);
    }
 
    result = await this.kontentDeliveryService.getItem(null, {
      'system.type[in]': 'ncoa_article_content',
      'elements.secondary_authors[contains]': authorCodename,
      'limit': this.limit,
      'skip': 0,
      'includeTotalCount': true,
      'order': 'elements.display_date[desc]',
    });
    if(result.items && result.items.length > 0) {
    this.processRawList(result.items);
  }

  if(result.pagination && result.pagination.total_count && result.pagination.total_count > this.limit) {
    let otherRequest = Array.apply(null, Array(Math.round(result.pagination.total_count / this.limit))).map((_, index) => (
      this.kontentDeliveryService.getItem(null, {
        'system.type[in]': 'ncoa_article_content',
        'elements.secondary_authors[contains]': authorCodename,
        'limit': this.limit,
        'skip': (this.limit * index),
        'includeTotalCount': true,
        'order': 'elements.display_date[desc]',

      })
    ));

    otherRequest.shift(); 

    await this.getOtherRequests(otherRequest);
  }

    this.originalItems = deDupe(this.originalItems);
    this.displayedItems = this.originalItems;

    return {
      data: this.displayedItems.slice(0, 42),
      displayed: 42,
    };
  }

  async getOtherRequests(otherRequest) {
    return await Promise.all(otherRequest).then(data => {
      data.forEach(({ items }) => this.processRawList(items));
      return this.originalItems;
    });
  }

  processRawList(list) {
    list.forEach(item => {
      const id = item?.system.id;
      const cta = {
        text: item?.elements.event_date_time.value ? 'View Event Details' : 'Read Article',
        url: this.urlService.buildArticleURL(item?.elements.url.value),
      };
      const eyebrow = item?.elements.display_date.value ? dayjs.utc(item?.elements.display_date.value).format('MMM DD, YYYY') : '';
      const image = item?.elements.primary_image.value.length > 0 ? {
        url: item?.elements.primary_image.value[0].url,
        alt: item?.elements.primary_image.value[0].description,
      } : null;
      const title = item?.elements.title.value;
      const type = {
        codename: item?.elements.event_date_time.value ? 'event' : 'article',
        name: item?.elements.event_date_time.value ? 'Event' : 'Article',
      };

      this.originalItems.push({
        id,
        cta,
        eyebrow,
        image,
        title,
        type,
      });
    });
  }

  getMoreData(skip) {
    return {
      items: this.displayedItems.splice(skip, this.limit),
      displayed: skip + this.limit,
    };
  }

  processAPIData(rawAuthorData, articleData) {
    const headerData = this.extractHeaderData(rawAuthorData);

    this.seoService.extractAndRender(rawAuthorData.item, {
      title: headerData.authorName,
      image: headerData.image[0]?.url,
      description: headerData.authorBio
    });

    // Push analytics data
    this.dataLayerService.push({
      url: location.href,
      pageTitle: headerData.authorName,
      contentType: 'author',
      category: '',
      audience: ''
    });

    return {
      author: rawAuthorData.item.system.codename,
      headerData,
      articleData: {
        title: `Latest from ${headerData.authorName}`,
        data: articleData,
      },
      components: this.extractAdditionalComponents(rawAuthorData)
    }
  }

  extractAuthorData(rawAuthorData) {
    return this.kontentDeliveryService.extractItemElements(rawAuthorData.item, [
      'author_name', 'author_biography', 'author_headshot', 'author_position',
      'facebook_url', 'linkedin_url', 'twitter_url', 'email'
    ]);
  }

  extractHeaderData(rawAPIData) {
    const {
      author_name,
      author_biography,
      author_headshot,
      author_position,
      facebook_url,
      linkedin_url,
      twitter_url,
      email
    }: any = this.extractAuthorData(rawAPIData);

    const socialLinks = this.buildSocialLinks({
      facebook: facebook_url,
      linkedin: linkedin_url,
      twitter: twitter_url,
      email: email
    });
    
    return {
      image: {
        url: author_headshot[0]?.url || '',
        alt: author_headshot[0]?.description || ''
      },
      authorName: author_name,
      authorTitle: author_position,
      authorBio: author_biography,
      links: socialLinks,
      eyebrow: 'Articles by',
      // isInCategorySearch
    }
  }

  buildSocialLinks(socialLinks) {
    return Object.entries(socialLinks).reduce((all, [key, value]) => {
      if ( key ) {
        all.push({
          icon: key,
          url: value
        });
      }
      return all;
    }, []);
  }

  extractAdditionalComponents(rawAPIData) {
    const { additional_modules }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.items[0], ['additional_modules']);
    const rawComponentItems = this.kontentDeliveryService.extractModularContent(rawAPIData, additional_modules);

    // Remove cross pathing module
    const filteredRawComponentItems = rawComponentItems.filter(itemData => (
      this.componentService.componentTypes.cross_pathing_module !==  itemData.system.type
    ));
    const processedComponents = this.componentService.processRawComponents(filteredRawComponentItems, rawAPIData.modular_content)
    this.modalService.showAvailableModal(null);

    return processedComponents;
  }
}
