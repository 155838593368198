import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { stateOptions } from '@components/planner/benefits-listing/benefits-listing.variables';
import { DataLayerService } from '@services/data-layer.service';
import { BenefitsService } from '@services/planner/benefits.service';
import { RebaDataService } from '@services/planner/reba-data.service';

@Component({
  selector: 'ncoa-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BenefitsComponent implements OnInit {

  @Input() headline: string;
  @Input() description: string;
  @Input() image: {
    url: string,
    alt: string,
    responsive: any
  };
  @Input() verticals: any[];

  stateOptions: any[];
  selection: any[];
  state: any = '';
  hasError: boolean = false;

  constructor(
    private tools: ToolsPrimaryConfigService,
    private dataLayerService: DataLayerService,
    private benefitsService: BenefitsService,
  ) { }

  ngOnInit(): void {
    this.stateOptions = stateOptions;
    this.benefitsService.getCategories().then(categories=>{
      this.selection = categories.map((item) => ({
        value: item.codename,
        name: item.name,
        codename : item.codename,
        isSelected: false,
    }))
  });
  }
  selectAll() {
    this.selection = this.selection.map((selection) => ({
      ...selection,
      isSelected: true,
    }));
  }

  updateUrlWithDomain(urlString: string){
    return urlString.includes('http')? urlString : location.origin + urlString;
  }

  submit(e) {
    e.preventDefault();

    if (this.state === '') {
      this.hasError = true;
    } else {
      this.hasError = false;
      let benefits = [];
      let checkedOptions = [];
      const selectedValueText = stateOptions.find(item => item.value === this.state).text;
      this.selection
        .filter((selection) => selection.isSelected)
        .forEach((selection) => {
          benefits.push(selection.codename);
          checkedOptions.push(selection.label);
        });
      let url = this.tools.routes.find((route) => route.type === 'all-benefits').route;
          url = `${url}?state=${this.state === '' ? 'NY' : this.state}`;

      if (checkedOptions.length > 0) {
        benefits = [...new Set(benefits)];
        url = `${url}&categories=${benefits.join(',')}`;


          this.dataLayerService.push({ 
            'event': 'benefits', 
            'checkedOptions': checkedOptions.join(', '),
            'state': selectedValueText,
            'componentType': 'find benefits',
            'url': this.updateUrlWithDomain(url) },false); 
        

      }else{
        this.dataLayerService.push({ 
          'event': 'benefits', 
          'checkedOptions': '',
          'state': selectedValueText,
          'componentType': 'find benefits',
          'url': this.updateUrlWithDomain(url) },false); 
      }
      

      window.location.href = url;
    }
  }
}
