import {
  BrowserModule,
  Title,
  Meta,
  TransferState,
} from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from '@environment';

import { GridOverlay, StorybookWrapper } from '@sb/index';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalNav } from './components/global-nav/global-nav.component';
import { BasicList } from './components/basic-list/basic-list.component';
import { Button } from './components/button/button.component';
import { HomeHero } from './components/home-hero/home-hero.component';
import { GlobalFooterComponent } from './components/global-footer/global-footer.component';
import { FeaturePageBlockComponent } from './components/feature-page-block/feature-page-block.component';
import { ArticleCard } from './components/article-card/article-card.component';
import { ArticleCardGroup } from './components/article-card-group/article-card-group.component';
import { ContentPackage } from './components/content-package/content-package.component';
import { ExternalContentPackage } from '@components/external-content-package/external-content-package.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { OurMissionComponent } from './components/our-mission/our-mission.component';
import { FeaturedToolBreakerComponent } from './components/featured-tool-breaker/featured-tool-breaker.component';
import { SubcategoryPathingComponent } from './components/subcategory-pathing/subcategory-pathing.component';

import { GlobalService } from './services/global.service';
import { KontentDeliveryService } from './services/kontent-delivery.service';
import { HomepageService } from './services/homepage.service';
import { NavigationService } from './services/navigation.service';
import { ComponentService } from './services/component.service';
import { SearchService } from './services/search.service';
import { UrlService } from './services/url.service';
import { ArticleService } from './services/article.service';
import { SeoService } from './services/seo.service';
import { DataLayerService } from './services/data-layer.service';
import { FormsService } from './services/forms.service';
import { ModalService } from './services/modal.service';
import { QualtricsService } from './services/qualtrics.service';
import { PdfService } from './services/pdf.service';
import { ErrorService } from './services/error.service';

import { Home } from './pages/home/home.component';
import { Default } from './templates/default/default.component';
import { AgeWellPlanner } from './templates/age-well-planner/age-well-planner.component';
import { AgeWellPlannerAssessment } from './templates/age-well-planner-assessment/age-well-planner-assessment.component';
import { CategoryHeroComponent } from './components/category-hero/category-hero.component';
import { DonateComponent } from './components/donate/donate.component';
import { ExternalFormComponent } from '@components/external-form/external-form.component';
import { AudienceComponent } from './components/audience/audience.component';
import { TemporaryLandingPage } from './pages/temporary-landing/temporary-landing.component';
import { TopicComponent } from './components/topic/topic.component';
import { LoadMoreComponent } from './components/load-more/load-more.component';
import { AuthorComponent } from './pages/author/author.component';
import { ResultCardComponent } from './components/result-card/result-card.component';
import { ResultsListComponent } from './components/results-list/results-list.component';
import { EventResultsListComponent } from '@components/event-results-list/event-results-list.component'; 
import { AuthorHeaderComponent } from './components/author-header/author-header.component';
import { SearchComponent } from './pages/search/search.component';
import { ArticleComponent } from './pages/article/article.component';
import { TakeawaysComponent } from './components/takeaways/takeaways.component';
import { AuthorBylineComponent } from './components/author-byline/author-byline.component';
import { PartnersComponent } from './components/partners/partners.component';
import { RelatedArticlesComponent } from './components/related-articles/related-articles.component';
import { EventHeroComponent } from './components/event-hero/event-hero.component';
import { ListHeaderComponent } from './components/list-header/list-header.component';
import { ArticleBodyComponent } from './components/article-body/article-body.component';
import { ArticleHeaderComponent } from './components/article-header/article-header.component';
import { ArticleFeedbackComponent } from './components/article-feedback/article-feedback.component';
import { InlineTableComponent } from './components/inline-table/inline-table.component';
import { ModalComponent } from './components/modal/modal.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { PartnersCategoryComponent } from './components/partners-category/partners-category.component';
import { CategorySearchComponent } from './pages/category-search/category-search.component';
import { CustomListHeaderComponent } from './components/custom-list-header/custom-list-header.component';
import { MapComponent } from './components/map/map.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';

import { FormAssembly as FormAssemblyComponent } from '@components/forms/FormAssembly/form-assembly.component';
import { ContactPage } from './pages/contact/contact.component';
import { ProgramsComponent } from './pages/programs/programs.component';
import { PartnersGridComponent } from './components/partners-grid/partners-grid.component';
import { StandardPageHeaderComponent } from './components/standard-page-header/standard-page-header.component';
import { SpecialPathingComponent } from './components/special-pathing/special-pathing.component';
import { ArticleShareComponent } from './components/article-share/article-share.component';
import { StandardPageComponent } from './pages/standard/standard.component';
import { LeadershipGridComponent } from './components/leadership-grid/leadership-grid.component';
import { SafePipe } from './pipes/safe.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { GetInvolvedComponent } from './pages/get-involved/get-involved.component';
import { CoursesComponent } from './components/courses/courses.component';
import { NcoaLandingPageComponent } from '@pages/landing-pages/ncoa/ncoa-landing-page.component';

import { EngageableDirective } from './directives/engageable.directive';
import { ChunkPipe } from './pipes/chunk.pipe';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ServerErrorComponent } from './pages/server-error/server-error.component';
import { ShareLinkDirective } from './directives/share-link.directive';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { TaxonomyComponent } from './pages/taxonomy/taxonomy.component';

import { HomeResolverService } from '@services/home-resolver.service';
import { ArticleResolverService } from '@services/article-resolver.service';
import { AuthorResolverService } from '@services/author-resolver.service';
import { StandardResolverService } from '@services/standard-resolver.service';
import { SearchResolverService } from '@services/search-resolver.service';
import { GetInvolvedService } from '@services/get-involved.service';
import { FocusTrapService } from '@services/focus-trap.service';
import { MinimalComponent } from './templates/minimal/minimal.component';
import { MyPlannerHeader } from './components/planner/my-planner-header/my-planner-header.component';
import { ArticleDetailHeader } from './components/planner/article-detail-header/article-detail-header.component';
import { VerticalLandingHeaderComponent } from '@components/planner/vertical-landing-header/vertical-landing-header.component';
import { AuthorBylinePlannerComponent } from '@components/planner/author-byline/author-byline-planner.component';
import { BenefitsComponent } from '@components/planner/benefits/benefits.component';
import { GlobalHeaderComponent } from '@components/planner/global-header/global-header.component';
import { HeaderLandingComponent } from '@components/planner/header-landing/header-landing.component';
import { EditBasicInfoComponent } from './components/planner/edit-basic-info/edit-basic-info.component';
import { AccountProfileHeaderComponent } from '@components/planner/account-profile/account-profile-header.component';
import { EditAccountInfoComponent } from './components/planner/edit-account-info/edit-account-info.component';
import { VerticalPathing } from './components/planner/vertical-pathing/vertical-pathing.component';
import { AccountLoginComponent } from './components/planner/account-login/account-login.component';
import { TopicListing } from './components/planner/topic-listing/topic-listing.component';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { CustomerContactService } from '@services/planner/customer-contact.service';
import { UserService } from '@services/planner/user.service';
import { UtilityService } from '@services/planner/utility.service';
import { BenefitCard } from './components/planner/benefit-card/benefit-card.component';
import { ExploreAllBenefits } from './pages/planner/explore-all-benefits/explore-all-benefits.component';
import { BenefitsListing } from './components/planner/benefits-listing/benefits-listing.component';
import { VerticalPageBenefitsLookUp } from './pages/planner/vertical-page/benefits-look-up/benefits-look-up.component';
import { GlobalFooterPlannerComponent } from './components/planner/global-footer-planner/global-footer-planner.component';
import { ThreeUpComponent } from './components/planner/three-up/three-up.component';
import { SignIn } from './pages/planner/sign-in/sign-in.component';
import { ArticleResourcePageComponent } from './pages/planner/article-resource-page/article-resource-page.component';
import { MyPlannerPage } from './pages/planner/my-planner-page/my-planner-page.component';
import { PlannerHomePage } from './pages/planner/planner-home-page/planner-home-page.component';
import { RecommendationHeaderComponent } from '@components/planner/recommendation-header/recommendation-header.component';
import { SignInBanner } from './components/planner/sign-in-banner/sign-in-banner.component';
import { CardComponent } from '@components/planner/card/card.component';
import { SubtopicHeaderComponent } from '@components/planner/subtopic-header/subtopic-header.component';
import { VerticalRecommendedComponent } from '@components/planner/vertical-recommended/vertical-recommended.component';
import { OneUpComponent } from '@components/planner/one-up/one-up.component';
import { TextWithImageComponent } from '@components/planner/text-with-image/text-with-image.component';
import { ArticleGroupingGridComponent } from '@components/planner/article-grouping-grid/article-grouping-grid.component';
import { SingleColCardComponent } from '@components/planner/single-col-card/single-col-card.component';
import { SubtopicToolsComponent } from './pages/planner/subtopic-tools/subtopic-tools.component';
import { LeadGenComponent } from './components/planner/lead-gen/lead-gen.component';
import { SubtopicExpertComponent } from './pages/planner/subtopic-expert/subtopic-expert.component';
import { SubtopicLearnComponent } from './pages/planner/subtopic-learn/subtopic-learn.component';
import { SubtopicLandingComponent } from '@pages/planner/subtopic-landing/subtopic-landing.component';
import { AccountRegister } from './components/planner/account-register/account-register.component';
import { SignUp } from './pages/planner/sign-up/sign-up.component';
import { DataService } from '@services/planner/data.service';
import { AccountVerified } from './pages/planner/account-verified/account-verified.component';
import { ForgotPassword } from './pages/planner/forgot-password/forgot-password.component';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { AssessmentService } from '@services/planner/assessment.service';
import { Assessment } from './components/planner/assessment/assessment.component';

import { SaveContentComponent } from './components/planner/save-content/save-content.component';
import { BecFinderComponent } from './components/planner/bec-finder/bec-finder.component';
import { TrustedExpertPageComponent } from './pages/planner/trusted-expert-page/trusted-expert-page.component';
import { BenefitCardModalComponent } from './components/planner/benefit-card-modal/benefit-card-modal.component';
import { BenefitsService } from '@services/planner/benefits.service';
import { SavedToPlannerHeaderComponent } from './components/planner/save-content-page-header/saved-to-planner-header.component';
import { SavedToPlannerListingComponent } from './components/planner/saved-to-planner-listing/saved-to-planner-listing.component';
import { AssessmentPage } from './pages/planner/assessment/assessment.component';
import { VerticalPage } from './pages/planner/vertical-page/vertical-page.component';
import { VerticalService } from '@services/planner/vertical.service';
import { VerticalTopic } from './pages/planner/vertical-page/topic/topic-page.component';
import { ResourceService } from '@services/planner/resource.service';
import { SubTopicService } from '@services/planner/subtopic.service';
import { ContactFormPageComponent } from './pages/planner/contact-form-page/contact-form-page.component';
import { StandardComponent } from './pages/planner/standard/standard.component';
import { MyPlannerService } from '@services/planner/my-planner.service';
import { SavedContentsService } from '@services/planner/saved-contents.service';
import { PartnershipComponent } from './components/partnership/partnership.component';

import { UserPromptModalComponent } from './components/planner/user-prompt-modal-component/user-prompt-modal-component.component';
import { PlannerHomePageService } from '@services/planner/planner-homepage.service';
import { BreakdownHeaderComponent } from './components/planner/breakdown-header/breakdown-header.component';
import { BreakdownPartnershipComponent } from './components/planner/breakdown-partnership/breakdown-partnership.component';
import { AssessmentSummaryComponent } from './pages/planner/assessment-summary/assessment-summary.component';
import { BreakdownGraphComponent } from './components/planner/breakdown-graph/breakdown-graph.component';
import { BreakdownNextComponent } from './components/planner/breakdown-next/breakdown-next.component';
import { BreakdownHealthcareComponent } from './components/planner/breakdown-healthcare/breakdown-healthcare.component';
import { BreakdownPlansComponent } from './components/planner/breakdown-plans/breakdown-plans.component';
import { CostEstimatorComponent } from './pages/planner/cost-estimator/cost-estimator.component';
import { FallsRiskComponent } from '@pages/planner/falls-risk/falls-risk.component';
import { AccountProfile } from './pages/planner/account-profile/account-profile.component';
import { ProfileService } from '@services/planner/profile.service';
import { ProfilePanel } from './components/planner/profile-panel/profile-panel.component';
import { SaveToPlannerComponent } from './pages/planner/save-to-planner/save-to-planner.component';
import { LeadGen } from './pages/planner/lead-gen/lead-gen.component';
import { CostEstimatorService } from '@services/planner/cost-estimator.service';
import { FallsRiskService } from '@services/planner/falls-risk.service';
import { UserPromptService } from '@services/planner/user-prompt.service';

import { MedicareContactComponent } from '@pages/landing-pages/medicare-contact/medicare-contact.component';
import { AnnuitiesComponent } from './pages/landing-pages/annuities/annuities.component';
import { ThankYouComponent } from '@pages/landing-pages/thank-you/thank-you.component';
import { NgxMaskModule } from 'ngx-mask';
import { EventCustomSearchComponent } from './pages/event-custom-search/event-custom-search.component';
import { EventCustomSearchService } from '@services/event-custom-search.service';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { LandingPageHeaderComponent } from './components/landing-pages/landing-page-header/landing-page-header.component';
import { LandingPageContentPackage } from './components/landing-pages/landing-page-content-package/landing-page-content-package.component';

import { MapService } from '@services/map.service';
import { CategoryService } from '@services/category.service';
import { ModulesService } from '@services/modules.service';
import { SpecialPageService } from '@services/special-page.service';
import { GenericResolverService } from '@services/generic-resolver.service';
import { MedicareLeadGenComponent } from '@components/planner/medicare-lead-gen/medicare-lead-gen.component';
import { EmbeddedJsComponent } from './components/embedded-js/embedded-js.component';
import { SponsorsComponent } from './components/planner/sponsors/sponsors.component';
import { AWPPartnerGridComponent } from '@components/planner/partner-grid/partner-grid.component';
import { NcoaLandingPageService } from '@services/ncoa-landing-page.service';

import { PartnerLandingComponent } from '@pages/partner-landing/partner-landing.component';
import { CTAButtonComponent } from '@components/planner/cta-button/cta-button.component';
import { NextStepComponent } from '@components/planner/next-step/next-step.component';
import { FormModalComponent } from '@components/planner/form-modal/form-modal.component';
import { PlanComponent } from '@pages/planner/assessment-plan/plan/plan.component';
import { ToDoComponent } from '@components/planner/plan/to-do/to-do.component';
import { BackButtonComponent } from '@components/planner/back-button/back-button.component';
import { CheckboxComponent } from '@components/planner/form/checkbox/checkbox.component';
import { MultiSelectComponent } from '@components/planner/form/multi-select/multi-select.component';
import { QuestionLabelComponent } from '@components/planner/form/question-label/question-label.component';
import { QuestionComponent } from '@components/planner/form/question/question.component';
import { StepComponent } from '@components/planner/form/stepper/step/step.component';
import { StepperComponent } from '@components/planner/form/stepper/stepper.component';
import { TooltipDirective } from '@components/planner/form/tooltip.directive';
import { ScreeningComponent } from '@pages/planner/screening/screening.component';
import { VerticalAssessmentComponent } from './components/planner/vertical-assessment/vertical-assessment.component';
import { ToDoListComponent } from '@components/planner/plan/to-do-list/to-do-list.component';
import { TodoPageComponent } from '@pages/planner/assessment-plan/todo-page/todo-page.component';
import { FeatureContentBlockComponent } from '@components/planner/feature-content-block/feature-content-block.component';
import { AnalyzeYourBudgetComponent } from '@components/planner/plan/budget-calculator/analyze-your-budget/analyze-your-budget.component';
import { AccordianComponent } from '@components/accordian/accordian.component';
import { AmountQuestionComponent } from '@components/planner/plan/budget-calculator/analyze-your-budget/amount-question/amount-question.component';
import { TopicsComponent } from '@components/planner/plan/budget-calculator/analyze-your-budget/topics/topics.component';
import { ScreeningGroupTitlePipe } from './pipes/screening-group-title.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DynamicFormsComponent } from '@components/dynamic-forms/dynamic-forms.component';
import { CaseManagerComponent } from '@pages/planner/case-manager/case-manager.component';
import { ClientPlanComponent } from '@pages/planner/case-manager/plan/plan.component';
import { ClientTodoPageComponent } from '@pages/planner/case-manager/todo-page/todo-page.component';
import { ClientScreeningComponent } from '@pages/planner/case-manager/client-screening/client-screening.component';
import { CaseManagerAnalyzeYourBudgetComponent } from '@pages/planner/case-manager/analyze-your-budget/analyze-your-budget.component';
import { ClientTopicsComponent } from '@pages/planner/case-manager/analyze-your-budget/topics/topics.component';
import { OurSponsorsComponent } from '@pages/our-sponsors/our-sponsors.component';
import { PartnerCardBlockComponent } from '@components/partner-card-block/partner-card-block.component';
import { CmBannerComponent } from '@components/planner/cm-banner/cm-banner.component';
import { BannerComponent } from '@components/banner/banner.component';



if (/qa|prod|preview/.test(environment.environment)) {
  enableProdMode();
}

@NgModule({
  declarations: [
    StorybookWrapper,
    GridOverlay,
    // Components
    GlobalNav,
    GlobalFooterComponent,
    AnalyzeYourBudgetComponent,
    BasicList,
    TodoPageComponent,
    ToDoComponent,
    Button,
    HomeHero,
    ArticleCard,
    PlanComponent,
    ArticleCardGroup,
    ContentPackage,
    ExternalContentPackage,
    BackButtonComponent,
    StepComponent,
    StepperComponent,
    AccordianComponent,
    ScreeningGroupTitlePipe,
    TooltipDirective,
    CheckboxComponent,
    AmountQuestionComponent,
    QuestionLabelComponent,
    MultiSelectComponent,
    TopicsComponent,
    ScreeningComponent,
    QuestionComponent,
    GlobalFooterComponent,
    FeaturePageBlockComponent,
    OurMissionComponent,
    FeaturedToolBreakerComponent,
    SubcategoryPathingComponent,
    CategoryHeroComponent,
    DonateComponent,
    ExternalFormComponent,
    NewsletterComponent,
    AudienceComponent,
    TopicComponent,
    LoadMoreComponent,
    AuthorComponent,
    ResultCardComponent,
    ResultsListComponent,
    EventResultsListComponent,
    AuthorHeaderComponent,
    SearchComponent,
    TakeawaysComponent,
    AuthorBylineComponent,
    PartnersComponent,
    EventHeroComponent,
    ListHeaderComponent,
    ArticleBodyComponent,
    RelatedArticlesComponent,
    ArticleComponent,
    ArticleHeaderComponent,
    ArticleFeedbackComponent,
    InlineTableComponent,
    ModalComponent,
    CookiesComponent,
    PartnersCategoryComponent,
    CategorySearchComponent,
    CustomListHeaderComponent,
    MapComponent,
    PdfViewerComponent,
    DatePickerComponent,
    PartnershipComponent,
    FeatureContentBlockComponent,
    DynamicFormsComponent,
    // Templates
    Default,
    AgeWellPlanner,
    AgeWellPlannerAssessment,
    // Pages
    Home,
    VerticalPageBenefitsLookUp,
    ExploreAllBenefits,
    SignIn,
    SignUp,
    MyPlannerPage,
    PlannerHomePage,
    RecommendationHeaderComponent,
    AccountVerified,
    ForgotPassword,
    PlannerHomePage,
    AssessmentPage,
    VerticalPage,
    VerticalTopic,
    AccountProfile,
    EventCustomSearchComponent,
    CaseManagerComponent,
    // Main App
    AppComponent,
    TemporaryLandingPage,
    // Forms
    FormAssemblyComponent,
    ContactPage,
    ProgramsComponent,
    PartnersGridComponent,
    StandardPageHeaderComponent,
    SpecialPathingComponent,
    ArticleShareComponent,
    StandardPageComponent,
    LeadershipGridComponent,
    SafePipe,
    GetInvolvedComponent,
    CoursesComponent,
    EngageableDirective,
    ChunkPipe,
    NotFoundComponent,
    ServerErrorComponent,
    ShareLinkDirective,
    CoursesComponent,
    ModalContainerComponent,
    TaxonomyComponent,
    MinimalComponent,
    MyPlannerHeader,
    ArticleDetailHeader,
    VerticalLandingHeaderComponent,
    AuthorBylinePlannerComponent,
    BenefitsComponent,
    GlobalHeaderComponent,
    HeaderLandingComponent,
    AccountProfileHeaderComponent,
    EditBasicInfoComponent,
    EditAccountInfoComponent,
    VerticalPathing,
    AccountLoginComponent,
    TopicListing,
    ToDoListComponent,
    BenefitCard,
    BenefitsListing,
    CardComponent,
    GlobalFooterPlannerComponent,
    ArticleResourcePageComponent,
    ThreeUpComponent,
    SignInBanner,
    SubtopicHeaderComponent,
    VerticalRecommendedComponent,
    OneUpComponent,
    TextWithImageComponent,
    ArticleGroupingGridComponent,
    SingleColCardComponent,
    SubtopicToolsComponent,
    LeadGenComponent,
    SubtopicExpertComponent,
    SubtopicLearnComponent,
    SubtopicLandingComponent,
    AccountRegister,
    SaveContentComponent,
    BecFinderComponent,
    TrustedExpertPageComponent,
    BenefitCardModalComponent,
    SavedToPlannerHeaderComponent,
    SavedToPlannerListingComponent,
    Assessment,
    ContactFormPageComponent,
    StandardComponent,
    UserPromptModalComponent,
    BreakdownHeaderComponent,
    BreakdownPartnershipComponent,
    AssessmentSummaryComponent,
    BreakdownGraphComponent,
    BreakdownNextComponent,
    BreakdownHealthcareComponent,
    BreakdownPlansComponent,
    CostEstimatorComponent,
    FallsRiskComponent,
    ProfilePanel,
    SaveToPlannerComponent,
    LeadGen,
    MedicareLeadGenComponent,
    // Landing Pages
    MedicareContactComponent,
    ThankYouComponent,
    LandingPageHeaderComponent,
    LandingPageContentPackage,
    NcoaLandingPageComponent,
    OurSponsorsComponent,
    

    SafeHtmlPipe,
    AnnuitiesComponent,
    EmbeddedJsComponent,
    SponsorsComponent,
    AWPPartnerGridComponent,
    PartnerLandingComponent,
    CTAButtonComponent,
    NextStepComponent,
    FormModalComponent,
    VerticalAssessmentComponent,
    ClientPlanComponent,
    ClientTodoPageComponent,
    ClientScreeningComponent,
    CaseManagerAnalyzeYourBudgetComponent,
    ClientTopicsComponent,
    PartnerCardBlockComponent,
    CmBannerComponent,
    BannerComponent,

  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot({ validation: false }),
  ],
  providers: [
    GlobalService,
    KontentDeliveryService,
    HomepageService,
    NavigationService,
    ComponentService,
    SearchService,
    UrlService,
    ArticleService,
    Title,
    Meta,
    SeoService,
    FormsService,
    DataLayerService,
    ModalService,
    QualtricsService,
    PdfService,
    ErrorService,
    HomeResolverService,
    ArticleResolverService,
    AuthorResolverService,
    StandardResolverService,
    GetInvolvedService,
    FocusTrapService,
    TransferState,
    SearchResolverService,
    CustomerActivityService,
    CustomerContactService,
    UserService,
    UtilityService,
    UserPromptService,
    DataService,
    ToolsPrimaryConfigService,
    AssessmentService,
    BenefitsService,
    VerticalService,
    ResourceService,
    SubTopicService,
    MyPlannerService,
    PlannerHomePageService,
    SavedContentsService,
    CostEstimatorService,
    FallsRiskService,
    ProfileService,
    EventCustomSearchService,
    MapService,
    CategoryService,
    ModulesService,
    SpecialPageService,
    GenericResolverService,
    NcoaLandingPageService,
  ],
  bootstrap: [AppComponent],
})
export class AppCoreModule {}
