import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-to-do',
  templateUrl: './to-do.component.html',
  styleUrls: ['./to-do.component.scss']
})
export class ToDoComponent implements OnDestroy, AfterViewInit {
  @ViewChild("todoSnippet") todoSnippet: ElementRef<HTMLDivElement>;
  @Input() todo: ToDo;

  @Input() content: any;
  @Input() scrollToTarget?: string;

  @Output() onArchived: EventEmitter<ToDo> = new EventEmitter();
  @Output() onComplete: EventEmitter<ToDo> = new EventEmitter();
  @Output() onIncomplete: EventEmitter<ToDo> = new EventEmitter();
  @Output() onUnarchived: EventEmitter<ToDo> = new EventEmitter();

  // true = increase by 1, false = decrease by 1
  @Output() onUpdateListTotal: EventEmitter<ToDoAction> = new EventEmitter();


  @ViewChild('todoDiv') todoDiv: ElementRef;

  private onArchiveTimeout: any;
  private onCompleteTimeout: any;
  public showOverlay: boolean;
  public hide: boolean;
  public action: 'archived' | 'completed' | 'incomplete' | 'unarchived'

  constructor(private router: Router) {
    this.showOverlay = false;
    this.hide = false;
  }

  ngOnDestroy(): void {

    if(this.onArchiveTimeout){
      clearTimeout(this.onArchiveTimeout);
      this._doArchive();
    }else if(this.onCompleteTimeout){
      clearTimeout(this.onCompleteTimeout);
      this._doComplete();
    }
      
  
  }

  ngAfterViewInit(): void {
      this.todoSnippet.nativeElement.insertAdjacentHTML("afterbegin", this.todo.snippet)
  }

  public gotoTodoPage(){
    this.router.navigate([window.location.pathname], {queryParams: {todo: this.todo.slug}})
    if(this.scrollToTarget){
      let _target = document.getElementsByClassName(this.scrollToTarget).item(0);
      if(_target){
        _target.scrollIntoView({behavior: 'smooth'})
      }
    }
    else
      window.scrollTo(0,0);
  }

  private _doArchive(){
    if(!this.todo.archived){
      return this.onArchived.emit(this.todo);
    }

    this.onUnarchived.emit(this.todo);
  }


  private _doComplete(){

    if(!this.todo.completed){
      return this.onComplete.emit(this.todo);
    }
    this.onIncomplete.emit(this.todo);


  }

  public onArchiveClick(){


 

    this.action =  !this.todo.archived ? 'archived' : 'unarchived'

    this.onUpdateListTotal.emit({what: 'archived', action: !this.todo.archived});
    this.onUpdateListTotal.emit({what: this.todo.completed ? 'completed' : 'active', action: this.todo.archived});

    this.showOverlay = true;

    this.onArchiveTimeout = setTimeout(() => {
      
      this.hide = true;

      setTimeout(() => {
        this._doArchive()
      }, 400)

    }, 1500)

  }

  public onCompleteClick(){


    this.onUpdateListTotal.emit({what: 'active', action: this.todo.completed});
    this.onUpdateListTotal.emit({what: 'completed', action: !this.todo.completed})


    this.action = !this.todo.completed ? 'completed' : 'incomplete'

    this.showOverlay = true;

    this.onCompleteTimeout = setTimeout(() => {
      this.hide = true;

      setTimeout(() => {
        this._doComplete();
      }, 400)

    }, 1500)

  }


  public undo(){
    if(this.onArchiveTimeout){
      clearTimeout(this.onArchiveTimeout)
    }

    if(this.onCompleteTimeout){
      clearTimeout(this.onCompleteTimeout)
    }

    this.showOverlay = false;
    this.hide = false;


    switch(this.action){
      case 'archived':{
        this.onUpdateListTotal.emit({what: 'archived', action: this.todo.archived});
        this.onUpdateListTotal.emit({what: this.todo.completed ? 'completed' : 'active', action: !this.todo.archived}) 
        break;
      }
      case 'completed':{
        this.onUpdateListTotal.emit({what: 'completed', action: false});
        this.onUpdateListTotal.emit({what: 'active', action: true});
        break;
      }
      case 'incomplete':{
        this.onUpdateListTotal.emit({what: 'active', action: false});
        this.onUpdateListTotal.emit({what: 'completed', action: true});
        break;
      }
      case 'unarchived':{
        this.onUpdateListTotal.emit({what: 'active', action: false});
        this.onUpdateListTotal.emit({what: 'archived', action: true});
        break;
      }
    }


  }

}

export interface ToDo {
  completed: boolean,
  archived: boolean,
  goals?: string[],
  order?: number,
  timestamp: Date,
  budgetWidget?: any,
  snippet: string,
  slug: string;
  content: string;
  title: string;
  source: string;
}


export interface ToDoAction {
  what: 'active' | 'completed' | 'archived', action: boolean
}
