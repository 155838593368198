import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserService } from '@services/planner/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'age-well-planner-assessment',
  templateUrl: './age-well-planner-assessment.component.html',
  styleUrls: ['./age-well-planner-assessment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AgeWellPlannerAssessment implements OnInit {
  signInReminder: string;
  signInUrl: string;
  allowedPath: string[];
  assessmentId: string; // for Falls assessment check. remove once testing is done by NORC

  constructor(
    private tools: ToolsPrimaryConfigService,
    private userService: UserService,
  ) {
    this.signInReminder = this.tools.rawConfig.elements.sign_in_reminder_text.value;
    this.signInUrl = this.tools.routes.find((route) => route.type === 'sign-in').route;

    const home = this.tools.routes.find((route) => route.type === 'home').route;
    this.allowedPath = [home];

    this.tools.routes.filter((route) => route.type === 'assessment')
      .forEach((route) => {
        this.allowedPath.push(route.route);
      });

    this.assessmentId = this.tools.routes.filter((route) => route.type === 'assessment').find((route) => route.route === window.location.pathname)?.assessmentID;
  }

  ngOnInit(): void {
    let signInReminderCount = sessionStorage.getItem('signInReminderCount')
      ? parseInt(sessionStorage.getItem('signInReminderCount'))
      : 0;

    if (this.assessmentId !== 'fallsRiskAssessment' && // check to be removed after NORC testing 
        signInReminderCount < 2 && this.allowedPath.find((route) => route === window.location.pathname)) {
      setTimeout(() => {
        this.userService.isLoggedIn()
          .pipe(take(1))
          .subscribe((isLoggedIn) => {
            if (!isLoggedIn) {

              const signInReminderModal = document.querySelector('#sign-in-reminder');
              signInReminderModal.classList.add('is-active');
              setTimeout(() => {
                signInReminderModal.classList.add('is-visible');
                signInReminderCount++;
                sessionStorage.setItem('signInReminderCount', signInReminderCount.toString());
              }, 500);
              setTimeout(() => { this.dismissReminder() }, 5000);
            }
          });
      }, 4500);
    }
  }

  dismissReminder(): void {
    const signInReminderModal = document.querySelector('#sign-in-reminder');
    signInReminderModal.classList.remove('is-visible');
    setTimeout(() => { signInReminderModal.classList.remove('is-active') }, 500);
  }
}
