import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from '@services/planner/user.service';
import { take } from 'rxjs/operators';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { DataLayerService } from '@services/data-layer.service';
import { GlobalService } from '@services/global.service';
@Component({
  selector: 'age-well-planner-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignUp implements OnInit {

  accountRegister: any = null;

  showErrorModal: boolean = false;
  showLoadingModal: boolean = false;

  constructor(
    private userService: UserService,
    private toolsConfig: ToolsPrimaryConfigService,
    private dataLayerService: DataLayerService,
    private globalService: GlobalService,
  ) {

    this.accountRegister = {
      navCTA: {},
      backCTA: {},
      eyebrow: '',
      desc: '',
      signInCTA: {},
      helpCTA: {},
      forgotPasswordCTA: {},
      steps: [],
    };

    if (this.toolsConfig.currentConfig) {
      this.accountRegister = {
        navCTA: this.toolsConfig.currentConfig.navData.mainLogo,
        backCTA: {
          url: '/',
          text: 'Exit',
        },
        eyebrow: this.toolsConfig.rawConfig.elements.sign_up_eyebrow.value,
        desc: 'You will be the one accessing this account, even if it is for someone else, so please provide your email address.',
        signInCTA: {
          url: this.toolsConfig.routes.find(({ type }) => type === 'sign-in').route,
          text: this.toolsConfig.rawConfig.elements.sign_in_link_text.value,
        },
        helpCTA: {
          url: 'mailto:community@ncoa.org',
          text: 'Need more assistance? - Contact Us',
        },
        forgotPasswordCTA: {
          url: this.toolsConfig.routes.find(({ type }) => type === 'forgot-password').route,
          text: 'forgot password',
        },
        everyActionFormUrl : this.toolsConfig.rawConfig?.elements?.everyaction_form_url?.value || null,
        steps: [
          {
            title: this.toolsConfig.rawConfig.elements.email_address_title.value,
            desc: `<p>${this.toolsConfig.rawConfig.elements.email_address_description.value}</p>`,
          },
          {
            title: this.toolsConfig.rawConfig.elements.password_title.value,
            desc: this.toolsConfig.rawConfig.elements.password_description.value,
          },
        ],
      };
    }
  }

  ngOnInit(): void {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        window.location.href = this.toolsConfig.rootURL;
      }
    });

    this.dataLayerService.push({
      clickUrl: location.href,
      pageTitle: document.title || '',
      contentType: 'sign-up',
      pageType: 'sign-up-page',
    },false);

  this.globalService.loadFooter(true);

  }

  toggleModal(): void {
    this.showErrorModal = !this.showErrorModal;

    if (this.showErrorModal) {
      setTimeout(() => {
        const dismissBtn: HTMLButtonElement = document.querySelector('.dismiss-btn');
        if (dismissBtn) {
          dismissBtn.focus();
        }
      }, 400);
    }
  }

  toggleLoadingModal(): void {
    this.showLoadingModal = !this.showLoadingModal;
  }
}
