<div class="component component-lead-gen">
  <div class="wrapper">

    <h3 class="lead-gen__eyebrow mobile">{{ eyebrow }}</h3>
    <h2 class="lead-gen__heading mobile">{{ heading }}</h2>

    <picture class="lead-gen__icon" *ngIf="icon">
      <img src="{{ icon.src }}" alt="{{ icon.alt }}">
    </picture>

    <div class="lead-gen">
      <div class="lead-gen__photo">
        <div class="lead-gen__photo--wrapper">
          <picture>
            <img src="{{ photo.src }}" alt="{{ photo.alt }}">
          </picture>
        </div>
      </div>
      <div class="lead-gen__content">
        <div class="lead-gen__content--wrapper">
          <div class="lead-gen__content__header">
            <h3 class="lead-gen__eyebrow">{{ eyebrow }}</h3>
            <h2 class="lead-gen__heading">{{ heading }}</h2>
          </div>
          <div class="lead-gen__content__footer">
            <div>
              <p>{{ intro }}</p>
            </div>
            <a *ngIf="cta" href="{{ cta.url }}" (click) = 'trackAnalytics($event)'>{{ cta.label }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
