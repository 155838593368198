import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ResultCardType } from '@components/result-card/result-card.types';
import { TimelineLite } from 'gsap';

@Component({
  selector: 'ncoa-event-results-list',
  templateUrl: './event-results-list.component.html',
  styleUrls: ['./event-results-list.component.scss'],
})
export class EventResultsListComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() items: any[] = [];
  @Input() getMoreItems?: any;
  @Input() hasMoreItems = false;
  @Input() loading = false;

  searchAnimation: any;

  onLoadMoreClick(e: MouseEvent) {
    e.preventDefault();

    this.loading = true;

    if ( typeof this.getMoreItems === 'function' ) {
      setTimeout(() => {
        this.getMoreItems();
        this.loading = false;
      }, 1000); // fake loading time
    }
  }
  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      if (document.querySelector('.search-page')) {
        if (this.searchAnimation) {
          this.searchAnimation.kill();
        }
        if (this.items.length > 0) {
          this.searchAnimation = new TimelineLite()
            .to('.search-page', { opacity: 1, duration: 0.2 })
            .to('.search-page .component--list-header', { y: 0, duration: 0.4 })
            .to('.search-page .component--filter', { y: 0, duration: 0.4, delay: -0.1 }, 'combo1')
            .to('.search-page .component--list-header .list-header--heading', { opacity: 1, duration: 0.4 }, 'combo1')
            .to(
              '.search-page .component--list-header .search-field',
              {
                width: '100%',
                duration: window.innerWidth >= 1024 ? 0.6 : 0.4,
                delay: 0.2,
                onComplete: () => {
                  (document.querySelector('.component--list-header .search-field') as HTMLElement).style.overflow = 'visible';
                }
              },
              'combo1'
            )
            .to('.search-page .component--list-header .submit-btn', { opacity: 1, duration: 0.3 })
            .to('.search-page .component--list-header .reset-btn', { opacity: 1, duration: 0.4, delay: -0.2 })
            .to('.search-page .component--filter .wrapper', { opacity: 1, y: 0, duration: 0.4, delay: -0.2 })
            .to('.search-page .results-list', { opacity: 1, y: 0, duration: 0.4, delay: -0.2 })
            .to('.search-page .no-result', { position: 'absolute', opacity: 0, duration: 0.1 })
            .delay(1.4);
        } else {
          this.searchAnimation = new TimelineLite()
            .to('.search-page', { opacity: 1, duration: 0.2 })
            .to('.search-page .component--list-header', { y: 0, duration: 0.4 })
            .to('.search-page .component--filter', { y: 0, duration: 0.4, delay: -0.1 }, 'combo1')
            .to('.search-page .component--list-header .list-header--heading', { opacity: 1, duration: 0.4 }, 'combo1')
            .to(
              '.search-page .component--list-header .search-field',
              {
                width: '100%',
                duration: window.innerWidth >= 1024 ? 0.6 : 0.4,
                delay: 0.2,
                onComplete: () => {
                  (document.querySelector('.component--list-header .search-field') as HTMLElement).style.overflow = 'visible';
                }
              },
              'combo1'
            )
            .to('.search-page .component--list-header .submit-btn', { opacity: 1, duration: 0.3 })
            .to('.search-page .component--list-header .reset-btn', { opacity: 1, duration: 0.4, delay: -0.2 })
            .to('.search-page .component--filter .wrapper', { opacity: 1, y: 0, duration: 0.4, delay: -0.2 })
            .to('.search-page .no-result', { position: 'relative', duration: 0.1, delay: -0.2 })
            .to('.search-page .no-result', { opacity: 1, duration: 0.5 })
            .delay(1.4);
        }
      }

      const customSearchPage = document.querySelector('.category-search-page,.event-custom-search-page');
      if (customSearchPage) {
        const timeLine = new TimelineLite();
        const authorHeader = customSearchPage.querySelector('.author-header');
        const customListHeader = customSearchPage.querySelector('.component--custom-list-header');
        const customFilter = customSearchPage.querySelector('.component--custom-filter');
        const customListHeaderHeading = customListHeader?.querySelector('.list-header--heading');
        const customFilterWrapper = customFilter ? customFilter.querySelector('.wrapper') : {};
        const resultsList = customSearchPage.querySelector('.results-list');
        const noResult = customSearchPage.querySelector('.no-result');

        timeLine
          .to(customSearchPage, { opacity: 1, duration: 0.2 })
          .to(authorHeader, { opacity: 1, duration: 0.4 })
          .to(customListHeader, { y: 0, duration: 0.4 })
          .to(customFilter, { y: 0, duration: 0.4, delay: -0.1 })
          .to(customListHeaderHeading, { opacity: 1, duration: 0.4, delay: -0.2 })
          .to(customFilterWrapper, { opacity: 1, y: 0, duration: 0.4 })

        if (this.items.length > 0) {
          timeLine
            .to(resultsList, { opacity: 1, y: 0, duration: 0.4, delay: -0.2 })
            .to(noResult, { position: 'absolute', opacity: 0, duration: 0.1 })
            .delay(0.5);
        } else {
          timeLine
            .to(noResult, { position: 'relative', pointerEvents: 'all', opacity: 1, duration: 0.5, delay: 0.5})
        }
      }
    }
  }

  appendItems(items) {
    this.items = this.items.concat(items);
  }
}
