import { Injectable } from '@angular/core';
import { ComponentService } from './component.service';
import { KontentDeliveryService } from './kontent-delivery.service';
import { ModulesService } from './modules.service';
import { RichTextService } from './rich-text-resolver.service';
import { SeoService } from './seo.service';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { DataService } from './planner/data.service';
dayjs.extend(utc);

const dateToday = dayjs().format('YYYY-MM-DD');

@Injectable({
  providedIn: 'root',
})
export class NcoaLandingPageService {
  landingPageData: any = null;
  modularContent: any = null;
  components: any[] = [];
  articles: any[] = [];
  usedArticles: any[] = [];

  // search fields
  limit: number = 6;
  apiLimit: number = 1000;
  contentTypes: any[] = ['link_type___event_link'];
  // Will store all events (unfiltered)
  originalItems: any[] = [];
  // Will store filtered events (with date and query)
  filteredEvents: any[] = [];

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private componentService: ComponentService,
    private modulesService: ModulesService,
    //   private modalService: ModalService,
    private dataService: DataService,
    private seoService: SeoService,
    private richTextService: RichTextService
  ) {}

  async getLandingPageData(data: any) {
    this.modularContent = data.modular_content;
    this.landingPageData = data.items.length > 0 ? data.items[0] : null;

    // header
    this.setPageHeader();
    this.setSpecialPathing();

    // body
    this.setPageBody();
    //this.setSearchComponent();
    this.setEventsList();
    await this.setAdditionalComponents();

    // seo service
    this.setSeoData();

    return this.components;
  }

  setSearchComponent() {
    this.components.push({
      componentType: 'event-list-search',
      placeholder:
        this.landingPageData.elements.search_placeholder_text.value ||
        'Search here',
      filterType: 'date',
    });
  }

  setEventsList() {
    this.components.push({
      componentType: 'event-list',
      items: this.landingPageData.elements.event_links.value.map(
        (eventCode) => {
          return {
            type: this.modularContent[eventCode].elements.event_label.value
              ? {
                  name: this.modularContent[eventCode].elements.event_label
                    .value,
                  codename:
                    this.modularContent[eventCode].elements.event_type.value[0]
                      .codename,
                }
              : { name: 'Event', codename: 'event' }, // default to event
            eyebrow: this.modularContent[eventCode].elements.date.value
              ? dayjs
                  .utc(this.modularContent[eventCode].elements.date.value)
                  .format('MMM DD, YYYY')
              : '',
            title: this.modularContent[eventCode].elements.title.value,
            description: this.modularContent[eventCode].elements.description.value,
            cta: {
              text: this.modularContent[eventCode].elements.cta_copy.value,
              url: this.modularContent[eventCode].elements.link.value,
            },
            image: this.modularContent[eventCode].elements.image.value[0],
          };
        }
      ),
    });
  }

  setPageBody() {
    if (this.landingPageData.elements.body.value) {
      this.components.push({
        componentType: 'body',
        value: this.landingPageData.elements.body.value,
        styleConfig: {},
      });
    }
  }

  setPageHeader() {
    this.components.push({
      componentType: 'header',
      title: this.landingPageData.elements.page_title,
      description: this.richTextService.resolveRichText(
        this.landingPageData.elements.page_description
      ),
      primary_image: this.landingPageData.elements.hero_image,
    });
  }

  setSpecialPathing() {
    if (this.landingPageData.elements.special_pathing.value.length > 0) {
      const pageData = this.landingPageData;
      const codeNames = pageData.elements.special_pathing.value;
      const paths = codeNames.map((codename) => {
        const path = this.modularContent[codename];
        switch (path.system.type) {
          case 'template___other_taxonomy': {
            return {
              url: `/${path.system.name
                .trim()
                .toLowerCase()
                .replace(/\s+/g, '-')
                .replace(/[^\w\-]+/g, '')
                .replace(/\-\-+/g, '-')
                .replace(/^-+/, '')
                .replace(/-+$/, '')}`,
              title: path.system.name,
            };
          }
          case 'ncoa_article_content': {
            return {
              url: `/article/${path.elements.url.value}`,
              title: path.elements.title.value,
            };
          }
          case 'standard_page': {
            let url = null;
            const parentPageCodename =
              path.elements.parent_page.value[0] || null;

            if (parentPageCodename) {
              const parentPage = this.modularContent[parentPageCodename];

              if (parentPage) {
                url = `/page/${parentPage.elements.url.value}/${path.elements.url.value}`;
              }
            } else {
              url = `/page/${path.elements.url.value}`;
            }

            return {
              url,
              title: path.elements.title.value,
            };
          }
          case 'template___custom_search': {
            return {
              url: `/${path.elements.page_slug.value}`,
              title: path.elements.page_title.value,
            };
          }
          case 'navigation_item_0ae4ab8': {
            let url,
              external = false;
            if (path.elements.linked_content.value.length > 0) {
              const linkedContentItem =
                this.modularContent[path.elements.linked_content.value[0]];
              if (linkedContentItem.system.type === 'ncoa_article_content') {
                // article
                if (linkedContentItem.system.collection === 'default') {
                  // ncoa article
                  url = `article/${linkedContentItem.elements.url.value}`;
                } else {
                  // awp article
                  url = `age-well-planner/resource/${linkedContentItem.elements.url.value}`;
                }
              } else {
                // category/standard/audience page
                url =
                  this.modularContent[path.elements.linked_content.value[0]]
                    .elements.category_page_url?.value ||
                  `page/${
                    this.modularContent[path.elements.linked_content.value[0]]
                      .elements.url?.value
                  }`;
              }
            } else {
              url = path.elements.link_url.value;
              if (url.indexOf('http://') == 0 || url.indexOf('https://') == 0)
                external = true;
            }
            return {
              title:
                path.elements.link_name.value ||
                this.modularContent[path.elements.linked_content.value[0]]
                  .system.name,
              url,
              external,
            };
          }
          case 'taxonomy_custom_content': {
            return {
              title: path.system.name,
              url: this.buildTaxonomyPageURL(path),
            };
          }
        }
      });

      this.components.push({
        componentType: this.modulesService.moduleTypes.special_pathing,
        paths,
      });
    }
  }

  buildTaxonomyPageURL(itemData) {
    const type = itemData.system.type;

    switch (true) {
      case /taxonomy_custom_content/.test(type):
        const { categories, audiences, category_page_url }: any =
          this.kontentDeliveryService.extractItemElements(itemData);
        const category = categories[0];
        const audience = audiences[0];

        // Use manual URL if any
        if (category_page_url) {
          return category_page_url;
        }
        return '';

      case /template___other_taxonomy/.test(type):
        const { other_taxonomy }: any =
          this.kontentDeliveryService.extractItemElements(itemData);
        return other_taxonomy[0].codename.replace(/_/g, '-');
    }
  }

  async setAdditionalComponents() {
    // additional components
    (this.landingPageData.elements.additional_components.value || []).forEach(
      (codename) => {
        const component = this.modularContent[codename];
        switch (component.system.type) {
          // featured page block
          case this.modulesService.moduleTypes.featured_page_block: {
            this.components.push(
              this.modulesService.featurePageBlock(
                component,
                this.modularContent
              )
            );
            break;
          }

          // featured tool breaker
          case this.modulesService.moduleTypes.featured_tool_breaker: {
            this.components.push(
              this.modulesService.featuredToolBreaker(component)
            );
            break;
          }

          // donate block
          case this.modulesService.moduleTypes.call_to_donate: {
            this.components.push(this.modulesService.donateBlock(component));
            break;
          }

          // newsletter block
          case this.modulesService.moduleTypes.newsletter_signup_block: {
            this.components.push(
              this.modulesService.newsLetterBlock(component)
            );
            break;
          }

          // category pathing standard
          case this.modulesService.moduleTypes.category_pathing_std: {
            this.components.push(
              this.modulesService.categoryPathingStandard(
                component,
                this.modularContent
              )
            );
            break;
          }

          // content package
          case this.modulesService.moduleTypes.content_package: {
            this.contentPackage(component);
            break;
          }

          // partner grid
          case this.modulesService.moduleTypes.partner_grid: {
            this.components.push(
              this.modulesService.partnerGrid(component, this.modularContent)
            );
            break;
          }

          // content package
          case this.modulesService.moduleTypes.content_package: {
            this.contentPackage(component);
            break;
          }

          // secondary content package
          case this.modulesService.moduleTypes.content_package_secondary: {
            this.secondaryContentPackage();
            break;
          }

          // external form package
          case this.modulesService.moduleTypes.external_form: {
            this.components.push(this.modulesService.externalForm(component));
            break;
          }
        }
      }
    );
  }

  async contentPackage(component) {
    let contentPackageArticles = [];
    let selectedArticles = [];
    const contentPopulationMethod =
      component.elements.content_population_method.value[0].codename;

    if (contentPopulationMethod === 'dynamically') {
      selectedArticles = this.getDynamicArticles(component);
    } else if (contentPopulationMethod === 'dynamic_with_manual_additions') {
      selectedArticles = this.getManualArticles(component).concat(
        await this.getDynamicArticles(component)
      );
      selectedArticles.sort((a, b) => (a.date > b.date ? -1 : 1));
      const max = selectedArticles.length > 7 ? 7 : selectedArticles.length;
      selectedArticles = selectedArticles.slice(0, max);
    } else {
      selectedArticles = this.getManualArticles(component);
    }

    if (selectedArticles.length > 0) {
      contentPackageArticles = selectedArticles.map((article) => {
        this.usedArticles.push(article);

        const index = this.articles.findIndex(
          (libArticle) => libArticle.system.id === article.system.id
        );

        if (index >= 0) {
          this.articles.splice(index, 1);
        }

        const type = article.elements.display_date.value ? 'event' : 'article';
        const title = article.elements.title.value;
        const cta = {
          url: `/article/${article.elements.url.value}`,
          text: type === 'event' ? 'View Event Details' : 'Read Article',
        };
        const image =
          article.elements.primary_image.value.length > 0
            ? {
                url: article.elements.primary_image.value[0].url,
                alt: article.elements.primary_image.value[0].description,
                responsive: this.dataService.remodelImageSrc(
                  article.elements.primary_image.value[0].url
                ),
              }
            : {};
        const eyebrow = dayjs.utc(article.display_date).format('MMM D, YYYY');

        return {
          type,
          title,
          cta,
          image,
          eyebrow,
        };
      });

      this.components.push({
        componentType: this.modulesService.moduleTypes.content_package,
        articles: contentPackageArticles,
      });
    }
  }

  secondaryContentPackage() {
    const max = this.articles.length > 4 ? 4 : this.articles.length;
    let contentPackageArticles = this.articles.slice(0, max);

    contentPackageArticles = contentPackageArticles.map((article) => {
      const index = this.articles.findIndex(
        (libArticle) => libArticle.system.id === article.system.id
      );

      if (index >= 0) {
        this.articles.splice(index, 1);
      }

      const type = article.elements.date.value ? 'event' : 'article';
      const title = article.elements.title.value;
      const cta = {
        url: `/article/${article.elements.url.value}`,
        text: type === 'event' ? 'View Event Details' : 'Read Article',
      };
      const image =
        article.elements.primary_image.value.length > 0
          ? {
              url: article.elements.primary_image.value[0].url,
              alt: article.elements.primary_image.value[0].description,
              responsive: this.dataService.remodelImageSrc(
                article.elements.primary_image.value[0].url
              ),
            }
          : {};
      const eyebrow = dayjs.utc(article.date).format('MMM D, YYYY');

      return {
        type,
        title,
        cta,
        image,
        eyebrow,
      };
    });

    if (contentPackageArticles.length > 0) {
      this.components.push({
        componentType: this.componentService.componentTypes.content_package,
        articles: contentPackageArticles,
      });
    }
  }

  getManualArticles(component) {
    let selectedArticles = [];
    const articleCodeNames = component.elements.content.value;

    articleCodeNames.forEach((codename) => {
      const article = this.modularContent[codename];
      if (article) {
        selectedArticles.push({
          ...article,
          date: new Date(
            article.elements.display_date.value || article.system.last_modified
          ),
        });
      }
    });

    return selectedArticles;
  }

  getDynamicArticles(component) {
    const categories = component.elements.categories.value.map(
      ({ codename }) => codename
    );
    const audiences = component.elements.audiences.value.map(
      ({ codename }) => codename
    );
    const specialType = component.elements.other_categories.value.map(
      ({ codename }) => codename
    );
    let articles = this.articles;

    let filteredArticles = articles.filter((article) => {
      const primaryCategoryFound = article.elements.categories.value.some((x) =>
        categories.includes(x.codename)
      );
      const secondaryCategoryFound =
        article.elements.secondary_categories.value.some((x) =>
          categories.includes(x.codename)
        );

      const primaryAudienceFound = article.elements.audiences.value.some((x) =>
        audiences.includes(x.codename)
      );
      const secondaryAudienceFound =
        article.elements.secondary_audiences.value.some((x) =>
          audiences.includes(x.codename)
        );

      const specialTypeFound = article.elements.other.value.some((x) =>
        specialType.includes(x.codename)
      );

      return (
        primaryCategoryFound ||
        secondaryCategoryFound ||
        primaryAudienceFound ||
        secondaryAudienceFound ||
        specialTypeFound
      );
    });

    const max = filteredArticles.length > 7 ? 7 : filteredArticles.length;

    return filteredArticles.slice(0, max);
  }

  setSeoData() {
    const seo = {
      title:
        this.landingPageData.elements
          .seo_metadata_example_to_include_in_any_type__meta_title.value ||
          this.landingPageData.elements.page_title.value,
      description:
        this.landingPageData.elements
          .seo_metadata_example_to_include_in_any_type__meta_description
          .value || this.landingPageData.elements.page_description.value,
      image: this.landingPageData.elements.hero_image.value[0]?.url,
      ogTitle:
        this.landingPageData.elements
          .seo_metadata_example_to_include_in_any_type__meta_title.value ||
          this.landingPageData.elements.page_title.value,
      ogDescription:
        this.landingPageData.elements
          .seo_metadata_example_to_include_in_any_type__facebook_og_description
          .value || this.landingPageData.elements.page_description.value,
      ogImage:
        this.landingPageData.elements
          .seo_metadata_example_to_include_in_any_type__facebook_og_image
          .value[0]?.url ||
        this.landingPageData.elements.hero_image.value[0]?.url,
      twitterTitle:
        this.landingPageData.elements
          .seo_metadata_example_to_include_in_any_type__twitter_card_title
          .value || this.landingPageData.elements.page_title.value,
      twitterDescription:
        this.landingPageData.elements
          .seo_metadata_example_to_include_in_any_type__twitter_card_description
          .value || this.landingPageData.elements.page_description.value,
      twitterImage:
        this.landingPageData.elements
          .seo_metadata_example_to_include_in_any_type__twitter_card_image
          .value[0]?.url ||
        this.landingPageData.elements.hero_image.value[0]?.url,
    };

    this.seoService.applyMetaData(seo);
  }

  // search functions
  async storeAllEvents() {
    // Will contain filtered raw items
    const fetchedItems = [];

    // Will be used in API call iteration whether next iteration is necessary
    let hasMoreItems = true;

    while (hasMoreItems) {
      // Build out API params
      const params = {
        'system.type[in]': this.contentTypes.join(','),
        limit: this.apiLimit,
        includeTotalCount: true,
        'elements.date[nempty]': '',
        order: 'elements.date[desc]', // Order by date, descending
        depth: 1,
        skip: fetchedItems.length,
      };

      const result = await this.kontentDeliveryService.getItem(null, params);

      // Process raw list
      fetchedItems.push(...this.processRawList(result));

      // Update values for the next iteration
      hasMoreItems = result.pagination.next_page ? true : false;
    }

    // Store all events
    this.originalItems = fetchedItems;
  }

  processRawList(data) {
    const { items } = data;

    return (
      items
        .map((item) => {
          const id = item.system.id;
          const date = item.elements.date.value;

          const cta = {
            text: item.elements.cta_copy.value,
            url: item.elements.link.value,
          };
          const eyebrow = item.elements.date.value
            ? dayjs.utc(item.elements.date.value).format('MMM DD, YYYY')
            : '';
          const title = item.elements.title.value;
          const type = item.elements.event_label.value
            ? {
                name: item.elements.event_label.value,
                codename: item.elements.event_type.value[0].codename,
              }
            : { name: 'Event', codename: 'event' }; // default to event
          let image = null;

          if (item.elements.image.value.length > 0) {
            image = {
              url: item.elements.image.value[0].url,
              alt: item.elements.image.value[0].description,
            };
          }

          const description = item.elements.description.value

          return {
            id,
            cta,
            eyebrow,
            image,
            title,
            description,
            type,
            date,
          };
        })
        // Sort by event date descending
        .sort((a, b) => (a.date > b.date ? -1 : 1))
    );
  }

  searchEventsStore(query = '', dateFilter = 'forwards', skip = 0) {
    const queryRegExp = new RegExp(query, 'gi');

    const dateTodayFull = {
      min: dateToday + 'T00:00:00Z',
      max: dateToday + 'T23:59:59Z',
    };

    // Filter according to query and dateFilter
    this.filteredEvents = this.originalItems.filter((item) => {
      let queryMatch = queryRegExp.test(item.title);

      if (!queryMatch) {
        return false;
      }

      if (dateFilter === 'forwards') {
        return item.date >= dateTodayFull.min;
      } else {
        return item.date <= dateTodayFull.max;
      }
    });

    // Sort according to dateFilter
    // forwards - ascending
    // backwards - descending
    this.filteredEvents.sort((eventA, eventB) => {
      if (dateFilter === 'forwards') {
        return eventA.date > eventB.date ? 1 : -1;
      } else {
        return eventB.date > eventA.date ? 1 : -1;
      }
    });

    return {
      items: this.filteredEvents.slice(skip, this.limit),
      totalCount: this.filteredEvents.length,
    };
  }
}
