<div class="component sponsors">
    <div class="wrapper">
      <h2 class="sponsors--heading" [innerHtml]="sponsorHeader"></h2>

      <div class="sponsors--level" *ngFor="let sponsorLevel of sponsors | keyvalue: asIsOrder">
            <div class="sponsors--level__container" *ngIf="sponsorLevel.value.length > 0">
                <div class="sponsors--level__heading">
                    Our {{sponsorLevel.key}} Partners
                </div>
                <div class="sponsors--level__sponsors">
                    <div class="sponsor__container" *ngFor="let sponsor of sponsorLevel.value">
                        <div class="sponsor__image">
                            <img  *ngIf="sponsor.logo && sponsor.logo.url" src="{{ sponsor.logo.url }}" alt="{{ sponsor.logo.alt }}" title="{{sponsor.logo.alt }}" width="100%" height="100%" loading="lazy" />
                        </div>
                        <div class="sponsor__cta">
                            <a href="{{ sponsor.external_link }}" target="_blank" role="button">
                               Visit Site
                              </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h2 class="sponsors--footer" [innerHtml]="sponsorFooter"></h2> 
        
    </div>
  </div>