import { Component, Input, OnInit } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { UserPromptService } from '@services/planner/user-prompt.service';
import { UserService } from '@services/planner/user.service';
import { UtilityService } from '@services/planner/utility.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ncoa-topic-listing',
  templateUrl: './topic-listing.component.html',
  styleUrls: ['./topic-listing.component.scss']
})
export class TopicListing implements OnInit {

  @Input() heading: string;
  @Input() topics: {
    id: string;
    isSaved: boolean;
    eyebrow: string;
    title: string;
    desc: string;
    cta: {
      url: string;
      text: string;
    },
    image: {
      src: string;
      alt: string;
    },
  }[];

  constructor(
    private utility: UtilityService,
    private userService: UserService,
    private modalService: UserPromptService,
    private dataLayerService: DataLayerService,
  ) { }

  ngAfterViewInit() { 
    this.initializeCardLinks();
  }

  initializeCardLinks(){
    const allCards = document.querySelectorAll('.topic-listing .topic-listing__main .topic .main a.cta');
    const self = this;
    allCards.forEach(anchor =>{
      if(!anchor.classList.contains('three-col-analytics')){
        anchor.addEventListener('click', function(ev){
          ev.stopPropagation();
          self.trackEvent(this);
        })
      }
      anchor.classList.add('three-col-analytics');
    })
  }

  trackEvent( el: HTMLAnchorElement ) {
    const _clickUrl = el.href || el.getAttribute('href');

    this.dataLayerService.push({ 
      'event': 'recirc', 
      'url': _clickUrl,
      'componentType': 'single column card list' },false); 
  }

  ngOnInit(): void { }

  clickSave(index): void {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.utility.getSavedContent().then((savedContent) => {
          let newSavedContent;
          let isSaved;

          const found = savedContent.find((content) => content.ContentId === this.topics[index].id);

          if (found) {
            newSavedContent = savedContent.filter((content) => content.ContentId !== this.topics[index].id);
            isSaved = false;
          } else {
            newSavedContent = savedContent.concat({ ContentId: this.topics[index].id });
            isSaved = true;
          }

          this.utility.updateSavedContent(newSavedContent).then(() => {
            this.topics[index].isSaved = isSaved;
          });
        });
      }else{
        this.modalService.isModalOpen(true);
      }
    });
  }
}
