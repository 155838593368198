import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorService } from '@services/author.service';
import { TimelineLite } from 'gsap';
import { GlobalService } from '@services/global.service';
import { ComponentService } from '@services/component.service';


@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthorComponent implements OnInit {
  author?: any;
  headerData: any;
  articleListTitle: string = '';
  articlesListData: any[] = [];
  endingGroupIndex: number = 0;
  shownItems: number = 0;
  totalItems: number = 0;

  components: (any)[];
  componentTypes: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private authorService: AuthorService,
    private globalService: GlobalService,
    private componentService: ComponentService,
  ) {
    this.componentTypes = this.componentService.componentTypes;
  }

  ngOnInit(): void {
    this.activeRoute.data.subscribe(({ authorData }: any) => {
      this.useAuthorData(authorData);
    });
  }

  useAuthorData({ author, headerData, articleData, components }) {
    this.endingGroupIndex = 0;
    this.totalItems = this.authorService.originalItems.length;
    this.shownItems = this.totalItems <= 6 ? this.totalItems : 6;

    this.author = author;
    this.headerData = headerData;
    this.articleListTitle = articleData.title;

    this.articlesListData = articleData.data.data;

    this.globalService.loadFooter( this.totalItems > 0 ? true : false );

    this.components = components;
  }

  getMoreItems() {
    this.endingGroupIndex += 1;

    const shownItems = 6 * (this.endingGroupIndex + 1);
    this.shownItems = shownItems > this.totalItems ? this.totalItems : shownItems;

    const targetGroup: HTMLElement = document.querySelector(`.results-list__list .group:nth-child(${(this.endingGroupIndex + 1)})`);
    const firstLink: HTMLElement = targetGroup.querySelector('a');

    const topOffset = window.scrollY;

    document.body.style.top = `-${topOffset}px`;
    document.body.style.position = 'fixed';
    document.body.style.width = `${window.innerWidth}px`;
    document.querySelector('.header--global').classList.add('is-scrolled');
    document.querySelector('.header--global').classList.add('is-sticky');

    new TimelineLite()
      .to(targetGroup, { display: 'block' })
      .to(targetGroup, { opacity: 1, y: 0, duration: 0.4 })
      .eventCallback('onComplete', () => {
        document.body.style.removeProperty('top');
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('width');
        window.scrollTo(0, topOffset);
        firstLink.focus();
      });
  }

}
