import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ncoa-edit-basic-info',
  templateUrl: './edit-basic-info.component.html',
  styleUrls: ['./edit-basic-info.component.scss']
})
export class EditBasicInfoComponent implements OnInit {

  zipcode = '11211';
  selectedEmployee = 'full-time';
  selectedGross = 'less-1000';

  employeeStatusSelection = [
    { value: 'full-time', text: 'Full-Time', id: '_fullTime' },
    { value: 'part-time', text: 'Part-Time', id: '_PartTime' },
    { value: 'retired', text: 'Retired', id: '_retired' },
    { value: 'unemployed', text: 'Unemployed(Actively Searching)', id: '_unemployed' },
  ];


  grossIncomeSelection = [
    { value: 'less-1000', text: 'Less than $1000 per month', id: '_less1000' },
    { value: 'bet-1000', text: 'Between $1000 - $1499 per month', id: 'bet1000' },
    { value: 'bet-1500', text: 'Between $1500 - $1999 per month', id: 'bet1500' },
    { value: 'bet-2000', text: 'Between $2000 - $3000 per month', id: 'bet2000' },
    { value: 'greater-3000', text: 'Greater than $3000', id: 'greater-3000' },
  ];

  componentLabels = {
    title: 'Edit Basic Info',
    save: 'Save',
    location: 'Location (Zipcode)',
    locationsub: 'Where your loved one lives',
    employee: 'Employee',
    employeeSub: 'Select One',
    gross: 'Gross Montly Household Income',
    grossSub: 'Select One',
  }

  constructor() { }

  onSaveClick() {

  }

  ngOnInit(): void {
  }

}
