<div #todoDiv [id]="todo.slug" class="to-do-card-container" [ngClass]="{'hide': hide, 'completed': todo.completed, 'archived': todo.archived, 'active': !todo.archived && !todo.completed}">

  <section class="action-overlay" *ngIf="showOverlay">

    <ng-container [ngSwitch]="action">

      <h3 *ngSwitchCase="'completed'">Moved to {{content.completed_todos_title}}</h3>

      <h3 *ngSwitchCase="'incomplete'">Moved to {{content.active_todos_title}}</h3>
  
      <h3 *ngSwitchCase="'archived'">{{content.archived_todos_title}}</h3>
  
      <h3 *ngSwitchCase="'unarchived'">Moved to {{content.active_todos_title}}</h3>


    </ng-container>


    <button (click)="undo()" class="btn btn--prev">Undo</button>
  </section>
  
  
  <div class="to-do-card">

    <div class="to-do-card-left">

      <section  class="to-do-information">
        <h3 (click)="gotoTodoPage()">{{ todo.title }}</h3>
        <div class="body-content" >
          <div #todoSnippet>
            <span class="body-read-more" (click)="gotoTodoPage()">Read More</span>
          </div>
         
        </div>
      
      </section>
    
  
      <section class="to-do-controls">
    
        <button
          (click)="onArchiveClick()"
          [ngClass]="{
            archive:
              !todo.archived,
            'un-archive': todo.archived
          }"
        >
          {{
            !todo.archived
              ? content.archive_cta
              : content.unarchive_cta
          }}
        </button>
    
        <button
        (click)="onCompleteClick()"
        *ngIf="!todo.archived"
        class="mark-completed"
        [ngClass]="{
          complete:
            !todo.completed,
          incomplete: todo.completed
        }"
      >
        {{
          !todo.completed
            ? content.mark_complete_cta
            : content.mark_incomplete_cta
        }}
      </button>
    
      </section>

    </div>

    <div class="to-do-card-right">
      <img (click)="gotoTodoPage()" src="./assets/icons/chevron-right.svg">
    </div>
  
    
  
  
  </div>
  


</div>

