<header class="my-planner-header">
  <div class="my-planner-header__wrapper">
    <section class="my-planner-header__main">
      <div>
        <div class="eyebrow">{{ eyebrow }}</div>
        <h1 class="title">{{ title }}</h1>
        <p class="desc">{{ description }}</p>
      </div>
      <div>
        <a [href]="savedContentUrl" title="View Saved Content" class="meta" (click)="!isLogged ? clickSave( $event ) : true;">
          <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 2.23226V18.4647C1 19.4929 2.18505 20.0689 2.99357 19.4336L9 14.7143L15.0064 19.4336C15.8149 20.0689 17 19.4929 17 18.4647V2.23226C17 1.5517 16.4483 1 15.7677 1H2.23226C1.5517 1 1 1.5517 1 2.23226Z" fill="#0B4A5D" stroke="#0B4A5D" stroke-width="2"/>
          </svg>
          <div>View Saved Content: <span id="saved-content-counter">{{ savedContentCount }}</span></div>
        </a>
      </div>
    </section>
    <section class="my-planner-header__feature">
      <div class="background">
        <picture *ngIf="feature.image">
          <source *ngFor="let item of feature.image.sizes" [media]="item.media" [srcset]="item.url" />
          <img [src]="feature.image.src" alt="feature.image.alt" />
        </picture>
      </div>
      <div class="content">
        <div>
          <div class="eyebrow">{{ feature.eyebrow }}</div>
          <h2 class="title">{{ feature.title }}</h2>
          <p class="desc">{{ feature.description }}</p>
        </div>
        <div>
          <a *ngIf="feature.cta" class="cta" [href]="feature.cta.url" [title]="feature.cta.text" (click) = 'trackAnalytics($event)'>
            <span>{{ feature.cta.text }}</span>
            <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9208 1.03218L22.3662 8.64891C22.6054 8.89265 22.725 9.19732 22.725 9.50199C22.725 9.80666 22.6054 10.1113 22.3662 10.3551L14.9208 17.9718C14.4423 18.4593 13.6948 18.4593 13.2164 17.9718C12.738 17.4843 12.738 16.7227 13.2164 16.2352L18.6584 10.7207L1.19605 10.7207C0.538224 10.7207 0 10.1723 0 9.50199C0 8.83171 0.538224 8.28331 1.19605 8.28331L18.6584 8.28331L13.2463 2.73832C12.7679 2.25085 12.7679 1.48918 13.2463 1.0014C13.6948 0.544399 14.4423 0.544399 14.9208 1.03218Z" fill="white"/>
            </svg>
          </a>
        </div>
      </div>
    </section>
  </div>
</header>
