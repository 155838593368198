import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '@services/global.service';
import { RebaDataService } from '@services/planner/reba-data.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserService } from '@services/planner/user.service';
import { RichTextService } from '@services/rich-text-resolver.service';

@Component({
  selector: 'ncoa-vertical-assessment',
  templateUrl: './vertical-assessment.component.html',
  styleUrls: ['./vertical-assessment.component.scss']
})
export class VerticalAssessmentComponent implements OnInit {

  @Input() assessmentCode: string;
  @Input() pageData: any;

  isLoaded: boolean = false;
  isCompleted: boolean = false;
  isLoggedIn: boolean = false;
  bannerText;

  data: any = null;


  constructor(
    private reba: RebaDataService,
    private router: Router,
    private toolsConfig: ToolsPrimaryConfigService,
    private globalService: GlobalService,
    private userService: UserService,
    private richTextService: RichTextService,
    private cd: ChangeDetectorRef
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => {
    //   return false;
    // };

  }

  ngOnInit(): void {

    let screeningAnswers = JSON.parse(localStorage.getItem('screeningAnswers'));
    const screeningsCompleted = this.toolsConfig.customerActivity.basic.completedScreenings;
    if(screeningAnswers && screeningAnswers[this.assessmentCode]){
        this.isCompleted = screeningAnswers[this.assessmentCode]?.completed === true;
    }

    if(!this.isCompleted){
      if(screeningsCompleted.includes(this.assessmentCode)){
        this.isCompleted = true;
      }
    }


      this.reba.getScreening(this.assessmentCode, '23838').subscribe((result) => {
        this.isLoaded = true;
        this.data = result;
      });

    this.userService.isLoggedIn().take(1).subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });
    if(this.pageData.assessment?.bannerTextObject){
      this.bannerText = this.richTextService.resolveRichText(this.pageData?.assessment?.bannerTextObject );
      this.cd.detectChanges();

    }

      this.globalService.loadFooter(true);

  }

  public isComplete(): boolean{
    return this.isCompleted;
  }

  navToSignIn(){
    this.router.navigate(['/sign-up']).then(() => {
      window.location.reload();
  });
  }


}
