  <div class="age-well-planner-sign-up">
    <ncoa-account-register
      [backCTA]="accountRegister.backCTA"
      [eyebrow]="accountRegister.eyebrow"
      [signInCTA]="accountRegister.signInCTA"
      [helpCTA]="accountRegister.helpCTA"
      [steps]="accountRegister.steps"
      [everyActionFormUrl]="accountRegister.everyActionFormUrl"
      (showErrorModal)="toggleModal()"
      (showLoadingModal)="toggleLoadingModal()"
    ></ncoa-account-register>
    <div *ngIf="showErrorModal" class="age-well-planner-sign-up__error-modal">
      <div class="modal-container">
        <h2 class="title">It looks like you already have an account <span>with the email you provided.</span></h2>
        <button type="button" class="dismiss-btn" (click)="toggleModal()">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9145 3.36856C21.5175 2.75325 21.5175 1.76862 20.9145 1.1533C20.294 0.520155 19.2745 0.520154 18.6541 1.1533L11 8.96356L3.34595 1.1533C2.72547 0.520155 1.70596 0.520154 1.08548 1.1533C0.482471 1.76862 0.482471 2.75325 1.08548 3.36856L8.78429 11.2245L1.08548 19.0804C0.482471 19.6957 0.482471 20.6804 1.08548 21.2957C1.70596 21.9288 2.72547 21.9288 3.34595 21.2957L11 13.4854L18.6541 21.2957C19.2745 21.9288 20.294 21.9288 20.9145 21.2957C21.5175 20.6804 21.5175 19.6957 20.9145 19.0804L13.2157 11.2245L20.9145 3.36856Z" fill="white"/>
          </svg>
          Close
        </button>
        <div class="logos">
          <svg class="logo logo--age-well-planner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.71 82.41"><defs><style>.cls-1{fill:none;}.cls-2,.cls-3{fill:#fff;}.cls-3{font-size:38px;font-family:Arial;}.cls-4{letter-spacing:-0.02em;}.cls-5{letter-spacing:-0.04em;}.cls-6{letter-spacing:0em;}.cls-7{font-family:Arial;font-weight:700;}.cls-8{letter-spacing:-0.03em;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M71.69,38.46A35.86,35.86,0,0,0,43.09,3.34C31.78,4.84,23.6,10.35,18.94,20l13.34,9.61c1.93-7.93,7.81-12.14,16.83-12.14,8.17,0,13,3.37,13,9.62v1.08L45.26,30.8c-19.35,3-28.73,10.69-28.73,23.32,0,10.9,7.23,18.41,18.33,20.16l1,0A35.84,35.84,0,0,0,71.69,38.46ZM33.12,53.39c0-5.28,4.57-7.93,17.19-10l11.78-1.93v.85c0,12.62-9.49,18.51-18.75,18.51C37.09,60.85,33.12,58.08,33.12,53.39Z"/><path class="cls-2" d="M62.09,42.34v-.85L50.31,43.42c-12.62,2-17.19,4.69-17.19,10,0,4.69,4,7.46,10.22,7.46C52.6,60.85,62.09,55,62.09,42.34Z"/><path class="cls-2" d="M16.53,54.12c0-12.63,9.38-20.32,28.73-23.32l16.83-2.65V27.07c0-6.25-4.81-9.62-13-9.62-9,0-14.9,4.21-16.83,12.14L18.94,20C23.6,10.35,31.78,4.84,43.09,3.34a35.85,35.85,0,1,0-8.23,70.94C23.76,72.53,16.53,65,16.53,54.12Z"/><text class="cls-3" transform="translate(80.36 32.72)"><tspan class="cls-4">A</tspan><tspan x="25.69" y="0">g</tspan><tspan class="cls-5" x="50.2" y="0">eW</tspan><tspan class="cls-6" x="106.67" y="0">ell</tspan><tspan class="cls-7"><tspan class="cls-8" x="0" y="40">Pl</tspan><tspan x="33.67" y="40">anner</tspan></tspan></text></g></g></svg>
          <svg class="logo logo--mmm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 315.6 86.51"><defs><style>.cls-1{fill:none;}.cls-2,.cls-3{fill:#fff;}.cls-3{font-size:41px;font-family:Arial;letter-spacing:-0.05em;}.cls-4{font-family:Arial;font-weight:700;letter-spacing:-0.05em;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M71.69,42A35.85,35.85,0,0,0,43.09,6.93c-11.31,1.49-19.49,7-24.15,16.63l13.34,9.62C34.21,25.24,40.09,21,49.11,21c8.17,0,13,3.36,13,9.61v1.09L45.26,34.38c-19.35,3-28.73,10.7-28.73,23.32,0,10.9,7.23,18.42,18.33,20.16.33,0,.66,0,1,0A35.85,35.85,0,0,0,71.69,42ZM33.12,57c0-5.29,4.57-7.94,17.19-10l11.78-1.92v.84c0,12.62-9.49,18.51-18.75,18.51C37.09,64.43,33.12,61.67,33.12,57Z"/><path class="cls-2" d="M62.09,45.92v-.84L50.31,47c-12.62,2-17.19,4.69-17.19,10,0,4.69,4,7.45,10.22,7.45C52.6,64.43,62.09,58.54,62.09,45.92Z"/><path class="cls-2" d="M16.53,57.7c0-12.62,9.38-20.32,28.73-23.32l16.83-2.64V30.65c0-6.25-4.81-9.61-13-9.61-9,0-14.9,4.2-16.83,12.14L18.94,23.56C23.6,13.93,31.78,8.42,43.09,6.93a35.84,35.84,0,1,0-8.23,70.93C23.76,76.12,16.53,68.6,16.53,57.7Z"/><text class="cls-3" transform="translate(80.36 35.3)">My<tspan class="cls-4" x="55.96" y="0">Medicare</tspan><tspan x="0" y="41">Matters</tspan></text></g></g></svg>
        </div>
        <div class="footer">
          <p class="footer__body">
            You can use this email to login to your account here.
            If it’s been a while, just hit
            <a [href]="accountRegister.forgotPasswordCTA.url" [title]="accountRegister.forgotPasswordCTA.text">
              {{ accountRegister.forgotPasswordCTA.text }}
            </a>
            on the
            Log In page.
          </p>
          <a class="signin-cta" [href]="accountRegister.signInCTA.url" [title]="accountRegister.signInCTA.text">
            Log In
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="showLoadingModal" class="age-well-planner-sign-up__loading-modal">
      <img src="../../../../assets/images/loader.gif" alt="Loading" />
    </div>
  </div>
