import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccordianComponent } from '@components/accordian/accordian.component';
import { AmountQuestionComponent } from '@components/planner/plan/budget-calculator/analyze-your-budget/amount-question/amount-question.component';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { ClientInfo } from '@services/planner/case-manager.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { TodoStepHistoryService } from '@services/planner/todo-step-history.service';
import { UserService } from '@services/planner/user.service';
import { take } from 'rxjs/operators';
import { Question } from 'src/app/typings/Screening';

@Component({
  selector: 'app-client-analyze-your-budget',
  templateUrl: './analyze-your-budget.component.html',
  styleUrls: ['./analyze-your-budget.component.scss']
})
export class CaseManagerAnalyzeYourBudgetComponent implements OnInit {
  AnalyzeStep = AnalyzeStep
  public widgetContent: any;

  @Input() client: ClientInfo;

  @Output() hideContent: EventEmitter<boolean>;

  @ViewChildren(AccordianComponent) accordians: QueryList<AccordianComponent>;
  @ViewChildren(AmountQuestionComponent, { read: ElementRef }) amountQuestions: QueryList<ElementRef>;

  public budgetFormGroup: FormGroup;

  public expandedAll: boolean;

  public submitted: boolean;
  public valid: boolean;

  public expenseQuestions: any[];
  public incomeQuestions: any[];

  public ageGroupQuestion: Question


  constructor(private fb: FormBuilder, private kentico: KontentDeliveryService, private user: UserService, private cas: CustomerActivityService, public stepService: TodoStepHistoryService, private _elementRef: ElementRef) {
    this.expandedAll = false;
    this.submitted = false;
    this.valid = false;
    this.hideContent = new EventEmitter();
  }

  ngOnInit() {

    this.kentico.getItemAndCache("budget_calculator_widget").then(async ({ item, modular_content }) => {
      this.widgetContent = item.elements;

      this.expenseQuestions = item.elements.expenses.value.map((exp) => modular_content[exp].elements);
      this.incomeQuestions = item.elements.incomes.value.map((exp) => modular_content[exp].elements);

      const expenseCodes = this.expenseQuestions.map(q => q.expense_code.value);
      const incomeCodes = this.incomeQuestions.map(q => q.cad_field.value)


      this.budgetFormGroup = this.fb.group({
        'expenses': this._buildGroup(expenseCodes),
        'incomes': this._buildGroup(incomeCodes),
        'ageGroup': this.fb.control(null, { validators: [Validators.required] }),
      })





      const expenseFormValue = this.client.expenses
      const incomeformValue = this.client.incomes
      this.budgetFormGroup.setValue({ 'expenses': expenseFormValue, 'incomes': incomeformValue, 'ageGroup': this.client.ageGroup })



      const step = this.client.analyze_your_budget


      if (!step) {
        this.stepService.setHistoryStep("analyze_your_budget", { step: AnalyzeStep.Expenses, scenario: 'scenario_1_text' })
        return;
      }

      switch (step.step) {
        case AnalyzeStep.Expenses: {
          this.submitted = false;
          this.valid = false;
          this.hideContent.emit(false)
          break;
        }
        case AnalyzeStep.BudgetResult:
        case AnalyzeStep.BudgetOverview: {
          this.submitted = true;
          this.valid = true;
          this.hideContent.emit(true)
          break;
        }
      }


    })

  }



  public async onSubmit() {
    this.submitted = true;
    if (this.budgetFormGroup.valid) {
      window.scrollTo(0, 0)
      this.valid = true;
      this.hideContent.emit(true)
      this.stepService.setHistoryStep("analyze_your_budget", { step: AnalyzeStep.BudgetOverview })

      const expenseFormValue = this.client.expenses
      const incomeformValue = this.client.incomes
      this.budgetFormGroup.setValue({ 'expenses': expenseFormValue, 'incomes': incomeformValue, 'ageGroup': this.client.ageGroup })

    }
  }

  public onPrev() {
    window.scrollTo(0, 0)

    this.stepService.getStep("analyze_your_budget").then((step) => {
      if (!step) {
        return;
      }


      if (step.step === AnalyzeStep.BudgetResult) {
        return this.stepService.setHistoryStep("analyze_your_budget", { step: AnalyzeStep.BudgetOverview, scenario: step.lastScenario ?? step.scenario })
      }

      this.stepService.setHistoryStep("analyze_your_budget", { step: AnalyzeStep.Expenses })
      this.submitted = false;
      this.valid = false;
      this.hideContent.emit(false)
    });

  }

  public onExpandAllExpenses() {
    this.expandedAll = !this.expandedAll;

    this.accordians.toArray().slice(1).forEach((accordian) => {
      this.expandedAll ? accordian.expand() : accordian.collapse();
    })

  }


  private _buildGroup(fields: string[]) {
    return this.fb.group({
      ...fields.reduce((accum, val) => Object.assign(accum, { [val]: this._getAmountGroup() }), {})
    })
  }

  private _getAmountGroup() {
    return this.fb.group({
      'amount': this.fb.control(null),
      'period': this.fb.control('monthly', [Validators.required])
    })
  }


  public get expenses() {
    return this.budgetFormGroup.get('expenses')
  }

  public get incomes() {
    return this.budgetFormGroup.get('incomes')
  }

  public onEdit() {
    this.valid = false;
    setTimeout(() => {

      const offset = -115;

      const element = this.amountQuestions.first.nativeElement;

      const scroll = element.getBoundingClientRect().top + window.pageYOffset + offset;


      window.scrollTo({ top: scroll })

    }, 100)
  }

}


export enum AnalyzeStep {
  Expenses = 1,
  BudgetOverview = 2,
  BudgetResult = 3
}