import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
} from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { ResourceService } from '@services/planner/resource.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserPromptService } from '@services/planner/user-prompt.service';
import { UserService } from '@services/planner/user.service';
import { UtilityService } from '@services/planner/utility.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'ncoa-article-resource-page',
  templateUrl: './article-resource-page.component.html',
  styleUrls: ['./article-resource-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ArticleResourcePageComponent implements OnInit {

  @Input() takeAwaysComponent: any;
  @Input() articleBody: any;
  @Input() partnersComponent?: any;
  @Input() promo: any;
  @Input() otherContributors?: any;
  @Input() feedback?: any;
  @Input() articleDetailHeader?: any;
  @Input() authorByline?: any;
  @ViewChild('saveBookmarkDesktop') saveBookmarkDesktopE: ElementRef;
  @ViewChild('saveBookmarkMobile') saveBookmarkMobileE: ElementRef;

  articleID: string;
  data: any = null;
  isSaved: boolean = false;

  constructor(
    private resourceService: ResourceService,
    private utility: UtilityService,
    private tools: ToolsPrimaryConfigService,
    private userService: UserService,
    private modalService: UserPromptService,
    private dataLayerService: DataLayerService,
  ) {
    const path = window.location.pathname.split('/');

    if (path.indexOf('resource') > -1) {
      const routeIndex = path.indexOf('resource') + 1;
      const slug = window.location.pathname.split('/')[routeIndex];

      this.resourceService.getArticle(slug).then((data: any) => {
        this.data = data;
        this.articleID = data.id;

        const savedContent = this.tools.customerActivity.basic?.SavedContent || [];
        const flag = savedContent.find((content) => content.ContentId === this.articleID);

        if (flag) {
          this.isSaved = true;
        }

        if (!this.data.articleDetailHeader.readingTime) {
          const div = document.createElement('div');
          div.innerHTML = this.data.articleBody.content;

          const numberOfWords: number = div.innerText.match(/\b[-?(\w+)?]+\b/gi).length;
          const wordsPerMin = 180;
          const newReadingTime = Math.ceil(numberOfWords / wordsPerMin);

          this.data.articleDetailHeader = {
            ...this.data.articleDetailHeader,
            readingTime: `${newReadingTime} min read`,
          };

          // Push analytics data
          this.dataLayerService.push({
            pageType: 'article-page',
          },false);

        setTimeout(() => {
          this.checkInView();
        }, 500);
        }
      });
    }
  }

  checkInView(){
    const _pWithImage = document.querySelectorAll('p > .inline-image');
    _pWithImage.forEach(el =>{
      el.parentElement.classList.add('inline-image-parent');
    });
    const _pWithPdf = document.querySelectorAll('p > .pdf-viewer');
    _pWithPdf.forEach(el =>{
      el.parentElement.classList.add('pdf-viewer-parent');
    });
  }

  ngOnInit(): void { }

  onSaveContentClick(): any {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
    this.utility.getSavedContent().then((savedContent) => {
      let newSavedContent;
      let isSaved;

      const found = savedContent.find((content) => content.ContentId === this.articleID);

      if (found) {
        newSavedContent = savedContent.filter((content) => content.ContentId !== this.articleID);
        isSaved = false;
      } else {
        newSavedContent = savedContent.concat({ ContentId: this.articleID });
        isSaved = true;
      }

      this.utility.updateSavedContent(newSavedContent).then(() => {
        this.isSaved = isSaved;
      });
    });
    }else{
      this.modalService.isModalOpen(true);
    }
  });
  }
}
