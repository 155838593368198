import { Injectable } from '@angular/core';
import { GlobalService } from '@services/global.service';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { take } from 'rxjs/operators';
import { AssessmentService } from './assessment.service';
import { ToolsPrimaryConfigService } from './tools-primary-config.service';
import { UserService } from './user.service';
import { UtilityService } from './utility.service';

@Injectable({ providedIn: 'root' })
export class MyPlannerService {
  constructor(
    private tools: ToolsPrimaryConfigService,
    private global: GlobalService,
    private kontent: KontentDeliveryService,
    private assessment: AssessmentService,
    private utility: UtilityService,
    private userService: UserService,
  ) { }

  async getData() {
    await this.refreshSavedContents();

    const data = {};

    // header
    const eyebrow = this.tools.rawConfig.elements.welcome_message.value;
    const title = this.tools.rawConfig.elements.headline.value;
    const description = this.tools.rawConfig.elements.subhead.value;

    const cta = {
      url: '#',
      text: '',
    };

    const promoKey = this.tools.rawConfig.elements.promo_link.value[0];
    if (this.tools.modular_content[promoKey]) {
      const content = this.tools.modular_content[promoKey];

      switch (content.system.type) {
        case 'ncoa_article_content': {
          cta.url = `${this.tools.rootURL}/resource/${content.elements.url.value}`;
          cta.text = 'Read Now';
          break;
        }
        case 'awa_benefits_tool___tool': {
          cta.url = (this.tools.routes.find((route) => route.assessmentID === content.elements.assessment_id.value) || { route: null }).route;
          cta.text = 'Start Assessment';
          break;
        }
        case 'awa_benefits_tool___vertical_topic': {
          cta.url = (this.tools.routes.find((route) => route.type === 'vertical-topic' && route?.codename === content.system.codename) || { route: null }).route;
          cta.text = 'Learn More';
          break;
        }
        case 'awa_benefits_tool___vertical_subtopic': {
          cta.url = (this.tools.routes.find((route) => route.type === 'vertical-subtopic-tools' && route?.codename === content.system.codename) || { route: null }).route;
          cta.text = 'Learn More';
          break;
        }
      }
    }

    const feature = {
      image: {
        sizes: this.global.remodelImageSrc(this.tools.rawConfig.elements.promo_background.value[0].url, 'promoBackground'),
        src: this.tools.rawConfig.elements.promo_background.value[0].url,
        alt: this.tools.rawConfig.elements.promo_background.value[0].description,
      },
      eyebrow: this.tools.rawConfig.elements.promo_eyebrow.value,
      title: this.tools.rawConfig.elements.promo_headline.value,
      description: this.tools.rawConfig.elements.promo_description.value,
      cta: cta.url ? cta : null,
    };

    const savedContent = this.tools.customerActivity.basic?.SavedContent || [];
    let savedContentCount = 0;

    const systemIds = savedContent.map(({ ContentId }) => ContentId);

    await this.kontent.getItem(null, {
      'system.id[in]': systemIds.join(','),
    }).then((response) => {
      response.items.forEach((item) => {
        if (item.system.type !== 'awa_benefits_tool___tool') {
          savedContentCount++;
        }
      })
    })

    const savedContentUrl = this.tools.routes.find((route) => route.type === 'saved-contents').route;

    data['myPlannerHeader'] = {
      eyebrow,
      title,
      description,
      savedContentCount,
      feature,
      savedContentUrl,
    };

    // sign-in banner
    data['signInBanner'] = {
      title: this.tools.rawConfig.elements.sign_in_banner_heading.value,
      desc: this.tools.rawConfig.elements.sign_in_banner_message.value,
      signUpCTA: {
        url: this.tools.routes.find((route) => route.type === 'sign-up').route,
        text: 'Create Account',
      },
      signInCTA: {
        url: this.tools.routes.find((route) => route.type === 'sign-in').route,
        text: 'Log In',
      },
    };

    // recommended content
    const userProfileId = this.tools.rawConfig.elements.profile_assessment_id.value;
    const response = await this.assessment.getUserProfile(userProfileId)
      .pipe(take(1))
      .toPromise();

    if (this.tools.rawConfig.elements.recommended_content.value.length > 0) {
      const linkedCardGrid = this.tools.modular_content[this.tools.rawConfig.elements.recommended_content.value[0]];
      const heading = linkedCardGrid.elements.headline.value;
      const intro = linkedCardGrid.elements.subhead.value;
      const recommendedCards = [];
      const promoCards = [];

      const resArr = linkedCardGrid.elements.promo_cards.value.map((codename) => this.tools.modular_content[codename]);

      resArr.forEach((res) => {
        const background = {
          url: res.elements.background_image.value[0].url,
          alt: res.elements.background_image.value[0].description,
        };
        const eyebrow = res.elements.eyebrow.value;
        const heading = res.elements.headline.value;
        const cta = {
          label: res.elements.cta_text.value,
          url: res.elements.cta_manual_external_link.value,
        };

        if (res.elements.cta_internal_link.value.length > 0 &&
            this.tools.modular_content[res.elements.cta_internal_link.value[0]]) {
          const e = this.tools.modular_content[res.elements.cta_internal_link.value[0]].elements;
          const s = this.tools.modular_content[res.elements.cta_internal_link.value[0]].system;

          switch (s.type) {
            case 'ncoa_article_content': {
              cta.url = `${this.tools.rootURL}/resource/${e.url.value}`;
              break;
            }
            case 'awa_benefits_tool___vertical_topic': {
              cta.url = (this.tools.routes.find((route) => (
                route.type === 'vertical-topic' &&
                route.codename === s.codename
              )) || { route: null }).route;
              break;
            }
            case 'awa_benefits_tool___vertical': {
              cta.url = (this.tools.routes.find((route) => (
                route.type === 'vertical' &&
                route.codename === s.codename
              )) || { route: null }).route;
              break;
            }
            case 'standard_page': {
              cta.url = `${this.tools.rootURL}/pages/${e.url.value}`;
              break;
            }
            case 'awa_benefits_tool___vertical_subtopic': {
              cta.url = (this.tools.routes.find((route) => (
                route.type === 'vertical-subtopic-learn' &&
                route.codename === s.codename
              )) || { route: null }).route;
              break;
            }
          }
        }

        if (cta.url) {
          promoCards.push({
            type: 'promo',
            background,
            eyebrow,
            heading,
            cta,
          });
        }
      });

      const employmentStatus = this.tools.customerActivity?.finances?.employmentStatus;
      const areasTopOfMind = this.tools.customerActivity?.basic?.areasTopOfMind;
      let resourcesRelatedToRetirement = this.tools.customerActivity?.basic?.resourcesRelatedToRetirement;
          resourcesRelatedToRetirement = typeof resourcesRelatedToRetirement == 'string' ? [resourcesRelatedToRetirement] : resourcesRelatedToRetirement;
      const isCurrentlyReceivingBenefits = this.tools.customerActivity?.basic?.isCurrentlyReceivingBenefits;
      const financialConcerns = this.tools.customerActivity?.basic?.financialConcerns;
      const currentMedicarePlans = this.tools.customerActivity?.basic?.currentMedicarePlans;
      const currentlyEnrolledMedicare = response?.profile?.currentlyEnrolledMedicare;
      const completedAssessments = this.tools.customerActivity.basic?.completedAssessments || [];

      const getManualCards = async () => {
        const resArr = linkedCardGrid.elements.cards.value.map((codename) => this.tools.modular_content[codename]);

        resArr.forEach((res) => {
          switch (res.system.type) {
            case 'ncoa_article_content': {
              // article
              if (res.elements[this.tools.toolTaxonomyCodename].value.length > 0) {
                const div = document.createElement('div');
                      div.innerHTML = res.elements.body_ckeditor.value;
                const text = div.innerText;

                const heading = res.elements.title.value;
                const intro = `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`;
                const url = `${this.tools.rootURL}/resource/${res.elements.url.value}`;

                const taxonomies = res.elements[this.tools.toolTaxonomyCodename].value.map((taxonomy) => taxonomy.codename);
                const verticals = taxonomies.map((taxonomy) => this.tools.getVertical(taxonomy).codename);
                let vertical = this.utility.arrayMode(verticals);
                    vertical = this.tools.awpTaxonomyCatalog.find((v) => v.codename === vertical);

                if (res.elements.awp_trusted_expert.value.length > 0) {
                  // trusted experts
                  recommendedCards.push({
                    id: res.system.id,
                    type: 'referral',
                    eyebrow: `${vertical.name} Expert`,
                    heading,
                    intro,
                    cta: {
                      label: 'View Details',
                      url,
                    },
                    photo: {
                      url: res.elements.expert_logo.value[0].url,
                      alt: res.elements.expert_logo.value[0].description,
                    },
                    bookmark: true,
                  });
                } else {
                  // resource
                  recommendedCards.push({
                    id: res.system.id,
                    type: 'article',
                    breadcrumb: `${vertical.name} / Resource`,
                    heading,
                    intro,
                    cta: {
                      label: 'Read Resource',
                      url,
                    },
                  });
                }
              }
              break;
            }
            case 'awa_benefits_tool___card__tool_': {
              // tool
              if (res.elements.tool.value.length > 0) {
                // const completedAssessments = localStorage.getItem('completedAssessments') ? JSON.parse(localStorage.getItem('completedAssessments')) : [];

                const { elements: e, system: s } = this.tools.modular_content[res.elements.tool.value[0]];

                const heading = res.elements.headline.value;
                const photo = {
                  url: res.elements.card_image.value[0].url,
                  alt: res.elements.card_image.value[0].description,
                };

                const cta = {
                  label: completedAssessments.find((assessment) => assessment === e.assessment_id.value) ? 'View Results' : 'Start Assessment',
                  url: (this.tools.routes.find((route) => (
                    route.assessmentID === e.assessment_id.value
                  )) || { route: null }).route,
                };

                switch (e.assessment_id.value) {
                  case 'generalAssessment':
                  case 'generalAssessmentAWP': {
                    recommendedCards.push({
                      id: s.id,
                      linkedID: s.id,
                      linkedCodename: s.codename,
                      type: 'tool',
                      heading,
                      cta,
                      photo,
                      variation: 'tools-retirement-single-col',
                    });
                    break;
                  }
                  default: {
                    recommendedCards.push({
                      id: s.id,
                      linkedID: s.id,
                      linkedCodename: s.codename,
                      type: 'tool',
                      heading,
                      cta,
                      photo,
                      variation: 'tools-medicare-single-col',
                      theme: 'dark',
                    });
                    break;
                  }
                }
              }
              break;
            }
            case 'awa_benefits_tool___vertical_subtopic': {
              // subtopic
              const subTopicRoute = this.tools.routes.find((route) => (
                route.type === 'vertical-subtopic-learn' &&
                route.codename === res.system.codename
              ));

              if (subTopicRoute) {
                const topicRoute = this.tools.routes.find((route) => (
                  subTopicRoute.parentTopic &&
                  route.codename === subTopicRoute.parentTopic
                ));

                if (topicRoute) {
                  const verticalRoute = this.tools.routes.find((route) => (
                    subTopicRoute.parentVertical &&
                    route.codename === subTopicRoute.parentVertical
                  ));

                  const eyebrow = `${verticalRoute.taxonomy.name} / ${topicRoute.text}`;
                  const heading = res.elements.title.value;
                  const cta = {
                    label: 'Learn More',
                    url: subTopicRoute.route,
                  };

                  recommendedCards.push({
                    id: res.system.id,
                    type: 'subtopic',
                    eyebrow,
                    heading,
                    cta,
                  });
                }
              }
              break;
            }
          }
        });

        promoCards.forEach((promoCard, index) => {
          const pos = (index + 1) * 3;
          if ((pos - 1) <= recommendedCards.length) {
            recommendedCards.splice((pos - 1), 0, promoCard);
          }
        });

        if (recommendedCards.length > 0) {
          return {
            location: false,
            heading,
            intro,
            cards: recommendedCards,
          };
        } else {
          return null;
        }
      };

      let personalizationParam;
      if (employmentStatus) {
        personalizationParam = `${employmentStatus.toLowerCase()}`;
      }

      if (areasTopOfMind) {
        const strings = areasTopOfMind.map((string) => string.toLowerCase());
        personalizationParam = `${personalizationParam},${strings.join(',')}`;
      }

      if (resourcesRelatedToRetirement) {
        const strings = resourcesRelatedToRetirement.map((string) => string.toLowerCase());
        personalizationParam = `${personalizationParam},${strings.join(',')}`;
      }

      if (financialConcerns) {
        const strings = financialConcerns.map((string) => string.toLowerCase());
        personalizationParam = `${personalizationParam},${strings.join(',')}`;
      }

      if (currentMedicarePlans) {
        personalizationParam = `${personalizationParam},${currentMedicarePlans}`;
      }

      if (typeof isCurrentlyReceivingBenefits !== 'undefined' || typeof currentlyEnrolledMedicare !== 'undefined') {
        if (isCurrentlyReceivingBenefits || currentlyEnrolledMedicare) {
          personalizationParam = `${personalizationParam},currentlyenrolledmedicare_true`;
        } else {
          personalizationParam = `${personalizationParam},currentlyenrolledmedicare_false`;
        }
      }

      if (personalizationParam) {
        const content = await this.kontent.getItem(null, {
          'system.type[in]': 'ncoa_article_content,awa_benefits_tool___card__tool_,awa_benefits_tool___vertical_subtopic',
          'elements.awp_personalization_terms[any]': personalizationParam,
          'limit': 500,
        });

        if (content && content?.items && content.items.length > 0) {
          const { items, modular_content } = content;
          const completedAssessments = this.tools.customerActivity.basic?.completedAssessments || [];

          items.forEach((item) => {
            switch (item.system.type) {
              case 'ncoa_article_content': {
                const taxonomies = item.elements[this.tools.toolTaxonomyCodename].value;
                const verticals = taxonomies.map(({ codename }) => this.tools.getVertical(codename));
                const vertical = this.utility.arrayMode(verticals.map(({ name }) => name));

                const div = document.createElement('div');
                      div.innerHTML = item.elements.body_ckeditor.value;
                const text = div.innerText;
                const intro = `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`;

                if (item.elements.awp_trusted_expert.value.length === 0) {
                  // resource page
                  recommendedCards.push({
                    id: item.system.id,
                    type: 'article',
                    heading: item.elements.title.value,
                    intro,
                    cta: {
                      url: `${this.tools.rootURL}/resource/${item.elements.url.value}`,
                      label: 'Read Resource',
                    },
                    breadcrumb: vertical,
                  })
                } else {
                  // trusted expert page
                  recommendedCards.push({
                    id: item.system.id,
                    type: 'referral',
                    eyebrow: vertical,
                    heading: item.elements.title.value,
                    intro,
                    cta: {
                      label: 'View Details',
                      url: `${this.tools.rootURL}/resource/${item.elements.url.value}`,
                    },
                    bookmark: true,
                  });
                }
                break;
              }
              case 'awa_benefits_tool___card__tool_': {
                const linkedTool = modular_content[item.elements.tool.value[0]];

                recommendedCards.push({
                  id: item.system.id,
                  linkedID: linkedTool.system.id,
                  linkedCodename: linkedTool.system.codename,
                  type: 'tool',
                  heading: item.elements.headline.value,
                  intro: item.elements.description.value,
                  cta: {
                    label: completedAssessments.find((key) => key === linkedTool.elements.assessment_id.value)
                      ? 'View Results'
                      : 'Start Assessment',
                    url: (this.tools.routes.find((route) => (
                      route.assessmentID &&
                      route.assessmentID === linkedTool.elements.assessment_id.value
                    )) || { route: this.tools.rootURL }).route,
                  },
                  photo: item.elements.card_image.value.length > 0 ? {
                    url: item.elements.card_image.value[0].url,
                    alt: item.elements.card_image.value[0].description,
                  } : null,
                  variation: linkedTool.elements.assessment_id.value === 'generalAssessment' || linkedTool.elements.assessment_id.value === 'generalAssessmentAWP'
                    ? 'tools-retirement-single-col'
                    : 'tools-medicare-single-col',
                  theme: linkedTool.elements.assessment_id.value === 'generalAssessment' || linkedTool.elements.assessment_id.value === 'generalAssessmentAWP'
                    ? null
                    : 'dark',
                });

                break;
              }
              case 'awa_benefits_tool___vertical_subtopic': {
                const subTopic = this.tools.routes.find((route) => (
                  route.type === 'vertical-subtopic-learn' &&
                  route.codename === item.system.codename
                ));

                if (subTopic) {
                  const topic = this.tools.routes.find((route) => (
                    route.type === 'vertical-topic' &&
                    route.codename === subTopic.parentTopic
                  ));

                  if (topic) {
                    const vertical = this.tools.routes.find((route) => (
                      route.type === 'vertical' &&
                      route.codename === subTopic.parentVertical
                    ));

                    recommendedCards.push({
                      type: 'subtopic',
                      eyebrow: `${vertical.text} / ${topic.text}`,
                      heading: item.elements.title.value,
                      cta: {
                        label: 'Learn More',
                        url: subTopic.route,
                      },
                    });
                  }
                }

                break;
              }
            }
          });

          promoCards.forEach((promoCard, index) => {
            const pos = (index + 1) * 3;
            if ((pos - 1) <= recommendedCards.length) {
              recommendedCards.splice((pos - 1), 0, promoCard);
            }
          });

          if (recommendedCards.length > 0) {
            data['recommended3ColGrid'] = {
              location: false,
              heading,
              intro,
              cards: recommendedCards,
            };
          }
        } else {
          const recommended3ColGrid: any = await getManualCards();
          if (recommended3ColGrid) {
            data['recommended3ColGrid'] = recommended3ColGrid;
          }
        }
      } else {
        const recommended3ColGrid: any = await getManualCards();
        if (recommended3ColGrid) {
          data['recommended3ColGrid'] = recommended3ColGrid;
        }
      }
    }

    // manual multi-card 3-up
    if (this.tools.rawConfig.elements.manual_select_multi_card_3_up.value.length > 0) {
      const key = this.tools.rawConfig.elements.manual_select_multi_card_3_up.value[0];
      const component = this.tools.modular_content[key];

      const heading = component.elements.multi_card_heading.value;
      const cardKeys = component.elements.cards.value;

      if (cardKeys.length === 3) {
        const cards = await this.getManualMultiCards(cardKeys);

        data['manualMultiCard3up'] = {
          heading,
          cards,
        };
      }
    }


    // vertical pathing
    data['verticalPathing'] = this.getVerticalPathing();

    return data;
  }

  async getManualMultiCards(cardKeys) {
    const completedAssessments = this.tools.customerActivity.basic?.completedAssessments || [];

    const cards = [];

    const responses = cardKeys.map((codename) => this.tools.modular_content[codename]);

    responses.forEach((item) => {
      switch (item.system.type) {
        case 'ncoa_article_content': {
          const div = document.createElement('div');
                div.innerHTML = item.elements.body_ckeditor?.value || '';
          const text = div.innerText;

          const taxonomies = item.elements[this.tools.toolTaxonomyCodename].value.map((taxonomy) => taxonomy.codename);
          const verticals = taxonomies.map((taxonomy) => this.tools.getVertical(taxonomy).codename);
          let vertical = this.utility.arrayMode(verticals);
              vertical = this.tools.awpTaxonomyCatalog.find((v) => v.codename === vertical);

          if (item.elements.awp_trusted_expert.value.length > 0) {
            cards.push({
              id: item.system.id,
              type: 'referral',
              eyebrow: `${vertical.name} Expert`,
              heading: item.elements.title.value,
              intro: `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`,
              cta: {
                label: 'View Details',
                url: `${this.tools.rootURL}/resource/${item.elements.url.value}`,
              },
              photo: item.elements.expert_logo.value.length > 0 ? {
                url: item.elements.expert_logo.value[0].url,
                alt: item.elements.expert_logo.value[0].description,
              } : null,
              bookmark: true,
              full: false,
            });
          } else {
            cards.push({
              id: item.system.id,
              type: 'article',
              heading: item.elements.title.value,
              intro: `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`,
              cta: {
                url: `${this.tools.rootURL}/resource/${item.elements.url.value}`,
                label: 'Read Resource',
              },
              breadcrumb: item.elements[this.tools.toolTaxonomyCodename].value.length > 0
                ? item.elements[this.tools.toolTaxonomyCodename].value[0].name
                : 'Resource',
            });
          }
          break;
        };
        case 'awa_benefits_tool___vertical_subtopic': {
          const currentRoute = this.tools.routes.find((route) => route.codename && route.codename === item.system.codename);
          const parentTopicRoute = this.tools.routes.find((route) => route.codename && route.codename === currentRoute.parentTopic);
          const parentVerticalRoute = this.tools.routes.find((route) => route.codename && route.codename === parentTopicRoute.parentVertical);

          cards.push({
            id: item.system.id,
            type: 'subtopic',
            eyebrow: `${parentVerticalRoute.taxonomy.name} / ${parentTopicRoute.text}`,
            heading: item.elements.title.value,
            cta: {
              url: currentRoute.route,
              label: 'Learn More',
            },
          });

          break;
        }
        case 'awa_benefits_tool___card__tool_': {
          const tool = this.tools.modular_content[item.elements.tool.value[0]];

          if (tool.elements.assessment_id.value === 'generalAssessment' || tool.elements.assessment_id.value === 'generalAssessmentAWP') {
            cards.push({
              id: item.system.id,
              linkedID: tool.system.id,
              linkedCodename: tool.system.codename,
              type: 'tool',
              heading: item.elements.headline.value,
              cta: {
                label: completedAssessments.find((key) => key === tool.elements.assessment_id.value) ? 'View Results' : 'Start Assessment',
                url: (this.tools.routes.find((route) => (
                  route.assessmentID &&
                  route.assessmentID === tool.elements.assessment_id.value
                )) || { route: null }).route,
              },
              photo: {
                url: item.elements.card_image.value.length > 0 ? item.elements.card_image.value[0].url : '',
                alt: item.elements.card_image.value.length > 0 ? item.elements.card_image.value[0].description || '' : '',
              },
              variation: 'tools-retirement-single-col',
            });
          } else {
            cards.push({
              id: item.system.id,
              linkedID: tool.system.id,
              linkedCodename: tool.system.codename,
              type: 'tool',
              heading: item.elements.headline.value,
              cta: {
                label: completedAssessments.find((key) => key === tool.elements.assessment_id.value) ? 'View Results' : 'Start Assessment',
                url: (this.tools.routes.find((route) => (
                  route.assessmentID &&
                  route.assessmentID === tool.elements.assessment_id.value
                )) || { route: null }).route,
              },
              photo: {
                url: item.elements.card_image.value.length > 0 ? item.elements.card_image.value[0].url : '',
                alt: item.elements.card_image.value.length > 0 ? item.elements.card_image.value[0].description || '' : '',
              },
              variation: 'tools-medicare-single-col',
              theme: 'dark',
            });
          }

          break;
        }
        case 'awa_benefits_tools___promo_card': {
          let url = item.elements.cta_manual_external_link.value;

          if (item.elements.cta_internal_link.value.length > 0) {
            const linkedContent = this.tools.modular_content[item.elements.cta_internal_link.value[0]];

            switch (linkedContent.system.type) {
              case 'ncoa_article_content': {
                url = `${this.tools.rootURL}/resource/${linkedContent.elements.url.value}`;
                break;
              }
              case 'standard_page': {
                url = `${this.tools.rootURL}/page/${linkedContent.elements.url.value}`;
                break;
              }
              case 'awa_benefits_tool___vertical_topic': {
                url = (this.tools.routes.find((route) => (
                  route.type && route.codename &&
                  route.type === 'vertical-topic' &&
                  route.codename === linkedContent.system.codename
                )) || { route: null }).route;
                break;
              }
              case 'awa_benefits_tool___vertical': {
                url = (this.tools.routes.find((route) => (
                  route.type && route.codename &&
                  route.type === 'vertical' &&
                  route.codename === linkedContent.system.codename
                )) || { route: null }).route;
                break;
              }
              case 'awa_benefits_tool___vertical_subtopic': {
                url = (this.tools.routes.find((route) => (
                  route.type && route.codename &&
                  route.type === 'vertical-subtopic-learn' &&
                  route.codename === linkedContent.system.codename
                )) || { route: null }).route;
                break;
              }
            }
          }

          if (url) {
            cards.push({
              type: 'promo',
              background: {
                url: item.elements.background_image.value.length > 0 ? item.elements.background_image.value[0].url : '',
                alt: item.elements.background_image.value.length > 0 ? item.elements.background_image.value[0].description : '',
              },
              eyebrow: item.elements.eyebrow.value,
              heading: item.elements.headline.value,
              cta: {
                label: item.elements.cta_text.value,
                url,
              }
            });
          }

          break;
        }
      }
    });

    return cards;
  }

  getVerticalPathing() {
    const verticals = this.tools.routes.filter((route) => route.type === 'vertical');
    const verticalPaths = new Array(verticals.length);

    verticals.forEach((vertical) => {
      const index = this.tools.awpTaxonomyCatalog.findIndex((taxonomy) => (taxonomy.codename === vertical.taxonomy.codename));
      verticalPaths[index] = vertical;
    });

    const paths = [];

    verticalPaths.forEach((route) => {
      const links = [{ text: 'Explore More', url: route.route }];
      const benefitRoute = this.tools.routes.find((bRoute) => (
        bRoute.type === 'vertical-benefits' &&
        bRoute.codename === route.codename
      ));

      if (benefitRoute) {
        links.push({
          text: 'Find Benefits',
          url: benefitRoute.route,
        });
      }

      paths.push({
        title: route.text,
        links,
      });
    });

    paths.push({
      title: 'Explore All Benefits',
      links: [{
        text: 'Find Benefits',
        url: (this.tools.routes.find((route) => (
          route.type === 'all-benefits'
        )) || { route: null }).route,
      }],
    });

    return {
      heading: `Explore the ${this.tools.rawConfig.elements.tool_title.value}`,
      items: paths,
    };
  }

  async refreshSavedContents() {
    let savedContents = this.tools.customerActivity.basic?.SavedContent || [];
    const previousLength = savedContents.length;

    await this.kontent.getItem(null, {
      'system.id[in]': savedContents.map(({ ContentId }) => ContentId).join(','),
    }).then((response) => {
      savedContents = response.items.map((item) => ({
        ContentId: item.system.id,
      }));
    });

    if (savedContents.length !== previousLength) {
      this.userService.isLoggedIn()
        .pipe(take(1))
        .subscribe((isLoggedIn) => {
          if (isLoggedIn) {
            this.utility.updateSavedContent(savedContents);
          }
        });
    }
  }
}
