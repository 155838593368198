import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { UserService } from '@services/planner/user.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'ncoa-save-to-planner',
  templateUrl: './save-to-planner.component.html',
  styleUrls: ['./save-to-planner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SaveToPlannerComponent implements OnInit {

  selectedType: string = 'all';
  selectedVertical: string = 'all';
  isLoading: boolean = true;

  constructor(
    private dataLayerService: DataLayerService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.userService.isLoggedIn()
      .pipe(take(1))
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.isLoading = false;
          this.dataLayerService.push({
            clickUrl: location.href,
            pageTitle: document.title || '',
            contentType: 'saved-content',
            pageType: 'saved-content-page',
          },false);
        } else {
          window.location.href = '/404';
        }
      });
  }

  updateSelectedType(newValue): void {
    this.selectedType = newValue;
  }

  updateSelectedVertical(newValue): void {
    this.selectedVertical = newValue;
  }
}
