import { Component, OnInit, Input } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { SubTopicService } from '@services/planner/subtopic.service';

@Component({
  selector: 'ncoa-subtopic-expert',
  templateUrl: './subtopic-expert.component.html',
  styleUrls: ['./subtopic-expert.component.scss']
})
export class SubtopicExpertComponent implements OnInit {

  pageID: string = null;
  data: {
    subtopicHeader: any;
    promoTop: any;
    singleColCardList: any;
    expertListingHeadline: any;
    trustedExperts: any;
    toolCard1up: any;
  } = null;

  constructor(
    private subTopicService: SubTopicService,
  ) {
    this.subTopicService.getData().then((data: any) => {
      this.pageID = data.id;
      this.data = {
        subtopicHeader: data?.subtopicHeader,
        promoTop: data?.promoTop,
        singleColCardList: data?.singleColCardList,
        expertListingHeadline: data?.expertListingHeadline,
        trustedExperts: data?.trustedExperts,
        toolCard1up: data?.toolCard1up,
      };
    });
  }

  ngOnInit(): void {
  }

}
