<ncoa-template-default class="taxonomy-page" *ngIf="coreType === 'taxonomy-page' || coreType === 'special-page'">
  <ng-container *ngFor="let component of components; index as order" [ngSwitch]="component?.componentType">
    <ncoa-category-hero *ngSwitchCase="componentTypes.category_page_hero" [category]="component.category"
      ncoa-engageable="{{order}}"></ncoa-category-hero>
    <ncoa-content-package *ngSwitchCase="componentTypes.content_package" [title]="component.title"
      [articles]="component.articles" ncoa-engageable="{{order}}"></ncoa-content-package>
    <ncoa-feature-page-block *ngSwitchCase="componentTypes.featured_page_block" [image]="component.image"
      [headline]="component.headline" [description]="component.description" [type]="component.type"
      [background]="component.background" [audiences]="component.audiences" [url]="component.url"
      [blockCTA]="component.blockCTA" [orientation]="component.orientation"
      ncoa-engageable="{{order}}"></ncoa-feature-page-block>
    <ncoa-featured-tool-breaker *ngSwitchCase="componentTypes.featured_tool_breaker" [variation]="component.variation"
      [eyebrow]="component.eyebrow" [headline]="component.headline" [description]="component.description"
      [image]="component.image" [cta]="component.cta" ncoa-engageable="{{order}}"></ncoa-featured-tool-breaker>
    <ncoa-donate *ngSwitchCase="componentTypes.call_to_donate" [heading]="component.heading"
      [description]="component.description" [form_desc]="component.form_desc" [background]="component.photo"
      [backgroundCaption]="component.caption" ncoa-engageable="{{order}}"></ncoa-donate>
    <ncoa-external-form *ngSwitchCase="componentTypes.external_form" [component]="component"
      ncoa-engageable="{{order}}"></ncoa-external-form>
    <ncoa-newsletter *ngSwitchCase="componentTypes.newsletter_signup_block" [heading]="component.heading"
      [description]="component.description" [background]="component.background"
      [backgroundCaption]="component.backgroundCaption" [mobilebackground]="component.mobilebackground"
      [width]="component.width" [height]="component.height" ncoa-engageable="{{order}}"
      [everyActionFormUrl]="component.everyActionFormUrl" [fieldErrorText]="component.fieldErrorText"
      [emailErrorText]="component.emailErrorText"></ncoa-newsletter>
    <app-partners *ngSwitchCase="componentTypes.partner_grid" [heading]="component.heading"
      [partners]="component.partners" ncoa-engageable="{{order}}"></app-partners>
    <ncoa-subcategory-pathing *ngSwitchCase="componentTypes.category_pathing" [headline]="component.headline"
      [categories]="component.categories" [ncoa-engageable]="order" page="standard"></ncoa-subcategory-pathing>
    <ncoa-topic *ngSwitchCase="componentTypes.topic_different_audience" [theme]="component.theme"
      [topic]="component.topic" ncoa-engageable="{{order}}"></ncoa-topic>
    <ncoa-load-more *ngSwitchCase="componentTypes.category_load_more" [entries]="component.entries"
      ncoa-engageable="{{order}}"></ncoa-load-more>
    <ncoa-audience *ngSwitchCase="componentTypes.cross_pathing_module" [theme]="theme" [audience]="component.audience"
      ncoa-engageable="{{order}}"></ncoa-audience>
    <ncoa-special-pathing *ngSwitchCase="'specialPathing'" [paths]="component.paths"></ncoa-special-pathing>

  </ng-container>
</ncoa-template-default>

<ncoa-template-default class="taxonomy-page" *ngIf="coreType === 'category-search-page'">
  <ncoa-category-search *ngIf="customSearchData" [image]="customSearchData.image" [eyebrow]="customSearchData.eyebrow"
    [title]="customSearchData.title" [description]="customSearchData.description"
    [filterGroups]="customSearchData.filterGroups" [placeholder]="customSearchData.placeholder"
    [hideSearchBar]="customSearchData.hideSearchBar" [showFilterOption]="customSearchData.showFilterOption"
    [searchBarLink]="customSearchData.searchBarLink" [resultLabel]="customSearchData.resultLabel"
    [filterGroupTags]="customSearchData.filterGroupTags" [filterByDate]="customSearchData.filterByDate"
    [additionalComponents]="customSearchData.additionalComponents"
    [specialPaths]="customSearchData.specialPaths"></ncoa-category-search>
</ncoa-template-default>

<!-- <ncoa-template-default class="taxonomy-page" *ngIf="coreType === 'ncoa-landing-page'"> -->
<ng-container *ngIf="coreType === 'ncoa-landing-page'">
  <ncoa-landing-page [pageData]="components"></ncoa-landing-page>
</ng-container>
<!-- </ncoa-template-default> -->

<ng-container *ngIf="coreType === 'partner'">
  <ncoa-partner-landing [partnerData]="partnerData"></ncoa-partner-landing>
</ng-container>

<ng-container *ngIf="coreType === 'age-well-planner'">
  <ncoa-template-default>
    <ng-container [ngSwitch]="toolsContentType">
      <age-well-planner-sign-up *ngSwitchCase="'sign-up'"></age-well-planner-sign-up>
      <age-well-planner-sign-in *ngSwitchCase="'sign-in'"></age-well-planner-sign-in>
      <app-account-verified *ngSwitchCase="'verify-user'"></app-account-verified>
      <app-forgot-password *ngSwitchCase="'forgot-password'"></app-forgot-password>
      <assessment-page *ngSwitchCase="'assessment'"></assessment-page>
      <vertical-page *ngSwitchCase="'vertical'"> </vertical-page>
      <vertical-plan *ngSwitchCase="'vertical-subtopic-plan'"></vertical-plan>
      <planner-home-page *ngSwitchCase="'home'"></planner-home-page>
      <!-- <my-planner-page *ngSwitchCase="'my-planner'"></my-planner-page> -->
      <explore-all-benefits *ngSwitchCase="'all-benefits'"></explore-all-benefits>
      <vertical-topic *ngSwitchCase="'vertical-topic'"></vertical-topic>
      <vertical-plan *ngSwitchCase="'vertical-plan'"></vertical-plan>
      <vertical-page-benefits-look-up *ngSwitchCase="'vertical-benefits'"></vertical-page-benefits-look-up>
      <ncoa-subtopic-learn *ngSwitchCase="'vertical-subtopic-learn'"></ncoa-subtopic-learn>
      <ncoa-subtopic-expert *ngSwitchCase="'vertical-subtopic-experts'"></ncoa-subtopic-expert>
      <ncoa-subtopic-tools *ngSwitchCase="'vertical-subtopic-tools'"></ncoa-subtopic-tools>
      <ncoa-subtopic-landing *ngSwitchCase="'vertical-subtopic-landing'"></ncoa-subtopic-landing>
      <!-- <ncoa-article-resource-page *ngSwitchCase="'resource'"></ncoa-article-resource-page> -->
      <ncoa-contact-form-page-planner *ngSwitchCase="'contact-us'"></ncoa-contact-form-page-planner>
      <account-profile *ngSwitchCase="'profile'"></account-profile>
      <ncoa-planner-standard *ngSwitchCase="'pages'"></ncoa-planner-standard>
      <ncoa-save-to-planner *ngSwitchCase="'saved-contents'"></ncoa-save-to-planner>
      <lead-gen-assessment *ngSwitchCase="'lead-gen'"></lead-gen-assessment>
      <ncoa-assessment-summary *ngSwitchCase="'income-breakdown'"></ncoa-assessment-summary>
      <ncoa-cost-estimator *ngSwitchCase="'cost-estimator'"></ncoa-cost-estimator>
      <app-medicare-contact *ngSwitchCase="'medicare'"></app-medicare-contact>
      <app-annuities *ngSwitchCase="'annuity'"></app-annuities>
      <age-well-planner *ngSwitchCase="'medicare-awp'">
        <app-medicare-contact [awp]="true"></app-medicare-contact>
      </age-well-planner>
      <app-case-manager *ngSwitchCase="'case-manager'"></app-case-manager>
</ng-container>
</ncoa-template-default>

<ncoa-template-default class="page-404" *ngIf="errorPage">
  <div *ngFor="let component of components; index as order" [ngSwitch]="component?.componentType">
    <ncoa-standard-page-header *ngSwitchCase="'header'" [image]="component.primary_image.value[0]"
      [headline]="component.title.value" [description]="component.body_text.value" ncoa-engageable="{{ order }}">
    </ncoa-standard-page-header>
  </div>
</ncoa-template-default>