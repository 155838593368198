<div [ngClass]="styleVersion === 'v2' ? 'articleV2' : 'article' ">
<section class="component article-body article-query-selector" >

  <div class="article-body__container">

    <div class="article-body__pre-content">
      <ng-content></ng-content>
    </div>

    <div class="body-flex-wrapper">
      <app-embedded-js #embeddedJS [ngClass]="[scriptBeforeBody ? 'beforeBody' : 'afterBody']"></app-embedded-js>
      <div class="article-body__wrapper">
        <div class="article-body__inner-wrapper" [innerHTML]="content">

        </div>
      </div>
    </div>

    <div class="article--share" *ngIf="url && false">
      <h3 *ngIf='saveToContent'>{{ saveToContent.isSaved ? 'Saved' : 'Save' }}</h3>
      <aside *ngIf='saveToContent'>
        <ul class="save-bookmark">
          <li>
            <button type="button" (click)="onSaveContentClick()" aria-label="Save Content">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="19" stroke="#0B4A5D"
                  [attr.fill]="saveToContent.isSaved ? '#0B4A5D' : '#FFFFFF'" stroke-width="2" />
                <path
                  d="M14 14V25.9425C14 26.777 14.9617 27.2444 15.6178 26.7289L20 23.2857L24.3822 26.7289C25.0383 27.2444 26 26.777 26 25.9425V14C26 13.4477 25.5523 13 25 13H15C14.4477 13 14 13.4477 14 14Z"
                  [attr.stroke]="saveToContent.isSaved ? '#FFFFFF' : '#0B4A5D'" stroke-width="2" />
              </svg>
            </button>
          </li>
        </ul>
      </aside>
      <div class="wrapper">
        <h3>Share</h3>
        <aside>
          <ul>
            <li *ngIf="url"><a ncoa-share-link="facebook" href={{url.facebook}} class="icon icon--facebook"
                target="_blank" aria-label="Share on Facebook"></a></li>
            <li *ngIf="url"><a ncoa-share-link="twitter" href={{url.twitter}} class="icon icon--twitter" target="_blank"
                aria-label="Share on Twitter"></a></li>
            <li *ngIf="url"><a ncoa-share-link="linkedin" href={{url.linkedin}} class="icon icon--linkedin"
                target="_blank" aria-label="Share on Linkedin"></a></li>
            <li *ngIf="url"><a ncoa-share-link="pinterest" href={{url.pinterest}} class="icon icon--pinterest"
                target="_blank" aria-label="Share on Pinterest"></a></li>
            <li *ngIf="url">
              <a ncoa-share-link="mail" href="{{ url.mailto }}" target="_blank" aria-label="Share on Email">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM16.543 15.2783C19.7504 12.9514 20.6623 12.2596 21.3855 11.6936C21.5113 11.5992 21.7 11.6936 21.7 11.8508V18.2656C21.7 19.1146 21.0082 19.775 20.1906 19.775H7.10938C6.26035 19.775 5.6 19.1146 5.6 18.2656V11.8508C5.6 11.6936 5.75723 11.5992 5.88301 11.6936C6.60625 12.2596 7.51816 12.9514 10.7256 15.2783C10.8345 15.3561 10.9562 15.4493 11.0887 15.5507C11.7594 16.0641 12.7047 16.7877 13.65 16.7877C14.6068 16.7877 15.6111 16.0035 16.2704 15.4886C16.3695 15.4112 16.4608 15.34 16.543 15.2783ZM15.5802 14.7356C15.017 15.1698 14.2318 15.7753 13.65 15.75C13.0395 15.7754 12.2437 15.1629 11.679 14.7282C11.5456 14.6256 11.4252 14.5328 11.323 14.4607C7.48614 11.6912 6.90467 11.2244 6.10635 10.5834C6.03451 10.5257 5.9609 10.4666 5.88301 10.4043C5.69434 10.2785 5.6 10.0584 5.6 9.80684V9.20937C5.6 8.3918 6.26035 7.7 7.10938 7.7H20.1906C21.0082 7.7 21.7 8.3918 21.7 9.20937V9.80684C21.7 10.0584 21.5742 10.2785 21.3855 10.4043C21.3077 10.4666 21.234 10.5257 21.1622 10.5834C20.3639 11.2244 19.7824 11.6912 15.9455 14.4607C15.8409 14.5346 15.7171 14.63 15.5802 14.7356Z"
                    fill="#0B4A5D" />
                </svg>
              </a>
            </li>
          </ul>
        </aside>
      </div>
    </div>
  </div>
</section>
</div>
