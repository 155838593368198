<section class="article-header component">
  <div class="article-header__container">
    <div class="article-header__wrapper">
      <div class="texts">
        <a *ngIf="eyebrow?.url" [href]="eyebrow.url" class="eyebrow">
          <h2>{{eyebrow.text}}</h2>
        </a>
        <h1 class="article-title article-title--{{ titleScale }}">{{ articleTitle }}</h1>
        <div class="details-and-button">
          <div class="details" *ngIf="publishDate != '' || readingTime">
            <ng-container *ngIf="publishDate != ''">{{publishDate}}</ng-container>
            <ng-container *ngIf="publishDate && (readingTime || sponsorship_indication_text)"> | </ng-container>
            <ng-container *ngIf="readingTime">{{readingTime}} min read</ng-container>
            <ng-container *ngIf="readingTime && sponsorship_indication_text " > | </ng-container>
            <ng-container *ngIf="sponsorship_indication_text" class="sponsored"> <span class='sponsored'>{{sponsorship_indication_text}}</span></ng-container>
          </div>

          <button class="print-button no-print" (click)="printPage()" aria-label="click here to print this page">
            <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.93103 17V14.4861C3.93103 12.5693 5.53621 11.0295 7.53448 11.0295H15.069V17H3.93103ZM1.60517 13.2292H2.2931V10.7153C2.2931 10.1811 2.75172 9.7726 3.27586 9.7726H15.7241C16.281 9.7726 16.7069 10.2125 16.7069 10.7153V13.2292H17.3948C18.2793 13.2292 19 12.5379 19 11.6894V6.03323C19 4.80772 17.9517 3.77075 16.6414 3.77075H2.35862C1.08103 3.77075 0 4.7763 0 6.03323V11.6894C0 12.5064 0.72069 13.2292 1.60517 13.2292Z"
                fill="#0B4A5D" />
              <path d="M3.93115 2.51386C3.93115 1.13124 5.11046 0 6.55184 0H15.0691V2.51386H3.93115Z" fill="#0B4A5D" />
            </svg>
            <p>Print this page</p>
          </button>
        </div>
        <div class="authors" *ngIf="authors?.authors && authors.authors.length">
          <div *ngFor="let author of authors.authors; index as  i" class="authors__text">
            <span class="authors-name" *ngIf="!author.link"> {{author.name}} </span>
            <span class="authors-name" *ngIf="author.link"><a [attr.href]="kebabCase(author.link)" [attr.aria-label]="author.name">{{ author.name }}</a></span>
            <span *ngIf="author.position" class="authors-position"> {{author.position}}</span>
          </div>
        </div>
        <div class="partnership" *ngIf="partnership">
          <div class="partnership__text">{{ partnership.eyebrow }}</div>
          <div class="partnership__logo">
            <a *ngIf="partnership.url; else elseBlock1" [href]="partnership.url" target="_blank"><img
                [src]="partnership.src" [alt]="partnership.alt" /></a>
            <ng-template #elseBlock1><img [src]="partnership.src" [alt]="partnership.alt" /></ng-template>
          </div>
        </div>

      </div>
      <div class="image-and-takeaways">
        <div class="image">
          <img *ngIf="image && image.url && !image.responsive" [attr.src]="image.url" [attr.alt]="image.alt"
            [attr.title]="image.alt" width="100%" height="100%" />
          <picture *ngIf="image.responsive">
            <source *ngFor="let item of image.responsive" [srcset]="item.url" [media]="item.media" />
            <img [src]="image.responsive[0].url" [alt]="image.alt" width="{{width}}" height="{{height}}" />
          </picture>
          <img *ngIf="!image || !image.url" src="../../../assets/images/article-hero-coral-desktop.png"
            [alt]="articleTitle" width="100%" height="100%" />
        </div>
        <app-takeaways *ngIf="takeaways" [heading]="takeaways.heading"
          [takeaways]="takeaways.takeaways"></app-takeaways>
        <div class="wrapper" >
          <div class="share" [class.fixed]="isSticky" [ngStyle]="{'position': isSticky ? 'fixed' : 'static', 'top': stickyTop + 'px'}" #stickyElement>
            <h3>Share</h3>
            <aside>
              <ul>
                <li *ngIf="url"><a ncoa-share-link="facebook" href={{url.facebook}} class="icon icon--facebook"
                    target="_blank" aria-label="Share on Facebook"></a></li>
                <li *ngIf="url"><a ncoa-share-link="twitter" href={{url.twitter}} class="icon icon--twitter"
                    target="_blank" aria-label="Share on Twitter"></a></li>
                <li *ngIf="url"><a ncoa-share-link="linkedin" href={{url.linkedin}} class="icon icon--linkedin"
                    target="_blank" aria-label="Share on Linkedin"></a></li>
                <li *ngIf="url"><a ncoa-share-link="pinterest" href={{url.pinterest}} class="icon icon--pinterest"
                    target="_blank" aria-label="Share on Pinterest"></a></li>
                <li *ngIf="url">
                  <a ncoa-share-link="mail" href="{{ url.mailto }}" target="_blank" aria-label="Share on Email">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM16.543 15.2783C19.7504 12.9514 20.6623 12.2596 21.3855 11.6936C21.5113 11.5992 21.7 11.6936 21.7 11.8508V18.2656C21.7 19.1146 21.0082 19.775 20.1906 19.775H7.10938C6.26035 19.775 5.6 19.1146 5.6 18.2656V11.8508C5.6 11.6936 5.75723 11.5992 5.88301 11.6936C6.60625 12.2596 7.51816 12.9514 10.7256 15.2783C10.8345 15.3561 10.9562 15.4493 11.0887 15.5507C11.7594 16.0641 12.7047 16.7877 13.65 16.7877C14.6068 16.7877 15.6111 16.0035 16.2704 15.4886C16.3695 15.4112 16.4608 15.34 16.543 15.2783ZM15.5802 14.7356C15.017 15.1698 14.2318 15.7753 13.65 15.75C13.0395 15.7754 12.2437 15.1629 11.679 14.7282C11.5456 14.6256 11.4252 14.5328 11.323 14.4607C7.48614 11.6912 6.90467 11.2244 6.10635 10.5834C6.03451 10.5257 5.9609 10.4666 5.88301 10.4043C5.69434 10.2785 5.6 10.0584 5.6 9.80684V9.20937C5.6 8.3918 6.26035 7.7 7.10938 7.7H20.1906C21.0082 7.7 21.7 8.3918 21.7 9.20937V9.80684C21.7 10.0584 21.5742 10.2785 21.3855 10.4043C21.3077 10.4666 21.234 10.5257 21.1622 10.5834C20.3639 11.2244 19.7824 11.6912 15.9455 14.4607C15.8409 14.5346 15.7171 14.63 15.5802 14.7356Z"
                        fill="#0B4A5D" />
                    </svg>
                  </a>
                </li>
              </ul>
            </aside>
          </div>

          <div class="share-placeholder"></div>
        </div>
      </div>
    </div>
  </div>
</section>