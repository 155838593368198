import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { KontentDeliveryService } from './kontent-delivery.service';
import { ComponentService } from './component.service';
import { SeoService } from '@services/seo.service';
import { DataLayerService } from '@services/data-layer.service';
import { ErrorService } from '@services/error.service';
import { ModalService } from '@services/modal.service';
import { GlobalService } from '@services/global.service';
import { UrlService } from '@services/url.service';

// Add utc
dayjs.extend(utc);

@Injectable({
  providedIn: 'root'
})
export class TaxonomyService {
  // Static
  heroPlaceholder: string = '/assets/images/category-hero-placeholder.svg';
  codeName: string = 'category_template';
  customTaxonomyCodename: string = 'taxonomy_custom_content';
  audiencePathingCodename: string = 'homepage_audience_pathing';
  articleCodename: string = 'ncoa_article_content';

  // Dynamic
  isAudienceOnly: boolean = false;

  audience: string;
  category: string;
  categories: string[];
  otherTaxonomy: string;

  categoriesTree: any = {};
  categoriesMap: any = {};
  audiencesMap: any = {};
  otherTaxonomiesMap: any = {};

  currentAudienceData: any = {};
  currentCategoryData: any = {};
  currentOtherTaxonomyData: any = {};

  currentPagePath: any = window.location?.pathname || '/';

  taxonomyArticles: any[] = []; // Articles tagged with current audience/category
  taxonomyArticlesModularContent: any = {}; // Modular content for this.taxonomyArticles reference
  curatedArticles: any = {}; // Article data from curated contents (featured articles, curated content package)
  relatedTaxonomies?: ({
    audiences: (any)[],
    categories: (any)[]
  });

  usedArticles: any[] = [];

  catalog: any[] = [];

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private componentService: ComponentService,
    private router: Router,
    private seoService: SeoService,
    private dataLayerService: DataLayerService,
    private errorService: ErrorService,
    private modalService: ModalService,
    private globalService: GlobalService,
    private urlService: UrlService
  ) {}

  async getData(rootTerm, categories, currentPagePath: any = '/') {
    this.currentPagePath = currentPagePath;
    this.audience = rootTerm;
    this.categories = categories;
    this.category = categories.length > 0 ? categories[(categories.length - 1)].replace(/-/g, '_') : '';

    // Taxonomy Request for "Other" Taxonomy
    const otherTaxonomyRequest = this.kontentDeliveryService.getItem(null, {
      'elements.other_taxonomy[contains]': this.audience,
      depth: 4,
      limit: 1,
    });

    // Custom Taxonomy Request For Audience
    const customAudienceRequest = this.kontentDeliveryService.getItem(null, {
      'system.type': 'template___audience_page',
      'elements.audiences[contains]': this.audience,
      // 'order': 'elements.display_date[desc]',
      depth: 4,
      limit: 1,
    });

    // Custom Taxonomy Request For Category
    let path = currentPagePath;
        path = path.substring(1);

    if (path[(path.length - 1)] === '/') {
      path = path.substr(0, (path.length - 1));
    }

    const customCategoryRequest = this.category && this.audience ?
      this.kontentDeliveryService.getItem(null, {
        'system.type': 'taxonomy_custom_content',
        'elements.categories[any]': this.category,
        'elements.audiences[any]': this.audience,
        'elements.category_page_url': path,
        'order': 'system.last_modified[desc]',
        depth: 4,
        limit: 1,
      }) :
      Promise.resolve({
        items: [],
        modular_content: {}
      });

    // Initial data requests
    const [
      rawOtherTaxonomy,
      rawCustomAudienceData,
      rawCustomCategoryData,
      rawDefaultComponents,
    ] = await Promise.all([
      otherTaxonomyRequest,
      customAudienceRequest,
      customCategoryRequest,
      this.getDefaultComponents(),
      this.getTaxonomies(),
      this.modalService.showAvailableModal(this.audience ? this.audience.replace(/-/g, '_') : '')
    ]);

    // Extract curated article codenames
    // Should only select from any of the following in priority:
    // otherTaxonomy || category || audience
    const rawItems = rawOtherTaxonomy.items.length ? rawOtherTaxonomy.items :
                      rawCustomCategoryData.items.length ? rawCustomCategoryData.items :
                      rawCustomAudienceData.items.length ? rawCustomAudienceData.items :
                      [];
    const rawModularContent = rawOtherTaxonomy.items.length ? rawOtherTaxonomy.modular_content :
                      rawCustomCategoryData.items.length ? rawCustomCategoryData.modular_content :
                      rawCustomAudienceData.items.length ? rawCustomAudienceData.modular_content :
                      {};
    const curatedArticleCodenames = this.extractCuratedArticlesFromRawItems(rawItems, rawModularContent);

    // Get related contents
    const [
      otherAudiencesFeedData,
      rawTaxonomyArticles,
      rawCuratedArticles,
      rawOtherTaxonomyArticles,
      dynamicComponents,
    ] = await Promise.all([
      this.getOtherAudiences(),
      this.getTaxonomyArticles(30, curatedArticleCodenames),
      this.getCuratedArticles(curatedArticleCodenames),
      this.getOtherTaxonomyArticles(),
      this.getDynamicComponents()
    ]);

    // Save curated articles
    rawCuratedArticles.items.forEach(rawArticle => {
      if ( !(rawArticle.system.codename in this.curatedArticles) ) {
        this.curatedArticles[rawArticle.system.codename] = rawArticle;
        // Append modular_content for later reference
        this.curatedArticles[rawArticle.system.codename].modular_content = rawCuratedArticles.modular_content;
      }
    });

    // remove duplicates
    const uniqueArticles = rawTaxonomyArticles.items.filter((elem, index) =>
      rawTaxonomyArticles.items.findIndex(obj => obj.system.id === elem.system.id) === index);

    const articlesWithDate = uniqueArticles.filter((article) => article.elements.display_date.value);
    const articlesWithOutDate = uniqueArticles.filter((article) => !article.elements.display_date.value);

    const sortedArticlesWithDate = articlesWithDate.sort((a, b) => {
      const dateA = new Date(a.elements.display_date.value);
      const dateB = new Date(b.elements.display_date.value);

      return dateB.getTime() - dateA.getTime();
    });

    const sortedArticlesWithOutDate = articlesWithOutDate.sort((a, b) => {
      const dateA = new Date(a.system.last_modified);
      const dateB = new Date(b.system.last_modified);

      return dateB.getTime() - dateA.getTime();
    });

    rawTaxonomyArticles['items'] = [...sortedArticlesWithDate, ...sortedArticlesWithOutDate];

    // Catch 404 pages
    // Render the 404 template if conditions are true
    if ((!rawOtherTaxonomy.items.length && !rawCustomAudienceData.items.length && (categories.length && !rawCustomCategoryData.items.length)) ||
        (!rawOtherTaxonomy.items.length && rawCustomAudienceData.items.length && (categories.length && !rawCustomCategoryData.items.length)) ||
        (!rawOtherTaxonomy.items.length && !rawCustomAudienceData.items.length && !rawCustomCategoryData.items.length)) {
      console.error('Page Not Found', this.currentPagePath);
      return {
        error: 'not found'
      };
    }

    // Audience and Category Articles
    this.processTaxonomyArtices(rawTaxonomyArticles);

    this.catalog = this.processCatalog();

    const flag = this.catalog.find(item => item.category === this.category && item.audience === this.audience);

    if ( rootTerm in this.otherTaxonomiesMap ) {
      if ( rawOtherTaxonomy.items.length ) {
        return await this.processOtherTaxonomyData(rawOtherTaxonomy, rawOtherTaxonomyArticles);
      }
      else {
        return this.buildDynamicOtherTaxonomyData({
          items: [...dynamicComponents.items, ...rawDefaultComponents.items],
          modular_content: {...dynamicComponents.modular_content, ...rawDefaultComponents.modular_content}
        }, rawOtherTaxonomy);
      }
    }

    // Use audience or category, whichever is present
    const rawCustomTaxonomyData = rawCustomCategoryData.items.length ? rawCustomCategoryData : rawCustomAudienceData;

    // If Taxonomy Custom Layout (Audience/Category)
    if (flag && `/${path}` === flag.url && rawCustomTaxonomyData.items.length ) {
      return await this.processCustomTaxonomyData(rawCustomTaxonomyData, otherAudiencesFeedData);
    }

    // Use dynamic layout
    return await this.processCategoryData(
      dynamicComponents,
      otherAudiencesFeedData
    );
  }

  processRouteDetails() {
    const { audience, categories }: any = this.getRouteDetails();

    // Save for further processing
    this.categories = categories;
    this.audience = audience;
  }

  getDefaultComponents() {
    const componentRequests = [
      // Newsletter Signup
      this.kontentDeliveryService.getItem(null, {
        'system.type': this.componentService.componentTypes.newsletter_signup_block,
        limit: 1,
      }),
      // Call to donate
      this.kontentDeliveryService.getItem(null, {
        'system.type': this.componentService.componentTypes.call_to_donate,
        limit: 1,
      })
    ];

    return Promise.all(componentRequests)
      .then(responses => responses.reduce((all, response) => {
        return {
          items: [...all.items, ...response.items],
          modular_content: {...all.modular_content, ...response.modular_content},
        }
      }, { items: [], modular_content: {}}));
  }

  getDynamicComponents() {
    const componentTypes = this.getPageComponentTypes();

    const APIParams = {
      'system.type[in]': componentTypes.join(','),
      depth: 4,
    };

    if ( this.otherTaxonomy ) {
      APIParams['elements.other[all]'] = this.otherTaxonomy;
    }
    else if ( this.audience || this.categories.length )  {
      if ( this.audience ) {
        APIParams['elements.audiences[all]'] = this.audience;
      }
      if ( this.categories.length ) {
        APIParams['elements.categories[all]'] = this.categories.join(',');
      }
    }

    // Fetch  API Data
    return this.kontentDeliveryService.getItem(null, APIParams)
  }

  getCuratedArticles(articleCodenames) {
    const params = {
      'system.type': 'ncoa_article_content',
      'system.codename[in]': articleCodenames.join(','),
    };

    return this.kontentDeliveryService.getItem(null, params);
  }

  // Get articles tagged with given taxonomies
  // @param {number} count - Default: 30
  async getTaxonomyArticles(count: number = 30, excludeCodenames = []) {
    const category = this.category;
    const categories = this.categories.map(category => category.replace(/-/g, '_'));
    const audience = this.audience;
    const otherTaxonomy = this.otherTaxonomy;
    const articleRequests = [];

    // Primary articles
    const primaryArticlesParams = {
      'system.type': 'ncoa_article_content',
      'system.codename[nin]': excludeCodenames.join(','),
      limit: count,
    };

    // Secondary articles
    const secondaryArticlesParams = {
      'system.type': 'ncoa_article_content',
      'system.codename[nin]': excludeCodenames.join(','),
      limit: count,
    };

    // Build for other taxonomy only
    if ( otherTaxonomy ) {
      primaryArticlesParams['elements.other[any]'] = otherTaxonomy;
    }
    // Build for audience and/or categories
    else if ( categories.length || audience ) {
      if ( audience ) {
        primaryArticlesParams['elements.audiences[any]'] = audience;
        secondaryArticlesParams['elements.secondary_audiences[any]'] = audience;
      }
      if ( categories.length ) {
        primaryArticlesParams['elements.categories[any]'] = category;
        secondaryArticlesParams['elements.secondary_categories[any]'] = category;
      }

      // Add secondary articles request
      articleRequests.push(this.kontentDeliveryService.getFeed(null, secondaryArticlesParams));
    }

    // Prepend primary articles request
    articleRequests.unshift(this.kontentDeliveryService.getFeed(null, primaryArticlesParams));

    // Merge all articles
    return Promise.all(articleRequests)
    .then(([ primaryArticlesRaw, secondaryArticlesRaw ]) => ({
      items: [ ...(primaryArticlesRaw?.items || []), ...(secondaryArticlesRaw?.items || []) ],
      modular_content: { ...(primaryArticlesRaw?.modular_content || {}), ...(secondaryArticlesRaw?.modular_content || {}) }
    }));
  }

  // Get articles tagged with given "Other Category"
  async getOtherTaxonomyArticles() {
    const apiParams = {
      'system.type': 'ncoa_article_content',
      'order': 'elements.display_date[desc]',
      'limit': 30,
      'elements.other[contains]': this.otherTaxonomy,
      depth: 4
    };

    return await this.kontentDeliveryService.getItem(null, apiParams);
  }

  getOtherAudiences() {
    if ( !this.categories.length ) {
      return null;
    }

    const componentTypes = this.getPageComponentTypes();
    const [ currentTopic ] = this.categories.slice(-1);
    const currentAudience = this.audience;
    const otherAudiences = Object.keys(this.audiencesMap).filter(audience => audience !== this.audience);

    // Individual request for each audience since one-off request
    // doesn't get the rest the other audiences all the time
    const apiRequests = [];

    // request for primary taxonomy
    apiRequests.push(...otherAudiences.map(audience => this.kontentDeliveryService.getFeed(null, {
      'system.type': this.articleCodename,
      'elements.categories[any]': currentTopic.replace(/-/g, '_'),
      'elements.audiences[any]': audience,
      'elements': 'audiences,secondary_audiences,display_date',
      limit: 1,
    })));

    // request for primary taxonomy
    apiRequests.push(...otherAudiences.map(audience => this.kontentDeliveryService.getFeed(null, {
      'system.type': this.articleCodename,
      'elements.secondary_categories[any]': currentTopic.replace(/-/g, '_'),
      'elements.secondary_audiences[any]': audience,
      'elements': 'audiences,secondary_audiences,display_date',
      limit: 1,
    })));

    return Promise.all(apiRequests)
    // Merge individual responses as one
    .then(responses => responses.reduce((all: any, response) => {
      return {
        items: [...all.items, ...response.items],
        modular_content: {...all.modular_content, ...response.modular_content},
      }
    }, { items: [], modular_content: {}}));
  }

  getRouteDetails() {
    const [ audience, ...categories ] = this.currentPagePath.slice(1).split('/');

    // Replace dashes with underscores in order to match codenames
    return {
      audience: audience.replace(/-/g, '_'),
      categories: categories.map(category => category.replace(/-/g, '_'))
    };
  }

  getPageComponentTypes() {
    const { componentTypes }: any = this.componentService;

    return [
      componentTypes.category_page_hero,
      // componentTypes.content_package,
      componentTypes.category_pathing,
      componentTypes.topic_different_audience,
      // load_more
    ].map(component => this.componentService.componentTypes[component]);
  }

  async processOtherTaxonomyData(rawAPIData, rawOtherTaxonomyArticles = {}) {
    if ( !rawAPIData.items.length ) {
      return null;
    }

    const components = [];
    const { componentTypes } :any = this.componentService;
    const [ rawOtherTaxonomyData ] = rawAPIData.items;
    const {
      other_taxonomy,
      description,
      image,
      components: rawComponents,
    }: any = this.kontentDeliveryService.extractItemElements(rawOtherTaxonomyData);

    // Build hero data
    components.push({
      componentType: this.componentService.componentTypes.category_page_hero,
      category: {
        headline: other_taxonomy[0].name,
        description: description,
        photo: image.length ? {
          responsive: this.globalService.remodelImageSrc(image[0].url, 'categoryHero'),
          url: image[0].url,
          alt: image[0].description
        }: {},
        caption: image.length ? image[0].description : '',
        breadcrumbs: [],
      }
    });

    // Build special pathing component if any
    const specialPathingData = this.extractSpecialPathing(rawAPIData);
    if ( specialPathingData && specialPathingData.length ) {
      components.push({
        componentType: 'specialPathing',
        paths: specialPathingData
      });
    }

    // Build other components
    const additionalComponents = await this.extractOtherTaxonomyComponents({
      item: rawOtherTaxonomyData,
      modular_content: rawAPIData.modular_content
    }, rawOtherTaxonomyArticles);
    components.push(...additionalComponents);

    // Process SEO data
    this.seoService.extractAndRender(rawOtherTaxonomyData, {
      title: other_taxonomy[0].name,
      description,
      image
    });

    return { components };
  }

  async buildDynamicOtherTaxonomyData(dynamicComponents, rawAPIData) {
    const components = [];
    const { componentTypes } :any = this.componentService;
    const [ rawOtherTaxonomyData ] = rawAPIData.items;

    // Build hero data
    components.push({
      componentType: this.componentService.componentTypes.category_page_hero,
      category: {
        headline: this.currentOtherTaxonomyData.name,
        breadcrumbs: [],
      }
    });

    // Build content packages
    if ( this.taxonomyArticles?.length ) {
      for (let i = 0; i < 9; i = ++i * 6 ) {
        if ( this.taxonomyArticles[i] ) {
          const limit = i === 0 ? 6 : 4;
          const rawArticles = this.taxonomyArticles.slice(i, i + limit);
          components.push({
            componentType: componentTypes.content_package,
            articles: this.componentService.processContentPackageComponentArticles(rawArticles)
          });
        }
      }
    }

    // Build other components
    if ( dynamicComponents.items.length ) {
      const additionalComponents = this.componentService.processRawComponents(dynamicComponents.items, dynamicComponents.modular_content)
      components.push(...additionalComponents);
    }

    // Build load more articles
    if ( this.taxonomyArticles?.length > 10 ) {
      components.push(this.buildMoreStoriesData());
    }

    const orderedComponents = this.orderProcessedComponents([
      ...components,
    ]);

    // Process SEO data
    this.seoService.extractAndRender(rawOtherTaxonomyData, {
      title: this.currentOtherTaxonomyData.name,
      description: this.currentOtherTaxonomyData.name
    });

    return { components: orderedComponents };
  }

  async processCustomTaxonomyData(rawAPIData, otherAudiencesFeedData) {
    if ( !rawAPIData.items.length ) {
      return null;
    }

    const { componentTypes } :any = this.componentService;
    const [ rawCustomTaxonomyData ] = rawAPIData.items;
    const {
      special_pathing,
      taxonomy_description,
      header_image,
      audiences,
      categories,
      featured_articles,
      additional_modules
    }: any = this.kontentDeliveryService.extractItemElements(rawCustomTaxonomyData);

    const components = [];

    // Build hero data
    const heroData = this.buildDHeroData({
      description: taxonomy_description,
      photo: header_image.length ? {
        responsive: this.globalService.remodelImageSrc(header_image[0].url, 'categoryHero'),
        url: header_image[0].url,
        alt: header_image[0].description
      }: {},
      caption: header_image.length ? header_image[0].description : ''
    });
    components.push(heroData);

    // Build special pathing component if any
    const specialPathingData = this.extractSpecialPathing(rawAPIData);
    if ( specialPathingData && specialPathingData.length ) {
      components.push({
        componentType: 'specialPathing',
        paths: specialPathingData
      });
    }

    // If there are featured_articles, build out as a curated Content Package
    // Else, get from previously fetched taxonomyArticles
    const featuredArticlesCodenames = featured_articles.length ? featured_articles : this.taxonomyArticles.slice(0, 6).map(article => article.system.codename);
    const rawFeaturedArticles = featuredArticlesCodenames.map(articleCodename => (
      rawAPIData.modular_content[articleCodename] || this.taxonomyArticles.find(rawArticle => rawArticle.system.codename === articleCodename)
    ));

    // Saved used articles
    this.usedArticles.push(...featuredArticlesCodenames);

    components.push({
      componentType: componentTypes.content_package,
      articles: this.componentService.processContentPackageComponentArticles(rawFeaturedArticles, false)
    });

    // Get other components data
    const additionalModulesData = this.kontentDeliveryService.extractModularContent(rawAPIData, additional_modules);

    // Update each module if overrides are needed
    additionalModulesData.forEach(moduleData => {
      // Override component name for cross_pathing_module,
      // in order to use topic_different_audience module
      if ( moduleData.system.type === componentTypes.cross_pathing_module ) {
        moduleData.system.type = componentTypes.topic_different_audience;
      }
    });

    // Dynamic content packages
    const usedArticles = await this.processCustomTaxonomDynamicContentPackages(additionalModulesData, rawAPIData, featuredArticlesCodenames);
    this.usedArticles.push(...usedArticles);

    const additionalComponents = this.componentService.processRawComponents(additionalModulesData, rawAPIData.modular_content);

    components.push(...additionalComponents);

    // Append category pathing data
    const categoryPathingData = await this.buildCategoryPathingData();
    if ( categoryPathingData && categoryPathingData.categories.length > 0 ) {
      components.push(categoryPathingData);
    }

    // Add topic different audiences
    const sameTopicDiffAudience = await this.buildTopicOtherAudiencesFeedDataFromFeed(otherAudiencesFeedData);

    if (sameTopicDiffAudience && sameTopicDiffAudience.topic.links.length > 0) {
      components.push(sameTopicDiffAudience);
    }

    // Add more stories,
    // Pick 4 to determine load more, but use only 3
    let moreArticles = this.taxonomyArticles.filter((article) => !this.usedArticles.includes(article.system.codename));
        moreArticles = moreArticles.map((article) => article.system.codename);
        moreArticles = moreArticles.filter((v, i, a) => a.findIndex(t => (t === v)) === i);

    if ( moreArticles.length ) {
      const moreArticlesData = this.taxonomyArticles.filter(article => moreArticles.includes(article.system.codename));
      const moreStoriesData = this.buildMoreStoriesData(moreArticlesData);
      components.push(moreStoriesData);
    }

    // Process SEO data
    this.applySeoData(components, rawAPIData);

    // Push analytics data
    this.dataLayerService.push({
      url: location.href,
      pageTitle: this.getPageTitle(),
      contentType: 'taxonomy',
      category: this.category,
      audience: this.audience,
    });

    return {
      title: this.getPageTitle(),
      components: components
    };
  }

  async processCustomTaxonomDynamicContentPackages(rawComponentItems, rawAPIData, excludeArticles = []) {
    const contentPackages = rawComponentItems.filter(rawComponent => (
      // Dynamic content package
      (rawComponent.system.type === this.componentService.componentTypes.content_package
            && rawComponent.elements.content_population_method.value[0].codename === 'dynamically')
      // Or secondary content package
      || rawComponent.system.type === this.componentService.componentTypes.content_package_secondary
    ));

    const usedArticles = [];
    const taxonomyArticles = Object.values(this.taxonomyArticles).filter(rawArticle => !excludeArticles.includes(rawArticle.system.codename));

    // Using the fetched taxonomy articles, populated dynamic content packages
    contentPackages.every((rawContentPackage, index) => {
      const maxArticles = rawContentPackage.system.type === this.componentService.componentTypes.content_package_secondary ? 4 : 7;
      const {
        content
      }: any = this.kontentDeliveryService.extractItemElements(rawContentPackage);

      // Discard manual contents if any;
      if ( !content ) {
        // Secondary content package has no content element
        rawContentPackage.elements.content = {
          value: []
        };
      }
      rawContentPackage.elements.content.value = [];

      // Extract articles for this content package
      taxonomyArticles.slice(usedArticles.length, usedArticles.length + maxArticles).forEach(rawArticle => {
        // Append article
        rawContentPackage.elements.content.value.push(rawArticle.system.codename);
        // Add into modular_content
        rawAPIData.modular_content[rawArticle.system.codename] = rawArticle;
        // Add into usedArticles to prevent duplicates
        usedArticles.push(rawArticle.system.codename);
      });

      // Check if we still have remaining articles for next iteration
      if ( usedArticles.length < taxonomyArticles.length ) {
        return true;
      }
      // Else, break the loop
      else {
        return false;
      }
    });

    return usedArticles;
  }

  processTaxonomyArtices(rawArticlesData) {
    this.relatedTaxonomies = this.extractTaxonomyData(rawArticlesData);
    this.taxonomyArticles = rawArticlesData.items;
    this.taxonomyArticlesModularContent = rawArticlesData.modular_content;
  }

  async processCategoryData(rawDynamicComponents, rawOtherAudienceData) {
    // Verify if there is taxonomy data
    if ( !this.currentAudienceData && !this.currentCategoryData ) {
      this.errorService.notFound();
    }

    const { componentTypes }: any = this.componentService;

    // Process dynamic components first
    const rawComponents = rawDynamicComponents?.items || [];
    const dynamicComponents = rawComponents.map(component => this.processComponent(component));

    // Build out hero if not present
    if ( !dynamicComponents.length || dynamicComponents[0].componentType !== componentTypes.category_page_hero ) {
      dynamicComponents.unshift(this.buildDHeroData());
    }

    // Build out content packages
    dynamicComponents.push(...this.buildDynamicCategoryContentPackages());

    // Append category pathing data
    const categoryPathingData = await this.buildCategoryPathingData();
    if ( categoryPathingData && categoryPathingData.categories.length > 0 ) {
      dynamicComponents.push(categoryPathingData);
    }

    // Append more articles data
    const moreStoriesData = this.buildMoreStoriesData();
    if ( moreStoriesData ) {
      dynamicComponents.push(moreStoriesData);
    }

    const orderedComponents = this.orderProcessedComponents([
      ...dynamicComponents,
    ]);

    this.applySeoData(orderedComponents, null);

    // Push analytics data
    this.dataLayerService.push({
      url: location.href,
      pageTitle: this.getPageTitle(),
      contentType: 'taxonomy',
      category: this.currentCategoryData?.codename || '',
      audience: this.currentAudienceData?.codename || ''
    });

    return {
      title: this.getPageTitle(),
      components: orderedComponents
    };
  }

  // Extracts taxonomy data from the articles
  extractTaxonomyData(rawAPIData) {
    const audiencesMap = {};
    const categoriesMap = {};

    if ( !rawAPIData.items.length ) {
      return {
        audiences: [],
        categories: [],
      };
    };

    // Get raw articles taxonomies
    rawAPIData.items.forEach(rawArticle => {
      const {
        audiences,
        categories,
        audiences_fe91f6c = [],
        categories_84f9855 = [],
      }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.items[0], [
        'audiences', 'categories', 'audiences_fe91f6c', 'categories_84f9855'
      ]);

      // Add audience
      [ ...audiences, ...audiences_fe91f6c].forEach(audience => {
        if ( !(audience.codename in audiencesMap) ) {
          audiencesMap[audience.codename] = audience;
        }
      });

      // Add category
      [ ...categories, ...categories_84f9855].forEach(category => {
        if ( !(category.codename in categoriesMap) ) {
          categoriesMap[category.codename] = category;
        }
      });
    });

    return {
      audiences: Object.values(audiencesMap),
      categories: Object.values(categoriesMap)
    }
  }

  async extractOtherTaxonomyComponents(rawAPIData, rawOtherTaxonomyArticlesAPIData) {
  const { components, featured_articles }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.item);
    const rawComponentItems = this.kontentDeliveryService.extractModularContent(rawAPIData, components) || [];
    const rawArticles = rawOtherTaxonomyArticlesAPIData.items;

    // Extract manual content packages and get list down curated articles's codenames
    const curatedContentPackages = rawComponentItems.filter(rawComponent => (
      /content_package/i.test(rawComponent.system.type)
      && /manual/i.test(rawComponent.elements.content_population_method?.value[0]?.codename || '')
    ));

    // Get a list of curated article codenames.
    // These articles will be excluded in the dynamic content package requests
    const curatedArticleCodenames = curatedContentPackages.reduce((articleCodenames, rawContentPackage) => (
      [ ...articleCodenames, ...rawContentPackage.elements.content.value ]
    ), []);

    // Will store article codenames
    // This should be updated when during population of dynamic content package below
    const usedArticles = [...curatedArticleCodenames];

    // Build out content package from featured articles if any,
    // Otherwise, build a dynamic content package
    const featuredContentPackage = {
      system: { type: this.componentService.componentTypes.content_package },
      elements: {
        content: { value: [] },
        content_population_method: { value: [{ codename: "manually" }] }
      }
    };
    if ( featured_articles.length ) {
      featuredContentPackage.elements.content.value = featured_articles;
    }
    else {
      featuredContentPackage.elements.content_population_method.value[0].codename = 'dynamically';
    }

    // Prepend featured content package
    rawComponentItems.unshift(featuredContentPackage);


    // Get a list of dynamic content packages
    // This can be either:
    // (1) A regular Content Package with dynamic population method;
    // (2) A Secondary Content Package
    const dynamicContentPackages = rawComponentItems.filter(rawComponent => (
      // Match dynamic content package
      (/content_package/i.test(rawComponent.system.type) && /dynamic/i.test(rawComponent.elements.content_population_method?.value[0]?.codename || ''))
      // Match secondary package
      || /secondary.+content_package/i.test(rawComponent.system.type)
    ));

    // Populate dynamic content packages with articles
    // Using the rawArticles
    // We use "every" loop in order to break as soon as all articles are used up
    dynamicContentPackages.every(rawContentPackage => {

      // Determine if we still have articles left
      const remainingArticles = rawArticles.filter(rawArticle => (
        !usedArticles.includes(rawArticle.system.codename)
      ));

      if ( !remainingArticles.length ) {
        // No more articles, stop loop
        return false;
      }

      // Append "content" element, simulating curated content package articles
      // Use up to 6 articles max
      const selectedArticleCodenames = remainingArticles.slice(0, 6).map(rawArticle => rawArticle.system.codename);

      // Append articles into content package
      rawContentPackage.elements.content = { value: selectedArticleCodenames };

      // Necessary in order to be processed by component service
      rawContentPackage.elements.content_population_method = { value: [{ codename: 'dynamically' }] }

      // Update usedArticles array
      usedArticles.push(...selectedArticleCodenames);

      // Continue next iteration
      return true;
    });

    const processedComponents = this.componentService.processRawComponents(rawComponentItems, {
      ...rawAPIData.modular_content,
      // Include modular_content from rawOtherTaxonomyArticlesAPIData
      ...rawOtherTaxonomyArticlesAPIData.modular_content,
      ...rawOtherTaxonomyArticlesAPIData.items.reduce((allRawArticles, rawArticle) => ({
        ...allRawArticles,
        [rawArticle.system.codename]: rawArticle
      }), {})
    });

    // If we still have remaining articles, build out load more component
    const remainingArticles = rawArticles.filter(rawArticle => (
      !usedArticles.includes(rawArticle.system.codename)
    ));

    if ( remainingArticles.length ) {
      processedComponents.push(this.buildMoreStoriesData(remainingArticles));
    }

    return processedComponents;
  }

  getPageTitle() {
    const audience = this.currentAudienceData;
    const category = this.currentCategoryData;

    return category ? `${category.name} for ${audience.name}` : audience.name;
  }

  processComponent(component) {
    switch (this.componentService.extractComponentItemType(component)) {
      case this.componentService.componentTypes.category_page_hero:
        const categoryHero = this.componentService.processCategoryPageHeroComponent(component);
        return {
          componentType: categoryHero.componentType,
          category: {
            ...categoryHero.category,
            ...this.getAdditionalHeroData()
          }
        }

      default:
        return this.componentService.processRawComponents([ component ])[0];
    }
  }

  /**
   * Builds headline and breadcrumbs for hero
   */
  getAdditionalHeroData() {
    const audience = this.audiencesMap[this.audience];
    const flag = this.catalog.find(item => item.category === this.category && item.audience === this.audience);
    let headline = '';

    if ( flag?.name ) {
      headline += `<span>${flag.name}</span>`;
    }

    if ( audience?.name ) {
      headline += `${flag?.name ? ' for ' : ''} ${audience.name}`;
    }

    let breadcrumbs = [];
    this.categories.forEach(category => {
      const flag = this.catalog.find(item => item.category === category.replace(/-/g, '_') && item.audience === this.audience);
      if (flag) {
        breadcrumbs.push({
          text: flag.name,
          url: flag.url
        });
      }
    });

    return {
      headline,
      breadcrumbs,
      caption: 'Category Page Hero Image'
    };
  }

  orderProcessedComponents(processedComponents) {
    const { componentTypes }: any = this.componentService;
    const componentsOrder = [
      componentTypes.category_page_hero,
      componentTypes.content_package,
      componentTypes.featured_tool_breaker,
      componentTypes.category_pathing,
      componentTypes.featured_page_block,
      componentTypes.content_package,
      componentTypes.call_to_donate,
      componentTypes.newsletter_signup_block,
      componentTypes.topic_different_audience,
      componentTypes.category_load_more
    ];

    // Should contain components by type
    const mappedComponents = {
      [componentTypes.content_package]: []
    };

    processedComponents.forEach(component => {
      if ( component.componentType === componentTypes.content_package) {
        mappedComponents[componentTypes.content_package].push(component);
      }
      else {
        mappedComponents[component.componentType] = component;
      }
    });

    const orderedComponents = [];
    componentsOrder.forEach(componentType => {
      if ( componentType in mappedComponents && mappedComponents[componentType] ) {
        // mappedComponents.content_package is an array
        if ( componentType === componentTypes.content_package ) {
          if ( mappedComponents[componentType].length ) {
            orderedComponents.push(mappedComponents[componentType].shift());
          }
        }
        else {
          orderedComponents.push(mappedComponents[componentType]);
        }
      }
    });

    return orderedComponents;
  }

  async buildCategoryPathingData() {
    const currentURL = this.currentPagePath;
    const counter = currentURL.split('/').length;
    const matcher = new RegExp(`${currentURL.substring(1)}`, 'g');

    const allCategories = this.catalog.filter(item => matcher.test(item.url) && (item.url.split('/').length - 1) === counter);

    // Create a request for each category
    const categoriesRequest = this.createRequest(allCategories);

    // Get the list of requested pages
    const lists = await Promise.all(categoriesRequest);

    // Filter all the published pages
    const categories = lists.map((category: any, index) => {
      if (!category.items.length) { return; }

      return {
        url: allCategories[index].url,
        title: allCategories[index].name,
      }
    }).filter(x => x);

    const currentRef = this.catalog.find(item => item.audience === this.audience && item.category === this.category);
    let categoryName = '';
    if (currentRef?.category.trim().length > 0) {
      categoryName = `${currentRef.name} for ${currentRef.audienceName}`;
    }

    return {
      componentType: this.componentService.componentTypes.category_pathing,
      page: 'category',
      categoryName,
      categories
    };
  }

  async buildTopicOtherAudiencesFeedDataFromFeed(otherAudiencesFeedData) {
    if ( !otherAudiencesFeedData ) {
      return null;
    }

    const otherAudiences = [];

    // Extract other audiences
    otherAudiencesFeedData.items.forEach((rawArticle: any) => {
      const { audiences, secondary_audiences }: any = this.kontentDeliveryService.extractItemElements(rawArticle);

      [ ...audiences, ...secondary_audiences ].forEach(audience => {
        if ( audiences?.length &&
             this.audience !== audience.codename &&
             !otherAudiences.includes(audience.codename)
        ) {
          otherAudiences.push(audience.codename);
        }
      });
    });

    const allLinks = this.catalog.filter(item => item.category === this.category && otherAudiences.includes(item.audience));

    // Create a request for each link
    const linksRequest = this.createRequest(allLinks);

    // Get the list of requested pages
    const lists = await Promise.all(linksRequest);

    // Filter all the published pages
    const links = lists.map((category: any, index) => {
      if (!category.items.length) { return; }

      return {
        url: allLinks[index].url,
        text: allLinks[index].audienceName,
      }
    }).filter(x => x);

    const currentRef = this.catalog.find(item => item.audience === this.audience && item.category === this.category);

    return {
      componentType: 'topic_different_audience',
      theme: 'dark',
      topic: {
        heading: currentRef.name,
        links,
      },
    };
  }

  buildMoreStoriesData(remainingArticles: any[] = this.taxonomyArticles.slice(9)) {
    if ( !remainingArticles.length ) {
      return null
    }

    let entries = remainingArticles.map(rawArticle => {
      const {
        title,
        display_date,
        url,
        primary_image
      }: any = this.kontentDeliveryService.extractItemElements(rawArticle, [
        'title', 'display_date', 'url', 'primary_image'
      ]);

      return {
        image: primary_image.length ? {
          responsive: this.globalService.remodelImageSrc(primary_image[0].url, 'moreStories'),
          url: primary_image[0].url,
          alt: primary_image[0].description
        } : {
          url: '../../../assets/images/content-package-coral-mobile.png',
          alt: title,
        },
        eyebrow: !!display_date ? dayjs.utc(display_date).format('MMM DD, YYYY') : '',
        title,
        url: this.urlService.buildArticleURL(url),
      };
    });

    entries = entries.filter((v, i, a) => a.findIndex(t => t.url === v.url) === i);

    return {
      componentType: this.componentService.componentTypes.category_load_more,
      entries
    }
  }

  applySeoData(components, rawAPIData) {
    const seoData: any = {
      title: rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__meta_title.value.trim().length > 0
        ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__meta_title.value
        : this.getPageTitle(),
    };

    const heroData: any = components.find(component => component.componentType === this.componentService.componentTypes.category_page_hero );

    if ( heroData ) {
      seoData.description = rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__meta_description.value.trim().length > 0
        ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__meta_description.value
        : heroData.category.description || this.getPageTitle();
      seoData.image = heroData.category.photo;
    }

    seoData.ogTitle = rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value.trim().length > 0
      ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value
      : seoData.title;
    seoData.ogDescription = rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value.trim().length > 0
      ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value
      : seoData.description;
    seoData.ogImage = rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value.length > 0
      ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value[0].url
      : seoData.image;

    seoData.twitterTitle = rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value.trim().length > 0
      ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value
      : seoData.title;
    seoData.twitterDescription = rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value.trim().length > 0
      ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value
      : seoData.description;
    seoData.twitterImage = rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value.length > 0
      ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value[0].url
      : seoData.image;

    this.seoService.applyMetaData(seoData);
  }

  buildDHeroData(overrides = {}) {
    return {
      componentType: this.componentService.componentTypes.category_page_hero,
      category: {
        ...this.getAdditionalHeroData(),
        ...overrides
      }
    };
  }

  getTaxonomies() {
    return Promise.all([
      this.getAudiences(),
      this.getCategories(),
      this.getOthersTaxonomy(),
    ])
    .then(([ rawAudiences, rawCategories, rawOtherTaxonomy ]) => {
      // Process and save audiences
      this.processAudiencesMap(rawAudiences);

      // Process and save categories for easier mapping
      this.processCategoriesMap(rawCategories);

      // Process and save others taxonomy for easier mapping
      this.processOtherTaxonomiesMap(rawOtherTaxonomy);

      // Save data for current page's category
      if ( this.categories ) {
        this.currentCategoryData = this.categoriesMap[this.categories.slice(-1)[0]];
      }

      // Save data for current page's audience or other taxonomy
      if ( this.audience && this.audience in this.audiencesMap ) {
        this.currentAudienceData = this.audiencesMap[this.audience];
      }
      else if ( this.audience in this.otherTaxonomiesMap ) {
        this.otherTaxonomy = this.audience;
        this.currentOtherTaxonomyData = this.otherTaxonomiesMap[this.audience];
        this.audience = null;
      }
    });
  }

  getAudiences() {
    return this.kontentDeliveryService.getTaxonomies('audiences');
  }

  getCategories() {
    return this.kontentDeliveryService.getTaxonomies('categories');
  }

  getOthersTaxonomy() {
    return this.kontentDeliveryService.getTaxonomies('other');
  }

  processCategoriesMap(rawAPIData) {
    this.categoriesTree = rawAPIData.terms;
    this.categoriesMap = this.buildCategoriesMap(rawAPIData.terms);
  }

  processOtherTaxonomiesMap(rawAPIData) {
    this.otherTaxonomiesMap = (rawAPIData?.terms || []).reduce((allOtherTaxonomies, term) => {
      allOtherTaxonomies[term.codename] = term;
      return allOtherTaxonomies;
    }, {});
  }

  processAudiencesMap(rawAPIData) {
    this.audiencesMap = (rawAPIData?.terms || []).reduce((allAudiences, audience) => {
      allAudiences[audience.codename] = audience;
      return allAudiences;
    }, {});
  }

  buildCategoriesMap(terms, parentPath = []) {
    let flattenedTerms = {};

    terms.forEach(term => {
      flattenedTerms[term.codename] = {
        ...term,
        parent: parentPath
      };

      if ( term.terms.length ) {
        flattenedTerms = {
          ...flattenedTerms,
          ...this.buildCategoriesMap(term.terms, [...parentPath, term])
        }
      }
    });

    return flattenedTerms;
  }

  buildDynamicCategoryContentPackages(firstSet = null) {
    const contentPackages = [];

    // Build first set (1-6)
    const firstArticlesSet = firstSet && firstSet.length ? firstSet : this.taxonomyArticles.slice(0, 6);
    const firstArticleCodenames = firstArticlesSet.map(article => article.system.codename);

    if ( firstArticlesSet.length) {
      contentPackages.push({
        componentType: this.componentService.componentTypes.content_package,
        articles: this.componentService.processContentPackageComponentArticles(firstArticlesSet)
      });
    }

    // Build second set if any (7 - 10)
    let secondsArticlesSet = [];

    for (var i = 0; secondsArticlesSet.length < 4 && this.taxonomyArticles[i] ; ++i) {
      const currentArticle = this.taxonomyArticles[i];

      if ( currentArticle && !firstArticleCodenames.includes(currentArticle.system.codename) ) {
        secondsArticlesSet.push(currentArticle);
      }
    }

    // Set rest of articles to localStorage for access
    // by Secondary Content Pacakge
    // localStorage.setItem('secondary_cp_items', JSON.stringify(secondsArticlesSet));

    if ( secondsArticlesSet.length ) {
      contentPackages.push({
        componentType: this.componentService.componentTypes.content_package,
        articles: this.componentService.processContentPackageComponentArticles(secondsArticlesSet)
      });
    }

    return contentPackages;
  }

  processCatalog(): any {
    const catalog = [];

    const makeAKebab = (raw: string) => {
      return raw.replace(/([a-z])([A-Z])/g, '$1').replace(/[\s_]+/g, '-').toLowerCase();
    };

    const loopThroughTerms = (terms, parentCodeName) => {
      terms.forEach(term => {
        const { codename: category, name, terms } = term;
        const categoryURL = `${parentCodeName}/${makeAKebab(category)}`;

        Object.keys(this.audiencesMap).forEach(key => {
          const url = `/${makeAKebab(key)}/${categoryURL}`;

          catalog.push({
            category,
            name,
            audience: key,
            url,
            audienceName: this.audiencesMap[key].name,
          });
        });

        if (terms.length) {
          loopThroughTerms(terms, categoryURL);
        }
      });
    };

    Object.keys(this.audiencesMap).forEach(key => {
      const url = `/${makeAKebab(key)}`;

      catalog.push({
        category: '',
        name: '',
        audienceName: this.audiencesMap[key].name,
        audience: key,
        url,
      });
    })

    this.categoriesTree.forEach(term => {
      let { codename: category, name, terms } = term;
      const categoryURL = `${makeAKebab(category)}`;

      Object.keys(this.audiencesMap).forEach(key => {
        const url = `/${makeAKebab(key)}/${categoryURL}`;

        catalog.push({
          category,
          name,
          audienceName: this.audiencesMap[key].name,
          audience: key,
          url,
        });
      });

      if (terms.length) {
        loopThroughTerms(terms, categoryURL);
      }
    });

    return catalog;
  }

  extractSpecialPathing(rawAPIData) {
    const { modular_content } = rawAPIData;
    const { special_pathing }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.items[0], ['special_pathing']);

    const links = [];
    special_pathing.map(path => {
      const {
        title,
        url
      }: any = this.extractSpecialPathingItem(modular_content[path], modular_content);

      links.push({
        title,
        url: url
      });
    });

    return links;
  }

  extractSpecialPathingItem(item, modular_content: any) {
    const type = item.system.type;
    const {
      link_name, link_url, url, url_string, category_page_url, linked_content, parent_page
    }: any = this.kontentDeliveryService.extractItemElements(item);

    switch(true) {
      case /ncoa_article_content/.test(type): {
        const slug = url;

        return {
          title: item.system.name,
          url: `/article/${slug}`,
        };
      }

      case /standard_page/.test(type): {
        const slug = url;

        if (parent_page.length > 0 && modular_content[parent_page[0]]) {
          const parent = modular_content[parent_page[0]];
          const parentSlug = parent.elements.url.value;

          return {
            title: item.system.name,
            url: `/page/${parentSlug}/${slug}`,
          };
        }

        return {
          title: item.system.name,
          url: `/page/${slug}`,
        };
      }

      case /navigation_item/.test(type):
        return {
          title: link_name,
          url: link_url || this.buildTaxonomyPageURL(modular_content[linked_content[0]])
        };

      case /taxonomy_custom_content/.test(type):
        return {
          title: item.system.name,
          url: this.buildTaxonomyPageURL(item)
        };

      case /template___custom_search/.test(type):
        return {
          title: item.elements.page_title.value,
          url: `/${item.elements.page_slug.value}`
        };

      default:
        return {
          title: item.system.name,
          url: link_url || url || url_string
        };
    }
  }

  buildTaxonomyPageURL(itemData) {
    const type = itemData.system.type;

    switch (true) {

      case /taxonomy_custom_content/.test(type):
        const { categories, audiences, category_page_url }: any = this.kontentDeliveryService.extractItemElements(itemData);
        const category = categories[0];
        const audience = audiences[0];

        // Use manual URL if any
        if ( category_page_url ) {
          return category_page_url;
        }

        // Use dynamically built URL
        if ( category.codename in this.categoriesMap && audience.codename in this.audiencesMap ) {
          return '/' + [
            audience.codename.replace(/_/g, '-'),
            ...this.categoriesMap[category.codename].parent.map(categoryParent => (
              categoryParent.codename.replace(/_/g, '-')
            )),
            category.codename.replace(/_/g, '-')
          ].join('/')
        }

        return '';

      case /template___other_taxonomy/.test(type):
        const { other_taxonomy }: any = this.kontentDeliveryService.extractItemElements(itemData);
        return other_taxonomy[0].codename.replace(/_/g, '-');
    }
  }

  customAudienceRequest(audience: string) {
    return this.kontentDeliveryService.getItem(null, {
      'system.type': 'template___audience_page',
      'elements.audiences[contains]': audience,
      depth: 4,
      limit: 1,
    });
  }

  customCategoryRequest(audience: string, category: string) {
    if (!this.category && !this.audience) {
      return Promise.resolve({
        items: [],
        modular_content: {},
      });
    }

    return this.kontentDeliveryService.getItem(null, {
      'system.type': 'taxonomy_custom_content',
      'elements.categories[contains]': category,
      'elements.audiences[contains]': audience,
      depth: 4,
      limit: 1,
    });
  }

  createRequest(links: any[]) {
    // Create a request for each link
    return links.reduce((accumulator, category) => {
      const routes = category.url.split('/').filter(c => c);
      const audience = routes[0].replace(/-/g, '_');
      const ctgry = routes.length > 0 ? routes[(routes.length - 1)].replace(/-/g, '_') : '';
      const request = this.customCategoryRequest(audience, ctgry);
      accumulator.push(request);
      return accumulator;
    }, []);
  }

  // Extracts curated artiles from raw items
  // Checking for featured articles and manual content packages
  extractCuratedArticlesFromRawItems(rawItems, modularContent) {
    const curatedArticleCodenames = [];

    rawItems.forEach(item => {
      const {
        additional_modules, featured_articles, components
      }: any = this.kontentDeliveryService.extractItemElements(item);

      // Check for featured articles
      if ( featured_articles?.length ) {
        curatedArticleCodenames.push(...featured_articles);
      }

      // Loop through components for curated articles
      [...(additional_modules || []), ...(components || [])].forEach(componentCodename => {

        // Check for manual content package only
        if (
          /manual/i.test(modularContent[componentCodename]?.elements.content_population_method?.value[0]?.codename || '')
        ) {
          // Append curated article codenames
          curatedArticleCodenames.push(...modularContent[componentCodename].elements.content.value);
        }
      });
    });

    // Ensure unique article codenames
    return [...(new Set(curatedArticleCodenames))];
  }
}
