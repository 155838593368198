<div class="assessment" *ngIf="data">
  <app-banner  *ngIf="!isLoggedIn" [richTextObject]="bannerText"> </app-banner>
  <div class="assessment__wrapper">
    <a *ngIf="showBackCTA" [title]="backCTA.text" class="back-cta" (click) = 'goBack($event)'>
      <svg viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.86842 1.04722L0.315788 7.75062C0.105263 7.96513 0 8.23326 0 8.5014C0 8.76954 0.105263 9.03767 0.315788 9.25218L6.86842 15.9556C7.28947 16.3846 7.94737 16.3846 8.36842 15.9556C8.78947 15.5266 8.78947 14.8562 8.36842 14.4272L3.57895 9.57395L18.9474 9.57395C19.5263 9.57395 20 9.0913 20 8.5014C20 7.9115 19.5263 7.42886 18.9474 7.42886L3.57895 7.42886L8.34211 2.54878C8.76316 2.11977 8.76316 1.44943 8.34211 1.02014C7.94737 0.617936 7.28947 0.617936 6.86842 1.04722Z" fill="#0B4A5D"/>
      </svg>
      <div><span>{{ backCTA.text }}</span></div>
    </a>
    <a *ngIf="!showBackCTA" class="back-cta"></a>
    <div class="main"  id="main-content" *ngIf="!singlePageExperience">
      <div class="step">
        <div class="step__head">
          <div class="content">
            <h2 class="title">{{ data.title }}</h2>
            <div class="counter">{{ passedQuestions }} of {{ activeQuestionCount }}</div>
          </div>
          <p *ngIf="data.description" class="description">{{ data.description }}</p>
        </div>
        <div class="step__progress">
          <div class="active"></div>
        </div>
        <div class="step__container">
          <div class="step__track">
            <div class="step__slide" [ngClass]='{"prescriptionDrugGroup":question.answerGroup.answerType==="drugSearch"}' *ngFor="let question of data.questions; let questionIndex = index;">
              <div class="slide-wrapper" [ngStyle]="{'visibility': (questionIndex === activeIndex) ? 'visible' : ''}">
                <h3 *ngIf="questionIndex === 0 && data.introduction" class="introduction">{{ data.introduction }}</h3>
                <h4 class="question" tabindex="-1" [id]="'assessmentQuestion'+questionIndex">
                  {{ question.text || question.label }}
                  <strong *ngIf="question.answerGroup.validation?.isRequired">*</strong>
                </h4>
                <p class="desc">{{ question.description }}</p>
                <p class="hint">{{ question.hint || question.placeholder }}</p>
                <ng-container [ngSwitch]="question.answerGroup.answerType">
                  <div class="field" *ngSwitchCase="'boolean'">
                    <div class="error" aria-live="assertive" *ngIf="question.error">{{ question.error }}</div>
                    <div class="radio" *ngFor="let option of question.answerGroup.answers; let optionIndex = index;">
                      <div class="radio-box">
                        <input
                          [(ngModel)]="question.userAnswer"
                          type="radio"
                          [name]="question.id"
                          [value]="option.value"
                          [id]="question.id + optionIndex"
                          (click)="onChangeValidation( $event, questionIndex )"
                        />
                        <div class="active"></div>
                      </div>
                      <label [for]="question.id + optionIndex">{{ option.text }}</label>
                    </div>
                  </div>
                  <div class="field" *ngSwitchCase="'multiSelect'">
                    <div class="error" aria-live="assertive" *ngIf="question.error">{{ question.error }}</div>
                    <div class="multi-select">
                      <div class = 'checkboxParentContainer' tabindex="-1" *ngFor="let option of question.answerGroup.answers; let optionIndex = index;" >
                        <label class="checkbox"[for]="question.id + optionIndex">
                          <div class="box">
                            <input
                              type="checkbox"
                              [name]="question.id"
                              [id]="question.id + optionIndex"
                              [value]="option.value"
                              [(ngModel)]="option.isSelected"
                              [attr.aria-checked]="option.isSelected"
                              (change)="toggleCheck(questionIndex, option)"
                            />
                            <svg *ngIf="option.isSelected" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.09961 13.166C6.45117 13.5176 7.04883 13.5176 7.40039 13.166L17.7363 2.83008C18.0879 2.47852 18.0879 1.88086 17.7363 1.5293L16.4707 0.263672C16.1191 -0.0878906 15.5566 -0.0878906 15.2051 0.263672L6.76758 8.70117L2.79492 4.76367C2.44336 4.41211 1.88086 4.41211 1.5293 4.76367L0.263672 6.0293C-0.0878906 6.38086 -0.0878906 6.97852 0.263672 7.33008L6.09961 13.166Z" fill="#0B4A5D"/>
                            </svg>
                          </div>
                          <span>{{ option.text }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="field" *ngSwitchCase="'select'">
                    <div class="select-container">
                      <select [attr.aria-labelledby]='"assessmentQuestion"+questionIndex' [ngClass]="question.error ? 'error' : ''" [name]="question.id" [id]="question.id" [(ngModel)]="question.userAnswer" (ngModelChange)="onChangeValidation($event, questionIndex)">
                        <option *ngFor="let selectOption of question.answerGroup.answers" [value]="selectOption.value">
                          {{ selectOption.text }}
                        </option>
                      </select>
                      <svg viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57777 6.74545C6.41229 6.91515 6.20543 7 5.99859 7C5.79174 7 5.5849 6.91515 5.41942 6.74545L0.248217 1.46364C-0.0827392 1.12424 -0.0827392 0.593939 0.248217 0.254545C0.579174 -0.0848485 1.0963 -0.0848485 1.42725 0.254545L5.99859 5.09741L10.5906 0.275758C10.9216 -0.063636 11.4387 -0.063636 11.7698 0.275758C12.0801 0.59394 12.0801 1.12424 11.749 1.46364L6.57777 6.74545Z" fill="#0B4A5D"/>
                      </svg>
                    </div>
                    <div class="error" aria-live="assertive" *ngIf="question.error">{{ question.error }}</div>
                  </div>
                  <div class="field" *ngSwitchCase="'shortText'">
                    <input
                      type="text"
                      [name]="question.id"
                      [id]="question.id"
                      [attr.aria-labelledby]='"assessmentQuestion"+questionIndex'
                      [(ngModel)]="question.userAnswer"
                      [ngClass]="question.error ? 'error' : ''"
                      (ngModelChange)="onShortTextChange(questionIndex, question.id, $event)"
                    />
                    <div class="error" aria-live="assertive" *ngIf="question.error">{{ question.error }}</div>
                  </div>
                  <div class="field" *ngSwitchCase="'string'">
                    <input
                      type="text"
                      [name]="question.id"
                      [attr.aria-labelledby]='"assessmentQuestion"+questionIndex'
                      [id]="question.id"
                      [(ngModel)]="question.userAnswer"
                      [ngClass]="question.error ? 'error' : ''"
                      (ngModelChange)="onChangeValidation($event, questionIndex)"
                    />
                    <div class="error" aria-live="assertive" *ngIf="question.error">{{ question.error }}</div>
                  </div>
                  <div class="field" *ngSwitchCase="'number'">
                    <input
                      type="text"
                      [name]="question.id"
                      [id]="question.id"
                      [attr.aria-labelledby]='"assessmentQuestion"+questionIndex'
                      [(ngModel)]="question.userAnswer"
                      [ngClass]="question.error ? 'error' : ''"
                      (ngModelChange)="onChangeValidation($event, questionIndex)"
                    />
                    <div class="error" aria-live="assertive" *ngIf="question.error">{{ question.error }}</div>
                  </div>
                  <div class="field" *ngSwitchCase="'drugSearch'">
                    <div class="field--drug-search" *ngIf="!preSelectedDrug">
                      <div class="input-wrapper">
                        <label [for]="question.id">Drug Name</label>
                        <input
                          type="text"
                          class="drug-input"
                          [attr.aria-labelledby]='"assessmentQuestion"+questionIndex'
                          [name]="question.id"
                          [id]="question.id"
                          [ngClass]="question.error ? 'error' : ''"
                          (input)="onDrugKeyUp($event)"
                        />
                      </div>
                      <div class="drug-list" *ngIf="drugOptions.length > 0">
                        <ul>
                          <li *ngFor="let drugOption of drugOptions">
                            <button (click)="clickDrug(drugOption)" type="button">
                              {{ drugOption.name }} - {{ drugOption.strength }} {{ drugOption.form }}
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div class="selected-drugs" *ngIf="selectedDrugs.length > 0">
                        <span class="selected-drugs__label">Prescriptions Added</span>
                        <ul class="selected-drugs__list">
                          <li *ngFor="let selectedDrug of selectedDrugs; let drugIndex = index;">
                            <span>{{ selectedDrug.name }} - {{ selectedDrug.strength }} {{ selectedDrug.form }}</span>
                            <button type="button" (click)="clearSelectedDrug(drugIndex)">Clear</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div *ngIf="preSelectedDrug">
                      <div class="preselected-drug">
                        <span>{{ preSelectedDrug.name }} - {{ preSelectedDrug.strength }} {{ preSelectedDrug.form }}</span>
                        <button type="button" (click)="clearPreSelectedDrug()">Clear</button>
                      </div>
                      <div class="preselected-drug-add">
                        <div class="subfield">
                          <label for="quantity">Enter Quantity Per Refill</label>
                          <input type="text" name="quantity" id="quantity" [(ngModel)]="drugQuantity" />
                        </div>
                        <div class="subfield">
                          <label for="days">Enter Refill Frequency (Days)</label>
                          <!-- <input type="text" name="days" id="days" [(ngModel)]="drugFrequency" /> -->
                          <div class="select-container">
                            <select [attr.aria-labelledby]='"assessmentQuestion"+questionIndex' [ngClass]="question.error ? 'error' : ''" name="days" id="days" [(ngModel)]="drugFrequency" (ngModelChange)="onChangeValidation($event, questionIndex)">
                              <option [value]="30">
                                Once a month
                              </option>
                              <option [value]="60">
                                Every 2 Months
                              </option>
                              <option [value]="90">
                                Every 3 Months
                              </option>
                              <option [value]="182">
                                Every 6 Months
                              </option>
                              <option [value]="365">
                                Every 12 Months
                              </option>
                            </select>
                            <svg viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57777 6.74545C6.41229 6.91515 6.20543 7 5.99859 7C5.79174 7 5.5849 6.91515 5.41942 6.74545L0.248217 1.46364C-0.0827392 1.12424 -0.0827392 0.593939 0.248217 0.254545C0.579174 -0.0848485 1.0963 -0.0848485 1.42725 0.254545L5.99859 5.09741L10.5906 0.275758C10.9216 -0.063636 11.4387 -0.063636 11.7698 0.275758C12.0801 0.59394 12.0801 1.12424 11.749 1.46364L6.57777 6.74545Z" fill="#0B4A5D"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <button type="button" class="new-drug-btn" (click)="clickAddDrug()">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.98642 0V5.98642H0V8.01357H5.98642V14H7.9819V8.01357H13.9683V5.98642H7.9819V0H5.98642Z" fill="white"/>
                        </svg>
                        Add Prescription
                      </button>
                    </div>
                    <div class="error" aria-live="assertive" *ngIf="question.error">{{ question.error }}</div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="step__footer">
          <button (click)="onPrevious()" *ngIf="this.activeIndex !== 0" type="button" class="btn btn--prev">Previous</button>
          <button (click)="onSubmit()" type="button" class="btn btn--finish">
            Save & Continue
          </button>
        </div>
      </div>
    </div>
    <div class="main"  id="main-content" *ngIf="singlePageExperience">
      <div class="step single-page-step">
        <div class="step__head">
          <div class="content">
            <h2 class="title">{{ data.title }}</h2>
            <div *ngIf="hasSections && data.sections.length > 1" class="counter">{{ passedSections }} of {{ activeSectionCount }}</div>
          </div>
          <h3 *ngIf="data.description" class="description">{{ data.description }}</h3>
          <div *ngIf="showLanguageToogle" class="header-global__nav-desktop_languageToggle">
            <div>
              <select name="language-toggle" (change)="onLanguageToggle($event)">
                <option *ngFor="let option of languageOptions" [value]="option.value" [selected]="option.value === selectedLanguage">{{option.label}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="step__progress" style="margin-bottom: 15px;">
          <div class="active"></div>
        </div>

        <div class="step__slide">
          <h3 *ngIf="data.introduction" class="introduction-text">{{ data.introduction }}</h3>

          <div class="step__container">
            <div class="question-block" *ngFor="let question of data.questions; let questionIndex = index;" [ngClass]="{ 'question-block-answered': question.answered, 'hide-question': !checkShowQuestion(question) }">
              <span class="optional-label" *ngIf="!question.answerGroup?.validation?.isRequired">{{selectedLanguage === 'en' ? 'Optional' : 'Opcional'}}</span>
              <h4 class="question" tabindex="-1" [id]="'assessmentQuestion'+questionIndex">
                {{ question.text || question.label }}
                <strong *ngIf="question.answerGroup.validation?.isRequired">*</strong>
                <span *ngIf="question.hint && assessmentID !== 'medicareBrokerLeadGen'" class="tooltip--wrapper" (click)="openTooltip( $event )">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="9" stroke="#0B4A5D" stroke-width="2"/>
                    <path d="M10.8491 12.1873H8.97768C9.01578 11.5986 9.19197 11.1174 9.50626 10.7436C9.83007 10.3699 10.1634 10.0382 10.5063 9.74849C10.8301 9.46817 11.111 9.18784 11.3491 8.90752C11.5967 8.61785 11.7205 8.27212 11.7205 7.87032C11.7205 7.44049 11.5777 7.10411 11.292 6.86116C11.0063 6.60887 10.6205 6.48272 10.1348 6.48272C9.47768 6.48272 8.97768 6.71165 8.63483 7.16952C8.29197 7.62738 8.15387 8.24876 8.22054 9.03367L6.40625 8.12262C6.41577 7.63672 6.51577 7.19288 6.70625 6.79108C6.89673 6.37994 7.15387 6.02953 7.47768 5.73987C7.81101 5.44085 8.20149 5.21192 8.64911 5.05307C9.10625 4.88488 9.60149 4.80078 10.1348 4.80078C10.6396 4.80078 11.1063 4.87553 11.5348 5.02504C11.9634 5.1652 12.3301 5.3661 12.6348 5.62774C12.9396 5.88937 13.1777 6.20707 13.3491 6.58084C13.5205 6.9546 13.6063 7.36107 13.6063 7.80024C13.6063 8.1927 13.5539 8.5431 13.4491 8.85146C13.3444 9.15981 13.2063 9.43546 13.0348 9.67841C12.8634 9.91201 12.6729 10.1269 12.4634 10.3232C12.2634 10.5194 12.0634 10.7016 11.8634 10.8698C11.6063 11.0847 11.3777 11.2949 11.1777 11.5005C10.9872 11.7061 10.8777 11.935 10.8491 12.1873ZM11.0205 15.2008H8.77768V13.1124H11.0205V15.2008Z" fill="#0B4A5D"/>
                  </svg>
                  <span class="tooltip">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="closeTooltip( $event )">
                      <path d="M14.2446 2.26607C14.6618 1.8489 14.6618 1.17253 14.2446 0.755357C13.8275 0.338185 13.1511 0.338185 12.7339 0.755357L7.5 5.98929L2.26607 0.755357C1.8489 0.338185 1.17253 0.338185 0.755357 0.755357C0.338185 1.17253 0.338185 1.8489 0.755357 2.26607L5.98929 7.5L0.755357 12.7339C0.338185 13.1511 0.338185 13.8275 0.755357 14.2446C1.17253 14.6618 1.8489 14.6618 2.26607 14.2446L7.5 9.01071L12.7339 14.2446C13.1511 14.6618 13.8275 14.6618 14.2446 14.2446C14.6618 13.8275 14.6618 13.1511 14.2446 12.7339L9.01071 7.5L14.2446 2.26607Z" fill="#0B4A5D"/>
                    </svg>
                    <span><p>{{ question.hint }}</p></span>
                  </span>
                </span>
              </h4>

              <p class="desc">{{ question.description }}</p>
              <ng-container [ngSwitch]="question.answerGroup.answerType">
                <div class="field" *ngSwitchCase="'boolean'">
                  <div class="error" aria-live="assertive" *ngIf="question.error">{{ question.error }}</div>
                  <div class="radio single-page-radio-box" *ngFor="let option of question.answerGroup.answers; let optionIndex = index;">
                    <div class="radio-box">
                      <input
                        [(ngModel)]="question.userAnswer"
                        type="radio"
                        [name]="question.id"
                        [value]="option.value"
                        [id]="question.id + optionIndex"
                        (click)="onAnswer(question, option.value)"
                      />
                      <div class="active"></div>
                    </div>
                    <label style="cursor: auto; margin-top: 4px;" [for]="question.id + optionIndex">{{ option.text }}</label>
                  </div>
                </div>
                <div class="field" *ngSwitchCase="'multiSelect'">
                  <div class="error" aria-live="assertive" *ngIf="question.error">{{ question.error }}</div>
                  <div class="multi-select">
                    <div class = 'checkboxParentContainer' tabindex="-1" *ngFor="let option of question.answerGroup.answers; let optionIndex = index;" >
                      <label class="checkbox"[for]="question.id + optionIndex">
                        <div class="box">
                          <input
                            type="checkbox"
                            [name]="question.id"
                            [id]="question.id + optionIndex"
                            [value]="option.value"
                            [(ngModel)]="option.isSelected"
                            [attr.aria-checked]="option.isSelected"
                            (change)="toggleCheck(questionIndex, option)"
                          />
                          <svg *ngIf="option.isSelected" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.09961 13.166C6.45117 13.5176 7.04883 13.5176 7.40039 13.166L17.7363 2.83008C18.0879 2.47852 18.0879 1.88086 17.7363 1.5293L16.4707 0.263672C16.1191 -0.0878906 15.5566 -0.0878906 15.2051 0.263672L6.76758 8.70117L2.79492 4.76367C2.44336 4.41211 1.88086 4.41211 1.5293 4.76367L0.263672 6.0293C-0.0878906 6.38086 -0.0878906 6.97852 0.263672 7.33008L6.09961 13.166Z" fill="#0B4A5D"/>
                          </svg>
                        </div>
                        <span>{{ option.text }}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="field" *ngSwitchCase="'select'">
                  <div class="select-container" style="margin-bottom: 20px;">
                    <select [attr.aria-labelledby]='"assessmentQuestion"+questionIndex' [ngClass]="question.error ? 'error' : ''" [name]="question.id" [id]="question.id" [(ngModel)]="question.userAnswer" (ngModelChange)="onChangeValidation($event, questionIndex)">
                      <option [ngValue]="null" [disabled]="true" class="disabled">{{chooseDefault}}</option>
                      <option *ngFor="let selectOption of question.answerGroup.answers" [ngValue]="toString(selectOption.value)">
                        {{ selectOption.text }}
                      </option>
                    </select>
                    <svg viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57777 6.74545C6.41229 6.91515 6.20543 7 5.99859 7C5.79174 7 5.5849 6.91515 5.41942 6.74545L0.248217 1.46364C-0.0827392 1.12424 -0.0827392 0.593939 0.248217 0.254545C0.579174 -0.0848485 1.0963 -0.0848485 1.42725 0.254545L5.99859 5.09741L10.5906 0.275758C10.9216 -0.063636 11.4387 -0.063636 11.7698 0.275758C12.0801 0.59394 12.0801 1.12424 11.749 1.46364L6.57777 6.74545Z" fill="#0B4A5D"/>
                    </svg>
                  </div>
                  <div class="error" aria-live="assertive" *ngIf="question.error">{{ question.error }}</div>
                </div>
                <div class="field" *ngSwitchCase="'shortText'">
                  <input
                    type="text"
                    [name]="question.id"
                    [id]="question.id"
                    [attr.aria-labelledby]='"assessmentQuestion"+questionIndex'
                    [(ngModel)]="question.userAnswer"
                    [ngClass]="question.error ? 'error' : ''"
                    [placeholder]="question.placeholder || ''"
                    (ngModelChange)="onShortTextChange(questionIndex, question.id, $event)"
                    (blur)="onBlur($event, questionIndex)"
                  />
                  <div class="error" aria-live="assertive" *ngIf="question.error">{{ question.error }}</div>
                </div>
                <div class="field" *ngSwitchCase="'string'">
                  <input
                    type="text"
                    [name]="question.id"
                    [attr.aria-labelledby]='"assessmentQuestion"+questionIndex'
                    [id]="question.id"
                    [(ngModel)]="question.userAnswer"
                    [ngClass]="question.error ? 'error' : ''"
                    (ngModelChange)="onChangeValidation($event, questionIndex)"
                    (blur)="onBlur($event, questionIndex)"
                  />
                  <div class="error" aria-live="assertive" *ngIf="question.error">{{ question.error }}</div>
                </div>
                <div class="field" *ngSwitchCase="'drugSearch'">
                  <div class="field--drug-search" *ngIf="!preSelectedDrug">
                    <div class="input-wrapper">
                      <label [for]="question.id">Drug Name</label>
                      <input
                        type="text"
                        class="drug-input"
                        [attr.aria-labelledby]='"assessmentQuestion"+questionIndex'
                        [name]="question.id"
                        [id]="question.id"
                        [ngClass]="question.error ? 'error' : ''"
                        (input)="onDrugKeyUp($event)"
                      />
                    </div>
                    <div class="drug-list" *ngIf="drugOptions.length > 0">
                      <ul>
                        <li *ngFor="let drugOption of drugOptions">
                          <button (click)="clickDrug(drugOption)" type="button">
                            {{ drugOption.name }} - {{ drugOption.strength }} {{ drugOption.form }}
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div class="selected-drugs" *ngIf="selectedDrugs.length > 0">
                      <span class="selected-drugs__label">Prescriptions Added</span>
                      <ul class="selected-drugs__list">
                        <li *ngFor="let selectedDrug of selectedDrugs; let drugIndex = index;">
                          <span>{{ selectedDrug.name }} - {{ selectedDrug.strength }} {{ selectedDrug.form }}</span>
                          <button type="button" (click)="clearSelectedDrug(drugIndex, question.index)">Clear</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="preSelectedDrug">
                    <div class="preselected-drug">
                      <span>{{ preSelectedDrug.name }} - {{ preSelectedDrug.strength }} {{ preSelectedDrug.form }}</span>
                      <button type="button" (click)="clearPreSelectedDrug()">Clear</button>
                    </div>
                    <div class="preselected-drug-add">
                      <div class="subfield">
                        <label for="quantity">Enter Quantity Per Refill</label>
                        <input type="text" name="quantity" id="quantity" [(ngModel)]="drugQuantity" />
                      </div>
                      <div class="subfield">
                        <label for="days">Enter Refill Frequency (Days)</label>
                        <!-- <input type="text" name="days" id="days" [(ngModel)]="drugFrequency" /> -->
                        <div class="select-container">
                          <select [attr.aria-labelledby]='"assessmentQuestion"+questionIndex' [ngClass]="question.error ? 'error' : ''" name="days" id="days" [(ngModel)]="drugFrequency" (ngModelChange)="onChangeValidation($event, questionIndex)">
                            <option [value]="30">
                              Once a month
                            </option>
                            <option [value]="60">
                              Every 2 Months
                            </option>
                            <option [value]="90">
                              Every 3 Months
                            </option>
                            <option [value]="182">
                              Every 6 Months
                            </option>
                            <option [value]="365">
                              Every 12 Months
                            </option>
                          </select>
                          <svg viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57777 6.74545C6.41229 6.91515 6.20543 7 5.99859 7C5.79174 7 5.5849 6.91515 5.41942 6.74545L0.248217 1.46364C-0.0827392 1.12424 -0.0827392 0.593939 0.248217 0.254545C0.579174 -0.0848485 1.0963 -0.0848485 1.42725 0.254545L5.99859 5.09741L10.5906 0.275758C10.9216 -0.063636 11.4387 -0.063636 11.7698 0.275758C12.0801 0.59394 12.0801 1.12424 11.749 1.46364L6.57777 6.74545Z" fill="#0B4A5D"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <button type="button" class="new-drug-btn" (click)="clickAddDrug(question.index)">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.98642 0V5.98642H0V8.01357H5.98642V14H7.9819V8.01357H13.9683V5.98642H7.9819V0H5.98642Z" fill="white"/>
                      </svg>
                      Add Prescription
                    </button>
                  </div>
                  <div class="error" aria-live="assertive" *ngIf="question.error">{{ question.error }}</div>
                </div>
              </ng-container>
            </div>
            <div class="step__footer">
              <button (click)="onPrevious()" *ngIf="this.activeIndex !== 0" type="button" class="btn btn--prev">{{selectedLanguage === 'en' ? 'Previous' : 'Previo'}}</button>
              <button type="button" class="btn submit-btn btn--finish" [disabled]="!canSubmit" [ngClass]='{"disabled-btn" : !canSubmit}' (click)="submitSinglePageAssessment()">{{selectedLanguage === 'en' ? 'Save & Continue' : 'Guardar & Continuar'}}</button>
            </div>
            <label *ngIf="data.subCopy" class="sub-copy">{{data.subCopy}}</label>
          </div>
        </div>

      </div>
      <div class="step__progress_sep" *ngIf="data.contentCards">
        <div class="active"></div>
      </div>
      <div class="educational-content-section-2" *ngIf="data.contentCards">
        <ul class="accordion">
          <li class="accordion-item" *ngFor="let card of data.contentCards; let cardIndex = index;" (click)="toggleEdCard( $event )">
            <p class="accordion-thumb">{{ card.elements.statement.value }}</p>
            <svg class="exp-arrow" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57777 6.74545C6.41229 6.91515 6.20543 7 5.99859 7C5.79174 7 5.5849 6.91515 5.41942 6.74545L0.248217 1.46364C-0.0827392 1.12424 -0.0827392 0.593939 0.248217 0.254545C0.579174 -0.0848485 1.0963 -0.0848485 1.42725 0.254545L5.99859 5.09741L10.5906 0.275758C10.9216 -0.063636 11.4387 -0.063636 11.7698 0.275758C12.0801 0.59394 12.0801 1.12424 11.749 1.46364L6.57777 6.74545Z" fill="#0B4A5D"/>
            </svg>
            <div class="accordion-panel" [innerHTML]="card.elements.content.value"></div>
          </li>
        </ul>
      </div>
      <div class="faq" *ngIf="data.faqContent">
        <a [href]="'/age-well-planner/pages/' + data.faqContent.url" target="_blank"><button type="button" class="btn faq-link">{{data.faqContent.link}}</button></a>
      </div>

      <awp-partner-grid
        *ngIf="data.partnerGrid"
        [heading]="data.partnerGrid.elements.title.value"
        [images]="data.partnerGrid.elements.images.value"
        [blurb]="data.partnerGrid.elements.content_blurb.value"
      ></awp-partner-grid>

    </div>
  </div>
</div>
