<div [ngClass]="!ctaButton ? 'step-without-cta' : 'step-with-cta'">
    <img class="next-step-icon" [src]="icon.url" *ngIf="icon !== undefined">
    <div class="step-content" [ngClass]="!ctaButton ? 'without-cta' : 'with-cta'" [innerHtml]="content"></div>
        <cta-button 
        *ngIf="ctaButton"
        [label]="ctaButton.label"
        [type]="ctaButton.type"
        [link]="ctaButton.link"
        [configClass]="ctaButton.configClass"
        [contextClass]="contextClass"
        (click)="ctaClick($event)">
            {{ctaButton.label}}
        </cta-button>
</div>