import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ComponentService } from '@services/component.service';
import { StandardService } from '@services/standard.service';
import { ErrorService } from '@services/error.service';
import { GlobalService } from '@services/global.service';
import { RichTextService } from '@services/rich-text-resolver.service';

@Component({
  selector: 'app-standard',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StandardPageComponent implements OnInit, AfterViewInit {

  components: (any)[];
  componentTypes: any;
  partnership: any;
  minimalPage: boolean = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private standardService: StandardService,
    private componentService: ComponentService,
    private router: Router,
    private errorService: ErrorService,
    private globalService: GlobalService,
    private richTextService: RichTextService
  ) {
    this.componentTypes = this.componentService.componentTypes;
  }

  ngOnInit() {
    this.activeRoute.data.subscribe(({ pageData }: any ) => {
      if ( pageData ) {
        this.usePageData(pageData);
      }
      else {
        this.errorService.notFound();
      }
    });
  }

  ngAfterViewInit() {
    // load the footer after initializing the component's view
    this.globalService.loadFooter(!!this.components.length);
  }

  usePageData(data) {
    if ( data ) {
      this.components = [
        {componentType: 'body', ...data.body, styleConfig: data.styleConfig},
        ...data.components
      ];




      const item = data.item;

    if(item?.partnership_eyebrow?.value.length && item?.partnership_logo?.value?.length){

      const [logo] = item.partnership_logo.value;

      const modular = data.rawAPIData.modular_content[logo].elements;

      const [image] = modular.image.value;

        this.partnership = {
          eyebrow: item.partnership_eyebrow.value,
          src: image.url,
          url: modular.link.value,
          alt: image.description
        }
     }



      // Prepend header for non-minimal page
      if ( !data.minimalPage ) {
        if(data.item?.page_description_rich_text?.value !== '<p><br></p>'){
        data.item.description.value = this.richTextService.resolveRichText(data.item.page_description_rich_text)
        }
        this.components.unshift({
          componentType: 'header',
          ...data.item
        });
      }

      if(data.specialPathing && data.specialPathing?.length){
        this.components.splice(1, 0, {
          componentType: 'specialPathing',
          paths: data.specialPathing
        })
      }

      this.minimalPage = data.minimalPage;
    }
  }

}
