
<div *ngIf="isDataLoaded && kontent" class="screening-content">

    <section  *ngIf="!isVerticalScreening"  class="screening-exit">
        <app-back-button [url]="backCTA.url">{{backCTA.text}}</app-back-button>
    </section>

    <section class="screening-form">

        <div *ngIf="!isVerticalScreening" class="screening-header">
            <h1>{{screening.name['en']}}</h1>
        </div>

        <form #form (ngSubmit)="onSubmit()" [formGroup]="screeningForm" *ngIf="screeningForm">

                <app-stepper [showHeader]="!isVerticalScreening" #stepper>
                    <app-step *ngFor="let section of sections | keyvalue; let i = index" [header]="section.key">
                        <section class="screening-section" >

                            <section *ngIf="sectionInvalid" class="screening-section-errors">
                                <h3>{{kontent.section_error}}</h3>
                            </section>

                            <div class="screening-question-group" *ngFor="let questionGroup of section.value.groups | keyvalue: unsorted">
                               

                                <h2 class="screening-question-group-title">{{questionGroup | screeningGroupTitle: $any(screeningForm.get(section.key).get(questionGroup.key)): updateGroupTitles}}</h2>


                                <div class="screening-questions">
                                  <div *ngFor="let question of questionGroup.value" [ngClass]="{'hide': $any(screeningForm.get(section.key).get(questionGroup.key)).get(question.key).disabled}">
                                
                                        <app-question 
                                        [kenticoKontent]="kontent" [question]="question" [isCompleted]="isCompleted"
                                        (answerChangeEvent)="evaluateConditions($any(screeningForm.get(section.key).get(questionGroup.key)));sendQuestionResponses($any(screeningForm.get(section.key)))"
                                        [group]="$any(screeningForm.get(section.key).get(questionGroup.key))"></app-question>
                                  
                                  </div>
                                </div>
                            </div>

                            <div class="screening-section-controls" >

                                <button class="btn btn--prev" type="button" (click)="onGoBack()" *ngIf="i > 0">Back</button>
                                <button class="btn btn--finish" type="button" (click)="onNextSection(section.key)" *ngIf="i < screening.sections.length - 1">Save & Continue</button>
                                <button  class="btn" [ngClass]="{'btn--prev': screening.sections.length === 1, 'btn--finish': screening.sections.length > 1 }"  type="submit" *ngIf="i >= screening.sections.length - 1 && (!isCompleted || editMode )&& !hideSubmit">{{submitText || "Submit"}}</button>
                            </div>

                        </section>

                    </app-step>
                </app-stepper>
        </form>
    </section>
</div>
