import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService } from '../global.service';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RebaDataService {

  answeredQuestions: QuestionResponse[] = [];

  constructor(private global: GlobalService, private userService: UserService, private http: HttpClient) { }

  public evaluateQuestions(answers: {[questionCode: string]: any }){
    return this.userService.makeSignedCall('POST', `screening/client/evaluateQuestions`, {}, '', {answers});
  }

  public getScreeningHistory(isManager?: boolean, clientId: string = null) {
    let params = {}
    if (isManager) {
      params = { isManager: true, clientId }
    }
    return this.userService.makeSignedCall('POST', 'user-screening/getScreeningHistory', {}, '', params);
  }

  public getScreening(screeningCode: string, zip?: string, state?: string, lang?: string, isManager?: boolean, clientId?: string){
    let params:any = {screeningCode, zip, state, version: environment.workflowStep, lang}
    if(isManager){
      params = {...params, isManager:isManager, clientId}
    }
    return this.userService.makeSignedCall('POST', `screening/client/userScreening`, {}, '', params);
  }

  public getScreeningsForUser(isManager?: boolean, clientId: string = null) {
    let params = {}
    if (isManager) {
      params = { isManager: true, clientId }
    }
    return this.userService.makeSignedCall('POST', 'user-screening/getScreeningsForUser', {}, '', params);
  }

  public respondToQuestions(screeningId: string, screeningVersion: any, questionResponses: QuestionResponse[]) {
    if (questionResponses.length === 0) {
      return new Observable(subscriber => subscriber.next('done'));
    }
    this.answeredQuestions = [...this.answeredQuestions, ...questionResponses];
    const requestBody = {
      screening: { id: screeningId, version: screeningVersion },
      questionResponses
    }
    return this.userService.makeSignedCall('POST', 'user-screening/response/question', {}, '', requestBody);
  }

  public respondToScreeningHistory(screeningResults: any, screeningAnswers: any, eligiblePrograms: any, ineligiblePrograms: any) {
    const requestBody = {
      screeningResults,
      screeningAnswers,
      eligiblePrograms,
      ineligiblePrograms
    };
    return this.userService.makeSignedCall('POST', 'user-screening/response/screeningHistory', {}, '', requestBody);
  }



  public respondToScreening(screeningId: string, screeningVersion: any, state: string, fullResults, status?: 'IN_PROGRESS' | 'COMPLETED', type?: string) {
    const requestBody = {
      screening: { screeningId, screeningVersion },
      source: this.getEventSource(),
      fullResults,
      status,
      state,
      organizationId: { id: 'ncoa', name: 'ncoa' },
      lang: 'english',
      type
    };
    return this.userService.makeSignedCall('POST', 'user-screening/response/screening', {}, '', requestBody);
  }

  public submitScreening(code: string, version: string, answers: any, screeningProgramCodes: string[]){
    return this.http.post<any>(`${environment.ncoaApiBaseUrl}/screening/client/performScreening`, {code, version, screeningProgramCodes, answers})
  }

  private getEventSource(): string {

    return 'awp-workforce';
  }
  public getProgramsBySuperCatState(superCategoryCode: string,state:string){
    return this.http.get<any>(`${environment.ncoaApiBaseUrl}/screening/client/getPrograms?state=${state}&superCategoryCode=${superCategoryCode}`,{})
  }

}


export interface QuestionResponse {
  questionId: string,
  questionVersion: any,
  answer: Answer
}
interface Answer {
  text: string,
  value: any
}