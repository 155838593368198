import { Injectable } from '@angular/core';
import { GlobalService } from '@services/global.service';
import { take } from 'rxjs/operators';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { ToolsPrimaryConfigService } from './tools-primary-config.service';
import { UserService } from './user.service';
import { UtilityService } from './utility.service';

@Injectable({ providedIn: 'root' })
export class SavedContentsService {
  constructor(
    private tools: ToolsPrimaryConfigService,
    private kontent: KontentDeliveryService,
    private utility: UtilityService,
    private userService: UserService,
  ) { }

  async getCards(ids) {
    await this.refreshSavedContents();

    return await Promise.all(ids.map((id) => {
      return this.kontent.getItem(null, { 'system.id': id });
    })).then(async (responses) => {
      const cards = [];

      responses.forEach((response: any) => {
        const raw = response.items[0];

        if (raw && raw.system && raw.elements) {
          switch (raw.system.type) {
            case 'ncoa_article_content': {
              const div = document.createElement('div');
                    div.innerHTML = raw.elements.body_ckeditor ? raw.elements.body_ckeditor.value : '';
              const text = div.innerText;
              const description = `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`;

              const tags = raw.elements[this.tools.toolTaxonomyCodename].value.map(({ codename }) => codename);
              const verticals = tags.map((tag) => this.tools.getVertical(tag));

              let vertical = this.utility.arrayMode(verticals);

              if (raw.elements.awp_trusted_expert.value.length > 0) {
                // trusted expert card
                vertical = vertical ? vertical.name : 'Trusted';

                cards.push({
                  id: raw.system.id,
                  type: 'referral',
                  eyebrow: `${vertical} Experts`,
                  heading: raw.elements.title.value,
                  intro: description,
                  cta: {
                    label: 'View Details',
                    url: `${this.tools.rootURL}/resource/${raw.elements.url.value}`,
                  },
                  bookmark: true,
                  isSaved: true,
                  verticals: verticals.map(({ codename }) => codename),
                });
              } else {
                // resource card
                vertical = vertical ? vertical.name : 'Resource';

                cards.push({
                  id: raw.system.id,
                  type: 'article',
                  heading: raw.elements.title.value,
                  intro: description,
                  cta: {
                    label: 'Read Resource',
                    url: `${this.tools.rootURL}/resource/${raw.elements.url.value}`,
                  },
                  breadcrumb: vertical,
                  verticals: verticals.map(({ codename }) => codename),
                });
              }
              break;
            }
            case 'awa_benefits_tool___vertical_subtopic': {
              const tag = raw.elements[this.tools.toolTaxonomyCodename].value.length > 0
                ? raw.elements[this.tools.toolTaxonomyCodename].value[0]
                : null;

              if (tag) {
                const subtopicRoute = this.tools.routes.find((route) => (
                  route.type === 'vertical-subtopic-learn' &&
                  route.codename === raw.system.codename
                ));

                if (subtopicRoute) {
                  const topicRoute = this.tools.routes.find((route) => (
                    route.type === 'vertical-topic' &&
                    route.codename === subtopicRoute.parentTopic
                  ));

                  const verticalRoute = this.tools.routes.find((route) => (
                    route.type === 'vertical' &&
                    route.codename === subtopicRoute.parentVertical
                  ));

                  if (topicRoute && verticalRoute) {
                    cards.push({
                      id: raw.system.id,
                      type: 'subtopic',
                      eyebrow: `${verticalRoute.text} / ${topicRoute.text}`,
                      heading: raw.elements.title.value,
                      cta: {
                        label: 'Learn More',
                        url: subtopicRoute.route,
                      },
                      isSaved: true,
                      verticals: [verticalRoute.taxonomy.codename],
                    });
                  }
                }
              }

              break;
            }
            case 'awa_benefits_tool___card__tool_': {
              const completedAssessments = this.tools.customerActivity.basic?.completedAssessments || [];

              const key = raw.elements.tool.value[0];
              const tool = response.modular_content[key];
              const assessmentID = tool.elements.assessment_id.value;

              const tags = tool.elements[this.tools.toolTaxonomyCodename].value;
              let verticals = tags.map((tag) => this.tools.getVertical(tag));
                  verticals = verticals.filter((vertical) => vertical);

              const heading = raw.elements.headline.value;
              const photo = raw.elements.card_image.value.length > 0 ? {
                url: raw.elements.card_image.value[0].url,
                alt: raw.elements.card_image.value[0].description,
              } : null;
              const label = completedAssessments.find((key) => key === assessmentID)
                ? 'View Results'
                : 'Start Assessment';
              const url = (this.tools.routes.find((route) => (
                route.assessmentID &&
                route.assessmentID === assessmentID
              )) || { route: this.tools.rootURL }).route;
              const variation = assessmentID === 'generalAssessment' || assessmentID === 'generalAssessmentAWP'
                ? 'tools-retirement-single-col'
                : 'tools-medicare-single-col';
              const theme = assessmentID === 'generalAssessment' || assessmentID === 'generalAssessmentAWP'
                ? null
                : 'dark';

              cards.push({
                id: raw.system.id,
                linkedID: tool.system.id,
                linkedCodename: tool.system.codename,
                type: 'tool',
                heading,
                cta: {
                  label,
                  url,
                },
                photo,
                variation,
                theme,
                isSaved: true,
                verticals: verticals.map(({ codename }) => codename),
              });
              break;
            }
          }
        }
      });

      return cards;
    });
  }

  async refreshSavedContents() {
    const savedContents = this.tools.customerActivity.basic?.SavedContent || [];
    const previousLength = savedContents.length;

    await Promise.all(savedContents.map((content) => {
      return this.kontent.getItem(null, { 'system.id': content.ContentId });
    })).then((responses) => {
      responses.forEach((response: any, index) => {
        if (response.items.length === 0) {
          savedContents.splice(index, 1);
        }
      });
    });

    if (savedContents.length !== previousLength) {
      this.userService.isLoggedIn()
        .pipe(take(1))
        .subscribe((isLoggedIn) => {
          if (isLoggedIn) {
            this.utility.updateSavedContent(savedContents);
          }
        });
    }
  }

  slugify( string ) {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }
}
