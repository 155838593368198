import { Component, OnInit, Input, ViewChild, ViewEncapsulation, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserService } from '@services/planner/user.service';
import { UtilityService } from '@services/planner/utility.service';

@Component({
  selector: 'ncoa-account-register',
  templateUrl: './account-register.component.html',
  styleUrls: ['./account-register.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountRegister implements OnInit {

  errorMessage: string = '';

  @Output() showErrorModal: EventEmitter<any> = new EventEmitter();
  @Output() showLoadingModal: EventEmitter<any> = new EventEmitter();

  @Input() backCTA: {
    url: string;
    text: string;
  };

  @Input() eyebrow: string;
  @Input() steps: {
    title: string;
    desc: string;
  }[];

  @Input() signInCTA: {
    url: string;
    text: string;
  };

  @Input() helpCTA: {
    url: string;
    text: string;
  }
  @Input() everyActionFormUrl: string;

  @ViewChild('submitBtn') submitBtn: any;
  @ViewChild('progressBar') progressBar: any;
  @ViewChild('track') stepTrack: any;
  @ViewChildren('step') stepsContainers: QueryList<any>;

  email: {
    value: string;
    status: {
      code: string;
      message: string;
    };
  } = {
    value: '',
    status: {
      code: '',
      message: '',
    },
  };

  newsletter: {
    value: boolean;
  } = {
    value: false,
  };

  password: {
    value: string;
    status: {
      code: string;
      message: string;
    };
  } = {
    value: '',
    status: {
      code: '',
      message: '',
    },
  };

  confirmPassword: {
    value: string;
    status: {
      code: string;
      message: string;
    };
  } = {
    value: '',
    status: {
      code: '',
      message: '',
    },
  };

  activeStepIndex: number = 1;
  stepCount: number = 2;
  registrationDone: boolean = false;

  verificationEyebrow: string;
  verificationTitle: string;
  verificationDesc: string;
  everyactionData = {};

  constructor(
    private utilityService: UtilityService,
    private userService: UserService,
    private cas: CustomerActivityService,
    private tools: ToolsPrimaryConfigService,
    private dataLayerService: DataLayerService,
  ) {
    this.verificationEyebrow = this.tools.rawConfig.elements.verification_eyebrow.value;
    this.verificationTitle = this.tools.rawConfig.elements.verification_title.value;
    this.verificationDesc = this.tools.rawConfig.elements.verification_description.value;
  }

  async ngOnInit() { 
    this.userService.getFormData(this.everyActionFormUrl).then(data => {
      this.everyactionData = data;
    } );

  }

  goBack(e){
    if(!document.referrer){
      const lastUrl = window.sessionStorage.getItem("last_url");
      const lastUrlIndex = window.sessionStorage.getItem("last_url_index");
      if((history.length - 1) === parseInt(lastUrlIndex)){
        window.location.href = lastUrl;
      }else{
        window.location.href = this.backCTA.url;
      }
    }else{
      if (document.referrer.indexOf(window.location.host) !== -1){
        e.preventDefault();
        history.back();
      }else{
        window.location.href = this.backCTA.url;
      }
    }
  }


  onSubmit(e): void {
    e.preventDefault();

    const el_email: HTMLElement = document.querySelector('#email');
    const el_password: HTMLElement = document.querySelector('#password');
    const el_cpassword: HTMLElement = document.querySelector('#c_password');

    if (this.activeStepIndex === 1) {
      if (this.email.value.trim().length === 0 ||
          (this.email.value.trim().length > 0 &&
            !this.utilityService.isEmailValid(this.email.value))) {
        this.email = {
          ...this.email,
          status: {
            code: 'error',
            message: 'Please enter a valid email address',
          },
        };
        this.handleErrorMessageChange(this.email.status.message,el_email);
      } else if (this.email.value.trim().length > 0 &&
        this.utilityService.isEmailValid(this.email.value)) {
          this.email = {
            ...this.email,
            status: {
              code: '',
              message: '',
            },
          };

          const firstStep = this.stepsContainers.toArray()[0].nativeElement;
          const secondStep = this.stepsContainers.toArray()[1].nativeElement;
          const firstInput = secondStep.querySelector('input');

          secondStep.style.removeProperty('visibility');
          const offset = secondStep.clientWidth;

          this.stepTrack.nativeElement.style.transform = `translateX(-${offset}px)`;
          this.progressBar.nativeElement.style.width = '100%';

          setTimeout(() => {
            firstInput.focus();
            firstStep.style.visibility = 'hidden';
            this.activeStepIndex = 2;
          }, 400);

          this.submitBtn.nativeElement.innerHTML = 'Finish';
      }
    } else if (this.activeStepIndex === 2) {
      if (this.password.value.trim().length === 0 ||
          (this.password.value.trim().length > 0 &&
            !this.utilityService.isPasswordValid(this.password.value))) {
        this.password = {
          ...this.password,
          status: {
            code: 'error',
            message: 'Password must have at least 8 characters, one uppercase letter, one lowercase latter, one number, and one special character (@$!%*?&)',
          },
        };
        this.handleErrorMessageChange(this.password.status.message,el_password);
      } else if (this.password.value.trim().length > 0 &&
        this.utilityService.isPasswordValid(this.password.value)) {
          this.password = {
            ...this.password,
            status: {
              code: '',
              message: '',
            },
          };
          if(this.password.value.trim() !== this.confirmPassword.value.trim()){
            this.confirmPassword = {
              ...this.confirmPassword,
              status: {
                code: this.confirmPassword.value !== this.password.value ? 'error' : '',
                message: this.confirmPassword.value !== this.password.value ? 'Password didn\'t match' : '',
              },
            };
              this.handleErrorMessageChange(this.confirmPassword.status.message,el_cpassword);
          }
      }


      if (this.email.status.code !== 'error' &&
          this.password.status.code !== 'error' &&
          this.confirmPassword.status.code !== 'error') {

        this.showLoadingModal.emit();

        this.userService.signUp(this.email.value, this.password.value)
          .subscribe(() => {
            this.dataLayerService.push({
              'event': 'userAccount',
              'formAction': 'sign up',
              'formStatus': 'success'
            });
            window.localStorage.setItem('linkUser', 'true');

            this.cas.updateActivity({'userAccountEmail':this.email.value}).subscribe(() => {});
            this.cas.updateActivity({'newsletterConsent':this.newsletter.value}).subscribe(() => {});

            if(this.newsletter.value){
              this.userService.postEveryActionSignup(this.everyactionData['submissionUrl'], {EmailAddress:this.email.value}).subscribe((response) => {
            });
          }

            this.cas.announceSignUp().subscribe(() => {
              this.email = {
                ...this.email,
                value: '',
              };

              this.password = {
                ...this.password,
                value: '',
              };

              this.confirmPassword = {
                ...this.confirmPassword,
                value: '',
              };

              this.showLoadingModal.emit();
              this.registrationDone = true;
            });
          }, (error) => {
            // error 409 if email is already registered
            if (error.status === 409) {
              this.password = {
                ...this.password,
                value: '',
              };

              this.confirmPassword = {
                ...this.confirmPassword,
                value: '',
              };

              this.submitBtn.nativeElement.innerHTML = 'Save & Continue';
              this.stepTrack.nativeElement.style.removeProperty('transform');

              const firstStep = this.stepsContainers.toArray()[0].nativeElement;
              const secondStep = this.stepsContainers.toArray()[1].nativeElement;

              firstStep.style.removeProperty('visibility');

              this.progressBar.nativeElement.style.width = '50%';

              setTimeout(() => {
                secondStep.style.visibility = 'hidden';
                this.activeStepIndex = 1;
              }, 400);

              this.showLoadingModal.emit();
              this.showErrorModal.emit();
            }else{
              this.dataLayerService.push({
                'event': 'userAccount',
                'formAction': 'sign up',
                'formStatus': 'fail'
              });
            }
          })
      }
    }
  }
  clearErrors() {
    this.confirmPassword = {
      ...this.confirmPassword,
      status: {
        code: '',
        message: '',
      }
    }
    this.password = {
      ...this.password,
      status: {
        code: '',
        message: '',
      }
    }
  }
  handleErrorMessageChange(newMessage, focusTo){
    focusTo.focus();
    setTimeout(()=>{
      if(this.errorMessage !== ''){
        if(this.errorMessage === newMessage){
          this.errorMessage = '';
          setTimeout(()=>{
            this.errorMessage = newMessage
          },500);
        }else{
          this.errorMessage = newMessage;
          setTimeout(()=>{
            this.errorMessage = ''
          },1000);
        }
      }else{
        this.errorMessage = newMessage;
      }
    },300);
  }

}
