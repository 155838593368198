import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { IncomeServiceService } from '@services/planner/income-breakdown.service';
import { DataLayerService } from '@services/data-layer.service';
@Component({
  selector: 'ncoa-assessment-summary',
  templateUrl: './assessment-summary.component.html',
  styleUrls: ['./assessment-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssessmentSummaryComponent implements OnInit {

  data: any;

  @Input() breakdownHeader: any = null;
  @Input() breakdownPartnership: any = null;
  @Input() breakdownGraph: any = null;
  @Input() asset: any = null;
  @Input() fixed: any = null;
  @Input() variable: any = null;
  @Input() recommended: any = null;

  constructor(
    private toolsConfig: ToolsPrimaryConfigService,
    private income: IncomeServiceService,
    private dataLayerService: DataLayerService,
  ) { }

  ngOnInit(): void {

    this.income.getContents().then( _obj => {
      this.data = _obj;
    });

    // this.data = {
    //   breakdownHeader: {
    //     heading: '<span>Results:</span> Retirement Income Breakdown',
    //     backLink: {
    //       label: 'Retirement Income Basics',
    //       url: '#back'
    //     },
    //     subheadline: 'Here\'s what you should look out for:',
    //     body: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet orci nibh massa ut orci nisl, malesuada at aliquam. Nibh amet dictum suscipit aliquam pretium vitae sit velit enim. Nulla egestas scelerisque parturient eget.</p>
    //     <ul>
    //       <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel dignissim phasellus eu non eget tellus viverra feugiat tristique. Eu tellus porta faucibus tristique elementum nec euismod at. Praesent vel urna quisque nisl, felis. Vel eu venenatis, est, neque ac </li>
    //       <li>rhoncus id volutpat velit. Eu nulla odio amet, diam praesent aliquam malesuada. Velit ullamcorper vitae scelerisque aliquet tincidunt tellus a risus. Pellentesque viverra diam enim lectus. Sed dui vitae non id nunc, viverra nullam luctus et.</li>
    //       <li>Tortor eget sem donec adipiscing sed.</li>
    //     </ul>`
    //   },
    //   breakdownPartnership: {
    //     text: `Lorem ipsum <a href="#">Standards of Excellence</a> dolor sit amet, consectetur adipiscing elit. Vel dignissim phasellus eu non eget tellus viverra feugiat tristique. Eu tellus <a href="#">Nationwide</a> ristique elementum.`,
    //     icon: {
    //       alt: 'Partnership Logo',
    //       src: '../assets/images/breakdown-partnership.png'
    //     }
    //   },
    //   breakdownGraph: this.income.getBreakdownGraph(),
    //   recommended: {
    //     location: false,
    //     heading: 'Recommendations based on your answers:',
    //     cards: [
    //       {
    //         type: 'article',
    //         heading: 'What’s the Difference Between 401(K) and 403(b) Plans?',
    //         intro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat nulla metus suspendisse quis nulla tempor tristique sit.',
    //         cta: {
    //           label: 'Read Resource',
    //           url: '/read-more'
    //         },
    //         breadcrumb: [
    //           { label: 'Medicare', url: '/medicare' },
    //           { label: 'Resource', url: '/medicare/resource' }
    //         ]
    //       },
    //       {
    //         type: 'benefit',
    //         heading: 'New York Supplemental Income Program',
    //         intro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat nulla metus suspendisse quis nulla tempor tristique sit.',
    //         cta: {
    //           label: 'View Program Details',
    //           url: '/read-more'
    //         },
    //         breadcrumb: [
    //           { label: 'Medicare', url: '/medicare' },
    //           { label: 'Benefits', url: '/medicare/benefits' }
    //         ]
    //       },
    //       {
    //         type: 'promo',
    //         background: {
    //           url: '/assets/images/card-promo.jpg',
    //           alt: 'A photo of an old woman using a laptop'
    //         },
    //         eyebrow: 'Looking for Benefits?',
    //         heading: 'Search over 2500+ benefit programs quickly to see what is right for your situation. ',
    //         cta: {
    //           label: 'View Program Details',
    //           url: '/read-more'
    //         }
    //       },
    //       {
    //         type: 'referral',
    //         eyebrow: 'Trusted Expert',
    //         heading: 'Medicare Broker Title Lorem Ipsum Dolor Sit amet.',
    //         intro: 'Enroll in Medicare, Ask Questions, get Professional Guidance.',
    //         cta: {
    //           label: 'View Details',
    //           url: '/read-more'
    //         }
    //       },
    //       {
    //         type: 'tool',
    //         heading: 'Retirement Income Breakdown',
    //         cta: {
    //           label: 'Start Assessment',
    //           url: '/start'
    //         },
    //         photo: {
    //           url: '../../assets/images/retirement-income.png',
    //           alt: 'An illustration of Retirement Income'
    //         }
    //       },
    //       {
    //         type: 'subtopic',
    //         eyebrow: 'Finance / Retirement Income',
    //         heading: 'Is an Annuity Right for Me?',
    //         cta: {
    //           label: 'View Program Details',
    //           url: '/start'
    //         },
    //       }
    //     ]
    //   }
    // }
  }


  ngAfterViewInit(): void {
      this.initializeCardLinks();
  }

  onLoadMoreClick(){
    this.initializeCardLinks();
  }

  initializeCardLinks(){
    const allCards = document.querySelectorAll('.component-vertical-recommended .recommended .recommended--card-group .card--cta a');
    const self = this;
    allCards.forEach(anchor =>{
      anchor.addEventListener('click', function(ev){
        ev.stopPropagation();
        self.trackEvent(this);
      })
    })
  }


  trackEvent( el: HTMLAnchorElement ) {
    const _clickUrl = el.getAttribute('href');

    this.dataLayerService.push({
      'event': 'retirementResults',
      'componentType': 'recommendation click',
      'url': el.href || _clickUrl,
      'clickText': el.innerText,
      'pageType': 'income-breakdown-page'
     },false);
  }





}
