import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { GlobalService } from '@services/global.service';
import { environment } from '@environment';

const seoMetaDataFields = [
  'seo_metadata_example_to_include_in_any_type__meta_title',
  'seo_metadata_example_to_include_in_any_type__meta_description',
  'seo_metadata_example_to_include_in_any_type__facebook_og_title',
  'seo_metadata_example_to_include_in_any_type__facebook_og_description',
  'seo_metadata_example_to_include_in_any_type__facebook_og_image',
  'seo_metadata_example_to_include_in_any_type__twitter_card_title',
  'seo_metadata_example_to_include_in_any_type__twitter_card_description',
  'seo_metadata_example_to_include_in_any_type__twitter_card_image',
];

const defaultImage = `${window.location.origin}/assets/images/article-hero-share-midnight-desktop.png`;

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private metaService: Meta,
    private titleService: Title,
    private route: Router,
    private globalService: GlobalService,
    @Inject(DOCUMENT) private dom
  ) { }

  extractAndRender(rawItemData, defaultData = {}) {
    const seo = this.buildSeoData(rawItemData, defaultData);
    this.applyMetaData(seo);
  }

  buildSeoData(rawItemData = null, defaultData = {}) {
    const metaDataFields: any = rawItemData ? this.kontentDeliveryService.extractItemElements(rawItemData, seoMetaDataFields) : {};
    const type = rawItemData?.system.type || 'taxonomy';
    const primaryFields: any = rawItemData ? this.kontentDeliveryService.extractItemElements(rawItemData, [
      'title', 'excerpt', 'primary_image', 'event_date_time',
    ]) : {};

    const seoMeta = this.mapSeoMetaData({ ...metaDataFields, ...primaryFields, type});
    const processedSeoMeta = this.overrideDefaultMeta(seoMeta, defaultData);
    return processedSeoMeta;
  }

  mapSeoMetaData(rawFields) {
    const type = this.determineType(rawFields.type);

    const url = location.href;
    const title = rawFields.seo_metadata_example_to_include_in_any_type__meta_title || rawFields.title;
    const description = rawFields.seo_metadata_example_to_include_in_any_type__meta_description || rawFields.excerpt || '';

    const ogTitle = rawFields.seo_metadata_example_to_include_in_any_type__facebook_og_title || '';
    const ogDescription = rawFields.seo_metadata_example_to_include_in_any_type__facebook_og_description || '';
    const ogImageField = rawFields.seo_metadata_example_to_include_in_any_type__facebook_og_image;
    const ogImage = ogImageField && ogImageField.length ? ogImageField[0].url : `${window.location.origin}/assets/images/article-hero-share-midnight-desktop.png`;

    const twitterDescription = rawFields.seo_metadata_example_to_include_in_any_type__twitter_card_description || '';
    const twitterTitle = rawFields.seo_metadata_example_to_include_in_any_type__twitter_card_title || '';
    const twitterImageField = rawFields.seo_metadata_example_to_include_in_any_type__twitter_card_image;
    const twitterImage = twitterImageField && twitterImageField.length ? twitterImageField[0].url : `${window.location.origin}/assets/images/article-hero-share-midnight-desktop.png`;

    let image = '';
    if (ogImage) {
      image = ogImage;
    } else {
      if (rawFields.primary_image && rawFields.primary_image.length) {
        image = rawFields.primary_image[0].url;
      } else {
        if (type === 'article' && rawFields.event_date_time) {
          image = `${window.location.origin}/assets/images/article-hero-share-green-desktop.png`;
        } else if (type === 'article' && !rawFields.event_date_time) {
          image = `${window.location.origin}/assets/images/article-hero-share-coral-desktop.png`;
        } else {
          image = `${window.location.origin}/assets/images/article-hero-share-midnight-desktop.png`;
        }
      }
    }

    // Ensure title as text only
    const div = document.createElement('div');
    div.innerHTML = title || '';

    return {
      title: div.textContent,
      description,
      type,
      image,
      url,
      ogTitle,
      ogDescription,
      ogImage,
      twitterDescription,
      twitterTitle,
      twitterImage,
    }
  }

  overrideDefaultMeta(seoMeta, defaultData: any = {}) {
    if ( !seoMeta.title && 'title' in defaultData ) {
      seoMeta.title = defaultData.title;
    }

    if ( !seoMeta.description && 'description' in defaultData ) {
      seoMeta.description = defaultData.description;
    }

    if ( !seoMeta.image && 'image' in defaultData ) {
      seoMeta.image = defaultData.image;
    }

    return seoMeta;
  }

  applyMetaData(seoMetaData: any) {
    const baseURL = window.location.protocol && window.location.host && !/static/i.test(window.location.host)
      // ? `${window.location.protocol}//${window.location.host.split('.').slice(-2).join('.')}`
      ? `${window.location.protocol}//${window.location.host}`
      : 'https://www.ncoa.org';
    const path = this.globalService.getCurrentURL();
    
    if (!path.includes(baseURL)) seoMetaData.url = baseURL + path;
    else seoMetaData.url = path;

    if ( !seoMetaData.type ) {
      seoMetaData.type = 'page';
    }

    this.titleService.setTitle(seoMetaData.title);

    this.addORUpdateMetaTags([
      { name: 'description', content: seoMetaData.description },
      { property: "og:locale", content: 'en_US' },
      { property: "og:type", content: seoMetaData.ogType || seoMetaData.type },
      { property: "og:title", content: seoMetaData.ogTitle || seoMetaData.title },
      { property: "og:description", content: seoMetaData.ogDescription || seoMetaData.description },
      { property: "og:url", content: seoMetaData.url },
      { property: "og:site_name", content: '@NCOAging' },
      { property: "og:image", content: seoMetaData.ogImage || seoMetaData.image },
      { name: "twitter:url", content:seoMetaData.url },
      { name: "twitter:card", content:"summary_large_image" },
      { name: "twitter:site", content:"@NCOAging" },
      { name: "twitter:title", content: seoMetaData.twitterTitle || seoMetaData.title },
      { name: "twitter:description", content: seoMetaData.twitterDescription || seoMetaData.description },
      { name: "twitter:image", content: seoMetaData.twitterImage || seoMetaData.image || defaultImage },
    ]);

    // Update canonical URL
    this.updateCanonical(seoMetaData.url);
  }

  addORUpdateMetaTags(metaTagsData) {
    metaTagsData.forEach((metaTagData: any) => {
      const attributeName = 'name' in metaTagData ? `name` : 'property';
      const attributeValue = metaTagData[attributeName];
      const metaElement = this.metaService.getTag(`${attributeName}="${attributeValue}"`);
      const addOrUpdateMethod = metaElement ? 'updateTag' : 'addTag';

      this.metaService[addOrUpdateMethod](metaTagData);
    });
  }

  determineType(contentType) {
    switch (contentType) {

      case 'ncoa_article_content':
        return 'article';

      default:
        return 'page';

    }
  }

  updateCanonical(url) {
    if ( this.dom ) {
      const head = this.dom.getElementsByTagName('head')[0];
      let element: any = this.dom.querySelector(`link[rel='canonical']`);

      if (!element) {
        element = this.dom.createElement('link');
        head.appendChild(element);
      }

      element.setAttribute('rel','canonical');
      element.setAttribute('href', url);
    }
  }

  buildToolsSeoData(payload: {
    metaTitle: string;
    metaDesc: string;
    ogSiteName: string;
    ogTitle: string;
    ogDesc: string;
    ogImage: string;
    twitterSiteName: string;
    twitterTitle: string;
    twitterDesc: string;
    twitterImage: string;
    pageType: string;
    fallbackImage: string;
    fallbackTitle: string;
  }) {
    const baseURL = window.location.protocol && window.location.host && !/static/i.test(window.location.host)
      // ? `${window.location.protocol}//${window.location.host.split('.').slice(-2).join('.')}`
      ? `${window.location.protocol}//${window.location.host}`
      : 'https://www.ncoa.org';

    const path = this.globalService.getCurrentURL();
    let url = baseURL + path;

    var count = (url.split(baseURL) || []).length - 1;
    if (count > 1) url = url.replace(baseURL, ''); // replace nested baseUrl

    // update title
    this.titleService.setTitle(payload.metaTitle || payload.ogTitle);

    // update meta tags
    this.addORUpdateMetaTags([
      { name: 'description', content: payload.metaDesc },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:type', content: payload.pageType },
      { property: 'og:title', content: payload.ogTitle || payload.metaTitle || payload.fallbackTitle },
      { property: 'og:description', content: payload.ogDesc || payload.metaDesc || '' },
      { property: 'og:url', content: url },
      { property: 'og:site_name', content: payload.ogSiteName },
      { property: 'og:image', content: payload.ogImage || payload.fallbackImage },
      { name: 'twitter:url', content: url },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: payload.twitterSiteName },
      { name: 'twitter:title', content: payload.twitterTitle || payload.metaTitle || payload.fallbackTitle },
      { name: 'twitter:description', content: payload.twitterDesc || payload.metaDesc || '' },
      { name: 'twitter:image', content: payload.twitterImage || payload.fallbackImage },
    ]);

    this.updateCanonical(url);
  }
}
