<div class="home">
<ncoa-template-default>
  <ng-container
    *ngFor="let component of components; index as order"
    [ngSwitch]="component?.componentType"
  >
    <ncoa-home-hero
      *ngSwitchCase="'hero'"
      [theme]="theme"
      [title]="component.title"
      [cta]="component.cta"
      [image]="component.image"
      ncoa-engageable="{{order}}"
    ></ncoa-home-hero>
    <ncoa-audience
      *ngSwitchCase="'audiencePathing'"
      [theme]="theme"
      [audience]="component.audience"
      ncoa-engageable="{{order}}"
    ></ncoa-audience>
    <ncoa-our-mission
      *ngSwitchCase="'missionStatement'"
      [headline]="component.headline"
      [description]="component.description"
      [missionEntry]="component.missionEntry"
      [cta]="component.cta"
      [ctaText]="component.ctaText"
      ncoa-engageable="{{order}}"
    ></ncoa-our-mission>
    <ncoa-content-package
      *ngSwitchCase="componentTypes.content_package"
      [title]="component.title"
      [articles]="component.articles"
      ncoa-engageable="{{order}}"
    ></ncoa-content-package>
    <ncoa-feature-page-block
      *ngSwitchCase="componentTypes.featured_page_block"
      [image]="component.image"
      [headline]="component.headline"
      [description]="component.description"
      [type]="component.type"
      [background]="component.background"
      [audiences]="component.audiences"
      [url]="component.url"
      [blockCTA]="component.blockCTA"
      [orientation]="component.orientation"
      ncoa-engageable="{{order}}"
    ></ncoa-feature-page-block>
    <ncoa-donate
      *ngSwitchCase="componentTypes.call_to_donate"
      [heading]="component.heading"
      [description]="component.description"
      [form_desc]="component.form_desc"
      [background]="component.photo"
      [backgroundCaption]="component.caption"
      [responsive]="component.responsive"
      ncoa-engageable="{{order}}"
    ></ncoa-donate>
  
      <ncoa-external-form
      [component]="component"
       *ngSwitchCase="componentTypes.external_form" 
      ncoa-engageable="{{order}}"
      ></ncoa-external-form>
    
    <ncoa-newsletter
      *ngSwitchCase="componentTypes.newsletter_signup_block"
      [heading]="component.heading"
      [description]="component.description"
      [background]="component.background"
      [backgroundCaption]="component.backgroundCaption"
      [mobilebackground]="component.mobilebackground"
      [newsletterLink]="component.newsletterLink"
      [width]="component.width"
      [height]="component.height"
      [everyActionFormUrl]="component.everyActionFormUrl"
      [fieldErrorText]="component.fieldErrorText"
      [emailErrorText]="component.emailErrorText" 
      ncoa-engageable="{{order}}"
    ></ncoa-newsletter>
    <ncoa-subcategory-pathing
      *ngSwitchCase="componentTypes.category_pathing"
      [headline]="component.headline"
      [page]="'homepage'"
      [categories]="component.categories"
      ncoa-engageable={{order}}
    ></ncoa-subcategory-pathing>
    <ncoa-featured-tool-breaker
      *ngSwitchCase="componentTypes.featured_tool_breaker"
      [variation]="component.variation"
      [eyebrow]="component.eyebrow"
      [headline]="component.headline"
      [description]="component.description"
      [image]="component.image"
      [cta]="component.cta"
      ncoa-engageable="{{order}}"
    ></ncoa-featured-tool-breaker>
    <external-content-package
      *ngSwitchCase="componentTypes.external_content_package"
      [title]="component.title"
      [url]="component.url"
    ></external-content-package>
  </ng-container>
</ncoa-template-default>
</div>
