import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { UserService } from '@services/planner/user.service';
import { debounceTime } from 'rxjs/operators';
import { ErrorMessageProcessor } from 'src/app/validators/error-message-processor';
import { dateIsValid, maxYear, minYear } from 'src/app/validators/date-validator';

@Component({
  selector: 'ncoa-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FormModalComponent implements OnInit {

  @Input() formData: any = null; // for future use
  @Input() modalContent: any;
  @Input() contextClass: any;

  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() sendEmail: EventEmitter<any> = new EventEmitter();

  modalForm: FormGroup;
  errorMessageProcessor: ErrorMessageProcessor;
  errorMessages: any;
  disableSubmit = false;
  showThankYou = false;
  ctaClass: string;
  selectedLanguage = 'en';

  constructor(
    private userService: UserService,
    private cas: CustomerActivityService,
    private fb: FormBuilder,
  ) { }
  
  ngOnInit(): void {
    if (this.modalContent.race_ethnicity) { // silly check to see which modal we are showing
      this.createReminderForm();
      this.ctaClass = `reminder-cta-${this.contextClass}`;
    } else {
      this.createEmailForm();
      this.ctaClass = `email-cta-${this.contextClass}` ;
    }

    this.selectedLanguage = localStorage.getItem('language') || 'en'; // default to English
  }

  doCloseModal() {
      this.closeModal.emit('close');
  }

  createEmailForm() {
    this.modalForm = this.fb.group({
      firstName: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
      lastName: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
      email: ['', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur',
      }],
      communicationsConsent: ['true', {}]
    });

    this.errorMessageProcessor = new ErrorMessageProcessor({});
    this.errorMessages = this.errorMessageProcessor.processMessages(this.modalForm);

    this.modalForm.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.errorMessages = this.errorMessageProcessor.processMessages(this.modalForm);
    });
  }

  createReminderForm() {
    this.modalForm = this.fb.group({
      firstName: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
      lastName: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
      email: ['', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur',
      }],
      birthYear: ['', { 
        validators: [maxYear(new Date().getFullYear() - 18), minYear(new Date().getFullYear() - 100)],
        updateOn: 'blur',
      }],
      gender: ['', {}],
      race: ['', {}],
      sendReportEmail: ['', {}],
      // communicationsConsent: ['true', {}]
    });

    this.errorMessageProcessor = new ErrorMessageProcessor({});
    this.errorMessages = this.errorMessageProcessor.processMessages(this.modalForm);

    this.modalForm.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.errorMessages = this.errorMessageProcessor.processMessages(this.modalForm);
    });
  }

  onSubmit() {
      this.disableSubmit = true;

      if (this.modalForm.valid) {

        let formValues = JSON.parse(JSON.stringify(this.modalForm.value));
        if (formValues.race !== '') formValues.race = [formValues.race];
        // TODO: add language flag
        const cleanFormValues = Object.keys(formValues)
                                .filter((k) => formValues[k] != '')
                                .reduce((a, k) => ({ ...a, [k]: formValues[k] }), {});
        
        if (cleanFormValues['sendReportEmail'] || formValues.sendReportEmail === undefined) {
          this.sendEmail.emit(formValues.email);
        }

        // TODO: update fallsLanguage in CAD when adding language toggle

        if (formValues.sendReportEmail !== undefined) {
          this.cas.announceEvent('awpFallsReminder', cleanFormValues, true, window.location).subscribe(() => {
              this.showThankYou = true;
          }, err => {
              console.log(err);
          });
        } else {
          this.showThankYou = true;
        }
      } else {
        this.modalForm.markAllAsTouched();
        this.modalForm.updateValueAndValidity();
        this.disableSubmit = false;
      }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  genderValues = [
    { label: { en: 'Male', es: 'Masculino'}, value: 'male' },
    { label: { en: 'Female', es: 'Femenino'}, value: 'female' },
    { label: { en: 'Other', es: 'Otro'}, value: 'other' }
  ];

  raceEthnicityValues = [
    { label: { en: 'American Indian/Alaskan Native', es: 'Indio Americano/Nativo de Alaska'}, value: 'american_indian_alaskan_native' },
    { label: { en: 'Asian', es: 'Asiático'}, value: 'asian' },
    { label: { en: 'Black or African American', es: 'Negro o Africano-Americano'}, value: 'black_african_american' },
    { label: { en: 'Hispanic/Latino', es: 'Hispano / latino'}, value: 'hispanic_latino' },
    { label: { en: 'Native Hawaiian/Pacific Islander', es: 'Nativo hawaiano / isleño del Pacífico'}, value: 'native_hawaiian_pacific_islander' },
    { label: { en: 'White', es: 'Blanco'}, value: 'white' },
    { label: { en: 'Other', es: 'Otro'}, value: 'other' },
    { label: { en: 'Prefer not to answer', es: 'Prefiero No Decir'}, value: 'prefer_not_to_answer' }
  ];

  get firstName() { return this.modalForm.get('firstName'); }
  get lastName() { return this.modalForm.get('lastName'); }
  get email() { return this.modalForm.get('email'); }
  get birthYear() { return this.modalForm.get('birthYear'); }
  get race() { return this.modalForm.get('race');}
  get sendReportEmail() { return this.modalForm.get('sendReportEmail'); }
  // get communicationsConsent() { return this.modalForm.get('communicationsConsent'); }
}
