import { Component, OnInit, Input } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-article-grouping-grid',
  templateUrl: './article-grouping-grid.component.html',
  styleUrls: ['./article-grouping-grid.component.scss']
})
export class ArticleGroupingGridComponent implements OnInit {

  @Input() heading: string;
  @Input() cards: [any];

  constructor(private dataLayerService: DataLayerService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() { 
    this.initializeCardLinks();
  }

  initializeCardLinks(){
    const allCards = document.querySelectorAll('.component-article-grid .article-grid .articles--list .card--cta a');
    const self = this;
    allCards.forEach(anchor =>{
      if(!anchor.classList.contains('three--up-analytics')){
        anchor.addEventListener('click', function(ev){
          ev.stopPropagation();
          self.trackEvent(this);
        })
      }
      anchor.classList.add('three--up-analytics');
    })
  }

  trackEvent( el: HTMLAnchorElement ) {
    const _clickUrl = el.href || el.getAttribute('href');

    this.dataLayerService.push({ 
      'event': 'recirc', 
      'url': _clickUrl,
      'componentType': 'content grid' },false); 
  }

}
