<div class="text-with-image">
  <div *ngIf="alignment === 'left'" class="text-with-image__wrapper text-with-image--left">
    <div class="media">
      <picture>
        <source *ngFor="let source of image.sizes" [media]="source.media" [srcset]="source.url" />
        <img [src]="image.src" [alt]="image.alt" />
      </picture>
    </div>
    <div class="main">
      <h2 class="main__headline">{{ heading }}</h2>
      <div class="main__body" [innerHTML]="intro"></div>
      <a [href]="cta.url" [title]="cta.label" *ngIf="cta" class="main__cta">
        {{ cta.label }}
      </a>
    </div>
  </div>
  <div *ngIf="alignment === 'right'" class="text-with-image__wrapper text-with-image--right">
    <div class="main">
      <h2 class="main__headline">{{ heading }}</h2>
      <div class="main__body" [innerHTML]="intro"></div>
      <a [href]="cta.url" [title]="cta.label" *ngIf="cta" class="main__cta" (click) = 'trackAnalytics($event)' >
        {{ cta.label }}
      </a>
    </div>
    <div class="media">
      <picture>
        <source *ngFor="let source of image.sizes" [media]="source.media" [srcset]="source.url" />
        <img [src]="image.src" [alt]="image.alt" />
      </picture>
    </div>
  </div>
</div>
