import { Injectable } from "@angular/core";
import { CustomerActivityService } from "./customer-activity.service";
import { UserService } from "./user.service";
import { ToDo } from "@components/planner/plan/to-do/to-do.component";
import { KontentDeliveryService } from "@services/kontent-delivery.service";
import { ContentItem } from "@kentico/kontent-delivery";
import { RebaDataService } from "./reba-data.service";

@Injectable({
    providedIn: 'root'
})

export class CaseManagerService {

    allTodos : Map<string, any> = new Map<string, any>();


    constructor(
        private cas: CustomerActivityService,
        private kontent: KontentDeliveryService,
        private userService: UserService,
        private rebaServce: RebaDataService) { 

        }

    async getClients(mgrId): Promise<ClientInfo[]> {
        const response =  await this.cas.getClients(mgrId);
        return response;
    }
    async getTodos(verticalCodenames?: string[]): Promise<Map<string,any>> {
        const deliveryClient = this.kontent.client;
        let query = verticalCodenames ?? ['budget_calculator_default'];
    
        const deliveryResponse = await deliveryClient.items().type('awp___workforce___to_do').toPromise()
    
        let relevantTodos = deliveryResponse.items;
        this.allTodos = new Map<string, any>();
        relevantTodos.forEach((item: ContentItem) => {
          this.allTodos.set(item.code.value, {
            snippet: item.snippet?.value ?? '',
            slug: item.code?.value ?? '',
            content: item.body?.value ?? '',
            title: item.title?.value ?? '',
            goals: item.todo_goal?.value.map(goal => goal.name) ?? []
          })
        });
    
        return this.allTodos;
      }


      getTodoBySlug(slug: string) {
        return this.allTodos.get(slug);
      }

      getClientScreening(clientId: string, screeningCode: string='awp_budget_calculato') {
        const response =  this.rebaServce.getScreening(screeningCode,'91210', null,null,true,clientId).take(1).toPromise();
        return response;
      }


}

export type ClientInfo = {
    displayName: string;
    userAccountEmail: string;
    pk: string;
    activeTodos: CmTodo[];
    archivedTodos: CmTodo[];
    completedTodos: CmTodo[];
    events: CmClientEvent[];
    expenses: FinancialRecord[];
    incomes: FinancialRecord[];
    ageGroup: string;
    awp_goals: string[];
    analyze_your_budget: {
      scenario: string;
      step: number;
    }
  }

export type FinancialRecord = {
  [key: string]: {
    amount: number;
    period: string;
  }
}

export type CmTodo = {
    slug: string;
    source: string;
    timestamp: string;
}

export type CmClientEvent ={
    [key: string]: {
        lang: string;
        source: string;
        timestamp: string;
        value: string;
    }
}