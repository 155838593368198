import { DOCUMENT, Location } from '@angular/common';
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ViewChild,
  OnDestroy,
  Input, HostListener, Inject
} from '@angular/core';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { DataService } from '@services/planner/data.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserService } from '@services/planner/user.service';
import { take } from 'rxjs/operators';
import { DataLayerService } from '@services/data-layer.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'ncoa-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss']
})

export class GlobalHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() inAssessmentTemplate: boolean = false;
  @Input() isLoggedIn: boolean = false;
  @Input() hasPartner: boolean = false;

  @Input() config: any = null;

  cookieName = 'fontsize';
  skipToMainMessage = false;
  activeMenuIndex = 0;
  skipLink: string;
  isCaseManager: boolean = false;

  navData: {
    bannerCTA: {
      prefix: string;
      url: string;
      text: string;
    };
    mainLogo: {
      cta: {
        url: string;
        text: string;
      };
      src: string;
      alt: string;
    };
    secondaryLogo: {
      cta: {
        url: string;
        text: string;
      };
      src: string;
      alt: string;
    };
    signUpCTA: {
      url: string;
      text: string;
    };
    signInCTA: {
      url: string;
      text: string;
    };
    menus: {
      url: string;
      text: string;
      isHome: boolean;
      isActive: boolean;
    }[];
    headerMenus: {
      url: string;
      text: string;
      isActive: boolean;
    }[];
    savedCTA: {
      url: string;
      text: string;
    };
    profileCTA: {
      url: string;
      text: string;
    };
    aboutUsCTA: {
      url: string;
      text: string;
    }
    socials: {
      type: string;
      url: string;
      text: string;
    }[];
  } = null;

  isSideBarOpen: boolean = false;
  isFontSizeIncreased: boolean = false;
  primaryActiveMenuIndex: number = 0;
  lastScrollTop: any = 0;
  isMobileView = false;

  currentLocation: string;

  @ViewChildren('menuElement') menuElements: QueryList<any>;
  @ViewChildren('scrollMenuElement') scrollMenuElements: QueryList<any>;
  @ViewChild('backgroundMover') backgroundMover: any;
  @ViewChild('scrollBackgroundMover') scrollBackgroundMover: any;
  @HostListener('window:beforeunload', ['$event'])
  saveLastUrlToSession(event) {
    if(window.sessionStorage) {
        window.sessionStorage.setItem("last_url", location.href);
        window.sessionStorage.setItem("last_url_index", history.length + "");
    } else {
      //session storage not supported
    }
  }

  constructor(
    private userService: UserService,
    private toolsConfig: ToolsPrimaryConfigService,
    private dataService: DataService,
    private cas: CustomerActivityService,
    private dataLayerService: DataLayerService,
    private router: Router,
    private location: Location,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.currentLocation = window.location.pathname !== this.toolsConfig.rootURL
      ? `?url=${window.location.href}` : '';
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.isLoggedIn = true;
        return this.isLoggedIn;
      }
      return false;
    });
  }

  ngOnInit(): void {
    this.skipLink = `${this.router.url.split('#')[0]}#main-content`;

    if (this.toolsConfig.currentConfig) {
      this.dataService.currentHeaderMenus.subscribe((headerMenus) => {
        this.navData = {
          ...this.toolsConfig.currentConfig.navData,
          headerMenus,
        };
        this.primaryActiveMenuIndex = this.navData.menus.findIndex((menu) => menu.isActive);
      });
    } else {
      this.navData = {
        bannerCTA: {
          prefix: 'Go to',
          url: '#',
          text: 'AgeWellAmerica.org',
        },
        mainLogo: {
          cta: {
            url: '#',
            text: 'AgeWell Planner',
          },
          src: '../../../assets/images/age-well-planner-logo-midnight-green.svg',
          alt: 'AgeWell Planner',
        },
        secondaryLogo: null,
        signUpCTA: {
          url: '#',
          text: 'Create Account',
        },
        signInCTA: {
          url: '#',
          text: 'Log In',
        },
        menus: [
          {
            url: '#',
            text: 'My Planner',
            isHome: true,
            isActive: true,
          },
          {
            url: '#',
            text: 'Finance',
            isHome: false,
            isActive: false,
          },
          {
            url: '#',
            text: 'Health & Wellness',
            isHome: false,
            isActive: false,
          },
          {
            url: '#',
            text: 'Lifestyle',
            isHome: false,
            isActive: false,
          },
          {
            url: '#',
            text: 'Housing',
            isHome: false,
            isActive: false,
          },
          {
            url: '#',
            text: 'Work',
            isHome: false,
            isActive: false,
          },
        ],
        headerMenus: [
          {
            url: '#',
            text: 'Recommended',
            isActive: false,
          },
          {
            url: '#',
            text: 'Retirement Income',
            isActive: false,
          },
          {
            url: '#',
            text: 'Budgeting',
            isActive: false,
          },
          {
            url: '#',
            text: 'Finance Benefits',
            isActive: true,
          },
        ],
        socials: [
          {
            type: 'facebook',
            url: '#',
            text: 'Open Facebook',
          },
          {
            type: 'twitter',
            url: '#',
            text: 'Open Twitter',
          },
          {
            type: 'instagram',
            url: '#',
            text: 'Open Instagram',
          },
          {
            type: 'linkedin',
            url: '#',
            text: 'Open LinkedIn',
          },
          {
            type: 'pinterest',
            url: '#',
            text: 'Open Pinterest',
          },
          {
            type: 'youtube',
            url: '#',
            text: 'Open YouTube',
          },
        ],
        savedCTA: {
          url: '#',
          text: 'Saved',
        },
        profileCTA: {
          url: '#',
          text: 'Profile',
        },
        aboutUsCTA: {
          url: '#',
          text: 'About',
        }
      }
    }
    this.isCaseManager = localStorage.getItem('isCaseManager') === 'true'; 
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const target = this.menuElements.toArray()[this.primaryActiveMenuIndex];
      const scrollTarget = this.scrollMenuElements.toArray()[this.primaryActiveMenuIndex];

      if (target) {
        this.moveMenuBackground(target, this.backgroundMover, this.menuElements);
      }

      if (scrollTarget) {
        this.moveMenuBackground(scrollTarget, this.scrollBackgroundMover, this.scrollMenuElements);
      }
      this.isMobileView = window.matchMedia("all and (max-width: 767px)").matches;
    }, 500);

    window.addEventListener('scroll', this.onScroll, true);

    this.onScroll();


    // Cookie check
    setTimeout(() =>{
      if (this.readCookie(this.cookieName) && this.readCookie(this.cookieName) === 'increased') {
        this.document.body.classList.add('font-increased');
        this.isFontSizeIncreased = true;

          this.moveMenuBgOnFontSizeChange();

      }
    },1000);


  }

  onScroll(): void {
    const nav: HTMLDivElement = document.querySelector('.scroll-planner-nav');
    const st = window.pageYOffset || document.documentElement.scrollTop;


    if (nav) {
      const bottomNav: HTMLDivElement = nav.querySelector('.bottom-nav');
      const navHeight = nav.clientHeight;


      if (st > 200 && window.innerWidth >= 1024) {
        nav.style.visibility = 'visible';
      } else if (st <= 200 && window.innerWidth >= 1024) {
        nav.style.visibility = 'hidden';
      }

      if (!bottomNav) {
        if (st > 350) {
          nav.style.transform = `translate(-50%, 0)`;
        } else {
          nav.style.transform = `translate(-50%, -110%)`;
        }
      } else {
        const botNavHeight = bottomNav.clientHeight;

        if (st > 350) {
          if (st > this.lastScrollTop) {
            // scroll down
            const offset = botNavHeight - navHeight;
            nav.style.transform = `translate(-50%, ${offset}px)`;
          } else {
            // scroll up
            nav.style.transform = `translate(-50%, 0)`;
          }
        } else {
          nav.style.transform = `translate(-50%, -110%)`;
        }
      }
    }

    this.lastScrollTop = st <= 0 ? 0 : st;
  }

  toggleSideBar(): void {
    this.isSideBarOpen = !this.isSideBarOpen;
    this.disableTabOnHiddenContent();
  }

  disableTabOnHiddenContent(){
    const mainContainer = document.querySelector(".main-content-parent-container");
    const skipLink = document.querySelector(".skip-link");
    if(this.isSideBarOpen){
      mainContainer.setAttribute('aria-hidden', 'true');
      mainContainer.setAttribute('tabindex', '-1');
      skipLink.setAttribute('aria-hidden', 'true');
      skipLink.setAttribute('tabindex', '-1');
      document.body.setAttribute('style', `overflow-y: hidden; position: fixed; width: 100%; top: 0px`)
    }else{
      mainContainer.removeAttribute('aria-hidden');
      mainContainer.removeAttribute('tabindex');
      skipLink.removeAttribute('aria-hidden');
      skipLink.setAttribute('tabindex', '0');
      document.body.setAttribute('style', ``)
    }
  }

  moveMenuBackground(target, receiver, elements): void {
    const { offsetLeft } = target.nativeElement || { offsetLeft: 0 };
    const { width } = target.nativeElement ? target.nativeElement.getBoundingClientRect() : { width: 0 };

    elements.forEach((element) => {
      element.nativeElement.classList.remove('is-active')
    });

    target.nativeElement.classList.add('is-active');

    receiver.nativeElement.style.transform = `translateX(${offsetLeft}px)`;
    receiver.nativeElement.style.width = `${width}px`;
  }

  onMenuHover(index, identifier = 'default'): void {
    const elements = identifier === 'default' ? this.menuElements : this.scrollMenuElements;
    const backgroundMover = identifier === 'default' ? this.backgroundMover : this.scrollBackgroundMover;
    const target = elements.toArray()[index];
    this.activeMenuIndex = index;


    if (target) {
      this.moveMenuBackground(target, backgroundMover, elements);
    }
  }

  moveMenuBgOnFontSizeChange(){
    const elements =this.menuElements ;
    const elementsScroll = this.scrollMenuElements;
    const backgroundMover = this.backgroundMover;
    const backgroundMoverScroll = this.scrollBackgroundMover;
    const target = elements.toArray()[this.primaryActiveMenuIndex];
    const targetScroll = elementsScroll.toArray()[this.primaryActiveMenuIndex];

    if (target) {
      this.moveMenuBackground(target, backgroundMover, elements);
    }
    if(targetScroll){
      this.moveMenuBackground(targetScroll,backgroundMoverScroll,elementsScroll);
    }
  }

  onMouseLeave(identifier = 'default'): void {
    const elements = identifier === 'default' ? this.menuElements : this.scrollMenuElements;
    const backgroundMover = identifier === 'default' ? this.backgroundMover : this.scrollBackgroundMover;
    const target = elements.toArray()[this.primaryActiveMenuIndex];

    if (target) {
      this.moveMenuBackground(target, backgroundMover, elements);
    }
  }

  logOut(): void {
    this.userService.logout();
    this.cas.announceLogout().subscribe(() => {
      if (window.location.pathname === this.toolsConfig.rootURL) {
        window.location.reload();
      } else {
        window.location.href = this.toolsConfig.rootURL;
      }
    });
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobileView = window.matchMedia("all and (max-width: 767px)").matches;
    if(!this.isMobileView){
      this.isSideBarOpen = false;
    }
  }


  onFontSizeToggleClick(event: Event) {
    event.stopPropagation()

    const link = event.currentTarget as HTMLElement;
    const { effect } = link.dataset;

    if ( effect === 'increase' ) {
      document.body.classList.add('font-increased');
      this.createCookie('fontsize', 'increased');
      this.isFontSizeIncreased = true;
      setTimeout(() =>{
        this.moveMenuBgOnFontSizeChange();
      },300);
    }
    else {
      document.body.classList.remove('font-increased');
      this.createCookie('fontsize', '');
      this.isFontSizeIncreased = false;
      setTimeout(() => {
        this.moveMenuBgOnFontSizeChange();
      },300);
    }

  }

  createCookie(key, value) {
    const cookie = `${escape(key)}=${escape(value)};`;
    document.cookie = cookie;
  }

  // @TODO: Create a service that will handle cookies
  readCookie(name): any {
    const key = `${name}=`;
    const cookies = document.cookie ? document.cookie.split(';') : [];

    for (let cookie of cookies) {
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(key) === 0) {
        return cookie.substring(key.length, cookie.length);
      }
    }
    return null;
  }

  trackEvent( item: Event) {
    const _element = item.currentTarget as HTMLElement;
    const _navItem = _element.textContent || _element.querySelector('img').alt;

    this.dataLayerService.push({
      'event': 'navClick',
      'navItem': _navItem
    }, false);
  }

  updateURL() {
    setTimeout(() => {
      this.location.replaceState(this.router.url.split('#')[0]);
    }, 500);
  }
}

