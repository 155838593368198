import * as dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KontentDeliveryService } from './kontent-delivery.service';
import { UrlService } from './url.service';
import { GlobalService } from './global.service';

import * as utc from 'dayjs/plugin/utc';

// Add utc
dayjs.extend(utc);

const COMPONENT_TYPES = {
  content_package: 'content_package',
  content_package_std: 'content_package__copy_',
  content_package_secondary: 'secondary_category_content_package',
  featured_page_block: 'cross_pathing_module__copy_',
  call_to_donate: 'call_to_donate',
  newsletter_signup_block: 'newsletter_signup_block',
  cross_pathing_module: 'cross_pathing_module',
  featured_tool_breaker: 'featured_page_block__copy_',
  category_page_hero: 'category_page_hero',
  category_pathing: 'category_pathing',
  category_pathing_std: 'category_pathing__standard',
  topic_different_audience: 'topic_different_audience',
  category_load_more: 'category_load_more',
  partner_grid: 'category_partner_grid',
  article_partner_grid: 'article_partner_grid',
  article_feedback: 'article_feedback',
  standard_partner_grid: 'standard_page___partner_grid',
  external_content_package: 'component___external_content_package',
  external_form:'component__form',
  partner_card_block: 'component___partner_card_block',
};

@Injectable({
  providedIn: 'root'
})
export class ComponentService {
  modularContent: any;
  modular_content: any;
  // Allows for external access
  componentTypes = COMPONENT_TYPES;

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private router: Router,
    private urlService: UrlService,
    private globalService: GlobalService,
  ) { }

  /**
   * Processes given list of raw components data from the Kontent API
   * @param {array} rawComponents - List of raw components data
   * @param {object} modularContent - The modular_content from the main Kontent API response.
   *                                  This contains the linked contents necessary to each components
   */
  processRawComponents(rawComponents, modularContent = null) {
    if ( modularContent ) {
      this.useModularContent(modularContent);
    }

    return rawComponents.map(rawComponent => {
      const type = this.extractComponentItemType(rawComponent);

      switch (type) {
        case COMPONENT_TYPES.content_package:
        case COMPONENT_TYPES.content_package_std:
        case COMPONENT_TYPES.content_package_secondary:
          return this.processContentPackageComponent(rawComponent);

        // case COMPONENT_TYPES.content_package_secondary:
        //   return this.processSecondaryContentPackageComponent(rawComponent);

        case COMPONENT_TYPES.featured_page_block:
          return this.processFeaturedPackageBlockComponent(rawComponent);

        case COMPONENT_TYPES.call_to_donate:
          return this.processCallToDonateComponent(rawComponent);

        case COMPONENT_TYPES.newsletter_signup_block:
          return this.processNewsletterSignupComponent(rawComponent);

        case COMPONENT_TYPES.featured_tool_breaker:
          return this.processFeaturedToolBreakerComponent(rawComponent);

        case COMPONENT_TYPES.category_page_hero:
          return this.processCategoryPageHeroComponent(rawComponent);

        case COMPONENT_TYPES.cross_pathing_module:
          return this.processAudiencePathingComponent(rawComponent);

        case COMPONENT_TYPES.topic_different_audience:
          return this.processTopicDifferentAudienceComponent(rawComponent);

        case COMPONENT_TYPES.category_pathing:
        case COMPONENT_TYPES.category_pathing_std:
          return this.processStandardCategoryPathingComponent(rawComponent);

        case COMPONENT_TYPES.partner_grid:
        case COMPONENT_TYPES.article_partner_grid:
        case COMPONENT_TYPES.standard_partner_grid:
          return this.processPartnersGridComponent(rawComponent);
        case COMPONENT_TYPES.external_content_package:
          return this.processExternalContentPackage(rawComponent);
        case COMPONENT_TYPES.external_form:
          return this.processExternalForm(rawComponent);
        case COMPONENT_TYPES.partner_card_block:
          return this.processPartnerCardBlock(rawComponent);
      }
    });
  }

  /**
   * Extracts the component from a raw item data from Kontent API
   * @param {object} rawItemData
   */
  extractComponentItemType(rawItemData) {
    return rawItemData.system.type;
  }

  useModularContent(modularContent) {
    this.modularContent = modularContent;
    return this;
  }

  /**
   * Extract selected contents from the modularContent data
   * that has been passed through a recent call to 'processRawComponents' method
   * @param {type} contentCodeNames - List of content codenames
   */
  getModularContents(contentCodeNames = []) {
    if (!this.modularContent) {
      return [];
    }

    return contentCodeNames.map(contentCodeName => (
      (contentCodeName in this.modularContent) ? this.modularContent[contentCodeName] : null
    ));
  }

  processContentPackageComponent(rawComponent) {
    let doSort = true;
    if(rawComponent.elements.content_population_method) {
      rawComponent.elements.content_population_method.value[0].codename === 'dynamically' ? true : false;
    }
    
    const { title, content }: any = this.kontentDeliveryService.extractItemElements(rawComponent, [ 'title', 'content' ])
    const linkedContents = this.getModularContents(content);

    const articles = this.processContentPackageComponentArticles(linkedContents, doSort);

    return {
      componentType: COMPONENT_TYPES.content_package,
      title,
      articles
    };
  }

  processExternalContentPackage(rawComponent) {
    const { title, url }: any = this.kontentDeliveryService.extractItemElements(rawComponent, [ 'title', 'url' ])

    return {
      componentType: COMPONENT_TYPES.external_content_package,
      title,
      url
    }
  }

  // processSecondaryContentPackageComponent(rawComponent) {
  //   const secondaryCPSet = JSON.parse(localStorage.getItem('secondary_cp_items'));
  //   const articles = this.processContentPackageComponentArticles(secondaryCPSet || [])

  //   localStorage.removeItem('secondary_cp_items');

  //   return {
  //     componentType: COMPONENT_TYPES.content_package,
  //     // title,
  //     articles
  //   };
  // }

  processContentPackageComponentArticles(rawArticles, doSort = true) {
    const articles = rawArticles
    .filter(article => article)
    .map(contentData => {
      const {
        title,
        primary_image,
        url,
        display_date,
        event_date_time,
        parent_page,
      }: any = this.kontentDeliveryService.extractItemElements(contentData, [
        'title',
        'primary_image',
        'url',
        'display_date',
        'event_date_time',
        'parent_page',
      ]);

      let type = '';
      let thisUrl = ''
      let thisCTAText = ''
      switch(contentData.system.type) {
        case 'standard_page':
          type = 'page';
          thisUrl = this.urlService.buildPageURL(url);

          if (parent_page && parent_page.length > 0) {
            const parentPage = this.modularContent[parent_page[0]];
            if (parentPage) {
              const parentUrl = parentPage.elements.url.value;
              thisUrl = this.urlService.buildPageURL(`${parentUrl}/${url}`);
            }
          }

          thisCTAText = 'Explore More';
          break;

        case 'standard_page__special':
          type = 'page';
          thisUrl = `/${url}`;
          thisCTAText = 'Explore More';
          break;
        case 'category_page_layout':
        case 'taxonomy_custom_content':
          type = 'category';
          thisUrl = this.urlService.buildCategoryURL(url);
          thisCTAText = 'Explore More';
        default: {
          type = event_date_time ? 'event': 'article';
          thisUrl = this.urlService.buildArticleURL(url);
          thisCTAText = type === 'event' ? 'View Event Details' : 'Read Article';
        }
      };

      const article: any = {
        type,
        title,
        cta: {
          url: thisUrl,
          text: thisCTAText
        }
      }

      article.image = (primary_image?.length) ? {
        url: primary_image[0].url,
        alt: primary_image[0].description,
        responsive: this.globalService.remodelImageSrc(primary_image[0].url, 'moreStories'),
      } : {};

      if (type === 'article' || type === 'event') {
        const last_modified_date = dayjs.utc(contentData.system.last_modified).format('MMM D, YYYY');
        article.eyebrow = !!display_date ? dayjs.utc(display_date).format('MMM D, YYYY') : last_modified_date;
        article.dateProp = new Date(article.eyebrow);
      }

      return article;
    });

    const articlesWithDate = articles.filter((a) => a.dateProp);
    const articlesWithoutDate = articles.filter((a) => !a?.dateProp);

    let sortedArticles = articlesWithDate;

    if (doSort) {
      sortedArticles = articlesWithDate.sort((a, b) => b.dateProp - a.dateProp);
    }

    return [...sortedArticles, ...articlesWithoutDate];
  }

  processFeaturedPackageBlockComponent(rawComponent) {
    const { background_color }: any = this.kontentDeliveryService.extractItemElements(rawComponent, [ 'background_color' ]);
    const {
      title,
      pathing_description,
      image_asset,
      internal_link,
      manual_link,
      feature_block_custom_cta

    }: any = this.kontentDeliveryService.extractItemElements(rawComponent, [
      'title',
      'pathing_description',
      'image_asset',
      'manual_link',
      'internal_link',
      'feature_block_custom_cta',
    ]);

    const componentType = COMPONENT_TYPES.featured_page_block;
    const background = background_color.length > 0 ? background_color[0].codename : 'green';
    let url = null;
    let headline = null;
    let description = null;
    let type = 'page'; // default to page
    let image = null;
    let orientation = background === 'blue' ? 'image-text' : 'text-image';
    let blockCTA = feature_block_custom_cta ?? null;
    if (internal_link.length > 0) {
      const [linkedPage] = this.getModularContents(internal_link);

      if (/article/ig.test(linkedPage.system.type)) {
        const {
          title,
          url: rawUrl,
          primary_image,
          seo_metadata_example_to_include_in_any_type__meta_description,
        } = linkedPage.elements;

        url = this.urlService.buildArticleURL(rawUrl.value);
        headline = title.value;
        description = seo_metadata_example_to_include_in_any_type__meta_description.value;
        image = primary_image.value.length > 0 ? {
          url: primary_image.value[0].url,
          alt: primary_image.value[0].description,
          responsive: this.globalService.remodelImageSrc(primary_image.value[0].url),
        } : null;
        type = 'article';
      } else if (/taxonomy_custom_content/ig.test(linkedPage.system.type)) {
        const {
          categories,
          audiences,
          category_page_url,
          header_image,
          taxonomy_description,
        } = linkedPage.elements;
        const category = categories.value[0].name;
        const audience = audiences.value[0].name;

        url = `/${category_page_url.value}`;
        headline = `${category} <span class="${background}">for ${audience}</span>`;
        description = taxonomy_description.value;
        image = header_image.value.length > 0 ? {
          url: header_image.value[0].url,
          alt: header_image.value[0].description,
          responsive: this.globalService.remodelImageSrc(header_image.value[0].url),
        } : null;
        type = 'page';
      } else if (/standard_page/ig.test(linkedPage.system.type)) {
        const {
          parent_page,
          url: rawUrl,
          title,
          primary_image,
          description: pageDesc,
        } = linkedPage.elements;

        url = this.urlService.buildPageURL(rawUrl.value);

        if (parent_page.value.length > 0) {
          const [parentPage] = this.getModularContents(parent_page.value);
          if (parentPage) {
            url = this.urlService.buildPageURL(`${parentPage.elements.url.value}/${rawUrl.value}`);
          }
        }

        headline = title.value;
        description = pageDesc.value;
        image = primary_image.value.length > 0 ? {
          url: primary_image.value[0].url,
          alt: primary_image.value[0].description,
          responsive: this.globalService.remodelImageSrc(primary_image.value[0].url),
        } : null;
        type = 'page';
      } 
      else if (/template___other_taxonomy/ig.test(linkedPage.system.type)){

        let primary_image = linkedPage.elements['image'];
        image = primary_image.value.length > 0 ? {
          url: primary_image.value[0].url,
          alt: primary_image.value[0].description,
          responsive: this.globalService.remodelImageSrc(primary_image.value[0].url),
        } : null;
        url = this.urlService.buildCategoryURL(linkedPage.elements['category_page_url'].value);
        type = 'page';
      }
    }

    headline = title || headline;
    description = pathing_description || description;
    url = url || manual_link;
    image = image_asset.length > 0 ? {
      url: image_asset[0].url,
      alt: image_asset[0].description,
      responsive: this.globalService.remodelImageSrc(image_asset[0].url),
    } : image;

    return {
      url,
      componentType,
      headline,
      description,
      type,
      image,
      background,
      orientation,
      blockCTA,
    }
  }

  processCallToDonateComponent(rawComponent) {
    // heading: string,
    // description: string,
    // form_desc: string,
    // photo: string,
    // caption: string
    const {
      blurb,
      description,
      headline,
      image,
    }: any = this.kontentDeliveryService.extractItemElements(rawComponent, [
      'headline', 'blurb', 'description', 'image',
    ]);

    return {
      componentType: COMPONENT_TYPES.call_to_donate,
      heading: headline,
      description,
      form_desc: blurb,
      photo: image ? image[0].url : '',
      caption: image ? image[0].description : '',
      responsive: image ? this.globalService.remodelImageSrc(image[0].url, 'callToDonate') : null,
    }
  }

  processExternalForm(rawComponent) {
    return {
      componentType: COMPONENT_TYPES.external_form,
      formType: rawComponent.elements.form_type,
      embedCode:rawComponent.elements.embed_code ?? '',
    }
  }

  processNewsletterSignupComponent(rawComponent) {
    const {
      headline,
      description,
      background_image,
      background_image_mobile,
      thank_you_headline,
      thank_you_description,
      terms_page_link,
      everyaction_form_url,
      email_error_text,
      field_error_text
    }: any = this.kontentDeliveryService.extractItemElements(rawComponent, [
      'headline',
      'description',
      'background_image',
      'background_image_mobile',
      'thank_you_headline',
      'thank_you_description',
      'terms_page_link',
      'everyaction_form_url',
      'email_error_text',
      'field_error_text'

    ]);

    let newsletterLink = '';

    // Extract terms and conditions link
    const termsPageData = this.getModularContents(terms_page_link)[0];

    if ( termsPageData ) {
      const { url, parent_page }: any = this.kontentDeliveryService.extractItemElements(termsPageData);

      if ( parent_page.length ) {
        const termsParentPageData = this.getModularContents(parent_page)[0];
        const { url: parentURL }: any = this.kontentDeliveryService.extractItemElements(termsParentPageData);
        newsletterLink += `/page/${parentURL}/${url}`;
      }
      else {
        newsletterLink += `/page/${url}`;
      }
    }

    return {
      componentType: COMPONENT_TYPES.newsletter_signup_block,
      heading: headline,
      description,
      background: background_image[0].url,
      backgroundCaption: background_image[0].description,
      mobilebackground: background_image_mobile[0].url,
      newsletterLink,
      responsive: background_image ? this.globalService.remodelImageSrc(background_image[0].url, 'newsletter') : null,
      responsiveMobile: background_image_mobile ? this.globalService.remodelImageSrc(background_image_mobile[0].url, 'newsletter') : null,
      width: background_image[0].width,
      height: background_image[0].height,
      everyActionFormUrl : everyaction_form_url ?? '',
      emailErrorText : email_error_text ?? '',
      fieldErrorText : field_error_text ?? '',
    }
  }

  processAudiencePathingComponent(rawComponent) {
    const {
      title,
      audiences
    }: any = this.kontentDeliveryService.extractItemElements(rawComponent, [
      'title', 'audiences'
    ]);

    return {
      componentType: COMPONENT_TYPES.cross_pathing_module,
      audience: {
        heading: title,
        links: audiences.map(({ name, codename }: any) => ({
          text: name,
          codename: codename,
          url: codename // Should be updated later on
        }))
      }
    }
  }

  processStandardCategoryPathingComponent(rawComponent, manual = false) {
    const {
      title,
      links
    }: any = this.kontentDeliveryService.extractItemElements(rawComponent, [
      'title', 'links'
    ]);

    const pathLinks = links.map(link => {
      const linkData = this.getModularContents([link])[0];
      const linkType = linkData.system.type;
      let url = '';
      let title = '';
      if (/article/ig.test(linkType)) {
        url = this.urlService.buildArticleURL(linkData.elements.url.value);
        title = linkData.elements.title.value;

        return {
          url,
          title,
          linkType
        };
      } else if (/standard_page/ig.test(linkType)) {
        title = linkData.elements.title.value;
        if(linkData.elements.parent_page.value[0]) {
          const parentPage = this.getModularContents([linkData.elements.parent_page.value[0]])[0];
          const parentPageUrl = parentPage.elements.url.value;
          url = this.urlService.buildPageURL(`${parentPageUrl}/${linkData.elements.url.value}`);
        } else {
          url = this.urlService.buildPageURL(linkData.elements.url.value);
        }
        return {
          url,
          title,
          linkType,
          parentCodeName: linkData.elements.parent_page.value[0],
          primaryURL: url,
        };
      } else if (/template___other_taxonomy/ig.test(linkType)) {
        let codename = linkData.elements.other_taxonomy.value[0].codename.replace(/_/g, '-');
        url = this.urlService.buildCategoryURL(codename);
        title = linkData.elements.other_taxonomy.value[0].name;

        return {
          url,
          title,
          linkType,
        };

      } else {
        if(linkData.elements.category_page_url)
          url = `/${linkData.elements.category_page_url.value}`;
        if(linkData.elements.page_slug)
          url = `/${linkData.elements.page_slug.value}`;



        if(manual && linkData?.elements?.categories?.value[0]?.name ){
          title = linkData.elements.categories.value[0].name;
        }else{
          title = linkData.system.name;
        }


        return {
          url,
          title,
          linkType
        };
      }
    });

    return {
      componentType: COMPONENT_TYPES.category_pathing,
      headline: title,
      categories: pathLinks
    }
  }

  processFeaturedToolBreakerComponent(rawComponent) {
    const {
      eyebrow,
      title,
      pathing_description,
      image_asset,
      breaker_type,
      call_to_action_text,
      external_link,
    }: any = this.kontentDeliveryService.extractItemElements(rawComponent, [
      'eyebrow',
      'title',
      'pathing_description',
      'image_asset',
      'breaker_type',
      'call_to_action_text',
      'external_link',
    ]);

    const variation = breaker_type.some(type => type.codename === 'benefits_checkup') ? 'form' : 'link';

    return {
      componentType: COMPONENT_TYPES.featured_tool_breaker,
      variation,
      eyebrow,
      headline: title,
      description: pathing_description,
      image: {
        url: image_asset[0].url,
        alt: image_asset[0].description,
        responsive: this.globalService.remodelImageSrc(image_asset[0].url, 'featuredToolBreaker'),
      },
      cta: {
        text: call_to_action_text,
        url: external_link,
      },
    };
  }

  // In this case, only description and image  will be extracted.
  // Other properties are dynamically processed within the category service such as headline, breadcrumbs, etc.
  processCategoryPageHeroComponent(rawComponent) {
    const {
      image,
      description
    }: any = this.kontentDeliveryService.extractItemElements(rawComponent, [
      'image', 'description'
    ]);

    return {
      componentType: COMPONENT_TYPES.category_page_hero,
      category: {
        description,
        photo: image[0].url,
        caption: image[0].description,
        responsive: this.globalService.remodelImageSrc(image[0].url, 'categoryPageHero'),
      }
    };
  }

  processPartnersGridComponent(rawComponent) {
    const {
      title,
      grid_items
    }: any = this.kontentDeliveryService.extractItemElements(rawComponent, [
      'title', 'grid_items'
    ]);

    if ( !grid_items.length ) {
      return null;
    }

    const gridItems = this.getModularContents(grid_items).map(rawGridItem => {
      const {
        name,
        image,
        url_slug,
        open_in_new_tab
      }: any = this.kontentDeliveryService.extractItemElements(rawGridItem, [
        'name', 'image', 'url_slug','open_in_new_tab'
      ]);
      return {
        name,
        logo: image.length ? image[0].url : '',
        url : url_slug ?? '',
        newTab: open_in_new_tab?.[0]?.name === 'Yes' ? true : false
      }
    });
    return {
      componentType: COMPONENT_TYPES.partner_grid,
      heading: title,
      partners: gridItems
    }
  }

  /**
   * Since there is no actual topic_different_audience type,
   * Data is coming from the cross_pathing_module
   */
  processTopicDifferentAudienceComponent(rawComponentData) {
    const {
      audiences,
    }: any = this.kontentDeliveryService.extractItemElements(rawComponentData, [
      'audiences'
    ]);

    const currentPathSegments = this.router.url.slice(1).split('/').slice(1); // Removing the audience segment

    return {
      componentType: this.componentTypes.topic_different_audience,
      topic: {
        heading: rawComponentData.system.name, // This can be overriden on consuming service/component to match FE
        links: audiences.map((audience: any) => ({
          url: '/' + [audience.codename, ...currentPathSegments].join('/'),
          text: audience.name
        }))
      }
    }
  }


  processPartnerCardBlock(rawComponent) {
    const groupHeader = rawComponent.elements.group_header.value ?? '';
    const badgeText = rawComponent.elements.badge_text.value ?? '';
    const blockColorScheme = rawComponent.elements.block_color_scheme.value[0].codename ?? '';
    const partners = rawComponent.elements.partners.value ?? [];
    const partnerCards = this.getModularContents(partners)

    return {
      componentType: COMPONENT_TYPES.partner_card_block,
      groupHeader,
      badgeText,
      partners: partnerCards,
      blockColorScheme,
    }
  }

}
