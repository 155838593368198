import { Injectable } from '@angular/core';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { take } from 'rxjs/operators';
import { AssessmentService } from './assessment.service';
import { ToolsPrimaryConfigService } from './tools-primary-config.service';
import { UtilityService } from './utility.service';
import { GlobalService } from '@services/global.service';

@Injectable({ providedIn: 'root' })
export class PlannerHomePageService {
  constructor(
    private tools: ToolsPrimaryConfigService,
    private assessment: AssessmentService,
    private kontent: KontentDeliveryService,
    private utility: UtilityService,
    private global: GlobalService,
  ) { }

  async getData() {
    const data = {};

    // vertical path
    let routes = this.tools.routes.filter((route) => route.type === 'vertical');
    let subtopicRoutes = this.tools.routes.filter((route) => route.type === 'vertical-subtopic-learn');
    if (routes.length === 0 && subtopicRoutes.length > 0) {
      routes = subtopicRoutes;
    }

    const routePaths = new Array(routes.length);

    routes.forEach((route, index) => {
      if (route.type === 'vertical') {
        const targetIndex = this.tools.awpTaxonomyCatalog.findIndex((taxonomy) => (taxonomy.codename === route.taxonomy.codename));
        routePaths[targetIndex] = route;
      } else {
        routePaths[index] = route;
      }
    });

    const paths = [];

    routePaths.forEach((route) => {
      const links = [{ text: 'Explore More', url: route.route }];

      if (route.type === 'vertical') {
        const benefitRoute = this.tools.routes.find((bRoute) => (
          bRoute.type === 'vertical-benefits' &&
          bRoute.codename === route.codename
        ));

        if (benefitRoute) {
          links.push({
            text: 'Find Benefits',
            url: benefitRoute.route,
          });
        }
      }

      paths.push({
        title: route.text,
        links,
      });
    });

    if (!this.tools.singularVerticalTopic) {
      paths.push({
        title: 'Explore All Benefits',
        links: [{
          text: 'Find Benefits',
          url: (this.tools.routes.find((route) => (
            route.type === 'all-benefits'
          )) || { route: null }).route,
        }],
      });
    }

    data['verticalPath'] = {
      heading: `Explore the ${this.tools.rawConfig.elements.tool_title.value}`,
      items: paths,
    };

    // feature content block
    if(this.tools.rawConfig.elements?.feature_content_blocks?.value?.length > 0){
      let featureContentBlockList = this.tools.rawConfig.elements.feature_content_blocks.value;
      let blocks = [];
      featureContentBlockList.forEach((contentBlock, index) => {
        let rawFeatureContentBlock = this.tools.modular_content[contentBlock];
        if(rawFeatureContentBlock){
          const {
            title,
            description,
            image_asset,
            is_present,
            cta_text,
            cta_link,
            background_color
          }: any = this.kontent.extractItemElements(rawFeatureContentBlock, [
            'title',
            'description',
            'image_asset',
            'is_present',
            'cta_text',
            'cta_link',
            'background_color'
          ]);

          // Background Color
          const backgroundColor = background_color.length > 0
              ? background_color[0].codename
              : 'green';

          // Orientation
          const orientation = index % 2 !== 0  ? 'image-text' : 'text-image';

          // Button Configuration
          let isButtonPresent = (is_present[0].codename === 'true');
          let buttonCTALink = '', buttonCTAText = '';
          if (isButtonPresent) {
            buttonCTAText = cta_text;
            buttonCTALink = cta_link;
          }

          // Remodel Image
          let image = {
            url: image_asset[0].url,
            alt: image_asset[0].description,
            responsive: this.global.remodelImageSrc(image_asset[0].url),
          }

          blocks.push({
            componentType: 'featured_content_block',
            title,
            description,
            image,
            isButtonPresent,
            buttonCTAText,
            buttonCTALink,
            orientation,
            backgroundColor,
          });
        }
      });
      data['featureContentBlock'] = {
        blocks
      }
    }

    // recommended content
    const userProfileId = this.tools.rawConfig.elements.profile_assessment_id.value;
    const response = await this.assessment.getUserProfile(userProfileId)
      .pipe(take(1))
      .toPromise();

    if (this.tools.rawConfig.elements.recommended_content.value.length > 0) {
      const linkedCardGrid = this.tools.modular_content[this.tools.rawConfig.elements.recommended_content.value[0]];
      const heading = linkedCardGrid.elements.headline.value;
      const intro = linkedCardGrid.elements.subhead.value;
      const recommendedCards = [];
      const promoCards = [];

      linkedCardGrid.elements.promo_cards.value.map((codename) => {
        const res = this.tools.modular_content[codename];

        if (res) {
          const background = {
            url: res.elements.background_image.value[0].url,
            alt: res.elements.background_image.value[0].description,
          };
          const eyebrow = res.elements.eyebrow.value;
          const heading = res.elements.headline.value;
          const cta = {
            label: res.elements.cta_text.value,
            url: res.elements.cta_manual_external_link.value,
          };

          if (res.elements.cta_internal_link.value.length > 0 &&
              this.tools.modular_content[res.elements.cta_internal_link.value[0]]) {
            const e = this.tools.modular_content[res.elements.cta_internal_link.value[0]].elements;
            const s = this.tools.modular_content[res.elements.cta_internal_link.value[0]].system;

            switch (s.type) {
              case 'ncoa_article_content': {
                cta.url = `${this.tools.rootURL}/resource/${e.url.value}`;
                break;
              }
              case 'awa_benefits_tool___vertical_topic': {
                cta.url = (this.tools.routes.find((route) => (
                  route.type === 'vertical-topic' &&
                  route.codename === s.codename
                )) || { route: null }).route;
                break;
              }
              case 'awa_benefits_tool___vertical': {
                cta.url = (this.tools.routes.find((route) => (
                  route.type === 'vertical' &&
                  route.codename === s.codename
                )) || { route: null }).route;
                break;
              }
              case 'standard_page': {
                cta.url = `${this.tools.rootURL}/pages/${e.url.value}`;
                break;
              }
              case 'awa_benefits_tool___vertical_subtopic': {
                cta.url = this.tools.routes.find((route) => (
                  route.type === 'vertical-subtopic-learn' &&
                  route.codename === s.codename
                )).route;
                break;
              }
            }
          }

          if (cta.url) {
            promoCards.push({
              type: 'promo',
              background,
              eyebrow,
              heading,
              cta,
            });
          }
        }
      });

      const employmentStatus = this.tools.customerActivity?.finances?.employmentStatus;
      const areasTopOfMind = this.tools.customerActivity?.basic?.areasTopOfMind;
      let resourcesRelatedToRetirement = this.tools.customerActivity?.basic?.resourcesRelatedToRetirement;
          resourcesRelatedToRetirement = typeof resourcesRelatedToRetirement == 'string' ? [resourcesRelatedToRetirement] : resourcesRelatedToRetirement;
      const isCurrentlyReceivingBenefits = this.tools.customerActivity?.basic?.isCurrentlyReceivingBenefits;
      const financialConcerns = this.tools.customerActivity?.basic?.financialConcerns;
      const currentMedicarePlans = this.tools.customerActivity?.basic?.currentMedicarePlans;
      const currentlyEnrolledMedicare = response?.profile?.currentlyEnrolledMedicare;

      const getManualCards = async () => {
        const resArr = linkedCardGrid.elements.cards.value.map((codename) => this.tools.modular_content[codename]);

        resArr.forEach((res) => {
          switch (res.system.type) {
            case 'ncoa_article_content': {
              // article
              if (res.elements[this.tools.toolTaxonomyCodename].value.length > 0) {
                const div = document.createElement('div');
                      div.innerHTML = res.elements.body_ckeditor.value;
                const text = div.innerText;

                const heading = res.elements.title.value;
                const intro = `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`;
                const url = `${this.tools.rootURL}/resource/${res.elements.url.value}`;

                const taxonomies = res.elements[this.tools.toolTaxonomyCodename].value.map((taxonomy) => taxonomy.codename);
                const verticals = taxonomies.map((taxonomy) => this.tools.getVertical(taxonomy).codename);
                let vertical = this.utility.arrayMode(verticals);
                    vertical = this.tools.awpTaxonomyCatalog.find((v) => v.codename === vertical);

                if (res.elements.awp_trusted_expert.value.length > 0) {
                  // trusted experts
                  recommendedCards.push({
                    id: res.system.id,
                    type: 'referral',
                    eyebrow: `${vertical.name} Expert`,
                    heading,
                    intro,
                    cta: {
                      label: 'View Details',
                      url,
                    },
                    photo: {
                      url: res.elements.expert_logo.value[0].url,
                      alt: res.elements.expert_logo.value[0].description,
                    },
                    bookmark: true,
                  });
                } else {
                  // resource
                  recommendedCards.push({
                    id: res.system.id,
                    type: 'article',
                    breadcrumb: `${vertical.name} / Resource`,
                    heading,
                    intro,
                    cta: {
                      label: 'Read Resource',
                      url,
                    },
                  });
                }
              }
              break;
            }
            case 'awa_benefits_tool___card__tool_': {
              // tool

              
              if (res.elements.tool.value.length > 0) {
                const completedAssessments = this.tools.customerActivity.basic?.completedAssessments || [];
                const { elements: toolElements, system: s } = this.tools.modular_content[res.elements.tool.value[0]];

                const heading = res.elements.headline.value;
                const photo = {
                  url: res.elements.card_image.value[0].url,
                  alt: res.elements.card_image.value[0].description,
                };
                let cta = {};
                const completedSurveys = JSON.parse(localStorage.getItem("screeningAnswers") ?? "{}");
                if(s.type ==='awp__subtopic_vertical_landing_page'){
                  const card = res.elements
                  const subTopic = this.tools.modular_content[card.tool.value[0]]

                  const path:string[] = [];

                  const vertical = this.tools.getVertical(subTopic.elements.awp_verticals__topics__and_subtopics.value[0].codename) //get the vertical codename
                  const verticalContent = this.tools.modular_content[vertical.codename]; // need this in case the vertical name and url slug are different (i.e workforce is /employment/)

                  const topic = this.tools.modular_content[subTopic.elements.awp_verticals__topics__and_subtopics.value[0].codename]
                  path.push('age-well-planner') //root url
                  path.push(verticalContent.elements.url_slug.value) //vertical
                  path.push(topic.elements.url_slug.value) //topic
                  path.push(toolElements.url_slug.value) //subtopic
                  
                  const assessment = this.tools.modular_content[toolElements.associated_tool.value[0]].elements.screening_code.value

                  cta = {label: completedSurveys[assessment]?.completed ? 'View Your Plan' : 'Start Assessment',
                  url: path.join('/')}
                }
                else if(s.type==='awa_benefits_tool___vertical'){

                  const card = res.elements
                  const vertical = this.tools.modular_content[card.tool.value[0]]

                  const path: string[] = [];
                  path.push('age-well-planner') //root url
                  path.push(vertical.elements.url_slug.value) //vertical
                  

                  const assessment = this.tools.modular_content[toolElements.associated_tool.value[0]].elements.screening_code.value

                  cta = {label: completedSurveys[assessment]?.completed ? 'View Your Plan' : 'Start Assessment',
                  url: path.join('/')}
                }
                else{
                cta = {
                  label: completedAssessments.find((assessment) => assessment === toolElements.assessment_id.value) ? 'View Results' : 'Start Assessment',
                  url: this.tools.routes.find((route) => (
                    route.assessmentID === toolElements.assessment_id.value
                  )).route,
                };
              }

                switch (toolElements?.assessment_id?.value) {
                  case 'generalAssessment':
                  case 'generalAssessmentAWP': {
                    recommendedCards.push({
                      id: s.id,
                      linkedID: s.id,
                      linkedCodename: s.codename,
                      type: 'tool',
                      heading,
                      cta,
                      photo,
                      variation: 'tools-retirement-single-col',
                    });
                    break;
                  }
                  default: {
                    recommendedCards.push({
                      id: s.id,
                      linkedID: s.id,
                      linkedCodename: s.codename,
                      type: 'tool',
                      heading,
                      cta,
                      photo,
                      variation: 'tools-medicare-single-col',
                      theme: 'dark',
                    });
                    break;
                  }
                }
              }
              break;
            }
            case 'awa_benefits_tool___vertical_subtopic': {
              // subtopic
              const subTopicRoute = this.tools.routes.find((route) => (
                route.type === 'vertical-subtopic-learn' &&
                route.codename === res.system.codename
              ));

              if (subTopicRoute) {
                const topicRoute = this.tools.routes.find((route) => (
                  subTopicRoute.parentTopic &&
                  route.codename === subTopicRoute.parentTopic
                ));

                if (topicRoute) {
                  const verticalRoute = this.tools.routes.find((route) => (
                    subTopicRoute.parentVertical &&
                    route.codename === subTopicRoute.parentVertical
                  ));

                  const eyebrow = `${verticalRoute.taxonomy.name} / ${topicRoute.text}`;
                  const heading = res.elements.title.value;
                  const cta = {
                    label: 'Learn More',
                    url: subTopicRoute.route,
                  };

                  recommendedCards.push({
                    id: res.system.id,
                    type: 'subtopic',
                    eyebrow,
                    heading,
                    cta,
                  });
                }
              }
              break;
            }
          }
        });

        promoCards.forEach((promoCard, index) => {
          const pos = (index + 1) * 3;
          if ((pos - 1) <= recommendedCards.length) {
            recommendedCards.splice((pos - 1), 0, promoCard);
          }
        });

        if (recommendedCards.length > 0) {
          return {
            location: false,
            heading,
            intro,
            cards: recommendedCards,
          };
        } else {
          return null;
        }
      };

      let personalizationParam;
      if (employmentStatus) {
        personalizationParam = `${employmentStatus.toLowerCase()}`;
      }

      if (areasTopOfMind) {
        const strings = areasTopOfMind.map((string) => string.toLowerCase());
        personalizationParam = `${personalizationParam},${strings.join(',')}`;
      }

      if (resourcesRelatedToRetirement) {
        const strings = resourcesRelatedToRetirement.map((string) => string.toLowerCase());
        personalizationParam = `${personalizationParam},${strings.join(',')}`;
      }

      if (financialConcerns) {
        const strings = financialConcerns.map((string) => string.toLowerCase());
        personalizationParam = `${personalizationParam},${strings.join(',')}`;
      }

      if (currentMedicarePlans) {
        personalizationParam = `${personalizationParam},${currentMedicarePlans}`;
      }

      if (typeof isCurrentlyReceivingBenefits !== 'undefined' || typeof currentlyEnrolledMedicare !== 'undefined') {
        if (isCurrentlyReceivingBenefits || currentlyEnrolledMedicare) {
          personalizationParam = `${personalizationParam},currentlyenrolledmedicare_true`;
        } else {
          personalizationParam = `${personalizationParam},currentlyenrolledmedicare_false`;
        }
      }

      if (personalizationParam) {
        const content = await this.kontent.getItem(null, {
          'system.type[in]': 'ncoa_article_content,awa_benefits_tool___card__tool_,awa_benefits_tool___vertical_subtopic',
          'elements.awp_personalization_terms[any]': personalizationParam,
          'limit': 500,
        });

        if (content && content?.items && content.items.length > 0) {
          const { items, modular_content } = content;
          const completedAssessments = this.tools.customerActivity.basic?.completedAssessments || [];

          items.forEach((item) => {
            switch (item.system.type) {
              case 'ncoa_article_content': {
                const taxonomies = item.elements[this.tools.toolTaxonomyCodename].value;
                const verticals = taxonomies.map(({ codename }) => this.tools.getVertical(codename));
                const vertical = this.utility.arrayMode(verticals.map(({ name }) => name));

                const div = document.createElement('div');
                      div.innerHTML = item.elements.body_ckeditor.value;
                const text = div.innerText;
                const intro = `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`;

                if (item.elements.awp_trusted_expert.value.length === 0) {
                  // resource page
                  recommendedCards.push({
                    id: item.system.id,
                    type: 'article',
                    heading: item.elements.title.value,
                    intro,
                    cta: {
                      url: `${this.tools.rootURL}/resource/${item.elements.url.value}`,
                      label: 'Read Resource',
                    },
                    breadcrumb: vertical,
                  })
                } else {
                  // trusted expert page
                  recommendedCards.push({
                    id: item.system.id,
                    type: 'referral',
                    eyebrow: vertical,
                    heading: item.elements.title.value,
                    intro,
                    cta: {
                      label: 'View Details',
                      url: `${this.tools.rootURL}/resource/${item.elements.url.value}`,
                    },
                    bookmark: true,
                  });
                }
                break;
              }
              case 'awa_benefits_tool___card__tool_': {
                const linkedTool = modular_content[item.elements.tool.value[0]];

                recommendedCards.push({
                  id: item.system.id,
                  linkedID: linkedTool.system.id,
                  linkedCodename: linkedTool.system.codename,
                  type: 'tool',
                  heading: item.elements.headline.value,
                  intro: item.elements.description.value,
                  cta: {
                    label: completedAssessments.find((key) => key === linkedTool.elements.assessment_id.value)
                      ? 'View Results'
                      : 'Start Assessment',
                    url: (this.tools.routes.find((route) => (
                      route.assessmentID &&
                      route.assessmentID === linkedTool.elements.assessment_id.value
                    )) || { route: this.tools.rootURL }).route,
                  },
                  photo: item.elements.card_image.value.length > 0 ? {
                    url: item.elements.card_image.value[0].url,
                    alt: item.elements.card_image.value[0].description,
                  } : null,
                  variation: linkedTool.elements.assessment_id.value === 'generalAssessment' || linkedTool.elements.assessment_id.value === 'generalAssessmentAWP'
                    ? 'tools-retirement-single-col'
                    : 'tools-medicare-single-col',
                  theme: linkedTool.elements.assessment_id.value === 'generalAssessment' || linkedTool.elements.assessment_id.value === 'generalAssessmentAWP'
                    ? null
                    : 'dark',
                });

                break;
              }
              case 'awa_benefits_tool___vertical_subtopic': {
                const subTopic = this.tools.routes.find((route) => (
                  route.type === 'vertical-subtopic-learn' &&
                  route.codename === item.system.codename
                ));

                if (subTopic) {
                  const topic = this.tools.routes.find((route) => (
                    route.type === 'vertical-topic' &&
                    route.codename === subTopic.parentTopic
                  ));

                  if (topic) {
                    const vertical = this.tools.routes.find((route) => (
                      route.type === 'vertical' &&
                      route.codename === subTopic.parentVertical
                    ));

                    recommendedCards.push({
                      type: 'subtopic',
                      eyebrow: `${vertical.text} / ${topic.text}`,
                      heading: item.elements.title.value,
                      cta: {
                        label: 'Learn More',
                        url: subTopic.route,
                      },
                    });
                  }
                }

                break;
              }
            }
          });

          promoCards.forEach((promoCard, index) => {
            const pos = (index + 1) * 3;
            if ((pos - 1) <= recommendedCards.length) {
              recommendedCards.splice((pos - 1), 0, promoCard);
            }
          });

          if (recommendedCards.length > 0) {
            data['recommended3ColGrid'] = {
              location: false,
              heading,
              intro,
              cards: recommendedCards,
            };
          }
        } else {
          const recommended3ColGrid: any = await getManualCards();
          if (recommended3ColGrid) {
            data['recommended3ColGrid'] = recommended3ColGrid;
          }
        }
      } else {
        const recommended3ColGrid: any = await getManualCards();
        if (recommended3ColGrid) {
          data['recommended3ColGrid'] = recommended3ColGrid;
        }
      }
    }

    // find benefits
    if (this.tools.rawConfig.elements.find_benefits_headline.value) {
      const headline = this.tools.rawConfig.elements.find_benefits_headline.value;
      const description = this.tools.rawConfig.elements.find_benefits_subhead.value;
      const image = {
        sizes: this.global.remodelImageSrc(this.tools.rawConfig.elements.find_benefits_image.value[0].url, 'benefits'),
        src: this.tools.rawConfig.elements.find_benefits_image.value[0].url,
        alt: this.tools.rawConfig.elements.find_benefits_image.value[0].description,
      };
      const verticals = this.tools.awpTaxonomyCatalog.map(({ codename, name }) => ({ codename, name, benefits: [] }));

      const getUnique = (arr, key) => {
        return [...new Map(arr.map(item => [item[key], item])).values()]
      };

      this.tools.routes
        .filter((route) => (route.type === 'vertical'))
        .forEach((route) => {
          let overAllBenefits = [];

          const benefits = this.tools.modular_content[route.codename].elements.benefits_category.value;

          overAllBenefits = overAllBenefits.concat(benefits);

          this.tools.routes
            .filter((route) => (route.type === 'vertical-subtopic-learn'))
            .forEach((route) => {
              const benefits = this.tools.modular_content[route.codename].elements.benefits_category.value;
              overAllBenefits = overAllBenefits.concat(benefits);
            });

          const index = verticals.findIndex((vertical) => vertical.codename === route.taxonomy.codename);

          verticals[index].benefits = getUnique(overAllBenefits, 'codename');
        });


      data['findBenefits'] = {
        headline,
        description,
        image: {
          responsive: image.sizes,
          url: image.src,
          alt: image.alt,
        },
        verticals,
      };
    }

    if (this.tools.singularVerticalTopic) {
      // text with image
      if (this.tools.rawConfig.elements.image_with_text_promo.value.length > 0) {
        const promoKey = this.tools.rawConfig.elements.image_with_text_promo.value[0];
        const promo = this.tools.modular_content[promoKey];

        let cta;

        if (promo.elements.cta_text__optional_.value.trim().length > 0) {
          let url = promo.elements.cta_manual_external_link__optional_.value;

          if (promo.elements.cta_internal_link__optional_.value.length > 0) {
            let linkedContent = this.tools.modular_content[promo.elements.cta_internal_link__optional_.value[0]];

            if (linkedContent) {
              switch (linkedContent.system.type) {
                case 'ncoa_article_content': {
                  const slug = linkedContent.elements.url.value;
                  url = `${this.tools.rootURL}/resource/${slug}`;
                  break;
                }
                case 'awa_benefits_tool___vertical_subtopic': {
                  url = (this.tools.routes.find((route) => (
                    route.type === 'vertical-subtopic-learn' &&
                    route.codename === linkedContent.system.codename
                  )) || { route: null }).route;
                  break;
                }
              }
            }
          }

          if (url) {
            cta = {
              label: promo.elements.cta_text__optional_.value,
              url,
            };
          }
        }

        data['textImage'] = {
          alignment: 'left',
          heading: promo.elements.headline.value,
          intro: promo.elements.description.value,
          image: {
            sizes: this.global.remodelImageSrc(promo.elements.featured_image.value[0].url),
            src: promo.elements.featured_image.value[0].url,
            alt: promo.elements.featured_image.value[0].description,
          },
          cta,
        };
      }

      // manual multi-card 3-up
      if (this.tools.rawConfig.elements.resources_block.value.length > 0) {
        const key = this.tools.rawConfig.elements.resources_block.value[0];
        const component = this.tools.modular_content[key];

        const heading = component.elements.multi_card_heading.value;
        const cardKeys = component.elements.cards.value;

        const cards = await this.getManualMultiCards(cardKeys);

        data['resourceBlock'] = {
          heading,
          cards,
        };
      }

      // lead gen
      if (this.tools.rawConfig.elements.lead_gen.value.length > 0) {
        let isLeadGenAssessment = false;
        const key = this.tools.rawConfig.elements.lead_gen.value[0];
        const leadGen = this.tools.modular_content[key];

        let cta;

        if (leadGen.elements.assessment_id.value && leadGen.elements.cta_text.value) {
          isLeadGenAssessment = true;

          cta = {
            label: leadGen.elements.cta_text.value,
            url: `${this.tools.rootURL}/lead-gen?assessment=${leadGen.elements.assessment_id.value}&broker=${leadGen.elements.broker_type.value[0].codename}&url=${window.location.href}`,
          };
        }

        if (leadGen.elements.external_manual_cta_link.value && leadGen.elements.cta_text.value) {
          cta = {
            label: leadGen.elements.cta_text.value,
            url: leadGen.elements.external_manual_cta_link.value,
          };
        }

        if (leadGen.elements.internal_cta_link.value.length > 0 && leadGen.elements.cta_text.value) {
          const articleKey = leadGen.elements.internal_cta_link.value[0];
          const article = this.tools.modular_content[articleKey];

          if (article) {
            cta = {
              label: leadGen.elements.cta_text.value,
              url: `${this.tools.rootURL}/resource/${article.elements.url.value}`,
            };
          }
        }

        let icon;

        if (leadGen.elements.branding_image.value.length > 0) {
          icon = {
            src: leadGen.elements.branding_image.value[0].url,
            alt: leadGen.elements.branding_image.value[0].description
          };
        }

        const rawURL = new URL(window.location.href);
        let isLeadSent = rawURL.searchParams.get('lead') && rawURL.searchParams.get('lead') === 'sent';

        if (isLeadGenAssessment) {
          const assessmentID = leadGen.elements.assessment_id.value;
          const completedAssessments = this.tools.customerActivity?.basic?.completedAssessments || [];

          const flag = completedAssessments.find((id) => id === assessmentID);
          isLeadSent = !!flag;
        }

        data['leadGen'] = {
          eyebrow: leadGen.elements.eyebrow.value,
          heading: leadGen.elements.headline.value,
          intro: leadGen.elements.description.value,
          cta,
          photo: {
            src: leadGen.elements.left_image.value[0].url,
            alt: leadGen.elements.left_image.value[0].description,
          },
          icon,
        };
      }
    }

    return data;
  }

  async getManualMultiCards(cardKeys) {
    const cards = [];

    const responses = cardKeys.map((codename) => this.tools.modular_content[codename]);

    responses.forEach((item) => {
      if (item.system.type === 'ncoa_article_content') {
        const taxonomies = item.elements[this.tools.toolTaxonomyCodename].value.map((taxonomy) => taxonomy.codename);
        const verticals = taxonomies.map((taxonomy) => this.tools.getVertical(taxonomy).codename);
        let vertical = this.utility.arrayMode(verticals);
            vertical = this.tools.awpTaxonomyCatalog.find((v) => v.codename === vertical);

        if (vertical) {
          const div = document.createElement('div');
                div.innerHTML = item.elements.body_ckeditor?.value || '';
          const text = div.innerText;

          if (item.elements.awp_trusted_expert.value.length > 0) {
            cards.push({
              id: item.system.id,
              type: 'referral',
              eyebrow: `${vertical.name} Expert`,
              heading: item.elements.title.value,
              intro: `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`,
              cta: {
                label: 'View Details',
                url: `${this.tools.rootURL}/resource/${item.elements.url.value}`,
              },
              photo: item.elements.expert_logo.value.length > 0 ? {
                url: item.elements.expert_logo.value[0].url,
                alt: item.elements.expert_logo.value[0].description,
              } : null,
              bookmark: true,
              full: false,
            });
          } else {
            cards.push({
              id: item.system.id,
              type: 'article',
              heading: item.elements.title.value,
              intro: `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`,
              cta: {
                url: `${this.tools.rootURL}/resource/${item.elements.url.value}`,
                label: 'Read Resource',
              },
              breadcrumb: item.elements[this.tools.toolTaxonomyCodename].value.length > 0
                ? item.elements[this.tools.toolTaxonomyCodename].value[0].name
                : 'Resource',
            });
          }
        }
      }
    });

    return cards;
  }
}
