<div [ngClass]="{
  'article-feedback': true,
  'article-feedback--loading': loading
}">
  <div class="article-feedback__content">
    <div class="article-feedback__body">
      <div class="article-feedback__q-and-a">
        <span class="article-feedback__question">
          <span *ngIf="!voted && !choice">Was this helpful?</span>
          <span *ngIf="voted || choice">Thank you for your feedback!</span>
        </span>
        <div class="article-feedback__choices">
          <button
            [ngClass]="{
              'article-feedback__choice': true,
              'article-feedback__choice--active': choice === 'upvote'
            }"
            data-choice="upvote"
            attr.aria-label="Click to select Yes"
            (click)="onChoiceClick($event, 'upvote')">Yes</button>
          <button
            [ngClass]="{
              'article-feedback__choice': true,
              'article-feedback__choice--active': choice === 'downvote'
            }"
            data-choice="downvote"
            attr.aria-label="Click to select No"
            (click)="onChoiceClick($event, 'downvote')">No</button>
        </div>
      </div>
      <div class="article-feedback__result" *ngIf="votes >= 70">
        <strong class="article-feedback__votes">{{ votes | number : '1.0-0'}}%</strong> voted this helpful
      </div>
    </div>
  </div>
</div>
