import { Injectable } from '@angular/core';
import { KontentDeliveryService } from './kontent-delivery.service';
import { DataLayerService } from './data-layer.service';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

// Add utc
dayjs.extend(utc);

@Injectable({ providedIn: 'root' })
export class SearchService {
  articles: any[] = [];
  topics: any[] = [];
  events: any[] = [];
  authors: any[] = [];
  standardPages: any[] = [];
  otherPages: any[] = [];
  displayContent: any[] = [];
  totalItems: number = 0;

  constructor (
    private kontent: KontentDeliveryService,
    private dataLayerService: DataLayerService,
  ) { }

  async getAudiences() {
    return await this.kontent.getTaxonomiesAndCache('audiences').then(({ terms }) => terms);
  }

  async getList(query = null, audience = null, type = null) {
    this.dataLayerService.push({
      query: query || '',
      terms: {
        audience: audience || '',
      },
      contentType: type || '',
    }, false);

    this.articles = [];
    this.topics = [];
    this.events = [];
    this.authors = [];
    this.standardPages = [];
    this.otherPages = [];
    this.displayContent = [];

    let articles = [];
    let topics = [];
    let events = [];
    let authors = [];
    let standardPages = [];
    let otherPages = [];
    let displayContent = [];

    const projections = [
      'audiences', 'secondary_audiences', 'categories', 'secondary_categories', 'event_date_time', 'url', 'display_date',
      'primary_image', 'title', 'header_image', 'category_page_url',
      'author_name', 'author_biography', 'author_headshot', 'author_url_slug', 'parent_page',
      'body_ckeditor', 'body_text', 'taxonomy_description', 'description',
      'other', 'spanish_subcategory',
      'seo_metadata_example_to_include_in_any_type__meta_title', 'seo_metadata_example_to_include_in_any_type__meta_description',
      'image', 'include_in_search_'
    ];

    const contentTypes = [
      'ncoa_article_content', 'taxonomy_custom_content', 'author',
      'standard_page', 'standard_page__special', 'awa_benefits_tool_template___standard_page',
      'template___other_taxonomy'
    ];

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    const processData = (raw, modular_content) => {
      const removeNBSPs = (str) => {
        const re = new RegExp(String.fromCharCode(160), "g");
        return str.replace(re, " ");
      };

      const meta_title = raw.elements?.seo_metadata_example_to_include_in_any_type__meta_title?.value || '';
      const meta_description = raw.elements?.seo_metadata_example_to_include_in_any_type__meta_description?.value || '';
      const includeInSearch = raw.elements?.include_in_search_?.value.length > 0 ? raw.elements?.include_in_search_?.value[0].name : 'yes';

      switch (raw.system.type) {
        case 'ncoa_article_content': {
          const div = document.createElement('div');
                div.innerHTML = raw.elements.body_ckeditor.value;
          const body = removeNBSPs(div.innerText);
          const title = raw.elements.title.value;
          let audiences = raw.elements.audiences.value;
              audiences = audiences.concat(raw.elements.secondary_audiences.value);
              audiences = audiences.map(({ codename }) => codename);
              audiences = [...new Set(audiences)];

          if (!raw.elements.event_date_time.value) {
            const date = new Date(raw.elements.display_date.value || raw.system.last_modified);
            const dateString = dayjs.utc(raw.elements.display_date.value || raw.system.last_modified).format('MMM DD, YYYY');
            const image = raw.elements.primary_image.value.length > 0 ? {
              url: raw.elements.primary_image.value[0].url,
              alt: raw.elements.primary_image.value[0].description || title,
            } : {
              url: '../../../assets/images/content-package-coral-tablet.png',
              alt: title,
            };

            const cta = {
              url: `/article/${raw.elements.url.value}`,
              text: 'Read Article',
            };

            articles.push({
              type: {
                codename: 'article',
                name: 'Article',
              },
              audiences,
              date,
              eyebrow: dateString,
              title,
              body,
              image,
              cta,
              meta_title,
              meta_description,
              includeInSearch
            });
          } else {
            const date = new Date(raw.elements.display_date.value || raw.elements.event_date_time.value || raw.system.last_modified);
            const dateString = dayjs.utc(raw.elements.event_date_time.value || raw.elements.display_date.value || raw.system.last_modified).format('MMM DD, YYYY');
            const image = raw.elements.primary_image.value.length > 0 ? {
              url: raw.elements.primary_image.value[0].url,
              alt: raw.elements.primary_image.value[0].description || title,
            } : {
              url: '../../../assets/images/content-package-green-tablet.png',
              alt: title,
            };

            const cta = {
              url: `/article/${raw.elements.url.value}`,
              text: 'View Event Details',
            };

            events.push({
              type: {
                codename: 'event',
                name: 'Event',
              },
              audiences,
              date,
              eyebrow: dateString,
              title,
              body,
              image,
              cta,
              meta_title,
              meta_description,
              includeInSearch
            });
          }

          break;
        }
        case 'taxonomy_custom_content': {
          const title = raw.system.name;
          const body = raw.elements.taxonomy_description.value;
          const date = new Date(raw.system.last_modified);
          const image = raw.elements.header_image.value.length > 0 ? {
            url: raw.elements.header_image.value[0].url,
            alt: raw.elements.header_image.value[0].description || title,
          } : {
            url: '../../../assets/images/content-package-midnight-tablet.png',
            alt: title,
          };
          const cta = {
            url: `/${raw.elements.category_page_url.value}`,
            text: 'Explore More',
          };
          let audiences = raw.elements.audiences.value.map(({ codename }) => codename);

          topics.push({
            type: {
              codename: 'topic',
              name: 'Topic',
            },
            audiences,
            date,
            eyebrow: 'Topic',
            title,
            body,
            image,
            cta,
            meta_title,
            meta_description,
            includeInSearch
          });

          break;
        }
        case 'author': {
          const title = raw.elements.author_name.value;
          const div = document.createElement('div');
                div.innerHTML = raw.elements.author_biography.value;
          const body = removeNBSPs(div.innerText);
          const date = new Date(raw.system.last_modified);
          const image = raw.elements.author_headshot.value.length > 0 ? {
            url: raw.elements.author_headshot.value[0].url,
            alt: raw.elements.author_headshot.value[0].description || title,
          } : {
            url: '../../../assets/images/content-package-midnight-tablet.png',
            alt: title,
          };
          const cta = {
            url: `/author/${raw.elements.author_url_slug.value}`,
            text: 'Explore More',
          };
          let audiences = [];

          authors.push({
            type: {
              codename: 'author',
              name: 'Author',
            },
            audiences,
            date,
            eyebrow: 'Author',
            title,
            body,
            image,
            cta,
            meta_title,
            meta_description,
            includeInSearch
          });

          break;
        }
        case 'standard_page':
        case 'awa_benefits_tool_template___standard_page': {
          const title = raw.elements.title.value;
          const div = document.createElement('div');
                div.innerHTML = raw.elements.body_text.value;
          const body = removeNBSPs(div.innerText);
          const date = new Date(raw.system.last_modified);
          const image = raw.elements.primary_image.value.length > 0 ? {
            url: raw.elements.primary_image.value[0].url,
            alt: raw.elements.primary_image.value[0].description || title,
          } : {
            url: '../../../assets/images/content-package-midnight-tablet.png',
            alt: title,
          };

          let parentSlug;
          if (raw.elements.parent_page.value.length > 0) {
            const parentPageKey = raw.elements.parent_page.value[0];
            const parentPage = modular_content[parentPageKey];

            if (parentPage) {
              parentSlug = parentPage.elements.url.value;
            }
          }

          const isAWP = raw.system.type === 'awa_benefits_tool_template___standard_page';

          const cta = isAWP
            ? {
              url: parentSlug
                ? `/age-well-planner/pages/${parentSlug}/${raw.elements.url.value}`
                : `/age-well-planner/pages/${raw.elements.url.value}`,
              text: 'Explore More',
              newTab: true,
            } : {
              url: parentSlug
                ? `/page/${parentSlug}/${raw.elements.url.value}`
                : `/page/${raw.elements.url.value}`,
              text: 'Explore More',
              newTab: false,
            };

          standardPages.push({
            type: {
              codename: 'landing',
              name: 'Landing',
            },
            audiences: [],
            date,
            eyebrow: 'Page',
            title,
            body,
            image,
            cta,
            meta_title,
            meta_description,
            includeInSearch
          });

          break;
        }
        case 'standard_page__special': {
          const title = raw.elements.title.value;
          const body = raw.elements.description.value;
          const date = new Date(raw.system.last_modified);
          const image = raw.elements.primary_image.value.length > 0 ? {
            url: raw.elements.primary_image.value[0].url,
            alt: raw.elements.primary_image.value[0].description || title,
          } : {
            url: '../../../assets/images/content-package-midnight-tablet.png',
            alt: title,
          };

          const cta = {
            url: `/${raw.elements.url.value}`,
            text: 'Explore More',
          };

          standardPages.push({
            type: {
              codename: 'landing',
              name: 'Landing',
            },
            audiences: [],
            date,
            eyebrow: 'Page',
            title,
            body,
            image,
            cta,
            meta_title,
            meta_description,
            includeInSearch
          });

          break;
        } case 'template___other_taxonomy': {
          const title = meta_title;
          const body = raw.elements.description.value;
          const date = new Date(raw.system.last_modified);
          const image = raw.elements.image
            && raw.elements.image.value.length > 0 ? {
            url: raw.elements.image.value[0].url,
            alt: raw.elements.image.value[0].description || title,
          } : {
            url: '../../../assets/images/content-package-midnight-tablet.png',
            alt: title,
          };
          
          const slug = String(raw.system.name).toLowerCase().replace(/\s+/g, '-');
          const cta = {
            url: `/${slug}`,
            text: 'Explore More',
          };

          otherPages.push({
            type: {
              codename: 'other',
              name: 'News & Information',
            },
            audiences: [],
            date,
            eyebrow: 'Page',
            title,
            body,
            image,
            cta,
            meta_title,
            meta_description,
            includeInSearch
          });

          break;
        }
      };
    };

    const wait = (ms = 1000) => {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    };

    const pollingFunc = async (url) => {
      const response: any = await this.kontent.getAPIState(url);

      const items = response && response?.items && response.items.length > 0
        ? response.items : [];
      const nextPageUrl = response && response?.pagination && response.pagination?.next_page &&
        response.pagination.next_page.trim().length > 0
          ? response.pagination.next_page : null;

      return {
        items,
        modular_content: response.modular_content,
        nextPageUrl,
      };
    };

    const response: any = await this.kontent.getItem(null, {
      'system.type[in]': contentTypes.join(','),
      'elements': projections.join(','),
      'limit': 1300,
      'depth': 2,
    });

    if (response) {
      response.items.forEach((item) => processData(item, response.modular_content));

      if(response.pagination?.next_page &&
        response.pagination.next_page.trim().length > 0) {
        let nextPageUrl = response.pagination.next_page;

        while (nextPageUrl) {
          await wait();

          const returnedValue: any = await pollingFunc(nextPageUrl);

          returnedValue.items.forEach((item) => processData(item, returnedValue.modular_content));
          nextPageUrl = returnedValue.nextPageUrl;
        }
      }
    }

    this.articles = [...articles];
    this.topics = [...topics];
    this.events = [...events];
    this.authors = [...authors];
    this.standardPages = [...standardPages];
    this.otherPages = [...otherPages];

    if (audience) {
      articles = audience === 'all' ? articles : articles.filter(({ audiences }) => audiences.indexOf(audience) !== -1);
      topics = audience === 'all' ? topics : topics.filter(({ audiences }) => audiences.indexOf(audience) !== -1);
      events = audience === 'all' ? events : events.filter(({ audiences }) => audiences.indexOf(audience) !== -1);
      authors = audience === 'all' ? authors : [];
      standardPages = audience === 'all' ? standardPages : [];
    }

    if (type) {
      articles = type === 'all' || type === 'article' ? articles : [];
      topics = type === 'all' || type === 'topic' ? topics : [];
      events = type === 'all' || type === 'event' ? events : [];
      authors = type === 'all' || type === 'author' ? authors : [];
      standardPages = type !== 'all' ? [] : standardPages;
    }

    displayContent = [...articles, ...topics, ...events, ...authors, ...standardPages].sort((a, b) => b.date - a.date);

    if (query) {
      let arrOfQuery: string [] = query.split(' ')
      displayContent = displayContent.filter(({ title, meta_title, meta_description, includeInSearch }) => {
        return ((arrOfQuery.every(e => title.toLowerCase().includes(e.toLowerCase())) ||
        arrOfQuery.every(e => meta_title.toLowerCase().includes(e.toLowerCase())) ||
        arrOfQuery.every (e => meta_description.toLowerCase().includes(e.toLowerCase()))) &&
        includeInSearch.toLowerCase() === 'yes')
      });
    }

    this.displayContent = displayContent;

    this.totalItems = this.displayContent.length;
    return this.displayContent.slice(0, 18);
  }

  getMoreData(skip) {
    return this.displayContent.slice(skip, skip + 18);
  }

  searchData(query = null, audience = null, type = null) {
    this.dataLayerService.push({
      query: query || '',
      terms: {
        audience: audience || '',
      },
      contentType: type || '',
    }, false);

    this.displayContent = [];

    let articles = [...this.articles];
    let topics = [...this.topics];
    let events = [...this.events];
    let authors = [...this.authors];
    let standardPages = [...this.standardPages];
    let otherPages = [...this.otherPages];
    let displayContent = [];

    if (audience) {
      articles = audience === 'all' ? articles : articles.filter(({ audiences }) => audiences.indexOf(audience) !== -1);
      topics = audience === 'all' ? topics : topics.filter(({ audiences }) => audiences.indexOf(audience) !== -1);
      events = audience === 'all' ? events : events.filter(({ audiences }) => audiences.indexOf(audience) !== -1);
      authors = audience === 'all' ? authors : [];
      standardPages = audience === 'all' ? standardPages : [];
      otherPages = audience === 'all' ? otherPages : [];
    }

    if (type) {
      articles = type === 'all' || type === 'article' ? articles : [];
      topics = type === 'all' || type === 'topic' ? topics : [];
      events = type === 'all' || type === 'event' ? events : [];
      authors = type === 'all' || type === 'author' ? authors : [];
      standardPages = type !== 'all' ? [] : standardPages;
      otherPages = type !== 'all' ? [] : otherPages;
    }

    const removeSymbols = (string) => {
      return string.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    };

    displayContent = [...articles, ...topics, ...events, ...authors, ...standardPages, ...otherPages].sort((a, b) => b.date - a.date);

    if (query) {
      let arrOfQuery: string [] = query.split(" ")
      displayContent = displayContent.filter(({ title, meta_title, meta_description, includeInSearch }) => {
        return ((arrOfQuery.every(e => removeSymbols(title).toLowerCase().includes(removeSymbols(e).toLowerCase())) ||
          arrOfQuery.every(e => removeSymbols(meta_title).toLowerCase().includes(removeSymbols(e).toLowerCase())) ||
          arrOfQuery.every(e => removeSymbols(meta_description).toLowerCase().includes(removeSymbols(e).toLowerCase()))) &&
          includeInSearch.toLowerCase() === 'yes')
      });
    }

    this.displayContent = displayContent;

    this.totalItems = this.displayContent.length;

    return this.displayContent.slice(0, 18);
  }
  sanitizeQuery(string){
    return string.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  }
}
