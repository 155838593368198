import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'external-content-package',
  templateUrl: './external-content-package.component.html',
  styleUrls: ['./external-content-package.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ExternalContentPackage implements OnInit {
  @Input() url: string;
  @Input() title?: string;

  content: string;

  private readonly ncoaApiBaseUrl: string;

  constructor(private http: HttpClient,
    private sanitizer: DomSanitizer) {
      this.ncoaApiBaseUrl = (environment as any)?.ncoaApiBaseUrl || '';
    }

  async ngOnInit() {
    this.http.post(`${this.ncoaApiBaseUrl}/location/getExternalContent`, { url: this.url }).subscribe((data: any) => {
        this.content = this.sanitizer.bypassSecurityTrustHtml(data) as string;
    });
  }

}
