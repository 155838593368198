import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-breakdown-plans',
  templateUrl: './breakdown-plans.component.html',
  styleUrls: ['./breakdown-plans.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreakdownPlansComponent implements OnInit {

  @Input() heading: string;
  @Input() description: string;
  @Input() zipcode: string;
  @Input() tooltip: string;
  @Input() planCount: string;
  @Input() noteHeading: string;
  @Input() noteText: string;
  @Input() leadGenConfirmed: {
    show: boolean
  };

  @Input() noteCTA: {
    url: string,
    label: string
  };

  @Input() planA: {
    lowCost: [{
      heading: string,
      text: string
    }],
    medianCost: [{
      heading: string,
      text: string
    }],
    highCost: [{
      heading: string,
      text: string
    }]
  };

  @Input() planB: {
    lowCost: [{
      heading: string,
      text: string
    }],
    medianCost: [{
      heading: string,
      text: string
    }],
    highCost: [{
      heading: string,
      text: string
    }]
  };

  @Input() considerationHeading: string;
  @Input() considerations: [
    {
      heading: string,
      tooltip: string,
      title?: {
        typeA?: string,
        typeB?: string,
      },
      description: {
        typeA: string,
        typeB: string
      }
    }
  ];

  @Input() showOnlyConsiderations: boolean = false;

  @Input() disclaimer: string;

  activeElementTooltip: Element;

  constructor(private dataLayerService: DataLayerService) { }

  ngOnInit(): void {


  }

  ngAfterViewInit(){
    setTimeout( ()=>{
      const _currentPlan = document.querySelector('.plan--items.type-a');
      if (_currentPlan) {
        _currentPlan.classList.add('is-active');
        this.stickyControl();
      }
    },200);
  }

  stickyControl(): void {

    const _controls = document.querySelector('.plans--control');


    window.addEventListener('scroll', () => {
      let _scrollStart = document.querySelector('.scroll-start').getBoundingClientRect();
      let _scrollEnd = document.querySelector('.scroll-end').getBoundingClientRect();

      if( _scrollStart.top <= 0 && _scrollEnd.top > 0 ) {
        _controls.classList.add('sticky');
      } else if( _scrollStart.top > 0 ) {
        _controls.classList.remove('sticky');
        _controls.classList.remove('sticky-hidden');
      } else if( _scrollEnd.top <= 0 && _scrollStart.top <= 0  ) {
        _controls.classList.add('sticky-hidden');
      }

      if( _scrollEnd.top > 0 ) {
        _controls.classList.remove('sticky-hidden');
      }

    });
  }

  toogleConsiderationTooltip( event: Event ): void {
    let _trigger = event.currentTarget as Element | any;
    let _sibling = _trigger.nextElementSibling;
    let _parent = _trigger.parentElement as HTMLElement;

    this.activeElementTooltip = _trigger;

    let _toolTip = document.querySelectorAll('.icon .tooltip');
    _toolTip.forEach( _item => {
      _item.classList.remove('is-open');
    });

    _sibling.classList.toggle('is-open');

    // get the bounding position of the icon
    const { right: iconRight } = _trigger?.getBoundingClientRect();

    // get the tooltip element
    const tooltip = _parent.querySelector<HTMLElement>('.tooltip');

    // get the bounding position of the tooltip element
    const { right: tooltipRight } = tooltip?.getBoundingClientRect();

    // added an offset to position the arrow in the middle of the icon
    const offset = 2;

    // set the position of the tooltip arrow
    tooltip?.style.setProperty('--right', `${Math.ceil(tooltipRight - iconRight - offset)}px`);

    this.activeElementTooltip = _trigger;
    this.fillCurrentTooltip(true);
    this.dataLayerService.push({
      'event': 'medicareResults',
      'componentType': 'info click',
      'clickText': _trigger.dataset.title
     });
  }

  closeConsiderationTooltip( event: Event ): void {
    let _closeTooltip = event.currentTarget as Element;
    let _closeTooltipEl = _closeTooltip.parentElement;
    _closeTooltipEl.classList.remove('is-open');

    this.fillCurrentTooltip(false);
  }

  toggleControls( event: Event ): void {
    let _control = (event.currentTarget as Element);
    let _controlTrigger = document.querySelectorAll('.plans--control a');

    _controlTrigger.forEach( _item => {
      _item.classList.remove('is-active');
    });

    _control.classList.add('is-active');

    let _allPlans = document.querySelectorAll('.plan--items');
    _allPlans.forEach( _item => {
      _item.classList.remove('is-active');
    });

    let _allConsiderations = document.querySelectorAll('.consideration--detail');

    _allConsiderations.forEach( _item => {
      _item.classList.remove('is-open');
    });

    let _activePlan = _control.attributes['data-type']['nodeValue'];
    let _planIsActive = document.querySelectorAll( `.${ _activePlan }` )[0];
    _planIsActive.classList.add('is-active');

    let _considerationIsActive = document.querySelectorAll(`.consideration--detail.${_activePlan}`);
    _considerationIsActive.forEach( _item => {
      _item.classList.add('is-open');
    });
  }

  togglePriceDetails( event: Event ): void {
    let _toggleTrigger = event.currentTarget as Element | any;
    let _parentContainer = _toggleTrigger.parentElement;

    if(_parentContainer.classList.contains('is-open')){
      this.dataLayerService.push({
        'event': 'medicareResults',
        'componentType': 'button click',
        'clickText': "Collapse " + _toggleTrigger.dataset.text
      });
    }else{
      this.dataLayerService.push({
        'event': 'medicareResults',
        'componentType': 'button click',
        'clickText': "Expand " + _toggleTrigger.dataset.text
      });
    }
    _parentContainer.classList.toggle('is-open');
  }

  openTooltip( event: Event ): void {
    let _currentTarget = event.currentTarget as Element | any;

    _currentTarget.parentElement.classList.add('is-open');

    this.activeElementTooltip = _currentTarget;
    this.fillCurrentTooltip(true);

    this.dataLayerService.push({
      'event': 'medicareResults',
      'componentType': 'info click',
      'clickText': _currentTarget.dataset.title
     });
  }

  onPlanNoteClick(ev){
    this.dataLayerService.push({
      'event': 'medicareResults',
      'componentType': 'button click',
      'clickText': ev.target.innerText
     });
  }

  fillCurrentTooltip(toFill){
    if(toFill)
      {
        let _temp = document.querySelectorAll(`svg.icon-is-active`);
        _temp.forEach( i => {
          i.classList.remove('icon-is-active');
        });
        this.activeElementTooltip.classList.add('icon-is-active');
      }
    else
      this.activeElementTooltip.classList.remove('icon-is-active');
  }

  closeTooltip( event: Event ): void {
    let _currentTarget = event.currentTarget as Element;

    _currentTarget.parentElement.parentElement.classList.remove('is-open');

    this.fillCurrentTooltip(false);
  }

  toogleConsiderations( event: Event ): void {
    let _currentTarget: any = event.currentTarget as Element;

    let _label = _currentTarget.querySelector('.label')
    let _labelText = _label.textContent;

    let _considerationWrapper: any = document.querySelector('.consideration');

    _currentTarget.classList.toggle('is-active');
    _considerationWrapper.classList.toggle('is-open');

    if (_considerationWrapper.classList.contains('is-open')) {
      setTimeout(() => {
        _considerationWrapper.focus();
      }, 500);
    } else {
      _currentTarget.focus();
    }

    this.dataLayerService.push({
      'event': 'medicareResults',
      'componentType': 'button click',
      'clickText': _labelText
     });

    if( _labelText == "Expand" ) {
      _label.textContent = "Collapse";
    } else {
      _label.textContent = "Expand";
    }

  }
}
