import { Injectable } from '@angular/core';
import { DataService } from './planner/data.service';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  moduleTypes: any = null;

  constructor(
    private dataService: DataService,
  ) {
    this.moduleTypes = {
      content_package: 'content_package',
      content_package_std: 'content_package__copy_',
      content_package_secondary: 'secondary_category_content_package',
      featured_page_block: 'cross_pathing_module__copy_',
      call_to_donate: 'call_to_donate',
      newsletter_signup_block: 'newsletter_signup_block',
      cross_pathing_module: 'cross_pathing_module',
      featured_tool_breaker: 'featured_page_block__copy_',
      category_page_hero: 'category_page_hero',
      category_pathing: 'category_pathing',
      category_pathing_std: 'category_pathing__standard',
      topic_different_audience: 'topic_different_audience',
      category_load_more: 'category_load_more',
      partner_grid: 'category_partner_grid',
      article_partner_grid: 'article_partner_grid',
      article_feedback: 'article_feedback',
      standard_partner_grid: 'standard_page___partner_grid',
      special_pathing: 'specialPathing',
      external_form:'component__form',
    };
  }

  featurePageBlock(component, modularContent) {
    let url = component.elements.manual_link.value;
    let headline = null;
    let description = null;
    let type = null;
    let image = null;
    let blockCTA = component.elements?.feature_block_custom_cta?.value?.length > 0 ? component.elements.feature_block_custom_cta.value : '';

    const internalLinkCodeName = component.elements.internal_link.value[0];

    if (internalLinkCodeName) {
      const linkedContent = modularContent[internalLinkCodeName];

      switch (linkedContent.system.type) {
        case 'ncoa_article_content': {
          url = `/article/${linkedContent.elements.url.value}`;
          headline = linkedContent.elements.title.value;
          description = linkedContent.elements.seo_metadata_example_to_include_in_any_type__meta_description.value;
          type = 'article';
          image = linkedContent.elements.primary_image.value.length > 0 ? {
            url: linkedContent.elements.primary_image.value[0].url,
            alt: linkedContent.elements.primary_image.value[0].description,
            responsive: this.dataService.remodelImageSrc(linkedContent.elements.primary_image.value[0].url),
          } : linkedContent.elements.event_date_time.value ? {
            url: '/assets/images/content-package-green-tablet.png',
            alt: headline,
          } : {
            url: '/assets/images/content-package-coral-tablet.png',
            alt: headline,
          };
          break;
        }
        case 'standard_page': {
          const parentPageCodeName = (linkedContent.elements.parent_page.value || [null])[0];
          const parentPage = modularContent[parentPageCodeName] || null;

          if (parentPageCodeName && parentPage) {
            url = `/page/${parentPage.elements.url.value}/${linkedContent.elements.url.value}`;
          } else {
            url = `/page/${linkedContent.elements.url.value}`;
          }

          headline = linkedContent.elements.title.value;
          description = linkedContent.elements.description.value;
          type = 'page';
          image = linkedContent.elements.primary_image.value.length > 0 ? {
            url: linkedContent.elements.primary_image.value[0].url,
            alt: linkedContent.elements.primary_image.value[0].description,
            responsive: this.dataService.remodelImageSrc(linkedContent.elements.primary_image.value[0].url),
          } : {
            url: '/assets/images/content-package-midnight-tablet.png',
            alt: headline,
          }
          break;
        }
        case 'taxonomy_custom_content': {
          url = `/${linkedContent.elements.category_page_url.value}`;
          headline = linkedContent.system.name;
          description = linkedContent.elements.taxonomy_description.value;
          type = 'page';
          image = linkedContent.elements.header_image.value.length > 0 ? {
            url: linkedContent.elements.header_image.value[0].url,
            alt: linkedContent.elements.header_image.value[0].description,
            responsive: this.dataService.remodelImageSrc(linkedContent.elements.header_image.value[0].url),
          } : {
            url: '/assets/images/content-package-midnight-tablet.png',
            alt: headline,
          };
          break;
        }
      }
    }

    headline = component.elements.title.value;
    description = component.elements.pathing_description.value;

    const background = component.elements.background_color.value[0].codename;
    const orientation = background === 'blue' ? 'image-text' : 'text-image';

    return {
      componentType: this.moduleTypes.featured_page_block,
      image: component.elements.image_asset.value.length > 0 ? {
        url: component.elements.image_asset.value[0].url,
        alt: component.elements.image_asset.value[0].description,
        responsive: this.dataService.remodelImageSrc(component.elements.image_asset.value[0].url),
      } : image,
      headline,
      description,
      type,
      background,
      url,
      orientation,
      blockCTA
    };
  }

  featuredToolBreaker(component) {
    const variation = component.elements.breaker_type.value.some(type => type.codename === 'benefits_checkup') ? 'form' : 'link';
    const eyebrow = component.elements.eyebrow.value;
    const headline = component.elements.title.value;
    const description = component.elements.pathing_description.value;
    const image = {
      url: component.elements.image_asset.value[0].url,
      alt: component.elements.image_asset.value[0].description,
      responsive: this.dataService.remodelImageSrc(component.elements.image_asset.value[0].url),
    };
    const cta = {
      text: component.elements.call_to_action_text.value,
      url: component.elements.external_link.value,
    };

    return {
      componentType: this.moduleTypes.featured_tool_breaker,
      variation,
      eyebrow,
      headline,
      description,
      image,
      cta,
    };
  }

  donateBlock(component) {
    const heading = component.elements.headline.value;
    const description = component.elements.description.value;
    const form_desc = component.elements.blurb.value;
    const photo = component.elements.image.value[0].url;
    const caption = component.elements.image.value[0].description;

    return {
      componentType: this.moduleTypes.call_to_donate,
      heading,
      description,
      form_desc,
      photo,
      caption,
    };
  }

  newsLetterBlock(component) {
    const heading = component.elements.headline.value;
    const description = component.elements.description.value;
    const background = component.elements.background_image.value[0].url;
    const backgroundCaption = component.elements.background_image.value[0].description;
    const mobilebackground = component.elements.background_image_mobile.value[0].url;
    const width = component.elements.background_image.value[0].width;
    const height = component.elements.background_image.value[0].height;
    const everyActionFormUrl = component.elements.everyaction_form_url.value ?? '';
    const emailErrorText = component.elements.email_error_text.value  ?? '';
    const fieldErrorText = component.elements.field_error_text.value  ?? '';

    return ({
      componentType: this.moduleTypes.newsletter_signup_block,
      heading,
      description,
      background,
      backgroundCaption,
      mobilebackground,
      width,
      height,
      everyActionFormUrl,
      emailErrorText,
      fieldErrorText,
    });
  }

  categoryPathingStandard(component, modularContent) {
    const headline = component.elements.title.value;
    const links = component.elements.links.value;
    const categories = links.map(link => {
      const linkData = modularContent[link];
      const linkType = linkData.system.type;

      let url = '';
      let title = '';

      if (/article/ig.test(linkType)) {
        url = `/article/${linkData.elements.url.value}`;
        title = linkData.elements.title.value;

        return {
          url,
          title,
          linkType,
        };
      } else if (/standard_page/ig.test(linkType)) {
        const parentPageCodeName = (linkData.elements.parent_page.value || [null])[0];
        const parentPage = modularContent[parentPageCodeName] || null;

        if (parentPageCodeName && parentPage) {
          url = `/page/${parentPage.elements.url.value}/${linkData.elements.url.value}`;
          title = linkData.elements.title.value;

          return {
            url,
            title,
            linkType,
          };
        } else {
          url = `/page/${linkData.elements.url.value}`;
          title = linkData.elements.title.value;

          return {
            url,
            title,
            linkType,
          };
        }
      } else if (/template___other_taxonomy/ig.test(linkType)) {
        url = `/${linkData.elements.other_taxonomy.value[0].codename.replace(/_/g, '-')}`;
        title = linkData.elements.other_taxonomy.value[0].name;

        return {
          url,
          title,
          linkType,
        };
      } else {
        if(linkData.elements.category_page_url)
          url = `/${linkData.elements.category_page_url.value}`;
        if(linkData.elements.page_slug)
          url = `/${linkData.elements.page_slug.value}`;

        title = linkData.system.name;
        
  

        return {
          url,
          title,
          linkType,
        };
      }
    });
    
    return {
      componentType: this.moduleTypes.category_pathing,
      headline,
      categories,
    };
  }

  partnerGrid(component, modularContent) {
    const partnerCodeNames = component.elements.grid_items.value;
    let partners = partnerCodeNames.map((codename) => {
      const partner = modularContent[codename];

      return {
        name: partner.elements.name.value,
        logo: partner.elements.image.value[0].url,
        alt: partner.elements.image.value[0].description,
      };
    });

    return {
      componentType: this.moduleTypes.partner_grid,
      heading: component.elements.title.value,
      partners,
    };
  }
  externalForm(rawComponent) {
    return {
      componentType: this.moduleTypes.external_form,
      formType: rawComponent.elements.form_type,
      embedCode:rawComponent.elements.embed_code ?? '',
    }
  }
}
