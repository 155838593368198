import { Injectable } from '@angular/core';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { SeoService } from '@services/seo.service';
import { GlobalService } from '@services/global.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private seoService: SeoService,
    private globalService: GlobalService,
  ) { }

  getPageData() {
    return this.kontentDeliveryService.getItem(null, {
      'system.type': 'template___map',
      'limit': 1,
      'depth': 2
    })
    .then(rawData => {
      const { title, eyebrow, description, map_activities }: any = this.kontentDeliveryService.extractItemElements(rawData.items[0]);
      this.globalService.saveCurrentURL(`/ncoa-map`);
      this.seoData(rawData.items[0]);
      // this.seoService.extractAndRender(rawData.items[0], {
      //   title,
      //   description
      // });

      return { title, eyebrow, description, map_activities };
    });
  }

  seoData(rawAPIData) {
    const seoData = {
      title: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_title.value
        : rawAPIData.elements.title.value,
      description: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_description.value
        : rawAPIData.elements.description.value || '',
      ogTitle: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value
        : rawAPIData.elements.title.value,
      ogDescription: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value
        : rawAPIData.elements.description.value || '',
      ogImage: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value.length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value[0].url
        : rawAPIData.elements.primary_image?.value.length > 0
          ? rawAPIData.elements.primary_image.value[0].url
          : '/assets/images/article-hero-share-midnight-desktop.png',
      ogType: 'page',
      twitterTitle: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value
        : rawAPIData.elements.title.value,
      twitterDescription: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value
        : rawAPIData.elements.description.value || '',
      twitterImage: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value.length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value[0].url
        : rawAPIData.elements.primary_image?.value.length > 0
          ? rawAPIData.elements.primary_image.value[0].url
          : '/assets/images/article-hero-share-midnight-desktop.png',
    };

    this.seoService.applyMetaData(seoData);
  }
}
