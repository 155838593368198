import { Component, OnInit, Input, HostListener, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { image } from './article-header.types';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ncoa-article-header',
  templateUrl: './article-header.component.html',
  styleUrls: ['./article-header.component.scss']
})
export class ArticleHeaderComponent implements OnInit, AfterViewInit {
  @Input() image: image;
  @Input() eyebrow: any;
  @Input() articleTitle: string = '';
  @Input() publishDate: string;
  @Input() readingTime: string;
  @Input() sponsorship_indication_text: string;
  @Input() partnership: any;
  @Input() width: number;
  @Input() height: number;
  @Input() takeaways: any;
  @Input() authors: any;
  @Input() url: {
    facebook: string,
    twitter: string,
    linkedin: string,
    pinterest: string,
    mailto: string
  }
  titleScale: string = '';


  public isSticky: boolean = false;
  private elementPosition: number;
  private bottomElementPosition: number;
  public stickyTop: number = 215; // Initial top position

  private scrollEvent = new Subject<void>();
  constructor( ) { }
  @ViewChild('stickyElement') stickyElement: ElementRef;

  ngOnInit(): void {

    const titleLength = this.articleTitle?.length || 0;

    switch (true) {

      case (titleLength < 30):
        this.titleScale = 'larger';
        break;

      case (titleLength >= 30 && titleLength <= 50):
        this.titleScale = 'large';
        break;

      default:
        this.titleScale = 'default';
    }
  }

  printPage() {
    // Show invisible components first
    const bylines = document.querySelectorAll('.component--authors-byline');
    bylines.forEach(byline => {
      byline.setAttribute('style', 'opacity: 1');
    });
    window.print();
  }

  ngAfterViewInit() {
    this.recalculatePosition();
    this.scrollEvent.pipe(debounceTime(2)).subscribe(() => this.handleScroll());
  }

  ngOnDestroy() {
    this.scrollEvent.unsubscribe();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.scrollEvent.next();
  }

  handleScroll() {
    if(this.bottomElementPosition === 0) {
      this.recalculatePosition();
    }
    const windowScroll = window.scrollY;
    this.isSticky = windowScroll >= this.elementPosition;

    if (windowScroll >= this.bottomElementPosition) {
      // Adjust the top position as the user scrolls past the bottom element
      this.stickyTop = 215 - (windowScroll - this.bottomElementPosition)
    } else {
      this.stickyTop = 215; // Reset to initial top position
    }

  }

  recalculatePosition() {
    this.elementPosition = this.stickyElement.nativeElement.offsetTop;
    const bottomElement = document.querySelector('.article-query-selector');
    const bottomOfStickyElement = this.stickyElement.nativeElement.getBoundingClientRect().bottom
    this.bottomElementPosition = bottomElement ? bottomElement.getBoundingClientRect().bottom - bottomOfStickyElement : 0;

  }

  kebabCase(url: string): string {
    return url && url.replace(/([a-z])([A-Z])/g, '$1').replace(/[\s_]+/g, '-').toLowerCase();
  }
}
