import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FormsService } from '@services/forms.service';
import { UserService } from '@services/planner/user.service';

@Component({
  selector: 'app-dynamic-forms',
  templateUrl: './dynamic-forms.component.html',
  styleUrls: ['./dynamic-forms.component.scss']
})
/**
 * This component is responsible for rendering an EveryAction form based on the formUrl passed in.
 *  
 */
export class DynamicFormsComponent implements OnInit {
  form: FormGroup;

  @Input() formUrl: string;
  @Input() requiredFieldError: string;
  fields = [];
  submitCTA = ''; // updates to the value returned by the EA form
  submissionUrl = '';
  formVersion = '';
  data = {};
  submitted = false;
  success = false;
  submissionResponse;

  constructor(
    private formsService: FormsService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  async ngOnInit() {
    this.data = await this.formsService.getEveryActionFormData(this.formUrl);
    this.submissionUrl = this.data['submissionUrl'];
    this.formVersion = this.data['formVersion'];
    this.fields = this.getFieldsFromData(this.data['form_elements']);
    this.form = this.buildForm();

    this.changeDetectorRef.detectChanges();

  }

  buildForm(): FormGroup {
    const group = this.fb.group({});
    this.fields.forEach(fieldset => {
      if (fieldset.type === 'fieldset') {
        const fieldGroup = this.fb.group({});
        //remove fields that are not visible
        fieldset.children = this.filterFields(fieldset.children);

        fieldset.children.forEach(field => {
          const control = this.fb.control(
            { value: field.value || '', disabled: field.disabled },
            field.required ? Validators.required : null
          );
          fieldGroup.addControl(field.name, control);
        });
        group.addControl(fieldset.name, fieldGroup);
      }
    });
    return group;
  }

  // remove fields that are not visible
  filterFields(fields: any[]): any[] {
    return fields.filter(field => {
      if(field.type === 'submit'){
        this.submitCTA = field.value;
      }
      return (field['title'] || field?.type === 'hidden' || field?.type === 'markup')
    })
  }


  getFieldsFromData(elements: any): any[] {
    let fields: any[] = [];
    elements.forEach(element => {
      if (element['children']) {
        element['children'].forEach(child => {
          if (child.name === 'EmailAddress') {
            child.type = 'email';
          }
          if (child.type === 'select' && typeof child?.options === 'string') {
            const optionName = child.options;
            const options = this.data['metadata']['options'][optionName];
            child.options = options;
          }
        })
      }
      fields.push(element);

    });
    fields = this.filterFields(fields);

    return fields;
  }

  async onSubmit(e: any) {

    this.submitted = true;
    const formBody = this.flattenObject(this.form.value);
    formBody['formVersion'] = this.formVersion;
  

    if (this.form.valid) {
      const response = await this.userService.postEveryActionSignup(this.submissionUrl, formBody).toPromise().catch(err => {
        this.success = false;
      });


      if(response && response['resultCode'] === 'Success'){
      this.success = true;
      const confirmationPageContent = response['page']['confirmationPageContent'] ?? '';
      this.submissionResponse = this.sanitizer.sanitize(1, confirmationPageContent);
      }

    }
    this.changeDetectorRef.detectChanges();
  }

  flattenObject(ob: any): any {
    const toReturn: any = {};

    for (const i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if ((typeof ob[i]) === 'object' && ob[i] !== null) {
        const flatObject = this.flattenObject(ob[i]);
        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }
  static async createDynamicForm(formUrl: string, requiredFieldError, componentFactoryResolver, renderer: Renderer2, changeDetectorRef: ChangeDetectorRef, viewContainerRef: ViewContainerRef) {
    const componentFactory = componentFactoryResolver.resolveComponentFactory(DynamicFormsComponent);

    // Create a component dynamically
    const componentRef = componentFactory.create(viewContainerRef.injector);
    const dynamicComponent = componentRef.instance;
    dynamicComponent.requiredFieldError = requiredFieldError;
    dynamicComponent.formUrl = formUrl;
    await dynamicComponent.ngOnInit();
    changeDetectorRef.detectChanges();

    // Append the dynamic component to the target div
    //select by formUrl attribute 
    const targetDiv = document.querySelector(`div[data-form-id="${formUrl}"]`);
    if (targetDiv) {
      renderer.insertBefore(targetDiv.parentNode, componentRef.location.nativeElement, targetDiv);
      targetDiv.remove();
    }
    changeDetectorRef.detectChanges();
  }
}

