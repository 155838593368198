import {Injectable} from "@angular/core";
import {ScriptStore} from "../config/script.store";

declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
        this.scripts[script.name] = {
            loaded: false,
            src: script.src
        };
    });
  }

  load(el, ...scripts: string[]) {
      var promises: any[] = [];
      scripts.forEach((script) => promises.push(this.loadScript(el, script)));
      return Promise.all(promises);
  }

  loadScript(el, name: string) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = this.scripts[name].src;
      return new Promise((resolve, reject) => {
        //resolve if already loaded
        if (this.scripts[name].loaded) {
          el.appendChild(script);
          resolve({script: name, loaded: true, status: 'Already Loaded'});
        }
        else {
          //load script
          if (script.readyState) {  //IE
              script.onreadystatechange = () => {
                  if (script.readyState === "loaded" || script.readyState === "complete") {
                      script.onreadystatechange = null;
                      this.scripts[name].loaded = true;
                      resolve({script: name, loaded: true, status: 'Loaded'});
                  }
              };
          } else {  //Others
              script.onload = () => {
                  this.scripts[name].loaded = true;
                  resolve({script: name, loaded: true, status: 'Loaded'});
              };
          }
          script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
          el.appendChild(script);
        }
    });
  }
}
