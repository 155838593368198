import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { SearchService } from '@services/search.service';
import { GlobalService } from '@services/global.service';

@Injectable({
  providedIn: 'root'
})
export class SearchResolverService {

  constructor(
    private searchService: SearchService,
    private globalService: GlobalService
  ) { }

  async resolve(activeRoute: ActivatedRouteSnapshot, route: Router) {
    this.globalService.saveCurrentURL(`/${activeRoute.url.join('/')}`);

    const searchKey = activeRoute.queryParams.q;
    const filteredAudience = activeRoute.queryParams.audience;
    const filteredType = activeRoute.queryParams.type;

    await this.globalService.loadGlobalComponentsData();

    const audienceData = await this.searchService.getAudiences();
    await this.searchService.getList(searchKey, filteredAudience, filteredType);
    const listData = this.searchService.searchData(searchKey, filteredAudience, filteredType);

    return [listData, audienceData];
  }
}
