<div class="breakdown-body">
  <div class="breakdown-body__wrapper">
    <h2 class="headline">{{ headline }}</h2>
    <div class="body" [innerHTML]="body"></div>
  </div>
</div>
<div class="two-col-breakdown" *ngIf="twoColBreakdown">
  <div class="two-col-breakdown__wrapper">
    <h3 class="two-col-breakdown__heading">{{ twoColBreakdown.headline }}</h3>
    <div class="breakdowns">
      <section class="breakdowns__item">
        <h4 class="amount">{{ twoColBreakdown.checking }}</h4>
        <div class="detail">Your Checking Account</div>
      </section>
      <section class="breakdowns__item">
        <h4 class="amount">{{ twoColBreakdown.savings }}</h4>
        <div class="detail">Your Savings Account</div>
      </section>
    </div>
    <p class="two-col-breakdown__copy">{{ twoColBreakdown.copy }}</p>
  </div>
</div>
<div class="component component-breakdown-graph">
  <div class="wrapper">
    <h2 class="component-heading">
      {{ userIsWorking ? 'Your Monthly Income Breakdown' : 'Your Monthly Retirement Income Breakdown' }}
    </h2>
    <div class="pie-graph">
      <div
        class="pie-graph__graphics"
        [attr.data-working]="userIsWorking"
        [attr.data-assets]="chartData?.assets?.value"
        [attr.data-fixed]="chartData?.fixedIncome?.value"
        [attr.data-variable]="chartData?.variableIncome?.value"
        [attr.data-benefits]="chartData?.benefitsIncome?.value"
      >
        <h3>
          <span><i>$</i>{{ totalAmount }}</span>
          <span>{{ userIsWorking ? 'Total Portfolio Value' : 'Total Monthly Income' }}</span>
        </h3>
        <div id="chart" style="width: 334px; height: 334px;"></div>
      </div>
      <div class="pie-graph__legend">
        <div class="legend" [ngClass]='{"hasHundred": hasHundred}'>
          <div class="legend--item" *ngFor="let data of chartData | keyvalue: originalOrder">
            <div
              class="legend--item__summary"
              [ngClass] = "{
                hundredContainer: (data.value.value === '100' || data.value.value === 100)
              }"
            >
              <h3
                [style.background]="data.value.primaryColor"
                [style.color]="data.value.textColor"
              >{{ data.value.value }}%</h3>
              <p>{{ data.value.label }}</p>
            </div>
            <div class="legend--item__breakdown">
              <table class="legend__breakdown">
                <thead style="display: none;">
                  <tr>
                    <th>Label</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="legend__breakdown--item" *ngFor="let item of data.value.breakdown; let i = index">
                    <td class="legend__breakdown--label" [style.background]="i % 2 === 0 ? data.value.secondaryColorFade : data.value.secondaryColor">
                      <p>{{ item.label }}</p>
                    </td>
                    <td class="legend__breakdown--value" [style.background]="i % 2 === 0 ? data.value.secondaryColorFade : data.value.secondaryColor">
                      <p>${{ item.value }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
