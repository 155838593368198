import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'ncoa-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  @Input() isOpen: Boolean;
  @Input() heading: string;

  @Input() coursesData: {
    amp: Array<any>
  }

  constructor() {
    this.isOpen = false;
  }

  ngOnInit(): void {}

  closeModal() {
    const _modal = document.querySelector('.course--modal');

    _modal.classList.remove('is-open');
  }

  openCourse( _element ) {
    _element.srcElement.parentNode.parentNode.classList.toggle('is-open');
  }

  getDate( _date ) {
    return new Date( _date );
  }

  // @NOTE: Use the HostListener decorator to handle DOM events.
  // Handle keydown event
  @HostListener('document:keydown.escape', ['$event'])
  onKeyDownEscape(event: KeyboardEvent) {
    this.closeModal();
  }

}
