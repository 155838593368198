<div class="component component--authors-byline component--{{ variant }}">
  <div class="wrapper">
    <div class="author-byline--main" *ngIf="variant != 'others'">
      <ul>
        <li class="author-byline--author" *ngFor="let author of authors.slice(0,2)">
          <div class="author-byline--photo"><img [attr.src]="author.photo || '../../../assets/images/profile-placeholder.svg'" [attr.alt]="'Photo of ' + author.name" /></div>
          <div class="author-byline--details">
            <p class="author-byline--name"><a [attr.href]="kebabCase(author.link)" [attr.aria-label]="author.name"><b>{{ author.name }}</b></a></p>
            <p class="author-byline--position">{{ author.position }}</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="author-byline--others" *ngIf="variant == 'others'">
      <h2 class="author-byline--heading" *ngIf="variant == 'others'">
        Other Contributors
      </h2>

      <ul>
        <li class="author-byline--author" *ngFor="let author of authors">
          <div class="author-byline--details">
            <p class="author-byline--name"><a [attr.href]="kebabCase(author.link)" [attr.aria-label]="author.name"><b>{{ author.name }}</b></a></p>
            <p class="author-byline--position"name>{{ author.position }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
