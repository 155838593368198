import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class GenericResolverService {

  constructor(private globalService: GlobalService) { }

  async resolve() {
    await this.globalService.loadGlobalComponentsData();
  }
}
