import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'screeningGroupTitle'
})
export class ScreeningGroupTitlePipe implements PipeTransform {

  transform(group: any, formGroup: any, update: boolean): string {


    if(group?.value.every(question => formGroup?.get(question.key).disabled)){
      return "";
    }

    return group.key;
  }

}
