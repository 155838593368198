import { Component, OnInit, Input } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-header-landing',
  templateUrl: './header-landing.component.html',
  styleUrls: ['./header-landing.component.scss']
})
export class HeaderLandingComponent implements OnInit {

  @Input() logo: {
    url: string,
    alt: string
  };

  @Input() background: {
    responsive: {
      media: string;
      url: string;
    }[];
    url: string;
    alt: string;
  };

  @Input() headline: string;
  @Input() intro: string;
  @Input() cta: {
    label: string,
    url: string
  };

  constructor(private dataLayerService: DataLayerService) { }

  ngOnInit(): void {
  }

  trackAnalytics(ev){
    const _element = ev.currentTarget as HTMLAnchorElement;
    const _clickUrl = _element.href || _element.getAttribute('href');

    this.dataLayerService.push({ 
      'event': 'recirc', 
      'url': _clickUrl,
      'componentType': 'header' },false); 
  }

}
