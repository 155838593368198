import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserService } from '@services/planner/user.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'age-well-planner',
  templateUrl: './age-well-planner.component.html',
  styleUrls: ['./age-well-planner.component.scss'],
})

export class AgeWellPlanner implements OnInit {
  @Input() toolsConfig: any = null;

  sessionTimedOut: {
    title: string;
    desc: string;
    cta: {
      url: string;
      text: string;
    };
  };

  signInReminder: string;
  signInUrl: string;
  allowedPath: string[];
  assessmentId: string; // for Falls assessment check. remove once testing is done by NORC

  constructor(
    private tools: ToolsPrimaryConfigService,
    private userService: UserService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.signInReminder = this.tools.rawConfig.elements.sign_in_reminder_text.value;
    this.signInUrl = this.tools.routes.find((route) => route.type === 'sign-in').route;

    const home = this.tools.routes.find((route) => route.type === 'home').route;
    this.allowedPath = [home];

    this.tools.routes.filter((route) => route.type === 'assessment')
      .forEach((route) => {
        this.allowedPath.push(route.route);
      });

    this.sessionTimedOut = {
      title: this.tools.rawConfig.elements.timedout_title.value,
      desc: this.tools.rawConfig.elements.timedout_description.value,
      cta: {
        url: this.tools.routes.find((route) => route.type === 'sign-in').route,
        text: 'Sign In',
      },
    };
    
    this.assessmentId = this.tools.routes.filter((route) => route.type === 'assessment').find((route) => route.route === window.location.pathname)?.assessmentID;
  }

  ngOnInit(): void {
    let signInReminderCount = sessionStorage.getItem('signInReminderCount')
      ? parseInt(sessionStorage.getItem('signInReminderCount'))
      : 0;

      if (this.assessmentId !== 'fallsRiskAssessment' && // check to be removed after NORC testing 
          signInReminderCount < 2 && this.allowedPath.find((route) => route === window.location.pathname)) {
      setTimeout(() => {
        this.userService.isLoggedIn()
          .pipe(take(1))
          .subscribe((isLoggedIn) => {
            if (!isLoggedIn) {

              const signInReminderModal = document.querySelector('#sign-in-reminder');
              signInReminderModal.classList.add('is-active');
              setTimeout(() => {
                signInReminderModal.classList.add('is-visible');
                signInReminderCount++;
                sessionStorage.setItem('signInReminderCount', signInReminderCount.toString());
              }, 500);
              setTimeout(() => { this.dismissReminder() }, 5000);
            }
          });
      }, 4500);
    }

    //universal tag to re-target users for paid media
    let script = this._renderer2.createElement('script');
    script.text = `!function(s,a,e,v,n,t,z){if(s.saq)return;n=s.saq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!s._saq)s._saq=n;n.push=n;n.loaded=!0;n.version='1.0';n.queue=[];t=a.createElement(e);t.async=!0;t.src=v;z=a.getElementsByTagName(e)[0];z.parentNode.insertBefore(t,z)}(window,document,'script','https://tags.srv.stackadapt.com/events.js');saq('ts', 'KR5YuuHCYuC-n-B-Nmv-HQ');`;
    this._renderer2.appendChild(this._document.head, script);
  }

  dismissReminder(): void {
    const signInReminderModal = document.querySelector('#sign-in-reminder');
    signInReminderModal.classList.remove('is-visible');
    setTimeout(() => { signInReminderModal.classList.remove('is-active') }, 500);
  }
}
