import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ArticleCardType } from '@components/article-card/article-card.types';

@Component({
  selector: 'ncoa-article-card-group',
  templateUrl: './article-card-group.component.html',
  styleUrls: ['./article-card-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleCardGroup implements OnInit {
  @Input() type: string; // 1up | 2up | 3up | 4up
  @Input() articles: ArticleCardType[];

  constructor() { }

  ngOnInit(): void {
  }

}
