import { ValidatorFn, ValidationErrors, FormControl, AbstractControl } from '@angular/forms';

export const datePattern: RegExp = RegExp(/\d{2}\/\d{2}\/\d{4}/);

export const dateIsValid: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  if (control.value && control.value.length === 10) {
    // get the day from MM/DD/YYYY
    const day: number = +control.value.substring(3,5);
    // convert string value to Date
    const date: Date = new Date(control.value);
    // check that day from Date matches with day from string
    const isRealDay: boolean = date.getDate() === day;
    // get the year from MM/DD/YYYY
    const year: number = +control.value.substring(6, 10);
    // check that year from Date matches year from string - JS Date converts year 0000 to 2000
    const isRealYear: boolean = date.getFullYear() === year;
    return isRealDay && isRealYear ? null : { invalidDate: true };
  }
}

export function minDate(min: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const date: Date = new Date(control.value);
      const minDate: Date = new Date(min);
      return date >= minDate ? null : { minDate: true };
    } else return null;
  }
}

export function maxDate(max: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const date: Date = new Date(control.value);
      const maxDate: Date = new Date(max);
      return date < maxDate ? null : { maxDate: true };
    } else return null;
  }
}

export function minAge(minAge: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && control.value.length === 10) {
      const userDate = new Date(control.value);
      const now = new Date();
      const bornBefore = new Date(new Date().setFullYear(now.getFullYear() - minAge)); // user must be at least X years old: current date - X years
      return userDate > bornBefore ? { minAge: true } : null;
    } else return null;
  }
}

export function maxAge(maxAge: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && control.value.length === 10) {
      const userDate = new Date(control.value);
      const now = new Date();
      const maxDate = new Date(new Date(userDate).setFullYear(userDate.getFullYear() + maxAge)); // user must be at most X years old: birth date + X years
      return now > maxDate ? { maxAge: true } : null;
    } else return null;
  }
}

export function maxYear(maxAge: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      return control.value > maxAge ? { maxAge: true } : null;
    } else return null;
  }
}

export function minYear(minAge: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      return control.value < minAge ? { minAge: true } : null;
    } else return null;
  }
}