<div class="account-login-planner">
  <div class="account-login-planner__wrapper">
    <a class="back-cta" [title]="backCTA.text" (click) = 'goBack($event)'>
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M6.86842 0.547222L0.315788 7.25062C0.105263 7.46513 0 7.73326 0 8.0014C0 8.26954 0.105263 8.53767 0.315788 8.75218L6.86842 15.4556C7.28947 15.8846 7.94737 15.8846 8.36842 15.4556C8.78947 15.0266 8.78947 14.3562 8.36842 13.9272L3.57895 9.07395L18.9474 9.07395C19.5263 9.07395 20 8.5913 20 8.0014C20 7.4115 19.5263 6.92886 18.9474 6.92886L3.57895 6.92886L8.34211 2.04878C8.76316 1.61977 8.76316 0.949426 8.34211 0.52014C7.94737 0.117936 7.28947 0.117936 6.86842 0.547222Z"
          fill="#0B4A5D" />
      </svg>
      <div><span>{{ backCTA.text }}</span></div>
    </a>
    <div class="main"  id = 'main-content'>
      <div class="eyebrow">{{ eyebrow }}</div>
      <h1 class="title">{{ title }}</h1>
      <p class="desc">{{ desc }}</p>
      <form (ngSubmit)="onSubmit($event)" class="form-container">
        <div class="form-control">
          <label for="email">Email Address</label>
          <input class="{{ email.status.code === 'error' ? 'has-error' : '' }}" [(ngModel)]="email.value" type="email" name="email" id="email" />
          <div *ngIf="email.status.code === 'error'" class="error error-email">{{ email.status.message }}</div>
        </div>
        <div class="form-control password-container">
          <label for="password">Password</label>
          <input  class="{{ password.status.code === 'error' ? 'has-error' : '' }}" [(ngModel)]="password.value" type="password" name="password" id="password" />
          <div *ngIf="password.status.code === 'error'" class="error error-password">{{ password.status.message }}</div>
        </div>
        <div class = "sr-only" *ngIf= 'errorMessage !== ""' role = 'alert'>{{errorMessage}}</div>
        <div class="links">
          <a [href]="forgotPassword.url" [title]="forgotPassword.text">{{ forgotPassword.text }}</a>
          <a [href]="signUp.url" [title]="signUp.text">{{ signUp.text }}</a>
          <button type="submit" class="signin-cta">Sign In</button>
        </div>
      </form>
    </div>
  </div>
</div>
