import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {

  @Input() tooltip;

  private myPopup : HTMLElement;

  constructor(private el: ElementRef) { }

  ngOnDestroy(): void {
    if (this.myPopup) {
       this.myPopup.remove();
    }
  }

  @HostListener("click") onMouseClick() {
    if (!this.el.nativeElement.contains(this.myPopup)) {
      this.createTooltipPopup();
    }
    
  }

  @HostListener("document:click", ['$event.target']) onMouseExitClick(target) {
    let clickedInside = this.el.nativeElement.contains(target);
    if (!clickedInside) {
      if (this.myPopup) {
        this.myPopup.remove();
      }
    }
  }

  private getParentElementWithClassName(element: HTMLElement, className: string) {
    if (element == null) {
      return null;
    }

    if (element.classList.contains(className)) {
      return element;
    }

    return this.getParentElementWithClassName(element.parentElement, className);
  }

  private createTooltipPopup() {
    let popup = document.createElement('div');
    popup.innerHTML = this.tooltip;
    popup.setAttribute("class", "tooltip-container");
    this.el.nativeElement.appendChild(popup); // create element to see tooltip height, not positioned correctly yet

    let tooltipHeight = popup.offsetHeight; // calculate the tooltip height for position adjustment
    let tooltipWidth = popup.offsetWidth; // calculate the tooltip width in case it goes off screen
    this.el.nativeElement.removeChild(popup);

    popup.style.top = -tooltipHeight.toString() + "px"; // adjust the tooltip y position by its height

    let definitionXPos = this.el.nativeElement.offsetLeft; // calculate how far right the start of the definition is
    let definitionWidth = this.el.nativeElement.offsetWidth; // calculate the width of the definition
    let definitionHeight = this.el.nativeElement.offsetHeight; // calculate the height of the definition

    popup.style.left = (definitionXPos + definitionWidth / 2).toString() + "px";
    this.el.nativeElement.appendChild(popup);

    let screeningForm : HTMLElement = this.getParentElementWithClassName(this.el.nativeElement, "screening-form");

    if (popup.getBoundingClientRect().top < screeningForm.getBoundingClientRect().top) {
      popup.style.top = (definitionHeight + 5).toString() + "px";
    }

    // check if the tooltip is to the left of the question label (tooltip is off-screen) and replace it
    if (this.el.nativeElement.parentElement.getBoundingClientRect().left > popup.getBoundingClientRect().left - 5) {
      this.el.nativeElement.removeChild(popup);
      popup.style.left = tooltipWidth / 2 + 5 + "px";
      this.el.nativeElement.appendChild(popup);
    }

    // check if the tooltip is to the right of the question label (tooltip is off-screen) and replace it
    else if (this.el.nativeElement.parentElement.getBoundingClientRect().right < popup.getBoundingClientRect().right + 5) {
      this.el.nativeElement.removeChild(popup);
      let questionLabelWidth = this.el.nativeElement.parentElement.offsetWidth;
      popup.style.left = questionLabelWidth - tooltipWidth / 2 - 5 + "px";
      this.el.nativeElement.appendChild(popup);
    }

    this.myPopup = popup;
  }

}
