import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Change } from 'aws-sdk/clients/cloudformation';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { AbstractQuestion } from 'src/app/typings/questions/AbstractQuestion';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit, AfterViewInit {
  @Input() question!: AbstractQuestion<any>;
  @Input() group!: FormGroup;
  @Input() lang: string = 'en';
  @Input() kenticoKontent: any;
  @Output() answerChangeEvent = new EventEmitter<string>();
  @Input() isCompleted: boolean;
  @ViewChild('div') questionDiv: ElementRef;

  private throttler: Subject<string> = new Subject<string>();
  public label: string;

  constructor(private cd: ChangeDetectorRef) {
    this.throttler.pipe(throttleTime(1)).subscribe((val) => this.answerChangeEvent.emit(val));
  }

  ngOnInit(): void {
    
  }


  ngAfterViewInit(): void {
    this.enableControl();
  }

  enableControl(){
       //get edit query param
       const urlParams = new URLSearchParams(window.location.search);
       const edit = urlParams.get('edit');
       // if edit mode and question has options, then force conditional evluation
       if (edit && this.question.options?.some(option=> option.checked === true)) {
         this.answerChangeEvent.emit(''); // simulate answer change event
       }
       
     }
    
  
  get questionControl(){
    return this.group.get(this.question.key);
  }

  answerChanged(value: string) {
    this.throttler.next(value);
  }

  booleanOption(value: string){
   return value.toLowerCase() === 'true' ? true : false;
  }

  public renderValidationError(string: string, value: any){

    return string.split(/(\{.*?\})/g).map((token) => {

      if(token.startsWith('{') && token.endsWith("}")){

        switch(token){
          case '{validation}':{
            return value;
          }
        }
      }
      return token;
    }).join('');
  }

}
