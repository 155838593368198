import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ncoa-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss']
})
export class SponsorsComponent implements OnInit {

  @Input() sponsors: any;
  @Input() sponsorHeader: String;
  @Input() sponsorFooter: String;

  constructor() { }

  ngOnInit(): void {
  }

  asIsOrder(a, b) {
    return 1;
 }

}
