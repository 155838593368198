import { Component, OnInit } from '@angular/core';
import { GlobalService } from '@services/global.service';

import { ModalService } from '@services/modal.service';

@Component({
  selector: 'ncoa-template-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class Default implements OnInit {

  // open: string;
  // heading: string;
  // description: string;
  // soe_data: any;
  // backgroundImage: {
  //   src: string,
  //   caption: string
  // };
  // cta: {
  //   url: string,
  //   text: string
  // }

  // hasDisplayOnce: string;

  constructor(
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {


    // const hasModalShown = localStorage.getItem('welcomeModalShown');
    // this.hasDisplayOnce = hasModalShown;

    // if (!hasModalShown) {
    //   this.modalService.getModalData('general_modal')
    //     .then(data => {
    //       this.heading = data.heading;
    //       this.description = data.description;
    //       this.backgroundImage = data.backgroundImage;
    //       this.soe_data = data.soeData;
    //     })
    //     .then(() => {
    //       this.open = 'true';
    //       this.modalService.open('general_modal');
    //     })
    // }
  }
}
