import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, ViewEncapsulation, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramsService } from '@services/programs.service';
import { MapService } from '@services/map.service';
import { GlobalService } from '@services/global.service';
import { GoogleMapLatLng } from './map.types';
import { SeoService } from '@services/seo.service';

@Component({
  selector: 'ncoa-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MapComponent implements OnInit {
  eyebrow: string = '';
  title: string = '';
  description: string = '';

  programs: any[] = [];
  filteredPrograms: any[] = [];
  listing: any[] = [];
  filterOptions: any[] = [];
  activityLibrary: any[] = [];
  division: number = 100;
  resultCount: number = 0;
  map_activities: any [] = [];

  latitude: number = 40.067763;
  longitude: number = -97.125371;
  zoom: number = 7;
  bounds: boolean = false;
  google: any;
  googleMap: any;
  googleMapService: any;
  googleMapGeometry: any;
  activeInfoWindow: any;
  activeMarker: any;
  markers: any[] = [];

  flattenedFilters: any[] = [];

  coursesData: any[] = [];
  courseHeading: string;

  searchKey: string = '';
  filterToggleText: string = '+ Show Filters';
  isFilterOpen: boolean = false;

  isFetching: boolean = false;
  isOpenModal: boolean = false;
  isReady: boolean = false;

  @ViewChild('googleMapElement') googleMapElement;
  @ViewChild('loadingElement') loadingElement;

  constructor(
    private programsService: ProgramsService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private mapService: MapService,
    private seoService: SeoService,
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.queryParams?.location) {
      this.searchKey = this.route.snapshot.queryParams.location.trim();
    }
  
    const pageData = this.mapService.getPageData().then(pageData => {
      this.usePageData(pageData)
    });

    const url = "https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=AIzaSyBSsd_dI3YGzX7_-6OhPWio6UifK-H2Wy8";

    if (!document.querySelector(`script[src="${url}"]`)) {
      const s = document.createElement('script');
      s.src = url;

      s.onload = () => {
        const mapStyle = [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#9d9d9e"},{"visibility":"on"}]}];
        const mapConfig = {
          zoom: this.zoom,
          zoomControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          styles: mapStyle,
          center: {
            lat: this.latitude,
            lng: this.longitude,
          },
        };

        this.google = (<any>window).google;
        this.googleMap = new this.google.maps.Map(this.googleMapElement.nativeElement, mapConfig);
        this.googleMapService = new this.google.maps.places.PlacesService(this.googleMap);
        this.googleMapGeometry = this.google.maps.geometry;

        this.programsService.getFilterOptions()
          .then(filterOptions => {
            this.filterOptions = filterOptions;

            filterOptions.forEach(item => {
              this.activityLibrary.push({
                codename: item.value,
                name: item.name,
                parent: {
                  name: item.name,
                  codename: item.value,
                },
              });

              item.options.forEach(subitem => {
                this.activityLibrary.push({
                  codename: subitem.value,
                  name: subitem.name,
                  parent: {
                    name: item.name,
                    codename: item.value,
                  },
                });
              });
            });

            if (this.route.snapshot.queryParams?.activities) {
              const activities = this.route.snapshot.queryParams.activities.trim().split(',');

              activities.forEach(activity => {
                this.filterOptions.forEach((item, index) => {
                  if (item.value === activity) {
                    this.filterOptions[index].isChecked = true;
                    this.flattenedFilters.push(item.value);
                    this.filterOptions[index].options.forEach((subitem, subindex) => {
                      this.filterOptions[index].options[subindex].isChecked = true;
                      this.flattenedFilters.push(subitem.value)
                    });
                  } else {
                    const match = item.options.findIndex(subitem => subitem.value === activity);
                    if (match > -1) {
                      this.filterOptions[index].options[match].isChecked = true;
                      this.flattenedFilters.push(this.filterOptions[index].options[match].value);
                      this.filterOptions.push(this.filterOptions[index].options[match].value);
                    }
                  }
                });
              });
            }

            this.programsService.getData()
              .then(raw => {
                this.programs = this.processData(raw);
                this.startSearch();
              });
          });
      };

      document.body.appendChild(s);
    }
  }

  ngAfterViewInit(): void {
    const resultContainer: any = document.querySelector('.map--listing__result');

    if ( !resultContainer ) {
      return;
    }

    this.globalService.loadFooter(true);

    resultContainer.addEventListener('scroll', () => {
      if (!this.isFetching && this.listing.length !== this.filteredPrograms.length) {
        const lastItem: any = document.querySelector('.map--listing__result .listing--result:last-child');
        const lastItemOffset = lastItem.offsetTop;
        const pageOffset = resultContainer.scrollTop + resultContainer.clientHeight;

        if (pageOffset > lastItemOffset) {
          this.isFetching = true;
          this.showLoading();

          const skip = this.listing.length;
          const newPrograms = [...this.listing, ...this.programs.slice(skip, (skip + this.division))];

          this.listing = newPrograms;
          this.plotMarkers(newPrograms);
          this.hideLoading();
          this.isFetching = false;
        }
      }
    });
  }

  usePageData({ eyebrow = '', title = '', description = '', map_activities}) {
    this.eyebrow = eyebrow;
    this.title = title;
    this.description = description;
    this.map_activities = map_activities;
  }

  processData(data): any {
    return data.map(program => {
      // Ensure website has protocol
      if ( program.website && !/^http/.test(program.website) ) {
        program.website = '//' + program.website;
      }
      if (typeof program.activities === 'string') {
        program.activities = [program.activities];
      }
      const parentActivities = [];
      const resultActivities = [];
      program.activities.forEach(activity => {
        const flag = this.activityLibrary.find(item => item.codename === activity);
        
        resultActivities.push(flag.name);

        if (flag) {
          parentActivities.push(flag.parent);
        }
      });

      return {
        ...program,
        parent_activities: parentActivities.filter((v, i, a) => a.findIndex(t => (t.codename === v.codename)) === i),
        result_activities: resultActivities
      };
    });
  }

  clearMarkers() {
    if (this.markers.length > 0) {
      this.markers.forEach(({ marker }) => marker.setMap(null));
      this.markers = [];
    }
  }

  plotMarkers(programs) {
    const bounds = new this.google.maps.LatLngBounds();

    programs.forEach(program => {
      const latLng = new this.google.maps.LatLng(program.latitude, program.longitude);

      const marker = new this.google.maps.Marker({
        position: latLng,
        icon: `//${window.location.host}/assets/images/marker.png`,
        map: this.googleMap,
      });

      const infoWindow = new this.google.maps.InfoWindow({
        content: `
          <div class="info-window">
            <button type="button" class="info-window__close-btn">Close</button>
            <h3 class="info-window__heading">${program.name}</h3>
            <div class="info-window__body">
              <span class="address">${program.address}</span>
              ${program.city ? `<span class="city">${ program.city } </span>` : ''} 
              ${program.state ? `<span class="state">, ${ program.state } </span>` : ''}
              ${program.zip_code ? `<span class="zip_code">${ program.zip_code } </span>` : ''}
              ${program.phone_number ? `<span class="phone-number">${program.phone_number}</span>` : ''}
              ${program.amp_courses ? `<button type="button" class="info-window__body-link amp-course-window"><span>View AMP Courses</span></button>` : ``}
              ${program.website ? `<a href="//${program.website}" class="info-window__body-link"><span>View Website</span></a>` : ``}
            </div>
            <div class="info-window__icons"></div>
          </div>
        `
      });

      this.google.maps.event.addListener(infoWindow, 'domready', () => {
        const ampCourseBtn = document.querySelector('.amp-course-window');

        if (ampCourseBtn) {
          ampCourseBtn.addEventListener('click', () => {
            this.openModal(program.amp_courses, program.name);
          });
        }
      });

      bounds.extend(latLng);

      marker.addListener('click', () => {
        if (window.innerWidth >= 1024) {
          if (this.activeInfoWindow) {
            this.activeInfoWindow.close();
          }

          if (this.activeMarker) {
            this.activeMarker.setIcon(`//${window.location.host}/assets/images/marker.png`);
          }

          infoWindow.open(this.googleMap, marker);

          infoWindow.addListener('domready', () => {
            document.querySelector('.info-window__close-btn').addEventListener('click', () => {
              if (this.activeInfoWindow) {
                this.activeInfoWindow.close();
                this.activeMarker.setIcon(`//${window.location.host}/assets/images/marker.png`);
                this.activeMarker = null;
                this.activeInfoWindow = null;
              }
            });
          });

          marker.setIcon(`//${window.location.host}/assets/images/active-marker.png`);

          this.activeMarker = marker;
          this.activeInfoWindow = infoWindow;
        }
      });

      this.markers.push({ marker, data: program });
    });

    this.googleMap.fitBounds(bounds);
  }

  updateZoom(direction) {
    if (this.googleMap) {
      this.zoom = this.googleMap.getZoom();
      this.zoom = direction === 'in' ? this.zoom + 1 : this.zoom - 1;
      this.googleMap.setZoom(this.zoom);
    }
  }

  onSubmit() {
    if (this.google && this.googleMap && this.googleMapService) {

      this.filterToggleText = '+ Show Filters';
      this.isFilterOpen = false;
      this.flattenedFilters = [];
      this.listing = [];
      this.clearMarkers();

      const _filters = document.querySelectorAll('.js--filter-option:checked');

      _filters.forEach( (_item: HTMLInputElement ) => this.flattenedFilters.push(_item.value));

      document.querySelector('.map--listing__result').scrollTop = 0;

      this.startSearch();

      const queryParams = {
        location: this.searchKey.trim() != '' ? this.searchKey : null,
        activities: null,
      };

      if (_filters.length > 0) {
        const activities = [];
        this.filterOptions.forEach(item => {
          if (item.isChecked) {
            activities.push(item.value);
          }
          else if (item.options) {
            item.options.forEach(subitem => {
              if (subitem.isChecked) {
                activities.push(subitem.value);
              }
            });
          }
        });

        queryParams.activities = activities.join(',');
      }

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  }

  onChange(index, subindex = -1) {
    if (subindex > -1) {
      this.filterOptions[index].options[subindex].isChecked = !this.filterOptions[index].options[subindex].isChecked;
      this.filterOptions[index].isChecked = !this.filterOptions[index].options.some(option => !option.isChecked);
    } else {
      this.filterOptions[index].isChecked = !this.filterOptions[index].isChecked;
      this.filterOptions[index].options.forEach((option, subindex) => {
        this.filterOptions[index].options[subindex].isChecked = this.filterOptions[index].isChecked;
      });
    }
  }

  toggleFilters() {
    this.filterToggleText = this.isFilterOpen ? '+ Show Filters' : '- Hide Filters';
    this.isFilterOpen = !this.isFilterOpen;
  }

  openModal( _course, _heading ) {
    this.isOpenModal = true;
    this.coursesData = JSON.parse(_course);
    this.courseHeading = _heading;

    const _modal = document.querySelector('.course--modal');

    _modal.classList.add('is-open');
  }

  mouseEnter(index) {
    if (this.activeMarker) {
      this.activeMarker.setIcon(`//${window.location.host}/assets/images/marker.png`);
    }

    this.markers[index].marker.setIcon(`//${window.location.host}/assets/images/active-marker.png`);
    this.activeMarker = this.markers[index].marker;
  }

  mouseLeave() {
    if (this.activeMarker) {
      this.activeMarker.setIcon(`//${window.location.host}/assets/images/marker.png`);
    }
  }

  onFocus(element) {
    const parent = element.parentElement;
    parent.classList.add('focus');
  }

  onBlur(element) {
    const parent = element.parentElement;
    parent.classList.remove('focus');
  }

  filterPrograms(programs: any[] = [], placeLatLng: GoogleMapLatLng = null) {
    let f_p = programs;

    if (placeLatLng) {
      f_p = programs.filter(program => {
        const programLatLng: GoogleMapLatLng = new this.google.maps.LatLng(Number(program.latitude), Number(program.longitude));
        const distance = this.googleMapGeometry.spherical.computeDistanceBetween(placeLatLng, programLatLng);

        /// Seting meters to miles
        return !isNaN(distance) && distance <= 80467;
      });
    }

    if (this.flattenedFilters.length > 0) {
      f_p = f_p.filter(program => {
        if (typeof program.activities == 'string') {
          return this.flattenedFilters.includes(program.activities);
        } else {
          return program.activities.some(activity => this.flattenedFilters.includes(activity));
        }
      });
    }

    if (placeLatLng) {
      f_p = f_p.map(p => {
        const programLatLng: GoogleMapLatLng = new this.google.maps.LatLng(Number(p.latitude), Number(p.longitude));
        return { 
          distance: this.googleMapGeometry.spherical.computeDistanceBetween(placeLatLng, programLatLng),
          ...p
        }
      }).sort((p1, p2) => {
        if (p1.distance > p2.distance) return 1;
        if (p1.distance < p2.distance) return -1;
        return 0;
      });
    }

    return f_p;
  }

  updateMap(center, zoom) {
    this.googleMap.setCenter(center);
    this.googleMap.setZoom(zoom);
    this.zoom = zoom;
  }

  findPlaceFromQuery(query) {
    return new Promise(resolve => {
      this.googleMapService.findPlaceFromQuery({
        query,
        fields: ['place_id', 'geometry']
      }, (results, status) => {
        resolve(results ? results[0] || null : null);
      });
    });
  }

  startSearch() {

    this.showLoading();

    if (this.searchKey == '') {
      this.filteredPrograms = this.filterPrograms(this.programs);
      this.resultCount = this.filteredPrograms.length;
      this.listing = this.filteredPrograms.slice(0, this.division);
      this.plotMarkers(this.listing);
      this.isReady = true;
      this.hideLoading();
    } else {
      this
      .findPlaceFromQuery(this.searchKey)
      .then(resultPlace => this.usePlaceData(resultPlace))
      .then(() => {
        this.isReady = true;
        this.hideLoading();
      });
    }
  }

  usePlaceData(placeData) {
    if ( placeData ) {
      const placeLatLng = placeData.geometry.location

      this.filteredPrograms = this.filterPrograms(this.programs, placeLatLng);
      this.resultCount = this.filteredPrograms.length;
      this.listing = this.filteredPrograms.slice(0, this.division);

      this.clearMarkers();

      if (this.resultCount > 0) {
        const zoom = 11;
        const centerLatLng = {
          lat: placeData.geometry.location.lat(),
          lng: placeData.geometry.location.lng(),
        };

        this.plotMarkers(this.listing);
        this.updateMap(centerLatLng, zoom);
      }
    }
    else {
      this.filteredPrograms = [];
      this.resultCount = 0;
      this.listing = [];
    }
  }

  showLoading() {
    this.loadingElement.nativeElement.classList.remove('hide');
  }

  hideLoading() {
    this.loadingElement.nativeElement.classList.add('hide');
  }


  seoData(rawAPIData) {
    const seoData = {
      title: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_title.value
        : rawAPIData.elements.title.value,
      description: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_description.value
        : rawAPIData.elements.description.value || '',
      image: rawAPIData.elements.primary_image.value.length > 0
        ? rawAPIData.elements.primary_image.value[0].url
        : '/assets/images/article-hero-share-midnight-desktop.png',
      ogTitle: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value
        : rawAPIData.elements.title.value,
      ogDescription: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value
        : rawAPIData.elements.description.value || '',
      ogImage: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value.length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value[0].url
        : rawAPIData.elements.primary_image.value.length > 0
          ? rawAPIData.elements.primary_image.value[0].url
          : '/assets/images/article-hero-share-midnight-desktop.png',
      ogType: 'page',
      twitterTitle: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value
        : rawAPIData.elements.title.value,
      twitterDescription: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value
        : rawAPIData.elements.description.value || '',
      twitterImage: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value.length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value[0].url
        : rawAPIData.elements.primary_image.value.length > 0
          ? rawAPIData.elements.primary_image.value[0].url
          : '/assets/images/article-hero-share-midnight-desktop.png',
    };

    this.seoService.applyMetaData(seoData);
  }
}
