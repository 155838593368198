import { Component, OnInit, Input } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-breakdown-healthcare',
  templateUrl: './breakdown-healthcare.component.html',
  styleUrls: ['./breakdown-healthcare.component.scss']
})
export class BreakdownHealthcareComponent implements OnInit {

  @Input() headline: string;
  @Input() body: string;
  healStatus: string = 'none';
  @Input() pricing: {
    none: {
      lowPrice: string;
      highPrice: string;
    };
    good: {
      lowPrice: string;
      highPrice: string;
    };
    poor: {
      lowPrice: string;
      highPrice: string;
    };
  };

  constructor(private dataLayerService: DataLayerService){

  }

  onHealthStatusChange(){
    this.dataLayerService.push({ 
      'event': 'medicareResults', 
      'componentType': 'health status dropdown click',
      'selectedOption': this.healStatus,
      'clickText': this.healStatus === 'good'? 'Good Health': "Poor Health"
     }); 

    let cover = document.querySelectorAll('.breakdown__col--cover');
    cover.forEach( _item => {
      if(this.healStatus !== 'none') {
        _item.classList.add('active');
      } else {
        _item.classList.remove('active');
      }
    });
  }


  ngOnInit(): void { 
    this.healStatus = 'none';
  }
}
