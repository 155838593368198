import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ncoa-recommendation-header',
  templateUrl: './recommendation-header.component.html',
  styleUrls: ['./recommendation-header.component.scss']
})
export class RecommendationHeaderComponent implements OnInit {

  @Input() heading: string;
  @Input() subheading: string;
  @Input() tags: string;
  @Input() recentViews: {
    heading: string,
    links: [
      {
        label: string,
        url: string
      }
    ]
  }


  constructor() { }

  ngOnInit(): void {
  }

}
