import { Injectable } from '@angular/core';
import { ComponentService } from './component.service';
import { KontentDeliveryService } from './kontent-delivery.service';
import { DataService } from './planner/data.service';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { ModulesService } from './modules.service';
import { ModalService } from './modal.service';
import { DataLayerService } from './data-layer.service';
import { SeoService } from './seo.service';
import { UrlService } from './url.service';
import { RichTextService } from './rich-text-resolver.service';

dayjs.extend(utc);

const findByCodeName = (object, targetCodeName) => {
  let result = null;

  if (object instanceof Array) {
    for (let i = 0; i < object.length; i++) {
      result = findByCodeName(object[i], targetCodeName);

      if (result) {
        break;
      }
    }
  } else {
    for (let prop in object) {
      if (prop === 'codename') {
        if (object[prop] === targetCodeName) {
          return object;
        }
      }

      if (object[prop] instanceof Object ||
          object[prop] instanceof Array) {
        result = findByCodeName(object[prop], targetCodeName);

        if (result) {
          break;
        }
      }
    }
  }

  return result;
};

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  categoryPageData: any = null;
  modularContent: any = null;
  components: any[] = [];
  articles: any[] = [];
  audience: any = null;
  category: any = null;

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private componentService: ComponentService,
    private dataService: DataService,
    private modulesService: ModulesService,
    private modalService: ModalService,
    private dataLayerService: DataLayerService,
    private seoService: SeoService,
    private richTextService: RichTextService
  ) {}

  async getCategoryPageData(slug, categoryTaxonomy, audienceTaxonomy) {
    let categoryPageData = await this.kontentDeliveryService.getItem(null, {
      'system.type': 'taxonomy_custom_content',
      'order': 'system.last_modified[desc]',
      'elements.category_page_url': slug,
      'depth': 5,
    });

    this.modularContent = categoryPageData.modular_content;
    this.categoryPageData = categoryPageData.items.length > 0 ? categoryPageData.items[0] : null;

    if (this.categoryPageData) {  
      let categories = slug.split('/');
      const audienceSlug = categories[0];
      let currentCategory: any = categories.pop();

      currentCategory = {
        codename: currentCategory.replace(/-/g, '_'),
        name: findByCodeName(categoryTaxonomy.terms, currentCategory.replace(/-/g, '_')).name,
      };

      this.category = currentCategory;

      const currentAudience = {
        codename: audienceSlug.replace(/-/g, '_'),
        name: findByCodeName(audienceTaxonomy.terms, audienceSlug.replace(/-/g, '_')).name,
      };

      this.audience = currentAudience;

      this.modalService.showAvailableModal(this.audience.codename, this.categoryPageData.elements.categories.value.map((cat) => cat.codename), this.categoryPageData.system.codename);

      // category page articles library
      const primaryArticles = await this.kontentDeliveryService.getItem(null, {
        'system.type': 'ncoa_article_content',
        'elements': 'primary_image,title,display_date,url,event_date_time,categories,audiences,secondary_audiences,secondary_categories,spanish_subcategory,other',
        'elements.audiences[any]': this.audience.codename,
        'elements.categories[any]': this.category.codename,
        'depth': 5,
      });

      const secondaryArticles = await this.kontentDeliveryService.getItem(null, {
        'system.type': 'ncoa_article_content',
        'elements': 'primary_image,title,display_date,url,event_date_time,categories,audiences,secondary_audiences,secondary_categories,spanish_subcategory,other',
        'elements.secondary_audiences[any]': this.audience.codename,
        'elements.secondary_categories[any]': this.category.codename,
        'depth': 5,
      });

      let articles = [...primaryArticles.items, ...secondaryArticles.items];
          articles = articles.map((article) => ({
            ...article,
            date: new Date(article.elements.display_date.value || article.system.last_modified),
          }));
          articles = articles.sort((a, b) => b.date - a.date);
          articles = articles.filter((v, i, a) => a.findIndex(t => (t.system.id === v.system.id)) === i);

      this.articles = articles;

      // hero
      categories = categories.slice(1, categories.length);

      let breadcrumbs = [];

      categories.forEach((category, index) => {
        let slug = `${audienceSlug}`;

        for (let i = 0; i <= index; i++) {
          slug += `/${categories[i]}`;
        };

        breadcrumbs.push({
          text: findByCodeName(categoryTaxonomy.terms, category.replace(/-/g, '_')).name,
          url: `/${slug}`,
        });
      });

      if (breadcrumbs.length >= 3) {
        breadcrumbs = [
          breadcrumbs[(breadcrumbs.length - 1)],
          breadcrumbs[(breadcrumbs.length - 2)],
        ];
      }
      // rich text migration
      let description = this.categoryPageData.elements.taxonomy_description.value;
      if(this.categoryPageData.elements?.taxonomy_description_rich_text?.value !=="<p><br></p>" && this.categoryPageData.elements?.taxonomy_description_rich_text?.value !==undefined) {
        description = this.richTextService.resolveRichText(this.categoryPageData.elements?.taxonomy_description_rich_text)
      }

      this.components.push({
        componentType: this.componentService.componentTypes.category_page_hero,
        category: {
          breadcrumbs,
          headline: `<span>${currentCategory.name}</span> for ${currentAudience.name}`,
          description: description,
          photo: this.categoryPageData.elements.header_image.value.length > 0
            ? {
              responsive: this.dataService.remodelImageSrc(this.categoryPageData.elements.header_image.value[0].url),
              url: this.categoryPageData.elements.header_image.value[0].url,
              alt: this.categoryPageData.elements.header_image.value[0].description,
            } : null,
        },
      });

      // special pathing
      if (this.categoryPageData.elements.special_pathing.value.length > 0) {
        const pageData = this.categoryPageData;
        const codeNames = pageData.elements.special_pathing.value;
        const paths = codeNames.map((codename) => {
          const path = this.modularContent[codename];
          switch (path.system.type) {
            case 'template___other_taxonomy': {
              return {
                url: `/${path.system.name.trim()
                  .toLowerCase()
                  .replace(/\s+/g, '-')
                  .replace(/[^\w\-]+/g, '')
                  .replace(/\-\-+/g, '-')
                  .replace(/^-+/, '')
                  .replace(/-+$/, '')}`,
                title: path.system.name,
              };
            }
            case 'ncoa_article_content': {
              return {
                url: `/article/${path.elements.url.value}`,
                title: path.elements.title.value,
              };
            }
            case 'standard_page': {
              let url = null;
              const parentPageCodename = path.elements.parent_page.value[0] || null;

              if (parentPageCodename) {
                const parentPage = this.modularContent[parentPageCodename];

                if (parentPage) {
                  url = `/page/${parentPage.elements.url.value}/${path.elements.url.value}`;
                }
              } else {
                url = `/page/${path.elements.url.value}`;
              }

              return {
                url,
                title: path.elements.title.value,
              };
            }
            case 'template___custom_search': {
              return {
                url: `/${path.elements.page_slug.value}`,
                title: path.elements.page_title.value,
              };
            }
            case 'navigation_item_0ae4ab8': {
              let url, external = false;
              if (path.elements.linked_content.value.length > 0) {
                const linkedContentItem = this.modularContent[path.elements.linked_content.value[0]];
                if (linkedContentItem.system.type === 'ncoa_article_content') { // article
                  if (linkedContentItem.system.collection === 'default') { // ncoa article
                    url = `article/${linkedContentItem.elements.url.value}`;
                  } else { // awp article
                    url = `age-well-planner/resource/${linkedContentItem.elements.url.value}`;
                  }
                } else { // category/standard/audience page
                  url = this.modularContent[path.elements.linked_content.value[0]].elements.category_page_url?.value || `page/${this.modularContent[path.elements.linked_content.value[0]].elements.url?.value}`;
                }
              } else {
                url = path.elements.link_url.value;
                if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0) external = true;
              }
              return {
                title: path.elements.link_name.value || this.modularContent[path.elements.linked_content.value[0]].system.name,
                url,
                external
              }
            }
          }
        });

        this.components.push({
          componentType: this.modulesService.moduleTypes.special_pathing,
          paths,
        });
      }

      // top content package
      let topContents = [];
      if (this.categoryPageData.elements.featured_articles.value.length > 0) {
        const articleCodeNames = this.categoryPageData.elements.featured_articles.value;
        topContents = articleCodeNames.map((codename) => {
          const article = this.modularContent[codename];

          return {
            ...article,
            date: new Date(article.elements.display_date.value || article.system.last_modified),
          };
        });

        topContents = topContents.map((article) => {
          const index = this.articles.findIndex((libArticle) => libArticle.system.id === article.system.id);

          if (index >= 0) {
            this.articles.splice(index, 1);
          }

          const type = article.elements.event_date_time.value ? 'event' : 'article';
          const title = article.elements.title.value;
          const cta = {
            url: `/article/${article.elements.url.value}`,
            text: type === 'event' ? 'View Event Details' : 'Read Article',
          };
          const image = article.elements.primary_image.value.length > 0 ? {
            url: article.elements.primary_image.value[0].url,
            alt: article.elements.primary_image.value[0].description,
            responsive: this.dataService.remodelImageSrc(article.elements.primary_image.value[0].url),
          } : {};
          const eyebrow = dayjs.utc(article.date).format('MMM D, YYYY');

          return {
            type,
            title,
            cta,
            image,
            eyebrow,
          };
        });
      } else {
        const max = this.articles.length > 6 ? 6 : this.articles.length;
        const articles = this.articles.slice(0, max);

        topContents = articles.map((article) => {
          const index = this.articles.findIndex((libArticle) => libArticle.system.id === article.system.id);

          if (index >= 0) {
            this.articles.splice(index, 1);
          }

          const type = article.elements.event_date_time.value ? 'event' : 'article';
          const title = article.elements.title.value;
          const cta = {
            url: `/article/${article.elements.url.value}`,
            text: type === 'event' ? 'View Event Details' : 'Read Article',
          };
          const image = article.elements.primary_image.value.length > 0 ? {
            url: article.elements.primary_image.value[0].url,
            alt: article.elements.primary_image.value[0].description,
            responsive: this.dataService.remodelImageSrc(article.elements.primary_image.value[0].url),
          } : {};
          const eyebrow = dayjs.utc(article.date).format('MMM D, YYYY');

          return {
            type,
            title,
            cta,
            image,
            eyebrow,
          };
        })
      }

      this.components.push({
        componentType: this.componentService.componentTypes.content_package,
        articles: topContents,
      });

      // additional components
      this.categoryPageData.elements.additional_modules.value.forEach((codename) => {
        const component = this.modularContent[codename];
        switch (component.system.type) {
          // featured tool breaker
          case this.modulesService.moduleTypes.featured_tool_breaker: {
            this.components.push(this.modulesService.featuredToolBreaker(component));
            break;
          }

          // donate block
          case this.modulesService.moduleTypes.call_to_donate: {
            this.components.push(this.modulesService.donateBlock(component));
            break;
          }

          // secondary content package
          case this.modulesService.moduleTypes.content_package_secondary: {
            this.secondaryContentPackage();
            break;
          }

          // newsletter block
          case this.modulesService.moduleTypes.newsletter_signup_block: {
            this.components.push(this.modulesService.newsLetterBlock(component));
            break;
          }

          // featured page block
          case this.modulesService.moduleTypes.featured_page_block: {
            this.featurePageBlock(component);
            break;
          }

          // partner grid
          case this.modulesService.moduleTypes.partner_grid: {
            this.components.push(this.modulesService.partnerGrid(component, this.modularContent));
            break;
          }
          // content package
          case this.modulesService.moduleTypes.content_package: {
            this.contentPackage(component);
            break;
          }
          // external form package
          case this.modulesService.moduleTypes.external_form: {
            this.components.push(this.modulesService.externalForm(component));
            break;
          }
        }
      });

  

      // special components
      // sub category pathing
      // try to get manually set items first



      await this.subCategoryPathing(categoryTaxonomy);

      // same topic different audience
      await this.sameTopicDiffAudience(audienceTaxonomy);

      // more stories
      this.moreStories();

      // analytics
      this.dataLayerService.push({
        url: window.location.href,
        pageTitle: `${currentCategory.name} for ${currentAudience.name}`,
        contentType: 'taxonomy',
        category: currentCategory.codename,
        audience: currentAudience.codename,
      });

      // seo service
      const seo = {
        title: `${currentCategory.name} for ${currentAudience.name}`,
        description:
          this.categoryPageData.elements.seo_metadata_example_to_include_in_any_type__meta_description.value
          || this.categoryPageData.elements.taxonomy_description.value,
        image: this.categoryPageData.elements.header_image.value[0]?.url,
        ogTitle: this.categoryPageData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value
          || `${currentCategory.name} for ${currentAudience.name}`,
        ogDescription: this.categoryPageData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value
          || this.categoryPageData.elements.taxonomy_description.value,
        ogImage: this.categoryPageData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value[0]?.url
          || this.categoryPageData.elements.header_image.value[0]?.url,
        twitterTitle: this.categoryPageData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value
          || `${currentCategory.name} for ${currentAudience.name}`,
        twitterDescription: this.categoryPageData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value
          || this.categoryPageData.elements.taxonomy_description.value,
        twitterImage: this.categoryPageData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value[0]?.url
          || this.categoryPageData.elements.header_image.value[0]?.url,
      };

      this.seoService.applyMetaData(seo);

      return this.components;
    }

    return null;
  }

  secondaryContentPackage() {
    const max = this.articles.length > 4 ? 4 : this.articles.length;
    let contentPackageArticles = this.articles.slice(0, max);

    contentPackageArticles = contentPackageArticles.map((article) => {
      const index = this.articles.findIndex((libArticle) => libArticle.system.id === article.system.id);

      if (index >= 0) {
        this.articles.splice(index, 1);
      }

      const type = article.elements.event_date_time.value ? 'event' : 'article';
      const title = article.elements.title.value;
      const cta = {
        url: `/article/${article.elements.url.value}`,
        text: type === 'event' ? 'View Event Details' : 'Read Article',
      };
      const image = article.elements.primary_image.value.length > 0 ? {
        url: article.elements.primary_image.value[0].url,
        alt: article.elements.primary_image.value[0].description,
        responsive: this.dataService.remodelImageSrc(article.elements.primary_image.value[0].url),
      } : {};
      const eyebrow = dayjs.utc(article.date).format('MMM D, YYYY');

      return {
        type,
        title,
        cta,
        image,
        eyebrow,
      };
    });

    if (contentPackageArticles.length > 0) {
      this.components.push({
        componentType: this.componentService.componentTypes.content_package,
        articles: contentPackageArticles,
      });
    }
  }

  featurePageBlock(component) {
    let url = component.elements.manual_link.value;
    let headline = null;
    let description = null;
    let type = 'page';
    let image = null;
    let blockCTA = component.elements?.feature_block_custom_cta?.value.length > 0 ? component.elements.feature_block_custom_cta.value : null;

    const internalLinkCodeName = component.elements.internal_link.value[0];

    if (internalLinkCodeName) {
      const linkedContent = this.modularContent[internalLinkCodeName];

      switch (linkedContent.system.type) {
        case 'ncoa_article_content': {
          url = `/article/${linkedContent.elements.url.value}`;
          headline = linkedContent.elements.title.value;
          description = linkedContent.elements.seo_metadata_example_to_include_in_any_type__meta_description.value;
          type = 'article';
          image = linkedContent.elements.primary_image.value.length > 0 ? {
            url: linkedContent.elements.primary_image.value[0].url,
            alt: linkedContent.elements.primary_image.value[0].description,
            responsive: this.dataService.remodelImageSrc(linkedContent.elements.primary_image.value[0].url),
          } : linkedContent.elements.event_date_time.value ? {
            url: '/assets/images/content-package-green-tablet.png',
            alt: headline,
          } : {
            url: '/assets/images/content-package-coral-tablet.png',
            alt: headline,
          };
          break;
        }
        case 'standard_page': {
          const parentPageCodeName = (linkedContent.elements.parent_page.value || [null])[0];
          const parentPage = this.modularContent[parentPageCodeName] || null;

          if (parentPageCodeName && parentPage) {
            url = `/page/${parentPage.elements.url.value}/${linkedContent.elements.url.value}`;
          } else {
            url = `/page/${linkedContent.elements.url.value}`;
          }

          headline = linkedContent.elements.title.value;
          description = linkedContent.elements.description.value;
          type = 'page';
          image = linkedContent.elements.primary_image.value.length > 0 ? {
            url: linkedContent.elements.primary_image.value[0].url,
            alt: linkedContent.elements.primary_image.value[0].description,
            responsive: this.dataService.remodelImageSrc(linkedContent.elements.primary_image.value[0].url),
          } : {
            url: '/assets/images/content-package-midnight-tablet.png',
            alt: headline,
          }
          break;
        }
        case 'taxonomy_custom_content': {
          url = `/${linkedContent.elements.category_page_url.value}`;
          headline = linkedContent.system.name;
          description = linkedContent.elements.taxonomy_description.value;
          type = 'page';
          image = linkedContent.elements.header_image.value.length > 0 ? {
            url: linkedContent.elements.header_image.value[0].url,
            alt: linkedContent.elements.header_image.value[0].description,
            responsive: this.dataService.remodelImageSrc(linkedContent.elements.header_image.value[0].url),
          } : {
            url: '/assets/images/content-package-midnight-tablet.png',
            alt: headline,
          };
          break;
        }
      }
    }

    headline = component.elements.title.value;
    description = component.elements.pathing_description.value;

    const background = component.elements.background_color.value[0].codename;
    const orientation = background === 'blue' ? 'image-text' : 'text-image';

    this.components.push({
      componentType: this.componentService.componentTypes.featured_page_block,
      image: component.elements.image_asset.value.length > 0 ? {
        url: component.elements.image_asset.value[0].url,
        alt: component.elements.image_asset.value[0].description,
        responsive: this.dataService.remodelImageSrc(component.elements.image_asset.value[0].url),
      } : image,
      headline,
      description,
      type,
      background,
      url,
      orientation,
      blockCTA
    });
  }

  async getDynamicArticles(component) {
    const categories = component.elements.categories.value.map(({ codename }) => codename);
    const audiences = component.elements.audiences.value.map(({ codename }) => codename);
    const specialType = component.elements.other_categories.value.map(({ codename }) => codename);

    const { items: filteredArticles } = await this.kontentDeliveryService.getItemAndCache(null, {
      'system.type': 'ncoa_article_content',
      'elements': 'primary_image,title,display_date,url,event_date_time,categories,audiences,secondary_audiences,secondary_categories,spanish_subcategory,other',
      'order': 'system.last_modified[desc]',
      'elements.audiences[any]': audiences.join(','),
      'elements.categories[any]': categories.join(','),
      'depth': 5,
    });

    const max = filteredArticles.length > 7 ? 7 : filteredArticles.length;

    return filteredArticles.slice(0, max);
  }

  getManualArticles(component) {
    let selectedArticles = [];
    const articleCodeNames = component.elements.content.value;

    articleCodeNames.forEach((codename) => {
      const article = this.modularContent[codename];

      if (article) {
        selectedArticles.push({
          ...article,
          date: new Date(article.elements.display_date.value || article.system.last_modified),
        });
      }
    });

    return selectedArticles;
  }

  async contentPackage(component) {
    
    let contentPackageArticles = [];
    let selectedArticles = [];
    const contentPopulationMethod = component.elements.content_population_method.value[0].codename;

    if (contentPopulationMethod === 'dynamically') {
      selectedArticles = await this.getDynamicArticles(component);
    } else if (contentPopulationMethod === 'dynamic_with_manual_additions') {
      selectedArticles = this.getManualArticles(component).concat(await this.getDynamicArticles(component));
      selectedArticles.sort((a, b) => (a.date > b.date) ? -1 : 1);
      const max = selectedArticles.length > 7 ? 7 : selectedArticles.length;
      selectedArticles = selectedArticles.slice(0, max);
    } else {
      selectedArticles = this.getManualArticles(component);
    }

    if (selectedArticles.length > 0) {
      contentPackageArticles = selectedArticles.map((article) => {
        const index = this.articles.findIndex((libArticle) => libArticle.system.id === article.system.id);

        if (index >= 0) {
          this.articles.splice(index, 1);
        }

        const type = article.elements.event_date_time.value ? 'event' : 'article';
        const title = article.elements.title.value;
        const cta = {
          url: `/article/${article.elements.url.value}`,
          text: type === 'event' ? 'View Event Details' : 'Read Article',
        };
        const image = article.elements.primary_image.value.length > 0 ? {
          url: article.elements.primary_image.value[0].url,
          alt: article.elements.primary_image.value[0].description,
          responsive: this.dataService.remodelImageSrc(article.elements.primary_image.value[0].url),
        } : {};
        const eyebrow = dayjs.utc(article.date).format('MMM D, YYYY');

        return {
          type,
          title,
          cta,
          image,
          eyebrow,
        };
      });

      this.components.push({
        componentType: this.modulesService.moduleTypes.content_package,
        articles: contentPackageArticles,
        title: component.elements.title.value
      });
    }
  }

  async subCategoryPathing(categoryTaxonomy) {
    const target = findByCodeName(categoryTaxonomy.terms, this.category.codename);
    const codeNames = target.terms.map((subCategory) => (subCategory.codename));

    // if any manually set items just return those

    const paths = [];


    const pathingCodeName = this.categoryPageData.elements.additional_modules.value.find((module) => {
      const component = this.modularContent[module];

      return component.system.type === this.modulesService.moduleTypes.category_pathing_std;
    })


    if(pathingCodeName){
      this.componentService.modularContent = this.modularContent;
      const processed = this.componentService.processStandardCategoryPathingComponent(this.modularContent[pathingCodeName], true)
      paths.push(...processed.categories)
      

      this.components.push({
        componentType: this.modulesService.moduleTypes.category_pathing,
        page: 'category',
        categories: paths,
        headline: processed.headline,
        categoryName: `${this.category.name} for ${this.audience.name}`,
      });


      return;
    }


    const categoryPages = await this.kontentDeliveryService.getItem(null, {
      'system.type': 'taxonomy_custom_content',
      'order': 'system.last_modified[desc]',
      'elements': 'category_page_url,categories,audiences',
      'elements.category_page_url[nempty]': true,
      'elements.categories[any]': codeNames.join(','),
      'elements.audiences[any]': this.audience.codename,
    });

  

    target.terms.forEach((term) => {
      const page = categoryPages.items.find((page) => {
        const find = page.elements.categories.value.find((x) => x.codename === term.codename);
        return !!find;
      });

      if (page) {
        paths.push({
          url: `/${page.elements.category_page_url.value}`,
          title: term.name,
          linkType: page.system.type,
        });
      }
    });

    if (paths.length > 0) {
      this.components.push({
        componentType: this.modulesService.moduleTypes.category_pathing,
        page: 'category',
        categories: paths,
        categoryName: `${this.category.name} for ${this.audience.name}`,
      });
    }
  }

  async sameTopicDiffAudience(audienceTaxonomy) {
    let terms = audienceTaxonomy.terms;

    terms = terms.filter((term) => term.codename !== this.audience.codename);

    const categoryPages = await this.kontentDeliveryService.getItem(null, {
      'system.type': 'taxonomy_custom_content',
      'order': 'system.last_modified[desc]',
      'elements': 'category_page_url,categories,audiences',
      'elements.category_page_url[nempty]': true,
      'elements.categories[any]': this.category.codename,
      'elements.audiences[any]': terms.map((term) => term.codename).join(','),
    });
    const paths = [];

    terms.forEach((term) => {
      const page = categoryPages.items.find((page) => {
        const find = page.elements.audiences.value.find((x) => x.codename === term.codename);
        return !!find;
      });

      if (page) {
        paths.push({
          text: term.name,
          url: `/${page.elements.category_page_url.value}`,
        });
      }
    });

    this.components.push({
      componentType: this.modulesService.moduleTypes.topic_different_audience,
      topic: {
        theme: 'dark',
        heading: `${this.category.name} for`,
        links: paths,
      },
    });
  }

  moreStories() {
    const moreStories = this.articles.map((article) => {
      const type = article.elements.event_date_time.value ? 'event' : 'article';
      const title = article.elements.title.value;
      const cta = {
        url: `/article/${article.elements.url.value}`,
        text: type === 'event' ? 'View Event Details' : 'Read Article',
      };
      const image = article.elements.primary_image.value.length > 0 ? {
        url: article.elements.primary_image.value[0].url,
        alt: article.elements.primary_image.value[0].description,
        responsive: this.dataService.remodelImageSrc(article.elements.primary_image.value[0].url),
      } : type === 'event' ? {
        url: '/assets/images/content-package-green-tablet.png',
        alt: title,
      } : {
        url: '/assets/images/content-package-coral-tablet.png',
        alt: title,
      };
      const eyebrow = dayjs.utc(article.date).format('MMM D, YYYY');

      return {
        type,
        title,
        url: cta.url,
        image,
        eyebrow,
      };
    });

    if (moreStories.length > 0) {
      this.components.push({
        componentType: this.modulesService.moduleTypes.category_load_more,
        entries: moreStories,
      });
    }
  }

}
