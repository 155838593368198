<ng-template #pageContent>
  <ng-container *ngFor="let component of components; index as order" [ngSwitch]="component?.componentType">
    <ncoa-standard-page-header *ngSwitchCase="'header'" [image]="component.primary_image.value[0]"
      [headline]="component.title.value" [partnership]="partnership" [description]="component.description.value" ncoa-engageable={{order}}>
    </ncoa-standard-page-header>


    <ncoa-article-body *ngSwitchCase="'body'" [content]="component.value" [styleConfig]="component.styleConfig"
    [requiredFieldError]="component.requiredFieldError" ncoa-engageable={{order}}></ncoa-article-body>

    <ncoa-feature-page-block *ngSwitchCase="componentTypes.featured_page_block" [image]="component.image"
      [headline]="component.headline" [description]="component.description" [type]="component.type"
      [background]="component.background" [audiences]="component.audiences" [url]="component.url"
      [blockCTA]="component.blockCTA"
      [orientation]="component.orientation" ncoa-engageable={{order}}></ncoa-feature-page-block>

    <ncoa-featured-tool-breaker *ngSwitchCase="componentTypes.featured_tool_breaker" [variation]="component.variation"
      [eyebrow]="component.eyebrow" [headline]="component.headline" [description]="component.description"
      [image]="component.image" [cta]="component.cta" ncoa-engageable={{order}}></ncoa-featured-tool-breaker>

    <ncoa-content-package *ngSwitchCase="componentTypes.content_package" [title]="component.title"
      [articles]="component.articles" ncoa-engageable={{order}}></ncoa-content-package>

    <ncoa-donate *ngSwitchCase="componentTypes.call_to_donate" [heading]="component.heading"
      [description]="component.description" [form_desc]="component.form_desc" [background]="component.photo"
      [backgroundCaption]="component.caption" ncoa-engageable={{order}}></ncoa-donate>

      <ncoa-external-form 
      [component]="component" 
      *ngSwitchCase="componentTypes.external_form" 
      ncoa-engageable={{order}} 
      ></ncoa-external-form>

    <ncoa-newsletter *ngSwitchCase="componentTypes.newsletter_signup_block" [heading]="component.heading"
      [description]="component.description" [background]="component.background"
      [backgroundCaption]="component.backgroundCaption" [mobilebackground]="component.mobilebackground" 
      [width]="component.width" [height]="component.height"
      [everyActionFormUrl]="component.everyActionFormUrl"
      [fieldErrorText]="component.fieldErrorText"
      [emailErrorText]="component.emailErrorText" 
      ncoa-engageable={{order}}></ncoa-newsletter>

    <ncoa-subcategory-pathing
      *ngSwitchCase="componentTypes.category_pathing"
      [headline]="component.headline"
      [categories]="component.categories"
      [ncoa-engageable]="order"
      page="standard"
    ></ncoa-subcategory-pathing>

    <ncoa-partners-category
      *ngSwitchCase="componentTypes.partner_grid"
      [heading]="component.heading"
      [partners]="component.partners"
      [colored]="true"
    ></ncoa-partners-category>


    <ncoa-special-pathing
    *ngSwitchCase="'specialPathing'"
    ncoa-engageable={{order}}
    [paths]="component.paths"
    ></ncoa-special-pathing>

    <ncoa-partner-card-block
    *ngSwitchCase="componentTypes.partner_card_block"
    ncoa-engageable={{order}}
    [partners]="component.partners"
    [groupHeader]="component.groupHeader"
    [badgeText]="component.badgeText"
    [color]="component.blockColorScheme"

    >

    </ncoa-partner-card-block>

  </ng-container>
</ng-template>

<div class="standard-page">
  <ncoa-template-default  *ngIf="!minimalPage">
      <ng-container *ngTemplateOutlet="pageContent;"></ng-container>
  </ncoa-template-default>

  <ncoa-template-minimal  *ngIf="minimalPage">
    <ng-container *ngTemplateOutlet="pageContent;"></ng-container>
  </ncoa-template-minimal>
</div>