import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomerActivityService } from './customer-activity.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class TodoStepHistoryService {

  private readonly TODO_STEP_KEY = "todoStepHistory"

  private _stepHistory: {[todoCode: string]: any}

  private _setHistorySubject: Subject<any>;
  public $setHistorySubject;

  constructor(private cas: CustomerActivityService, private user: UserService) { 
    this._stepHistory = JSON.parse(localStorage.getItem(this.TODO_STEP_KEY) ?? "{}")
    this._setHistorySubject = new BehaviorSubject(this._stepHistory);
    this.$setHistorySubject = this._setHistorySubject.asObservable();
  }

  public setHistoryStep(todo: string, step: any, propogate: boolean = true){
    this._stepHistory[todo] = step;
    localStorage.setItem(this.TODO_STEP_KEY, JSON.stringify(this._stepHistory));

    this.cas.updateActivity({[todo]: step}).pipe(take(1)).subscribe(() => {})
    
    if(propogate) this._setHistorySubject.next(this._stepHistory)
  }

  public async getStep(todo: string){

    if(this.user.authSet()){

      const step = await this.cas.getQuestionForID(todo).pipe(take(1)).toPromise()

      if(step){
        return step[0][todo]
      }

    }


    return this._stepHistory?.[todo];
  }

  public clear(){
    localStorage.removeItem(this.TODO_STEP_KEY);
  }

}
