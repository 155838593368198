import { Component, OnInit, Input } from '@angular/core';
import { HomeHeroCTAType, HomeHeroImageType } from './home-hero.types';

@Component({
  selector: 'ncoa-home-hero',
  templateUrl: './home-hero.component.html',
  styleUrls: ['./home-hero.component.scss']
})
export class HomeHero implements OnInit {
  @Input() theme: string;
  @Input() title: string;
  @Input() cta: HomeHeroCTAType;
  @Input() image: HomeHeroImageType;
  titleScale: string = 'default';

  constructor() { }

  ngOnInit(): void {
    this.loadComponent();

    const titleLength = this.title?.replace(/\s+/g, '').length || 0;

    switch (true) {

      case (titleLength < 30):
        this.titleScale = 'larger';
        break;

      case (titleLength >= 30 && titleLength <= 50):
        this.titleScale = 'large';
        break;

      default:
        this.titleScale = 'default';
    }
  }

  loadComponent() {
    const _element = document.querySelector('.header--nav__section-top');

    if (!_element) {
      return;
    }

    _element.addEventListener("animationend", () => {
      const _hero = document.querySelector('.home-hero');
      const _audience = document.querySelector('.component--audience');

      _hero.classList.add('is-loaded');
      if(_audience) _audience.classList.add('is-loaded');

    }, false);

    const _mobileElement = document.querySelector('.header--global');

    _mobileElement.addEventListener("transitionend", () => {
      const _hero = document.querySelector('.home-hero');
      const _audience = document.querySelector('.component--audience');

      _hero.classList.add('is-loaded');
      if(_audience) _audience.classList.add('is-loaded');

    }, false);
  }

}
