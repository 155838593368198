import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'storybook-wrapper',
  templateUrl: './storybook-wrapper.component.html',
  styleUrls: ['./storybook-wrapper.component.scss']
})
export class StorybookWrapper implements OnInit {
  @Input() contain: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
