import { Component, OnInit } from '@angular/core';
import { GlobalService } from '@services/global.service';

@Component({
  selector: 'ncoa-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  privacy: {
    text: string;
    url: string;
  } = {
    text: 'Privacy Policy',
    url: '#',
  };
  dismissed = false;
  constructor(
    private globalService: GlobalService,
  ) { }

  ngOnInit(): void {
    if ( window.localStorage.getItem('cookie_dismissed') ) {
      this.dismissed = true;
      return;
    }

    this.privacy = this.globalService.cookieData;
    this.loadCookies();
  }

  loadCookies() {
    const _cookiesBanner = document.querySelector('.component--cookies');
    const _checkCookie = ((document.cookie || '').match(/^(?:.*;)?\s*cookie_accepted\s*=\s*([^;]+)(?:.*)?$/)||[,null])[1]

    if( !_checkCookie && _cookiesBanner ) {
      _cookiesBanner.classList.add('is-loaded');
    }
  }

  closeCookies( event: Event ) {
    event.preventDefault();

    const _cookiesBanner = document.querySelector('.component--cookies');
    _cookiesBanner.classList.remove('is-loaded');

    if ( window.localStorage ) {
      window.localStorage.setItem('cookie_dismissed', 'true');
      this.dismissed = true;
    }
  }

  setCookies( event: Event ) {
    event.preventDefault();
    document.cookie = 'cookie_accepted=true';
    this.closeCookies( event );
  }
}
