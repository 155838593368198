import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { AssessmentService } from './assessment.service';
import { ToolsPrimaryConfigService } from './tools-primary-config.service';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  assessmentID: string;
  assessmentVersion: string;

  menu: any[] = [];
  sections: any[] = [];
  tabSections: any[] = [];
  profileData: any;

  watchAttributes: any[] = [];

  constructor(
    private tools: ToolsPrimaryConfigService,
    private assessment: AssessmentService,
  ) { }

  async getData() {
    const data = {};

    if (this.tools.rawConfig) {

      // profile header
      const elements = this.tools.rawConfig.elements;
      const headline = elements.header_title.value;
      const subhead = elements.header_subtitle.value;
      const accountTypeUpdateCopy = elements.header_button_copy.value;
      const image = elements.header_icon_image.value.length > 0 ? {
        src: elements.header_icon_image.value[0].url,
        alt: elements.header_icon_image.value[0].description,
      } : null;
      let accountType = 'Myself';

      const userProfileId = this.tools.rawConfig.elements.profile_assessment_id.value;
      const response = await this.assessment.getUserProfile(userProfileId)
        .pipe(take(1))
        .toPromise();

      this.assessmentID = response.assessment.id;
      this.assessmentVersion = response.assessment.version;

      this.sections = response.assessment.sections;
      this.tabSections = this.sections.filter(({ label }) => label.toLowerCase() !== 'header');
      this.profileData = response.profile;

      const accountTypeCatalog = [
        {
          codename: 'own',
          text: 'Myself',
        },
        {
          codename: 'friend_or_family',
          text: 'Family/Friend',
        },
        {
          codename: 'client',
          text: 'My Client',
        },
      ];

      let headerQuestion = this.sections.find(({ label }) => label.toLowerCase() === 'header');
          headerQuestion = headerQuestion.questionGroups[0].questions[0].question;

      headerQuestion.answerGroup.answers = headerQuestion.answerGroup.answers.map((headerQuestionAnswer) => ({
        value: headerQuestionAnswer.value,
        text: headerQuestionAnswer.text,
        displayText: accountTypeCatalog.find((item) => item.codename === headerQuestionAnswer.value).text,
      }));

      headerQuestion['userAnswer'] = this.profileData[headerQuestion.id];

      this.menu = this.tabSections.map(({ label }, index) => ({
        id: `#${label.toLowerCase().replace(/\s+/g, '-').replace(/\W+/g, '-')}`,
        text: label,
        isActive: index === 0
      }));

      data['assessmentData'] = {
        id: this.assessmentID,
        version: this.assessmentVersion,
      };

      data['profileHeader'] = {
        backCTA: {
          url: this.tools.routes.find((route) => (route.type === 'home')).route,
          text: 'Back',
        },
        image,
        headline,
        subhead,
        headerQuestion,
        accountType,
        accountTypeUpdateCopy,
        menu: this.menu,
      };

      // profile panel
      data['profilePanel'] = this.getPanel(0);
    }

    return data;
  }

  getPanel(index) {
    const panelQuestions = [];

    this.tabSections[index].questionGroups.forEach((qGroup) => {
      const { questions, condition } = qGroup;

      questions.forEach((q) => {
        const { question } = q;

        let displayQuestion = true;

        if (question.answerGroup.answerType === 'multiSelect') {
          let answers = this.profileData[question.id];

          if (typeof answers == 'string') {
            answers = [answers];
          }

          question.answerGroup.answers = question.answerGroup.answers.map((answer) => ({
            ...answer,
            isSelected: !!(answers || []).find((key) => key === answer.value),
          }));
        }

        if (condition) {
          const { dependentId, operator, value } = condition;

          let userAnswer = this.profileData[dependentId];

          if (userAnswer) {
            switch (operator) {
              case '=': {
                displayQuestion = userAnswer === value;
                break;
              }
              case 'in': {
                userAnswer = typeof userAnswer == 'string' ? [userAnswer] : userAnswer;
                displayQuestion = !!value.some((item) => userAnswer.includes(item));
                break;
              }
            }
          }
        }

        panelQuestions.push({
          ...question,
          condition,
          displayQuestion,
          error: null,
          userAnswer: this.profileData[question.id],
        });
      });
    });

    return {
      questions: panelQuestions,
      panelID: this.menu[index].id,
      headline: this.menu[index].text,
    };
  }

  async hasNewData(questionResponses) {
    const wait = (ms = 1000) => {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    };

    const pollingFunc = async () => {
      const userProfileId = this.tools.rawConfig.elements.profile_assessment_id.value;
      const response: any = await this.assessment.getUserProfile(userProfileId)
        .pipe(take(1))
        .toPromise();

      if (response && response.profile) {
        return response.profile;
      } else {
        return null;
      }
    };

    const objectAreSame = (newProfileData) => {
      let flag = true;

      this.watchAttributes.forEach(({ id, value }) => {
        const newData = newProfileData[id];

        if (Array.isArray(newData) && newData.length > 0) {
          const x = newData[0];

          if (typeof x === 'string') {
            const difference = newData.filter((y) => !(value || []).includes(y))
              .concat((value || []).filter((y) => !newData.includes(y)));

            if (difference.length > 0) {
              flag = false;
              return true;
            }
          }
        } else {
          if (newData !== value) {
            flag = false;
            return;
          }
        }
      });

      return flag;
    };

    this.watchAttributes = [];

    questionResponses.forEach(({ questionId }) => {
      this.watchAttributes.push({
        id: questionId,
        value: this.profileData[questionId],
      });
    });

    let newProfileData = await pollingFunc();

    while (newProfileData && objectAreSame(newProfileData)) {
      await wait();
      newProfileData = await pollingFunc();
    }

    newProfileData = await pollingFunc();

    if (newProfileData) {
      this.profileData = newProfileData;
      return true;
    } else {
      return false;
    }
  }
}
