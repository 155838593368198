<div class="amount-question" *ngIf="form" [formGroup]="form">

    <div class="label-tooltip-container">

        <div class="label">
            <label>{{label}} </label>
        </div>

        <div *ngIf="tooltipText" class="amount-tooltip">
            <span>{{tooltipText}}</span>
        </div>

    </div>



    <div class="amount-field" [formGroupName]="formGroupName">

        
        <div class="amount-container">

            <div class="amount-bottom">
                <img src="assets/icons/money.svg">
                <input type="number" [placeholder]="placeholder" formControlName="amount">
            </div>

        </div>

        <div class="period">
            <span>{{typeLabel}}</span>
            <select formControlName="period">
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
                <option value="annual">Annual</option>
            </select>
        </div>

    </div>
</div>