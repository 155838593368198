<div class="component component--cookies" *ngIf="!dismissed">
  <div class="wrapper">
    <div class="cookies">
      <div class="cookies--panel cookies--content">
        <h3>This site uses cookies.</h3>
        <p>
          We use cookies to give you the best experience on our website. For more information on what this means and how we use your data, please see our
          <a href="{{ privacy.url }}">{{ privacy.text }}</a>
        </p>
      </div>
      <div class="cookies--panel cookies--close">
        <a role="button" title="Close cookie banner" aria-label="Close cookie banner" href="javascript: void(0);" (click)="closeCookies($event); $event.stopPropagation();">
          <span>
            <i></i>
            <i></i>
          </span>
          Close
        </a>
      </div>
      <div class="cookies--panel cookies--accept">
        <button class="cookies--accept__button" (click)="setCookies($event); $event.stopPropagation();">Accept</button>
      </div>
    </div>
  </div>
</div>
