<div class="to-do-list-content">
  <section class="list" *ngIf="todos">

    <ng-container *ngFor="let sorted of sortedTodos | keyvalue: keepOrder">

      <ng-container *ngIf="sorted.value.length">

        <h3>{{sorted.key}}</h3>

          <app-to-do
          [content]="content"
          (onArchived)="onArchived.emit($event)"
          (onComplete)="onComplete.emit($event)"
          (onUnarchived)="onUnarchived.emit($event)"
          (onIncomplete)="onIncomplete.emit($event)"
          *ngFor="let todo of sorted.value" 
          (onUpdateListTotal)="onUpdateListTotal.emit($event)"
          [todo]="todo"
          [scrollToTarget]="scrollToTarget"
        ></app-to-do>

      </ng-container>

     

      
    </ng-container>



  </section>
</div>
