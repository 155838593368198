<div class="event-custom-search-page" #eventCustomSearchRoot>
<ncoa-global-nav *ngIf="contentReady"></ncoa-global-nav>
<!-- <img class="event-custom-search-page__loading" src="../../../../assets/images/loader.gif" alt="Loading..." *ngIf="loading" /> -->
<ng-container
  *ngFor="let component of components; index as order"
  [ngSwitch]="component?.componentType"
>
  <!-- Header -->
  <ncoa-standard-page-header
    *ngSwitchCase="'header'"
    [image]="component.primary_image.value[0]"
    [headline]="component.title.value"
    [partnership]="partnership"
    [description]="component.description"
    ncoa-engageable="{{ order }}"
  >
  </ncoa-standard-page-header>
  <!-- <ncoa-custom-list-header
      *ngSwitchCase="'event-list-search'"
      [filterType]="component.filterType"
      [filters]="filters"
      [totalItems]="totalItems"
      [searchKey]="searchKey"
      (searchContent)="searchContent($event)"
      [placeholder]="component.placeholder"
      [dateFilterChangeHandler]="onDateChange.bind(this)"
      [clearQueryHandler]="onClearQuery.bind(this)"
      ncoa-engageable="1"
  ></ncoa-custom-list-header> -->
  <ncoa-event-results-list
    *ngSwitchCase="'event-list'"
    [items]="items"
    [title]="''"
    [hasMoreItems]="hasMoreItems"
    [getMoreItems]="getMoreContents.bind(this)"
  ></ncoa-event-results-list>
  <ncoa-content-package
    *ngSwitchCase="componentTypes.content_package"
    [title]="component.title"
    [articles]="component.articles"
    ncoa-engageable="{{ order }}"
  ></ncoa-content-package>
  <ncoa-feature-page-block
    *ngSwitchCase="componentTypes.featured_page_block"
    [image]="component.image"
    [headline]="component.headline"
    [description]="component.description"
    [type]="component.type"
    [background]="component.background"
    [audiences]="component.audiences"
    [url]="component.url"
    [blockCTA]="component.blockCTA"
    [orientation]="component.orientation"
    ncoa-engageable="{{ order }}"
  ></ncoa-feature-page-block>
  <ncoa-featured-tool-breaker
    *ngSwitchCase="componentTypes.featured_tool_breaker"
    [variation]="component.variation"
    [eyebrow]="component.eyebrow"
    [headline]="component.headline"
    [description]="component.description"
    [image]="component.image"
    [cta]="component.cta"
    ncoa-engageable="{{ order }}"
  ></ncoa-featured-tool-breaker>
  <ncoa-donate
    *ngSwitchCase="componentTypes.call_to_donate"
    [heading]="component.heading"
    [description]="component.description"
    [form_desc]="component.form_desc"
    [background]="component.photo"
    [backgroundCaption]="component.caption"
    ncoa-engageable="{{ order }}"
  ></ncoa-donate>
  <ncoa-external-form
    *ngSwitchCase="componentTypes.external_form"
    [component]="component"
    ncoa-engageable="{{ order }}"
  ></ncoa-external-form>
  <ncoa-newsletter
    *ngSwitchCase="componentTypes.newsletter_signup_block"
    [heading]="component.heading"
    [description]="component.description"
    [background]="component.background"
    [backgroundCaption]="component.backgroundCaption"
    [mobilebackground]="component.mobilebackground"
    [width]="component.width"
    [height]="component.height"
    [everyActionFormUrl]="component.everyActionFormUrl"
    [fieldErrorText]="component.fieldErrorText"
    [emailErrorText]="component.emailErrorText" 
    ncoa-engageable="{{ order }}"
  ></ncoa-newsletter>
  <app-partners
    *ngSwitchCase="componentTypes.partner_grid"
    [heading]="component.heading"
    [partners]="component.partners"
    ncoa-engageable="{{ order }}"
  ></app-partners>
  <ncoa-subcategory-pathing
    *ngSwitchCase="componentTypes.category_pathing"
    [headline]="component.headline"
    [categories]="component.categories"
    [ncoa-engageable]="order"
    page="standard"
  ></ncoa-subcategory-pathing>
  <ncoa-topic
    *ngSwitchCase="componentTypes.topic_different_audience"
    [theme]="component.theme"
    [topic]="component.topic"
    ncoa-engageable="{{ order }}"
  ></ncoa-topic>
  <ncoa-load-more
    *ngSwitchCase="componentTypes.category_load_more"
    [entries]="component.entries"
    ncoa-engageable="{{ order }}"
  ></ncoa-load-more>
  <ncoa-audience
    *ngSwitchCase="componentTypes.cross_pathing_module"
    [audience]="component.audience"
    ncoa-engageable="{{ order }}"
  ></ncoa-audience>
  <ncoa-special-pathing
    *ngSwitchCase="'specialPathing'"
    [paths]="component.paths"
  ></ncoa-special-pathing>
  <ncoa-article-body *ngSwitchCase="'body'" [content]="component.value" [styleConfig]="component.styleConfig"
  [requiredFieldError]="component.requiredFieldError" ></ncoa-article-body>
</ng-container>
</div>
<ncoa-global-footer></ncoa-global-footer>
<!-- <div class="standard-page">
  <ncoa-template-default>
    <ng-container *ngTemplateOutlet="pageContent"></ng-container>
  </ncoa-template-default>
</div> -->
