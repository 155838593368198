<section *ngIf="categories" class="component category-pathing">
  <div class="category-pathing__container">
    <h2 class="heading" *ngIf="page == 'homepage'">{{ headline || 'Featured Topics' }}</h2>
    <h2 class="heading" *ngIf="page == 'category'">Explore More<span class="highlight"> {{ categoryName }}</span></h2>
    <h2 class="heading" *ngIf="page == 'standard'">{{ headline }}</h2>
    <div class="category-pathing__wrapper">
      <div *ngIf="categories?.length > 1">
        <svg class="icon mobile" width="102" height="280" viewBox="0 0 102 280" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M102 0C42.7266 19.5569 0 74.8474 0 140C0 205.153 42.7266 260.443 102 280V0Z" fill="#FAFAFA" />
        </svg>
      </div>
      <div *ngIf="categories?.length !== 2">
        <svg class="icon desktop" width="789" height="264" viewBox="0 0 789 264" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M395 264C573.281 264 726.046 154.938 790 0L0 0C63.9538 154.938 216.719 264 395 264Z"
            fill="#FAFAFA" />
        </svg>
      </div>
      <div *ngIf="categories?.length == 2">
        <svg class="icon desktop" width="563" height="188" viewBox="0 0 563 188" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M281.5 188C408.554 188 517.423 110.335 563 0L0 0C45.5772 110.335 154.446 188 281.5 188Z"
            fill="#FAFAFA" />
        </svg>
      </div>
      <ul *ngIf="categories?.length < 2" class="one">
        <li class="category--link__item" *ngFor="let category of categories">
          <h3 class="title">
            {{ category.title }} <span *ngIf="category.audiences">for <div *ngFor="let audience of category.audiences">
                {{ audience.name }}</div></span>
          </h3>
          <ng-container *ngIf="category.linkType == 'standard_page'; else elseLink">
            <a class="cta" href="{{ category.url }}">
              <span class="cta--text">Explore More</span><span class="icons__icon--arrow-right"></span></a>
          </ng-container>
          <ng-template #elseLink>
            <a class="cta" aria-label="Click here to Explore More" href="{{ category.url }}">
              <span class="cta--text">Explore More</span><span class="icons__icon--arrow-right"></span>
            </a>
          </ng-template>
        </li>
      </ul>
      <div *ngIf="categories?.length > 1">
        <ul *ngIf="categories?.length % 2 == 0" class="even">
          <li class="category--link__item" *ngFor="let category of categories">
            <h3 class="title">
              {{ category.title }} <span *ngIf="category.audiences">for <div
                  *ngFor="let audience of category.audiences">{{ audience.name }}</div></span>
            </h3>
            <ng-container *ngIf="category.linkType == 'standard_page'; else elseLink">
              <a class="cta" href="{{ category.url }}">
                <span class="cta--text">Explore More</span><span class="icons__icon--arrow-right"></span></a>
            </ng-container>
            <ng-template #elseLink>
              <a class="cta" aria-label="Click here to Explore More" href="{{ category.url }}">
                <span class="cta--text">Explore More</span><span class="icons__icon--arrow-right"></span>
              </a>
            </ng-template>
          </li>
        </ul>
        <ul *ngIf="categories?.length % 2 == 1" class="odd">
          <li class="category--link__item" *ngFor="let category of categories">
            <h3 class="title">
              {{ category.title }} <span *ngIf="category.audiences">for <div
                  *ngFor="let audience of category.audiences">{{ audience.name }}</div></span>
            </h3>
            <ng-container *ngIf="category.linkType == 'standard_page'; else elseLink">
              <a class="cta" href="{{ category.url }}">
                <span class="cta--text">Explore More</span><span class="icons__icon--arrow-right"></span></a>
            </ng-container>
            <ng-template #elseLink>
              <a class="cta" aria-label="Click here to Explore More" href="{{ category.url }}">
                <span class="cta--text">Explore More</span><span class="icons__icon--arrow-right"></span>
              </a>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
