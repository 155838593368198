import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KontentDeliveryService } from './kontent-delivery.service';
import { env } from 'process';
@Injectable({
    providedIn: 'root'
})
export class AwsCachedContentService {
    constructor(private http: HttpClient,
        private kontentDeliveryService: KontentDeliveryService

    ) { }

    PREVIEW = environment.API_BASE_URL.includes('preview');

    public async getAwsCachedContentAndCache  (codename: string) {
        const cache = await caches.open('ncoa-cached-kontent');
        if(this.PREVIEW){
            codename =`preview-${codename}`;
        }
        const url = `${environment.ncoaApiBaseUrl}/infra/getCacheditem/${codename}`
 
        const cacheKey = `cachedKontent/${codename}`;
        let cachedItem = await cache.match(cacheKey);
        if(cachedItem){
            const parsedResponse = JSON.parse(await cachedItem.text());
            return parsedResponse.data
        }
        if (!cachedItem) {
            try{
                const getResponse = await this.http.get(url, { responseType: 'text' }).toPromise();;
                const parsedResponse = JSON.parse(getResponse);
                if(!parsedResponse.data){
                    return null;
                }
                const responseToCache = new Response(getResponse, {
                    headers: { 'Content-Type': 'application/json' }
                  });
                await cache.put(cacheKey, responseToCache);

                return parsedResponse.data
            }catch(e){ 
                return null;
            }
        }
    }

    
}
