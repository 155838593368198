import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'next-step',
  templateUrl: './next-step.component.html',
  styleUrls: ['./next-step.component.scss']
})
export class NextStepComponent implements OnInit {

  @Input() content;
  @Input() ctaButton;
  @Input() modalData;
  @Input() icon;
  @Input() contextClass;

  @Output() print: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() showModal: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  ctaClick(event) {
      if (event === 'print') this.print.emit(event);
      if (event === 'save') this.save.emit(event);
      if (event === 'reminder') this.showModal.emit(this.modalData);
      if (event === 'email-report') this.showModal.emit(this.modalData);
  }

}
