import { Component, OnInit, Input } from '@angular/core';
import { ContentPackageCTAType } from './content-package.types';
import { ArticleCardType } from '@components/article-card/article-card.types';
import { ArticleCardGroupType } from '@components/article-card-group/article-card-group.types';

@Component({
  selector: 'ncoa-content-package',
  templateUrl: './content-package.component.html',
  styleUrls: ['./content-package.component.scss']
})
export class ContentPackage implements OnInit {
  @Input() type?: string;
  @Input() title: string;
  @Input() cta: ContentPackageCTAType;
  @Input() articles: ArticleCardType[];
  @Input() loadMore: boolean = false;

  slimTitle: boolean = false;
  articleGroups: ArticleCardGroupType[] = [];

  constructor() { }

  ngOnInit(): void {

    if ( this.articles.length ) {
      this.type = `${this.articles.length}up`;
    }

    // Group articles according to count
    switch ( this.articles.length ) {

      // 1 Up
      case 1:
        this.articleGroups.push({
          type: '1up',
          articles: this.articles
        });
        break;

      // 2 Up
      case 2:
        this.articleGroups.push({
          type: '2up',
          articles: this.articles
        });
        break;

      // 3 Up
      case 3:
        this.articleGroups.push({
          type: '3up',
          articles: this.articles
        });
        break;

      // 4 Up
      case 4:
        this.articleGroups.push({
          type: '4up',
          articles: this.articles
        });
        this.slimTitle = true;
        break;

      // 5 Up (2Up + 3Up)
      case 5:
        this.articleGroups.push(
        {
          type: '2up',
          articles: this.articles.slice(0, 2),
        },
        {
          type: '3up',
          articles: this.articles.slice(2, 5),
        });
        break;

      // 6 Up (4Up + 2Up)
      case 6:
        this.articleGroups.push(
        {
          type: '4up',
          articles: this.articles.slice(0, 4),
        },
        {
          type: '2up',
          articles: this.articles.slice(4, 6),
        });
        this.slimTitle = true;
        break;

      // 7 Up (4Up + 3Up)
      case 7:
        this.articleGroups.push(
        {
          type: '4up',
          articles: this.articles.slice(0, 4),
        },
        {
          type: '3up',
          articles: this.articles.slice(4, 7),
        });
        this.slimTitle = true;
        break;
    }
  }

  loadMoreArticles() {

  }

  ngAfterViewInit() {
    this.checkComponentIsInView();
  }

  checkComponentIsInView() {
    const _element = document.querySelectorAll('.article-card-group__list');
    const root = document.documentElement;
    let ctr1 = 1;
    let ctr2 = 1;

    const _contentPackage = document.querySelectorAll('.content-package__content');
    let initialPos = window.pageYOffset;

    _element.forEach(element => {
      const articles = element.querySelectorAll('.article-card');

      articles.forEach(card => {
        const title = card.querySelector('.article-card__title');
        const cta = card.querySelector('.article-card__cta');

        title.addEventListener('focus', () => {
          if (!card.classList.contains('in-view')) {
            card.classList.add('in-view');
          }
        });

        cta.addEventListener('focus', () => {
          if (!card.classList.contains('in-view')) {
            card.classList.add('in-view');
          }
        });
      });
    });

    // @TODO: Use HostListener decorator to handle DOM Events.
    document.addEventListener( 'scroll', () => {

      _element.forEach( _item => {

        let _article = _item.querySelectorAll('.article-card');

        _article.forEach( _card => {
          if (this.isInView( _card, (window.innerHeight / 2) )) {
            _card.classList.add('in-view');
          } else {
            if (!_card.contains(document.activeElement)) {
              //_card.classList.remove('in-view')
            }
          }
          // this.isInView( _card, (window.innerHeight / 2) ) ? _card.classList.add('in-view') : _card.classList.remove('in-view');
        });
      });

      _contentPackage.forEach( (_item: HTMLElement) => {

        const _ref = _item.querySelector('.article-card-group__list');

        if (_ref) {
          const _rect = _ref.getBoundingClientRect();

          if( _rect.top < ((window.innerHeight / 4) * 3) && _rect.bottom > 0 ) {
            let _currentPos = window.pageYOffset;

            const _coral = <HTMLElement>_item.querySelector('.coral-circle');
            const _green = <HTMLElement>_item.querySelector('.green-circle');

            if( initialPos > _currentPos ){
              _coral.style.setProperty('--coralPosY', `${ctr1--}px`);
              _green.style.setProperty('--greenPosY', `${ctr2--}px`);
            } else {
              _coral.style.setProperty('--coralPosY', `${ctr1++}px`);
              _green.style.setProperty('--greenPosY', `${ctr2++}px`);
            }

            initialPos = _currentPos;
          }

          if( (window.scrollY || window.pageYOffset) < _rect.top ) {
            ctr1 = 1;
            ctr2 = 1;
          }
        }

      });
    });

  }

  isInView( _element, _offset ) {
    let _componentPosition = _element.getBoundingClientRect();

    if( _componentPosition.top <= _offset ) {
      return true;
    } else {
      return false;
    }

  }

}
