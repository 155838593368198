<div class="component component--custom-list-header" [ngClass]="hideSearchBar ? 'hide' : {}">
  <div class="wrapper">
    <div class="list-header">
      <h3 class="list-header--heading" tabindex="-1" [attr.aria-label]="'There are ' + totalItems + ( totalItems > 1 ? ' Results ' : ' Result ' ) + ' for your Keyword'">{{ totalItems }} Search Result{{ totalItems != 1 ? 's' : '' }} for:</h3>
      <form class="list-header--form" (ngSubmit)="onSubmit()">
        <div class="field spacer">
          <input
            type="text"
            name="q"
            aria-label="Type your Keyword here"
            [attr.placeholder]="placeholder ? placeholder : 'ex. Prescription Assistance'"
            (input)="onInputChange($event.target.value)"
            [value]="searchKey"
          />
          <button type="submit" value="Submit">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.1527 15.0943H16.0686L15.6844 14.7238C17.0292 13.1595 17.8388 11.1286 17.8388 8.91938C17.8388 3.99314 13.8456 0 8.91938 0C3.99314 0 0 3.99314 0 8.91938C0 13.8456 3.99314 17.8388 8.91938 17.8388C11.1286 17.8388 13.1595 17.0292 14.7238 15.6844L15.0943 16.0686V17.1527L21.9554 24L24 21.9554L17.1527 15.0943ZM8.91938 15.0943C5.50257 15.0943 2.74443 12.3362 2.74443 8.91938C2.74443 5.50257 5.50257 2.74443 8.91938 2.74443C12.3362 2.74443 15.0943 5.50257 15.0943 8.91938C15.0943 12.3362 12.3362 15.0943 8.91938 15.0943Z" fill="#0B4A5D"/>
            </svg>
            <span>Search</span>
          </button>
        </div>
        <div>

        </div>
        <div class="search-bar-container ">
          <input
            class="reset-btn"
            type="reset"
            value="Clear Field"
            (click)="onInputChange('')"
          >
          <div  class='search-bar-links'[innerHTML]="searchBarLink" ><p><a href="/"></a></p></div>
        </div>
      </form>
    </div>
  </div>
</div>

<div [ngClass]="{
  component: true,
  'component--custom-filter': true,
  'component--custom-filter-date': filterType === 'date'
}"
[ngStyle]="{
  'marginTop': marginTop !== null ? marginTop : '-65px'
}">
  <div class="wrapper">
    <div class="list-filter">
      <div class="list-filter--header">
        <div class="list-filter--header--item" *ngIf="filters && filters.length > 0">
          <p>Filter by:</p>
          <button
            class="js-filter-trigger"
            aria-label="Show Filters"
            (click)="toggleFilters( $event )"
          >
            <span class="indicator">{{ filterIndicator }}</span>
            {{ filterTextToggle }}
            <span *ngIf="!isFilterOpen" class="counter">{{ filterCount > 0 ? '('+filterCount+')' : '' }}</span>
            <svg *ngIf="!isFilterOpen" width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1555 13.4909C12.8246 13.8303 12.4109 14 11.9972 14C11.5835 14 11.1698 13.8303 10.8388 13.4909L0.496435 2.92727C-0.165478 2.24848 -0.165478 1.18788 0.496435 0.509091C1.15835 -0.169697 2.1926 -0.169697 2.85451 0.509091L11.9972 10.1948L21.1812 0.551514C21.8431 -0.127274 22.8774 -0.127274 23.5397 0.551514C24.1602 1.18788 24.1602 2.24848 23.4979 2.92727L13.1555 13.4909Z" fill="#0B4A5D"/>
            </svg>
            <svg *ngIf="isFilterOpen" width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8445 0.509094C11.1754 0.169701 11.5891 1.08499e-06 12.0028 1.04883e-06C12.4165 1.01266e-06 12.8302 0.169701 13.1612 0.509094L23.5036 11.0727C24.1655 11.7515 24.1655 12.8121 23.5036 13.4909C22.8417 14.1697 21.8074 14.1697 21.1455 13.4909L12.0028 3.80517L2.81879 13.4485C2.15688 14.1273 1.12264 14.1273 0.460314 13.4485C-0.160229 12.8121 -0.160235 11.7515 0.502092 11.0727L10.8445 0.509094Z" fill="#0B4A5D"/>
            </svg>
          </button>
        </div>
        <ng-container  *ngIf="filterByDate?.length > 0">
          <div class="component--custom-filter-date" >
          <div class="list-filter__date-filter">
            <p>{{resourceTitle}} from:</p>
  
            <div class="list-filter__date-filter-field">

              <ng-container  *ngIf="filterByDate.includes('month')">
                <select class="list-filter__date-filter-select" (change)="onMonthFilterSelect($event.currentTarget.value)" #dateFilterSelect>
                  <option [value]="null" [selected]="filterMonth === null">All Months</option>
                  <option *ngFor="let month of filterMonths" [value]="month.value" [selected]="month.value === filterMonth">{{ month.label }}</option>
                </select>
              </ng-container>

              <ng-container  *ngIf="filterByDate.includes('year')">               
                <select class="list-filter__date-filter-select" (change)="onYearFilterSelect($event.currentTarget.value)" #dateFilterSelect>
                  <option [value]="null" [selected]="filterByDate === null">All Years</option>
                  <option *ngFor="let year of filterYears" [value]="year" [selected]="year === filterByDate">{{ year }}</option>
                </select>
              </ng-container>
            </div>
          </div>
        </div>
        </ng-container>

      </div>

      <div class="list-filter--body">

        <ng-container *ngIf="filterType === 'date'">
          <div class="list-filter__date-filter">
            <div class="list-filter__date-filter-label">Events from:</div>

            <div class="list-filter__date-filter-field">
              <select class="list-filter__date-filter-input" (change)="onDateFilterSelect($event.currentTarget.value)" #dateFilterSelect>
                <option value="forwards" selected>Future</option>
                <option value="backwards">Past</option>
              </select>
              <svg class="list-filter__date-filter-chevron" width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.39681 9.63636C9.16042 9.87879 8.86491 10 8.56941 10C8.27391 10 7.97842 9.87879 7.74203 9.63636L0.354596 2.09091C-0.118199 1.60606 -0.118199 0.848485 0.354596 0.363636C0.827391 -0.121212 1.56614 -0.121212 2.03893 0.363636L8.56941 7.28202L15.1294 0.39394C15.6022 -0.0909085 16.341 -0.0909085 16.8141 0.39394C17.2573 0.848485 17.2573 1.60606 16.7842 2.09091L9.39681 9.63636Z" fill="#0B4A5D"/>
              </svg>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="filterType === 'default'">
          <svg class ='svg-filter' width="433" height="90" viewBox="0 0 433 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 24C0 10.7452 11.7452 0 25 0H359C372.255 0 383 10.7452 383 24V46C383 55.9411 391.059 64 401 64H433V90H0V24Z" fill="#F3F6F7"/>
          </svg>

          <div class="button-group">
            <span *ngIf="isFilterOpen && filterCount > 0" class="indicator">{{ filterCount > 0 ? filterCount+' selected' : '' }}</span>
            <button type="button" (click)="toggleAllFilters(true, $event)">Select All</button>
            <button type="button" (click)="toggleAllFilters(false, $event)">Unselect All</button>
            <button type="button" (click)="applyFilter($event)" class="list-filter--apply">Apply Filters</button>
          </div>

          <div class="button-group" *ngIf="showFilterOption">
            <div class="filter-option-group" (click)="toggleFilterCheckbox($event)">
              <input type="checkbox"/>
              <label>Filter on all</label>
              <span class="rect" >
                <svg *ngIf="applyAllFilters" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.09961 13.166C6.45117 13.5176 7.04883 13.5176 7.40039 13.166L17.7363 2.83008C18.0879 2.47852 18.0879 1.88086 17.7363 1.5293L16.4707 0.263672C16.1191 -0.0878906 15.5566 -0.0878906 15.2051 0.263672L6.76758 8.70117L2.79492 4.76367C2.44336 4.41211 1.88086 4.41211 1.5293 4.76367L0.263672 6.0293C-0.0878906 6.38086 -0.0878906 6.97852 0.263672 7.33008L6.09961 13.166Z" fill="#0B4A5D"/>
                </svg>
              </span>
            </div>
          </div>

          <!-- <div class="filter-group__wrapper"> -->
            <div class="filter-group"  >
              <ng-container *ngFor="let item of filters; let i = index" >
              <div class="filter-accordion" 
              [ngStyle]="{'grid-column-end': (item.options.length >= 10) ? 'span 2' : 'span 1', 'grid-row-end': (item.options.length/2 > 10) ? 'span 2' : 'span 1'}"
              
              >
              <!-- 'grid-row-end': (item.options.length/2 > 10) ? 'span 2' : 'span 1' -->
                  <button
                    type="button"
                    id="filter-accordion-toggle-{{ i }}"
                    (click)="toggleGroupFilter(i, $event)"
                  >
                    <span><span class="indicator">{{ item.isExpanded ? '-' : '+' }} </span>{{ item.title }}</span>
                  </button>
                  <div
                    id="filter-accordion-{{ i }}"
                    [ngClass]="{'filter-accordion__body': true, 'long': item.options.length > 10}"
                  >
                    <div class="field-group {{ option.isChecked ? 'checked' : '' }}" *ngFor="let option of item.options; let subindex = index">
                      <label [for]="option.value">{{ option.text }}</label>
                      <input
                        [checked]="option.isChecked"
                        (change)="toggleCheck(i, subindex, $event)"
                        type="checkbox" name="option"
                        [id]="option.value"
                        (focus)="toggleFocus($event.target)"
                        (blur)="toggleBlur($event.target)"
                      />
                      <span class="rect">
                        <svg *ngIf="option.isChecked" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.09961 13.166C6.45117 13.5176 7.04883 13.5176 7.40039 13.166L17.7363 2.83008C18.0879 2.47852 18.0879 1.88086 17.7363 1.5293L16.4707 0.263672C16.1191 -0.0878906 15.5566 -0.0878906 15.2051 0.263672L6.76758 8.70117L2.79492 4.76367C2.44336 4.41211 1.88086 4.41211 1.5293 4.76367L0.263672 6.0293C-0.0878906 6.38086 -0.0878906 6.97852 0.263672 7.33008L6.09961 13.166Z" fill="#0B4A5D"/>
                        </svg>
                      </span>
                    </div>
                </div>
              </div>
              </ng-container>
            </div>
          <!-- </div> -->
        </ng-container>

        <div class="list-filter--body__item" *ngIf="filterType === 'default'">
          <div><button (click)="applyFilter($event)" class="list-filter--apply">Apply Filters</button></div>
        </div>

      </div>

    </div>
  </div>
</div>
