<div
    [ngClass]="{
      'content-package': true,
      'content-package--slim-title': slimTitle
    }"
    *ngIf="articleGroups.length"
  >
  <div class="component content-package__content">
    <div class="content-package__header">
      <h2 *ngIf="title" class="content-package__title">{{ title }}</h2>
      <a *ngIf="cta" href="{{ cta.url }}" class="content-package__cta">
        <span class="content-package__cta-text">{{ cta.text }}</span>
        <span class="content-package__cta-icon" aria-hidden="true"></span>
      </a>
    </div>
    <div class="content-package__body" #contentPackageBody>
      <ncoa-article-card-group
        *ngFor="let articleGroup of articleGroups"
        [type]="articleGroup.type"
        [articles]="articleGroup.articles"
      ></ncoa-article-card-group>
    </div>
    <div class="content-package__footer" *ngIf="loadMore">
      <button class="content-package__loadmore" (click)="loadMoreArticles()">
        <span class="content-package__loadmore-icon" aria-hidden="true"></span>
        <span class="content-package__loadmore-text">Load More Articles</span>
      </button>
    </div>
  </div>
</div>
