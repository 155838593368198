<div class="component component-breakdown-plans" [ngClass]="{'show-only-considerations': showOnlyConsiderations}">
  <div class="wrapper">
    <div *ngIf="!showOnlyConsiderations" class="plans--header">
      <h1>
        <span>{{ heading }}</span>
        <span>
          <svg width="22" height="34" viewBox="0 0 22 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="7" stroke="#0B4A5D" stroke-width="8"/>
            <rect x="10" y="21" width="2" height="13" fill="#0B4A5D"/>
          </svg>
          {{ zipcode }}
        </span>
      </h1>
      <div [innerHTML]="description">{{ description }}</div>
    </div>

    <div *ngIf="!showOnlyConsiderations" class="plans--meta">
      <p>
        <b>We found {{planCount}} plans available in your area</b><br/>
        <span>
          {{ disclaimer }}
          <span class="tooltip--wrapper">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" [attr.data-title]='disclaimer' (click)="openTooltip( $event )" >
              <circle cx="10" cy="10" r="9" stroke="#0B4A5D" stroke-width="2"/>
              <path d="M10.8491 12.1873H8.97768C9.01578 11.5986 9.19197 11.1174 9.50626 10.7436C9.83007 10.3699 10.1634 10.0382 10.5063 9.74849C10.8301 9.46817 11.111 9.18784 11.3491 8.90752C11.5967 8.61785 11.7205 8.27212 11.7205 7.87032C11.7205 7.44049 11.5777 7.10411 11.292 6.86116C11.0063 6.60887 10.6205 6.48272 10.1348 6.48272C9.47768 6.48272 8.97768 6.71165 8.63483 7.16952C8.29197 7.62738 8.15387 8.24876 8.22054 9.03367L6.40625 8.12262C6.41577 7.63672 6.51577 7.19288 6.70625 6.79108C6.89673 6.37994 7.15387 6.02953 7.47768 5.73987C7.81101 5.44085 8.20149 5.21192 8.64911 5.05307C9.10625 4.88488 9.60149 4.80078 10.1348 4.80078C10.6396 4.80078 11.1063 4.87553 11.5348 5.02504C11.9634 5.1652 12.3301 5.3661 12.6348 5.62774C12.9396 5.88937 13.1777 6.20707 13.3491 6.58084C13.5205 6.9546 13.6063 7.36107 13.6063 7.80024C13.6063 8.1927 13.5539 8.5431 13.4491 8.85146C13.3444 9.15981 13.2063 9.43546 13.0348 9.67841C12.8634 9.91201 12.6729 10.1269 12.4634 10.3232C12.2634 10.5194 12.0634 10.7016 11.8634 10.8698C11.6063 11.0847 11.3777 11.2949 11.1777 11.5005C10.9872 11.7061 10.8777 11.935 10.8491 12.1873ZM11.0205 15.2008H8.77768V13.1124H11.0205V15.2008Z" fill="#0B4A5D"/>
            </svg>

            <span class="tooltip" *ngIf="tooltip">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="closeTooltip( $event )">
                <path d="M14.2446 2.26607C14.6618 1.8489 14.6618 1.17253 14.2446 0.755357C13.8275 0.338185 13.1511 0.338185 12.7339 0.755357L7.5 5.98929L2.26607 0.755357C1.8489 0.338185 1.17253 0.338185 0.755357 0.755357C0.338185 1.17253 0.338185 1.8489 0.755357 2.26607L5.98929 7.5L0.755357 12.7339C0.338185 13.1511 0.338185 13.8275 0.755357 14.2446C1.17253 14.6618 1.8489 14.6618 2.26607 14.2446L7.5 9.01071L12.7339 14.2446C13.1511 14.6618 13.8275 14.6618 14.2446 14.2446C14.6618 13.8275 14.6618 13.1511 14.2446 12.7339L9.01071 7.5L14.2446 2.26607Z" fill="#0B4A5D"/>
              </svg>
              <span [innerHTML]="tooltip"></span>
            </span>
          </span>
        </span>
      </p>

    </div>

    <div class="scroll-start"></div>

    <div *ngIf="showOnlyConsiderations && considerations?.length >= 1" class="plans--control">
      <a href="javascript: void(0);" class="is-active" data-type="type-a" (click)="toggleControls($event)">{{ considerations[0].title?.typeA }}</a>
      <a href="javascript: void(0);" class="" data-type="type-b" (click)="toggleControls($event)">{{ considerations[0].title?.typeB }}</a>
    </div>

    <div *ngIf="!showOnlyConsiderations" class="plans--control">
      <a href="javascript: void(0);" class="is-active" data-type="type-a" (click)="toggleControls($event)">Medicare Advantage (Part C)</a>
      <a href="javascript: void(0);" class="" data-type="type-b" (click)="toggleControls($event)">Medigap + Part D</a>
    </div>

    <div *ngIf="!showOnlyConsiderations" class="plans">
      <div class="plan--items type-a is-active" *ngIf="planA">
        <h3>Medicare Advantage (Part C)</h3>

        <div class="plan--detail">
          <h4 class="plan--detail__heading">Low Cost Plan</h4>
          <div class="plan--detail__breakdown">
            <a href="javascript: void(0);" aria-label="Toggle Plan Details" role="button" class="js-toggle" data-text = 'Medicare Advantage (Part C) - Low Cost Plan' (click)="togglePriceDetails( $event )">
              <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.03705 10.5598C9.31285 10.8255 9.65761 10.9583 10.0024 10.9583C10.3471 10.9583 10.6918 10.8255 10.9676 10.5598L19.5863 2.29128C20.1379 1.75997 20.1379 0.929795 19.5863 0.398484C19.0347 -0.132828 18.1728 -0.132828 17.6212 0.398484L10.0024 7.97986L2.349 0.431691C1.7974 -0.0996203 0.935534 -0.0996203 0.383595 0.431691C-0.133526 0.929796 -0.133528 1.75997 0.418411 2.29128L9.03705 10.5598Z" fill="#0B4A5D"/>
              </svg>
            </a>

            <div *ngFor="let plan of planA.lowCost; let i = index;" [class]="( i == planA.lowCost.length - 1) ? 'plan--detail__cost total' : 'plan--detail__cost'">
              <h3 class="plan--detail__cost-price"><span>$</span>{{ plan.heading }}</h3>
              <p>{{ plan.text }}</p>
            </div>
          </div>
        </div>

        <div class="plan--detail">
          <h4 class="plan--detail__heading">Median Cost Plan</h4>
          <div class="plan--detail__breakdown">
            <a href="javascript: void(0);" aria-label="Toggle Plan Details" role="button" class="js-toggle" data-text = 'Medicare Advantage (Part C) - Median Cost Plan'(click)="togglePriceDetails( $event )">
              <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.03705 10.5598C9.31285 10.8255 9.65761 10.9583 10.0024 10.9583C10.3471 10.9583 10.6918 10.8255 10.9676 10.5598L19.5863 2.29128C20.1379 1.75997 20.1379 0.929795 19.5863 0.398484C19.0347 -0.132828 18.1728 -0.132828 17.6212 0.398484L10.0024 7.97986L2.349 0.431691C1.7974 -0.0996203 0.935534 -0.0996203 0.383595 0.431691C-0.133526 0.929796 -0.133528 1.75997 0.418411 2.29128L9.03705 10.5598Z" fill="#0B4A5D"/>
              </svg>
            </a>

            <div *ngFor="let plan of planA.medianCost; let i = index;" [class]="( i == planA.medianCost.length - 1) ? 'plan--detail__cost total' : 'plan--detail__cost'">
              <h3 class="plan--detail__cost-price"><span>$</span>{{ plan.heading }}</h3>
              <p>{{ plan.text }}</p>
            </div>
          </div>
        </div>

        <div class="plan--detail">
          <h4 class="plan--detail__heading">Highest Cost Plan</h4>
          <div class="plan--detail__breakdown">
            <a href="javascript: void(0);" aria-label="Toggle Plan Details" role="button" class="js-toggle" data-text = 'Medicare Advantage (Part C) - Highest Cost Plan' (click)="togglePriceDetails( $event )">
              <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.03705 10.5598C9.31285 10.8255 9.65761 10.9583 10.0024 10.9583C10.3471 10.9583 10.6918 10.8255 10.9676 10.5598L19.5863 2.29128C20.1379 1.75997 20.1379 0.929795 19.5863 0.398484C19.0347 -0.132828 18.1728 -0.132828 17.6212 0.398484L10.0024 7.97986L2.349 0.431691C1.7974 -0.0996203 0.935534 -0.0996203 0.383595 0.431691C-0.133526 0.929796 -0.133528 1.75997 0.418411 2.29128L9.03705 10.5598Z" fill="#0B4A5D"/>
              </svg>
            </a>

            <div *ngFor="let plan of planA.highCost let i = index;" [class]="( i == planA.highCost.length - 1) ? 'plan--detail__cost total' : 'plan--detail__cost'">
              <h3 class="plan--detail__cost-price"><span>$</span>{{ plan.heading }}</h3>
              <p>{{ plan.text }}</p>
            </div>
          </div>
        </div>

      </div>
      <div class="plan--items type-b" *ngIf="planB">
        <h3>Medigap + Part D</h3>

        <div class="plan--detail">
          <h4 class="plan--detail__heading">Low Cost Plan</h4>
          <div class="plan--detail__breakdown">
            <a href="javascript: void(0);" aria-label="Toggle Plan Details" role="button" class="js-toggle" data-text = 'Medigap + Part D - Low Cost Plan' (click)="togglePriceDetails( $event )">
              <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.03705 10.5598C9.31285 10.8255 9.65761 10.9583 10.0024 10.9583C10.3471 10.9583 10.6918 10.8255 10.9676 10.5598L19.5863 2.29128C20.1379 1.75997 20.1379 0.929795 19.5863 0.398484C19.0347 -0.132828 18.1728 -0.132828 17.6212 0.398484L10.0024 7.97986L2.349 0.431691C1.7974 -0.0996203 0.935534 -0.0996203 0.383595 0.431691C-0.133526 0.929796 -0.133528 1.75997 0.418411 2.29128L9.03705 10.5598Z" fill="#0B4A5D"/>
              </svg>
            </a>

            <div *ngFor="let plan of planB.lowCost; let i = index;" [class]="( i == planB.lowCost.length - 1) ? 'plan--detail__cost total' : 'plan--detail__cost'">
              <h3 class="plan--detail__cost-price"><span>$</span>{{ plan.heading }}</h3>
              <p>{{ plan.text }}</p>
            </div>
          </div>
        </div>

        <div class="plan--detail">
          <h4 class="plan--detail__heading">Median Cost Plan</h4>
          <div class="plan--detail__breakdown">
            <a href="javascript: void(0);" aria-label="Toggle Plan Details" role="button" class="js-toggle" data-text = 'Medigap + Part D - Median Cost Plan' (click)="togglePriceDetails( $event )">
              <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.03705 10.5598C9.31285 10.8255 9.65761 10.9583 10.0024 10.9583C10.3471 10.9583 10.6918 10.8255 10.9676 10.5598L19.5863 2.29128C20.1379 1.75997 20.1379 0.929795 19.5863 0.398484C19.0347 -0.132828 18.1728 -0.132828 17.6212 0.398484L10.0024 7.97986L2.349 0.431691C1.7974 -0.0996203 0.935534 -0.0996203 0.383595 0.431691C-0.133526 0.929796 -0.133528 1.75997 0.418411 2.29128L9.03705 10.5598Z" fill="#0B4A5D"/>
              </svg>
            </a>

            <div *ngFor="let plan of planB.medianCost; let i = index;" [class]="( i == planB.medianCost.length - 1) ? 'plan--detail__cost total' : 'plan--detail__cost'">
              <h3 class="plan--detail__cost-price"><span>$</span>{{ plan.heading }}</h3>
              <p>{{ plan.text }}</p>
            </div>
          </div>
        </div>

        <div class="plan--detail">
          <h4 class="plan--detail__heading">Highest Cost Plan</h4>
          <div class="plan--detail__breakdown">
            <a href="javascript: void(0);" aria-label="Toggle Plan Details" role="button" class="js-toggle" data-text = 'Medigap + Part D - Highest Cost Plan' (click)="togglePriceDetails( $event )">
              <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.03705 10.5598C9.31285 10.8255 9.65761 10.9583 10.0024 10.9583C10.3471 10.9583 10.6918 10.8255 10.9676 10.5598L19.5863 2.29128C20.1379 1.75997 20.1379 0.929795 19.5863 0.398484C19.0347 -0.132828 18.1728 -0.132828 17.6212 0.398484L10.0024 7.97986L2.349 0.431691C1.7974 -0.0996203 0.935534 -0.0996203 0.383595 0.431691C-0.133526 0.929796 -0.133528 1.75997 0.418411 2.29128L9.03705 10.5598Z" fill="#0B4A5D"/>
              </svg>
            </a>

            <div *ngFor="let plan of planB.highCost; let i = index;" [class]="( i == planB.highCost.length - 1) ? 'plan--detail__cost total' : 'plan--detail__cost'">
              <h3 class="plan--detail__cost-price"><span>$</span>{{ plan.heading }}</h3>
              <p>{{ plan.text }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="plans--note" *ngIf="!leadGenConfirmed || !showOnlyConsiderations">
      <div class="plans--note__item" *ngIf = 'noteHeading'>
        <h3>{{ noteHeading }}</h3>
        <div [innerHTML]="noteText">{{ noteText }}</div>
      </div>
      <div class="plans--note__item" *ngIf = 'noteCTA && noteCTA.label'>
        <a [href]="noteCTA.url" (click) = 'onPlanNoteClick($event)'>{{ noteCTA.label }}</a>
      </div>
    </div>
  </div>
</div>

<div class="component component-breakdown-consideration">
  <div class="wrapper">
    <h3>{{ considerationHeading }}</h3>

    <div class="consideration" tabindex="-1">
      <div class="consideration--item" *ngFor="let item of considerations">
        <h4>
          <span>{{ item.heading }}</span>
          <span class="icon" *ngIf="item.tooltip">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" [attr.data-title] = 'item.heading' (click)="toogleConsiderationTooltip( $event )">
              <circle cx="10" cy="10" r="9" stroke="#0B4A5D" stroke-width="2"/>
              <path d="M10.8413 12.1873H8.96987C9.00796 11.5986 9.18415 11.1174 9.49844 10.7436C9.82225 10.3699 10.1556 10.0382 10.4984 9.74849C10.8222 9.46817 11.1032 9.18784 11.3413 8.90752C11.5889 8.61785 11.7127 8.27212 11.7127 7.87032C11.7127 7.44049 11.5699 7.10411 11.2842 6.86116C10.9984 6.60887 10.6127 6.48272 10.127 6.48272C9.46987 6.48272 8.96987 6.71165 8.62701 7.16951C8.28415 7.62738 8.14606 8.24876 8.21272 9.03367L6.39844 8.12261C6.40796 7.63672 6.50796 7.19287 6.69844 6.79108C6.88891 6.37994 7.14606 6.02953 7.46987 5.73987C7.8032 5.44085 8.19368 5.21192 8.64129 5.05307C9.09844 4.88488 9.59368 4.80078 10.127 4.80078C10.6318 4.80078 11.0984 4.87553 11.527 5.02504C11.9556 5.1652 12.3222 5.3661 12.627 5.62774C12.9318 5.88937 13.1699 6.20707 13.3413 6.58084C13.5127 6.9546 13.5984 7.36107 13.5984 7.80024C13.5984 8.1927 13.5461 8.5431 13.4413 8.85146C13.3365 9.15981 13.1984 9.43546 13.027 9.67841C12.8556 9.91201 12.6651 10.1269 12.4556 10.3232C12.2556 10.5194 12.0556 10.7016 11.8556 10.8698C11.5984 11.0847 11.3699 11.2949 11.1699 11.5005C10.9794 11.7061 10.8699 11.935 10.8413 12.1873ZM11.0127 15.2008H8.76987V13.1124H11.0127V15.2008Z" fill="#0B4A5D"/>
            </svg>

            <span class="tooltip">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="closeConsiderationTooltip( $event )">
                <path d="M14.2446 2.26607C14.6618 1.8489 14.6618 1.17253 14.2446 0.755357C13.8275 0.338185 13.1511 0.338185 12.7339 0.755357L7.5 5.98929L2.26607 0.755357C1.8489 0.338185 1.17253 0.338185 0.755357 0.755357C0.338185 1.17253 0.338185 1.8489 0.755357 2.26607L5.98929 7.5L0.755357 12.7339C0.338185 13.1511 0.338185 13.8275 0.755357 14.2446C1.17253 14.6618 1.8489 14.6618 2.26607 14.2446L7.5 9.01071L12.7339 14.2446C13.1511 14.6618 13.8275 14.6618 14.2446 14.2446C14.6618 13.8275 14.6618 13.1511 14.2446 12.7339L9.01071 7.5L14.2446 2.26607Z" fill="#0B4A5D"/>
              </svg>
              <span [innerHTML]="item.tooltip">{{ item.tooltip }}</span>
            </span>
          </span>
        </h4>

        <div *ngIf="showOnlyConsiderations" class="consideration--detail__label">
          <span>{{ item.title.typeA }}</span>
          <span>{{ item.title.typeB }}</span>
        </div>

        <div *ngIf="!showOnlyConsiderations" class="consideration--detail__label">
          <span>Medicare Advantage (Part C) Plans</span>
          <span>Medigap + Part D Plans</span>
        </div>

        <div class="consideration--detail__wrapper">
          <div class="consideration--detail type-a is-open" [innerHTML]="item.description.typeA">
            {{ item.description.typeA }}
          </div>

          <div class="consideration--detail type-b" [innerHTML]="item.description.typeB">
            {{ item.description.typeB }}
          </div>
        </div>
      </div>
    </div>

    <div class="consideration--toggle">
      <a href="javascript: void(0);" (click)="toogleConsiderations( $event );">
        <span class="label">Expand</span>
        <span>
          <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9629 10.6028C10.6872 10.8684 10.3424 11.0013 9.99764 11.0013C9.6529 11.0013 9.30816 10.8684 9.03236 10.6028L0.413696 2.33425C-0.137899 1.80294 -0.137899 0.972764 0.413696 0.441452C0.965289 -0.0898591 1.82716 -0.0898591 2.37876 0.441452L9.99764 8.02282L17.651 0.47466C18.2026 -0.0566515 19.0645 -0.0566515 19.6164 0.47466C20.1335 0.972764 20.1335 1.80294 19.5816 2.33425L10.9629 10.6028Z" fill="#0B4A5D"/>
          </svg>
        </span>
      </a>
    </div>
  </div>
</div>

<div class="scroll-end"></div>
