import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ResultCardImageType, ResultCardCTAType, ResultCardTypeType } from './result-card.types';

@Component({
  selector: 'ncoa-result-card',
  templateUrl: './result-card.component.html',
  styleUrls: ['./result-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResultCardComponent implements OnInit {
  @Input() type: ResultCardTypeType; // Content type
  @Input() eyebrow: string;
  @Input() title: string;
  @Input() description?: string;
  @Input() image: ResultCardImageType;
  @Input() cta: any;
  @Input() listType?: 'default'|'author'|'search'|'categorySearch'|'customSearch' = 'default';

  constructor() { }

  ngOnInit(): void {
    // if type.name is a string make it an array
    if (typeof this.type.name === 'string') {
      this.type.name = [this.type.name];
    }
    this.type.name.sort();
  }

  getCardClass(label:string, type) {
    let cssClass = 'result-card__type ';
    if(this.listType ==='categorySearch'){
      cssClass += 'result-card__type result-card__type--format-label--' + label.toLowerCase().replace(/[\s,/]/g, '-')
    }

    if(type.codename === 'article'){
      cssClass += ' result-card__type--article'
    }
    if(type.codename === 'topic' ){
      cssClass += ' result-card__type--topic'
    }
    if(type.codename === 'event'){
      cssClass += ' result-card__type--event'
    }
    if(type.codename === 'other'){
      cssClass += ' result-card__type--other'
    }
    if(type.codename=== 'landing'){
      cssClass += ' result-card__type--landing'
      type.name = ['Resource']
    }
    if(type.codename === 'author'){
      cssClass += ' result-card__type--author'
    }
    return cssClass
  }

}
