import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { image, cta, audiences } from './standard-page-header.types';

@Component({
  selector: 'ncoa-standard-page-header',
  templateUrl: './standard-page-header.component.html',
  styleUrls: ['./standard-page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StandardPageHeaderComponent implements OnInit {
  @Input() image: image;
  @Input() headline: string;
  @Input() description: string;
  @Input() partnership?: any;
  @Input() cta: cta;
  @Input() audiences: audiences[];

  constructor() { }

  ngOnInit(): void {
  }

}
