<div *ngIf="!data" class="loading-page">
  <img src="../../../../assets/images/loader.gif" alt="Loading" />
</div>

<age-well-planner *ngIf="data">
  <div class="planner-home-page">
    <ncoa-my-planner-header
      *ngIf="data.myPlannerHeader"
      [eyebrow]="data.myPlannerHeader.eyebrow"
      [title]="data.myPlannerHeader.title"
      [description]="data.myPlannerHeader.description"
      [savedContentCount]="data.myPlannerHeader.savedContentCount"
      [feature]="data.myPlannerHeader.feature"
    ></ncoa-my-planner-header>

    <sign-in-banner
      *ngIf="data.signInBanner"
      [title]="data.signInBanner.title"
      [desc]="data.signInBanner.desc"
      [signUpCTA]="data.signInBanner.signUpCTA"
      [signInCTA]="data.signInBanner.signInCTA"
    >
    </sign-in-banner>

    <ncoa-vertical-recommended
      *ngIf="data.recommended3ColGrid"
      [location]="data.recommended3ColGrid.location"
      [heading]="data.recommended3ColGrid.heading"
      [intro]="data.recommended3ColGrid.intro"
      [cards]="data.recommended3ColGrid.cards"
    ></ncoa-vertical-recommended>

    <ncoa-three-up
      *ngIf="data.manualMultiCard3up"
      [heading]="data.manualMultiCard3up.heading"
      [cards]="data.manualMultiCard3up.cards"
    ></ncoa-three-up>

    <ncoa-vertical-pathing
      *ngIf="data.verticalPathing"
      [heading]="data.verticalPathing.heading"
      [items]="data.verticalPathing.items"
    ></ncoa-vertical-pathing>
  </div>
</age-well-planner>
