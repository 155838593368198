import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { VerticalService } from '@services/planner/vertical.service';

@Component({
  selector: 'vertical-topic',
  templateUrl: './topic-page.component.html',
  styleUrls: ['./topic-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalTopic implements OnInit {
  data: any = null;

  constructor(
    private verticalService: VerticalService,
  ) {
    this.verticalService.getData('vertical-topic')
      .then((data) => {
        this.data = data
      });
  }

  ngOnInit(): void { }
}
