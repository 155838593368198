import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'benefit-card',
  templateUrl: './benefit-card.component.html',
  styleUrls: ['./benefit-card.component.scss']
})
export class BenefitCard implements OnInit {

  @Input() index: number;
  @Input() title: string;
  @Input() eyebrow: string;
  @Input() desc: string;

  @Output() showModal: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  onClick(): void {
    this.showModal.emit(this.index);
  }
}
