<age-well-planner *ngIf="data">
  <div class="assessment-estimator">
    <ncoa-breakdown-header
      *ngIf="data.breakdownHeader"
      [id]="data.breakdownHeader.id"
      [isSaved]="data.breakdownHeader.isSaved"
      [codename]="data.breakdownHeader.codename"
      [backLink]="data.breakdownHeader.backLink"
      [profileLink]="data.breakdownHeader.profileLink"
      [headline]="data.breakdownHeader.headline"
      [variationType] = '"estimator"'
    ></ncoa-breakdown-header>

    <div class="lead-gen-confirmed" *ngIf="data.leadGenConfirmed && data?.leadGenConfirmed?.show">
      <div class="lead-gen-confirmed__wrapper">
        <div class="lead-gen-confirmed__col">
          <div class="lead-gen-confirmed__headline">
            <h3>{{ data.leadGenConfirmed.headline }}</h3>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf='!data.leadGenConfirmed.noCheck'>
              <path d="M8.06641 14.1113C8.30078 14.3457 8.69922 14.3457 8.93359 14.1113L15.8242 7.2207C16.0586 6.98633 16.0586 6.58789 15.8242 6.35352L14.9805 5.50977C14.7461 5.27539 14.3711 5.27539 14.1367 5.50977L8.51172 11.1348L5.86328 8.50977C5.62891 8.27539 5.25391 8.27539 5.01953 8.50977L4.17578 9.35352C3.94141 9.58789 3.94141 9.98633 4.17578 10.2207L8.06641 14.1113Z" fill="#0B4A5D"/>
              <circle cx="10" cy="10" r="9" stroke="#0B4A5D" stroke-width="2"/>
            </svg>
          </div>
          <div class="lead-gen-confirmed__body" [innerHTML]="data.leadGenConfirmed.body"></div>
        </div>
        <div class="lead-gen-confirmed__col">
          <div class="lead-gen-confirmed__info" *ngIf="data.leadGenConfirmed.phone_number">
            <div class="lead-gen-confirmed__label">{{ data.leadGenConfirmed.phone_number.eyebrow }}</div>
            <div class="lead-gen-confirmed__content">{{ data.leadGenConfirmed.phone_number.value }}</div>
          </div>
          <div class="lead-gen-confirmed__info" *ngIf="data.leadGenConfirmed.schedule">
            <div class="lead-gen-confirmed__label">{{ data.leadGenConfirmed.schedule.eyebrow }}</div>
            <div class="lead-gen-confirmed__content">
              <a [href]="data.leadGenConfirmed.schedule.value.url" [title]="data.leadGenConfirmed.schedule.value.text" target="_blank">{{ data.leadGenConfirmed.schedule.value.text }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ncoa-breakdown-healthcare
      *ngIf="data.breakdownHealthcare"
      [headline]="data.breakdownHealthcare.headline"
      [body]="data.breakdownHealthcare.body"
      [pricing]="data.breakdownHealthcare.pricing"
    ></ncoa-breakdown-healthcare>

    <ncoa-breakdown-plans
      *ngIf="data.breakdownPlan"
      [heading]="data.breakdownPlan.heading"
      [description]="data.breakdownPlan.description"
      [zipcode]="data.breakdownPlan.zipcode"
      [tooltip]="data.breakdownPlan.tooltip"
      [disclaimer]="data.breakdownPlan.disclaimer"
      [planCount]="data.breakdownPlan.planCount"
      [noteHeading]="data.breakdownPlan.noteHeading"
      [noteText]="data.breakdownPlan.noteText"
      [noteCTA]="data.breakdownPlan.noteCTA"
      [planA]="data.breakdownPlan.planA"
      [planB]="data.breakdownPlan.planB"
      [considerationHeading]="data.breakdownPlan.considerationHeading"
      [considerations]="data.breakdownPlan.considerations"
      [leadGenConfirmed]="data.leadGenConfirmed?.show"
    ></ncoa-breakdown-plans>

    <ncoa-breakdown-next
      *ngIf="data.breakdownNext"
      [heading]="data.breakdownNext.heading"
      [steps]="data.breakdownNext.steps"
    ></ncoa-breakdown-next>

    <ncoa-lead-gen
      *ngIf="data.leadGen && !data.leadGenConfirmed?.show"
      [heading]="data.leadGen.heading"
      [eyebrow]="data.leadGen.eyebrow"
      [intro]="data.leadGen.intro"
      [cta]="data.leadGen.cta"
      [photo]="data.leadGen.photo"
      [icon]="data.leadGen.icon"
    ></ncoa-lead-gen>

    <ncoa-three-up
      *ngIf="data.threeUp"
      [heading]="data.threeUp.heading"
      [cards]="data.threeUp.cards"
      [moreLink]="data.threeUp.moreLink"
    ></ncoa-three-up>
  </div>
</age-well-planner>
