import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ncoa-article-share-mobile',
  templateUrl: './article-share.component.html',
  styleUrls: ['./article-share.component.scss']
})
export class ArticleShareComponent implements OnInit {
  @Input() url: {
    facebook: string,
    twitter: string,
    linkedin: string,
    pinterest: string,
    mailto: string
  }


  //Save to Content for tools
  @Input() saveToContent?: {
    isSaved: false,
  };
  @Output() saveToContentClick?: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onSaveContentClick(){
    this.saveToContentClick.emit();
  }
}
