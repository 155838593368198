<div (window:resize)="onResize()" class="vertical-pathing">
  <div class="vertical-pathing__wrapper">
    <h3 class="vertical-pathing__heading">{{ heading }}</h3>
    <div class="vertical-pathing__body">
      <svg class="background-oval background-oval--desktop" width="789" height="264" viewBox="0 0 789 264" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M395 264C573.281 264 726.046 154.938 790 0L0 0C63.9538 154.938 216.719 264 395 264Z" fill="#FAFAFA"/>
      </svg>
      <svg class="background-oval background-oval--mobile" width="102" height="280" viewBox="0 0 102 280" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M102 0C42.7266 19.5569 0 74.8474 0 140C0 205.153 42.7266 260.443 102 280V0Z" fill="#FAFAFA"/>
      </svg>
      <div class="body-wrapper">
        <div #paths *ngFor="let item of items" class="path">
          <h4 class="path__title">{{ item.title }}</h4>
          <div class="links">
            <a [href]="link.url" class="link" *ngFor="let link of item.links" (click) = 'trackAnalytics($event)'>
              <div><span>{{ link.text }}</span></div>
              <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1316 0.547657L19.6842 7.25106C19.8947 7.46556 20 7.7337 20 8.00184C20 8.26997 19.8947 8.53811 19.6842 8.75262L13.1316 15.456C12.7105 15.885 12.0526 15.885 11.6316 15.456C11.2105 15.027 11.2105 14.3567 11.6316 13.9276L16.4211 9.07438L1.05263 9.07438C0.473684 9.07438 0 8.59173 0 8.00184C0 7.41194 0.473684 6.92929 1.05263 6.92929L16.4211 6.92929L11.6579 2.04922C11.2368 1.6202 11.2368 0.949861 11.6579 0.520575C12.0526 0.118371 12.7105 0.118371 13.1316 0.547657Z" fill="#0B4A5D"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
