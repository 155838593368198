import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataLayerService } from '@services/data-layer.service';
import { BenefitsService } from '@services/planner/benefits.service';
import { TweenLite } from 'gsap';
import { stateOptions } from './benefits-listing.variables';
@Component({
  selector: 'benefits-listing',
  templateUrl: './benefits-listing.component.html',
  styleUrls: ['./benefits-listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BenefitsListing implements OnInit {

  @Input() type: string;
  @Input() backCta: any = null;
  @Input() header: {
    title: string;
    desc: string;
    eyebrow: string;
    backgroundImage: {
      sizes: {
        media: string;
        url: string;
      }[];
      src: string;
      alt: string;
    };
    cta: {
      url: string;
      text: string;
    };
  };
  @Input() subHeaderCTA: {
    url: string;
    text: string;
  };
  @Input() categoryOptions: any[] = [];
  @Input() selectedCategory: any[] = [];
  list: {
    title: string;
    eyebrow: string;
    desc: string;
    full_desc: string;
    cta: {
      url: string;
      text: string;
    };
  }[] = [];
  shownItems: number = 0;
  stateOptions: any[] = stateOptions;
  selectedCategories: any[] = [];
  preSelectedCategories: any[] = [];
  superCategories = [];
  selectedState: string = '';
  isLoaded: boolean = true;
  isLoadingMore: boolean = false;
  isFilterOpen: boolean = false;
  isCategoryFilterOpen: boolean = false;
  firstElement: any;
  lastElement: any;
  firstElementFunc: any;
  lastElementFunc: any;
  modalData: any = {
    title: null,
    eyebrow: null,
    desc: null,
    cta: {
      url: null,
      text: null,
    },
  };
  allCategory: any;

  constructor(
    private benefitsService: BenefitsService,
    private route: ActivatedRoute,
    private router: Router,
    private dataLayerService: DataLayerService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.selectedCategories = params['categories'] ? params['categories'].split(',') : [];
      this.preSelectedCategories = this.selectedCategories;
      this.selectedState = params['state'] || '';
    });

    const firstElementFunc = (e) => {
      const keyID = (e.charCode) ? e.charCode : ((e.which) ? e.which : e.keyCode);

      if (keyID === 9 && e.shiftKey && this.lastElement) {
        e.preventDefault();
        this.lastElement.focus();
      }
    };

    const lastElementFunc = (e) => {
      const keyID = (e.charCode) ? e.charCode : ((e.which) ? e.which : e.keyCode);

      if (keyID === 9 && !e.shiftKey && this.firstElement) {
        e.preventDefault();
        this.firstElement.focus();
      }
    };

    this.firstElementFunc = firstElementFunc;
    this.lastElementFunc = lastElementFunc;

    this.allCategory = this.categoryOptions.find((category) => category.value === 'category_161');
  }

  ngOnInit(): void {
    this.isLoaded = false;

    if (this.selectedCategories.length > 0) {
      this.selectedCategories.forEach((key) => {
        const index = this.categoryOptions.findIndex((category) => category.value === key);

        if (index >= 0) {
          this.categoryOptions[index].isSelected = true;
        }
      });
    }

    this.benefitsService.getCategories().then(res=>{
      this.superCategories = res
      this.loadData().then((arr) => this.processRawData(arr));
      this.isLoaded=true;
    }
    )
  }

  goBack(e){
    window.location.href = this.backCta.url;
  }

  async loadData() {
    if (this.selectedState !== "") {
      const state = this.selectedState;
      let categories = [];

      if (this.selectedCategories.length > 0) {
        categories = this.selectedCategories.map(value => {
          return value;
        });
      }
      if (this.selectedCategory.length > 0) {
        const category = this.selectedCategory[0].codename;
        categories = [category];
      }
      return await Promise.all(categories.map(async category => {
        return this.benefitsService.getBenefits(state, category);
      })).then((responses: any) => {
        return responses.flat();
      });
    } else {
      this.list = [];
      this.isLoaded = true;
    }
  }

  processRawData(arr) {
    this.list = [];
    

    if ((arr || []).length > 0) {
      (arr || []).forEach((data) => {
       let body = '';

          if (data.externalDescription?.en && data.externalDescription.en.trim().length > 0) {
            body += `<p>${data.externalDescription.en}</p>`;
          }
          if(data.faqs && data.faqs.length > 0){
            Object.keys(data['faqs']).forEach((key) => {
              const question = data.faqs[key].question.en;
              const answer = data.faqs[key].answer.en;
            if (question&&
                (!question.toLowerCase().match(/who should i contact/g) &&
                !question.toLowerCase().match(/what will i need/g))) {
              if (question && question.trim().length > 0) {
                body += `<h4>${question}</h4>`;
              }

              if (answer && answer.trim().length > 0) {
                body += `<p>${answer}</p>`;
              }
            }
        })}

        const card = {
          title: data.name?.en ? data.name.en : data.name,
          desc: data.shortDescription?.en? data.shortDescription.en : data.shortDescription,
          full_desc: body,
          eyebrow: data.category.name,
          cta: {
            url: data.url,
            text: 'Visit Program Website'
          },
        };

        this.list.push(card);
      });

      this.shownItems = this.list.length > 5 ? 5 : this.list.length;
    }
    this.isLoaded=true;
  }

  loadMore(): void {
    this.isLoadingMore = true;

    setTimeout(() => {
      let newCount = this.shownItems + 5;
          newCount = this.list.length > newCount ? newCount : this.list.length;

      this.shownItems = newCount;
      this.isLoadingMore = false;

      setTimeout(() => {
        const cards = Array.from(document.querySelectorAll('.benefit-card'));
        const card = cards[newCount - 5];

        if (card) {
          const interactive: any = card.querySelector('a,button');

          if (interactive) {
            interactive.focus();
          }
        }
      }, 500);
    }, 2000);
  }

  toggleFilter(): void {
    const filterBody = document.querySelector('.filter__body');

    if (filterBody) {
      const isOpen = filterBody.clientHeight > 0;

      if (window.innerWidth < 1240) {
        if (isOpen) {
          this.isFilterOpen = false;
          new TweenLite(filterBody, { height: 0, duration: 0.25 });
        } else {
          this.isFilterOpen = true;
          new TweenLite(filterBody, { height: 'auto', duration: 0.25 });
        }
      } else {
        if (isOpen) {
          this.isFilterOpen = false;
          new TweenLite(filterBody, {
            display: 'none',
            height: 0,
            paddingTop: 0,
            duration: 0.25,
            onComplete: () => {
              const body = document.querySelector('.filter__body');
              Array.from(body.querySelectorAll('a, button, input, textarea, select, details'))
                .forEach((element) => {
                  element.setAttribute('tabindex', '-1');
                });

              this.firstElement.removeEventListener('keydown', this.firstElementFunc, false);
              this.lastElement.removeEventListener('keydown', this.lastElementFunc, false);

              this.firstElement = null;
              this.lastElement = null;
            },
          });
        } else {
          this.isFilterOpen = true;
          new TweenLite(filterBody, {
            display: 'block',
            height: 'auto',
            paddingTop: 73,
            duration: 0.25,
            onComplete: () => {
              const body = document.querySelector('.filter__body');
              Array.from(body.querySelectorAll('a, button, input, textarea, select, details'))
                .forEach((element) => {
                  element.removeAttribute('tabindex');
                });

              const first = document.querySelector('.filter__head--desktop .filter-toggle-btn');
              let last: any = Array.from(filterBody.querySelectorAll('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'));
                  last = last[(last.length - 2)];

              this.firstElement = first;
              this.lastElement = last;

              this.firstElement.addEventListener('keydown', this.firstElementFunc, false);
              this.lastElement.addEventListener('keydown', this.lastElementFunc, false);
            },
          });
        }
      }
    }
  }

  toggleCategoryFilter(): void {
    const categoryFilterBody = document.querySelector('.category__body');

    if (categoryFilterBody) {
      const isOpen = categoryFilterBody.clientHeight > 0;

      if (isOpen) {
        this.isCategoryFilterOpen = false;
        new TweenLite(categoryFilterBody, { height: 0, duration: 0.25 });
      } else {
        this.isCategoryFilterOpen = true;
        new TweenLite(categoryFilterBody, { height: 'auto', duration: 0.25 });
      }
    }
  }

  selectAll(): void {
    const categoryFilterBody = document.querySelector('.category__body');

    if (categoryFilterBody && window.innerWidth < 1240) {
      this.isCategoryFilterOpen = true;
      new TweenLite(categoryFilterBody, { height: 'auto', duration: 0.25 });
    }

    this.categoryOptions = this.categoryOptions.map((data) => ({
      ...data,
      isSelected: true,
    }));

    this.preSelectedCategories = this.categoryOptions.filter((option) => option.isSelected);
  }

  unSelectAll(): void {
    const categoryFilterBody = document.querySelector('.category__body');

    if (categoryFilterBody && window.innerWidth < 1240) {
      this.isCategoryFilterOpen = false;
      new TweenLite(categoryFilterBody, { height: 0, duration: 0.25 });
    }

    this.categoryOptions = this.categoryOptions.map((data) => ({
      ...data,
      isSelected: false,
    }));

    this.preSelectedCategories = [];
  }

  applyFilter(): void {
    this.selectedCategories = [];

    const filterBody = document.querySelector('.filter__body');
    const categoryFilterBody = document.querySelector('.category__body');

    if (window.innerWidth < 1240) {
      if (categoryFilterBody) {
        this.isCategoryFilterOpen = false;
        new TweenLite(categoryFilterBody, { height: 0, duration: 0.25 });
      }

      if (filterBody) {
        this.isFilterOpen = false;
        new TweenLite(filterBody, { height: 0, duration: 0.25 });
      }
    } else {
      this.isFilterOpen = false;
      new TweenLite(filterBody, {
        display: 'none',
        height: 0,
        paddingTop: 0,
        duration: 0.25,
        onComplete: () => {
          const first: any = document.querySelector('.filter__head--desktop .filter-toggle-btn');
          first.focus();

          const body = document.querySelector('.filter__body');
          Array.from(body.querySelectorAll('a, button, input, textarea, select, details'))
            .forEach((element) => {
              element.setAttribute('tabindex', '-1');
            });

          this.firstElement.removeEventListener('keydown', this.firstElementFunc, false);
          this.lastElement.removeEventListener('keydown', this.lastElementFunc, false);

          this.firstElement = null;
          this.lastElement = null;
        },
      });
    }
    this.categoryOptions.forEach(({ text, value, isSelected }) => {
      if (isSelected) {
        this.selectedCategories.push(value);

        this.dataLayerService.push({
          'event': 'filter',
          'filterType': 'category',
          'filterFacet': text,
        });
      }
    });
    const queryParams = {
      state: this.selectedState,
      categories: this.selectedCategories.length > 0 ? this.selectedCategories.join(',') : null,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });

    this.isLoaded = false;
    this.loadData().then(arr => this.processRawData(arr));
  }

  onStateChange(): void {
    const categoryFilterBody = document.querySelector('.category__body');
    const filterBody = document.querySelector('.filter__body');

    if (filterBody && categoryFilterBody) {
      if (window.innerWidth < 1240) {
        this.isCategoryFilterOpen = false;
        new TweenLite(categoryFilterBody, { height: 0, duration: 0.25 });

        this.isFilterOpen = false;
        new TweenLite(filterBody, { height: 0, duration: 0.25 });
      } else {
        this.isFilterOpen = false;
        new TweenLite(filterBody, { height: 0, paddingTop: 0, duration: 0.25 });
      }
    }

    const queryParams = {
      state: this.selectedState,
      categories: this.selectedCategories.length > 0 ? this.selectedCategories.join(',') : null,
    };

    this.dataLayerService.push({
      'event': 'filter',
      'filterType': 'state',
      'filterFacet': this.selectedState
    });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });

    this.isLoaded = false;
    this.loadData().then(arr => this.processRawData(arr));
  }

  onResize(): void {
    const filterBody: HTMLDivElement = document.querySelector('.filter__body');
    const categoryFilterBody: HTMLDivElement = document.querySelector('.category__body');

    if (filterBody && categoryFilterBody) {
      if (window.innerWidth >= 1240) {
        this.isFilterOpen = false;
        this.isCategoryFilterOpen = true;

        filterBody.style.height = '0';
        filterBody.style.paddingTop = '0';
        categoryFilterBody.style.height = 'auto';
      } else {
        this.isFilterOpen = false;
        this.isCategoryFilterOpen = false;

        filterBody.style.height = '0';
        categoryFilterBody.style.height = '0';
      }
    }
  }

  showModal(index): void {
    if (this.list[index]) {
      this.modalData = {
        title: this.list[index].title,
        eyebrow: this.list[index].eyebrow,
        desc: this.list[index].full_desc,
        cta: this.list[index].cta,
      };
    }
  }

  onModalClose(){
    this.modalData = {
      title: null,
      eyebrow: null,
      desc: null,
      cta: {
        url: null,
        text: null,
      },
    };
  }

  onCheckboxChange(e) {
    this.preSelectedCategories = this.categoryOptions.filter((option) => option.isSelected);
  }
  getCatIdFromCatCode(categoryCode: string) {
    const result = this.superCategories.find(cat => {
      return cat.code === categoryCode;
    })
    if (result) {
      return result.id;
    }
    return '';
  }
  getCatCodeFromId(categoryId:string){
    const result = this.superCategories.find(cat => {
      return cat.id === categoryId;
    })
    if (result) {
      return result.code;
    }
    return '';

  }
}
