import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ComponentService } from '@services/component.service';
import { GlobalService } from '@services/global.service';
@Component({
  selector: 'ncoa-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class Home implements OnInit {
  title: string;
  theme: string;
  // Components
  components: (any)[];
  componentTypes: any;

  @ViewChild('mainTitle') mainTitleRef;

  constructor(
    private activeRoute: ActivatedRoute,
    private componentService: ComponentService,
    private globalService: GlobalService,
  ) {
    this.componentTypes = this.componentService.componentTypes;
  }

  ngOnInit() {
    this.activeRoute.data.subscribe(({ homeData }: any) => {
      this.useHomepageData(homeData);
    });
  }

  getPreviewSlug() {
    return (/^preview/.test(this.activeRoute.routeConfig.path) && this.activeRoute.snapshot.params.slug) ?
      this.activeRoute.snapshot.params.slug :
      null;
  }

  useHomepageData({ theme, components }) {
    this.theme = theme;
    this.components = components;
    this.globalService.loadFooter( components.length > 0 ? true : false );
  }

}
