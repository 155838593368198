<div class="accordian-content">
    <section class="accordian-header" [ngClass]="{'expanded': expanded}">
        <ng-content select="[header]"></ng-content>

        <img (click)="expand()" *ngIf="!expanded" src="assets/icons/plus.svg">
        <img (click)="collapse()" *ngIf="expanded" src="assets/icons/minus.svg">
    </section>

    <section class="accordian-body" *ngIf="expanded">
        <ng-content select="[body]"></ng-content>
    </section>

</div>