import { Component, Inject, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import objectFitImages from 'object-fit-images';

import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'

import { QualtricsService } from '@services/qualtrics.service';
import { GlobalService } from '@services/global.service';

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ncoa-fe';
  private renderer: Renderer2;

  constructor(
    private qualtricsService: QualtricsService,
    private global: GlobalService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {

    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit(): void {
    if (typeof window === 'undefined') {
      return;
    }

    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      objectFitImages();
    }
 
    // this.qualtricsService.appendSnippet(); // NAM-655 // NAM-685 // NAM-738 - commented out for now 
    this.setFavicon(this.global.navData.favicon);
  }


  changeLinkUrl(isDarkMode: boolean): void {
    // const appleTouch = this.document.querySelector('link[rel="apple-touch-icon"]') as HTMLLinkElement;
    // const icon32 = this.document.querySelector('link[rel="icon"][sizes="32x32"]') as HTMLLinkElement;
    // const icon16 = this.document.querySelector('link[rel="icon"][sizes="16x16"]') as HTMLLinkElement;
    // const icon48 = this.document.querySelector('link[rel="icon"][sizes="48x48"]') as HTMLLinkElement;
    const maskIcon = this.document.querySelector('link[rel="mask-icon"]') as HTMLLinkElement;

    if (isDarkMode) {
      // appleTouch && appleTouch.setAttribute('href', '/apple-touch-icon.png');
      // icon32 && icon32.setAttribute('href', '/favicon-32x32.png');
      // icon16 && icon16.setAttribute('href', '/favicon-16x16.png');
      // icon48 && icon48.setAttribute('href', '/favicon.png');
      maskIcon && maskIcon.setAttribute('color', '#04a299');
    }
    else {
      // appleTouch && appleTouch.setAttribute('href', '/apple-touch-icon.png');
      // icon32 && icon32.setAttribute('href', '/favicon-32x32.png');
      // icon16 && icon16.setAttribute('href', '/favicon-16x16.png');
      // icon48 && icon48.setAttribute('href', '/favicon.png');
      maskIcon && maskIcon.setAttribute('color', '#0b4a5d');
    }
  }
  setFavicon(url: string) {
    if(!url) return;
    let favicon;
    try {
      favicon = this.renderer.selectRootElement('link[rel="icon"]');
    } catch (e) {
      favicon = this.renderer.createElement('link');
      this.renderer.setAttribute(favicon, 'rel', 'icon');
      const head = this.renderer.selectRootElement('head',true);
      this.renderer.appendChild(head, favicon);
    }
    this.renderer.setAttribute(favicon, 'href', url);
  }
}
