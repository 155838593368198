import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@services/planner/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-cm-banner',
  templateUrl: './cm-banner.component.html',
  styleUrls: ['./cm-banner.component.scss']
})
export class CmBannerComponent implements OnInit {
  isCaseMgmr = false;
  constructor(
    private user: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.isCaseMgmr = localStorage.getItem('isCaseManager') === 'true';

  }


  logOut() {

    this.user.logout();
    //nav to /tools/budget-checkup
    this.router.navigate(['/tools/budget-checkup']).then(() => {
      window.location.reload();
    });

  }
  navigateBackDirectory(params?: { [key: string]: string }) {
    const path = window.location.pathname;
    const segments = path.split('/');
    segments.pop();
    const newUrl = segments.join('/');

    const existingQueryParams = this.route.snapshot.queryParams;

    const queryParams = { ...existingQueryParams, ...params };

    this.router.navigate([newUrl], { queryParams: queryParams }).then(() => {
      window.location.reload();
    });
  }

}
