import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SavedContentsService } from '@services/planner/saved-contents.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';

@Component({
  selector: 'ncoa-saved-to-planner-listing',
  templateUrl: './saved-to-planner-listing.component.html',
  styleUrls: ['./saved-to-planner-listing.component.scss']
})
export class SavedToPlannerListingComponent implements OnInit, OnChanges {
  @Input() selectedType: string = 'all';
  @Input() selectedVertical: string = 'all';

  isLoading: boolean = true;
  cards: any[] = [];
  filteredCards: any[] = []
  displayedCards: any[] = [];
  isLoadingMore: boolean = false;

  constructor(
    private tools: ToolsPrimaryConfigService,
    private savedContentsService: SavedContentsService,
  ) { }

  ngOnInit(): void {
    const savedContent = (this.tools.customerActivity?.basic?.SavedContent || []);

    if (savedContent.length > 0) {
      const ids = [];

      savedContent.forEach((content) => {
        ids.push(content.ContentId);
      });

      this.savedContentsService.getCards(ids).then((cards) => {
        this.cards = cards;
        this.filteredCards = this.filterCards(cards);
        this.displayedCards = this.filteredCards.slice(0, 9);
        this.isLoading = false;
      });
    } else {
      this.displayedCards = this.cards;
      this.isLoading = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedType || changes.selectedVertical) {
      this.isLoading = true;

      setTimeout(() => {
        this.filteredCards = this.filterCards(this.cards);
        this.displayedCards = this.filteredCards.slice(0, 9);
        this.isLoading = false;
      }, 1500);
    }
  }

  loadMore(): void {
    this.isLoadingMore = true;

    setTimeout(() => {
      let display = this.displayedCards.length + 6;
          display = display <= this.filteredCards.length ? display : this.filteredCards.length;

      let targetIndex = this.displayedCards.length;
          targetIndex = targetIndex <= (this.filteredCards.length - 1) ? targetIndex : (this.filteredCards.length - 1);

      this.displayedCards = this.filteredCards.slice(0, display);
      this.isLoadingMore = false;

      setTimeout(() => {
        const cards = Array.from(document.querySelectorAll('.card--wrapper'));

        if (cards[targetIndex]) {
          const interactive: any = cards[targetIndex].querySelector('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');

          if (interactive) {
            interactive.focus();
          }
        }
      }, 1500);
    }, 1500);
  }

  filterCards(cards) {
    let filteredCards = [];

    if (this.selectedType === 'all' && this.selectedVertical === 'all') {
      filteredCards = cards;
    } else {
      filteredCards = cards;

      switch (this.selectedType) {
        case 'resources': {
          filteredCards = filteredCards.filter((card) => card.type === 'article');
          break;
        }
        case 'tools': {
          filteredCards = filteredCards.filter((card) => card.type === 'tool');
          break;
        }
        case 'experts': {
          filteredCards = filteredCards.filter((card) => card.type === 'referral');
          break;
        }
        case 'subtopics': {
          filteredCards = filteredCards.filter((card) => card.type === 'subtopic');
          break;
        }
      }

      if (this.selectedVertical !== 'all') {
        filteredCards = filteredCards.filter((card) => (
          card.verticals.find((vertical) => vertical === this.selectedVertical)
        ));
      }
    }

    return filteredCards;
  }
}
