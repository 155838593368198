import { Injectable } from '@angular/core';
import { KontentDeliveryService } from './kontent-delivery.service';


@Injectable({
  providedIn: 'root'
})
/**
 * Service for rendering rich text elements
 * encapsulation for page needs to be set to none in order to render links
 * 
 */
export class RichTextService {

  constructor(
  ) { }
    /**
     * 
     * @param richText element from kontent delivery api
     * @returns html string
      */
  resolveRichText = (richText: any) => {
    const linkedItems = richText.links as LinkedItem;
    const linkIds = Object.keys(linkedItems)
    let html = new Document();
    try{
      html = new DOMParser().parseFromString(richText.value, "text/html");
    } catch(e){
      return richText.value;
    }

    if(!html) return "";
    linkIds.forEach((id: any) => {
      const item = linkedItems[id];

      if(!item.url_slug) return;

      const elements = html.querySelectorAll(`[data-item-id="${id}"]`)
      elements.forEach(ele=> ele.setAttribute('href', `${this.getRouteFromItemType(item.type)}${item.url_slug}`));

    })
    return html.body.innerHTML;
  }

  getRouteFromItemType(type) {
    switch (type) {

      case 'ncoa_article_content':
        return '/article/';
      case 'author':
        return '/author/';
      case 'standard_page':
        return '/page/';
      default:
        return '/';
    }
  }
}



// can't use type from sdk because it doesn't match api response
interface LinkedItem {
  [key: string]: {
    codename: string;
    type: string;
    url_slug: string;
  }
}
