import { Component, Input, OnInit } from '@angular/core';
import { SubTopicService } from '@services/planner/subtopic.service';

@Component({
  selector: 'ncoa-subtopic-tools',
  templateUrl: './subtopic-tools.component.html',
  styleUrls: ['./subtopic-tools.component.scss']
})
export class SubtopicToolsComponent implements OnInit {

  pageID: string = null;
  data: {
    subtopicHeader: any;
    trustedExperts: any;
    trustedExpertPromo: any;
    resource3Up: any;
    toolCards: any;
    singleColCardList: any;
  } = null;

  constructor(
    private subTopicService: SubTopicService,
  ) {
    this.subTopicService.getData().then((data: any) => {
      this.pageID = data.id;

      this.data = {
        subtopicHeader: data?.subtopicHeader,
        trustedExperts: data?.trustedExperts,
        trustedExpertPromo: data?.trustedExpertPromo,
        resource3Up: data?.ncoaThreeUp,
        toolCards: data?.toolCards,
        singleColCardList: data?.singleColCardList,
      }
    });
  }

  ngOnInit(): void { }

}
