<age-well-planner>
    <div class='case-manager'>
        <app-cm-banner> </app-cm-banner> 
        <ng-container *ngIf="templateView === 'home'; then home"></ng-container>
        <ng-container *ngIf="templateView === 'budgetCheckUp'; then budgetCheckUp"></ng-container>
        <ng-container *ngIf="templateView === 'recentActivity'; then recentActivity"></ng-container>

    </div>
</age-well-planner>

<ng-template #home>
    <div *ngIf="templateView ==='home'" class="active-fade">

        <div class="case-manager-header blue-bg">
            <div class="case-manager-header__title">
                <span>My Clients</span>
            </div>
        </div>

        <div class="case-manager-body">
            <div class="case-manager__divider">
                Below is a list of your clients. You can view their plans or their recent activity, by clicking the
                links
                below.
            </div>
            <div>
                <table>
                    <thead class="green-bg">
                        <tr class="green-bg">
                            <th>Name</th>
                            <th>Email</th>
                            <th>Budget Checkup Plan</th>
                            <th>Recent Activity</th>
                        </tr>
                    </thead>
                    <tr *ngFor="let client of clientList; let i = index" [class.alternate]="i % 2 === 1">
                        <td>{{getDisplayName(client)}}</td>
                        <td> {{client.userAccountEmail}}</td>
                        <td> <a (click)="viewBudgetCheckUp(client)">View Budget CheckUp Plan</a> </td>
                        <td> <a (click)="viewRecentActivity(client)">View Recent Activity </a> </td>
                    </tr>

                </table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #budgetCheckUp>
    <div *ngIf="templateView ==='budgetCheckUp'" class="active-fade">

        <ng-container *ngTemplateOutlet="backButton"></ng-container>

        <div class="case-manager-header green-bg">
            <div class="case-manager-header__title">
                <span>Budget CheckUp Plan for {{getTitleString(selectedClient)}}</span>
            </div>
        </div>

        <div class="case-manager__message">
            <p>You are viewing the Budget CheckUp plan for the client named above. </p>
            <p>Here, you can freely read everything in their Budget CheckUp plan. </p>
            <p>You can click their to-dos and navigate through the tabs called Your To-Do's, Completed and Archived.</p>
            <p>You are not able to change the client's plan -- only view it.</p>
        </div>

        <div class="case-manager-body">
            <app-client-plan [client]="selectedClient"></app-client-plan>
        </div>
    </div>
</ng-template>

<ng-template #recentActivity>
    <div *ngIf="templateView ==='recentActivity'" class="active-fade">

        <ng-container *ngTemplateOutlet="backButton"></ng-container>
        <div class="case-manager-wrapper">
            <div class="case-manager-header green-bg">
                <div class="case-manager-header__title">
                    <span>Recent Activity for {{getTitleString(selectedClient)}}</span>
                </div>
            </div>

            <div class="case-manager-body card-container">
                <div class="list-card">
                    <span class="list-card_total-count"> {{loginCount}}</span>
                    <span class="list-card_title"> Logins</span>
                    <ul>
                        <li *ngFor="let login of logins | slice:0:10">
                            {{formatTimestamp(login.timestamp)}}
                        </li>
                    </ul>
                </div>
                <div class="list-card">
                    <span class="list-card_total-count"> {{budgetViewedCount}}</span>
                    <span class="list-card_title"> Viewed Analyze Your Budget To-Do </span>
                    <ul>
                        <li *ngFor="let activity of budgetViewed| slice:0:10">
                            {{formatTimestamp(activity.timestamp)}}
                        </li>
                    </ul>
                </div>
                <div class="list-card">
                    <span class="list-card_total-count"> {{budgetEditedCount}}</span>
                    <span class="list-card_title"> Edited Analyze Your Budget To-Do</span>
                    <ul>
                        <li *ngFor="let activity of budgetEdited| slice:0:10">
                            {{formatTimestamp(activity.timestamp)}}
                        </li>
                    </ul>
                </div>
                <div class="list-card">
                    <span class="list-card_total-count"> {{planResetCount}}</span>
                    <span class="list-card_title"> Reset Plan </span>
                    <ul>
                        <li *ngFor="let activity of planReset| slice:0:10">
                            {{formatTimestamp(activity.timestamp)}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #backButton>
    <div class="back-btn-wrapper">
        <a (click)="viewHome()" class="back-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                    d="M7.26498 3.235C7.3352 3.30531 7.37465 3.40062 7.37465 3.5C7.37465 3.59938 7.3352 3.69469 7.26498 3.765L3.4056 7.625L13.5 7.625C13.5994 7.625 13.6948 7.66451 13.7651 7.73484C13.8355 7.80516 13.875 7.90054 13.875 8C13.875 8.09946 13.8355 8.19484 13.7651 8.26517C13.6948 8.33549 13.5994 8.375 13.5 8.375H3.4056L7.26498 12.235C7.33121 12.3061 7.36728 12.4001 7.36556 12.4973C7.36385 12.5944 7.32449 12.6871 7.25579 12.7558C7.18708 12.8245 7.09439 12.8639 6.99724 12.8656C6.90009 12.8673 6.80606 12.8312 6.73498 12.765L2.23498 8.265C2.16475 8.19469 2.12531 8.09938 2.12531 8C2.12531 7.90063 2.16475 7.80531 2.23498 7.735L6.73498 3.235C6.80529 3.16477 6.9006 3.12533 6.99998 3.12533C7.09935 3.12533 7.19466 3.16477 7.26498 3.235Z"
                    fill="#0B4A5D" />
            </svg>
            <span> Back to My Clients</span>
        </a>
    </div>
</ng-template>