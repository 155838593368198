import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '@services/global.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserService } from '@services/planner/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-account-verified',
  templateUrl: './account-verified.component.html',
  styleUrls: ['./account-verified.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountVerified implements OnInit {
  pageData: {
    navCTA: {
      cta: {
        url: string;
        text: string;
      }
      src: string;
      alt: string;
    };
    backCTA: {
      url: string;
      text: string;
    };
    mainCTA: {
      url: string;
      text: string;
    };
    eyebrow: string;
    desc: string;
  } = null;

  accountVerified: boolean = false; // set to default false

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private toolsConfig: ToolsPrimaryConfigService,
    private globalService: GlobalService,
  ) {
    this.pageData = {
      navCTA: this.toolsConfig.currentConfig.navData.mainLogo,
      backCTA: {
        url: '/',
        text: 'Exit',
      },
      mainCTA: {
        url: this.toolsConfig.routes.find((route) => route.type === 'sign-in').route,
        text:  this.toolsConfig.rawConfig.elements?.account_verified_cta?.value,
      },
      eyebrow: this.toolsConfig.rawConfig.elements.av_eyebrow.value,
      desc: this.toolsConfig.rawConfig.elements.body.value,
    };
  }

  ngOnInit(): void {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        window.location.href = this.toolsConfig.rootURL;
      }
    });

    const userID = this.route.snapshot.queryParamMap.get('user_id');
    const confirmationCode = this.route.snapshot.queryParamMap.get('confirmation_code');

    if (!userID || !confirmationCode) {
      window.location.href = '/404';
    } else {
      this.userService.verifyUser(userID, confirmationCode)
        .subscribe(() => {
          this.accountVerified = true;
        }, (error) => {
          if (error.status === 404) {
            window.location.href = this.toolsConfig.routes.find(({ type }) => type === 'sign-in').route;
          }
        });
    }
    this.globalService.loadFooter(true);
  }

  goBack(e){
    if(!document.referrer){
      const lastUrl = window.sessionStorage.getItem("last_url");
      const lastUrlIndex = window.sessionStorage.getItem("last_url_index");
      if((history.length - 1) === parseInt(lastUrlIndex)){
        window.location.href = lastUrl;
      }else{
        window.location.href = this.pageData.backCTA.url;
      }
    }else{
      if (document.referrer.indexOf(window.location.host) !== -1){
        e.preventDefault();
        history.back();
      }else{
        window.location.href = this.pageData.backCTA.url;
      }
    }
  }

}
