import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { BenefitsService } from '@services/planner/benefits.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';

@Component({
  selector: 'explore-all-benefits',
  templateUrl: './explore-all-benefits.component.html',
  styleUrls: ['./explore-all-benefits.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExploreAllBenefits implements OnInit {

  benefitsListing: any = null;

  constructor(
    private toolsConfig: ToolsPrimaryConfigService,
    private benefitsService: BenefitsService,
    private dataLayerService: DataLayerService,
  ) { }

  ngOnInit(): void {
    this.benefitsService.getCategories().then((categories) => {
      const categoryOptions = categories.map((option) => ({
        text: option.name,
        value: option.codename,
        isSelected: false,
      }));

      this.benefitsListing = {
        type: 'decorative',
        backCTA: {
          url: this.toolsConfig.routes.find(({ type }) => type === 'home').route,
          text: this.toolsConfig.rawConfig.elements.home_navigation_name.value || 'My Planner',
        },
        header: this.toolsConfig.currentConfig.eabHeroData,
        categoryOptions,
        verticalPathing: this.getVerticalPathing(),
      };
    });

    this.dataLayerService.push({
      clickUrl: location.href,
      pageTitle: document.title || '',
      contentType: 'explore-benefits',
      pageType: 'explore-benefits-page',
    },false);
  }

  getVerticalPathing() {
    const verticals = this.toolsConfig.routes.filter((route) => route.type === 'vertical');
    const verticalPaths = new Array(verticals.length);

    verticals.forEach((vertical) => {
      const index = this.toolsConfig.awpTaxonomyCatalog.findIndex((taxonomy) => (taxonomy.codename === vertical.taxonomy.codename));
      verticalPaths[index] = vertical;
    });

    const paths = [];

    verticalPaths.forEach((route) => {
      const links = [{ text: 'Explore More', url: route.route }];
      const benefitRoute = this.toolsConfig.routes.find((bRoute) => (
        bRoute.type === 'vertical-benefits' &&
        bRoute.codename === route.codename
      ));

      if (benefitRoute) {
        links.push({
          text: 'Find Benefits',
          url: benefitRoute.route,
        });
      }

      paths.push({
        title: route.text,
        links,
      });
    });

    paths.push({
      title: 'My Planner',
      links: [{
        text: 'Go to My Planner',
        url: this.toolsConfig.routes.find((route) => route.type === 'home').route,
      }, {
        text: 'My Saved Items',
        url: this.toolsConfig.routes.find((route) => route.type === 'saved-contents').route,
      }],
    });

    return {
      heading: `Explore the ${this.toolsConfig.rawConfig.elements.tool_title.value}`,
      items: paths,
    };
  }
}
