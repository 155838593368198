import { Component, OnInit } from '@angular/core';
import { GlobalService } from '@services/global.service';

@Component({
  selector: 'ncoa-contact-page',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactPage implements OnInit {

  constructor(private globalService: GlobalService) { }

  ngOnInit(): void {
    this.globalService.loadFooter( true  );
  }

}
