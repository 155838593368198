import { Injectable } from '@angular/core';
import fetch from 'node-fetch';
import { KontentDeliveryService } from './kontent-delivery.service';


@Injectable({
  providedIn: 'root'
})
export class ProgramsService {
  originalItems: any[] = [];
  totalItems: number = 0;
  continuationToken: string;

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
  ) {}

  getData() {
    return this.kontentDeliveryService.getItem(null, {
      'system.type': 'resource___partners_and_programs',
      'order': 'system.last_modified[desc]',
      'limit': 1,
    }).then(async ({ items }) => {
      if (items.length > 0) {
        const { value } = items[0].elements.partners_and_programs_reference;
        const { url } = value[0];
        const response = await this.getJSONData(url);


        if (response.items) {
          this.originalItems = response.items;
          this.totalItems = response.items.length;
          return response.items;
        }

        return [];
      }

      return [];
    });
  }

  async getJSONData(url) {
    return await fetch(url).then(res => res.json()).then(data => data);
  }

  getFilterOptions() {
    return this.kontentDeliveryService.getTaxonomies('activities')
      .then(({ terms }) => {
        const filterOptions = terms.map(term => {
          const options = term.terms.map(option => {
            return {
              name: option.name,
              value: option.codename,
              isChecked: false,
            };
          });

          return {
            name: term.name,
            value: term.codename,
            isChecked: false,
            options,
          };
        });


        return filterOptions;
      });
  }
}
