import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class DataService {
  private headerMenusSource = new BehaviorSubject<any[]>([]);
  currentHeaderMenus = this.headerMenusSource.asObservable();

  constructor() {}

  changeHeaderMenus(headerMenus) {
    this.headerMenusSource.next(headerMenus);
  }

  remodelImageSrc(url, component = '') {

    //set default breakpoint props and object
    const dpr = 2;
    const fm = 'pjpg';
    const q = 60;
    const dimensions = {
      mobile: 486,
      tablet: 1024,
      desktop: 1440
    }

    const mediaQueries = {
      mobile: 'all and (max-width: 767px)',
      tablet: '(min-width: 768px) and (max-width: 1239px)',
      desktop: 'all and (min-width: 1240px)',
    }

    let breakpointProps = {
      mobile: {
        dpr: dpr,
        width: dimensions.mobile,
        media: mediaQueries.mobile,
        fm: fm,
        q: q
      },
      tablet: {
        dpr: dpr,
        width: dimensions.tablet,
        media: mediaQueries.tablet,
        fm: fm,
        q: q
      },
      desktop: {
        dpr: dpr,
        width: dimensions.desktop,
        media: mediaQueries.desktop,
        fm: fm,
        q: q
      },
    }

    switch(component) {
      case 'callToDonate':
      case 'featuredPageBlock':
      case 'featuredToolBreaker':
      case 'moreStories':
      case 'benefits':
      case 'promoBackground':
        breakpointProps.tablet.width = 800;
        breakpointProps.desktop.width = 800;
        break;

      case 'homeHero':
      case 'mission':
      case 'categoryHero':
        breakpointProps.tablet.width = 700;
        breakpointProps.desktop.width = 700;
        break;

      case 'relatedArticles':
        breakpointProps.tablet.width = 460;
        breakpointProps.desktop.width = 460;
        break;
    }

    return [
      {
        url: `${url}?w=${breakpointProps.mobile.width}&dpr=${breakpointProps.mobile.dpr}&fm=${breakpointProps.mobile.fm}&q=${breakpointProps.mobile.q}`,
        media: breakpointProps.mobile.media,
      },
      {
        url: `${url}?w=${breakpointProps.tablet.width}&dpr=${breakpointProps.tablet.dpr}&fm=${breakpointProps.tablet.fm}&q=${breakpointProps.tablet.q}`,
        media: breakpointProps.tablet.media,
      },
      {
        url: `${url}?w=${breakpointProps.desktop.width}&dpr=${breakpointProps.desktop.dpr}&fm=${breakpointProps.desktop.fm}&q=${breakpointProps.desktop.q}`,
        media: breakpointProps.desktop.media,
      },
    ]
  }
}
