import * as dayjs from 'dayjs';
import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';
import { EventCustomSearchService } from '@services/event-custom-search.service';
import { Location } from '@angular/common';
import { image } from '../../components/author-header/author-header.types';
import { ActivatedRoute } from '@angular/router';
import gsap from 'gsap';
import { GlobalService } from '@services/global.service';

@Component({
  selector: 'ncoa-event-custom-search',
  templateUrl: './event-custom-search.component.html',
  styleUrls: ['./event-custom-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EventCustomSearchComponent implements OnInit {
  // identifier
  items: any[] = [];
  searchKey: string = '';
  resultTitle: string = '';
  totalItems: number = 0;

  // header props
  image: image;
  title: string = '';
  description: string = '';
  placeholder : string = '';
  eyebrow: string = '';

  @ViewChild('eventCustomSearchRoot') rootElement: any;
  @ViewChild('noResult') noResult: any;

  filters: any[];
  selectedFilters: any[];

  limit: number = 6;
  dateFilterValue: string = 'forwards';
  dateToday: string = dayjs().format('YYYY-MM-DD');
  loading: boolean = true;

  constructor(
    private activeRoute: ActivatedRoute,
    private eventCustomSearchService: EventCustomSearchService,
    private location: Location,
    private globalService: GlobalService

  ) {

  }

  ngOnInit(): void {
    this.searchKey = this.activeRoute.snapshot.queryParams.q || '';

    this.eventCustomSearchService.limit = this.limit;

    Promise.all([
      this.eventCustomSearchService.getPageData(),
      this.eventCustomSearchService.storeAllEvents(),
    ])
    .then(([ pageData ]) => {
      this.usePageDAta(pageData);
      this.globalService.loadFooter(true);
      return this.eventCustomSearchService.searchEventsStore(this.searchKey, this.dateFilterValue);
    })
    .then(result => this.onSearchDataReceived(result));
  }

  usePageDAta(pageData) {
    this.image = pageData.image;
    this.title = pageData.title;
    this.description = pageData.description;
    this.placeholder = pageData.placeholder;
    this.eyebrow = pageData.eyebrow;
  }

  onSearchDataReceived(data, callback = null) {
    const { items, totalCount } = data;

    this.loading = false;
    this.totalItems = totalCount;

    if (!items.length) {
      this.resultTitle = '';
      return;
    }

    this.resultTitle = 'Most Relevant';

    // A simple .push() doesn't change the array, so overriding existing array is necessary
    this.items = [ ...this.items, ...items ];

    gsap.to(this.rootElement, { opacity: 1, y: 0, duration: 0.4 })

    if (callback) {
      callback();
    }
  }

  getMoreContents() {
    const items = this.eventCustomSearchService.filteredEvents.slice(this.items.length, this.items.length + this.limit);

    this.onSearchDataReceived({
      items,
      totalCount: this.totalItems
    });
  }

  async searchContent(keyword) {
    const path = keyword && keyword.length > 0 ? `/${this.activeRoute.snapshot.url[0].path}?q=${keyword}` : `/${this.activeRoute.snapshot.url[0].path}`;

    // Show animation
    this.showLoading()
    .then(() => {
      this.location.replaceState(path);
      this.searchKey = keyword || '';

      const data = this.eventCustomSearchService.searchEventsStore(keyword, this.dateFilterValue);

      // Reset items
      this.items = [];

      // Process data
      this.onSearchDataReceived(data);
    });
  }

  onDateChange(dateFilter) {
    if ( dateFilter === this.dateFilterValue ) {
      return;
    }

    // Save updated date
    this.dateFilterValue = dateFilter;

    // Search using filter date
    const data = this.eventCustomSearchService.searchEventsStore(this.searchKey, this.dateFilterValue);

    // Reset items
    this.items = [];

    // Process filtered items
    this.onSearchDataReceived(data);
  }

  onClearQuery() {
    this.dateFilterValue = 'forwards';
    this.searchKey = '';

    this.searchContent('');
  }

  async showLoading() {
    return new Promise(resolve => {
      gsap.to('.results-list', { y: 200, opacity: 0, duration: 0.4 });
      gsap.to('.no-result', { opacity: 0, duration: 0.4, onComplete: () => {
        this.loading = true;
      }});
    });
  }
}
