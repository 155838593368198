import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, ComponentFactoryResolver, Injectable, Renderer2, ViewContainerRef,  } from '@angular/core';

import { ScriptService } from './script.service';
import { DynamicFormsComponent } from '@components/dynamic-forms/dynamic-forms.component';

@Injectable({ providedIn: 'root' })

export class FormsService {

  everyActionFormUrls = [];

  constructor(
    private ScriptService: ScriptService,
    private http: HttpClient
  ) { }

  processForms() {
    const forms = document.querySelectorAll('.ncoa-form');

    forms.forEach(form => {
      const span = form.querySelector('span[style="font-size: 0px"]');
      if (span) {
        span.remove();
      }

      this.renderForm(form);
    })
    return this.everyActionFormUrls;
  }

  renderForm(form) {
    const formId = form.getAttribute('data-form-id');
    const formType = form.getAttribute('data-form-vendor');

    switch(formType) {
      case 'formassembly':
        this.getFormAssemblyForm(form, formId);
        break;
      case 'pardot':
        this.getPardotForm(form, formId);
        break;
      case 'everyaction':
        this.getEveryActionForm(form, formId);
        break;
      default:
        this.getRallyBoundForm(form, formId);
    }
  }
  
  getEveryActionForm(el, id: string) {
    this.everyActionFormUrls.push(id);

  }

  getEveryActionFormData(url): Promise<any> {
    return this.http.get(url).toPromise();
  }
  getFormAssemblyForm(el, id: string) {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('height', '1745');
    iframe.src = `//ncoa.tfaforms.net/${id}`;
    iframe.setAttribute('title', 'Contact Us Form');
    el.appendChild(iframe);
 
    this.ScriptService.load(el, 'formassembly-iframe-resizer').then(data => { }).catch(error => console.log(error));
  }


  getRallyBoundForm(el, id: string) {
    const div = document.createElement('div');
    div.setAttribute('id', 'rbEmbedded');
    div.dataset.account = `${id}`;
    el.appendChild(div);

    this.ScriptService.load(el, 'rallybound-form').then(data => {}).catch(error => console.log(error));
  }

  getPardotForm(el, id: string) {
    const iframe = document.createElement('iframe');
    iframe.src = `//go.ncoa.org/l/48252/${id}`;
    iframe.setAttribute('title', 'Newsletter Sign Up Form');
    iframe.setAttribute('class', 'pardot-iframe');
    el.appendChild(iframe);

    this.ScriptService.load(el, 'pardot-iframe-resizer')
      .then(data => addiFrameResizeScript())
      .catch(error => console.log(error));

    function addiFrameResizeScript() {
      const script = document.createElement('script');
      script.text = `iFrameResize({}, '.pardot-iframe')`;
      el.appendChild(script);
    }
  }

  addScript(src: any) {
    const s = document.createElement( 'script' );
    s.setAttribute( 'src', src );

    return s;
  }


}
