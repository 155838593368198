import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
  }]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {

  @Input() name: string;
  @Input() id: string;

  checked: boolean;
  disabled: boolean;

  onChange = (_: any) => { }; // No-op

  constructor() { }

  ngOnInit(): void { }

  changeValue($event) {
    this.checked = $event && $event.target && $event.target.checked;
    // $event.stopPropagation();
    this.onChange(this.checked); // call parent OnChange fn if internal component value has changed
  }


  // ControlValueAccessor overrides

  writeValue(value: boolean): void {
    this.checked = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn; // Replace internal callback fn
  }

  registerOnTouched(fn: any): void { } // not used by checkbox

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
