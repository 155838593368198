import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { AssessmentService } from '@services/planner/assessment.service';
import { DataService } from '@services/planner/data.service';
import { ProfileService } from '@services/planner/profile.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ncoa-account-profile-header',
  templateUrl: './account-profile-header.component.html',
  styleUrls: ['./account-profile-header.component.scss']
})
export class AccountProfileHeaderComponent implements OnInit {
  @Input() backCTA: {
    url: string;
    text: string;
  };

  @Input() image: {
    src: string;
    alt: string;
  };

  @Input() headline: string;
  @Input() subhead: string;
  @Input() accountType: string;
  @Input() accountTypeUpdateCopy: string;
  @Input() menu: {
    id: string;
    text: string;
    isActive: boolean;
  }[];

  profileCTALink: string = '';

  @Input() assessmentData: any;
  @Input() headerQuestion: any;
  displayText: string;
  showLoadingOverlay: boolean = false;

  @Output() onNavigationClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private assessment: AssessmentService,
    private dataLayerService: DataLayerService,
    private profileService: ProfileService,
    private toolsConfig: ToolsPrimaryConfigService,
    private dataService: DataService,
  ) { }

  navigationOnClick(index,text){

    this.onNavigationClick.emit(index);
    const _clickUrl = this.profileCTALink + text;

    this.dataLayerService.push({
      'event': 'recirc',
      'url': this.updateUrlWithDomain(_clickUrl),
      'componentType': 'header'},false);
  }

  updateUrlWithDomain(urlString: string){
    return urlString.includes('http')? urlString : location.origin + urlString;
  }

  ngOnInit(): void {
    if (this.headerQuestion) {
      const selectedAnswer = this.headerQuestion.answerGroup.answers.find((answer) => answer.value === this.headerQuestion.userAnswer);
      if (selectedAnswer) {
        this.displayText = selectedAnswer.displayText;
      }
    }

    if (this.toolsConfig.currentConfig) {
      this.dataService.currentHeaderMenus.subscribe((headerMenus) => {
        let navData = {
          ...this.toolsConfig.currentConfig.navData,
          headerMenus,
        };
        this.profileCTALink =  navData.profileCTA.url;
      });
    } else {

      this.profileCTALink =  '/profile';
    }
  }

  goBack(e){
    if(!document.referrer){
      const lastUrl = window.sessionStorage.getItem("last_url");
      const lastUrlIndex = window.sessionStorage.getItem("last_url_index");
      if((history.length - 1) === parseInt(lastUrlIndex)){
        window.location.href = lastUrl;
      }else{
        window.location.href = this.backCTA.url;
      }
    }else{
      if (document.referrer.indexOf(window.location.host) !== -1){
        e.preventDefault();
        history.back();
      }else{
        window.location.href = this.backCTA.url;
      }
    }
  }

  onChange(): void {
    this.showLoadingOverlay = true;

    const a = this.headerQuestion.answerGroup.answers.find(({ value }) => value === this.headerQuestion.userAnswer);

    this.displayText = a.displayText;

    const response = {
      answer: {
        text: a.text,
        value: a.value,
      },
      questionId: this.headerQuestion.id,
      questionVersion: this.headerQuestion.version,
    };

    this.assessment.respondToQuestions(this.assessmentData.id, this.assessmentData.version, [response])
      .pipe(take(1))
      .subscribe(() => {
          this.profileService.hasNewData([response]).then(() => {
            this.showLoadingOverlay = false;
          });
      });
  }
}
