import { Component, OnInit, Input } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { UserService } from '@services/planner/user.service';
import { UtilityService } from '@services/planner/utility.service';
import { UserPromptService } from '@services/planner/user-prompt.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ncoa-my-planner-header',
  templateUrl: './my-planner-header.component.html',
  styleUrls: ['./my-planner-header.component.scss']
})
export class MyPlannerHeader implements OnInit {

  @Input() eyebrow: string;
  @Input() title: string;
  @Input() description: string;
  @Input() savedContentCount: number;
  @Input() feature: {
    image: {
      sizes: {
        media: string;
        url: string;
      }[];
      src: string;
      alt: string;
    };
    eyebrow: string;
    title: string;
    description: string;
    cta: {
      url: string;
      text: string;
    };
  };
  @Input() savedContentUrl: string;
  
  isLogged: boolean = false;

  constructor(
    private dataLayerService: DataLayerService,
    private utility: UtilityService,
    private userService: UserService,
    private modalService: UserPromptService,
  ) { }


  trackAnalytics(ev){
    const _element = ev.currentTarget as HTMLAnchorElement;
    const _clickUrl = _element.href || _element.getAttribute('href');

    this.dataLayerService.push({
      'event': 'recirc',
      'url': _clickUrl,
      'componentType': 'header' },false);
  }

  ngOnInit(): void {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.isLogged = true;
      } else {
        this.isLogged = false;
      }
    });
  }

  clickSave( $event ): void {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {

      if (!isLoggedIn) {
        $event.preventDefault();
        this.modalService.isModalOpen(true);
      }

    });
  }
}
