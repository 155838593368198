import { Component, OnInit, Input, ElementRef, OnChanges, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import Litepicker from 'litepicker';
import dayjs from 'dayjs';

@Component({
  selector: 'ncoa-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatePickerComponent implements OnInit {
  @Input() dateChangeHandler?: any;
  @Input() visible?: boolean = false;

  @ViewChild('datePickerContainer') datePickerContainer:any;

  litePicker?: Litepicker;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.litePicker = new Litepicker({
      element: this.datePickerContainer.nativeElement,
      parentEl: this.datePickerContainer.nativeElement,
      format: 'YYYY-MM-DD',
      dropdowns: {
        months: true,
        years: true,
        minYear: null,
        maxYear: null,
      },
      inlineMode: true,
      firstDay: 0,
    });

    this.litePicker.on('selected', (date) => this.onLitePickerSelect(date));
  }

  onLitePickerSelect(date) {
    if ( typeof this.dateChangeHandler === 'function' ) {
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const dateSegments = [
        date.getFullYear(),
        month > 9 ? month : `0${month}`,
        day > 9 ? day : `0${day}`
      ];
      this.dateChangeHandler(dateSegments.join('-'));
    }
  }

  onTodayClick(event) {
    event.preventDefault();

    if ( this.litePicker ) {
      const dateToday = dayjs().format('YYYY-MM-DD')
      this.litePicker.setDate(dateToday);
      this.litePicker.gotoDate(dateToday);
    }
  }
}
