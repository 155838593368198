import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ncoa-author-byline-planner',
  templateUrl: './author-byline-planner.component.html',
  styleUrls: ['./author-byline-planner.component.scss']
})
export class AuthorBylinePlannerComponent implements OnInit {
  @Input() authors?: any;

  constructor() { }

  ngOnInit(): void { }
}
