import { Injectable } from '@angular/core';
import { KontentDeliveryService } from './kontent-delivery.service';
import { ComponentService } from './component.service';
import { SeoService } from '@services/seo.service';
import { UrlService } from './url.service';
import { DataLayerService } from './data-layer.service';
import { ModalService } from './modal.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {
  codeName: string = 'homepage_template';
  audiencePathingCodename: string = 'homepage_audience_pathing';
  usedArticles = [];

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private componentService: ComponentService,
    private seoService: SeoService,
    private urlService: UrlService,
    private dataLayerService: DataLayerService,
    private modalService: ModalService,
    private globalService: GlobalService,
  ) {}

  getData(previewSlug: any = null) {
    this.modalService.showAvailableModal(null, [], this.codeName);

    const homepageParams = {
      'system.type': 'homepage_layout',
      'order': 'system.last_modified[desc]',
      'limit': 1,
      'depth': 3
    };

    if ( previewSlug ) {
      homepageParams['elements.url_slug'] = previewSlug;
    }

    return Promise.all([
      this.kontentDeliveryService.getItemAndCache(null, homepageParams),
      this.kontentDeliveryService.getItemAndCache(null, {
        'system.type': 'audience_pathing__ct_',
        'order': 'system.last_modified[desc]',
        'depth': 2,
        'limit': 1,
      }),
    ])
    .then(([rawHomePages, audiencePath]) => {
      const [ rawHomepageItem ] = rawHomePages.items;

      // Restructure to match raw API format
      const homepageData = {
        item: rawHomepageItem,
        modular_content: rawHomePages.modular_content
      }

      return this.processHomepageData(homepageData, audiencePath);
    });
  }

  async processHomepageData(rawHomepageData, audiencePath) {
    const components = [];

    // Process theme
    const theme = this.extractTheme(rawHomepageData);

    // Process Home Hero
    const homeHero = this.extractHomeHero(rawHomepageData);
    components.push(homeHero);

    // Process Audience Pathing
    components.push(this.extractAudiencePathing(audiencePath));

    // Process Mission Statement Block
    components.push(await this.extractMissionStatement(rawHomepageData));

    const additionalComponents = await this.extractAdditionalComponents(rawHomepageData);

    // Process additional components
    components.push(...additionalComponents);

    // Process title
    const title = homeHero?.title || '';

    this.seoService.extractAndRender(rawHomepageData.item, {
      title,
      image: homeHero?.image.url || '',
      description: homeHero?.description || ''
    });

    // Push analytics data
    this.dataLayerService.push({
      url: location.href,
      pageTitle: title,
      contentType: 'homepage_layout',
      category: '',
      audience: ''
    });

    return {
      theme,
      title,
      components
    };
  }

  extractHomeHero(rawAPIData) {
    if ( !rawAPIData || !rawAPIData.item ) {
      return {}
    }

    const featuredArticleCodename = rawAPIData.item.elements.featured_article.value[0];

    this.usedArticles.push(featuredArticleCodename);

    const featuredArticleItem = this.kontentDeliveryService.extractModularContent(rawAPIData, featuredArticleCodename);

    let { title, primary_image, url, excerpt, audience_page_url, author_url_slug, category_page_url }: any = this.kontentDeliveryService.extractItemElements(featuredArticleItem, [ 'title', 'primary_image', 'url', 'excerpt', 'audience_page_url', 'author_url_slug', 'category_page_url' ]);


    switch(featuredArticleItem.system.type){
      case 'standard_page':{
        url = this.urlService.buildPageURL(url)
        break;
      }
      case 'ncoa_article_content':{
        url = this.urlService.buildArticleURL(url)
        break;
      }
      case 'author':{
        url = this.urlService.buildAuthorLink(author_url_slug)
        break;
      }
      case 'template___audience_page':
      case 'taxonomy_custom_content':{
        url = this.urlService.buildCategoryURL(category_page_url ?? audience_page_url)
        break;
      }
    }


    if ((rawAPIData.item.elements.homepage_headline.value||'').trim().length > 0) {
      title = rawAPIData.item.elements.homepage_headline.value;
    }

    if (rawAPIData.item.elements.homepage_header_image.value.length > 0) {
      primary_image = rawAPIData.item.elements.homepage_header_image.value;
    }

    return {
      componentType: 'hero',
      title,
      image: {
        url: primary_image && primary_image.length > 0 ? primary_image[0].url : `${window.location.origin}/assets/images/article-hero-coral-desktop.png`,
        alt: primary_image && primary_image.length > 0 ? primary_image[0].description : title,
        responsive: primary_image && primary_image.length > 0 ? this.globalService.remodelImageSrc(primary_image[0].url, 'homeHero') : null,
        width: primary_image && primary_image.length > 0 ? primary_image[0].width : null,
        height: primary_image && primary_image.length > 0 ? primary_image[0].height : null
      },
      cta: {
         url,
         text: rawAPIData?.item?.elements?.featured_content_cta_text?.value,
         newTab: rawAPIData?.item?.elements?.open_featured_content_in_new_tab?.value?.some((opt) => opt.codename === 'yes')
      },
      description: excerpt
    }
  }

  extractAudiencePathing(audiencePath) {
    const [ audiencePathingItem ] = audiencePath.items;

    if(audiencePathingItem) {
      const {
        path_links,
        title
      }: any = this.kontentDeliveryService.extractItemElements(audiencePathingItem, [
        'path_links',
        'title'
      ]);

      const pathLinksRaw = this.kontentDeliveryService.extractModularContent(audiencePath, path_links);

      const links = pathLinksRaw.map(linkData => {
        const { link_name, link_url }: any = this.kontentDeliveryService.extractItemElements(linkData, [ 'link_name', 'link_url' ])
        return {
          url: link_url,
          text: link_name
        }
      });

      return {
        componentType: 'audiencePathing',
        audience: {
          heading: title,
          links: links
        }
      };
    }
  }

  async extractMissionStatement(rawAPIData) {
    const { mission_statement_block }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.item, [ 'mission_statement_block' ]);
    const [ missionStatementItem ] = this.kontentDeliveryService.extractModularContent(rawAPIData, mission_statement_block);

    const {
      mission_headline,
      mission_description,
      linked_content,
      mission_entry_1_description,
      mission_entry_1_image,
      mission_entry_1_title,
      mission_entry_2_description,
      mission_entry_2_image,
      mission_entry_2_title,
      cta_text
    }: any = this.kontentDeliveryService.extractItemElements(missionStatementItem, [
      'mission_headline',
      'mission_description',
      'linked_content',
      'mission_entry_1_description',
      'mission_entry_1_image',
      'mission_entry_1_title',
      'mission_entry_2_description',
      'mission_entry_2_image',
      'mission_entry_2_title',
      'cta_text'
    ]); 

    const cta = this.kontentDeliveryService.extractModularContent(rawAPIData, linked_content[0]);

    let url = '';
    if ( cta ) {
      if (/article/ig.test(cta.system.type)) {
        url = this.urlService.buildArticleURL(cta.elements.url.value);
      } else {
        if (cta.elements.parent_page && cta.elements.parent_page.value.length > 0) {
          const parentPage = this.kontentDeliveryService.extractModularContent(rawAPIData, cta.elements.parent_page.value[0]);
          url = this.urlService.buildPageURL(`${parentPage.elements.url.value}/${cta.elements.url.value}`);
        } else {
          url = this.urlService.buildPageURL(cta.elements.url.value);
        }
      }
    }

    const missionEntry1: any = {
      title: mission_entry_1_title,
      description: mission_entry_1_description,
      image: {
        url: mission_entry_1_image[0].url,
        text: mission_entry_1_image[0].description,
        responsive: this.globalService.remodelImageSrc(mission_entry_1_image[0].url, 'mission'),
        width: mission_entry_1_image[0].width,
        height: mission_entry_1_image[0].height
      }
    }

    const missionEntry2: any = {
      title: mission_entry_2_title,
      description: mission_entry_2_description,
      image: {
        url: mission_entry_2_image[0].url,
        text: mission_entry_2_image[0].description,
        responsive: this.globalService.remodelImageSrc(mission_entry_2_image[0].url, 'mission'),
        width: mission_entry_2_image[0].width,
        height: mission_entry_2_image[0].height
      }
    }

    return {
      componentType: 'missionStatement',
      headline: mission_headline,
      description: mission_description,
      cta: {
        url,
      },
      ctaText: cta_text ?? 'Explore More',
      missionEntry: [ missionEntry1, missionEntry2 ]
    }
  }

  checkCatParents(cat, parentCat) {
    let found = false;
    if (cat.parent.length) {
      cat.parent.forEach(pCat => {
        if (pCat.codename === parentCat) found = true;
      });
    }
    return found;
  }
  buildCategoriesMap(terms, parentPath = []) {
    let flattenedTerms = {};

    terms.forEach(term => {
      flattenedTerms[term.codename] = {
        ...term,
        parent: parentPath
      };

      if ( term.terms.length ) {
        flattenedTerms = {
          ...flattenedTerms,
          ...this.buildCategoriesMap(term.terms, [...parentPath, term])
        }
      }
    });

    return flattenedTerms;
  }
  joinCategoryAndChildCategories(category, catMap) {
    let cats = [category];
    Object.keys(catMap).forEach(c => {
      if (this.checkCatParents(catMap[c], category)) cats.push(c);
    });

    return cats;
  }

  async extractAdditionalComponents(rawAPIData) {
    const { components }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.item, ['components']);
    const rawComponentItems = this.kontentDeliveryService.extractModularContent(rawAPIData, components);
    const contentPackages = rawComponentItems.filter(rawComponent => (
      rawComponent.system.type === this.componentService.componentTypes.content_package
      && rawComponent.elements.content_population_method.value[0].codename === 'dynamically'
    ));
    const allTaxonomies: any = await this.kontentDeliveryService.getTaxonomies('categories');
    const catMap = this.buildCategoriesMap(allTaxonomies.terms);

    // Fetch for additional contents for dynamic content package
    await Promise.all(contentPackages.map(async contentPackage => {
      const { audiences, categories }: any = this.kontentDeliveryService.extractItemElements(contentPackage);

      const primaryArticles = await this.kontentDeliveryService.getItemAndCache(null, {
        'system.type': 'ncoa_article_content',
        'elements.audiences[any]': audiences.map(item => item.codename).join(','),
        'elements.categories[any]': categories.map(item => this.joinCategoryAndChildCategories(item.codename,catMap)).join(','),
        'elements.display_date[nempty]': true,
        'order': 'elements.display_date[desc]',
        limit: 7,
      });

      const secondaryArticles = await this.kontentDeliveryService.getItemAndCache(null, {
        'system.type': 'ncoa_article_content',
        'elements.secondary_audiences[any]': audiences.map(item => item.codename).join(','),
        'elements.secondary_categories[any]': categories.map(item => item.codename).join(','),
        'elements.display_secondary_content_[contains]': 'yes',
        'elements.display_date[nempty]': true,
        'order': 'elements.display_date[desc]',
        limit: 7,
      });
      
      const articles = {
        items: [...primaryArticles.items, ...secondaryArticles.items],
        modular_content: {...primaryArticles.modular_content, ...secondaryArticles.modular_content},
      };

      articles.items.sort((a, b) => new Date(b.elements.display_date.value).getTime() - new Date(a.elements.display_date.value).getTime()); // sort to get most recent at top

      return articles;
    })).then((responses: any[]) => {
      let counter = 0;

      contentPackages.forEach((contentPackage, index) => {
        // Discard manual contents if any
        contentPackage.elements.content.value = [];

        responses[index].items.forEach((article) => {
          if (this.usedArticles.indexOf(article.system.codename) === -1 && counter <= 6) {
            this.usedArticles.push(article.system.codename);
            contentPackage.elements.content.value.push(article.system.codename);
            responses[index].modular_content[article.system.codename] = article;
            counter++;
          }
        });

        Object.values(responses[index].modular_content).forEach((rawContent: any) => {
          rawAPIData.modular_content[rawContent.system.codename] = rawContent;
        });

        counter = 0;
      });
    });

    const manualContentPackage = rawComponentItems.filter(rawComponent => (
      rawComponent.system.type === this.componentService.componentTypes.content_package
      && rawComponent.elements.content_population_method.value[0].codename !== 'dynamically'
    ));

    manualContentPackage.forEach((contentPackage) => {
      const contents = contentPackage.elements.content.value;
      const filteredContents = [];

      contents.forEach((content) => {
        if (!this.usedArticles.includes(content)) {
          filteredContents.push(content);
        }
      });

      const index = rawComponentItems.findIndex((item) => item.system.id === contentPackage.system.id);
      if (index >= 0) {
        rawComponentItems[index].elements.content.value = filteredContents;
      }
    });

    // dynamic with manual additions changes for content package content
    const dynamicWithManualAdditionsContentPackage = rawComponentItems.filter(rawComponent => (
      rawComponent.system.type === this.componentService.componentTypes.content_package
      && rawComponent.elements.content_population_method.value[0].codename === 'dynamic_with_manual_additions'
    ));

    await Promise.all(dynamicWithManualAdditionsContentPackage.map(async (contentPackage) => {
      const { audiences, categories }: any = this.kontentDeliveryService.extractItemElements(contentPackage);

      const primaryArticles = await this.kontentDeliveryService.getItemAndCache(null, {
        'system.type': 'ncoa_article_content',
        'elements.audiences[any]': audiences.map(item => item.codename).join(','),
        'elements.categories[any]': categories.map(item => this.joinCategoryAndChildCategories(item.codename,catMap)).join(','),
        'elements.display_date[nempty]': true,
        'order': 'elements.display_date[desc]',
        limit: 7,
      });

      const secondaryArticles = await this.kontentDeliveryService.getItemAndCache(null, {
        'system.type': 'ncoa_article_content',
        'elements.secondary_audiences[any]': audiences.map(item => item.codename).join(','),
        'elements.secondary_categories[any]': categories.map(item => item.codename).join(','),
        'elements.display_secondary_content_[contains]': 'yes',
        'elements.display_date[nempty]': true,
        'order': 'elements.display_date[desc]',
        limit: 7,
      });
      
      const articles = {
        items: [...primaryArticles.items, ...secondaryArticles.items],
        modular_content: {...primaryArticles.modular_content, ...secondaryArticles.modular_content},
      };

      articles.items.sort((a, b) => new Date(b.elements.display_date.value).getTime() - new Date(a.elements.display_date.value).getTime()); // sort to get most recent at top

      return { articles, contentPackage };
    }))
    .then((responses: any[]) => {
      responses.forEach(res => {
        const contents = res.contentPackage.elements.content.value;
        let filteredContents = [];
        contents.forEach((content) => {
          if (!this.usedArticles.includes(content)) {
            filteredContents.push(rawAPIData.modular_content[content]);
          }
        });
        filteredContents = filteredContents.concat(res.articles.items);

        filteredContents.sort((a, b) => (new Date(a.elements.display_date.value) > new Date(b.elements.display_date.value)) ? -1 : 1);

        const max = filteredContents.length > 6 ? 6 : filteredContents.length;
        const articles = filteredContents.slice(0, max).map(a => { return a.system.codename });

        const index = rawComponentItems.findIndex((item) => item.system.id === res.contentPackage.system.id);

        if (index >= 0) {
          rawComponentItems[index].elements.content.value = articles;
        }
      });
    });

    const processedComponents = this.componentService.processRawComponents(rawComponentItems, rawAPIData.modular_content);

    return processedComponents;
  };

  extractTheme(rawAPIData) {
    const { theme }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.item, ['theme']);

    if ( theme.length ) {
      return theme[0].codename;
    }

    // Default to mid
    return 'mid';
  }
}
