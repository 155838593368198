import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import postscribe from 'postscribe';

@Component({
  selector: 'app-embedded-js',
  templateUrl: './embedded-js.component.html',
  styleUrls: ['./embedded-js.component.scss']
})
export class EmbeddedJsComponent {
  @Input()
  scriptTags: string;

  @ViewChild('script') script: ElementRef;

  kontentClass = 'left';

  constructor() {
  }

  convertToScript() {
      postscribe(`#emscript`, this.scriptTags);
  }

  setStyle(styleConfig: any) {
    // alignment left, center, right
    if (styleConfig && styleConfig?.alignment && styleConfig?.alignment?.value[0]) {
      this.kontentClass = styleConfig.alignment.value[0].name;
    }
  }
}