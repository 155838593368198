import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-lead-gen',
  templateUrl: './lead-gen.component.html',
  styleUrls: ['./lead-gen.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LeadGenComponent implements OnInit {

  @Input() heading: string;
  @Input() intro: string;
  @Input() eyebrow: string;
  @Input() cta: {
    label: string,
    url: string
  };

  @Input() icon: {
    sizes: [
      {
        media: string,
        src: string,
      },
    ],
    src: string,
    alt: string,
  };

  @Input() photo: {
    sizes: [
      {
        media: string,
        src: string,
      },
    ],
    src: string,
    alt: string,
  };

  constructor(
    private router: Router,
    private dataLayerService: DataLayerService,
  ) { }

  trackAnalytics(ev){
    const _element = ev.currentTarget as HTMLAnchorElement;
    const _clickUrl = _element.href || _element.getAttribute('href');
    this.dataLayerService.push({
      'event': 'recirc',
      'url': _clickUrl,
      'componentType': 'lead gen' },false);
  }

  ngOnInit(): void { }
}
