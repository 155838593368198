import { FormGroup } from '@angular/forms';

// Generic validator for Reactive forms
// Implemented as a class, not a service, so it can retain state for multiple forms.
export class ErrorMessageProcessor {

  // Provide the set of valid validation messages
  // Stucture:
  // controlName1: {
  //     validationRuleName1: 'Validation Message.',
  //     validationRuleName2: 'Validation Message.'
  // },
  // controlName2: {
  //     validationRuleName1: 'Validation Message.',
  //     validationRuleName2: 'Validation Message.'
  // }
  constructor(private validationMessages: { [key: string]: { [key: string]: string } }) {

  }

  // Processes each control within a FormGroup
  // And returns a set of validation messages to display
  // Structure
  // controlName1: 'Validation Message.',
  // controlName2: 'Validation Message.'
  processMessages(formGroup: FormGroup): { [key: string]: string } {
    let messages = {};
    for (let controlKey in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(controlKey)) {
        let control = formGroup.controls[controlKey];
        // if it is a FormGroup, process its child controls.
        if (control instanceof FormGroup) {
          let childMessages = this.processMessages(control);
          Object.assign(messages, childMessages);
        } else {
          // Only validate if there are validation messages for the control
          if (this.validationMessages[controlKey]) {
            messages[controlKey] = '';
            if ((control.dirty || control.touched) && control.errors) {
              Object.keys(control.errors).map(messageKey => {
                if (this.validationMessages[controlKey][messageKey]) {
                  // OLD CODE - this wasn't setting the error message if there was already one, resulting in undesirable behavior
                  // messages[controlKey] = messages[controlKey] ? messages[controlKey] :
                  //   this.validationMessages[controlKey][messageKey];
                  messages[controlKey] = this.validationMessages[controlKey][messageKey]; // replace message with most recent error TODO: come up with a nicer solution
                }
              });
            }
          }
        }
      }
    }
    if (this.validationMessages['formErrors']) {
      Object.keys(this.validationMessages['formErrors']).map(formKey => {
        messages[formKey] = ''
        if (formGroup.errors && formGroup.errors[formKey]) {
          messages[formKey] = this.validationMessages['formErrors'][formKey];          
        }
      })
    }
    return messages;
  }
}