import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { UtilityService } from '@services/planner/utility.service';
import { debounceTime } from 'rxjs/operators';
import { minAge, maxAge, dateIsValid } from 'src/app/validators/date-validator';
import { ErrorMessageProcessor } from 'src/app/validators/error-message-processor';
import { zipCodeValidator } from 'src/app/validators/zip-validator';

@Component({
  selector: 'ncoa-medicare-lead-gen',
  templateUrl: './medicare-lead-gen.component.html',
  styleUrls: ['./medicare-lead-gen.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MedicareLeadGenComponent implements OnInit {
  contactForm: FormGroup;
  errorMessageProcessor: ErrorMessageProcessor;
  errorMessages: any;
  buttonDisabled = false;

  @Input() eyebrow: string = '&nbsp;';
  @Input() headline: string;
  @Input() image: any;
  @Input() branding: any;
  @Input() data: any;
  @Input() awp: boolean = false;

  get firstName() { return this.contactForm.get('firstName'); }
  get lastName() { return this.contactForm.get('lastName'); }
  get zipCode() { return this.contactForm.get('zipCode'); }
  get email() { return this.contactForm.get('email'); }
  get dateOfBirth() { return this.contactForm.get('dateOfBirth'); }
  get phoneNumber() { return this.contactForm.get('phoneNumber'); }
  get medicareInterestReason() { return this.contactForm.get('medicareInterestReason'); }

  constructor(
    private formBuilder: FormBuilder,
    private cas: CustomerActivityService,
    private utilService: UtilityService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  submit():void {
    this.buttonDisabled = true;

    if (this.contactForm.valid) {
      this.cas.announceMedicareContact(this.contactForm.value, this.awp, window.location).subscribe(res => {
        if (res && res.status === "received") {
          localStorage.setItem('broker', 'true');
          localStorage.setItem('broker_name', 'mcg_broker');
        } else {
          localStorage.setItem('broker', 'false');
          localStorage.setItem('broker_name', '');
        }
        window.location.href = '/age-well-planner/pages/thanks';
      }, err => {
        throw new Error(err);
      });
    } else {
      this.buttonDisabled = false;
      this.contactForm.markAllAsTouched();
      this.contactForm.updateValueAndValidity();
    }
  }

  createForm(): void {
    this.contactForm = this.formBuilder.group({
      firstName: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
      lastName: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
      email: ['', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur',
      }],
      zipCode: this.formBuilder.control('', [Validators.required], zipCodeValidator(this.utilService)),
      dateOfBirth: ['', {
        validators: [Validators.required, minAge(18), maxAge(100), dateIsValid],
        updateOn: 'blur',
      }],
      phoneNumber: ['', {
        updateOn: 'blur',
      }],
      medicareInterestReason: ['default', {
        updateOn: 'blur'
      }]
    });

    const validationMessages = {
      zipCode: {
        required: 'Required',
        invalidZip: 'Invalid Zip Code'
      },
      dateOfBirth: {
        minAge: `Invalid date format or value. Format should be MM/DD/YYYY and fall within age range of ${18} to ${100}.`,
        maxAge: `Invalid date format or value. Format should be MM/DD/YYYY and fall within age range of ${18} to ${100}.`,
        invalidDate: `Invalid date format or value. Format should be MM/DD/YYYY and fall within age range of ${18} to ${100}.`
      }
    };

    this.errorMessageProcessor = new ErrorMessageProcessor(validationMessages);
    this.errorMessages = this.errorMessageProcessor.processMessages(this.contactForm);

    this.contactForm.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.errorMessages = this.errorMessageProcessor.processMessages(this.contactForm);
    });
  }
}
