<section class="component author-header">
  <div class="author-header__container">
    <div class="author-header__wrapper">
      <div class="image">
        <img *ngIf="image && image.url; else authorPlaceholder" src="{{ image.url }}" alt="{{ image.alt }}" title="{{ image.alt }}">
        <ng-template #authorPlaceholder>
          <picture>
            <source srcset="../../../assets/images/author-hero-mobile.png" media="all and (max-width: 1023px)" />
            <source srcset="../../../assets/images/author-hero-desktop.png" media="all and (min-width: 1024px)" />
            <img src="../../../assets/images/author-hero-desktop.png" [alt]="authorName" />
          </picture>
        </ng-template>
      </div>
      <div class="texts">
        <p class="eyebrow" *ngIf="authorName">{{ eyebrow || 'Articles By' }}</p>
        <h2 class="author-name">{{ authorName }}</h2>
        <p class="author-title">{{ authorTitle }}</p>
        <p class="author-bio" [innerHTML]="authorBio"></p>
        <ul class="links">
          <ng-container *ngFor="let link of links" [ngSwitch]="link.icon">
             <li *ngIf="link.url">
                <a *ngSwitchCase="'facebook'" href="{{ link.url }}" aria-label="View profile on Facebook" target="_blank">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40 20.1217C40 9.00609 31.0484 0 20 0C8.95161 0 0 9.00609 0 20.1217C0 30.1826 7.25806 38.5396 16.8548 40V25.9635H11.7742V20.1217H16.8548V15.7404C16.8548 10.7099 19.8387 7.87018 24.3548 7.87018C26.6129 7.87018 28.871 8.27586 28.871 8.27586V13.2252H26.371C23.871 13.2252 23.0645 14.7667 23.0645 16.3895V20.1217H28.629L27.7419 25.9635H23.0645V40C32.6613 38.5396 40 30.1826 40 20.1217Z" fill="#FFFFFF"/>
                  </svg>
                </a>

                <a *ngSwitchCase="'linkedin'" href="{{ link.url }}" aria-label="View profile on LinkedIn" target="_blank">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM10.5762 8.6875H29.375C30.2051 8.6875 30.9375 9.41992 30.9375 10.2988V29C30.9375 29.8789 30.2051 30.5625 29.375 30.5625H10.5762C9.74609 30.5625 9.0625 29.8789 9.0625 29V10.2988C9.0625 9.41992 9.74609 8.6875 10.5762 8.6875ZM12.4316 17.0371V27.4375H15.6543V17.0371H12.4316ZM12.1387 13.7168C12.1387 14.7422 12.9688 15.5723 14.043 15.5723C15.0684 15.5723 15.8984 14.7422 15.8984 13.7168C15.8984 12.6914 15.0684 11.8125 14.043 11.8125C12.9688 11.8125 12.1387 12.6914 12.1387 13.7168ZM24.541 27.4375H27.8125V21.7246C27.8125 18.9414 27.1777 16.7441 23.9062 16.7441C22.3438 16.7441 21.2695 17.623 20.8301 18.4531H20.7812V17.0371H17.7051V27.4375H20.9277V22.3105C20.9277 20.9434 21.1719 19.625 22.8809 19.625C24.541 19.625 24.541 21.1875 24.541 22.3594V27.4375Z" fill="#FFFFFF"/>
                  </svg>
                </a>

                <a *ngSwitchCase="'twitter'" href="{{ link.url }}" aria-label="View profile on Twitter" target="_blank">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM30.6401 10.105H27.1135L21.3021 16.7491L16.2776 10.1059H9L17.6959 21.4737L9.45425 30.8941H12.9828L19.3442 23.6261L24.9026 30.8941H32L22.9351 18.912L30.6401 10.105ZM27.8303 28.7838H25.8762L13.1189 12.105H15.2157L27.8303 28.7838Z" fill="white"/>
                  </svg>
                </a>

                <a *ngSwitchCase="'email'" href="mailto:{{ link.url }}" aria-label="Email author" target="_blank">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM16.543 15.2783C19.7504 12.9514 20.6623 12.2596 21.3855 11.6936C21.5113 11.5992 21.7 11.6936 21.7 11.8508V18.2656C21.7 19.1146 21.0082 19.775 20.1906 19.775H7.10938C6.26035 19.775 5.6 19.1146 5.6 18.2656V11.8508C5.6 11.6936 5.75723 11.5992 5.88301 11.6936C6.60625 12.2596 7.51816 12.9514 10.7256 15.2783C10.8345 15.3561 10.9562 15.4493 11.0887 15.5507C11.7594 16.0641 12.7047 16.7877 13.65 16.7877C14.6068 16.7877 15.6111 16.0035 16.2704 15.4886C16.3695 15.4112 16.4608 15.34 16.543 15.2783ZM15.5802 14.7356C15.017 15.1698 14.2318 15.7753 13.65 15.75C13.0395 15.7754 12.2437 15.1629 11.679 14.7282C11.5456 14.6256 11.4252 14.5328 11.323 14.4607C7.48614 11.6912 6.90467 11.2244 6.10635 10.5834C6.03451 10.5257 5.9609 10.4666 5.88301 10.4043C5.69434 10.2785 5.6 10.0584 5.6 9.80684V9.20937C5.6 8.3918 6.26035 7.7 7.10938 7.7H20.1906C21.0082 7.7 21.7 8.3918 21.7 9.20937V9.80684C21.7 10.0584 21.5742 10.2785 21.3855 10.4043C21.3077 10.4666 21.234 10.5257 21.1622 10.5834C20.3639 11.2244 19.7824 11.6912 15.9455 14.4607C15.8409 14.5346 15.7171 14.63 15.5802 14.7356Z" fill="#FFFFFF"/>
                  </svg>
                </a>
            </li>
          </ng-container>
        </ul>
      </div>
      <div *ngIf="!isInCategorySearch" class="rectangle-box"></div>
      <svg *ngIf="!isInCategorySearch" class="rectangle rectangle--desktop" width="1336" height="65" viewBox="0 0 1336 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 60C0 26.8629 26.8629 0 60 0H1336V106H0V60Z" fill="white"/>
      </svg>
    </div>
  </div>
</section>
