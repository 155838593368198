import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, Subscription, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import * as AWS from 'aws-sdk';
import * as aws4 from 'ngx-aws4';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import dayjs from 'dayjs';
import { ToolsPrimaryConfigService } from './tools-primary-config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly MAX_IDLE_TIME = parseInt((environment as any).maxUserIdleTime);
  private readonly MAX_IDLE_TIMEOUT = parseInt((environment as any).maxUserIdleTimeOut);
  private idleState = 'Not started.';
  private userTimedOut = false;
  private refreshTokenSub: Subscription = new Subscription();

  private _isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.authSet());

  private idPool = new IdentityPool();

  private baseUrl: string;

  constructor(
    private http: HttpClient, private idle: Idle,
    private keepalive: Keepalive, private router: Router,
    private tools: ToolsPrimaryConfigService,
  ) {
    if (environment) {
      this.baseUrl = (environment as any).ncoaApiBaseUrl + '/users';
    } else {
      this.baseUrl = ''
    }
    if (this.authSet()) {
      this.idPool.setupCredentials(false, this.idToken).subscribe();
      this.initalizeUserSession();
    } else {
      this.idPool.setupCredentials(true).subscribe();
    }
  }

  authSet(): boolean {
    return Boolean(this.idToken && this.accessToken && this.refreshToken && this.userEmail);
  }

  logout() {
    this.idle.stop();
    this.clearStorage();
    this.idPool.logout();
    this.idPool = new IdentityPool();
    this.idPool.setupCredentials(true).subscribe();
    this._isLoggedInSubject.next(false);
  }

  public makeSignedCall(method, path, httpOptions, baseUrl?, body?): Observable<any> {
    return Observable.create(async (responseSubject: Subject<any>) => {
      const signature = await this.generateAuthSignature(method, (environment as any).ncoaApiBaseUrl, path, body);
      httpOptions.headers = signature;
      const url = baseUrl ? baseUrl : (environment as any).ncoaApiBaseUrl + '/' + path;

      if (method === 'GET') {
        this.http.get(url, httpOptions).subscribe((data: any) => {
          responseSubject.next(data);
        })
      } else if (method === 'POST') {
        this.http.post(url, body, httpOptions).pipe(catchError(err => {
          return of(null);
        })).subscribe((data: any) => {
          responseSubject.next(data);
        })
      } else if (method === 'PUT') {
        this.http.put(url, body, httpOptions).subscribe((data: any) => {
          responseSubject.next(data);
        })
      } else if (method === 'DELETE') {
        this.http.delete(url, httpOptions).subscribe((data: any) => {
          responseSubject.next(data);
        })
      }

    });
  }

  async generateAuthSignature(method, endpoint, path, body?) {
    await this.idPool.getCredentials();
    await this.idPool.refreshCredentials();
    const credentials = {
      accessKeyId: this.idPool.accessKeyId,
      secretAccessKey: this.idPool.secretAccessKey,
      sessionToken: this.idPool.sessionToken
    }
    const awsCreds = new AwsSignature();
    return await awsCreds.getSignature(method, endpoint, path, credentials, body);
  }

  public signUp(username: string, password: string): Observable<any> {
    return this.http.post(this.baseUrl + '/signUp', { email: username, password });
  }

  public signIn(username: string, password: string): Observable<any> {
    return this.http.post(this.baseUrl + '/signIn', { email: username, password }).pipe(
      tap((response) => {
        if (response.signedInUser && response.signedInUser.AuthenticationResult) {
          window.localStorage.removeItem('guestIdentityExpiry');

          const authResult = response.signedInUser.AuthenticationResult;
          window.localStorage.setItem(PoolAtr.IdToken, authResult.IdToken);
          window.localStorage.setItem(PoolAtr.AccessToken, authResult.AccessToken);
          window.localStorage.setItem(PoolAtr.RefreshToken, authResult.RefreshToken);
          window.localStorage.setItem(PoolAtr.Username, username);

          // window.localStorage.setItem(PoolAtr.Expiry, formatISO(addSeconds(new Date(), authResult.ExpiresIn)));
          window.localStorage.setItem(PoolAtr.Expiry, dayjs().add(authResult.ExpiresIn, 'second').toISOString());

          this.idPool.setupCredentials(false, authResult.IdToken).subscribe();
          this.initalizeUserSession();
          this._isLoggedInSubject.next(true);
        }
      })
    );
  }

  public refreshSession(): Observable<any> {
    return this.http.post(this.baseUrl + '/refreshToken', { refreshToken: this.refreshToken }).pipe(
      tap((response) => {
        if (response.updateCredentials && response.updateCredentials.AuthenticationResult) {
          const authResult = response.updateCredentials.AuthenticationResult;
          window.localStorage.setItem(PoolAtr.IdToken, authResult.IdToken);
          window.localStorage.setItem(PoolAtr.AccessToken, authResult.AccessToken);

          // window.localStorage.setItem(PoolAtr.Expiry, formatISO(addSeconds(new Date(), authResult.ExpiresIn)));
          window.localStorage.setItem(PoolAtr.Expiry, dayjs().add(authResult.ExpiresIn, 'second').toISOString());

          this.idPool.setupCredentials(false, authResult.IdToken).subscribe();
          this.scheduleRefresh();
        }
      })
    );
  }

  public forgotPassword(email: string) {
    const forgotPasswordBody = { email };
    return this.http.post(this.baseUrl + '/forgotPassword', forgotPasswordBody);
  }

  public changePassword(previousPassword: string, proposedPassword: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.idToken || '',
        'Access-Token': this.accessToken || ''
      })
    };
    const changePasswordBody = {
      previousPassword,
      proposedPassword
    }
    return this.http.post(this.baseUrl + '/changePassword', changePasswordBody, httpOptions);
  }

  public resetPassword(sub: string, confirmationCode: string, password: string): Observable<any> {
    return this.http.post(this.baseUrl + '/verify', { sub, confirmation_code: confirmationCode, password });
  }

  public getProfile(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.idToken || '',
        'Access-Token': this.accessToken || ''
      })
    };
    return this.http.get(this.baseUrl + '/profile', httpOptions);
  }

  public verifyUser(sub: string, confirmationCode: string): Observable<any> {
    return this.http.post(this.baseUrl + '/verify', { sub, confirmation_code: confirmationCode });
  }

  public isLoggedIn(): Observable<boolean> {
    return this._isLoggedInSubject.asObservable();
  }

  public initalizeUserSession() {
    if (this.remainingTokenLifeInSeconds > 0) {
      // sets an idle timeout period.
      this.idle.setIdle(this.MAX_IDLE_TIME);
      // sets a timeout period.
      this.idle.setTimeout(this.MAX_IDLE_TIMEOUT);
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.'
      });

      this.idle.onIdleStart.subscribe(() => {
          this.idleState = 'You\'ve gone idle!'
      });

      this.idle.onTimeout.subscribe(() => {


        const timedOutModal = document.querySelector('#timeout-modal');
        if (timedOutModal) {
          this.idleState = 'TIMED_OUT';
          this.userTimedOut = true;
          this.logout();

          const topOffset = window.scrollY;

          document.body.style.top = `-${topOffset}px`;
          document.body.style.position = 'fixed';
          document.body.style.width = `${window.innerWidth}px`;

          timedOutModal.classList.add('is-active');
        } else {
          setTimeout(() => {
            this.router.navigate([this.tools.routes.find((route) => route.type === 'sign-in').route]);
          }, 100);
        }

      });

      this.scheduleRefresh();

      this.idle.watch();
      this.idleState = 'Started.';
      this.userTimedOut = false;
    } else {
      //should never get here realistically
      this.logout();
    }
  }

  scheduleRefresh() {
    if (!this.userTimedOut) {
      // sets the interval period for refreshing token
      this.keepalive.interval(this.remainingTokenLifeInSeconds);

      if (this.refreshTokenSub && !this.refreshTokenSub.closed) {
        this.refreshTokenSub.unsubscribe();
        this.refreshTokenSub = null;
      }

      this.refreshTokenSub = this.keepalive.onPing.subscribe(
        () => {
          if (!this.userTimedOut) {
            this.refreshSession().toPromise();
          }
        });
    }
  }

  updateUserID() {
    this.clearStorage();
    this.idPool = new IdentityPool();
    this.idPool.setupCredentials(true).subscribe();
  }

  clearStorage() {
    window.localStorage.removeItem('accessKey');
    window.localStorage.removeItem('secretAccessKey');
    window.localStorage.removeItem('sessionToken');
    window.localStorage.removeItem('username');
    window.localStorage.removeItem('AccessToken');
    window.localStorage.removeItem('RefreshToken');
    window.localStorage.removeItem('IdToken');
    window.localStorage.removeItem('ExpiresIn');
    localStorage.clear();
  }

  get CADId() {
    return localStorage.getItem(`aws.cognito.identity-id.${environment.identityPoolId}`)
  }

  get userEmail() {
    return window.localStorage.getItem(PoolAtr.Username)
  }

  get accessToken() {
    return window.localStorage.getItem(PoolAtr.AccessToken);
  }

  get refreshToken() {
    return window.localStorage.getItem(PoolAtr.RefreshToken);
  }

  get idToken() {
    return window.localStorage.getItem(PoolAtr.IdToken);
  }

  get tokenExpiry() {
    return window.localStorage.getItem(PoolAtr.Expiry);
  }

  get remainingTokenLifeInSeconds() {
    // return this.tokenExpiry ? differenceInSeconds(new Date(this.tokenExpiry), new Date()) : 0;
    return this.tokenExpiry ? dayjs(this.tokenExpiry).diff(dayjs(), 'second') : 0;
  }

  get sessionExpired(): boolean {
    return window.localStorage.getItem('sessionExpired') && this.userTimedOut ? true : false;
  }
  public postEveryActionSignup(url: string, params:any){
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    const query = new HttpParams({ fromObject: params });
    return this.http.post(url, query.toString(), options)

  }
  getFormData(url): Promise<any> {
    return this.http.get(url).toPromise();
  }

}

enum PoolAtr {
  IdToken = 'IdToken',
  AccessToken = 'AccessToken',
  RefreshToken = 'RefreshToken',
  Username = 'username',
  Expiry = 'ExpiresIn'
}

/**
 * Amazon web services (AWS) Identity pool utility class.
 */
class IdentityPool {
  private aws = AWS;

  private providerName;
  private _identityId: string;

  constructor() {
    this.aws.config.update({
      region: (environment as any).region
    });
    this.setProviderName();
  }

  get accessKeyId() {
    return window.localStorage.getItem('accessKey');
  }

  get secretAccessKey() {
    return window.localStorage.getItem('secretAccessKey');
  }

  get sessionToken() {
    return window.localStorage.getItem('sessionToken');
  }

  get identityId() {
    return window.localStorage.getItem('identityId');
  }

  setProviderName() {
    this.providerName = `cognito-idp.${(environment as any).region}.amazonaws.com/${(environment as any).userPoolId}`;
  }

  setupCredentials(isGuest: boolean = false, token?: string) {
    return Observable.create(async (setCredsSubject: Subject<any>) => {
      let loginProviders = {};
      if (!isGuest && token) {
        loginProviders = {
          [this.providerName]: token
        }
      }

      let isNewCredentials = false;

      const loadCredentials = () => {
        this.aws.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: (environment as any).identityPoolId,
          IdentityId: (window.localStorage.getItem('linkUser') === 'true') &&
            window.localStorage.getItem('guestIdentity') ? window.localStorage.getItem('guestIdentity') : undefined,
          Logins: loginProviders
        });

        isNewCredentials = true;
      };

      if (Object.keys(loginProviders).length === 0 && !window.localStorage.getItem('sessionToken')) {
        // if guest and no session stored yet
        // request cognito credentials
        loadCredentials();
      } else if (Object.keys(loginProviders).length === 0 && window.localStorage.getItem('sessionToken')) {
        // if guest but has session stored already
        if (window.localStorage.getItem('guestIdentityExpiry')) {
          let expireTime: any = window.localStorage.getItem('guestIdentityExpiry');
              expireTime = new Date(parseInt(expireTime));

          const now = new Date();

          if (expireTime < now) {
            // if session already expired
            loadCredentials();
          }
        } else {
          loadCredentials();
        }
      } else if (Object.keys(loginProviders).length > 0) {
        // if logged in
        // request cognito credentials
        loadCredentials();
      }

      await this.getCredentials(isNewCredentials);
      await this.refreshCredentials(isNewCredentials);

      if (isGuest) {
        window.localStorage.setItem('guestIdentity', this._identityId);
      } else if (window.localStorage.getItem('guestIdentity') && !isGuest) {
        window.localStorage.removeItem('linkUser');
        window.localStorage.removeItem('guestIdentity');
      }

      setCredsSubject.next();
    });
  }

  async getCredentials(isNewCredentials = true) { // Credentials will be available when this function is called.
    const getFunc = (this.aws.config.credentials as AWS.CognitoIdentityCredentials)?.get;

    if (getFunc) {
      (this.aws.config.credentials as AWS.CognitoIdentityCredentials).get(async (error) => {
        if (error) {
          return {};
        }

        if (isNewCredentials) {
          window.localStorage.setItem('accessKey', this.aws.config.credentials.accessKeyId);
          window.localStorage.setItem('secretAccessKey', this.aws.config.credentials.secretAccessKey);
          window.localStorage.setItem('sessionToken', this.aws.config.credentials.sessionToken);

          if (!window.localStorage.getItem('username')) {
            let expireTime: any = (this.aws.config.credentials as AWS.CognitoIdentityCredentials).expireTime;
                expireTime = new Date(expireTime).getTime().toString();
            window.localStorage.setItem('guestIdentityExpiry', expireTime);
          }

          this._identityId = (this.aws.config.credentials as AWS.CognitoIdentityCredentials).identityId;
        } else {
          this._identityId = window.localStorage.getItem('guestIdentity');
        }
      });
    } else {
      if (!isNewCredentials) {
        this._identityId = window.localStorage.getItem('guestIdentity');
      }
    }
  }

  async refreshCredentials(isNewCredentials = true) {
    const needsRefreshFunc = (this.aws.config.credentials as AWS.CognitoIdentityCredentials)?.needsRefresh;

    if (needsRefreshFunc) {
      if (isNewCredentials && (this.aws.config.credentials as AWS.CognitoIdentityCredentials).needsRefresh()) {
        await (this.aws.config.credentials as AWS.CognitoIdentityCredentials).refreshPromise();
      }
    }
  }

  logout() {
    const clearFunc = (this.aws.config.credentials as AWS.CognitoIdentityCredentials)?.clearCachedId;
    if (clearFunc) {
      (this.aws.config.credentials as AWS.CognitoIdentityCredentials).clearCachedId();
    }
  }


}

/**
 * Amazon web services (AWS) Signature version 4 - API request signing utility class.
 */
class AwsSignature {

  DEFAULT_TYPE = 'application/json';
  parser = document.createElement('a');

  constructor() { }

  public async getSignature(method, endpoint, path, creds?, body?) {
    if (method === 'GET') {
      return await this._request({ verb: 'GET', endpoint, path }, creds)
    } else if (method === 'POST') {
      return await this._request({ verb: 'POST', endpoint, path, body }, creds)
    } else if (method === 'PUT') {
      return await this._request({ verb: 'PUT', endpoint, path, body }, creds)
    } else if (method === 'DELETE') {
      return await this._request({ verb: 'DELETE', endpoint, path }, creds)
    }
  }

  private async _request(request, credentials) {

    const reqEndpoint = /(^https?:\/\/[^\/]+)/g.exec(request.endpoint)[1]
    const reqPathComponent = request.endpoint.substring(reqEndpoint.length)
    const verb = request.verb
    const path = reqPathComponent + '/' + request.path
    const headers = request.headers || {}

    // If the user has not specified an override for Content type the use default
    if (headers['Content-Type'] === undefined) { headers['Content-Type'] = this.DEFAULT_TYPE }

    // If the user has not specified an override for Accept type the use default
    if (headers.Accept === undefined) { headers.Accept = this.DEFAULT_TYPE }

    let body = request.body
    // override request body and set to empty when signing GET requests
    body = (body === undefined || verb === 'GET') ? '' : JSON.stringify(body)

    // If there is no body remove the content-type header so it is not included in SigV4 calculation
    if (body === '' || body === undefined || body === null) { delete headers['Content-Type'] }

    this.parser.href = reqEndpoint

    const aws4Options = {
      host: this.parser.hostname,
      path,
      method: verb,
      headers,
      body,
      service: 'execute-api',
      date: new Date().toISOString().replace(/-|:|\..{3}/g, '')
    }

    const aws4Sign = aws4.sign(
      aws4Options,
      {
        secretAccessKey: credentials.secretAccessKey, accessKeyId: credentials.accessKeyId,
        sessionToken: credentials.sessionToken
      }
    )
    aws4Sign.url = reqEndpoint + path
    if (headers['Content-Type'] === undefined) { headers['Content-Type'] = this.DEFAULT_TYPE }
    delete headers.Host
    delete headers['Content-Length']
    return aws4Sign.headers;
  }





}
