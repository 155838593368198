import { Injectable } from '@angular/core';
// import { environment } from 'src/environments/environment';
import { environment } from '@environment';
import { Observable } from 'rxjs';

// import { UserService } from '../user/user.service';
import { UserService } from './user.service';
import { take } from 'rxjs/operators';
import { ClientInfo } from './case-manager.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerActivityService {
  baseUrl: string;


  constructor(
    private userService: UserService
  ) {
    this.baseUrl = (environment as any).customerActivityBaseUrl || '';
  }

  getActivity(): Observable<any> {
    return this.userService.makeSignedCall('GET', 'activity', {}, this.baseUrl);
  }

  updateActivity(updateObj: any): Observable<any> {
    return this.userService.makeSignedCall('POST', 'activity', {}, this.baseUrl, updateObj);
  }

  submitConsentForBroker(): Observable<any> {
    const body = { userConsentedForBroker: true };
    return this.userService.makeSignedCall('POST', 'activity/broker/customer/consent', {}, this.baseUrl, body);
  }

  submitConsentForAnnuities(formInput?): Observable<any> {
    const body = { userConsentedForAnnuities: true, form: formInput };
    return this.userService.makeSignedCall('POST', 'activity/consentAnnuities', {}, this.baseUrl, body);
  }

  announceLogin(): Observable<any> {
    return this.userService.makeSignedCall('POST', 'activity/clientEvent', {}, this.baseUrl, { event: 'SIGN_IN', email: this.userService.userEmail });
  }

  announceSignUp(): Observable<any> {
    return this.userService.makeSignedCall('POST', 'activity/clientEvent', {}, this.baseUrl, { event: 'SIGN_UP' });
  }

  announceLogout(): Observable<any> {
    return this.userService.makeSignedCall('POST', 'activity/clientEvent', {}, this.baseUrl, { event: 'LOG_OUT', email: this.userService.userEmail });
  }

  announceAnnuitiesLeadPage(): Observable<any> {
    return this.userService.makeSignedCall('POST', 'activity/clientEvent', {}, this.baseUrl, { event: 'leadannuitypage', email: this.userService.userEmail });
  }

  announceMedicareContact(formInput, isAwp, location, partner?): Observable<any> {
    return this.userService.makeSignedCall('POST', 'activity/clientEvent', {}, this.baseUrl, { event: 'MEDICARE_CONTACT', form : formInput, isAwp, location, partner});
  }

  announceEvent(event, formInput, isAwp, location, partner?): Observable<any> {
    return this.userService.makeSignedCall('POST', 'activity/clientEvent', {}, this.baseUrl, { event, form : formInput, isAwp, location, partner});
  }

  getQuestionForID(code: string, isCaseManager?, clientId?): Observable<any> {
    return this.userService.makeSignedCall('POST', 'activity/getQuestionForID', {}, this.baseUrl, {questionCode: code, isCaseManager, clientId});
  }

  getClients(managerId:string): Promise<ClientInfo[]> {
    return this.userService.makeSignedCall('GET', 'activity/clients', {}, this.baseUrl,{origin:'awp',id:managerId}).pipe(take(1)).toPromise();
  }

  getClientHistory(id:string):Promise<any> {
    return this.userService.makeSignedCall('POST', 'activity/clientEventHistory', {}, this.baseUrl,{"userId":id,event:'test'}).pipe(take(1)).toPromise();
  }
  announceAnalyzeBudgetViewed(): Observable<any> {
    return this.userService.makeSignedCall('POST', 'activity/clientEvent', {}, this.baseUrl, { event: 'ANALYZE_BUDGET_VIEWED' });
  }
  announceAnalyzeBudgetEdited(): Observable<any> {
    return this.userService.makeSignedCall('POST', 'activity/clientEvent', {}, this.baseUrl, { event: 'ANALYZE_BUDGET_EDITED' });
  }
  announcePlanReset(): Observable<any> {
    return this.userService.makeSignedCall('POST', 'activity/clientEvent', {}, this.baseUrl, { event: 'PLAN_RESET' });
  }

}
