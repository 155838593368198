<section class="component feature-page-block feature-page-block--{{orientation}}">
  <div class="feature-page-block__container">
    <div class="feature-page-block__wrapper feature-page-block__wrapper--{{background}}">
      <div class="feature-page-block__texts">
        <h2 class="headline" [innerHTML]="headline"></h2>
        <p class="description" [innerHtml]="description"></p>
        <a *ngIf="url" class="button button--{{ background }}" [attr.href]="url" [attr.title]="getCTATitle(type)">
          {{ getCTATitle(type) }}
        </a>
      </div>
      <div class="feature-page-block__image">
        <div class="section">
          <img *ngIf="!image.responsive" [attr.src]="image.url" [attr.alt]="image.alt" [attr.title]="image.alt"
            loading="lazy">
          <picture *ngIf="image.responsive">
            <source *ngFor="let item of image.responsive" [srcset]="item.url" [media]="item.media" />
            <img [src]="image.url" [alt]="image.alt" loading="lazy" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>