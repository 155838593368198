import { Component, OnInit, Input } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-text-with-image',
  templateUrl: './text-with-image.component.html',
  styleUrls: ['./text-with-image.component.scss']
})
export class TextWithImageComponent implements OnInit {

  @Input() heading: string;
  @Input() intro: string;
  @Input() cta: {
    label: string,
    url: string
  };
  @Input() image: {
    sizes: [
      {
        media: string,
        url: string,
      },
    ],
    src: string,
    alt: string,
  };

  @Input() alignment: string;

  constructor(private dataLayerService: DataLayerService) { }


  trackAnalytics(ev){
    const _element = ev.currentTarget as HTMLAnchorElement;
    const _clickUrl = _element.href || _element.getAttribute('href');
    
    this.dataLayerService.push({ 
      'event': 'recirc', 
      'url': _clickUrl,
      'componentType': 'text w/ image cta' },false); 
  }

  ngOnInit(): void {
  }

}
