import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserPromptService } from '@services/planner/user-prompt.service';
import { UserService } from '@services/planner/user.service';
import { UtilityService } from '@services/planner/utility.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ncoa-planner-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Output() showModal: EventEmitter<any> = new EventEmitter();

  @Input() index: number;
  @Input() id: string;
  @Input() linkedID: string;
  @Input() linkedCodename: string;
  @Input() type: string;
  @Input() variation?: string;
  @Input() background: {
    url: string,
    alt: string
  };
  @Input() intro: string;
  @Input() heading: string;
  @Input() body: string;
  @Input() cta: {
    label: string,
    url: string
  };
  @Input() photo: {
    url: string,
    alt: string
  };
  @Input() eyebrow: string;
  @Input() breadcrumb: string;
  @Input() bookmark: boolean;
  @Input() full: boolean;
  @Input() theme: string;
  @Input() full_desc: string;
  @Input() program_cta: {
    url: string;
    text: string;
  };
  @Input() isSaved: boolean = false;
  @Input() titleAriaLabel: string;

  constructor(
    private utility: UtilityService,
    private tools: ToolsPrimaryConfigService,
    private userService: UserService,
    private modalService: UserPromptService,
    private kontent: KontentDeliveryService,
  ) { }

  ngOnInit(): void {
    if (this.id) {
      const savedContent = this.tools.customerActivity.basic?.SavedContent || [];
      const flag = savedContent.find((content) => content.ContentId === this.id);

      if (flag) {
        this.isSaved = true;
      }

      if (this.type === 'tool' && this.linkedID) {
        const flag = savedContent.find((content) => content.ContentId === this.linkedID);

        if (flag) {
          this.isSaved = true;
        }
      }
    }
  }

  clickSave(): void {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        if (this.linkedID && this.linkedCodename) {
          this.kontent.getItem(null, {
            'system.type': 'awa_benefits_tool___card__tool_',
            'elements.tool[any]': this.linkedCodename,
          }).then((response) => {
            if (response.items && response.items.length > 0) {
              this.utility.getSavedContent().then((savedContent) => {
                let newSavedContent;
                let isSaved;

                const found = savedContent.find((content) => content.ContentId === this.id);
                const toolCards = Array.from(document.querySelectorAll(`.card--tool[linked-id="${this.linkedID}"]`));

                if (found) {
                  // un-save tool cards
                  newSavedContent = savedContent;

                  response.items.forEach((item) => {
                    newSavedContent = newSavedContent.filter((content) => content.ContentId !== item.system.id);
                  });

                  // un-save tool
                  newSavedContent = newSavedContent.filter((content) => content.ContentId !== this.linkedID);

                  // dom manipulation
                  toolCards.forEach((toolCard) => {
                    const bookmark = toolCard.querySelector('.card--bookmark');
                    const svgPath = bookmark.querySelector('svg path');
                    const textContainer = bookmark.querySelector('.save--text');

                    svgPath.setAttribute('fill', 'none');
                    textContainer.innerHTML = 'Save';
                  });

                  isSaved = false;
                } else {
                  // save tool cards
                  newSavedContent = savedContent;

                  response.items.forEach((item) => {
                    newSavedContent = newSavedContent.concat({ ContentId: item.system.id });
                  });

                  // save tool
                  newSavedContent = newSavedContent.concat({ ContentId: this.linkedID });

                  // dom manipulation
                  toolCards.forEach((toolCard) => {
                    const bookmark = toolCard.querySelector('.card--bookmark');
                    const svgPath = bookmark.querySelector('svg path');
                    const textContainer = bookmark.querySelector('.save--text');

                    svgPath.setAttribute('fill', '#ffffff');
                    textContainer.innerHTML = 'Saved';
                  });

                  isSaved = true;
                }

                this.utility.updateSavedContent(newSavedContent).then(() => this.isSaved = isSaved);
              });
            }
          });
        } else {
          this.utility.getSavedContent().then((savedContent) => {
            let newSavedContent;
            let isSaved;

            const found = savedContent.find((content) => content.ContentId === this.id);

            if (found) {
              newSavedContent = savedContent.filter((content) => content.ContentId !== this.id);
              isSaved = false;
            } else {
              newSavedContent = savedContent.concat({ ContentId: this.id });
              isSaved = true;
            }

            this.utility.updateSavedContent(newSavedContent).then(() => this.isSaved = isSaved);
          });
        }
      } else {
        this.modalService.isModalOpen(true);
      }
    });
  }

  showBenefitModal(): void {
    this.showModal.emit(this.index);
  }
}
