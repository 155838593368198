<div class="component component--medicare-lead-gen">
  <div class="component__container component-spacing">
    <div class="component__wrapper">
      <div class="medicare-lead-gen">
        <div class="medicare-lead-gen__wrapper">
          <div class="medicare-lead-gen__text">
            <div class="medicare-lead-gen__heading">
              <h2 class="medicare-lead-gen__eyebrow" [innerHTML]="eyebrow"></h2>
              <h3 class="medicare-lead-gen__headline">{{ headline }}</h3>
            </div>
            <div class="medicare-lead-gen__image">
              <picture *ngIf="image">
                <source *ngFor="let item of image.sizes" [srcset]="item.url" [media]="item.media" />
                <img [src]="image.src" [alt]="image.alt" />
              </picture>
            </div>
            <div *ngIf="branding" class="medicare-lead-gen__branding">
              <img [src]="branding.src" [alt]="branding.alt" />
            </div>
          </div>
          <div class="medicare-lead-gen__form">
            <div class="medicare-lead-gen__form__wrapper">
              <form [formGroup]="contactForm" (ngSubmit)="submit()" role="form">
                <h4 *ngIf="data.form_instructions" class="medicare-lead-gen__instructions">{{ data.form_instructions }}</h4>
                <div class="medicare-lead-gen__fields">
                  <div class="medicare-lead-gen__field" [ngClass]="{'has-error': firstName.errors && firstName.touched}">
                    <input type="text" value="" aria-required="true" name="firstName" id="firstName" formControlName="firstName" [placeholder]="data.first_name_label" required>
                    <div *ngIf="firstName.errors && firstName.touched">
                      <div class="error" *ngIf="firstName.errors.required">{{ data.first_name_required_error }}</div>
                    </div>
                  </div>

                  <div class="medicare-lead-gen__field" [ngClass]="{'has-error': lastName.errors && lastName.touched}">
                    <input type="text" aria-required="true" name="lastName" id="lastName" [placeholder]="data.last_name_label" formControlName="lastName" required>
                    <div *ngIf="lastName.errors && lastName.touched">
                      <div class="error" *ngIf="lastName.errors.required">{{ data.last_name_required_error }}</div>
                    </div>
                  </div>
                  <div class="medicare-lead-gen__field half">
                    <div class="medicare-lead-gen__field" [ngClass]="{'has-error': dateOfBirth.errors && dateOfBirth.touched}">
                      <input type="text" aria-required="true" name="dateOfBirth" id="dateOfBirth" formControlName="dateOfBirth" placeholder="mm/dd/yyyy" mask="00/00/0000" [dropSpecialCharacters]="false" [placeholder]="data.date_of_birth_label" [validation]="true" [ngClass]="" required>
                      <div *ngIf="dateOfBirth.errors && dateOfBirth.touched">
                        <div class="error" *ngIf="dateOfBirth.errors.required">{{ data.date_of_birth_required_error }}</div>
                        <div class="error" *ngIf="dateOfBirth.errors.mask">{{ data.date_of_birth_invalid_error }}</div>
                        <div class="error" *ngIf="dateOfBirth.errors.minAge || dateOfBirth.errors.maxAge || dateOfBirth.errors.invalidDate">{{ errorMessages['dateOfBirth'] }}</div>
                      </div>
                    </div>
                    <div class="medicare-lead-gen__field" [ngClass]="{'has-error': (zipCode.errors && zipCode.touched) || errorMessages.zipCode}">
                      <input type="text" aria-required="true" name="zipCode" id="zipCode" formControlName="zipCode"  mask="00000" [validation]="true" [placeholder]="data.zip_code_label" required>
                      <div *ngIf="(zipCode.errors && zipCode.touched) || errorMessages.zipCode">
                        <div class="error" *ngIf="zipCode?.errors?.required">{{ data.zip_code_required_error }}</div>
                        <div class="error" *ngIf="zipCode?.errors?.mask">{{ data.zip_code_invalid_error }}</div>
                        <div class="error" *ngIf="!zipCode?.errors?.mask && !zipCode?.errors?.required && errorMessages.zipCode">{{ errorMessages.zipCode }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="medicare-lead-gen__field">
                    <input type="tel" name="phoneNumber" id="phoneNumber" formControlName="phoneNumber" [placeholder]="data.phone_number_label" mask="000-000-0000" [dropSpecialCharacters]="true" [validation]="true">
                    <div *ngIf="phoneNumber.errors && phoneNumber.touched">
                      <div class="error" *ngIf="phoneNumber.errors.mask">{{ data.phone_number_invalid_error }}</div>
                    </div>
                  </div>
                  <div class="medicare-lead-gen__field" [ngClass]="{'has-error': email.errors && email.touched}">
                    <input type="email" aria-required="true" name="email" id="email" [placeholder]="data.email_label" formControlName="email"  required>
                    <div *ngIf="email.errors && email.touched">
                      <div class="error" *ngIf="email.errors.required">{{ data.email_required_error }}</div>
                      <div class="error" *ngIf="email.errors.email">{{ data.email_invalid_error }}</div>
                    </div>
                  </div>
                  <div class="medicare-lead-gen__field">
                    <select name="medicare-interest-reason" aria-label="I am" name="medicareInterestReason" id="medicareInterestReason" formControlName="medicareInterestReason">
                      <option value="default" disabled>What best describes you?</option>
                      <option value="eligible">I’m eligible for Medicare (I’m age 65 or older or I have a disability)</option>
                      <option value="approaching_eligibility">I’m approaching eligibility</option>
                      <option value="caregiver">I’m a caregiver for an older adult</option>
                      <option value="more_information">I’d like more Medicare information</option>
                    </select>
                  </div>
                </div>
                <span *ngIf="data.form_agreement_text" class="medicare-lead-gen__agreement">{{ data.form_agreement_text }}</span>
                <button type="submit" [disabled]="buttonDisabled" [attr.aria-label]="data.form_cta_text">{{ data.form_cta_text }}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
