import { Component, AfterViewInit, Input, Output, ViewChild, HostListener } from '@angular/core';

@Component({
  selector: 'ncoa-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class Button implements AfterViewInit {
  @Input() text = "";

  @Input() onClick: Function = null;

  @Output() testAlert = "test alert";

  @ViewChild(Button) componentRef;
  @ViewChild('textRef') textRef;

  constructor() { }

  ngAfterViewInit() {
    console.log('button', this.componentRef, this.textRef);
  }

  onTransitionEnd(e) {
    console.log('transitionend', e, this.componentRef);
  }

  @HostListener('transitionend') transitionend(event) {
    console.log('transitionend from hostlistener');
  }

}
