import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TimelineLite } from 'gsap';

@Component({
  selector: 'ncoa-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss']
})
export class ListHeaderComponent implements OnInit, OnChanges {
  @Input() searchKey?: string;
  @Input() totalItems ?: number;
  @Input() audienceOptions?: any[];
  @Input() chosenAudience: string = '';
  @Input() chosenType: string = '';

  @Output() searchArticle: EventEmitter<any> = new EventEmitter();
  @Output() filterArticle: EventEmitter<any> = new EventEmitter();

  input: string;

  isFilterOpen: boolean = false;
  activeFilterCount: number = 0;
  toggleLabel: string = '+ Show Filters';

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if( changes.totalItems ) {
      const _searchHeading = <HTMLElement> document.querySelector('.list-header--heading');
      if ( _searchHeading ) {
        _searchHeading.focus();
      }
    }
  }

  toggleFilters( _element ) {
    _element.preventDefault();

    if (!this.isFilterOpen) {
      this.toggleLabel = '- Hide Filters';

      new TimelineLite()
        .to('.component--filter .list-filter--body', { height: 'auto', paddingTop: 17, duration: 0.3 })
        .to('.component--filter .list-filter--body .list-filter--body__item', { opacity: 1, duration: 0.3 })
        .eventCallback('onComplete', () => {
          this.isFilterOpen = true;
        });
    } else {
      this.toggleLabel = '+ Show Filters';

      new TimelineLite()
        .to('.component--filter .list-filter--body .list-filter--body__item', { opacity: 0, duration: 0.3 })
        .to('.component--filter .list-filter--body', { height: 0, paddingTop: 0, duration: 0.3 })
        .eventCallback('onComplete', () => {
          this.isFilterOpen = false;
        });
    }
  }

  onInputChange(input): void {
    this.searchKey = input;
  }

  resetQuery(): void {
    this.searchKey = '';
    this.searchArticle.emit(this.searchKey);
  }

  onSubmit(): void {
    this.searchArticle.emit(this.searchKey);
  }

  onSelectChange(key, value): void {
    if (key === 'audience') {
      this.chosenAudience = value;
    }

    if (key === 'content_type') {
      this.chosenType = value;
    }
    this.applyFilters();
  }

  onSelectValueChange(): void {
    const _filterButton = document.querySelector('.list-filter--apply') as HTMLInputElement;
    _filterButton.classList.add('is-changed');
  }

  applyFilters(): void {
    this.activeFilterCount = 0;
    if (this.chosenAudience != '') this.activeFilterCount += 1;
    if (this.chosenType != '') this.activeFilterCount += 1;

    this.filterArticle.emit({
      audience: this.chosenAudience,
      content_type: this.chosenType,
    });
  }
}
