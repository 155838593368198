import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-vertical-recommended',
  templateUrl: './vertical-recommended.component.html',
  styleUrls: ['./vertical-recommended.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalRecommendedComponent implements OnInit {

  @Input() cards: any[];
  @Input() heading: string;
  @Input() intro: string;
  @Input() location: boolean;
  @Input() assessment: any;
  @Output() onLoadMoreClick?: EventEmitter<boolean> = new EventEmitter();

  displayedCards: any[];
  isLoadingMore: boolean = false;
  isLoadingFromInit: boolean = false;

  constructor(private dataLayerService: DataLayerService) {

  }

  ngOnInit(): void {
    this.sortCards();
    this.displayedCards = this.cards.slice(0, 6);
    setTimeout(()=> {
      this.onLoadMoreClick.emit(true)
      this.initializeCardLinks();
    },1000);
  }

  ngAfterViewInit(){
    if(this.cards && this.cards.length === 0){
      this.isLoadingFromInit = true;
      this.loadMore();
    }
  }

  // push 'tool' cards to the top of the list of cards
  sortCards() {
    const firstCardType = 'tool';
    this.cards.sort(function(x,y){ return x.type == firstCardType ? -1 : y.type == firstCardType ? 1 : 0; });
  }

  initializeCardLinks(){
    const allCards = document.querySelectorAll('.component-vertical-recommended .recommended .recommended--card-group .card--cta a');
    const self = this;
    allCards.forEach(anchor =>{
      if(!anchor.classList.contains('vertical-analytics')){
          anchor.addEventListener('click', function(ev){
            ev.stopPropagation();
            self.trackEvent(this);
          })
      }
      anchor.classList.add('vertical-analytics');
    })
  }


  trackEvent( el: HTMLAnchorElement ) {
    const _clickUrl =el.href || el.getAttribute('href');

    this.dataLayerService.push({
      'event': 'recirc',
      'url': _clickUrl,
      'componentType': 'content grid'
     },false);
  }

  loadMore(): void {
    this.isLoadingMore = true;

    setTimeout(() => {
      const currentLen = this.displayedCards.length;
      const addCards = this.cards.slice(this.displayedCards.length, this.displayedCards.length + 6);

      this.displayedCards = [...this.displayedCards, ...addCards];
      const newLen = this.displayedCards.length;
      this.isLoadingMore = false;

      if (currentLen !== newLen && !this.isLoadingFromInit) {
        setTimeout(() => {
          const cards = Array.from(document.querySelectorAll('.recommended--card-group'));
          const targetCard = cards[currentLen];

          this.onLoadMoreClick.emit(true);
          this.initializeCardLinks();
          if (targetCard) {
            const interactive: any = targetCard.querySelector('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');

            if (interactive) {
              interactive.focus();
            }
          }
        }, 500);
      }else{
        this.onLoadMoreClick.emit(true);
        this.initializeCardLinks();
      }
      if(this.isLoadingFromInit) this.isLoadingFromInit = false;
    }, 1500);
  }
}
