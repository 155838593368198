import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { AssessmentService } from '@services/planner/assessment.service';
import { FallsRiskService } from '@services/planner/falls-risk.service';
import { UserService } from '@services/planner/user.service';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { UtilityService } from '@services/planner/utility.service';
import { take } from 'rxjs/operators';
import { UserPromptService } from '@services/planner/user-prompt.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { Observable } from 'rxjs';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { SeoService } from '@services/seo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataLayerService } from '@services/data-layer.service';
import { GlobalService } from '@services/global.service';
@Component({
  selector: 'ncoa-falls-risk-summary',
  templateUrl: './falls-risk.component.html',
  styleUrls: ['./falls-risk.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FallsRiskComponent implements OnInit {
  category: any;
  prevention: any;
  recommendations: any;
  resources: any;
  screenningDate: any

  showModal: boolean = false;
  modalContent: any;
  categoryCode: string;

  isLoading = false;
  isLoggedIn = false;
  fullData: any = null;
  bannerSubText: any;

  language$: Observable<string> = this.toolsConfig.language$;
  selectedLanguage = 'en';
  languageOptions = [];
  baseLanguageOptions = {
    en : [
      {
        value: 'en',
        label: 'English'
      },
      {
        value: 'es',
        label: 'Spanish'
      }
    ],
    es: [
      {
        value: 'en',
        label: 'Inglés'
      },
      {
        value: 'es',
        label: 'Español'
      }
    ]
  };

  @Input() data: any = null;
  @Input() fullAssessmentData: any;

  recomm: ElementRef;

  @ViewChild('recomm') set content(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.recomm = content;

      if (this.recomm.nativeElement.getElementsByClassName("print-link").length) {
        let linkRef = this.recomm.nativeElement.getElementsByClassName("print-link").item(0);
        linkRef.addEventListener('click', this.onClickReportLink.bind(this));
      }
      if (this.recomm.nativeElement.getElementsByClassName("save-link").length) {
        let linkRef = this.recomm.nativeElement.getElementsByClassName("save-link").item(0);
        linkRef.addEventListener('click', this.clickSave.bind(this));
      }
    }
  }

  constructor(
    private fallsService: FallsRiskService,
    private assessment: AssessmentService,
    private userService: UserService,
    private kontent: KontentDeliveryService,
    private utility: UtilityService,
    private modalService: UserPromptService,
    private cas: CustomerActivityService,
    private toolsConfig: ToolsPrimaryConfigService,
    private seoService: SeoService,
    private router: Router,
    private dataLayerService: DataLayerService,
    private route: ActivatedRoute,
    private globalService: GlobalService,
  ) { }

  ngOnInit(): void {
    this.fallsService.getData().then((data) => {
      this.fullData = data;
      this.data = data[`${this.toolsConfig.language ?? 'en'}`]; // default
      if(this.data.bannerSubText){
        this.bannerSubText = this.data.bannerSubText
      }
      this.categoryCode = this.data.category.categoryCode;
    });
    this.selectedLanguage = localStorage.getItem('language') || 'en';

    this.language$.subscribe((lang: string) => {
      if (lang) this.selectedLanguage = lang;
      if (this.fullData) this.data = this.fullData[`${this.selectedLanguage}`];
      this.cas.updateActivity({ 'fallsLanguage': this.selectedLanguage || 'en' }).subscribe(() => { });
      this.seoService.extractAndRender(this.fullAssessmentData[`${this.selectedLanguage}`].assessmentData, {});
      this.languageOptions = this.baseLanguageOptions[`${this.selectedLanguage}`];
      if(this?.data?.bannerSubText){
        this.bannerSubText = this.data.bannerSubText
      }
    });

    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    } );
    this.globalService.loadFooter(true);
    localStorage.setItem('lastPage', window.location.href);



  }

  onClickReportLink(event) {
    this.generateReport(this.data.category.categoryName);
  }

  sendEmailAction(emailAddress: string) {
    this.emailReport(this.data.category.categoryName, emailAddress);
  }

  toggleModal(event) {
    this.modalContent = event;
    this.showModal = !this.showModal;
  }

  async generateReport(category: string) {
    if (this.isLoading) return;
    this.isLoading = true;
    this.cas.announceEvent('awpFallsReportDownload', { fallsCategory: category }, true, window.location).subscribe(() => { }, err => { });
    this.assessment.generateFallsReport({ templateData: this.data.report, category }).subscribe(data => {
      this.isLoading = false;
      let link = document.createElement('a');
      link.download = `${this.data.report.reportFileName}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + data;
      link.click();
    });
  }

  async emailReport(category: string, emailAddress: string) {
    if (this.isLoading) return;
    this.isLoading = true;
    this.cas.announceEvent('awpFallsReportEmail', { emailAddress }, true, window.location).subscribe(() => { }, err => { });
    this.assessment.generateFallsReport({ templateData: this.data.report, category, emailData: this.data.emailTemplate, emailAddress }).subscribe(data => {
      this.isLoading = false;
    });
  }

  clickSave(): void {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.kontent.getItem(null, {
          'system.type': 'awa_benefits_tool___card__tool_',
          'elements.tool[any]': this.data.breakdownHeader.codename,
        }).then((response) => {
          if (response.items.length > 0) {
            this.utility.getSavedContent().then((savedContent) => {
              let newSavedContent;
              let isSaved;

              const found = savedContent.find((content) => content.ContentId === this.data.breakdownHeader.id);

              if (found) {
                // un-save tool
                newSavedContent = savedContent.filter((content) => content.ContentId !== this.data.breakdownHeader.id);

                // un-save tool cards
                response.items.forEach((item) => {
                  newSavedContent = newSavedContent.filter((content) => content.ContentId !== item.system.id);
                });

                isSaved = false;
              } else {
                // save tool
                newSavedContent = savedContent.concat({ ContentId: this.data.breakdownHeader.id });

                // save tool cards
                response.items.forEach((item) => {
                  newSavedContent = newSavedContent.concat({ ContentId: item.system.id });
                });

                isSaved = true;
              }
              // fire save content update
              this.utility.updateSavedContent(newSavedContent).then(() => {
                this.data.breakdownHeader.isSaved = isSaved;
              });
            });
          }
        });
      } else {
        this.modalService.isModalOpen(true);
      }
    });
  }

  logOut(){

    this.userService.logout();
    this.clickStartOver();
  }

  navToSignIn() {
    this.router.navigate(['/sign-in']).then(() => {
      window.location.reload();
    });
  }
    navToSignUp() {
      this.router.navigate(['/sign-up']).then(() => {
        window.location.reload();
      });
    }

  async clickStartOver(): Promise<void> {
    // clear query params
    await this.router.navigate([], { queryParams: {} });
    //auth'd {
    this.userService.isLoggedIn()
      .pipe(take(1))
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.editAssessment();
        }
        else {
          //clear data and reload
          const index = this.toolsConfig.customerActivity.basic.completedAssessments.findIndex((assessment) => assessment === 'fallsRiskAssessment');
          if (index > -1) {
            this.userService.logout();
          }
          //reload window
          window.location.reload();
        }
      });


  }

  editAssessment(): void {
    // get query parameters
    let queryParams = this.route.snapshot.queryParams;
    // add the edit parameter
    queryParams = { ...queryParams, edit: 'true' };
    // navigate
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' }).then(() => {
      window.location.reload();
    });
    return;
  }

 

  clickPrint(): void {
    this.dataLayerService.push({
      'event': 'retirementResults',
      'componentType': 'header click',
      'clickText': 'Print this page',
      'pageType': 'income-breakdown-page'
    }, false);
    const considerationToggle: HTMLDivElement = document.querySelector('.consideration--toggle');
    if (considerationToggle) {
      const childredToogle: HTMLAnchorElement = considerationToggle.querySelector('a');
      if (childredToogle.innerText === 'Expand') {
        childredToogle.click();
      }
    }
    const comparison: any = document.querySelectorAll('.plan--detail__breakdown');
    comparison.forEach(element => {
      element.classList.add('is-open-print');
    });
    window.print();
  }


  onLanguageToggle(event: any) {
    const language = event.currentTarget.value;
    this.toolsConfig.setLanguage(language);

    this.cas.updateActivity({'fallsLanguage':language || 'en'}).subscribe(() => {});
    this.languageOptions = this.baseLanguageOptions[`${language}`];
  }
}
