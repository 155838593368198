<age-well-planner *ngIf="data">
  <div class="account-profile">
    <ncoa-account-profile-header
      *ngIf="data.profileHeader"
      [backCTA]="data.profileHeader.backCTA"
      [image]="data.profileHeader.image"
      [headline]="data.profileHeader.headline"
      [subhead]="data.profileHeader.subhead"
      [accountType]="data.profileHeader.accountType"
      [accountTypeUpdateCopy]="data.profileHeader.accountTypeUpdateCopy"
      [headerQuestion]="data.profileHeader.headerQuestion"
      [menu]="data.profileHeader.menu"
      [assessmentData]="data.assessmentData"
      (onNavigationClick)="onNavigationClick($event)"
    ></ncoa-account-profile-header>

    <!-- profile panel -->
    <ncoa-profile-panel
      *ngIf="data.profilePanel"
      [questions]="data.profilePanel.questions"
      [panelID]="data.profilePanel.panelID"
      [saveCopy]="'Save'"
      [headline]="data.profilePanel.headline"
      [assessmentData]="data.assessmentData"
    ></ncoa-profile-panel>
  </div>
</age-well-planner>
