<div role="dialog" class="course--modal" aria-hidden="true">
  <div class="course--modal__wrapper">

    <div class="course--modal__container">
      <div class="courses">

        <div class="courses--wrapper" *ngFor="let course of coursesData">
          <h3 class="courses--heading">
            <span (click)="openCourse( $event ); $event.stopPropagation();">{{ course.AMP_Program__r.Name }} </span>
            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.9248 7.70909C8.70976 7.90303 8.44094 8 8.17214 8C7.90334 8 7.63455 7.90303 7.41951 7.70909L0.699513 1.67273C0.269433 1.28485 0.269433 0.678788 0.699513 0.290909C1.12959 -0.0969697 1.8016 -0.0969697 2.23168 0.290909L8.17214 5.82562L14.1395 0.315152C14.5696 -0.0727268 15.2416 -0.0727268 15.6719 0.315152C16.0751 0.678788 16.0751 1.28485 15.6448 1.67273L8.9248 7.70909Z" fill="white"/>
            </svg>              
          </h3>
          <div class="courses--content">
            <div class="courses--content__item courses--content__label"><p>Date:</p></div>
            <div class="courses--content__item courses--content__value"><p>{{ course.Start_Date__c | date: 'MM/dd/yyyy' }} - {{ course.End_Date__c | date: 'MM/dd/yyyy' }}</p></div>

            <div class="courses--content__item courses--content__label"><p>Time:</p></div>
            <div class="courses--content__item courses--content__value"><p>{{ getDate( course.Start_Date__c + 'T' + course.Start_Time__c ) | date: 'h:mm a' }} - {{ getDate( course.Start_Date__c + 'T' + course.End_Time__c ) | date: 'h:mm a' }}</p></div>

            <div class="courses--content__item courses--content__label"><p>Call to Register:</p></div>
            <div class="courses--content__item courses--content__value"><p>{{ course.Call_To_Register__c }}</p></div>

            <div class="courses--content__item courses--content__label"><p>Email to Register:</p></div>
            <div class="courses--content__item courses--content__value"><p><a href="{{'mailto:' + course.Email_to_Register__c }}"><span>{{ course.Email_to_Register__c }}</span></a></p></div>

            <div class="courses--content__item courses--content__label"><p>Special Considerations:</p></div>
            <div class="courses--content__item courses--content__value"><p>{{ course.Special_Considerations__c }}</p></div>

            <div class="courses--content__item courses--content__label"><p>Notes:</p></div>
            <div class="courses--content__item courses--content__value"><p>{{ course.Notes__c }}</p></div>
          </div>
        </div>
      </div>
    </div>

    <button class="course--modal__close" (click)="closeModal()">
      <span>Close</span>
    </button>

  </div>

  <div class="course--modal__overlay"></div>
</div>