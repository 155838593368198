<age-well-planner>
  <div class="trusted-expert-page">

    <article-detail-header [showCTA]='articleHeaderComponent.showCTA' [eyebrow]='articleHeaderComponent.eyebrow'
      [title]='articleHeaderComponent.title' [ctaArticleText]='articleHeaderComponent.ctaArticleText'>
    </article-detail-header>
    <ncoa-author-byline-planner *ngIf='authorByline' [authors]="authorByline"></ncoa-author-byline-planner>
    <div class="article-body-page-container" id = 'main-content'>
      <ncoa-article-body *ngIf='articleBody' [content]='articleBody.content'
      [requiredFieldError]="articleBody.requiredFieldError" [url]="articleBody.url">

        <app-takeaways *ngIf='takeAwaysComponent' [heading]='takeAwaysComponent.headings'
          [takeaways]='takeAwaysComponent.takeaways'>
        </app-takeaways>

      </ncoa-article-body>

    </div>
    <app-author-byline *ngIf='otherContributors' [authors]='otherContributors' variant='others'></app-author-byline>

    <app-partners *ngIf='partnersComponent' [heading]='partnersComponent.heading'
      [partners]='partnersComponent.partners'></app-partners>

    <ncoa-article-share-mobile [url]='articleBody.url'></ncoa-article-share-mobile>

    <ncoa-article-feedback *ngIf='wasThisHelpful'></ncoa-article-feedback>


    <ncoa-lead-gen [heading]="leadgen.heading" [eyebrow]="leadgen.eyebrow" [intro]="leadgen.intro" [cta]="leadgen.cta"
      [photo]="leadgen.photo" [icon]="leadgen.icon">
    </ncoa-lead-gen>
    <div class="space-between">

    </div>

    <ncoa-three-up [heading]='threeUpComponent.heading' [moreLink]='threeUpComponent.moreLink'
      [cards]='threeUpComponent.cards'></ncoa-three-up>

  </div>

  <div class="save-bookmark--desktop" #saveBookmarkDesktop>
    <h3>Save</h3>
    <aside>
      <ul class="save-bookmark" (click)='onSaveContentClick()'>
        <li>
          <a aria-label="Save Content" target="_blank">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="19" stroke="#0B4A5D" stroke-width="2" />
              <path
                d="M14 14V25.9425C14 26.777 14.9617 27.2444 15.6178 26.7289L20 23.2857L24.3822 26.7289C25.0383 27.2444 26 26.777 26 25.9425V14C26 13.4477 25.5523 13 25 13H15C14.4477 13 14 13.4477 14 14Z"
                stroke="#0B4A5D" stroke-width="2" />
            </svg>
          </a>
        </li>
      </ul>
    </aside>
  </div>

  <div #saveBookmarkMobile>
    <div class="save-bookmark--mobile">
      <aside class='save-bookmark-wrapper'>
        <ul class="save-bookmark" (click)='onSaveContentClick()'>
          <li>
            <a aria-label="Save Content" target="_blank">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="19" stroke="#0B4A5D" stroke-width="2" />
                <path
                  d="M14 14V25.9425C14 26.777 14.9617 27.2444 15.6178 26.7289L20 23.2857L24.3822 26.7289C25.0383 27.2444 26 26.777 26 25.9425V14C26 13.4477 25.5523 13 25 13H15C14.4477 13 14 13.4477 14 14Z"
                  stroke="#0B4A5D" stroke-width="2" />
              </svg>
            </a>
          </li>
        </ul>
      </aside>
      <span class="save-label">Save</span>
    </div>
  </div>

</age-well-planner>
