<div class="component component--list-header">
  <div class="wrapper">
    <div class="list-header">
      <h3 class="list-header--heading" tabindex="-1" [attr.aria-label]="'There are ' + totalItems + ( totalItems > 1 ? ' Results ' : ' Result ' ) + ' for your Keyword'">{{ totalItems }} Search Result{{ totalItems != 1 ? 's' : '' }} for:</h3>
      <form class="list-header--form" (ngSubmit)="onSubmit()">
        <div class="search-field">
          <input
            type="text"
            name="q"
            aria-label="Type your Keyword here"
            placeholder="ex. Prescription Assistance"
            (input)="onInputChange($event.target.value)"
            [(ngModel)]="searchKey"
          />
          <button class="submit-btn" type="submit" value="Submit">
            <i></i>
            <span>Search</span>
          </button>
        </div>
        <div class="reset">
          <input
            class="reset-btn"
            type="reset"
            value="Clear Field"
            (click)="resetQuery()"
          >
        </div>
      </form>
    </div>
  </div>
</div>

<div class="component component--filter">
  <div class="wrapper">
    <div class="list-filter">
      <div class="list-filter--header">
        <div><p>Filter by:</p></div>
        <div>
          <a
            href="#"
            aria-role="button"
            class="js-filter-trigger"
            aria-label="Show Filters"
            (click)="toggleFilters( $event )"
          >
            {{ toggleLabel }} <span *ngIf="activeFilterCount > 0 && !isFilterOpen">({{ activeFilterCount }})</span>
          </a>
        </div>
      </div>

      <div class="list-filter--body">
        <div class="list-filter--body__item">
          <div><label>Audience</label></div>
          <div>
            <select
              [(ngModel)]="chosenAudience"
              class="list-filter--selection"
              name="audience"
              aria-label="Filter by Audience"
              (change)="onSelectValueChange()"
            >
              <option value="all">For All Audiences</option>
              <option *ngFor="let option of audienceOptions" [value]="option.codename">For {{ option.name }}</option>
            </select>
          </div>
        </div>

        <div class="list-filter--body__item">
          <div><label>Type</label></div>
          <div>
            <select
              [(ngModel)]="chosenType"
              class="list-filter--selection"
              name="type"
              aria-label="Filter by Type"
              (change)="onSelectValueChange()"
            >
              <option value="all">All Content</option>
              <option value="article">Article</option>
              <option value="author">Author</option>
              <option value="event">Event</option>
              <option value="topic">Topic</option>
            </select>
          </div>
        </div>

        <div class="list-filter--body__item">
          <div>
            <button
              class="list-filter--apply"
              (click)="applyFilters()"
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>

      <div class="list-filter--content">
        <div class="list-filter--content__item">
          <div><label id="audience-label">Filter by Audience</label></div>
          <div>
            <select
              [(ngModel)]="chosenAudience"
              class="list-filter--selection"
              name="audience"
              (change)="onSelectChange('audience', $event.target.value)"
              aria-labelledby="audience-label"
            >
              <option value="all">For All Audiences</option>
              <option *ngFor="let option of audienceOptions" [value]="option.codename">For {{ option.name }}</option>
            </select>
          </div>
        </div>

        <div class="list-filter--content__item">
          <div><label id="type-label">Filter by Type</label></div>
          <div>
            <select
              [(ngModel)]="chosenType"
              (change)="onSelectChange('content_type', $event.target.value)"
              class="list-filter--selection"
              name="type"
              aria-labelledby="type-label"
            >
              <option value="all">All Content</option>
              <option value="article">Article</option>
              <option value="author">Author</option>
              <option value="event">Event</option>
              <option value="topic">Topic</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
