import { Injectable } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist/es5/build/pdf';

(window as any).pdfjsLib = pdfjsLib;

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor() { }

  processPDFs() {
    const pdfContainers = Array.from(document.querySelectorAll('.ncoa-pdf-container'));

    pdfContainers.forEach(container => this.processPDFContainer(container))
  }

  processPDFContainer(container) {
    const file = container.dataset.file;
    const iframeMarkup = this.buildPDFViewerMarkup(file);

    container.insertAdjacentHTML('afterEnd', iframeMarkup);
    container.parentElement.removeChild(container);
  }

  buildPDFViewerMarkup(file) {
    return `
      <section class="component pdf-viewer">
        <div class="pdf-viewer__container">
          <div class="pdf-viewer__wrapper">
            <iframe src="/assets/viewer/web/viewer.html?url=${file}" title="PDF File Preview"></iframe>
          </div>
        </div>
      </section>
    `
  }
}
