<a class="back-cta" (click)="goBack()">
  <svg viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.86842 1.04722L0.315788 7.75062C0.105263 7.96513 0 8.23326 0 8.5014C0 8.76954 0.105263 9.03767 0.315788 9.25218L6.86842 15.9556C7.28947 16.3846 7.94737 16.3846 8.36842 15.9556C8.78947 15.5266 8.78947 14.8562 8.36842 14.4272L3.57895 9.57395L18.9474 9.57395C19.5263 9.57395 20 9.0913 20 8.5014C20 7.9115 19.5263 7.42886 18.9474 7.42886L3.57895 7.42886L8.34211 2.54878C8.76316 2.11977 8.76316 1.44943 8.34211 1.02014C7.94737 0.617936 7.28947 0.617936 6.86842 1.04722Z" fill="#0B4A5D"/>
  </svg>
    <div>
      <span #ref>
        <ng-content></ng-content>
      </span>
      <ng-container *ngIf="!ref.hasChildNodes()">
        <span>{{ cta }}</span>
      </ng-container>
    </div>
  </a>