import { Component, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { VerticalAssessmentComponent } from '@components/planner/vertical-assessment/vertical-assessment.component';
import { VerticalService } from '@services/planner/vertical.service';


@Component({
  selector: 'vertical-page',
  templateUrl: './vertical-page.component.html',
  styleUrls: ['./vertical-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalPage {
  data: any = null;

  @ViewChild("assessment", { static: false }) set vertical(component: VerticalAssessmentComponent) {
    //get edit query param
    const urlParams = new URLSearchParams(window.location.search);
    const edit = urlParams.get('edit');
    localStorage.setItem('lastPage', window.location.href);
    
    if (component?.isComplete() && !edit) {
      this.router.navigate([this.data.assessment.viewPlanLink]).then(() => {
        window.location.reload()
      });
    }
  }

  @HostListener('window:pageshow', ["$event"])
  reload(event) {
    if(event.persisted){
      window.location.reload()
    }
  }

  constructor(
    private verticalService: VerticalService,
    private router: Router,
  ) {
    this.verticalService.getData('vertical')
      .then((data) => {
        this.data = data;
        if (this.data?.assessment?.assessmentCode === 'AWPworkforce') {
          this.loadIBMUniversalPixel();
        }
      });

  }

  loadIBMUniversalPixel() {
    let node = document.createElement('script');
      node.text = `
        !function(s, a, e, v, n, t, z) {
          if (s.saq) return;
          n = s.saq = function() {
              n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!s._saq) s._saq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '1.0';
          n.queue = [];
          t = a.createElement(e);
          t.async = !0;
          t.src = v;
          z = a.getElementsByTagName(e)[0];
          z.parentNode.insertBefore(t, z)
        }(window, document, 'script', 'https://tags.srv.stackadapt.com/events.js');
        saq('ts', 'KR5YuuHCYuC-n-B-Nmv-HQ'); 
      `;
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
  }


}
