import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  testArticleURL: string = 'http://ncoa-fe-www.s3-website-us-east-1.amazonaws.com/article/how_financial_counseling_can_help_you';

  constructor(
    @Inject(DOCUMENT) private document: any,
    private route: Router
  ) {}

  getFullURL() {
    return this.document.URL;
  }

  getPathName() {
    return this.document.location.pathname;
  }

  buildArticleURL(url, audiences = null, categories = null) {
    return `/article/${url}`;
  }

  buildPageURL(url, audiences = null, categories = null) {
    return `/page/${url}`;
  }

  buildCategoryURL(url, audiences = null, categories = null) {
    return `/${url}`;
  }

  buildAuthorLink(codename) {
    return `/author/${codename}`;
  }

  buildShareURLs() {
    const currentURL = location.href;

    return {
      facebook: this.buildFacebookShareURL(currentURL),
      twitter: this.buildTwitterShareURL(currentURL),
      linkedin: this.buildLinkedinShareURL(currentURL),
      pinterest: this.buildPinterestShareURL(currentURL),
      mailto: this.buildMailtoShareURL(currentURL)
    }
  }

  buildFacebookShareURL(url) {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    // return url;
  }

  buildTwitterShareURL(url) {
    return `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
    // return url;
  }

  buildLinkedinShareURL(url) {
    return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`;
    // return url;
  }

  buildPinterestShareURL(url) {
    return `http://pinterest.com/pin/create/link/?url=${encodeURIComponent(url)}`;
    // return url;
  }

  buildMailtoShareURL(url) {
    return `mailto:?body=${encodeURIComponent(url)}`;
  }

}
