import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '@services/global.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserService } from '@services/planner/user.service';
import { UtilityService } from '@services/planner/utility.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPassword implements OnInit {

  pageData: {
    navCTA: {
      src: string;
      alt: string;
      cta: {
        url: string;
        text: string;
      };
    };
    backCTA: {
      url: string;
      text: string;
    };
    contactCTA: {
      url: string;
      text: string;
    };
    signInCTA: {
      url: string;
      text: string;
    };
    eyebrow: string;
    title: string;
    desc: string;
  } = null;

  email: {
    value: string;
    status: {
      code: string;
      message: string;
    };
  } = {
    value: '',
    status: {
      code: '',
      message: '',
    },
  };

  password: {
    value: string;
    status: {
      code: string;
      message: string;
    };
  } = {
    value: '',
    status: {
      code: '',
      message: '',
    },
  };

  confirmPassword: {
    value: string;
    status: {
      code: string;
      message: string;
    };
  } = {
    value: '',
    status: {
      code: '',
      message: '',
    },
  };

  userID: string = null;
  confirmationCode: string = null;
  linkSent: boolean = false;
  showLoadingModal: boolean = false;
  stageCount: number = 1;

  constructor(
    private utilityService: UtilityService,
    private userService: UserService,
    private route: ActivatedRoute,
    private toolsConfig: ToolsPrimaryConfigService,
    private globalService: GlobalService, 
  ) {
    this.pageData = {
      navCTA: this.toolsConfig.currentConfig.navData.mainLogo,
      backCTA: {
        url: '/',
        text: 'Exit',
      },
      contactCTA: {
        url:  this.toolsConfig.rawConfig.elements?.contact_us_url?.value,
        text: this.toolsConfig.rawConfig.elements.contact_us_link_text_fd6de3d.value,
      },
      signInCTA: {
        url: this.toolsConfig.routes.find(({ type }) => type === 'sign-in').route,
        text: 'Back to Sign In',
      },
      eyebrow: this.toolsConfig.rawConfig.elements.fp_eyebrow.value,
      title: this.toolsConfig.rawConfig.elements.heading_8c3f753.value,
      desc: this.toolsConfig.rawConfig.elements.body_bea63d1.value,
    };
  }

  ngOnInit(): void {
    this.userService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        window.location.href = this.toolsConfig.rootURL;
      }
    });

    this.userID = this.route.snapshot.queryParamMap.get('user_id');
    this.confirmationCode = this.route.snapshot.queryParamMap.get('confirmation_code');

    if (this.userID && this.confirmationCode) {
      this.stageCount = 2;
    }

  this.globalService.loadFooter(true);
  }

  goBack(e){
    if(!document.referrer){
      const lastUrl = window.sessionStorage.getItem("last_url");
      const lastUrlIndex = window.sessionStorage.getItem("last_url_index");
      if((history.length - 1) === parseInt(lastUrlIndex)){
        window.location.href = lastUrl;
      }else{
        window.location.href = this.pageData.backCTA.url;
      }
    }else{
      if (document.referrer.indexOf(window.location.host) !== -1){
        e.preventDefault();
        history.back();
      }else{
        window.location.href = this.pageData.backCTA.url;
      }
    }
  }

  onSubmit(e): void {
    e.preventDefault();

    if (this.stageCount === 1) {
      if (this.email.value.trim().length === 0 ||
         (this.email.value.trim().length > 0 &&
         !this.utilityService.isEmailValid(this.email.value))) {
        this.linkSent = false;
        this.email = {
          ...this.email,
          status: {
            code: 'error',
            message: 'Please enter a valid email address',
          },
        };
      } else if (this.email.value.trim().length > 0 && this.utilityService.isEmailValid(this.email.value)) {
        this.email = {
          ...this.email,
          status: {
            code: '',
            message: '',
          },
        };

        this.showLoadingModal = true;

        this.userService.forgotPassword(this.email.value).subscribe(() => {
          this.showLoadingModal = false;
          this.email = {
            ...this.email,
            status: {
              code: 'idle',
              message: 'If the supplied email address is valid, you should receive an email shortly with instructions on resetting your password. Please contact community@ncoa.org if further assistance is required.',
            }
          };
        }, (error) => {
          this.showLoadingModal = false;

          if (error.status === 404) {
            this.email = {
              ...this.email,
              status: {
                code: 'idle',
                message: 'If the supplied email address is valid, you should receive an email shortly with instructions on resetting your password. Please contact community@ncoa.org if further assistance is required.',
              },
            };
          }
        })
      }
    } else {
      if (this.password.value.trim().length === 0 ||
         (this.password.value.trim().length > 0 &&
         !this.utilityService.isPasswordValid(this.password.value))) {
        this.password = {
          ...this.password,
          status: {
            code: 'error',
            message: 'Password must have at least 8 characters, one uppercase letter, one lowercase latter, one number, and one special character (@$!%*?&)',
          },
        };
      } else if (this.password.value.trim().length > 0 && this.utilityService.isPasswordValid(this.password.value)) {
        this.password = {
          ...this.password,
          status: {
            code: '',
            message: '',
          },
        };
      }

      this.confirmPassword = {
        ...this.confirmPassword,
        status: {
          code: this.confirmPassword.value !== this.password.value ? 'error' : '',
          message: this.confirmPassword.value !== this.password.value ? 'Password didn\'t match' : '',
        },
      };

      if (this.password.status.code !== 'error' && this.confirmPassword.status.code !== 'error') {
        this.showLoadingModal = true;

        this.userService.resetPassword(this.userID, this.confirmationCode, this.password.value)
          .subscribe(() => {
            this.showLoadingModal = false;
            window.location.href = this.toolsConfig.routes.find(({ type }) => type === 'sign-in').route;
          }, (error) => {
            this.showLoadingModal = false;
            console.log('has error');
            console.log(error);
          })
      }
    }
  }
}
