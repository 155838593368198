<div class="header" role="banner">
  <div class="wrapper">
    <div class="header-content" *ngIf="data">
      <div class="header-brand">
        <img *ngIf="data.logo && data.logo?.url" [src]="data.logo?.url" [alt]="data.logo?.alt" class="logo">
      </div>
      <div class="header-tagline">{{ data.page_header_phrase }}</div>
    </div>
  </div>
</div>

<section class="page-header">
  <div class="page-header__container">
    <svg class="half-circle" width="229" height="458" viewBox="0 0 229 458" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M-8.00793e-05 458C126.473 458 229 355.475 229 229.001C229 102.527 126.474 2.21133e-05 0 0" fill="#F3F6F7" />
    </svg>
    <div class="page-header__wrapper">
      <svg class="ellipse" width="164" height="164" viewBox="0 0 164 164" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="82" cy="82" r="82" fill="#E1F4EB" />
      </svg>
      <div class="texts">
        <p class="page-headline">{{ data?.page_header_title }}</p>
        <div #headerBodyText class="page-description"></div>
      </div>
      <div class="image" *ngIf="data && data.hero_image && data.hero_image?.url; else elseBlock">
        <img src="{{ data.hero_image.url }}" alt="{{ data.hero_image.name }}" title="{{ data.hero_image.name }}">
      </div>
      <ng-template #elseBlock class="image">
        <div class="image default">
        </div>
      </ng-template>
    </div>
  </div>
</section>

<!-- <div class="section-divider">
  <svg class="circle" width="100" height="100" viewBox="-50 -50 100 100" fill="white"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="0" cy="0" r="50" />
  </svg>
  <span class="down-arrow"></span>
  <span class="down-arrow"></span>
</div> -->

<section class="contact-form" *ngIf="data">
  <div class="form-header">{{ data.form_title_text }}</div>
  <div class="form-description">{{ data.form_instructions }}</div>
  <div class="form-help">{{ data.form_required_text }}</div>
  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <div class="field-container">
      <label for="firstName">{{ data.first_name_label }}*</label>
      <input type="text" value="" aria-required="true" name="firstName" id="firstName" formControlName="firstName" required>
      <div *ngIf="firstName.errors && firstName.touched">
        <div class="error" *ngIf="firstName.errors.required">{{ data.first_name_required_error }}</div>
      </div>
    </div>
    <div class="field-container">
      <label for="lastName">{{ data.last_name_label }}*</label>
      <input type="text" aria-required="true" name="lastName" id="lastName" formControlName="lastName"  required>
      <div *ngIf="lastName.errors && lastName.touched">
        <div class="error" *ngIf="lastName.errors.required">{{ data.last_name_required_error }}</div>
      </div>
    </div>
    <div class="field-container">
      <label for="email">{{ data.email_label }}*</label>
      <input type="email" aria-required="true" name="email" id="email" formControlName="email"  required>
      <div *ngIf="email.errors && email.touched">
        <div class="error" *ngIf="email.errors.required">{{ data.email_required_error }}</div>
        <div class="error" *ngIf="email.errors.email">{{ data.email_invalid_error }}</div>
      </div>
    </div>
    <div class="field-container">
      <div class="field-label-container">
        <label for="zipCode">{{ data.zip_code_label }}*</label>
        <span class="tooltip--wrapper" [attr.data-title]='data.form_tooltip_label' (click)="openTooltip( $event )">
          <span class="tooltip-label">{{ data.form_tooltip_label }}</span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="9" stroke="#0B4A5D" stroke-width="2"/>
            <path d="M10.8491 12.1873H8.97768C9.01578 11.5986 9.19197 11.1174 9.50626 10.7436C9.83007 10.3699 10.1634 10.0382 10.5063 9.74849C10.8301 9.46817 11.111 9.18784 11.3491 8.90752C11.5967 8.61785 11.7205 8.27212 11.7205 7.87032C11.7205 7.44049 11.5777 7.10411 11.292 6.86116C11.0063 6.60887 10.6205 6.48272 10.1348 6.48272C9.47768 6.48272 8.97768 6.71165 8.63483 7.16952C8.29197 7.62738 8.15387 8.24876 8.22054 9.03367L6.40625 8.12262C6.41577 7.63672 6.51577 7.19288 6.70625 6.79108C6.89673 6.37994 7.15387 6.02953 7.47768 5.73987C7.81101 5.44085 8.20149 5.21192 8.64911 5.05307C9.10625 4.88488 9.60149 4.80078 10.1348 4.80078C10.6396 4.80078 11.1063 4.87553 11.5348 5.02504C11.9634 5.1652 12.3301 5.3661 12.6348 5.62774C12.9396 5.88937 13.1777 6.20707 13.3491 6.58084C13.5205 6.9546 13.6063 7.36107 13.6063 7.80024C13.6063 8.1927 13.5539 8.5431 13.4491 8.85146C13.3444 9.15981 13.2063 9.43546 13.0348 9.67841C12.8634 9.91201 12.6729 10.1269 12.4634 10.3232C12.2634 10.5194 12.0634 10.7016 11.8634 10.8698C11.6063 11.0847 11.3777 11.2949 11.1777 11.5005C10.9872 11.7061 10.8777 11.935 10.8491 12.1873ZM11.0205 15.2008H8.77768V13.1124H11.0205V15.2008Z" fill="#0B4A5D"/>
          </svg>
          <span class="tooltip" *ngIf="data.zip_code_tooltip_text">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="closeTooltip( $event )">
              <path d="M14.2446 2.26607C14.6618 1.8489 14.6618 1.17253 14.2446 0.755357C13.8275 0.338185 13.1511 0.338185 12.7339 0.755357L7.5 5.98929L2.26607 0.755357C1.8489 0.338185 1.17253 0.338185 0.755357 0.755357C0.338185 1.17253 0.338185 1.8489 0.755357 2.26607L5.98929 7.5L0.755357 12.7339C0.338185 13.1511 0.338185 13.8275 0.755357 14.2446C1.17253 14.6618 1.8489 14.6618 2.26607 14.2446L7.5 9.01071L12.7339 14.2446C13.1511 14.6618 13.8275 14.6618 14.2446 14.2446C14.6618 13.8275 14.6618 13.1511 14.2446 12.7339L9.01071 7.5L14.2446 2.26607Z" fill="#0B4A5D"/>
            </svg>
            <span [innerHTML]="data.zip_code_tooltip_text"></span>
          </span>
        </span>
      </div>
      <input type="text" aria-required="true" name="zipCode" id="zipCode" formControlName="zipCode"  mask="00000" [validation]="true" required>
      <div *ngIf="(zipCode.errors && zipCode.touched) || errorMessages.zipCode">
        <div class="error" *ngIf="zipCode?.errors?.required">{{ data.zip_code_required_error }}</div>
        <div class="error" *ngIf="zipCode?.errors?.mask">{{ data.zip_code_invalid_error }}</div>
        <div class="error" *ngIf="!zipCode?.errors?.mask && !zipCode?.errors?.required && errorMessages.zipCode">{{ errorMessages.zipCode }}</div>
      </div>
    </div>
    <div class="field-container">
      <label for="phoneNumber">{{ data.phone_number_label }} {{ data.form_optional_label }}</label>
      <input type="tel" name="phoneNumber" id="phoneNumber" formControlName="phoneNumber" placeholder="555-555-5555" mask="000-000-0000" [dropSpecialCharacters]="true" [validation]="true">
      <div *ngIf="phoneNumber.errors && phoneNumber.touched">
        <div class="error" *ngIf="phoneNumber.errors.mask">{{ data.phone_number_invalid_error }}</div>
      </div>
    </div>
    <div class="field-container">
      <label for="ageRange">{{ data.age_range_label }}*</label>
      <select  aria-required="true"  name="ageRange" id="ageRange" formControlName="ageRange" required> 
        <option selected disabled>Select Age Group</option>
        <option *ngFor="let options of data.age_range_options.options" [ngValue]="options.name">{{options.name}}</option>
      </select>
      <div *ngIf="ageRange.errors && ageRange.touched">
        <div class="error" *ngIf="ageRange.errors.required">{{ data.age_range_required_error }}</div>
      </div>
    </div>
    <div class="field-container">
      <label for="annuitySituation">{{ data.annuities_situation_label }}*</label>
      <select name="annuitySituation" id="annuitySituation" formControlName="annuitySituation" required>
        <option selected disabled>Select Scenario</option>
        <option *ngFor="let options of data.annuities_situation_options.options" [ngValue]="options.name">{{options.name}}</option>
      </select>
      <div *ngIf="annuitySituation.errors && annuitySituation.touched">
        <div class="error" *ngIf="annuitySituation.errors.required">{{ data.annuities_situation_required_error }}</div>
      </div>
    </div>
    <div class="submit-container">
      <button type="submit" aria-label="Get Expert Help" [disabled]="disableSubmit">{{ data.form_cta_text }}</button>
    </div>
  </form>
  <div class="disclaimer">{{ data.form_agreement_text }}</div>
  <div class="footer-links">
    <a [href]="data.privacy_policy_link_url" target="_blank"> {{ data.privacy_policy_link_text }} </a> 
    |
    <a (click)="soeModal()"> {{ data.standards_of_excellence_link_text }} </a> 
  </div>
</section>
<ncoa-modal *ngIf="open" [id]="'soe_modal'" [type]="'soe'" [isOpen]="open" [heading]="heading"
  [description]="description" [cta]="cta" [backgroundImage]="backgroundImage" [soeData]="soe_data">
</ncoa-modal>