import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { UtilityService } from '@services/planner/utility.service';
import { LandingPageService } from '@services/landing-page.service';
import { ErrorMessageProcessor } from 'src/app/validators/error-message-processor';
import { debounceTime } from 'rxjs/operators';
import { minAge, maxAge, dateIsValid } from 'src/app/validators/date-validator';
import { zipCodeValidator } from 'src/app/validators/zip-validator';
import { DomSanitizer } from '@angular/platform-browser';
import { UrlService } from '@services/url.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'ncoa-partner-landing',
  templateUrl: './partner-landing.component.html',
  styleUrls: ['./partner-landing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PartnerLandingComponent implements OnInit, AfterViewInit {

  @Input() partnerData: any;

  contactForm: FormGroup;
  errorMessageProcessor: ErrorMessageProcessor;
  errorMessages: any;
  buttonDisabled = false;

  data: any;

  isSideBarOpen: boolean = false;
  isMobileView = false;

  url: {
    facebook: string,
    twitter: string,
    linkedin: string,
    pinterest: string,
    mailto: string
  };

  // header items
  ncoaLogo: any;
  ncoaLogoURL: string;
  partnerLogo: any;
  partnerLogoURL: string;
  headerLinkLabel: string;
  headerLinkURL: string;
  headerCTALabel: string;
  headerCTAURL: string;

  topNavLinks: any[] = [];
  bad = ['1','2'];

  // block 1 items
  block1: {
    title: string,
    copy: string,
    image: any,
    post_sub_copy?: string,
    post_sub_image?: any
  }

  // block 2 items
  block2: {
    copy: string,
    post_sub_copy?: string
  }

  // block 3 items
  block3: {
    copy: string,
    post_sub_copy?: string,
    post_sub_image1: any,
    post_sub_image2: any
  }

  // block 4 items (iframe)
  block4: {
    copy: string
  }

  // block 5 items
  block5: {
    copy: string,
    post_sub_copy?: string
  }

  // footer items
  footerLogo: any;
  footerLogoURL: string;

  bottomNavLinks: any[] = [];
  finePrintLinks: any[] = [];

  partnerCode: string;
  partnerEventRecord: any;

  @ViewChild('script') script: ElementRef;

  contentReady = false;
  postSubmission = false;

  constructor(
    private formBuilder: FormBuilder,
    private cas: CustomerActivityService,
    private utilService: UtilityService,
    private landingPageService: LandingPageService,
    private sanitizer: DomSanitizer,
    private urlService: UrlService,
    private activeRoute: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.url = this.urlService.buildShareURLs();

    const pageComponents = this.partnerData.items[0];
    this.landingPageService.applySeoData(this.partnerData);
    //console.log(pageComponents);

    this.partnerCode = pageComponents.elements.partner_source_code?.value || 'unknown';

    // load header data
    this.ncoaLogo = pageComponents.elements.ncoa_logo.value[0];
    this.ncoaLogoURL = pageComponents.elements.ncoa_logo_url.value;
    this.partnerLogo = pageComponents.elements.partner_logo.value.length ? pageComponents.elements.partner_logo.value[0] : '';
    this.partnerLogoURL = pageComponents.elements.partner_logo_url.value;
    this.headerLinkLabel = pageComponents.elements.about_label.value;
    this.headerLinkURL = pageComponents.elements.about_url.value;
    if (pageComponents.elements.cta_text.value && pageComponents.elements.cta_link.value) {
      this.headerCTALabel = pageComponents.elements.cta_text.value;
      this.headerCTAURL = pageComponents.elements.cta_link.value;
    }
    if (pageComponents.elements.navigation_bar_link_1_label.value && 
      pageComponents.elements.navigation_bar_link_1_url.value) this.topNavLinks.push({label: pageComponents.elements.navigation_bar_link_1_label.value, url: pageComponents.elements.navigation_bar_link_1_url.value});
    if (pageComponents.elements.navigation_bar_link_2_label.value && 
      pageComponents.elements.navigation_bar_link_2_url.value) this.topNavLinks.push({label: pageComponents.elements.navigation_bar_link_2_label.value, url: pageComponents.elements.navigation_bar_link_2_url.value});
    if (pageComponents.elements.navigation_bar_link_3_label.value && 
      pageComponents.elements.navigation_bar_link_3_url.value) this.topNavLinks.push({label: pageComponents.elements.navigation_bar_link_3_label.value, url: pageComponents.elements.navigation_bar_link_3_url.value});
    if (pageComponents.elements.navigation_bar_link_4_label.value && 
      pageComponents.elements.navigation_bar_link_4_url.value) this.topNavLinks.push({label: pageComponents.elements.navigation_bar_link_4_label.value, url: pageComponents.elements.navigation_bar_link_4_url.value});
  
    // load block 1 data
    this.block1 = {
      title: pageComponents.elements.title.value,
      copy: this.sanitizer.bypassSecurityTrustHtml(pageComponents.elements.block1_content.value) as string, // rich text
      image: pageComponents.elements.image.value[0],
      post_sub_copy: this.sanitizer.bypassSecurityTrustHtml(pageComponents.elements.block1_post_submission_content.value) as string ?? '',
      post_sub_image: pageComponents.elements.block1_post_submission_image.value.length ? pageComponents.elements.block1_post_submission_image.value[0] : undefined
    }

    // load block 2 data
    if (pageComponents.elements.block2_content.value) {
      this.block2 = {
        copy: this.sanitizer.bypassSecurityTrustHtml(pageComponents.elements.block2_content.value) as string,
        post_sub_copy: this.sanitizer.bypassSecurityTrustHtml(pageComponents.elements.block2_post_submission_content.value) as string ?? ''
      }
    }

    // load block 3 data
    if (pageComponents.elements.block3_content.value && pageComponents.elements.medicare_lead_form.value) {
      this.createForm();
      this.block3 = {
        copy: this.sanitizer.bypassSecurityTrustHtml(pageComponents.elements.block3_content.value) as string,
        post_sub_copy: this.sanitizer.bypassSecurityTrustHtml(pageComponents.elements.block3_post_submission_content.value) as string ?? '',
        post_sub_image1: pageComponents.elements.block3_post_submission_images.value.length ? pageComponents.elements.block3_post_submission_images.value[0] : undefined,
        post_sub_image2: pageComponents.elements.block3_post_submission_images.value.length > 1 ? pageComponents.elements.block3_post_submission_images.value[1] : undefined
      };
      await this.landingPageService.getMedContactData().then(pageData => {
        this.data = pageData;
      });
    }

    // load block 4 data
    if (pageComponents.elements.block4_content.value) {
      let iframe = pageComponents.elements.block4_content.value;
      
      this.activeRoute.queryParams.subscribe((params: Params) => {
        const utmParams = {
          ...(params["utm_source"] && {utm_source: params["utm_source"]}),
          ...(params["utm_medium"] && {utm_medium: params["utm_medium"]}),
          ...(params["utm_campaign"] && {utm_campaign: params["utm_campaign"]}),
          ...(params["utm_term"] && {utm_term: params["utm_term"]}),
          ...(params["utm_content"] && {utm_content: params["utm_content"]}),
          ...(params["utm_audience"] && {utm_audience: params["utm_audience"]}),
          ...(params["utm_drip"] && {utm_drip: params["utm_drip"]}),
        };
        const attributionParams = {
          source_id: "ncoa",
          external_id: `User#${localStorage.getItem("guestIdentity")}`,
        };
        const src = new URLSearchParams({ ...utmParams, ...attributionParams }).toString();
        iframe = iframe.replace('source_id=ncoa',src);
      });
      this.block4 = {
        copy: this.sanitizer.bypassSecurityTrustHtml(iframe) as string
      }
    }

    // load block 5 data
    if (pageComponents.elements.block5_content.value) {
      this.block5 = {
        copy: this.sanitizer.bypassSecurityTrustHtml(pageComponents.elements.block5_content.value) as string,
        post_sub_copy: this.sanitizer.bypassSecurityTrustHtml(pageComponents.elements.block5_post_submission_content.value) as string ?? ''
      }
    }

    // load footer data
    this.footerLogo = pageComponents.elements.footer_ncoa_logo.value[0];
    this.footerLogoURL = pageComponents.elements.footer_ncoa_logo_url.value;
    if (pageComponents.elements.footer_navigation_bar_link_1_label.value && 
      pageComponents.elements.footer_navigation_bar_link_1_url.value) this.bottomNavLinks.push({label: pageComponents.elements.footer_navigation_bar_link_1_label.value, url: pageComponents.elements.footer_navigation_bar_link_1_url.value});
    if (pageComponents.elements.footer_navigation_bar_link_2_label.value && 
      pageComponents.elements.footer_navigation_bar_link_2_url.value) this.bottomNavLinks.push({label: pageComponents.elements.footer_navigation_bar_link_2_label.value, url: pageComponents.elements.footer_navigation_bar_link_2_url.value});
    if (pageComponents.elements.footer_navigation_bar_link_3_label.value && 
      pageComponents.elements.footer_navigation_bar_link_3_url.value) this.bottomNavLinks.push({label: pageComponents.elements.footer_navigation_bar_link_3_label.value, url: pageComponents.elements.footer_navigation_bar_link_3_url.value});
    if (pageComponents.elements.footer_navigation_bar_link_4_label.value && 
      pageComponents.elements.footer_navigation_bar_link_4_url.value) this.bottomNavLinks.push({label: pageComponents.elements.footer_navigation_bar_link_4_label.value, url: pageComponents.elements.footer_navigation_bar_link_4_url.value});
    
    if (pageComponents.elements.fine_print_1_label.value && 
      pageComponents.elements.fine_print_1_url.value) this.finePrintLinks.push({label: pageComponents.elements.fine_print_1_label.value, url: pageComponents.elements.fine_print_1_url.value});
    if (pageComponents.elements.fine_print_2_label.value && 
      pageComponents.elements.fine_print_2_url.value) this.finePrintLinks.push({label: pageComponents.elements.fine_print_2_label.value, url: pageComponents.elements.fine_print_2_url.value});
    if (pageComponents.elements.fine_print_3_label.value && 
      pageComponents.elements.fine_print_3_url.value) this.finePrintLinks.push({label: pageComponents.elements.fine_print_3_label.value, url: pageComponents.elements.fine_print_3_url.value});

    this.contentReady = true;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkInView();
    }, 1000);

    this.partnerEventRecord = {
      location: window.location,
      partner: this.partnerCode,
      timestamp: new Date().getTime(),
      calculator: this.block4?.copy,
      navClicks: []
    }
    this.cas.updateActivity({'partnerLandingPage':this.partnerEventRecord}).subscribe(() => {});
  }

  submit():void {
    this.buttonDisabled = true;

    if (this.contactForm.valid) {
      this.cas.announceMedicareContact(this.contactForm.value, false, window.location, this.partnerCode).subscribe(res => {
        if (res && res.status === "received") {
          localStorage.setItem('broker', 'true');
          localStorage.setItem('broker_name', 'mcg_broker');
        } else {
          localStorage.setItem('broker', 'false');
          localStorage.setItem('broker_name', '');
        }

        // replace content blocks with post-submission content
        this.postSubmission = true;
        if (this.block1) this.block1.copy = this.block1.post_sub_copy;
        if (this.block1) this.block1.image = this.block1.post_sub_image;
        if (this.block2) this.block2.copy = this.block2.post_sub_copy;
        if (this.block3) this.block3.copy = this.block3.post_sub_copy;
        if (this.block5) this.block5.copy = this.block5.post_sub_copy;
      }, err => {
        throw new Error(err);
      });
    } else {
      this.buttonDisabled = false;
      this.contactForm.markAllAsTouched();
      this.contactForm.updateValueAndValidity();
    }
  }

  recordNavClick(event, link): void {
    this.partnerEventRecord.navClicks.push({ ...link, timestamp: new Date().getTime() });
    this.cas.updateActivity({'partnerLandingPage':this.partnerEventRecord}).subscribe(() => { window.open(link.url, "_blank"); });
  }

  createForm(): void {
    this.contactForm = this.formBuilder.group({
      firstName: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
      lastName: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
      email: ['', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur',
      }],
      zipCode: this.formBuilder.control('', [Validators.required], zipCodeValidator(this.utilService)),
      dateOfBirth: ['', {
        validators: [Validators.required, minAge(18), maxAge(100), dateIsValid],
        updateOn: 'blur',
      }],
      phoneNumber: ['', {
        updateOn: 'blur',
      }],
      medicareInterestReason: ['default', {
        updateOn: 'blur'
      }]
    });

    const validationMessages = {
      zipCode: {
        required: 'Required',
        invalidZip: 'Invalid Zip Code'
      },
      dateOfBirth: {
        minAge: `Invalid date format or value. Format should be MM/DD/YYYY and fall within age range of ${18} to ${100}.`,
        maxAge: `Invalid date format or value. Format should be MM/DD/YYYY and fall within age range of ${18} to ${100}.`,
        invalidDate: `Invalid date format or value. Format should be MM/DD/YYYY and fall within age range of ${18} to ${100}.`
      }
    };

    this.errorMessageProcessor = new ErrorMessageProcessor(validationMessages);
    this.errorMessages = this.errorMessageProcessor.processMessages(this.contactForm);

    this.contactForm.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.errorMessages = this.errorMessageProcessor.processMessages(this.contactForm);
    });
  }

  get firstName() { return this.contactForm.get('firstName'); }
  get lastName() { return this.contactForm.get('lastName'); }
  get zipCode() { return this.contactForm.get('zipCode'); }
  get email() { return this.contactForm.get('email'); }
  get dateOfBirth() { return this.contactForm.get('dateOfBirth'); }
  get phoneNumber() { return this.contactForm.get('phoneNumber'); }
  get medicareInterestReason() { return this.contactForm.get('medicareInterestReason'); }

  toggleSideBar(): void {
    this.isSideBarOpen = !this.isSideBarOpen;
    this.disableTabOnHiddenContent();
  }

  disableTabOnHiddenContent(){
    const mainContainer = document.querySelector(".main-content-parent-container");
    //const skipLink = document.querySelector(".skip-link");
    if(this.isSideBarOpen){
      mainContainer.setAttribute('style', 'margin-top: 100%');
      mainContainer.setAttribute('tabindex', '-1');
      // skipLink.setAttribute('aria-hidden', 'true');
      // skipLink.setAttribute('tabindex', '-1');
      document.body.setAttribute('style', `overflow-y: hidden; position: fixed; width: 100%; top: 0px`)
    }else{
      mainContainer.removeAttribute('style');
      mainContainer.removeAttribute('tabindex');
      // skipLink.removeAttribute('aria-hidden');
      // skipLink.setAttribute('tabindex', '0');
      document.body.setAttribute('style', ``)
    }
  }

  socials = [
    {
      type: 'facebook',
      url: '#',
      text: 'Open Facebook',
    },
    {
      type: 'twitter',
      url: '#',
      text: 'Open Twitter',
    },
    {
      type: 'youtube',
      url: '#',
      text: 'Open YouTube',
    },
  ]

  checkInView() {
    //const _articleBody = <HTMLElement>document.querySelector('.partner-page');
    const _articleBody = <HTMLElement>document.querySelector('.component');

    if ( !_articleBody ) {
      return;
    }

    const _articleShare = <HTMLElement>document.querySelector('.article--share');

    let initialPos = window.pageYOffset;
    let ctr1 = 1;
    let ctr2 = 1;

    window.addEventListener('scroll', () => {

      if( _articleShare ){
        let _articleBodyBottom = _articleBody.getBoundingClientRect().bottom;
        let _articleShareBottom = _articleShare.getBoundingClientRect().bottom;
        let _articleShareLeft = _articleShare.getBoundingClientRect().left + _articleShare.clientWidth;

        if( this.isInView( _articleShare, window.innerHeight ) && _articleBodyBottom < 113 ) {
          _articleShare.classList.add('in-view');
        } else {
          _articleShare.classList.remove('in-view');
        }
//console.log(_articleBodyBottom + ' -- ' + window.innerHeight);
        if( this.isInView( _articleBody, 0 && _articleBodyBottom < 113) ) {
          _articleShare.classList.add('is-sticky');

          if( _articleBodyBottom > _articleShareBottom ) {
            _articleShare.classList.remove('is-absolute');
          } else if( _articleBodyBottom > window.innerHeight ) {
            _articleShare.classList.remove('is-absolute');
          } else {
            //_articleShare.classList.add('is-absolute');
          }

        } else {
          _articleShare.classList.remove('is-sticky');
          _articleShare.classList.remove('is-absolute');
        }
      }
    });
  }

  isInView( _element, _offset ) {
    let _componentPosition = _element.getBoundingClientRect();

    if( _componentPosition.top <= _offset ) {
      return true;
    } else {
      return false;
    }
  }
}
