import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-benefit-card-modal',
  templateUrl: './benefit-card-modal.component.html',
  styleUrls: ['./benefit-card-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BenefitCardModalComponent implements OnInit {
  private _title: string;
  private isModalOpen: boolean = false;

  @Input() set title(value:string){
    this._title = value;
    if(value !== null){
      this.openModal();
    }
  };
  get title() : string{
    return this._title;
  }

  @Input() eyebrow?;
  @Input() desc?;

  @Input() cta?: {
    text: string,
    url: string
  };


  @Output() onModalClose: EventEmitter<any> = new EventEmitter();
  scrollY: any = 0;

  constructor(private renderer: Renderer2,@Inject(DOCUMENT) private document: Document, private dataLayerService: DataLayerService) {
   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.renderer.setAttribute(this.document.body,'style', '');
  }

  openModal(){
    if(!this.isModalOpen){
      this.scrollY = window.scrollY;
      this.renderer.setAttribute(this.document.body,'style', `overflow-y: hidden; position: fixed; width: 100%; top: -${this.scrollY}px`);
      this.isModalOpen = true;
    }
  }

  closeModal() {
    this.renderer.setAttribute(this.document.body,'style', '');
    window.scrollTo({top: this.scrollY});
    this.isModalOpen = false;
    this.onModalClose.emit(true);
  }

  trackAnalytics(ev){

    const _element = ev.currentTarget as HTMLAnchorElement;
    const _navItem = _element.textContent || _element.querySelector('img').alt;

    this.dataLayerService.push({
      'event': 'resultClick',
      'componentType': 'benefits results',
      'url': _element.href || _element.getAttribute('href')},
      false);
  }

}
