import { Component, OnInit, Input } from '@angular/core';

interface ListItemType {
  text: string,
  button?: {
    text: string
  }
};

@Component({
  selector: 'ncoa-basic-list',
  templateUrl: './basic-list.component.html',
  styleUrls: ['./basic-list.component.scss']
})

export class BasicList implements OnInit {
  @Input() items: ListItemType[] = [];

  constructor() { }

  ngOnInit() {

  }

}
