import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ncoa-subcategory-pathing',
  templateUrl: './subcategory-pathing.component.html',
  styleUrls: ['./subcategory-pathing.component.scss']
})
export class SubcategoryPathingComponent implements OnInit {
  @Input() page: string;
  @Input() headline: string;
  @Input() categoryName: string;
  @Input() categories: any[];

  constructor() { }

  ngOnInit(): void { }
}
