import { Component, OnInit } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { ProfileService } from '@services/planner/profile.service';
import { UserService } from '@services/planner/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss']
})
export class AccountProfile implements OnInit {
  data: any;
  activeIndex: number = 0;

  constructor(
    private profileService: ProfileService,
    private userService: UserService,
    private dataLayerService: DataLayerService,
  ) { }

  ngOnInit(): void {
    this.userService.isLoggedIn()
      .pipe(take(1))
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.profileService.getData()
            .then((data) => {
              this.data = data;
            });
        } else {
          window.location.href = '/404';
        }
      });

    // Push analytics data
    this.dataLayerService.push({
      clickUrl: location.href,
      pageTitle: document.title || '',
      contentType: 'profile-page',
      pageType: 'profile-page',
    },false);
  }

  onNavigationClick(index) {
    this.data['profilePanel'] = this.profileService.getPanel(index);

    this.data['profileHeader'].menu[this.activeIndex].isActive = false;
    this.data['profileHeader'].menu[index].isActive = true;

    this.activeIndex = index;
  }
}
