import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { ComponentService } from '@services/component.service';
import { ErrorService } from '@services/error.service';
import { GlobalService } from '@services/global.service';
import { RichTextService } from '@services/rich-text-resolver.service';
import gsap from 'gsap';
import { NcoaLandingPageService } from '@services/ncoa-landing-page.service';

@Component({
  selector: 'ncoa-landing-page',
  templateUrl: './ncoa-landing-page.component.html',
  styleUrls: ['./ncoa-landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NcoaLandingPageComponent implements OnInit {

  @Input() pageData: any;

  @ViewChild('eventCustomSearchRoot') rootElement: any;

  contentReady = false;

  components: any[] = [];
  componentTypes: any;
  partnership: any;

  items: any[] = [];
  searchKey: string = '';
  resultTitle: string = '';
  totalItems: number = 0;
  dateFilterValue: string = 'forwards';
  filters: any[];

  limit: number = 5;
  loading: boolean = true;
  hasMoreItems: boolean = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private componentService: ComponentService,
    private errorService: ErrorService,
    private globalService: GlobalService,
    private richTextService: RichTextService,
    private location: Location,
    private landingPageService: NcoaLandingPageService
  ) {
    this.componentTypes = this.componentService.componentTypes;
  }

  ngOnInit() {
      if (this.pageData) {
        this.usePageData(this.pageData);
      } else {
        this.errorService.notFound();
      }

      this.searchKey = this.activeRoute.snapshot.queryParams.q || '';
        this.landingPageService.limit = this.limit;

        Promise.all([
        this.landingPageService.storeAllEvents(),
        ])
        .then(() => {
            this.globalService.loadFooter(true);
            return this.landingPageService.searchEventsStore(this.searchKey, this.dateFilterValue);
        })
  }

  usePageData(data) {
    if (data) {
      this.components = [
        ...data,
      ];
      
      //find event list component
      const eventList = (data as any[]).find(component => component.componentType === 'event-list');
      this.items = eventList.items.slice(0, this.limit);  
      this.totalItems = eventList.items.length;
      this.hasMoreItems = this.items.length < this.totalItems;
      this.contentReady = true;
    }
  }

  getMoreContents() {
    const items = this.components.find(component=> component.componentType === 'event-list').items.slice(this.items.length, this.items.length + this.limit);
    this.onSearchDataReceived({
      items,
      totalCount: this.totalItems
    });
  }

  async searchContent(keyword) {
    const path = keyword && keyword.length > 0 ? `/${this.activeRoute.snapshot.url[0].path}?q=${keyword}` : `/${this.activeRoute.snapshot.url[0].path}`;

    // Show animation
    // this.showLoading()
    // .then(() => {
      this.location.replaceState(path);
      this.searchKey = keyword || '';

      const data = this.landingPageService.searchEventsStore(keyword, this.dateFilterValue);

      // Reset items
      this.items = [];

      // Process data
      this.onSearchDataReceived(data);
    // });
  }

  onSearchDataReceived(data) {
    const { items, totalCount } = data;

    this.loading = false;
    this.totalItems = totalCount;

    if (!items.length) {
      this.resultTitle = '';
      return;
    }

    this.resultTitle = 'Most Relevant';

    this.items = [ ...this.items, ...items ];
    this.hasMoreItems = this.items.length < this.totalItems;
  }
  

  onDateChange(dateFilter) {
    if ( dateFilter === this.dateFilterValue ) {
      return;
    }

    // Save updated date
    this.dateFilterValue = dateFilter;

    // Search using filter date
    const data = this.landingPageService.searchEventsStore(this.searchKey, this.dateFilterValue);

    // Reset items
    this.items = [];

    // Process filtered items
    this.onSearchDataReceived(data);
  }

  onClearQuery() {
    this.dateFilterValue = 'forwards';
    this.searchKey = '';

    this.searchContent('');
  }

  async showLoading() {
    return new Promise(resolve => {
      gsap.to('.results-list', { y: 200, opacity: 0, duration: 0.4 });
      gsap.to('.no-result', { opacity: 0, duration: 0.4, onComplete: () => {
        this.loading = true;
      }});
    });
  }
}
