import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ArticleService } from '@services/article.service';
import { ComponentService } from '@services/component.service';
import { GlobalService } from '@services/global.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleComponent implements OnInit {
  categories: any;
  audiences: any;
  takeaways: any;
  bodyStyle: 'v1' | 'v2' = 'v2'; // v2 is default for articles, v1 is for events

  componentTypes: any;
  components: any[];

  constructor(
    private activeRoute: ActivatedRoute,
    private articleService: ArticleService,
    private componentService: ComponentService,
    private globalService: GlobalService
  ) {
    this.componentTypes = this.componentService.componentTypes;
  }

  ngOnInit(): void {
    this.activeRoute.data.subscribe(({ articleData }: any) => {
      this.useArticleData(articleData);
    });
  }

  useArticleData({
    categories,
    audiences,
    takeaways,
    components
  }) {
    this.categories = categories;
    this.audiences = audiences;
    this.takeaways = takeaways;
    this.components = components;
    components.forEach(component => {
      if (component.componentType === 'eventHero') {
        this.bodyStyle = 'v1'
      }
    });
    this.globalService.loadFooter( components.length > 0 ? true : false );

  }

  checkTakeAways(takeawaysData) {
    let innerHTML = '';
    takeawaysData.takeaways.forEach(({ text }) => {
      innerHTML += text;
    });

    const div = document.createElement('div');
    div.innerHTML = innerHTML;

    if (div.innerText.trim().length === 0) {
      return null;
    } else {
      return takeawaysData;
    }
  }

  parseShortCodes(body): any {
    let newBody = body;

    newBody.content = this.parseCaptionCode(newBody.content);
    newBody.content = this.parseVimeoCode(newBody.content);
    newBody.content = this.parseYouTubeCode(newBody.content);

    if (!body.readingTime) {
      const div = document.createElement('div');
      div.innerHTML = newBody.content;

      const numberOfWords: number = div.innerText.match(/\b[-?(\w+)?]+\b/gi).length;
      const wordsPerMin = 180;
      const newReadingTime = Math.ceil(numberOfWords / wordsPerMin);

      body.readingTime = newReadingTime;
    }

    return newBody;
  }

  unescapeHTML(string): string {
    const entityMap = {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#39;': "'",
      '&#x2F;': '/',
      '&#x60;': '`',
      '&#x3D;': '='
    };

    return String(string).replace(/(&lt;)|(&gt;)|(&amp;)|(&quot;)|(&#39;)|(&#x2F;)|(&#x60;)|(&#x3D;)/g, function (s) {
      return entityMap[s];
    });
  }

  parseCaptionCode(candidate = ''): string {
    let newBody: string = candidate;
    const regex = /\[(caption).+\]/g;
    const matches = candidate.match(regex);

    if (matches) {
      matches.forEach(match => {
        const e = document.createElement('div');
        e.innerHTML = this.unescapeHTML(match.replace(/\[.*?\]/g, ''));

        const imageNode = e.querySelector('img').cloneNode(true);
        const captionString = e.innerText.trim();

        e.innerHTML = '';
        e.appendChild(imageNode);

        const inlineImage = `
          <div class="inline-image">
            <div class="image-container">${e.innerHTML}</div>
            ${captionString.trim().length > 0 ? `<div class="caption">${captionString}</div>` : ''}
          </div>`;

        newBody = newBody.replace(match, inlineImage);
      });
    }

    return newBody;
  };

  parseVimeoCode(candidate): string {
    let newBody: string = candidate;
    const embedRegex = /\[(embed).+\]/g;
    const urlRegex = /http(s)?:\/\/(www\.)?vimeo.com\/(\d+)(\/)?(#.*)?/;
    let matches = candidate.match(embedRegex);

    if (matches) {
      matches.forEach(match => {
        const vimeoURL = match.replace(/\[.*?\]/g, '');
        const IDMatch = vimeoURL.match(urlRegex);

        if (IDMatch) {
          const vimeoEmbed = `
            <div class="embed embed--vimeo">
              <iframe
                src="https://player.vimeo.com/video/${IDMatch[3]}"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              >
              </iframe>
            </div>`;

          newBody = newBody.replace(match, vimeoEmbed);
        }
      });
    }

    const vimeoRegex = /\[(vimeo).+\]/g;
    matches = candidate.match(vimeoRegex);

    if (matches) {
      matches.forEach(match => {
        const id = /id="([^"]*)"/g.exec(this.unescapeHTML(match));

        if (id) {
          const vimeoEmbed = `
            <div class="embed embed--vimeo">
              <iframe
                src="https://player.vimeo.com/video/${id[1]}"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              >
              </iframe>
            </div>`;

          newBody = newBody.replace(match, vimeoEmbed);
        }
      });
    }

    return newBody;
  }

  parseYouTubeCode(candidate): string {
    let newBody: string = candidate;
    const regex = /\[(youtube).+\]/g;
    const matches = candidate.match(regex);

    if (matches) {
      matches.forEach(match => {
        const id = /id="([^"]*)"/g.exec(this.unescapeHTML(match));

        if (id) {
          const youTubeEmbed = `
            <div class="embed embed--youtube">
              <iframe
                src="https://www.youtube.com/embed/${id[1]}"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              >
              </iframe>
            </div>`;

          newBody = newBody.replace(match, youTubeEmbed);
        }
      });
    }

    return newBody;
  }

  updateReadingTime(newReadingTime) {
    this.components.some(component => {
      if ( component.componentType === 'body' ) {
        component.readingTime = newReadingTime;
        return true;
      }
    })
  }
}
