<div class="stepper-container">

    <ng-container *ngIf="showHeader">

        <section class="stepper-header">
            <h3 [class]="headerColor">{{header}}</h3>
        
            <div class="stepper-step-container">
    
                <h3> {{currentStep + 1}} of {{totalSteps}}</h3>
               
            </div>
        </section>
        
        
        <section class="stepper-progress-bar">
            <div [style.width]="progressWidth" class="progress-bar"></div>
            <div class="bar-background"></div>
        </section>


    </ng-container>


    
    <section #steps class="stepper-content">
        <ng-content></ng-content>
    </section>
    
</div>

