import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserPromptService {
  constructor() { }

  private isModalOpenSubject = new BehaviorSubject(false);
  isModalOpenObservable = this.isModalOpenSubject.asObservable();

  isModalOpen(isModalOpen: boolean) {
    this.isModalOpenSubject.next(isModalOpen)
  }

}