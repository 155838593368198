<ncoa-template-default>
  <div class="search-page">
    <ncoa-list-header
      [totalItems]="totalItems"
      [searchKey]="searchKey"
      [chosenAudience]="filteredAudience"
      [chosenType]="filteredType"
      [audienceOptions]="audienceOptions"
      (searchArticle)="searchArticle($event)"
      (filterArticle)="filterArticle($event)"
      ncoa-engageable="0"
    ></ncoa-list-header>
    <ncoa-results-list
      [items]="items"
      [title]="resultTitle"
      [hasMoreItems]="shownItems !== totalItems"
      [getMoreItems]="getMoreArticles.bind(this)"
      ncoa-engageable="1"
      ncoa-engageable-name="search-results-list"
    ></ncoa-results-list>
    <div
      class="no-result"
      [ngClass]="totalItems === 0 ? 'is-active' : ''"
      [attr.aria-hidden]="totalItems === 0 ? false : true"
    >
      <svg width="93" height="106" viewBox="0 0 93 106" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M55.5496 66.7615C73.321 66.7615 87.7276 52.2559 87.7276 34.3622C87.7276 16.4686 73.321 1.96295 55.5496 1.96295C37.7782 1.96295 23.3716 16.4686 23.3716 34.3622C23.3716 52.2559 37.7782 66.7615 55.5496 66.7615Z" fill="#DAE4E7"/>
        <path d="M7.06838 83.3395C4.65038 80.9048 4.65038 76.7847 7.06838 74.3501L29.2148 52.4336C29.2148 52.4336 30.432 54.8597 32.8666 57.3134C35.3013 59.7672 38.2227 61.3424 38.2227 61.3424L15.9964 83.3395C13.3924 85.7741 9.48638 85.7741 7.06838 83.3395Z" fill="#DAE4E7"/>
        <ellipse cx="55.5077" cy="34.3519" rx="24.3456" ry="24.537" fill="#F3F6F7"/>
        <path d="M51.0807 23.3015C50.8437 20.806 52.8062 18.6481 55.313 18.6481C57.7843 18.6481 59.7348 20.7481 59.5527 23.2127L58.3852 39.0141C58.2728 40.5358 57.0054 41.7129 55.4796 41.7129C53.9773 41.7129 52.7213 40.5706 52.5792 39.075L51.0807 23.3015Z" fill="#0B4A5D" stroke="#0B4A5D" stroke-width="2"/>
        <ellipse cx="55.5078" cy="47.1112" rx="2.43456" ry="2.4537" fill="#0B4A5D" stroke="#0B4A5D" stroke-width="2"/>
      </svg>
      <h3>No results found. Please try again!</h3>
      <h4>
        <span>Try these tips to expand your search:</span>
      </h4>
      <div class="description">
        <p>Check the spelling of your words.</p>
        <p>Try using fewer, different or more general keywords.</p>
      </div>
    </div>
  </div>
</ncoa-template-default>
