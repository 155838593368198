<div class="account-register-planner">
  <div class="account-register-planner__wrapper">
    <a class="back-cta" [title]="backCTA.text" (click) = 'goBack($event)'>
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M6.86842 0.547222L0.315788 7.25062C0.105263 7.46513 0 7.73326 0 8.0014C0 8.26954 0.105263 8.53767 0.315788 8.75218L6.86842 15.4556C7.28947 15.8846 7.94737 15.8846 8.36842 15.4556C8.78947 15.0266 8.78947 14.3562 8.36842 13.9272L3.57895 9.07395L18.9474 9.07395C19.5263 9.07395 20 8.5913 20 8.0014C20 7.4115 19.5263 6.92886 18.9474 6.92886L3.57895 6.92886L8.34211 2.04878C8.76316 1.61977 8.76316 0.949426 8.34211 0.52014C7.94737 0.117936 7.28947 0.117936 6.86842 0.547222Z"
          fill="#0B4A5D" />
      </svg>
      <div><span>{{ backCTA.text }}</span></div>
    </a>
    <div *ngIf="!registrationDone" class="main"  id = 'main-content'>
      <div class="step-indicator">
        <div class="text">
          <div class="text__eyebrow">{{ eyebrow }}</div>
          <div class="text__counter">
            {{ activeStepIndex }} of {{ stepCount }}
          </div>
        </div>
        <div class="progress-bar">
          <div #progressBar class="progress-bar__active"></div>
        </div>
      </div>
      <div class="step-body" *ngIf="steps.length === 2">
        <div #track class="step-body__track">
          <div #step class="step">
            <h2 class="step__title">{{ steps[0].title }}</h2>
            <div class="step__desc" [innerHTML]="steps[0].desc"></div>
            <div class="field">
              <label for="email">Email Address</label>
              <input
                [(ngModel)]="email.value"
                type="email"
                name="email"
                id="email"
                [ngClass]="email.status.code"
              />
              <div *ngIf="email.status.code === 'error'" class="error">
                {{ email.status.message }}
              </div>
            </div>
              <label class="checkbox" for="newsletter">
                <div class="box">
                  <input
                    type="checkbox"
                    name="newsletter"
                    id="newsletter"
                    [(ngModel)]="newsletter.value"
                    [(ngModel)]="newsletter.value"
                    [attr.aria-checked]="newsletter.value"
                  />
                  <svg *ngIf="newsletter.value" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.09961 13.166C6.45117 13.5176 7.04883 13.5176 7.40039 13.166L17.7363 2.83008C18.0879 2.47852 18.0879 1.88086 17.7363 1.5293L16.4707 0.263672C16.1191 -0.0878906 15.5566 -0.0878906 15.2051 0.263672L6.76758 8.70117L2.79492 4.76367C2.44336 4.41211 1.88086 4.41211 1.5293 4.76367L0.263672 6.0293C-0.0878906 6.38086 -0.0878906 6.97852 0.263672 7.33008L6.09961 13.166Z" fill="#0B4A5D"/>
                  </svg>
                </div>
                <span>Sign me up to the newsletter to receive the latest news, promotions, etc.</span>
              </label>
            <a class="signin-cta" [href]="signInCTA.url" [title]="signInCTA.text">{{ signInCTA.text }}</a>
          </div>
          <div #step class="step" style="visibility: hidden;">
            <h2 class="step__title">{{ steps[1].title }}</h2>
            <div class="step__desc" [innerHTML]="steps[1].desc"></div>
            <div class="field field--row">
              <div class="field__col">
                <label for="password">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  [ngClass]="password.status.code"
                  [(ngModel)]="password.value"
                  (input)="clearErrors()"
                />
                <div *ngIf="password.status.code === 'error'" class="error" role='alert'>
                  {{ password.status.message }}
                </div>
              </div>
              <div class="field__col">
                <label for="c_password">Confirm Password</label>
                <input
                  type="password"
                  name="c_password"
                  id="c_password"
                  [(ngModel)]="confirmPassword.value"
                  [ngClass]="confirmPassword.status.code"
                  (input)="clearErrors()"
                />
                <div *ngIf="confirmPassword.status.code === 'error'" class="error" role='alert'>
                  {{ confirmPassword.status.message }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="step-footer">
        <button #submitBtn type="button" class="submit-btn" (click)="onSubmit($event)">Save & Continue</button>
      </div>
    </div>
    <div *ngIf="registrationDone" class="main"  id = 'main-content'>
      <div class="registration-done">
        <div class="eyebrow">{{ verificationEyebrow }}</div>
        <h2 class="title">{{ verificationTitle }}</h2>
        <p class="desc">{{ verificationDesc }}</p>
        <a [href]="helpCTA.url" [title]="helpCTA.text" class="help-cta">{{ helpCTA.text }}</a>
      </div>
    </div>
  </div>

  <div class="sr-only" *ngIf="errorMessage !== ''" role="alert">
    {{errorMessage}}
  </div>
</div>
