import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { StandardService } from '@services/standard.service';
import { GlobalService } from '@services/global.service';

@Injectable({
  providedIn: 'root'
})
export class StandardResolverService {

  constructor(
    private standardService: StandardService,
    private globalService: GlobalService
  ) { }

  async resolve(activeRoute: ActivatedRouteSnapshot, route: Router) {
    this.globalService.saveCurrentURL(`/${activeRoute.url.join('/')}`);

    const [ pageData ] = await Promise.all([
      this.standardService.getPage(activeRoute.firstChild?.params['child'] || activeRoute.params.slug),
      this.globalService.loadGlobalComponentsData()
    ]);

    if ( pageData ) {
      const { url, data }: any = pageData;

      let pathname = route.url;
      pathname = this.cleanPathname(pathname)

      if (pathname !== url) {
        return null;
      } else {
        return data;
      }
    }

    return null;
  }

   cleanPathname(pathname) {
    const hashIndex = pathname.indexOf('#');
    const queryIndex = pathname.indexOf('?');
    
    if (hashIndex === -1 && queryIndex === -1) {
      return pathname;
    }
  
    let minIndex = hashIndex;
  
    if (hashIndex === -1 || (queryIndex !== -1 && queryIndex < hashIndex)) {
      minIndex = queryIndex;
    }
  
    return pathname.substring(0, minIndex);
  }
}
