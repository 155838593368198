import { Component, OnInit, Input, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { image, links } from './author-header.types';
import { TimelineLite } from 'gsap';

@Component({
  selector: 'ncoa-author-header',
  templateUrl: './author-header.component.html',
  styleUrls: ['./author-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthorHeaderComponent implements OnInit, AfterViewInit {
  @Input() image: image;
  @Input() authorName: string;
  @Input() authorTitle: string;
  @Input() authorBio?: string;
  @Input() links?: links[];
  @Input() eyebrow?: string;
  @Input() isInCategorySearch?: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.animatePageLoad();
  }

  animatePageLoad() {
    const _header = document.querySelector('.component.author-header');
    const _result = document.querySelector('.results-list');

    new TimelineLite()
      .to(_header, { y: 0, opacity: 1, duration: 0.4 })
      .to(_result, { y: 0, opacity: 1, duration: 0.4 });
  }

}
