<div class="analyze-your-budget-content" *ngIf="budgetFormGroup && widgetContent">

    <form *ngIf="!valid; else topics" #budgetForm="ngForm" id="ngForm" [formGroup]="budgetFormGroup" (ngSubmit)="onSubmit()">


        <section #expenseSection class="expenses" >

           <div class="expense-sections">

            <div formGroupName="expenses">
                <app-amount-question *ngFor="let question of expenseQuestions" [placeholder]="widgetContent.amount_placeholder_text.value" [quarterLabel]="widgetContent.interval___quarterly_label.value" [tooltipText]="question.tooltip.value" [annualLabel]="widgetContent.interval___annual_label.value" [monthlyLabel]="widgetContent.interval___monthly_label.value" [typeLabel]="widgetContent.receive_amount_label.value" [label]="question.label.value" [formGroupName]="question.expense_code.value" [form]="expenses" [disableInputs]="true"></app-amount-question>
            
            </div>

            <div formGroupName='incomes'>
                <app-amount-question *ngFor="let question of incomeQuestions" [placeholder]="widgetContent.amount_placeholder_text.value" [quarterLabel]="widgetContent.interval___quarterly_label.value" [tooltipText]="question.tooltip.value" [annualLabel]="widgetContent.interval___annual_label.value" [monthlyLabel]="widgetContent.interval___monthly_label.value" [typeLabel]="widgetContent.receive_amount_label.value" [label]="question.label.value" [formGroupName]="question.cad_field.value" [form]="incomes" [disableInputs]="true"></app-amount-question>
            </div>
            
           </div>


        </section>

        <section class="age-group">


            <div class="age-group-question">

                <label for="ageGroup">{{widgetContent.question_text.value}}</label>

                <select disabled [ngClass]="{'invalid': submitted && budgetFormGroup.invalid}" class="form-field" formControlName="ageGroup" >
                    <option class="select-default" [ngValue]="null" disabled>{{widgetContent.question_placeholder.value}}</option>
                    <option [value]="'age_under_25'" >{{widgetContent.under_25_label.value}}</option>
                    <option [value]="'age_25___34'" >{{widgetContent.n25___34_label.value}}</option>
                    <option [value]="'age_35___44'" >{{widgetContent.n35___44_label.value}}</option>
                    <option [value]="'age_45___54'" >{{widgetContent.n45___54_label.value}}</option>
                    <option [value]="'age_55___64'" >{{widgetContent.n55___64_label.value}}</option>
                    <option [value]="'age_65___74'" >{{widgetContent.n65___74_label.value}}</option>
                    <option [value]="'age_75_and_older'" >{{widgetContent.n75_and_older_label.value}}</option>
                </select>

            </div>




        </section>


    </form>

    <ng-template #topics>
        <section class="topics">
            <app-client-topics [client]="client" (onEdit)="onEdit()" [content]="widgetContent" [incomeQuestions]="incomeQuestions" [expenseQuestions]="expenseQuestions" [results]="budgetFormGroup.getRawValue()"></app-client-topics>
        </section>
      
    </ng-template>

</div>


<div class="footer">
    <div class="footer-controls">
        <button (click)="onPrev()" *ngIf="valid && (stepService.$setHistorySubject | async)?.analyze_your_budget?.step !== AnalyzeStep.BudgetResult" form="ngForm" class="todo-button previous-btn">Previous</button>

        <button *ngIf="!valid"  type="submit" form="ngForm" class="todo-button submit-btn">Submit</button>
    
    </div>

</div>