<div class="component component--partners-category" [ngClass]="{'colored': colored}">
  <div class="wrapper">
    <h2 class="partner--heading">{{ heading }}</h2>
    <div class="partner">
      <ul class="partner--list">
        <li class="partner--logo" *ngFor="let partner of partners">
          <div class="partner--logo__thumb">
            <span>
              <a *ngIf="partner.url" [href]="partner.url" [target]="partner.newTab ? '_blank' : '_self'">
                <img [src]="partner.logo" [alt]="'Logo ' + partner.name" />
              </a>
              <img *ngIf="!partner.url" [src]="partner.logo" [alt]="'Logo ' + partner.name" />
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
