<section class="component our-mission">
  <div class="our-mission__container desktop-version svg-bg">
    <svg class="oval" width="1336" height="641" viewBox="0 0 1336 641" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1336 5.83984e-05C1336 354.015 1036.93 641 668 641C299.074 641 -1.54745e-05 354.014 0 0" fill="#F3F6F7"/>
    </svg>
  </div>
  <div class="our-mission__container desktop-version">
    <div class="our-mission__wrapper">
      <div class="our-mission__texts">
        <h2 class="headline">{{ headline.split(' ')[0] }}
          <span class="highlight" *ngFor="let colored of headline.split(' '); index as i">
            <span class="highlight" *ngIf="i > 0"> {{ headline.split(' ')[i] }} </span>
          </span>
        </h2>
        <p class="description">{{ description }}</p>
        <a *ngIf="cta?.url" href="{{ cta.url }}" aria-label="Click here to Explore More" class="cta-link">
          {{ ctaText }}
        </a>
      </div>
      <div class="our-mission__entries">

        <div class="our-mission__entry" *ngFor="let entry of missionEntry; let i = index;">
          <div [ngClass]="{
            '': 'entry.description',
            '--no-desc': !entry.description,
            '--no-link': !entry.cta,
            '--has-link': entry.cta
          }">
            <div *ngIf="!entry.description">
              <h3 class="entry-title --mobile id{{i}}">{{ entry.title.split(' ')[0] }}<span class="highlight" *ngFor="let colored of entry.title.split(' '); index as i"><span class="highlight" *ngIf="i > 0"> {{ entry.title.split(' ')[i] }} </span></span></h3>
            </div>
            <div class="section">
              <img *ngIf="!entry.image.responsive" src="{{ entry.image.url }}" alt="{{ entry.image.alt }}" title="{{ entry.image.alt }}" loading="lazy">
              <picture *ngIf="entry.image.responsive">
                <source *ngFor="let item of entry.image.responsive" [srcset]="item.url" [media]="item.media" />
                <img [src]="entry.image.responsive[0].url" [alt]="entry.image.alt" [width]="entry.image.width" [height]="entry.image.height" loading="lazy"/>
              </picture>
            </div>
            <div [ngClass]="{
              'texts-section': entry.description,
              'texts-section--no-desc': !entry.description,
              'texts-section--no-link': !entry.cta,
              'texts-section--has-link': entry.cta
            }">
              <h3 class="entry-title id{{i}}">{{ entry.title.split(' ')[0] }}<span class="highlight" *ngFor="let colored of entry.title.split(' '); index as i"><span class="highlight" *ngIf="i > 0"> {{ entry.title.split(' ')[i] }} </span></span></h3>
              <p class="entry-description" *ngIf="entry.description">{{ entry.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="accent">
        <svg class="coral-circle" width="142" height="142" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="71" cy="71" r="71" fill="#F7696B"/>
        </svg>
        <svg class="pale-coral-circle" width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="43" cy="43" r="43" fill="#FEECEC"/>
        </svg>
      </div>
    </div>
  </div>

  <div class="our-mission__container mobile-version">
    <svg class="oval" width="1336" height="641" viewBox="0 0 1336 641" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1336 5.83984e-05C1336 354.015 1036.93 641 668 641C299.074 641 -1.54745e-05 354.014 0 0" fill="#F3F6F7"/>
    </svg>

    <div class="our-mission__wrapper">
      <div class="our-mission__texts">
        <h2 class="headline">{{ headline.split(' ')[0] }}
          <span class="highlight" *ngFor="let colored of headline.split(' '); index as i">
            <span class="highlight" *ngIf="i > 0"> {{ headline.split(' ')[i] }} </span>
          </span>
        </h2>
        <p class="description">{{ description }}</p>
        <a *ngIf="cta?.url" href="{{ cta.url }}" aria-label="Click here to Explore More" class="cta-link">
            {{ ctaText }}
        </a>
      </div>
      <div class="our-mission__entries">
        <div class="our-mission__entry" *ngFor="let entry of missionEntry; let i = index;">
          <div [ngClass]="{
            '': 'entry.description',
            '--no-desc': !entry.description,
            '--no-link': !entry.cta,
            '--has-link': entry.cta
          }">
          <svg *ngIf="i == 1" class="coral-circle" width="142" height="142" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="71" cy="71" r="71" fill="#F7696B"/>
          </svg>
          <svg *ngIf="i == 0" class="pale-coral-circle" width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="43" cy="43" r="43" fill="#FEECEC"/>
          </svg>
            <div *ngIf="!entry.description">
              <h3 class="entry-title --mobile id{{i}}">{{ entry.title.split(' ')[0] }}<span class="highlight" *ngFor="let colored of entry.title.split(' '); index as i"><span class="highlight" *ngIf="i > 0"> {{ entry.title.split(' ')[i] }} </span></span></h3>
            </div>
            <div class="section">
              <img *ngIf="!entry.image.responsive" src="{{ entry.image.url }}" alt="{{ entry.image.alt }}" title="{{ entry.image.alt }}" loading="lazy">
              <picture *ngIf="entry.image.responsive">
                <source *ngFor="let item of entry.image.responsive" [srcset]="item.url" [media]="item.media" loading="lazy" />
                <img [src]="entry.image.responsive[0].url" [alt]="entry.image.alt" />
              </picture>
            </div>
            <div [ngClass]="{
              'texts-section': entry.description,
              'texts-section--no-desc': !entry.description,
              'texts-section--no-link': !entry.cta,
              'texts-section--has-link': entry.cta
            }">
              <h3 class="entry-title id{{i}}">{{ entry.title.split(' ')[0] }}<span class="highlight" *ngFor="let colored of entry.title.split(' '); index as i"><span class="highlight" *ngIf="i > 0"> {{ entry.title.split(' ')[i] }} </span></span></h3>
              <p class="entry-description" *ngIf="entry.description">{{ entry.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
