import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ToDo, ToDoAction } from '@components/planner/plan/to-do/to-do.component';
import { CustomerActivityService } from '@services/planner/customer-activity.service';


@Component({
  selector: 'app-todo-page',
  templateUrl: './todo-page.component.html',
  styleUrls: ['./todo-page.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class TodoPageComponent implements OnInit {
  @ViewChild('pageContent') pageContent: ElementRef;
  @Input() todo: ToDo;
  @Input() content: any;
  @Input() header: any;

  @Output() onArchived: EventEmitter<ToDo> = new EventEmitter();
  @Output() onComplete: EventEmitter<ToDo> = new EventEmitter();
  @Output() onIncomplete: EventEmitter<ToDo> = new EventEmitter();
  @Output() onUnarchived: EventEmitter<ToDo> = new EventEmitter();

  // true = increase by 1, false = decrease by 1
  @Output() onUpdateListTotal: EventEmitter<ToDoAction> = new EventEmitter();

  public showFooter: boolean;

  public hideContent: boolean;
  private bottomViewed: boolean = false;



  constructor(private router: Router, private renderer: Renderer2, private cas: CustomerActivityService) {
    this.showFooter = true;
    this.hideContent = false;
  }

  ngOnInit(): void {
    
    switch(this.todo.slug){
      case 'analyze-your-budget':{
        this.showFooter = false;
      }
    }

  }
  ngAfterViewInit() {
    this.renderer.listen('window', 'scroll', (event) => {
      const elementPosition = this.pageContent.nativeElement.getBoundingClientRect();
      if (elementPosition.top >= 0 && elementPosition.bottom <= window.innerHeight && !this.bottomViewed) {
        this.bottomViewed = true; //prevent making more than 1 request

        this.cas.announceEvent(this.todo.slug+'ToDoViewed', {}, true, window.location).subscribe(() => { }, err => { });
      }
    });
  }

     
  public onArchiveClick(){
    this.onUpdateListTotal.emit({what: 'archived', action: !this.todo.archived});
    this.onUpdateListTotal.emit({what: this.todo.completed ? 'completed' : 'active', action: this.todo.archived});
    if(!this.todo.archived){
      return this.onArchived.emit(this.todo);
    }
    this.onUnarchived.emit(this.todo);
  }

  public onCompleteClick(){
    this.onUpdateListTotal.emit({what: 'active', action: this.todo.completed});
    this.onUpdateListTotal.emit({what: 'completed', action: !this.todo.completed})
    if(!this.todo.completed){
      return this.onComplete.emit(this.todo);
    }
    this.onIncomplete.emit(this.todo);
  }

  public onHideContent($event: boolean){
    this.hideContent = $event;
  }



}
