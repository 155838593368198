<div class="component component-article-grid">
  <div class="wrapper">
    <div class="article-grid">
      <div class="article--grid__panel article-grid__heading">
        <h3>{{ heading }}</h3>
      </div>
      <div class="article--grid__panel article-grid__articles">
        <div class="articles">
          <div class="articles--list" *ngFor="let card of cards">
            <ncoa-planner-card
                [id]="card.id"
                [type]="card.type"
                [heading]="card.heading"
                [intro]="card.intro"
                [breadcrumb]="card.breadcrumb"
                [cta]="card.cta"
              >
            </ncoa-planner-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
