import { Component, OnInit, Input } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {
  locale,
  type,
  title,
  description,
  url,
  site_name,
  image,
  image_width,
  image_height,
  facebook,
  twitter,
  instagram,
  linkedin,
  pinterest,
  youtube
} from './data';
import { GlobalService } from '@services/global.service';



@Component({
  selector: 'ncoa-temporary-landing',
  templateUrl: './temporary-landing.component.html',
  styleUrls: ['./temporary-landing.component.scss']
})
export class TemporaryLandingPage implements OnInit {
  @Input() socials: ({
    type: string,
    url: string,
  })[] = [
    { type: 'facebook', url: facebook },
    { type: 'twitter', url: twitter },
    { type: 'instagram', url: instagram },
    { type: 'linkedin', url: linkedin },
    { type: 'pinterest', url: pinterest },
    { type: 'youtube', url: youtube }
  ];

  constructor(
    private meta: Meta,
    private titleService: Title,
    private globalService: GlobalService
  ) {
    this.titleService.setTitle('Age Well America | Improving the Lives of Older Adults');

    this.meta.updateTag(
      { name: 'description', content: 'Explore everything Age Well America has to offer for professionals, older adults, caregivers, and advocates.' }
    );

    this.meta.addTags([
      { property: "og:locale", content: locale },
      { property: "og:type", content: type },
      { property: "og:title", content: title },
      { property: "og:description", content: description },
      { property: "og:url", content: url },
      { property: "og:site_name", content: site_name },
      { property: "og:image", content: image },
      { property: "og:image:width", content: image_width },
      { property: "og:image:height", content: image_height }
    ]);

    this.meta.addTags([
      { name: "twitter:card", content:"summary_large_image" },
      { name: "twitter:site", content:"@NCOAging" },
      { name: "twitter:description", content: "Explore everything Age Well America has to offer for professionals, older adults, caregivers, and advocates." },
      { name: "twitter:title", content: title },
      { name: "twitter:image", content: image },
    ]);

  }

  ngOnInit(): void {
    this.globalService.loadFooter( true );
  }

}
