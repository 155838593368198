import { Injectable } from '@angular/core';
import { KontentDeliveryService } from './kontent-delivery.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  codeName: string = 'primary_navigation';
  subNavNames: string[] = [];

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
  ) { }

  getData() {
    // return this.kontentDeliveryService
    //         .getItem(this.codeName, { depth: 3 })
    //         .then(navigationData => this.processNavigationData(navigationData));
  }


}
