import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { CostEstimatorService } from '@services/planner/cost-estimator.service';
@Component({
  selector: 'ncoa-cost-estimator',
  templateUrl: './cost-estimator.component.html',
  styleUrls: ['./cost-estimator.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CostEstimatorComponent implements OnInit {

  @Input() data: any = null;

  constructor(
    private costEstimator: CostEstimatorService
  ) { }

  ngOnInit(): void {
    this.costEstimator.getData().then((data) => this.data = data);
  }

}
