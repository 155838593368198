import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { AssessmentService } from '@services/planner/assessment.service';
import { ProfileService } from '@services/planner/profile.service';
import { UtilityService } from '@services/planner/utility.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ncoa-profile-panel',
  templateUrl: './profile-panel.component.html',
  styleUrls: ['./profile-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfilePanel implements OnInit, OnChanges {

  _assessmentData: any;
  _questions: any[];
  get assessmentData(): any {
      return this._assessmentData;
  }
  @Input() set assessmentData(value: any) {
      this._assessmentData = value;
      this.updatedAnswerFields = [];
  }
  get questions(): any[] {
      return this._questions;
  }
  @Input() set questions(value: any[]) {
      this._questions = value;
      this.updatedAnswerFields = [];
  }

  @Input() panelID: string;
  @Input() headline: string;
  @Input() saveCopy: string;

  initialQuestionAnswers: any[];

  updatedAnswerFields = [];

  preSelectedPrescription: any;
  prescriptionSelection: any;
  prescriptionQuantity: string;
  prescriptionFrequency: string;

  showLoadingOverlay: boolean = false;
  saveButtonDisabled: boolean = true;
  showSnackBar: boolean = false;
  showErrorSnackBar: boolean = false;

  constructor(
    private utility: UtilityService,
    private assessment: AssessmentService,
    private profileService: ProfileService,
    private dataLayerService: DataLayerService,
  ) { }

  ngOnInit(): void {
    this.initialQuestionAnswers = [];

    this.questions.forEach(({ userAnswer, id }) => {
      this.initialQuestionAnswers.push({
        id,
        userAnswer,
      });
    });
  }

  ngOnChanges(): void {
    this.initialQuestionAnswers = [];

    this.questions.forEach(({ userAnswer, id }) => {
      this.initialQuestionAnswers.push({
        id,
        userAnswer,
      });
    });

    // setTimeout(() => {
    //   this.updateDatepicker();
    // },1000)
  }

  genericOnChange(): void {
    let flag = true;

    this.questions.forEach((question) => {
      const { userAnswer, id: qId } = question;
      const initialAnswer = this.initialQuestionAnswers.find(({ id }) => id === qId);

      if (initialAnswer) {
        if (Array.isArray(initialAnswer.userAnswer) && initialAnswer.userAnswer.length > 0) {
          const x = initialAnswer.userAnswer[0];

          if (typeof x === 'string') {
            const processedUserAnswer = userAnswer.map(value => value);

            const difference = initialAnswer.userAnswer
              .filter(x => !processedUserAnswer.includes(x))
              .concat(processedUserAnswer.filter(x => !initialAnswer.userAnswer.includes(x)));

            if (difference.length > 0) {
              if(!this.updatedAnswerFields.includes(qId)){
                this.updatedAnswerFields.push(qId);
              }
              flag = false;
              return;
            }else{
              this.updatedAnswerFields = this.updatedAnswerFields.filter((item) => item !== qId)
            }
          }else if('text' in x){
            const processedUserAnswer = userAnswer.map(value => value.text);
            const processInitialAnswer = initialAnswer.userAnswer.map(value => value.text);
            let difference = processedUserAnswer
              .filter(x => !processInitialAnswer.includes(x))
              .concat(processInitialAnswer.filter(x => !processedUserAnswer.includes(x)));
            if (difference.length > 0) {
              flag = false;
              if(!this.updatedAnswerFields.includes(qId)){
                this.updatedAnswerFields.push(qId);
              }
              return;
            }else{
              this.updatedAnswerFields = this.updatedAnswerFields.filter((item) => item !== qId)
            }
          }
        } else {
          if (initialAnswer.userAnswer !== userAnswer) {
            if(!this.updatedAnswerFields.includes(qId)){
              this.updatedAnswerFields.push(qId);
            }
            flag = false;
            return;
          }else{
            this.updatedAnswerFields = this.updatedAnswerFields.filter((item) => item !== qId)
          }
        }
      }
    });

    this.saveButtonDisabled = flag;
  }

  onChange(id): void {
    const userAnswer = this.questions.find((question) => question.id === id).userAnswer;

    this.questions.forEach((question, index) => {
      if (question.condition && question.condition.dependentId === id) {
        if (userAnswer !== question.condition.value) {
          this.questions[index].displayQuestion = false;
        } else {
          this.questions[index].displayQuestion = true;
        }
      }
    });

    this.genericOnChange();
  }

  async onShortTextChange(questionIndex, id) {
    const userAnswer = this.questions[questionIndex].userAnswer;
    let error;

    if (id === 'zipCode') {
      const res = await this.utility.getZipCode(userAnswer)
      if (userAnswer.length > 5 || !(userAnswer.match(/^[0-9]+$/))) {
        error = 'Invalid Zip Code';
      } else if (!res || (res && res['zipCode'] === '')) {
        error = 'Invalid Zip Code';
      } else{
        error = null;
      }
      this.questions[questionIndex].error = error;
    }

    if (id === 'dateOfBirth') {
      if (userAnswer && userAnswer !== '') {
        // date validation
        if (userAnswer.length !== 10 || !(userAnswer.match(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/))) {
          error = 'Invalid date format. Format should be MM/DD/YYYY';
        } else {
          error = null;
        }
      } else if (!userAnswer || userAnswer === '') {
        error = 'Field is required';
      }

      this.questions[questionIndex].error = error;
    }
    if(error){
      this.saveButtonDisabled = true;
      return;
    }
    this.genericOnChange();
  }

  clickSave() {
    this.questions.forEach(async (question, index) => {
      let error;

      // email validation
      if (question.answerGroup?.validation &&
          question.answerGroup.validation?.isEmail &&
          question.answerGroup.validation.isEmail === true &&
          question.userAnswer &&
          !error) {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        error = !emailRegex.test(question.userAnswer) ? 'Invalid Email' : null;
      }

      // date validation
      if (question.answerGroup.answerType === 'date' &&
          question.userAnswer &&
          !(question.userAnswer.match(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/)) &&
          !error) {
        error = 'Invalid date format. Format should be MM/DD/YYYY';
      }

      // zip code validation
      if (question.answerGroup?.validation &&
          question.answerGroup.validation?.isZipcode &&
          question.answerGroup.validation.isZipcode === true &&
          question.userAnswer &&
          !error) {
        const res: any = await this.utility.getZipCode(question.userAnswer);

        error = !res || (res && res.zipCode === '') ? 'Invalid Zip Code' : null;
      }

      // currency validation
      if (question.answerGroup.validation && question.answerGroup.validation?.isCurrency &&
          question.answerGroup.validation.isCurrency === true && !(/^[0-9]*[.]?[0-9]*$/.test(question.userAnswer)) &&
          question.userAnswer &&
          !error) {
        const currencyRegex = /^[0-9]*[.]?[0-9]*$/;

        error = !currencyRegex.test(question.userAnswer)
          ? 'Field must be a valid amount'
          : null;
      }

      this.questions[index].error = error;
    });

    const errors = this.questions.filter((question) => question.error);

    if (errors.length === 0) {
      this.showLoadingOverlay = true;

      const questionResponses = [];

      this.questions.forEach((question) => {
        if (typeof question.userAnswer !== 'undefined') {
          const response = {
            answer: {
              text: question.userAnswer,
              value: question.userAnswer,
            },
            questionId: question.id,
            questionVersion: question.version,
          };

          const a = question.answerGroup.answers.find(({ value }) => value === question.userAnswer);

          if (a) {
            response.answer = a;
          } else {
            // prescription drug answer
            if (question.id === 'prescriptionDrugs') {
              response.answer = {
                text: 'drugSearch',
                value: question.userAnswer,
              };
            }

            // multi select answer
            if (question.answerGroup.answerType === 'multiSelect') {
              const selectedAnswers = question.answerGroup.answers.filter((answer) => (
                answer.isSelected && answer.isSelected === true
              )).map(({ text, value }) => ({ text, value }));

              response.answer = selectedAnswers;
            }

            // currency answer
            if (question.answerGroup?.validation?.isCurrency &&
              !!question.userAnswer.toString().match(/^\$?[0-9][0-9,]*[0-9]\.[0-9]{0,2}$/) === false) {
              response.answer = {
                text: question.id,
                value: question.userAnswer != '0' ? `${question.userAnswer}.00` : question.userAnswer,
              };
            }
          }

          questionResponses.push(response);
        }
      });

      this.assessment.respondToQuestions(this.assessmentData.id, this.assessmentData.version, questionResponses)
        .pipe(take(1))
        .subscribe(() => {
          this.profileService.hasNewData(questionResponses).then((successful) => {
            this.showLoadingOverlay = false;

            if (successful) {
              this.showSnackBar = true;
              this.saveButtonDisabled = true;
              this.initialQuestionAnswers = [];
              this.updatedAnswerFields.forEach(item =>{
                const tempField = this.questions.find(q => q.id === item);
                const question: any = tempField.label || tempField.text || "";
                let answer: any = '';
                if(Array.isArray(tempField.userAnswer)){
                  let answersArray = [];
                  tempField.userAnswer.forEach(ans =>{
                    if('text' in ans){
                      answersArray.push(ans.text);
                    }else if('form' in ans || 'name' in ans){//pres drug
                      answersArray.push(ans.name + " - " + ans.strength + " " + ans.form);
                    }
                  })
                  answer = answersArray.join(', ');
                }else{
                  if(tempField.userAnswer !== undefined || tempField.userAnswer !== null)
                      answer = tempField.userAnswer
                }
                if(typeof answer === 'boolean')
                answer = answer? 'Yes': 'No';

                this.dataLayerService.push({
                  'event': 'userProfileUpdate',
                  'fieldUpdated': item
                });
              })
              this.questions.forEach(({ userAnswer, id }) => {
                this.initialQuestionAnswers.push({
                  id,
                  userAnswer,
                });
              });

              setTimeout(() => {
                this.showSnackBar = false;
              }, 4000);
            } else {
              this.showErrorSnackBar = true;

              setTimeout(() => {
                this.showSnackBar = false;
              }, 4000);
            }
          });
        });
    } else {
      const firstErrorId = errors[0].id;

      const container = document.querySelector(`#field-${firstErrorId}`);
      if (container) {
        const interactive: any = container.querySelector('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');
        if (interactive) {
          interactive.focus();
        }
      }
    }
  }

  checkboxChange(qIndex, answer): void {
    if (this.questions[qIndex]) {
      const q = this.questions[qIndex];

      const flagNoneIndex = q.answerGroup.answers.findIndex((answer) => answer.value === 'none');
      if (flagNoneIndex !== -1) {
        // if the user selected an answer besides none
        // uncheck none
        if (answer.isSelected && answer.value !== 'none') {
          this.questions[qIndex].answerGroup.answers[flagNoneIndex].isSelected = false;
        }
        // if the user selected none
        // uncheck all except none
        if (answer.isSelected && answer.value === 'none') {
          this.questions[qIndex].answerGroup.answers.forEach((loopData, loopIndex) => {
            if (loopData.value !== 'none') {
              this.questions[qIndex].answerGroup.answers[loopIndex].isSelected = false;
            }
          });
        }
      }

      const selectedAnswers = q.answerGroup.answers.filter((answer) => (
        answer.isSelected && answer.isSelected === true
      )).map(({ text, value }) => ({ text, value }));

      this.questions[qIndex].userAnswer = selectedAnswers;
    }

    this.genericOnChange();
  }

  onPrescriptionInput(e): void {
    if (e.target.value.trim().length >= 4) {
      this.assessment.searchDrug(e.target.value)
        .then((res) => {
          this.prescriptionSelection = res;
        });
    } else if (e.target.value.trim().length === 0) {
      this.prescriptionSelection = null;
    }
  }

  clickPrescription(data): void {
    this.preSelectedPrescription = {
      form: data.form,
      frequency: null,
      name: data.name,
      ndc: data.ndc,
      quantity: null,
      strength: data.strength,
    };

    this.prescriptionSelection = null;
  }

  savePrescription(qIndex): void {
    this.preSelectedPrescription.frequency = this.prescriptionFrequency;
    this.preSelectedPrescription.quantity = this.prescriptionQuantity;

    this.prescriptionFrequency = null;
    this.prescriptionQuantity = null;

    const userAnswer = this.questions[qIndex].userAnswer;
    this.questions[qIndex].userAnswer = !userAnswer
      ? [this.preSelectedPrescription]
      : [...userAnswer, this.preSelectedPrescription];

    this.genericOnChange();

    this.preSelectedPrescription = null;
  }

  removeSelectedPrescription(qIndex, pIndex): void {
    const prescriptions = this.questions[qIndex].userAnswer;

    prescriptions.splice(pIndex, 1);

    this.questions[qIndex].userAnswer = prescriptions.length > 0
      ? prescriptions
      : null;
  }

  removePreSelectedPrescription(): void {
    this.preSelectedPrescription = null;
    this.prescriptionFrequency = null;
    this.prescriptionQuantity = null;
  }
}
