import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { KontentDeliveryService } from './kontent-delivery.service';
import { UserService } from './planner/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SeoService } from './seo.service';

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {

  medContactData: Observable<any>;
  annuityData: Observable<any>;
  private _medContactData: any;
  private _annuityData: any;
  baseUrl: string;

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private userService: UserService,
    private http: HttpClient,
    private seoService: SeoService
  ) {

    this.baseUrl = (environment as any).ncoaApiBaseUrl || '';
    this.medContactData = new Observable(observer => {
      if ( this._medContactData ) {
        observer.next(this._medContactData);
      }
      else {
        this.getMedContactData().then(data => {
          this._medContactData = data;
          observer.next(data);
        });
      }
    });

    this.annuityData = new Observable(observer => {
      if(this._annuityData){
        observer.next(this._annuityData);
      } else {
        this.getAnnuityData().then(data => {
          this._annuityData = data;
          observer.next(data);
        })
      }
      

    })
  }

  getMedContactData() {
    return this.kontentDeliveryService.getItem('medicare_contact_landing_page', {})
      .then(data => this.processMedContactData(data))
  }

  getAnnuityData() {
    return this.kontentDeliveryService.getItem('annuity_contact_landing_page', {})
      .then(data => this.processAnnuityData(data))
  }

  async processMedContactData(rawData) {
    const {
      logo,
      hero_image,
      page_header_phrase,
      page_header_title,
      page_header_body_text,
      form_title_text,
      form_instructions,
      form_required_text,
      form_optional_label,
      form_tooltip_label,
      first_name_label,
      first_name_required_error,
      interest_reason_label,
      last_name_label,
      last_name_required_error,
      email_label,
      email_required_error,
      email_invalid_error,
      zip_code_label,
      zip_code_required_error,
      zip_code_invalid_error,
      zip_code_tooltip_text,
      phone_number_label,
      phone_number_invalid_error,
      phone_number_tooltip_text,
      date_of_birth_label,
      date_of_birth_required_error,
      date_of_birth_invalid_error,
      date_of_birth_tooltip_text,
      privacy_policy_link_text,
      privacy_policy_link_url,
      form_cta_text,
      form_agreement_text,
      standards_of_excellence_link_text,
    }: any = this.kontentDeliveryService.extractItemElements(rawData.item, [
      'logo',
      'hero_image',
      'page_header_phrase',
      'page_header_title',
      'page_header_body_text',
      'form_title_text',
      'form_instructions',
      'form_required_text',
      'form_optional_label',
      'form_tooltip_label',
      'first_name_label',
      'first_name_required_error',
      'interest_reason_label',
      'last_name_label',
      'last_name_required_error',
      'email_label',
      'email_required_error',
      'email_invalid_error',
      'zip_code_label',
      'zip_code_required_error',
      'zip_code_invalid_error',
      'zip_code_tooltip_text',
      'phone_number_label',
      'phone_number_invalid_error',
      'phone_number_tooltip_text',
      'date_of_birth_label',
      'date_of_birth_required_error',
      'date_of_birth_invalid_error',
      'date_of_birth_tooltip_text',
      'privacy_policy_link_text',
      'privacy_policy_link_url',
      'form_cta_text',
      'form_agreement_text',
      'standards_of_excellence_link_text',
    ]);
    return {
      logo: logo[0] ? this.extractLogo(logo) : undefined,
      hero_image: hero_image[0] ? this.extractHeroImage(hero_image) : undefined,
      page_header_phrase,
      page_header_title,
      page_header_body_text,
      form_title_text,
      form_instructions,
      form_required_text,
      form_optional_label,
      form_tooltip_label,
      first_name_label,
      first_name_required_error,
      interest_reason_label,
      last_name_label,
      last_name_required_error,
      email_label,
      email_required_error,
      email_invalid_error,
      zip_code_label,
      zip_code_required_error,
      zip_code_invalid_error,
      zip_code_tooltip_text,
      phone_number_label,
      phone_number_invalid_error,
      phone_number_tooltip_text,
      date_of_birth_label,
      date_of_birth_required_error,
      date_of_birth_invalid_error,
      date_of_birth_tooltip_text,
      privacy_policy_link_text,
      privacy_policy_link_url,
      form_cta_text,
      form_agreement_text,
      standards_of_excellence_link_text,
    }
  }

  async processAnnuityData(rawData) {
    const {
      logo,
      hero_image,
      page_header_phrase,
      page_header_title,
      page_header_body_text,
      form_title_text,
      form_instructions,
      form_required_text,
      form_optional_label,
      form_tooltip_label,
      first_name_label,
      first_name_required_error,
      last_name_label,
      last_name_required_error,
      email_label,
      email_required_error,
      email_invalid_error,
      zip_code_label,
      zip_code_required_error,
      zip_code_invalid_error,
      zip_code_tooltip_text,
      phone_number_label,
      phone_number_invalid_error,
      text_contact_label,
      age_range_label,
      age_range_required_error,
      age_range_options,
      annuities_situation_label,
      annuities_situation_required_error,
      annuities_situation_options,
      privacy_policy_link_text,
      privacy_policy_link_url,
      form_cta_text,
      form_agreement_text,
      standards_of_excellence_link_text,
      standards_of_excellence_modal
    }: any = this.kontentDeliveryService.extractItemElements(rawData.item, [
      'logo',
      'hero_image',
      'page_header_phrase',
      'page_header_title',
      'page_header_body_text',
      'form_title_text',
      'form_instructions',
      'form_required_text',
      'form_optional_label',
      'form_tooltip_label',
      'first_name_label',
      'first_name_required_error',
      'last_name_label',
      'last_name_required_error',
      'email_label',
      'email_required_error',
      'email_invalid_error',
      'zip_code_label',
      'zip_code_required_error',
      'zip_code_invalid_error',
      'zip_code_tooltip_text',
      'phone_number_label',
      'phone_number_invalid_error',
      'text_contact_label',
      'age_range_label',
      'age_range_required_error',
      'age_range_options',
      'annuities_situation_label',
      'annuities_situation_required_error',
      'annuities_situation_options',
      'privacy_policy_link_text',
      'privacy_policy_link_url',
      'form_cta_text',
      'form_agreement_text',
      'standards_of_excellence_link_text',
      'standards_of_excellence_modal'
    ]);
    
    return {
      logo: logo[0] ? this.extractLogo(logo) : undefined,
      hero_image: hero_image[0] ? this.extractHeroImage(hero_image) : undefined,
      page_header_phrase,
      page_header_title,
      page_header_body_text,
      form_title_text,
      form_instructions,
      form_required_text,
      form_optional_label,
      form_tooltip_label,
      first_name_label,
      first_name_required_error,
      last_name_label,
      last_name_required_error,
      email_label,
      email_required_error,
      email_invalid_error,
      zip_code_label,
      zip_code_required_error,
      zip_code_invalid_error,
      zip_code_tooltip_text,
      phone_number_label,
      phone_number_invalid_error,
      text_contact_label,
      age_range_label,
      age_range_required_error,
      age_range_options: age_range_options[0] ? this.extractOptions(age_range_options) : undefined,
      annuities_situation_label,
      annuities_situation_required_error,
      annuities_situation_options: annuities_situation_options[0] ? this.extractOptions(annuities_situation_options) : undefined,
      privacy_policy_link_text,
      privacy_policy_link_url,
      form_cta_text,
      form_agreement_text,
      standards_of_excellence_link_text,
      standards_of_excellence_modal
    }
  }

  extractLogo(el) {
    return {
      url: el[0].url,
      alt: el[0].description || 'NCOA Logo',
    }
  }

  extractHeroImage(el) {
    return {
      url: el[0].url,
      name: el[0].description,
    }
  }

  extractOptions(el) {
    return {
      options: el
    }
  }

  getShipData(){
    return this.kontentDeliveryService.getItem('ship_thank_you_content', {})
      .then(data => this.processShipData(data))
  }

  async processShipData(rawData) {
    const {
      partner_body
    }: any = this.kontentDeliveryService.extractItemElements(rawData.item, [
      'partner_body'
    ]);
    return {
      partner_body: partner_body
    }
  }

  getBrokerData(broker){

    const item = broker + '_thank_you_content';
    return this.kontentDeliveryService.getItem(item, {})
      .then(data => this.processBrokerData(data))

  }

  async processBrokerData(rawData) {
    const {
      partner_logo,
      standards_of_excellence_logo,
      partner_body,
      cta_button_link,
      cta_button_text
    }: any = this.kontentDeliveryService.extractItemElements(rawData.item, [
      'partner_logo',
      'standards_of_excellence_logo',
      'partner_body',
      'cta_button_text',
      'cta_button_link'
    ]);
    return {
      partner_logo: this.extractLogo(partner_logo),
      standards_of_excellence_logo: this.extractLogo(standards_of_excellence_logo),
      partner_body: partner_body,
      cta_button_text: cta_button_text,
      cta_button_link: cta_button_link
    }
  }

  applySeoData(rawAPIData) {
    const seoData: any = {
      title: rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__meta_title.value.trim().length > 0
        ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__meta_title.value
        : 'The National Council on Aging',
    };

    seoData.ogTitle = rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value.trim().length > 0
      ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value
      : seoData.title;
    seoData.ogDescription = rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value.trim().length > 0
      ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value
      : seoData.description;
    seoData.ogImage = rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value.length > 0
      ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value[0].url
      : seoData.image;

    seoData.twitterTitle = rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value.trim().length > 0
      ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value
      : seoData.title;
    seoData.twitterDescription = rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value.trim().length > 0
      ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value
      : seoData.description;
    seoData.twitterImage = rawAPIData && rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value.length > 0
      ? rawAPIData.items[0]?.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value[0].url
      : seoData.image;

    this.seoService.buildToolsSeoData(seoData);
  }

}
