<div class="benefit-card">
  <div class="benefit-card__head">
    <div class="background" aria-hidden="true">
      <div class="pattern pattern--mobile">
        <svg width="179" height="141" viewBox="0 0 179 141" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.2">
            <path d="M89.5005 60.8681C113.048 60.7521 135.592 52.4426 152.236 37.7476C168.862 23.0685 178.246 3.21518 178.395 -17.5006L89.5004 -17.5006L89.5005 60.8681Z" stroke="#F3F6F7"/>
            <path d="M89.5004 61.5005C113.048 61.6165 135.592 69.926 152.236 84.621C168.862 99.3001 178.246 119.153 178.395 139.869L89.5004 139.869L89.5004 61.5005Z" stroke="#F3F6F7"/>
            <path d="M0.500427 140.498C24.0744 140.381 46.6444 132.006 63.3079 117.194C79.9543 102.397 89.351 82.3834 89.4987 61.4993L0.500431 61.4993L0.500427 140.498Z" stroke="#F3F6F7"/>
            <path d="M0.502229 -17.5006C0.650921 3.21512 10.0355 23.0684 26.6614 37.7475C43.3053 52.4425 65.8494 60.752 89.3968 60.868L89.3968 -17.5006L0.502229 -17.5006Z" stroke="#F3F6F7"/>
          </g>
        </svg>
      </div>
      <div class="pattern pattern--desktop">
        <svg width="726" height="246" viewBox="0 0 726 246" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.2">
            <path d="M616.5 122.342C649.074 122.21 680.285 109.213 703.328 86.1705C726.371 63.1276 739.367 31.9165 739.499 -0.657234L616.5 -0.65724L616.5 122.342Z" stroke="#F3F6F7"/>
            <path d="M616.5 122.499C649.074 122.63 680.285 135.627 703.328 158.67C726.371 181.713 739.367 212.924 739.499 245.498L616.5 245.498L616.5 122.499Z" stroke="#F3F6F7"/>
            <path d="M493.528 -0.502441C493.66 32.0713 506.657 63.2824 529.7 86.3253C552.743 109.368 583.954 122.365 616.527 122.497L616.527 -0.502438L493.528 -0.502441Z" stroke="#F3F6F7"/>
            <path d="M370.5 245.497C403.074 245.365 434.285 232.368 457.328 209.325C480.371 186.282 493.367 155.071 493.499 122.498L370.5 122.498L370.5 245.497Z" stroke="#F3F6F7"/>
            <path d="M493.5 -0.501425C460.926 -0.369728 429.715 12.627 406.672 35.6699C383.629 58.7127 370.633 89.9238 370.501 122.498L493.5 122.498L493.5 -0.501425Z" stroke="#F3F6F7"/>
            <path d="M369.581 245.498C369.449 212.924 356.453 181.713 333.41 158.67C310.367 135.627 279.156 122.63 246.582 122.499L246.582 245.498L369.581 245.498Z" stroke="#F3F6F7"/>
            <path d="M123.5 245.497C156.074 245.365 187.285 232.368 210.328 209.325C233.371 186.282 246.367 155.071 246.499 122.498L123.5 122.498L123.5 245.497Z" stroke="#F3F6F7"/>
            <path d="M246.5 122.497C213.926 122.365 182.715 109.368 159.672 86.3252C136.629 63.2824 123.633 32.0713 123.501 -0.502449L246.5 -0.502455L246.5 122.497Z" stroke="#F3F6F7"/>
            <path d="M0.501022 245.498C0.632718 212.924 13.6295 181.713 36.6723 158.67C59.7152 135.627 90.9262 122.63 123.5 122.499L123.5 245.498L0.501022 245.498Z" stroke="#F3F6F7"/>
          </g>
        </svg>
      </div>
    </div>
    <div class="main">
      <h3 class="title">{{ title }}</h3>
    </div>
  </div>
  <div class="benefit-card__body">
    <div class="eyebrow">{{ eyebrow }}</div>
    <p class="desc" [innerHTML]="desc"></p>
    <button class="cta" type="button" (click)="onClick()">
      <div><span>View Program Details</span></div>
      <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1316 1.04527L19.6842 7.74867C19.8947 7.96318 20 8.23131 20 8.49945C20 8.76758 19.8947 9.03572 19.6842 9.25023L13.1316 15.9536C12.7105 16.3826 12.0526 16.3826 11.6316 15.9536C11.2105 15.5246 11.2105 14.8543 11.6316 14.4253L16.4211 9.57199L1.05263 9.57199C0.473684 9.57199 0 9.08935 0 8.49945C0 7.90955 0.473684 7.4269 1.05263 7.4269L16.4211 7.4269L11.6579 2.54683C11.2368 2.11781 11.2368 1.44747 11.6579 1.01819C12.0526 0.615983 12.7105 0.615983 13.1316 1.04527Z" fill="#0B4A5D"/>
      </svg>
    </button>
  </div>
</div>
