import { Component, OnInit, Input, ViewEncapsulation, AfterViewInit, ViewChild } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';

@Component({
  selector: 'ncoa-three-up',
  templateUrl: './three-up.component.html',
  styleUrls: ['./three-up.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ThreeUpComponent implements OnInit, AfterViewInit {

  @Input() heading: string;
  @Input() moreLink: {
    label: string,
    url: string
  };
  @Input() cards: [any];
  @Input() isAnimationOn: boolean = false;

  @ViewChild('cardContainer') cardContainer: any;

  modalData: any;
  activeIndex: number = 0;
  animationDone: boolean = false;

  constructor(private dataLayerService: DataLayerService) { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.isAnimationOn && window.innerWidth < 1240) {
      const elToObserve = this.cardContainer.nativeElement;

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          this.runAnimation(entry.intersectionRatio);
        });
      }, { threshold: [0, 0.1, 0.9, 1] });

      observer.observe(elToObserve);
    }

    this.initializeCardLinks();
  }

  runAnimation(intersectionRatio) {
    if (intersectionRatio === 1 && !this.animationDone) {
      setTimeout(() => {
        this.cardContainer.nativeElement.scrollTo({
          left: 100,
          behavior: 'smooth',
        });

        setTimeout(() => {
          this.cardContainer.nativeElement.scrollTo({
            left: 0,
            behavior: 'smooth',
          });

          this.animationDone = true;
        }, 500);
      }, 250);
    }
  }

  trackAnalytics(ev){
    const _element = ev.currentTarget as HTMLAnchorElement;
    const _clickUrl = _element.href ||  _element.getAttribute('href');

    this.dataLayerService.push({
      'event': 'recirc',
      'url': _clickUrl,
      'componentType': '3-up' },false);
  }

  initializeCardLinks(){
    const allCards = document.querySelectorAll('.component-three-up .wrapper .card--cta a');
    const self = this;
    allCards.forEach(anchor =>{
      anchor.addEventListener('click', function(ev){
        ev.stopPropagation();
        self.trackEvent(this);
      })
    })
  }

  trackEvent( el: HTMLAnchorElement ) {
    const _clickUrl = el.href || el.getAttribute('href');

    this.dataLayerService.push({
      'event': 'recirc',
      'url': _clickUrl,
      'componentType': '3-up' },false);
  }

  showModal(index): void {
    if (this.cards[index]) {
      this.modalData = {
        title: this.cards[index].heading,
        eyebrow: this.cards[index].breadcrumb,
        desc: this.cards[index].full_desc,
        cta: {
          url: this.cards[index].program_url,
          text: 'Go to Program Website',
        },
      };
    }
  }

  onModalClose(): void {
    this.modalData = null;
  }
}
