import { Component, OnInit } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { CostEstimatorService } from '@services/planner/cost-estimator.service';
import { MyPlannerService } from '@services/planner/my-planner.service';
import { PlannerHomePageService } from '@services/planner/planner-homepage.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';

@Component({
  selector: 'planner-home-page',
  templateUrl: './planner-home-page.component.html',
  styleUrls: ['./planner-home-page.component.scss']
})
export class PlannerHomePage implements OnInit {

  data: any = null;
  siteType: string = 'awp';
  pageType: string = 'homepage';

  constructor(
    private toolsConfig: ToolsPrimaryConfigService,
    private homeService: PlannerHomePageService,
    private myPlannerService: MyPlannerService,
    private dataLayerService: DataLayerService,
    private costEstimator: CostEstimatorService,
  ) { }

  ngOnInit(): void {
    if (this.toolsConfig.currentConfig) {
      this.siteType = this.toolsConfig.singularVerticalTopic ? 'private-label' : 'awp';

      const primaryAssessment = this.toolsConfig.currentConfig.core.primaryAssessment.assessmentID;

      if (!this.toolsConfig.singularVerticalTopic) {
        if ((this.toolsConfig.customerActivity?.basic?.completedAssessments || []).includes(primaryAssessment)) {
          this.pageType = 'my-planner';
          this.myPlannerService.getData().then((data: any) => {
            this.data = data;
          });
        } else {
          this.pageType = 'homepage';
          this.homeService.getData().then((data) => {
            this.data = {
              ncoaHeaderLanding: this.toolsConfig.currentConfig.heroData,
              ...data,
            };
          });
        }
      } else {
        if ((this.toolsConfig.customerActivity?.basic?.completedAssessments || []).includes(primaryAssessment)) {
          if (primaryAssessment === 'medicareCostEstimator') {
            this.pageType = 'cost-estimator';
            // this.costEstimator.getData().then((data) => {
            //   this.data = data;
            // });
            this.homeService.getData().then((data) => {
              this.data = {
                ncoaHeaderLanding: this.toolsConfig.currentConfig.heroData,
                ...data,
              };
            });
          }
        } else {
          this.pageType = 'homepage';
          this.homeService.getData().then((data) => {
            this.data = {
              ncoaHeaderLanding: this.toolsConfig.currentConfig.heroData,
              ...data,
            };
          });
        }
      }

    // Push analytics data
    this.dataLayerService.push({
      clickUrl: location.href,
      pageTitle: document.title || '',
      contentType: 'planner-homepage-page',
      pageType: this.pageType || '',
    },false);
    }
  }
}
