import { Component, OnInit, Input } from '@angular/core';
import { rows, headers } from './inline-table.types';

@Component({
  selector: 'ncoa-inline-table',
  templateUrl: './inline-table.component.html',
  styleUrls: ['./inline-table.component.scss']
})
export class InlineTableComponent implements OnInit {
  @Input() rows: rows[];
  @Input() headers: headers[];

  constructor() { }

  ngOnInit(): void {
  }

  onScroll(event: Event) {
    event.stopPropagation()

    const elem = event.currentTarget as HTMLElement;
    const wrapper = document.querySelector('.inline-table__wrapper')

    if (elem.offsetWidth + elem.scrollLeft >= elem.scrollWidth) {
      wrapper.classList.add('end');
      wrapper.classList.remove('both');
    } else {
      wrapper.classList.remove('end');
      wrapper.classList.add('both');
    }

    if (elem.scrollLeft == 0) {
      wrapper.classList.remove('both');
    }
  }
}
