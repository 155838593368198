<div class="component component--awp-partners" *ngIf="images.length > 0">
    <div class="wrapper">
      <h2 class="partner--heading">{{ heading }}</h2>
      <ul class="partner">
        <li class="partner--logo" *ngFor="let image of images">
          <div class="partner--logo__thumb">
            <span>
              <img [src]="image.url" />
            </span>
          </div>
          <!-- <div class="partner--logo__thumb frame" [ngStyle]="{ 'background-image': 'url(' + image.url + ')'}"></div> -->
        </li>
        <li class="partner--blurb">
            <div class="blurb" [innerHtml]="blurb"></div>
        </li>
      </ul>
    </div>
</div>
  