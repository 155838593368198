<ncoa-template-default class="article-page">
  <ng-container *ngFor="let component of components; index as order">
    <ncoa-article-header
      *ngIf="component.componentType === 'articleHero'"
      [image]="component.image"
      [eyebrow]="component.eyebrow"
      [articleTitle]="component.articleTitle"
      [publishDate]="component.publishDate"
      [readingTime]="component.readingTime"
      [sponsorship_indication_text]="component.sponsorship_indication_text"
      [partnership]="component.partnership"
      ncoa-engageable="{{ order }}"
      [width]="component?.image?.width"
      [height]="component?.image?.height"
      [takeaways] = "component?.takeawaysData"
      [authors] = "component?.authors"
      [url]="component.url"
    ></ncoa-article-header>
    <ncoa-event-hero
      *ngIf="component.componentType === 'eventHero'"
      [ngClass]="{ 'has-partnership' : component.partnership }"
      [image]="component.image"
      [title]="component.title"
      [event_start]="component.start"
      [event_end]="component.end"
      [isPast]="component.isPast"
      [disregardEndTime]="component.disregardEndTime"
      [registration_link]="component.registration_link"
      [sponsorship_indication_text]="component.sponsorship_indication_text"
      [partnership]="component.partnership"
      ncoa-engageable="{{ order }}"
      [width]="component?.image?.width"
      [height]="component?.image?.height"
    ></ncoa-event-hero>

    <app-author-byline 
      *ngIf="component.componentType === 'authorByLine' && component.authors.length && bodyStyle !=='v2' "
      [authors]="component.authors"
      [variant]="component.variant"
      ncoa-engageable="{{ order }}"
    ></app-author-byline>

    <ncoa-article-body
      *ngIf="component.componentType === 'body'"
      [content]="component.content"
      [styleConfig]="component.styleConfig"
      [url]="component.url"
      [readingTime]="component.readingTime"
      (updateReadingTime)="updateReadingTime($event)"
      [requiredFieldError]="component.requiredFieldError"
      [styleVersion]="bodyStyle"
      ncoa-engageable="{{ order }}"
    >
      <app-takeaways
        *ngIf="takeaways  &&  bodyStyle !=='v2'"
        [heading]="takeaways.heading"
        [takeaways]="takeaways.takeaways"
        ncoa-engageable="{{ order }}"
      ></app-takeaways>
    </ncoa-article-body>

    <ncoa-pdf-viewer
      *ngIf="component.componentType === 'pdfViewer'"
      [pdfData]="component.pdfData"
      ncoa-engageable="{{ order }}"
    ></ncoa-pdf-viewer>

    <app-author-byline
      *ngIf="component.componentType === 'secondaryAuthorByLine'"
      [authors]="component.authors"
      [variant]="component.variant"
      ncoa-engageable="{{ order }}"
    ></app-author-byline>

    <app-partners
      *ngIf="component.componentType === 'partners_grid'"
      [heading]="component.heading"
      [partners]="component.partners"
      ncoa-engageable="{{ order }}"
    ></app-partners>

    <ncoa-article-feedback
      *ngIf="component.componentType === 'articleFeedback'"
      ncoa-engageable="{{ order }}"
      [votes]="component.votes"
      [article]="component.article"
      [slug]="component.slug"
      [id]="component.id"
    ></ncoa-article-feedback>

    <ncoa-article-share-mobile
      *ngIf="component.componentType === 'articleShareMobile'"
      [url]="component.url"
      ncoa-engageable="{{ order }}"
    ></ncoa-article-share-mobile>

    <ncoa-related-articles
      *ngIf="component.componentType === 'relatedArticles' && component.entries.length"
      [entries]="component.entries"
      ncoa-engageable="{{ order }}"
    ></ncoa-related-articles>

    <ncoa-feature-page-block
      *ngIf="component.componentType === 'featuredPageBlock'"
      [image]="component.image"
      [headline]="component.headline"
      [description]="component.description"
      [type]="component.type"
      [background]="component.background"
      [audiences]="component.audiences"
      [url]="component.url"
      [orientation]="component.orientation"
      [blockCTA]="component.blockCTA"
      ncoa-engageable="{{order}}"
    ></ncoa-feature-page-block>

    <ncoa-medicare-lead-gen
      *ngIf="component.componentType === 'medicareLeadGen'"
      [awp]="false"
      [eyebrow]="component.eyebrow"
      [headline]="component.headline"
      [image]="component.image"
      [branding]="component.branding"
      [data]="component.form"
    ></ncoa-medicare-lead-gen>

    <!-- Ending Modules -->
    <ncoa-newsletter
      *ngIf="component.componentType === componentTypes.newsletter_signup_block"
      [heading]="component.heading"
      [description]="component.description"
      [background]="component.background"
      [mobilebackground]="component.mobilebackground"
      [backgroundCaption]="component.backgroundCaption"
      [isError]="component.isError"
      [isSubmitted]="component.isSubmitted"
      [width]="component.width"
      [height]="component.height"
      ncoa-engageable="{{ order }}"
      [everyActionFormUrl]="component.everyActionFormUrl"
      [fieldErrorText]="component.fieldErrorText"
      [emailErrorText]="component.emailErrorText" 
    ></ncoa-newsletter>

    <ncoa-donate
      *ngIf="component.componentType === 'callToDonate'"
      [heading]="component.data.heading"
      [description]="component.data.description"
      [form_desc]="component.data.form_desc"
      [background]="component.data.photo"
      [backgroundCaption]="component.data.caption"
      ncoa-engageable="{{order}}"
    ></ncoa-donate>

      <ncoa-external-form  
      *ngIf="component.componentType === 'component__form'"
       [component]="component" 
      ncoa-engageable="{{ order }}" >
    </ncoa-external-form>
  </ng-container>

</ncoa-template-default>
