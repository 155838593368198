import { Component, Input, OnInit } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { DataService } from '@services/planner/data.service';

@Component({
  selector: 'ncoa-vertical-landing-header',
  templateUrl: './vertical-landing-header.component.html',
  styleUrls: ['./vertical-landing-header.component.scss'],
})
export class VerticalLandingHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() desc: string;
  @Input() image: {
    sizes: {
      media: string;
      url: string;
    }[];
    src: string;
    alt: string;
  };
  @Input() menu: {
    url: string;
    text: string;
    isActive: boolean;
  };
  @Input() assessmentCTA: {
    url: string;
    text: string;
  };

  @Input() planSteps: any[];

  constructor(
    private dataService: DataService,
    private dataLayerService: DataLayerService,
  ) { 
    this.planSteps = [];
  }


  trackAnalytics(ev){
    const _element = ev.currentTarget as HTMLAnchorElement;
    const _clickUrl = _element.href || _element.getAttribute('href');

    this.dataLayerService.push({ 
      'event': 'recirc', 
      'url': _clickUrl,
      'componentType': 'header' },false); 
  }
  
  ngOnInit(): void {
    this.dataService.changeHeaderMenus(this.menu);
  }
}
