import { Component, OnInit, Input } from '@angular/core';
import { SubTopicService } from '@services/planner/subtopic.service';

@Component({
  selector: 'ncoa-subtopic-learn',
  templateUrl: './subtopic-learn.component.html',
  styleUrls: ['./subtopic-learn.component.scss']
})
export class SubtopicLearnComponent implements OnInit {

  pageID: string = null;
  data: {
    subtopicHeader: any;
    promoTop: any;
    toolCard1up: any;
    articleCardGrouping: any;
    trustedExperts: any;
    trustedExpertPromo: any;
    benefitCard3Up: any;
    singleColCardList: any;
    verticalPath: any;
    leadGen: any;
  } = null;

  constructor(
    private subTopicService: SubTopicService,
  ) {
    this.subTopicService.getData().then((data: any) => {
      this.pageID = data.id;
      this.data = {
        subtopicHeader: data?.subtopicHeader,
        promoTop: data?.promoTop,
        toolCard1up: data?.toolCard1up,
        articleCardGrouping: data?.articleCardGrouping,
        trustedExperts: data?.trustedExperts,
        trustedExpertPromo: data?.trustedExpertPromo,
        benefitCard3Up: data?.benefitCard3Up,
        singleColCardList: data?.singleColCardList,
        verticalPath: data?.verticalPath,
        leadGen: data?.leadGen,
      };
    });
  }

  ngOnInit(): void { }

}
