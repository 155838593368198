import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FocusTrapService {

  focusableElementsSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
  tabKeycode = 9;

  container: any;
  lastFocusedElement: any;
  containerFirstFocusable: any;
  containerLastFocusable: any;

  constructor() {}

  trapFocus = (containerElement, lastFocused) => {
    this.container = containerElement;
    this.lastFocusedElement = lastFocused;

    const containerFocusable = this.container.querySelectorAll(this.focusableElementsSelector);

    // set first and last focusable elements
    this.containerFirstFocusable = containerFocusable[ 0 ];
    this.containerLastFocusable = containerFocusable[ containerFocusable.length - 1 ];

    // give focus to first focusable element
    this.containerFirstFocusable.focus();

    this.container.addEventListener('keydown', this.loopFocus);
  };

  unTrapFocus = () => {
    this.container.removeEventListener('keydown', this.loopFocus);
    this.lastFocusedElement.focus();
  };

  loopFocus = (event) => {
    if (event.keyCode === 9) {
      if (! event.shiftKey && document.activeElement === this.containerLastFocusable) {
        event.preventDefault();
        this.containerFirstFocusable.focus();
      } else if (event.shiftKey && document.activeElement === this.containerFirstFocusable) {
        event.preventDefault();
        this.containerLastFocusable.focus();
      }
    }
  }
}
