import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ComponentService } from '@services/component.service';
import { GetInvolvedService } from '@services/get-involved.service';
import { GlobalService } from '@services/global.service';

@Component({
  selector: 'app-get-involved',
  templateUrl: './get-involved.component.html',
  styleUrls: ['./get-involved.component.css']
})
export class GetInvolvedComponent implements OnInit {
  components: (any)[];
  componentTypes: any;

  constructor(
    private getInvolvedService: GetInvolvedService,
    private componentService: ComponentService,
    private activeRoute: ActivatedRoute,
    private globalService: GlobalService
  ) {
    this.componentTypes = this.componentService.componentTypes;
  }

  ngOnInit() {
    this.activeRoute.data.subscribe(({ pageData }: any) => {
      this.components = [
        { componentType: 'header', ...pageData[0].item },
        { componentType: 'specialPathing', specialPaths: pageData[0].special_pathing },
        ...pageData[0].components
      ];

      this.globalService.loadFooter( this.components.length > 0 ? true : false );
    });
  }

}
