<article class="result-card">
  <div class="result-card__content">
    <a
      class="result-card__image"
      [ngClass]="type.codename === 'author' ? 'result-card__image--author' : ''"
      href="{{ cta.url }}"
      [attr.aria-label]="title"
      [target]="cta.newTab ? '_blank' : '_self'"
    >
      <img *ngIf="image && image.url; else elseBlock" src="{{ image.url }}" alt="{{ image.alt }}">
      <ng-template #elseBlock>
        <img *ngIf="type.codename === 'article'" src="../../../assets/images/content-package-coral-tablet.png" />
        <img *ngIf="type.codename === 'event'" src="../../../assets/images/content-package-green-tablet.png" />
        <img *ngIf="type.codename === 'topic' || type.codename === 'author'" src="../../../assets/images/content-package-midnight-tablet.png" />
      </ng-template>
    </a>
    <div class="result-card__body">
      <div class="result-card__eyebrow">
        <span *ngFor="let label of type.name"
        [ngClass]="getCardClass(label, type)">
        {{ label }}</span>
  

        <span class="result-card__eyebrow-text"
          *ngIf="type.codename === 'article' || type.codename === 'event'"
        >{{ eyebrow }}</span>
       
      </div>
      <div class="result-card__details">
        <div class="left-col">
          <h3 class="result-card__title">
            <a href="{{ cta.url }}" [target]="cta.newTab ? '_blank' : '_self'">{{ title }}</a>
          </h3>
          <p *ngIf="description" class="result-card__description">{{ description }}</p>
        </div>
        <div class="right-col">
          <a href="{{ cta.url }}" [target]="cta.newTab ? '_blank' : '_self'" class="result-card__cta">{{ cta && cta.text ? cta.text : 'Explore More' }}</a>
        </div>
      </div>
    </div>
  </div>
</article>
