<div *ngIf="!data" class="loading-page">
  <img src="../../../../assets/images/loader.gif" alt="Loading" />
</div>

<age-well-planner *ngIf="data">
  <div class="subtopic subtopic--tools">
    <ncoa-subtopic-header
      *ngIf="data.subtopicHeader"
      [heading]="data.subtopicHeader.heading"
      [photo]="data.subtopicHeader.photo"
      [menu]="data.subtopicHeader.menu"
      [backCTA]="data.subtopicHeader.backCTA"
    ></ncoa-subtopic-header>
    <div id = "main-content">
      <ncoa-one-up
        *ngIf="data.toolCards && data.toolCards.cards.length === 1"
        [type]="'tool'"
        [heading]="data.toolCards.heading"
        [card]="data.toolCards.cards[0]"
      ></ncoa-one-up>

      <ncoa-single-col-card
        *ngIf="data.toolCards && data.toolCards.cards.length > 1"
        [heading]="data.toolCards.heading"
        [cards]="data.toolCards.cards"
      ></ncoa-single-col-card>

      <ncoa-one-up
        *ngIf="data.trustedExperts && data.trustedExperts.cards.length === 1"
        [type]="data.trustedExperts.type"
        [heading]="data.trustedExperts.heading"
        [card]="data.trustedExperts.cards[0]"
      ></ncoa-one-up>

      <ncoa-text-with-image
        *ngIf="data.trustedExpertPromo && data.trustedExperts && data.trustedExperts.cards.length > 1"
        [alignment]="data.trustedExpertPromo.alignment"
        [heading]="data.trustedExpertPromo.heading"
        [intro]="data.trustedExpertPromo.intro"
        [image]="data.trustedExpertPromo.image"
        [cta]="data.trustedExpertPromo.cta"
      ></ncoa-text-with-image>

      <ncoa-three-up
        *ngIf="data.resource3Up && data.resource3Up.cards.length === 3"
        [heading]="data.resource3Up.heading"
        [cards]="data.resource3Up.cards"
        [moreLink]="data.resource3Up.moreLink"
      ></ncoa-three-up>

      <ncoa-topic-listing
        *ngIf="data.singleColCardList"
        [heading]="data.singleColCardList.heading"
        [topics]="data.singleColCardList.topics"
      ></ncoa-topic-listing>
    </div>
  </div>
</age-well-planner>
