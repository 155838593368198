import { Component, Input, OnInit } from '@angular/core';
import { AbstractQuestion } from 'src/app/typings/questions/AbstractQuestion';

@Component({
  selector: 'app-question-label',
  templateUrl: './question-label.component.html',
  styleUrls: ['./question-label.component.scss']
})
export class QuestionLabelComponent implements OnInit {

  @Input() question: AbstractQuestion<any>
  @Input() kenticoKontent: any;
  private _lang: string;
  public get lang(): string {
    return this._lang;
  }

  @Input()
  public set lang(value: string) {
    this._lang = value;
    if(this.question){
      this.labelContent = this.renderQuestionLabel(this.question.label) as any;
    }
  }

  public labelContent: {type: string, text: string, definition: string}[];

  //public showHint: boolean;

  constructor() {
    //this.showHint = false;
  }

  ngOnInit(): void {
      this.labelContent = this.renderQuestionLabel(this.question.label) as any;
  }

  public renderQuestionLabel(label: string){

    if(this.question.definitions && Object.keys(this.question.definitions).length > 0){

      return (label[this.lang] || label['en']).split(/(\{.*?\})/g).map((token) => {

        if(token.startsWith('{') && token.endsWith('}')){

          const stripped = token.replace(/[\{\}]/g, '');
          const def = this.question.definitions[stripped];
          return {type: 'definition', text: def.text[this.lang], definition: def.definition[this.lang]}
        }

        return {type: 'text', text: token}
      });
    }

    return [{type: 'text', text: label[this.lang] || label['en']}];
  }

}
