import { Component, Input } from '@angular/core';

@Component({
  selector: 'awp-partner-grid',
  templateUrl: './partner-grid.component.html',
  styleUrls: ['./partner-grid.component.scss']
})
export class AWPPartnerGridComponent {
  @Input() heading: string;
  @Input() images: any[];
  @Input() blurb: string;
}
