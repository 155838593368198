<div class="component component-save-content">
  <div class="wrapper">
   <div class="save-content">
     <div class="save-content__text">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56C43.464 56 56 43.464 56 28Z" fill="#DAE4E7"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M28.3992 16.001C31.6138 16.001 34.2242 18.6087 34.2242 21.82C34.2242 25.0314 31.6222 27.6391 28.3992 27.6391C25.1845 27.6391 22.5742 25.0314 22.5742 21.82C22.5742 18.6087 25.1845 16.001 28.3992 16.001Z" fill="#0B4A5D"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M28.3985 28.6616C32.8637 28.6616 36.6911 31.8562 38.2774 36.384C35.7175 38.8492 32.2342 40.3668 28.3985 40.3668C24.5627 40.3668 21.0795 38.8492 18.5195 36.3757C20.1059 31.8562 23.9332 28.6616 28.3985 28.6616Z" fill="#0B4A5D"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M41.4672 15.6318C44.1027 15.6318 46.243 17.77 46.243 20.4028C46.243 23.0356 44.1027 25.1738 41.4672 25.1738C38.8317 25.1738 36.6914 23.0356 36.6914 20.4028C36.6914 17.77 38.8233 15.6318 41.4672 15.6318Z" fill="#F3F6F7"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3305 15.6318C17.966 15.6318 20.1063 17.77 20.1063 20.4028C20.1063 23.0356 17.966 25.1738 15.3305 25.1738C12.695 25.1738 10.5547 23.0356 10.5547 20.4028C10.5547 17.77 12.695 15.6318 15.3305 15.6318Z" fill="#F3F6F7"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6382 36.3507L17.7221 36.1075C18.5699 33.7011 19.9967 31.6552 21.8516 30.1711C23.1526 29.1397 24.6047 28.4354 26.1322 28.0833C24.9824 27.664 23.9752 26.9429 23.2198 26.0122H15.2965C10.8396 26.0122 7.23047 29.6177 7.23047 34.07V36.3423H17.6382V36.3507Z" fill="#F3F6F7"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M39.0669 36.1074L39.1509 36.3506H49.5586V34.0783C49.5586 29.626 45.9495 26.0205 41.4926 26.0205H33.5609C32.8055 26.9512 31.7983 27.6723 30.6484 28.0916C32.176 28.4437 33.6281 29.148 34.929 30.1794C36.8007 31.6467 38.2276 33.701 39.0669 36.1074Z" fill="#F3F6F7"/>
      </svg>     
      
      <h3>{{ heading }}</h3>
      <p>{{ intro }}</p>
     </div>
     <div class="save-content__buttons">
       <a [href]="create.url" class="btn-create" role="button" *ngIf="create">{{ create.label }}</a>
       <span class="btn-spacer" *ngIf="login">or</span>
       <a [href]="login.url" class="btn-login" role="button" *ngIf="login">{{ login.label }}</a>
     </div>
   </div>
  </div>
</div>