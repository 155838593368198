<ncoa-assessment-summary *ngIf="(isLoaded && pageData && data) && isCompleted && currentAssessmentID == 'retirementIncomeBreakdown'"></ncoa-assessment-summary>
<ncoa-cost-estimator *ngIf="(isLoaded && pageData && data) && isCompleted && currentAssessmentID == 'medicareCostEstimator'"></ncoa-cost-estimator>
<ncoa-falls-risk-summary *ngIf="(isLoaded && pageData && data) && isCompleted && currentAssessmentID == 'fallsRiskAssessment' && !edit" [fullAssessmentData]="data"></ncoa-falls-risk-summary>

<app-screening *ngIf="isLoaded && data && screeningCode && !assessmentID" [backCTA]="backCTA" [screening]="data"></app-screening>

<age-well-planner-assessment *ngIf="isLoaded && pageData && data && (!isCompleted || edit )&& !screeningCode">
  <app-banner [richTextObject]="data.bannerSubtext"></app-banner>
  <div class="age-well-planner-assessment">
    <tools-assessment
      [assessmentID]="assessmentID"
      [version]="version"
      [fullData]="data"
      [data]="data"
      [backCTA]="backCTA"
      (toggleLoadingOverlay)="toggleLoadingOverlay($event)"
      (onFinish)="onFinish($event)"
    ></tools-assessment>

    <div class="loading-overlay" *ngIf="showLoadingOverlay">
      <img src="../../../../assets/images/loader.gif" alt="Loading" />
    </div>
  </div>
</age-well-planner-assessment>

<div *ngIf="!isLoaded || !pageData || !data" class="loading-page">
  <img src="../../../../assets/images/loader.gif" alt="Loading" />
</div>
