import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { GlobalService } from '@services/global.service';
import { ComponentService } from '@services/component.service';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { ErrorService } from '@services/error.service';

@Component({
  selector: 'ncoa-taxonomy',
  templateUrl: './taxonomy.component.html',
  styleUrls: ['./taxonomy.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TaxonomyComponent implements OnInit, AfterViewInit {
  description: string;
  components: (any)[];
  componentTypes: any;
  customSearchData: any;
  placeholder: string;
  theme: 'string';

  isInToolsDomain: boolean = false;
  toolsData: any = null;
  toolsContentType: string = null;

  partnerData: any;

  errorPage: boolean = false;

  //
  coreType: string = '';
  //

  constructor(
    private activeRoute: ActivatedRoute,
    private globalService: GlobalService,
    private componentService: ComponentService,
    private kontentDeliveryService: KontentDeliveryService,
    private errorService: ErrorService,
    private router: Router,
  ) {
    this.componentTypes = this.componentService.componentTypes;
  }

  ngOnInit(): void {
    this.activeRoute.data.subscribe(({ taxonomyData }: any) => {
      if (!taxonomyData) {
        this.errorService.notFound();
      }

      this.coreType = taxonomyData?.coreType ?? '';

      switch (this.coreType) {
        case 'ncoa-landing-page':
        case 'taxonomy-page':
        case 'special-page': {
          this.components = taxonomyData.components;
          break;
        }

        case 'category-search-page': {
          this.customSearchData = taxonomyData.customSearchData;
          break;
        }

        case 'partner': {
          this.partnerData = taxonomyData.data;
          break;
        }

        case 'age-well-planner': {
          this.toolsData = taxonomyData.data;
          this.toolsContentType = taxonomyData?.data?.core?.route?.type ?? null;

          if(this.toolsContentType === 'resource') {
            const path = window.location.pathname.split('/');
            const routeIndex = path.indexOf('resource') + 1;
            const slug = window.location.pathname.split('/')[routeIndex];
            if(slug)
              this.router.navigate(['article', slug]);
          }
          break;
        }
      }
    });
  }

  ngAfterViewInit(): void {
    // load the footer after initializing the component's view
    this.globalService.loadFooter(!!(this.components || []).length);
  }

  useTaxonomyData({ components }) {
    this.components = components;
  }

  loadFooterData() {
    this.kontentDeliveryService.getItem(null, {
      'system.type': 'standard_page',
      'elements.url': '404',
      limit: 1,
    }).then(data => {
        this.components = [{ componentType: 'header', ...data?.items[0].elements }];
        this.globalService.loadFooter(true);
      });
  }
}
