import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges  } from '@angular/core';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
@Component({
  selector: 'ncoa-saved-to-planner-header',
  templateUrl: './saved-to-planner-header.component.html',
  styleUrls: ['./saved-to-planner-header.component.scss']
})
export class SavedToPlannerHeaderComponent implements OnInit {

  @Input() selectedType: string = 'all';
  @Input() selectedVertical: string = 'all';

  @Output() updateSelectedType: EventEmitter<any> = new EventEmitter();
  @Output() updateSelectedVertical: EventEmitter<any> = new EventEmitter();

  backCTA: {
    url: string;
    text: string;
  };
  isFilterOpen: boolean = false;
  verticals: any[];
  allVerticals: string = 'View All';

  constructor(
    private tools: ToolsPrimaryConfigService
  ) {
    this.verticals = this.tools.awpTaxonomyCatalog.map(({ codename: value, name: text }) => ({ text, value }));
    this.backCTA = {
      url: this.tools.routes.find((route) => route.type === 'home').route,
      text: 'Back',
    };
  }

  ngOnInit(): void {
    this.onResize();
  }

  toggleFilter(): void {
    this.isFilterOpen = !this.isFilterOpen;
  }

  onResize(): void {
    if (window.innerWidth < 1240) {
      this.isFilterOpen = false;
      this.allVerticals = 'View All';
    } else {
      this.isFilterOpen = true;
      this.allVerticals = 'All Verticals';
    }
  }

  onSubmit(): void {
    this.updateSelectedType.emit(this.selectedType);
    this.updateSelectedVertical.emit(this.selectedVertical);

    this.isFilterOpen = false;
  }

  goBack(e){
    if(!document.referrer){
      const lastUrl = window.sessionStorage.getItem("last_url");
      const lastUrlIndex = window.sessionStorage.getItem("last_url_index");
      if((history.length - 1) === parseInt(lastUrlIndex)){
        window.location.href = lastUrl;
      }else{
        window.location.href = this.backCTA.url;
      }
    }else{
      if (document.referrer.indexOf(window.location.host) !== -1){
        e.preventDefault();
        history.back();
      }else{
        window.location.href = this.backCTA.url;
      }
    }
  }


  handleTypeChange(): void {
    if (window.innerWidth >= 1240) {
    this.updateSelectedType.emit(this.selectedType);
    }
  }

  handleVerticalChange(): void {
    if (window.innerWidth >= 1240) {
      this.updateSelectedVertical.emit(this.selectedVertical);
    }
  }
}
