import { Component, OnInit, Input, ViewEncapsulation, OnChanges } from '@angular/core';
import { TakeawaysType } from './takeaways.types';

@Component({
  selector: 'app-takeaways',
  templateUrl: './takeaways.component.html',
  styleUrls: ['./takeaways.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TakeawaysComponent implements OnInit {

  @Input() heading: string;
  @Input() takeaways: TakeawaysType[];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes) {
    if ( 'takeaways' in changes ) {
      // Ensure all items are not empty text
      this.takeaways = changes.takeaways.currentValue.filter(takeaway => {
        const div = document.createElement('DIV');
        div.innerHTML = takeaway.text;

        return div.textContent.trim() ? true : false;
      });
    }
  }

}
