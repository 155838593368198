import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserPromptService } from '@services/planner/user-prompt.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ncoa-user-prompt-modal',
  templateUrl: './user-prompt-modal-component.component.html',
  styleUrls: ['./user-prompt-modal-component.component.scss']
})
export class UserPromptModalComponent implements OnInit {
  private _title: string;
  @Input() isOpen: boolean = false;
  private isModalOpen: boolean = false;
  modalSubscription: Subscription;

  @Input() title: string;

  @Input() desc?;
  @Input() imageUrl?;

  @Input() cta?: {
    first: {
      label: string,
      url: string,
    },
    second: {
      label: string,
      url: string,
    }
  } = {
    first: {
      label: '',
      url: ''
    },
    second: {
      label: '',
      url: ''
    }
  };

  scrollY: any = 0;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private userPromptService: UserPromptService,
    private tools: ToolsPrimaryConfigService) {

      this.title = this.tools.rawConfig.elements['prompt_headline'].value;
      this.desc = this.tools.rawConfig.elements['prompt_desc'].value;
      this.cta.first.label = this.tools.rawConfig.elements['sign_in_cta_text'].value;

      this.cta.first.url = this.tools.routes.find((route) => route.type === 'sign-in').route
      const currentLocation = window.location.pathname !== this.tools.rootURL
        ? `?url=${window.location.href}` : '';
      this.cta.first.url = `${this.cta.first.url}${currentLocation}`;

      this.cta.second.label = this.tools.rawConfig.elements['sign_up_cta_text'].value;
      this.cta.second.url = this.tools.routes.find((route) => route.type === 'sign-up').route
      this.imageUrl = this.tools.rawConfig.elements['modal_image'].value[0].url;

      this.userPromptService.isModalOpenObservable.subscribe((isOpen) =>{
        this.isOpen = isOpen;
        if(isOpen) this.openModal();
      });


  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.renderer.setAttribute(this.document.body, 'style', '');
    this.modalSubscription?.unsubscribe();
  }

  openModal() {
      this.scrollY = window.scrollY;
      this.renderer.setAttribute(this.document.body, 'style', `overflow-y: hidden; position: fixed; width: 100%; top: -${this.scrollY}px`);
  }

  closeModal() {
    this.renderer.setAttribute(this.document.body, 'style', '');
    window.scrollTo({ top: this.scrollY });
    this.userPromptService.isModalOpen(false);
  }
}
