import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Logo } from '@components/global-nav/global-nav.types';
import { image } from '@components/standard-page-header/standard-page-header.types';
import { LandingPageService } from '@services/landing-page.service';
import { UtilityService } from '@services/planner/utility.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { zipCodeValidator } from '../../../validators/zip-validator';
import { ErrorMessageProcessor } from 'src/app/validators/error-message-processor';
import { debounceTime } from 'rxjs/operators';
import { dateIsValid, maxAge, minAge } from 'src/app/validators/date-validator';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-medicare-contact',
  templateUrl: './medicare-contact.component.html',
  styleUrls: ['./medicare-contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MedicareContactComponent implements OnInit, AfterViewInit {

  contactForm: FormGroup;
  hasLoadedContent: BehaviorSubject<boolean>;

  @Input('awp') awp: boolean = false;

  @ViewChild('headerBodyText') headerBodyText: ElementRef;

  data: any;
  activeElementTooltip: Element;
  errorMessageProcessor: ErrorMessageProcessor;
  errorMessages: any;

  open: string;
  heading: string;
  description: string;
  soe_data: any;
  backgroundImage: {
    src: string,
    caption: string
  };
  cta: {
    url: string,
    text: string
  };
  disableSubmit = false;

  constructor(
    private landingService: LandingPageService,
    private cas: CustomerActivityService,
    private fb: FormBuilder,
    private modalService: ModalService,
    private router: Router,
    private elementRef: ElementRef,
    private utilService: UtilityService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) { }

  ngOnInit(): void {
    localStorage.setItem('broker', 'withoutBroker');
    this.createForm();
    this.hasLoadedContent = new BehaviorSubject(false);
    this.landingService.medContactData.subscribe(data => {
      this.mapKontent(data);
      this.hasLoadedContent.next(true);
    });

    // universal pixel for med landing page
    let script = this._renderer2.createElement('script');
    script.text = `
      !function(s,a,e,v,n,t,z){if(s.saq)return;n=s.saq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!s._saq)s._saq=n;n.push=n;n.loaded=!0;n.version='1.0';n.queue=[];t=a.createElement(e);t.async=!0;t.src=v;z=a.getElementsByTagName(e)[0];z.parentNode.insertBefore(t,z)}(window,document,'script','https://tags.srv.stackadapt.com/events.js');saq('ts', 'KR5YuuHCYuC-n-B-Nmv-HQ');
    `;
    this._renderer2.appendChild(this._document.head, script);
  }

  ngAfterViewInit(): void {
    this.hasLoadedContent.subscribe(res => {
      if (res) {
        // bind event listener to SoE link in header
        this.elementRef.nativeElement.querySelector('.soe-header-link').addEventListener("click", () => {
          this.soeModal();
        });
      }
    })
  }

  createForm() {
    this.contactForm = this.fb.group({
      firstName: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
      lastName: ['', {
        validators: [Validators.required],
        updateOn: 'blur',
      }],
      email: ['', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur',
      }],
      zipCode: this.fb.control('', [Validators.required], zipCodeValidator(this.utilService)),
      // zipCode: ['', {
      //   validators: [Validators.required, zipCodeValidator(this.utilService)],
      //   updateOn: 'blur',
      // }],
      phoneNumber: ['', {
        updateOn: 'blur',
      }],
      dateOfBirth: ['', {
        validators: [Validators.required, minAge(18), maxAge(100), dateIsValid],
        updateOn: 'blur',
      }],
      medicareInterestReason: ['', {
        updateOn: 'blur'
      }]
    });

    const validationMessages = {
      zipCode: {
        required: 'Required',
        invalidZip: 'Invalid Zip Code'
      },
      dateOfBirth: {
        minAge: `Invalid date format or value. Format should be MM/DD/YYYY and fall within age range of ${18} to ${100}.`,
        maxAge: `Invalid date format or value. Format should be MM/DD/YYYY and fall within age range of ${18} to ${100}.`,
        invalidDate: `Invalid date format or value. Format should be MM/DD/YYYY and fall within age range of ${18} to ${100}.`
      }
    };

    this.errorMessageProcessor = new ErrorMessageProcessor(validationMessages);
    this.errorMessages = this.errorMessageProcessor.processMessages(this.contactForm);

    this.contactForm.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.errorMessages = this.errorMessageProcessor.processMessages(this.contactForm);
    });
  }

  onSubmit() {
    this.disableSubmit = true;
    if (this.contactForm.valid) {
      this.cas.announceMedicareContact(this.contactForm.value, this.awp, window.location).subscribe(res => {
        // console.log("response from NCOA API: ", res);
        if (res && res.status === "received") {
          // this.sentToMCG = true;
          // this.noBroker = false;
          localStorage.setItem('broker', 'true');
          localStorage.setItem('broker_name', 'mcg_broker');
          window.location.href = '/age-well-planner/pages/thanks';
        } else {
          localStorage.setItem('broker', 'false');
          localStorage.setItem('broker_name', '');
          window.location.href = '/age-well-planner/pages/thanks';
          // console.log('no mcg coverage')
          // this.sentToMCG = false;
          // this.noBroker = true;
        }
      }, err => {
        // console.log('error', err)
        // this.sentToMCG = false;
        // this.noBroker = true;
      });
    } else {
      this.contactForm.markAllAsTouched();
      this.contactForm.updateValueAndValidity();
      this.disableSubmit = false;
    }
  }

  soeModal(){
    this.modalService.getModalData('standards_of_excellence_modal_9b046ba')
    .then(data => {
      this.heading = data.heading;
      this.description = data.description;
      this.backgroundImage = data.backgroundImage;
      this.soe_data = data.soeData;
    })
    .then(() => {
      this.open = 'true';
      this.modalService.open('soe_modal');
    });
  }

  openTooltip( event: Event ): void {
    let _currentTarget = event.currentTarget as Element | any;
    _currentTarget.classList.add('is-open');
    this.activeElementTooltip = _currentTarget;
    this.fillCurrentTooltip(true);
    event.stopPropagation();
  }

  fillCurrentTooltip(toFill) {
    if (toFill) {
      let _temp = document.querySelectorAll(`icon-is-active`);
      _temp.forEach(i => {
        i.classList.remove('icon-is-active');
      });
      this.activeElementTooltip.classList.add('icon-is-active');
    }
    else {
      this.activeElementTooltip.classList.remove('icon-is-active');
    }
  }

  closeTooltip( event: Event ): void {
    let _currentTarget = event.currentTarget as Element;
    _currentTarget.parentElement.parentElement.classList.remove('is-open');
    this.fillCurrentTooltip(false);
    event.stopPropagation();
  }

  mapKontent(data) {
    this.data = data;
    this.headerBodyText.nativeElement.innerHTML = this.data.page_header_body_text;
  }

  get firstName() { return this.contactForm.get('firstName'); }
  get lastName() { return this.contactForm.get('lastName'); }
  get zipCode() { return this.contactForm.get('zipCode'); }
  get email() { return this.contactForm.get('email'); }
  get phoneNumber() { return this.contactForm.get('phoneNumber'); }
  get dateOfBirth() { return this.contactForm.get('dateOfBirth'); }
  get medicareInterestReason() { return this.contactForm.get('medicareInterestReason'); }

}
