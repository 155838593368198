<div *ngIf="!accountVerified" class="loading-page">
  <img src="../../../../assets/images/loader.gif" alt="Loading" />
</div>

<ng-container *ngIf="accountVerified &&  pageData">
  <div class="account-verified">
    <p class="title">
      {{ pageData.eyebrow }}<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33"
        fill="none">
        <path
          d="M14.1337 22.8216L23.5337 13.4216L21.667 11.555L14.1337 19.0883L10.3337 15.2883L8.46699 17.155L14.1337 22.8216ZM16.0003 30.0216C14.1559 30.0216 12.4225 29.6714 10.8003 28.971C9.1781 28.2705 7.76699 27.3208 6.56699 26.1216C5.36699 24.9216 4.41721 23.5105 3.71766 21.8883C3.0181 20.2661 2.66788 18.5328 2.66699 16.6883C2.66699 14.8439 3.01721 13.1105 3.71766 11.4883C4.4181 9.86609 5.36788 8.45498 6.56699 7.25498C7.76699 6.05498 9.1781 5.1052 10.8003 4.40565C12.4225 3.70609 14.1559 3.35587 16.0003 3.35498C17.8448 3.35498 19.5781 3.7052 21.2003 4.40565C22.8225 5.10609 24.2337 6.05587 25.4337 7.25498C26.6337 8.45498 27.5839 9.86609 28.2843 11.4883C28.9848 13.1105 29.3345 14.8439 29.3337 16.6883C29.3337 18.5328 28.9834 20.2661 28.283 21.8883C27.5825 23.5105 26.6328 24.9216 25.4337 26.1216C24.2337 27.3216 22.8225 28.2719 21.2003 28.9723C19.5781 29.6728 17.8448 30.0225 16.0003 30.0216ZM16.0003 27.355C18.9781 27.355 21.5003 26.3216 23.567 24.255C25.6337 22.1883 26.667 19.6661 26.667 16.6883C26.667 13.7105 25.6337 11.1883 23.567 9.12165C21.5003 7.05498 18.9781 6.02165 16.0003 6.02165C13.0225 6.02165 10.5003 7.05498 8.43366 9.12165C6.36699 11.1883 5.33366 13.7105 5.33366 16.6883C5.33366 19.6661 6.36699 22.1883 8.43366 24.255C10.5003 26.3216 13.0225 27.355 16.0003 27.355Z"
          fill="#0B4A5D" />
      </svg></p>
    <p class="description">{{ pageData.desc }}</p>
    <a [href]="pageData.mainCTA.url" [title]="pageData.mainCTA.text" class="button">{{ pageData.mainCTA.text }}</a>
  </div>
</ng-container>