import { Injectable } from '@angular/core';
import { KontentDeliveryService } from './kontent-delivery.service';
import { ComponentService } from './component.service';
import { UrlService } from './url.service';
import { ModalService } from './modal.service';
import { SeoService } from './seo.service';
import { ToolsPrimaryConfigService } from './planner/tools-primary-config.service';
import { TaxonomyService } from './taxonomy.service';
import { DataService } from './planner/data.service';

@Injectable({
  providedIn: 'root'
})
export class StandardService {
  contentType: string = 'standard_page,standard_page__special';

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private componentService: ComponentService,
    private urlService: UrlService,
    private taxoService: TaxonomyService,
    private modalService: ModalService,
    private seoService: SeoService,
    private tools: ToolsPrimaryConfigService,
    private dataService: DataService,
  ) {}

  getToolsPage(slug) {
    this.tools.updateActiveNav('home');

    return this.kontentDeliveryService.getItem(null, {
      'system.type': 'awa_benefits_tool_template___standard_page',
      'elements.url': slug,
      limit: 1,
      depth: 5,
    }).then(async rawAPIData => {
      if ( !rawAPIData || !rawAPIData.items.length ) {
        return {}
      }

      let toolsData = {
        item: rawAPIData.items[0].elements,
        body: rawAPIData.items[0].elements.body_text,
        components: await this.extractAdditionalComponents(rawAPIData),
        minimalPage: false
      }

      this.seoData(rawAPIData.items[0]);

      if(rawAPIData.items[0].system.codename === "awp_our_sponsors"){
        const sponsors = this.sponsorData(rawAPIData);
        toolsData.components.push({
          componentType: "sponsors",
          sponsors: sponsors,
          sponsorHeader: rawAPIData.items[0].elements.sponsors_header_text.value,
          sponsorFooter: rawAPIData.items[0].elements.sponsors_footer_text.value

        });
      }
      

      const { page_variation }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.items[0]);

      return toolsData;

    });
  }

  sponsorData(rawAPIData){
    const {
      platinium_sponsors,
      gold_sponsors,
      silver_sponsors,
      bronze_sponsors
    }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.items[0]);
    const sponsors = new Map();

    sponsors.set('platinum', this.processSponsors(platinium_sponsors, rawAPIData.modular_content));
    sponsors.set('gold', this.processSponsors(gold_sponsors, rawAPIData.modular_content));
    sponsors.set('silver', this.processSponsors(silver_sponsors, rawAPIData.modular_content));
    sponsors.set('bronze', this.processSponsors(bronze_sponsors, rawAPIData.modular_content));
    
    return sponsors;

  }

  processSponsors(rawSponsor, modular_content) {
    return rawSponsor.map(sponsorCodename => {
      const sponsorRawData = this.kontentDeliveryService.extractModularContent({ modular_content }, sponsorCodename);
      const {
        sponsor_name,
        sponsor_logo,
        external_link
      }: any = this.kontentDeliveryService.extractItemElements(sponsorRawData);
     
      let logo = sponsor_logo.length ? {
        url: sponsor_logo[0].url,
        alt: sponsor_logo[0].description
      } : {};

      return {
        sponsor_name,
        logo,
        external_link
      }
    });
  }

  getPage(slug) {
    return Promise.all([
      this.getPageData(slug),
    ])
    .then((data: any) => {
      if ( !data.length || !data[0].item ) {
        return null
      }

      // custom style config
      const styleConfig = {
        alignment: data[0]?.item?.script_alignment,
        position: data[0]?.item?.script_placement
      }
      data[0].styleConfig = styleConfig;

      if (data[0].item.parent_page && data[0].item.parent_page.value.length > 0) {
        const parentPage = data[0].rawAPIData.modular_content[data[0].item.parent_page.value[0]];

        if (parentPage) {
          const parentUrl = parentPage.elements.url.value;

          return {
            url: this.urlService.buildPageURL(`${parentUrl}/${slug}`),
            data: data[0],
          };
        } else {
          return {
            url: this.urlService.buildPageURL(slug),
            data: data[0],
          };
        }
      } else {
        return {
          url: this.urlService.buildPageURL(slug),
          data: data[0],
        };
      }
    });
  }

  getPageData(slug) {
    return this.kontentDeliveryService.getItem(null, {
      'system.type[in]': this.contentType,
      'elements.url': slug,
      limit: 1,
      depth: 5
    }).then(async rawAPIData => {
      if ( !rawAPIData || !rawAPIData.items.length ) {
        return {}
      }

      this.seoData(rawAPIData.items[0]);

      const { page_variation }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.items[0]);

      const components = await this.extractAdditionalComponents(rawAPIData)

      const specialPathingData = this.extractSpecialPathing(rawAPIData);

      return {
        rawAPIData,
        item: rawAPIData.items[0].elements,
        body: rawAPIData.items[0].elements.body_text,
        specialPathing: specialPathingData,
        components,
        minimalPage: page_variation[0]?.codename === 'minimal_layout'
      }
    });
  }

  async extractAdditionalComponents(rawAPIData) {
    const { additional_modules, system }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.items[0], ['additional_modules', 'system']);
    const rawComponentItems = this.kontentDeliveryService.extractModularContent(rawAPIData, additional_modules);
    const dynamicContentPackages = (rawComponentItems as any[]).filter(
      (rawComponent) => rawComponent.system.type === this.componentService.componentTypes.content_package && rawComponent.elements.content_population_method.value[0].codename === 'dynamically'
    );

    await this.collectDynamicArticles(dynamicContentPackages);

    // Remove cross pathing module
    const filteredRawComponentItems = rawComponentItems.filter(itemData => (
      this.componentService.componentTypes.cross_pathing_module !==  itemData.system.type
    ));
    const processedComponents = this.componentService.processRawComponents(filteredRawComponentItems, rawAPIData.modular_content)

    // Add dynamic articles to content packages
    dynamicContentPackages.forEach(pkg =>{
      const component = processedComponents.find(comp => comp.title === pkg.title);
      if(component){
        component.articles = pkg.articles;
      }
    })
   


    
    this.modalService.showAvailableModal(null, [], system.codename);

    return processedComponents;
  }

  seoData(rawAPIData) {
    const seoData = {
      title: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_title.value
        : rawAPIData.elements.title.value,
      description: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_description.value
        : rawAPIData.elements.description.value || '',
      image: rawAPIData.elements.primary_image?.value.length > 0
        ? rawAPIData.elements.primary_image?.value[0].url
        : '/assets/images/article-hero-share-midnight-desktop.png',
      ogTitle: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value
        : rawAPIData.elements.title.value,
      ogDescription: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value
        : rawAPIData.elements.description.value || '',
      ogImage: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value.length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value[0].url
        : rawAPIData.elements.primary_image?.value.length > 0
          ? rawAPIData.elements.primary_image?.value[0].url
          : '/assets/images/article-hero-share-midnight-desktop.png',
      ogType: 'page',
      twitterTitle: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value
        : rawAPIData.elements.title.value,
      twitterDescription: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value
        : rawAPIData.elements.description.value || '',
      twitterImage: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value.length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value[0].url
        : rawAPIData.elements.primary_image?.value.length > 0
          ? rawAPIData.elements.primary_image?.value[0].url
          : '/assets/images/article-hero-share-midnight-desktop.png',
    };

    this.seoService.applyMetaData(seoData);
  }

  extractSpecialPathing(rawAPIData) {
    const { modular_content } = rawAPIData;
    const { special_pathing }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.items[0], ['special_pathing']);

    const links = [];
    special_pathing.map(path => {
      const {
        title,
        url
      }: any = this.extractSpecialPathingItem(modular_content[path], modular_content);

      links.push({
        title,
        url: url
      });
    });

    return links;
  }


  extractSpecialPathingItem(item, modular_content: any) {
    const type = item.system.type;
    const {
      link_name, link_url, url, url_string, category_page_url, linked_content, parent_page
    }: any = this.kontentDeliveryService.extractItemElements(item);

    switch(true) {
      case /ncoa_article_content/.test(type): {
        const slug = url;

        return {
          title: item.system.name,
          url: `/article/${slug}`,
        };
      }

      case /standard_page/.test(type): {
        const slug = url;

        if (parent_page.length > 0 && modular_content[parent_page[0]]) {
          const parent = modular_content[parent_page[0]];
          const parentSlug = parent.elements.url.value;

          return {
            title: item.system.name,
            url: `/page/${parentSlug}/${slug}`,
          };
        }

        return {
          title: item.system.name,
          url: `/page/${slug}`,
        };
      }

      case /navigation_item/.test(type):
        return {
          title: link_name,
          url: link_url || this.buildTaxonomyPageURL(modular_content[linked_content[0]])
        };

      case /taxonomy_custom_content/.test(type):
        return {
          title: item.system.name,
          url: this.buildTaxonomyPageURL(item)
        };

      case /template___custom_search/.test(type):
        return {
          title: item.elements.page_title.value,
          url: `/${item.elements.page_slug.value}`
        };

      default:
        return {
          title: item.system.name,
          url: link_url || url || url_string
        };
    }
  }

  buildTaxonomyPageURL(itemData) {
    const type = itemData.system.type;

    switch (true) {

      case /taxonomy_custom_content/.test(type):
        const { categories, audiences, category_page_url }: any = this.kontentDeliveryService.extractItemElements(itemData);
        const category = categories[0];
        const audience = audiences[0];

        // Use manual URL if any
        if ( category_page_url ) {
          return category_page_url;
        }

        // // Use dynamically built URL
        // if ( category.codename in this.categoriesMap && audience.codename in this.audiencesMap ) {
        //   return '/' + [
        //     audience.codename.replace(/_/g, '-'),
        //     ...this.categoriesMap[category.codename].parent.map(categoryParent => (
        //       categoryParent.codename.replace(/_/g, '-')
        //     )),
        //     category.codename.replace(/_/g, '-')
        //   ].join('/')
        // }

        return '';

      case /template___other_taxonomy/.test(type):
        const { other_taxonomy }: any = this.kontentDeliveryService.extractItemElements(itemData);
        return other_taxonomy[0].codename.replace(/_/g, '-');
    }
  }

  async collectDynamicArticles(dynamicContentPackages) {
    for (const dynamicContentPackage of dynamicContentPackages) {
      const audiences = dynamicContentPackage.elements.audiences.value.map(audience => audience.codename);
      const categories = dynamicContentPackage.elements.categories.value.map(category => category.codename);

      const baseParams = {
        'system.type': 'ncoa_article_content',
        'elements': 'primary_image,title,display_date,url,event_date_time,other,audiences,categories,other_categories',
        'order': 'elements.display_date[desc]',
        'limit': 10,
      }
      // if audience or category is selected
      let getAudienceAndCategoryArticles = false;
      const audienceAndCategoryArticlesParams = { ...baseParams };
      if (audiences.length > 0) {
        audienceAndCategoryArticlesParams['elements.audiences[any]'] = audiences;
        getAudienceAndCategoryArticles = true;
      }
      if (categories.length > 0) {
        audienceAndCategoryArticlesParams['elements.categories[any]'] = categories;
        getAudienceAndCategoryArticles = true;
      }

      // if other is selected
      const otherCategoryParams = { ...baseParams };
      let getOtherArticles = false;
      if (dynamicContentPackage.elements.other_categories.value.length > 0) {
        otherCategoryParams['elements.other[any]'] = dynamicContentPackage.elements.other_categories.value.map(other => other.codename);
        getOtherArticles = true;
      }


      const promises = [];
      if (getAudienceAndCategoryArticles) { promises.push(this.kontentDeliveryService.getItem(null, audienceAndCategoryArticlesParams)); }
      if (getOtherArticles) { promises.push(this.kontentDeliveryService.getItem(null, otherCategoryParams)); }

      const result = await Promise.all(promises);

      // build articles as content package type
      const articles = [];
      result.forEach((res) => {
        res.items.forEach((item) => {
          const type = item.elements.event_date_time.value ? 'event' : 'article';
          const title = item.elements.title.value;
          const cta = {
            url: `/article/${item.elements.url.value}`,
            text: type === 'event' ? 'View Event Details' : 'Read Article',
          };
          const image = item.elements.primary_image.value.length > 0 ? {
            url: item.elements.primary_image.value[0].url,
            alt: item.elements.primary_image.value[0].description,
            responsive: this.dataService.remodelImageSrc(item.elements.primary_image.value[0].url),
          } : {};
          const eyebrow = new Date(item.elements.display_date.value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
          const dateProp = item.elements.event_date_time.value
          articles.push({ type, title, cta, image, eyebrow, dateProp });
        });
      });


      articles.sort((a, b) => new Date(b.dateProp).getTime() - new Date(a.dateProp).getTime()); // sort to get most recent at top
      articles.splice(7); // limit to 7 articles
      dynamicContentPackage.articles = articles
      dynamicContentPackage.title = dynamicContentPackage.elements?.title?.value;

    };
  }


}
