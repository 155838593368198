import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { DataLayerService } from './data-layer.service';
import { ErrorService } from './error.service';
import { KontentDeliveryService } from './kontent-delivery.service';
import { ModulesService } from './modules.service';
import { DataService } from './planner/data.service';
import { SeoService } from './seo.service';

dayjs.extend(utc);

@Injectable({
  providedIn: 'root'
})
export class SpecialPageService {

  specialPageType: any = null;
  specialPageData: any = null;
  modularContent: any = null;
  components: any[] = [];
  articles: any[] = [];

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private dataService: DataService,
    private modulesService: ModulesService,
    private dataLayerService: DataLayerService,
    private seoService: SeoService,
    private errorService: ErrorService,
  ) { }

  async getSpecialPageData(pageData, pageLibraryModularContents) {
    this.specialPageType = pageData.elements.other_taxonomy.value[0];

    if (!this.specialPageType) {
      this.errorService.notFound();
    }

    // articles library
    let articles = await this.kontentDeliveryService.getItem(null, {
      'system.type': 'ncoa_article_content',
      'elements': 'primary_image,title,display_date,url,event_date_time,categories,audiences,secondary_audiences,secondary_categories,spanish_subcategory,other',
      'elements.other[any]': this.specialPageType.codename,
      'order': 'elements.display_date[desc]',
      'limit': 200,
    });

    articles = articles.items.map((article) => ({
      ...article,
      date: new Date(article.elements.display_date.value || article.system.last_modified),
    }));
    articles = articles.sort((a, b) => b.date - a.date);

    this.articles = articles;

    this.modularContent = pageLibraryModularContents;

    // hero
    const headline = pageData.system.name;
    this.components.push({
      componentType: this.modulesService.moduleTypes.category_page_hero,
      category: {
        breadcrumbs: [],
        headline,
        description: pageData.elements.description.value,
        photo: pageData.elements.image.value.length > 0 ? {
          responsive: this.dataService.remodelImageSrc(pageData.elements.image.value[0].url),
          url: pageData.elements.image.value[0].url,
          alt: pageData.elements.image.value[0].description,
        } : null,
      },
    });

    // special pathing
    if (pageData.elements.special_pathing.value.length > 0) {
      const codeNames = pageData.elements.special_pathing.value;
      const paths = codeNames.map((codename) => {
        const path = pageLibraryModularContents[codename];

        switch (path.system.type) {
          case 'template___other_taxonomy': {
            return {
              url: `/${path.system.name.trim()
                .toLowerCase()
                .replace(/\s+/g, '-')
                .replace(/[^\w\-]+/g, '')
                .replace(/\-\-+/g, '-')
                .replace(/^-+/, '')
                .replace(/-+$/, '')}`,
              title: path.system.name,
            };
          }
          case 'ncoa_article_content': {
            return {
              url: `/article/${path.elements.url.value}`,
              title: path.elements.title.value,
            };
          }
          case 'standard_page': {
            let url = null;
            const parentPageCodename = path.elements.parent_page.value[0] || null;

            if (parentPageCodename) {
              const parentPage = pageLibraryModularContents[parentPageCodename];

              if (parentPage) {
                url = `/page/${parentPage.elements.url.value}/${path.elements.url.value}`;
              }
            } else {
              url = `/page/${path.elements.url.value}`;
            }

            return {
              url,
              title: path.elements.title.value,
            };
          }
          case 'template___custom_search': {
            return {
              url: `/${path.elements.page_slug.value}`,
              title: path.elements.page_title.value,
            };
          }

          case 'navigation_item_0ae4ab8': {
            let url, external = false;
            if (path.elements.linked_content.value.length > 0) {
              url = this.modularContent[path.elements.linked_content.value[0]].elements.category_page_url?.value || `page/${this.modularContent[path.elements.linked_content.value[0]].elements.url?.value}`;
            } else {
              url = path.elements.link_url.value;
              if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0) external = true;
            }

            return {
              title: path.elements.link_name.value || this.modularContent[path.elements.linked_content.value[0]].system.name,
              url,
              external
            }
          }
        }
      });

      this.components.push({
        componentType: this.modulesService.moduleTypes.special_pathing,
        paths,
      });
    }

    // top content package
    let featuredArticles = [];

    if (pageData.elements.featured_articles.value.length > 0) {
      pageData.elements.featured_articles.value.map(codename => {
        const article = pageLibraryModularContents[codename];

        if (article) {
          const index = this.articles.findIndex((libArticle) => libArticle.system.id === article.system.id);

          if (index >= 0) {
            this.articles.splice(index, 1);
          }

          featuredArticles.push({
            type: article.elements.event_date_time.value ? 'event' : 'article',
            title: article.elements.title.value,
            eyebrow: article.elements.display_date.value
              ? dayjs.utc(article.elements.display_date.value).format('MMM D, YYYY')
              : dayjs.utc(article.system.last_modified).format('MMM D, YYYY'),
            image: article.elements.primary_image.value.length > 0 ? {
              url: article.elements.primary_image.value[0].url,
              alt: article.elements.primary_image.value[0].description,
              responsive: this.dataService.remodelImageSrc(article.elements.primary_image.value[0].url),
            } : article.elements.event_date_time.value ? {
              url: '/assets/images/content-package-green-tablet.png',
              alt: article.elements.title.value,
            } : {
              url: '/assets/images/content-package-coral-tablet.png',
              alt: article.elements.title.value,
            },
            cta: {
              url: `/article/${article.elements.url.value}`,
              text: article.elements.event_date_time.value ? 'View Event Details' : 'Read Article',
            },
            date: new Date(article.elements.display_date.value || article.system.last_modified),
          });
        }
      });
    } else {
      const max = this.articles.length > 6 ? 6 : this.articles.length;
      const articles = this.articles.slice(0, max);

      featuredArticles = articles.map((article) => {
        const index = this.articles.findIndex((libArticle) => libArticle.system.id === article.system.id);

        if (index >= 0) {
          this.articles.splice(index, 1);
        }

        const type = article.elements.event_date_time.value ? 'event' : 'article';
        const title = article.elements.title.value;
        const cta = {
          url: `/article/${article.elements.url.value}`,
          text: type === 'event' ? 'View Event Details' : 'Read Article'
        };
        const image = article.elements.primary_image.value.length > 0 ? {
          url: article.elements.primary_image.value[0].url,
          alt: article.elements.primary_image.value[0].description,
          responsive: this.dataService.remodelImageSrc(article.elements.primary_image.value[0].url),
        } : {};
        const eyebrow = dayjs.utc(article.date).format('MMM D, YYYY');

        return {
          type,
          title,
          cta,
          image,
          eyebrow,
        };
      });
    }

    if (featuredArticles.length) {
      featuredArticles = featuredArticles.sort((a, b) => b.date - a.date);

      this.components.push({
        componentType: this.modulesService.moduleTypes.content_package,
        articles: featuredArticles,
      });
    }

    // additional components
    pageData.elements.components.value.forEach((codename) => {
      const component = pageLibraryModularContents[codename];

      switch (component.system.type) {
        // featured tool breaker
        case this.modulesService.moduleTypes.featured_tool_breaker: {
          this.components.push(this.modulesService.featuredToolBreaker(component));
          break;
        }

        // donate block
        case this.modulesService.moduleTypes.call_to_donate: {
          this.components.push(this.modulesService.donateBlock(component));
          break;
        }

        // featured page block
        case this.modulesService.moduleTypes.featured_page_block: {
          this.components.push(this.modulesService.featurePageBlock(component, this.modularContent));
          break;
        }

        // content package
        case this.modulesService.moduleTypes.content_package: {
          this.contentPackage(component);
          break;
        }

        // newsletter block
        case this.modulesService.moduleTypes.newsletter_signup_block: {
          this.components.push(this.modulesService.newsLetterBlock(component));
          break;
        }

        // secondary content package
        case this.modulesService.moduleTypes.content_package_secondary: {
          this.secondaryContentPackage();
          break;
        }

        // category pathing
        case this.modulesService.moduleTypes.category_pathing_std: {
          this.components.push(this.modulesService.categoryPathingStandard(component, pageLibraryModularContents));
          break;
        }
      }
    });

    // analytics
    this.dataLayerService.push({
      url: window.location.href,
      pageTitle: headline,
      contentType: 'taxonomy',
      category: null,
      audience: null,
    });

    // seo service
      const seo = {
        title: headline,
        description:
          pageData.elements.seo_metadata_example_to_include_in_any_type__meta_description.value
          || pageData.elements.description.value,
        image: pageData.elements.image.value[0]?.url,
        ogTitle: pageData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value
          || headline,
        ogDescription: pageData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value
          || pageData.elements.description.value,
        ogImage: pageData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value[0]?.url
          || pageData.elements.image.value[0]?.url,
        twitterTitle: pageData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value
          || headline,
        twitterDescription: pageData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value
          || pageData.elements.description.value,
        twitterImage: pageData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value[0]?.url
          || pageData.elements.image.value[0]?.url,
      };

      this.seoService.applyMetaData(seo);

    return this.components;
  }

  getManualArticles(component) {
    let selectedArticles = [];
    const articleCodeNames = component.elements.content.value;

    articleCodeNames.forEach((codename) => {
        const article = this.modularContent[codename];
        if (article) {
          selectedArticles.push({
            ...article,
            date: new Date(article.elements.display_date.value || article.system.last_modified),
          });
        }
    });

    return selectedArticles;
  }

  getDynamicArticles(component) {
    const categories = component.elements.categories.value.map(({ codename }) => codename);
    const audiences = component.elements.audiences.value.map(({ codename }) => codename);
    const specialType = component.elements.other_categories.value.map(({ codename }) => codename);
    let articles = this.articles;

    let filteredArticles = articles.filter((article) => {
      const primaryCategoryFound = article.elements.categories.value.some((x) => categories.includes(x.codename));
      const secondaryCategoryFound = article.elements.secondary_categories.value.some((x) => categories.includes(x.codename));

      const primaryAudienceFound = article.elements.audiences.value.some((x) => audiences.includes(x.codename));
      const secondaryAudienceFound = article.elements.secondary_audiences.value.some((x) => audiences.includes(x.codename));

      const specialTypeFound = article.elements.other.value.some((x) => specialType.includes(x.codename));

      return primaryCategoryFound || secondaryCategoryFound || primaryAudienceFound || secondaryAudienceFound || specialTypeFound;
    });

    const max = filteredArticles.length > 7 ? 7 : filteredArticles.length;

    return filteredArticles.slice(0, max);
  }


  async contentPackage(component) {
    const categories = component.elements.categories.value.map(({ codename }) => codename);
    const audiences = component.elements.audiences.value.map(({ codename }) => codename);
    const specialType = component.elements.other_categories.value.map(({ codename }) => codename);
    let contentPackageArticles = [];
    let selectedArticles = [];
    const contentPopulationMethod = component.elements.content_population_method.value[0].codename;

    if (contentPopulationMethod === 'dynamically') {
      selectedArticles = this.getDynamicArticles(component);
    } else if (contentPopulationMethod === 'dynamic_with_manual_additions') {
      selectedArticles = this.getManualArticles(component).concat(await this.getDynamicArticles(component));
      selectedArticles.sort((a, b) => (a.date > b.date) ? -1 : 1);
      const max = selectedArticles.length > 7 ? 7 : selectedArticles.length;
      selectedArticles = selectedArticles.slice(0, max);
    } else {
      selectedArticles = this.getManualArticles(component);
    }

    if (selectedArticles.length > 0) {
      contentPackageArticles = selectedArticles.map((article) => {
        const index = this.articles.findIndex((libArticle) => libArticle.system.id === article.system.id);

        if (index >= 0) {
          this.articles.splice(index, 1);
        }

        const type = article.elements.event_date_time.value ? 'event' : 'article';
        const title = article.elements.title.value;
        const cta = {
          url: `/article/${article.elements.url.value}`,
          text: type === 'event' ? 'View Event Details' : 'Read Article'
        };
        const image = article.elements.primary_image.value.length > 0 ? {
          url: article.elements.primary_image.value[0].url,
          alt: article.elements.primary_image.value[0].description,
          responsive: this.dataService.remodelImageSrc(article.elements.primary_image.value[0].url),
        } : {};
        const eyebrow = dayjs.utc(article.date).format('MMM D, YYYY');

        return {
          type,
          title,
          cta,
          image,
          eyebrow,
        };
      });

      this.components.push({
        componentType: this.modulesService.moduleTypes.content_package,
        articles: contentPackageArticles,
      });
    }
  }

  secondaryContentPackage() {
    const max = this.articles.length > 4 ? 4 : this.articles.length;
    let contentPackageArticles = this.articles.slice(0, max);

    contentPackageArticles = contentPackageArticles.map((article) => {
      const index = this.articles.findIndex((libArticle) => libArticle.system.id === article.system.id);

      if (index >= 0) {
        this.articles.splice(index, 1);
      }

      const type = article.elements.event_date_time.value ? 'event' : 'article';
      const title = article.elements.title.value;
      const cta = {
        url: `/article/${article.elements.url.value}`,
        text: type === 'event' ? 'View Event Details' : 'Read Article'
      };
      const image = article.elements.primary_image.value.length > 0 ? {
        url: article.elements.primary_image.value[0].url,
        alt: article.elements.primary_image.value[0].description,
        responsive: this.dataService.remodelImageSrc(article.elements.primary_image.value[0].url),
      } : {};
      const eyebrow = dayjs.utc(article.date).format('MMM D, YYYY');

      return {
        type,
        title,
        cta,
        image,
        eyebrow,
      };
    });

    if (contentPackageArticles.length > 0) {
      this.components.push({
        componentType: this.modulesService.moduleTypes.content_package,
        articles: contentPackageArticles,
      });
    }
  }
}
