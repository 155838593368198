
  export const environment = {
    production: true,
    region: "us-east-1",
    identityPoolId: "us-east-1:1db14cb5-af94-4d16-b935-3cf3cf3fc3d5",
    userPoolId: "us-east-1_Jue3eilnX",
    ncoaApiBaseUrl: "https://qa.api.ncoa.org",
    contentId: "0803a328-d20d-488e-bab9-55f8e5923ea4",
    verticalTaxonomyId: "4c4422d4-fc06-4d66-84e5-768c2652e12e",
    taxonomyElementCodename: "awp_verticals__topics__and_subtopics",
    feedbackAPIUrl: "https://api.ncoa.org/feedback",
    environment: "qa",
    benefitsBaseUrl: "https://api.ncoa.org/content/programsBySubsetState",
    workflowStep: "qa",
    prerender: "undefined",
    PROJECT_ID: "ffacfe7d-10b6-0083-2632-604077fd4eca",
    PRIMARY_KEY: "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjVjM2EyZWM1ZGJhNDQ2NjlhMDg4ZmNkYjU0ZTM0MWRkIiwNCiAgImlhdCI6ICIxNTk1NDEzNzM3IiwNCiAgImV4cCI6ICIxOTQxMDEzNzM3IiwNCiAgInByb2plY3RfaWQiOiAiNzhlMmFlMzUxMDFkMDA2ZDVmYzkzZDcyNzRlYWY1ZDYiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.hxyIUTmqi4qZNCBZSmxbbkVwm5Ts4xzYw4P_4Wd4Nmw",
    SECONDARY_KEY: "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjYwNzdhMWYxZjg5YzRlZDE4NzE0MTViNTliY2U0M2RiIiwNCiAgImlhdCI6ICIxNTk0OTEyODU1IiwNCiAgImV4cCI6ICIxOTQwNTEyODU1IiwNCiAgInByb2plY3RfaWQiOiAiZmZhY2ZlN2QxMGI2MDA4MzI2MzI2MDQwNzdmZDRlY2EiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.wYQ0buZHXtj2QdrbnjkUN9ETCLAqmhzLH4zPCUj62_M",
    API_BASE_URL: "https://deliver.kontent.ai",
    maxUserIdleTime: "60",
    maxUserIdleTimeOut: "600"
  };
