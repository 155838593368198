import { Component, OnInit, Input , ViewChild, ElementRef, AfterViewInit} from '@angular/core';

import { DataLayerService } from '@services/data-layer.service';
import { UserService } from '@services/planner/user.service';

@Component({
  selector: 'ncoa-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit, AfterViewInit {

  @ViewChild("email") inputElement: ElementRef;
  @ViewChild("emailText") emailText: ElementRef;
  @ViewChild('newsletterForm') formElement: ElementRef;

  @Input() heading: string;
  @Input() description: string;
  @Input() background: string;
  @Input() mobilebackground: string;
  @Input() backgroundCaption: string;
  @Input() isError: boolean;
  @Input() isSubmitted: boolean = false;
  @Input() formSubmitHandler: any;
  @Input() newsletterLink: string;
  @Input() width: number;
  @Input() height: number;
  @Input() everyActionFormUrl: string;
  @Input () emailErrorText: string = 'empty';
  @Input() fieldErrorText: string = 'eee';

  isLoading: boolean = false;
  initialInputWidth: number = 0; 

  newsletterEmail: {
    value: string;
    status: {
      code: string;
      message: string;
    };
  } = {
      value: '',
      status: {
        code: '',
        message: '',
      },
    };
    firstName: { value: string; status: {code: string; message: string;};
    } = { value: '',status: {code: '',message: '',},};
    lastName: { value: string; status: {code: string; message: string;};
    } = { value: '',status: {code: '',message: '',},};
    
  newsletterRole: any;
  errorMessage: string = '';
  signupOrigin: string = '';
  everyActionFormData: any;
  everyActionSelectName = 'newsletterRole'
  everyActionSubmitUrl = ''
  emailButtonCTAText = 'Sign Up Now';
  rolesOptions = [];
  emailPlaceHolder = 'Email Address';

  validEmail = false;
  dropdownTitle: string = '';

  constructor(
    private dataLayerService: DataLayerService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.isSubmitted = false;
    if(!this.everyActionFormUrl){
      this.newsletterRole = this.rolesOptions[0].value;
    }

    fetch(this.everyActionFormUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
    .then((data) => {
      this.everyActionFormData = data;
      this.parseFormData(data);

    }
    );
  }

  ngAfterViewInit() {
  }

  onSelect(event: any) {
    const value = event.currentTarget.value;

    // Fire analytics
    this.dataLayerService.push({
      event: "gtm.onChange",
      identifier: value
    });
  }

  onEmailChange(event: any) {
    const value = event.currentTarget.value;
    
    // Fire analytics
    this.dataLayerService.push({
      event: "gtm.onBlur",
      emailAddress: value ? 'complete' : 'incomplete'
    });
  }

  onEmailInput(event: any) {
  }

  onSubmit(e: any) {
    const emailAddress = this.newsletterEmail.value ? 'complete' : 'incomplete';
    const identifier = this.everyActionFormData['shortCode'];
    const ctaValue = 'submit';
    e.preventDefault();

    const email: HTMLElement = document.querySelector('#email');
    let allfieldsValid = true;
    if (this.newsletterEmail.value.trim().length === 0 ||
        (this.newsletterEmail.value.trim().length > 0 &&
          !this.isEmailValid(this.newsletterEmail.value))) {
            allfieldsValid = false;
      this.newsletterEmail = {
        ...this.newsletterEmail,
        status: {
          code: 'error',
          message: this.emailErrorText,
        },
      };
      this.handleErrorMessageChange(this.newsletterEmail.status.message, email);
    } else if (this.newsletterEmail.value.trim().length > 0 &&
      this.isEmailValid(this.newsletterEmail.value)) {
        this.newsletterEmail = {
          ...this.newsletterEmail,
          status: {
            code: '',
            message: '',
          },
        };
    }
    if (this.firstName.value.trim().length === 0) {
      allfieldsValid = false;
      this.firstName = {
        ...this.firstName,
        status: {
          code: 'error',
          message: this.fieldErrorText,
        },
      };
    }
    if (this.lastName.value.trim().length === 0) {
      allfieldsValid = false;
      this.lastName = {
        ...this.lastName,
        status: {
          code: 'error',
          message: this.fieldErrorText,
        },
      };
    }
    
    if (allfieldsValid){

      const formBody ={
        [this.everyActionSelectName]: this.newsletterRole,
        'EmailAddress': this.newsletterEmail.value,
        'FormVersion' : this.everyActionFormData['formVersion'],
        'LastName': this.lastName.value,
        'FirstName': this.firstName.value,
      }
      this.userService.postEveryActionSignup(this.everyActionFormData['submissionUrl'], formBody).subscribe((response) => { 
        window.localStorage.setItem('newsletterSignUp', 'true');
        this.isSubmitted = true;
      });
    }

  }

  parseFormData(data: any) {
    this.everyActionSubmitUrl = data['submitUrl'] ?? ''
    const formElements = data['form_elements'];

    formElements.forEach((element) => {
      if(element.type === 'fieldset' && element.name === 'AdditionalInformation'){
        this.dropdownTitle = element.title;
      }
    });

    const children = Object.keys(formElements)
    .filter((key) => formElements[key]?.children)
    .reduce((acc, key) => acc.concat(formElements[key]?.children), []);


    children.forEach((child) => {
      if (child?.type === 'select' && typeof child?.options !== 'string') {
         this.everyActionSelectName = child.name
          this.rolesOptions = child?.options.map((option) => {
            if(!this.newsletterRole){
              this.newsletterRole = option.value;
            }
            return {
              value: option.value,
              label: option.display,
            };
          });
      }
    });

  }

  handleErrorMessageChange(newMessage, focusTo){
    focusTo.focus();
    setTimeout(()=>{
      if(this.errorMessage !== ''){
        if(this.errorMessage === newMessage){
          this.errorMessage = '';
          setTimeout(()=>{
            this.errorMessage = newMessage
          },500);
        }else{
          this.errorMessage = newMessage;
          setTimeout(()=>{
            this.errorMessage = ''
          },1000);
        }
      }else{
        this.errorMessage = newMessage;
      }
    },300);
  }

  isEmailValid(email: string): boolean {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  }


}
