import fetch from 'node-fetch';
import { environment } from '../../environments/environment';
// export const FEEDBACK_URL = 'https://fv56nzrkk1.execute-api.us-east-1.amazonaws.com/ncoa-feeback-stage';
export const FEEDBACK_URL =  (environment as any)?.ncoaApiBaseUrl + '/infra';
export const getItem = async (itemId: string = '') => {
  if ( !itemId ) {
    return null;
  }

  const url = FEEDBACK_URL + `/getArticleFeedback?itemSystemId=${itemId}`;
  return await fetch(url).then(data => 
    { 
    
      return data.json()
    });
}

export const updateItem = async (itemId: string = '', voteType: string = '', revote = false) => {
  if ( !itemId || !voteType ) {
    return null;
  }

  let url = FEEDBACK_URL + `/submitArticleFeedback?itemSystemId=${itemId}&voteType=${voteType}`;

  if ( revote ) {
    url += `&voteUpdate=true`;
  }

  return await fetch(url).then(data => data.json());
}
