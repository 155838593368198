import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { CustomSearchService, SEARCH_TAXONOMIES } from '@services/custom-search.service';
import { Location } from '@angular/common';
import { image } from '../../components/author-header/author-header.types';
import { ActivatedRoute } from '@angular/router';
import { TimelineLite, TweenLite } from 'gsap';
import { GlobalService } from '@services/global.service';
import { ComponentService } from '@services/component.service';

@Component({
  selector: 'ncoa-category-search',
  templateUrl: './category-search.component.html',
  styleUrls: ['./category-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CategorySearchComponent implements OnInit {
  // identifier
  items: any[] = [];
  displayed: any[] = [];
  searchKey: string = '';
  resultTitle: string = '';
  totalItems: number;
  shownItems: number = 0;
  endingGroupIndex: number = 0;
  isInCategorySearch: boolean = true;
  hasMoreItems: boolean = false;

  // header props
  @Input() image: image;
  @Input() eyebrow: string;
  @Input() title: string;
  @Input() description: string;
  @Input() placeholder: string;
  @Input() hideSearchBar: boolean;
  @Input() showFilterOption: boolean = false;
  @Input() searchBarLink: string;
  @Input() filterGroupTags : any[];
  @Input() filterByDate : string[] = [''];
  @Input() resultLabel: string;
  @Input() filterGroups: any[];
  @Input() additionalComponents?: any[];
  @Input() specialPaths: any= {};
  filters: any[];
  selectedFilters: any[];
  selectedFilterNames: string;
  searchTitle: any;
  filterYears: number[];
  currentYear: number;
  currentMonth: number;
  components: any[] = [];
  componentTypes: any;
  marginTop: any;


  constructor(
    private activeRoute: ActivatedRoute,
    private customSearchService: CustomSearchService,
    private componentService: ComponentService,
    private location: Location,
    private globalService: GlobalService,
    private cdr: ChangeDetectorRef
  ) {
    this.filterYears = [];
    this.componentTypes = this.componentService.componentTypes;
  }

  ngOnInit(): void {
    if(this.specialPaths){
      // remove negative margin from header component
      this.marginTop = 0;
    }
    if (this.additionalComponents) {  
      this.components = [
        ...this.additionalComponents,
      ];
    } 

    this.searchKey = this.activeRoute.snapshot.queryParams.q || '';


    this.customSearchService.totalItems.subscribe((total) => {
      this.totalItems = total;
      this.cdr.detectChanges();
    });


    this.customSearchService.sortBy(this.filterByDate.length>0 ? 'year' : '')
      .getList(this.searchKey)
      .then((data) => {
        this.onSearchDataReceived(data)
        this.filterYears = Array.from(data.yearList).sort((a, b) => b - a);
      });

    this.filters = (this.filterGroups || []).map((data) => {
      const isExpanded = false;

      const options = data.value.map((term) => {
        return {
          text: term.name,
          value: `${data.codename}-${term.codename}`,
          isChecked: false,
        };
      });

      return {
        taxonomy: data.codename,
        title: data.title,
        isExpanded,
        options,
      };
    });

  }

  onSearchDataReceived(data, callback = null) {
    const { displayed, items } = data;

    this.displayed = displayed;
    this.endingGroupIndex = 0;
    this.items = items;

    this.transformItemTags() 


  

    this.globalService.loadFooter(true);


    if (!items.length) {
      this.resultTitle = '';
      return;
    }

    
     this.selectedFilterNames = this.filters.map((filter) => {
      return filter.options.filter((option) => {
        return option.isChecked;
      })
      .map((option) => option.text.trim())
      .join(', ');
    }).filter((filter) => filter.length > 0)
    .join(', ');

 
    this.buildResultTitle() 
    //sort items by eyebrow coverting it to a date
    if(this.filterByDate.includes('year')){
    this.items.sort((a, b) => {
      return new Date(b.eyebrow).getTime() - new Date(a.eyebrow).getTime();
    });
    }



    this.hasMoreItems = this.totalItems > this.items.length;
    if (callback) {
      callback();
    }
  }
  transformItemTags() {
    if(this.filterGroupTags.length > 0){
      //set item.type.name to an array of filter group names
      this.items.forEach((item) => {

      if(item.cta.text){
        item.cta.text = item.cta.text.replace('Article', 'Resource')
      }
        const taxonomies = SEARCH_TAXONOMIES.reduce((acc, curr) => {
          if (item[curr]&& item[curr].length > 0) {
            //for each taxonomy codename in item[curr] find the filter group name
            item[curr].forEach((taxonomy) => {
              const filterGroup = this.filterGroupTags.find((filterGroup) => {
                return filterGroup.codename === taxonomy;
              });
              if (filterGroup) {
                acc.push(filterGroup.name);
              }
            } );
          }
          return acc;
        }, []);


        item.type.name = taxonomies;
    });
  }

  }
    

  getMoreContents() {
    const newGroupDisplayed = this.endingGroupIndex + 1;

    const topOffset = window.scrollY;

    document.body.style.top = `-${topOffset}px`;
    document.body.style.position = 'fixed';
    document.body.style.width = `${window.innerWidth}px`;
    document.querySelector('.header--global').classList.add('is-scrolled');
    document.querySelector('.header--global').classList.add('is-sticky');

    const data = this.customSearchService.getMoreData(this.items.length);

    this.displayed = data.displayed;
    this.items = [...this.items, ...data.items];
    this.shownItems = this.items.length;
    this.hasMoreItems = this.totalItems > this.items.length;
    this.transformItemTags() 
    this.buildResultTitle()
    const targetGroup: HTMLElement = document.querySelector(
      `.results-list__list .group:nth-child(${newGroupDisplayed})`
    );

  
    new TimelineLite()
      .to(targetGroup, { display: 'block' })
      .to(targetGroup, { opacity: 1, y: 0, duration: 0.4 })
      .eventCallback('onComplete', () => {
        document.body.style.removeProperty('top');
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('width');
        window.scrollTo(0, topOffset);
      });
  }

  searchContent(keyword): void {
    const path =
      keyword && keyword.length > 0
        ? `/${this.activeRoute.snapshot.url[0].path}?q=${keyword}`
        : `/${this.activeRoute.snapshot.url[0].path}`;

    new TweenLite('.results-list', { y: 200, opacity: 0, duration: 0.4 });
    new TimelineLite().to('.no-result', { opacity: 0, duration: 0.4 });

    this.location.replaceState(path);
    this.searchKey = keyword || '';

    setTimeout(() => {
      const data = this.customSearchService.search(
        keyword,
        this.selectedFilters,
        false
      );

      this.onSearchDataReceived(data, () => {
        if (data.items.length > 0) {
          new TweenLite('.results-list', { y: 0, opacity: 1, duration: 0.4 });
          new TweenLite('.no-result', { position: 'absolute', duration: 0.4 });
        } else {
          new TimelineLite()
            .to('.no-result', { position: 'relative', duration: 0.1 })
            .to('.no-result', { opacity: 1, duration: 0.5 });
        }
      });
    }, 1500);
  }

  updateSelectedFilters({ filtersApplied, applyAllFilters}): void {
    this.selectedFilters = filtersApplied;

    new TweenLite('.results-list', { y: 200, opacity: 0, duration: 0.4 });
    new TimelineLite().to('.no-result', { opacity: 0, duration: 0.4 });

    setTimeout(() => {
      const data = this.customSearchService.search(
        this.searchKey,
        this.selectedFilters,
        applyAllFilters
      );

      this.onSearchDataReceived(data, () => {
        if (data.items.length > 0) {
          new TweenLite('.results-list', { y: 0, opacity: 1, duration: 0.4 });
          new TweenLite('.no-result', { position: 'absolute', duration: 0.4 });
        } else {
          new TimelineLite()
            .to('.no-result', { position: 'relative', duration: 0.1 })
            .to('.no-result', { opacity: 1, duration: 0.5 });
        }
      });
    }, 1000);

  }
  updateFilterYear(year:number): void {
    this.currentYear = year;
    this.customSearchService.updateYearFilter(this.currentYear);
    this.updateSelectedFilters({ filtersApplied: this.selectedFilters, applyAllFilters: false });
  }

  updateFilterMonth(month:number): void {
    this.currentMonth = month;
    this.customSearchService.updaterMonthFilter(this.currentMonth);
    this.updateSelectedFilters({ filtersApplied: this.selectedFilters, applyAllFilters: false });
  }

  buildResultTitle(): void {
    this.resultTitle = `Showing ${this.items.length} of ${this.totalItems} results`

    if (this.selectedFilterNames?.length > 0) {
      this.resultTitle += ` for <span class='bold'> ${this.selectedFilterNames} </span>`;
      
    }
    else if (this.resultLabel?.length > 0) {
      this.resultTitle += ` for ${this.resultLabel}`;
    }
  }
}
