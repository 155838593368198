import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { AssessmentService } from '@services/planner/assessment.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { UserService } from '@services/planner/user.service';
import { RebaDataService } from '@services/planner/reba-data.service';
import { take } from 'rxjs/operators';
import { SeoService } from '@services/seo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '@services/global.service';
import { RichTextService } from '@services/rich-text-resolver.service';

@Component({
  selector: 'assessment-page',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  encapsulation: ViewEncapsulation.None, 
})
export class AssessmentPage implements OnInit {

  isLoaded: boolean = false;
  showLoadingOverlay: boolean = false;
  pageData: any = null;
  data: any = null;
  isCompleted: boolean = false;
  currentAssessmentID: string = null;
  currentScreeningCode: string;

  assessmentID: string = null;
  screeningCode: string;
  version: number = null;

  //NAM-727 - allow user to return to screening to edit
  edit: boolean = false; 

  backCTA: {
    text: string;
    url: string;
  } = null;

  constructor(
    private toolsConfig: ToolsPrimaryConfigService,
    private assessment: AssessmentService,
    private cas: CustomerActivityService,
    private reba: RebaDataService,
    private dataLayerService: DataLayerService,
    private kontent: KontentDeliveryService,
    private seoService: SeoService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private richTextService: RichTextService
  ) {
    this.cas.getActivity().subscribe();
  }

  async ngOnInit() {
    const assessmentData = this.toolsConfig.currentConfig.core.assessmentData.find((page) => page.route === window.location.pathname);
    const educationCards = this.toolsConfig.currentConfig.core.educationalCards.filter((card) => { return assessmentData?.elements.content_cards.value.includes(card.system.codename) });

    const partnerGridItems = this.toolsConfig.currentConfig.core.partnerGrids.find((grid) => { return assessmentData.elements.partner_grid.value.includes(grid.system.codename) });
    const edit = this.route.snapshot.queryParamMap.get('edit');
    this.edit = edit === 'true';
    localStorage.setItem('lastPage', window.location.href);

    if (!assessmentData) {
      window.location.href = '/404';
    } else {
      // NAM-440 temp solution
      let esAssessmentData;

      const langParam = this.route.snapshot.queryParamMap.get('lang');
      if (langParam && ['en','es'].includes(langParam) && localStorage.getItem('language') == null) localStorage.setItem('language', langParam);

      if (assessmentData.elements.assessment_id.value === 'fallsRiskAssessment') { // only falls assessment is in Spanish
        esAssessmentData = await this.kontent.getItem('falls_risk_assessment', { depth: 4, language: 'Spanish' });
        // temporary solution until content is translated
        if (esAssessmentData.item.elements.content_cards.length == 0) esAssessmentData.item.elements.content_cards = assessmentData.elements.content_cards;
        if (esAssessmentData.item.elements.faq_page.length == 0) esAssessmentData.item.elements.content_cards = assessmentData.elements.faq_page;
        if (esAssessmentData.item.elements.partner_grid.length == 0) esAssessmentData.item.elements.content_cards = assessmentData.elements.partner_grid;
      }

      const { value: assessmentID } = assessmentData.elements.assessment_id;
      const { value: description } = assessmentData.elements.description;
      const { value: introduction } = assessmentData.elements.introduction;
      const screeningCode = assessmentData?.elements?.screening_code?.value;

      // NAM-440
      const esDescription = esAssessmentData?.item.elements.description.value;
      const esIntroduction = esAssessmentData?.item.elements.introduction.value;

      let contentCards = [];
      if (assessmentData.elements.content_cards.value.length > 0) {
        assessmentData.elements.content_cards.value.forEach(card => {
          contentCards.push(educationCards.find(c => { return c.system.codename === card }));
        });
      }
      let esContentCards;
      if (esAssessmentData?.item.elements.content_cards.value.length > 0) {
        esContentCards = await Promise.all(esAssessmentData.item.elements.content_cards.value.map(async card => {
          let p = await this.kontent.getItem(card, { depth: 4, language: 'Spanish' });
          return p.item;
        }));
      }
       
      if (esContentCards == undefined) esContentCards = contentCards;

      this.seoService.extractAndRender(assessmentData, {});
      
      let faqContent;
      if (assessmentData.elements.faq_link_text.value && assessmentData.elements.faq_page.value) {
        faqContent = {
          link: assessmentData.elements.faq_link_text.value,
          url: this.toolsConfig.currentConfig.core.assessmentFAQPages.find((page) => { return assessmentData.elements.faq_page.value.includes(page.system.codename) }).elements.url.value
        };
      }
      let esFaqContent;
      if (esAssessmentData?.item.elements.faq_link_text.value && esAssessmentData?.item.elements.faq_page.value) {
        esFaqContent = {
          link: esAssessmentData.item.elements.faq_link_text.value,
          url: esAssessmentData.modular_content[`${esAssessmentData.item.elements.faq_page.value[0]}`]?.elements.url.value
        }
      }
      if (esFaqContent == undefined) esFaqContent = faqContent;


      let esPartnerGridItems;
      if (esAssessmentData?.item.elements.partner_grid.value && esAssessmentData?.modular_content[esAssessmentData.item.elements.partner_grid.value]) {
        esPartnerGridItems = esAssessmentData?.modular_content[esAssessmentData.item.elements.partner_grid.value];
      }
      if (esPartnerGridItems == undefined) esPartnerGridItems = partnerGridItems;

      if(!assessmentID && screeningCode){

        this.screeningCode = screeningCode;

        this.reba.getScreening(screeningCode, '23838').subscribe((result) => { // zipcode is hardcoded

          this.isLoaded = true;
          this.data = result;
          this.pageData = {};

          this.backCTA = {
            url: this.toolsConfig.routes.find(({ type }) => type === 'home').route,
            text: 'Exit',
          };

          this.dataLayerService.push({
            clickUrl: location.href,
            pageTitle: document.title || '',
            contentType: this.screeningCode,
            pageType: 'assessment-page',
          },false);

        });

      } else {
        const assessmentsCompleted = this.toolsConfig.customerActivity.basic.completedAssessments;
        const currentAssessment = assessmentData.elements.assessment_id;

        if( assessmentsCompleted.includes( currentAssessment.value ) ) {
          this.isCompleted = true;
          this.currentAssessmentID = currentAssessment.value;

          // Push analytics data
          this.dataLayerService.push({
            clickUrl: location.href,
            pageTitle: document.title || '',
            contentType: this.currentAssessmentID,
            pageType: 'assessment-summary-page',
          },false);
        }

        const isSinglePageAssessment = assessmentData.elements.single_page.value.find(v => { return v.codename === 'true' }) !== undefined;

        const subCopy = assessmentData.elements.sub_copy.value;
        let esSubCopy;
        if (esAssessmentData?.item.elements.sub_copy.value) {
          esSubCopy = esAssessmentData.item.elements.sub_copy.value;
        }
        if (esSubCopy == undefined) esSubCopy = subCopy;
        
        const assessmentRes = await this.assessment.startAssessmentResponse(assessmentID, 0).first().toPromise();
        
        if (!assessmentRes) window.location.href = '/404';
        const assessment = assessmentRes.assessment; 
        const { questions, sectionGroups } = this.mapAssessmentData(assessment);

        let esQuestions, esSectionGroups;
        if (esAssessmentData) {
          const esAssessment = await this.assessment.startAssessmentResponse(`es-${assessmentID}`, 0).first().toPromise();
          const esDataContent = this.mapAssessmentData(esAssessment.assessment);
          esQuestions = esDataContent.questions;
          esSectionGroups = esDataContent.sectionGroups;
        }
        if (esQuestions === undefined) esQuestions = questions;
        let bannerText = '';
        if(assessmentData.elements?.sign_up_banner_text?.value){
          bannerText = assessmentData.elements.sign_up_banner_text
        }
        this.data = {
          en: {
            title: assessmentData.elements.title.value,
            description,
            introduction,
            questions: isSinglePageAssessment && sectionGroups.length > 0 ? sectionGroups[0].questions : questions,
            contentCards,
            partnerGrid: partnerGridItems,
            faqContent,
            isSinglePageAssessment,
            sections: sectionGroups,
            subCopy,
            assessmentData,
            bannerText: bannerText
          }
        };
        if (esAssessmentData) {
          let esBannerText = '';
          if(esAssessmentData.item.elements?.sign_up_banner_text?.value){
            esBannerText = esAssessmentData.item.elements.sign_up_banner_text;
          }
          this.data.es = {
            title: esAssessmentData.item.elements.title.value,
            description: esDescription,
            introduction: esIntroduction,
            questions: esQuestions,
            contentCards: esContentCards,
            partnerGrid: esPartnerGridItems,
            faqContent: esFaqContent,
            isSinglePageAssessment,
            sections: esSectionGroups,
            subCopy: esSubCopy,
            assessmentData: esAssessmentData.item,
            bannerText: esBannerText
          }
        }
        
        this.pageData = {
          navCTA: this.toolsConfig.currentConfig.navData.mainLogo,
        };

        this.backCTA = {
          url: this.toolsConfig.routes.find(({ type }) => type === 'home').route,
          text: 'Exit',
        };

        this.isLoaded = true;

        this.dataLayerService.push({
          clickUrl: location.href,
          pageTitle: document.title || '',
          contentType: this.assessmentID,
          pageType: 'assessment-page',
        },false);
      }
  
    }
    
    this.globalService.loadFooter(true);
  }
        
  mapAssessmentData(assessment: any) {
    const questions = [];
    const { id, version, sections } = assessment;

    this.assessmentID = id;

    this.version = version;

    let sectionGroups = [];

    sections.forEach((section) => {
      const { questionGroups } = section;

      let sectionQuestions = [];

      questionGroups.forEach((questionGroup) => {
        const { condition, questions: _questions } = questionGroup;

        _questions.forEach((question) => {
          let { answerGroup } = question.question;
          const { answerType, answers } = answerGroup;

          if (answerType === 'multiSelect') {
            answers.forEach((answer, index) => {
              answers[index] = {
                ...answer,
                isSelected: false,
              };
            });

            answerGroup = {
              ...answerGroup,
              answers,
            };
          }

          questions.push({
            index: questions.length,
            ...question.question,
            answerGroup,
            disabled: false,
            condition,
            userAnswer: null,
            error: null,
          });

          sectionQuestions.push({
            index: sectionQuestions.length,
            ...question.question,
            answerGroup,
            disabled:  condition && condition.dependentId ? true : false,
            condition,
            userAnswer: null,
            error: null,
          });
        });
      });

      sectionGroups.push({
        index: sectionGroups.length,
        label: section.label,
        questions: sectionQuestions
      })
    });

    return { questions, sectionGroups };
  }

  toggleLoadingOverlay(toggle: boolean): void {
    this.showLoadingOverlay = toggle;
  }

  async onFinish(questionResponse) {
    await this.removeQueryParams();

    this.showLoadingOverlay = true;
    if (this.assessmentID === 'es-fallsRiskAssessment') this.assessmentID = 'fallsRiskAssessment'; // default back to English assessment for data submission


    localStorage.setItem(this.assessmentID, JSON.stringify(questionResponse));

    this.assessment.respondToQuestions(this.assessmentID, this.version, questionResponse)
      .pipe(take(1))
      .subscribe(() => {
        this.assessment.hasNewData(this.assessmentID).then(() => {
          this.cas.getActivity().pipe(take(1)).subscribe((data) => {
            if (this.assessmentID === 'fallsRiskAssessment') {
              let category = data?.fallsRisk?.category || data?.basic?.fallsRisk || '';
              category = category.toLowerCase().replaceAll(' ', '');
              if(data.fallsRisk){
                localStorage.setItem('fallsRisk', JSON.stringify(data.fallsRisk));
              }
              setTimeout( () => {
                window.location.href += (window.location.href.indexOf('?') > 0 ? `&category=${category}` : `?category=${category}`);
              }, 2000);
            } else if (this.assessmentID === 'medicareCostEstimator' || this.assessmentID === 'retirementIncomeBreakdown') {
              setTimeout( () => {
                window.location.reload();
              }, 2000);
            } else {
              window.location.href = this.toolsConfig.rootURL;
            }
          });
        });
      });
  }
  async removeQueryParams() {
    // update route with no query params
  const queryParams = { ...this.route.snapshot.queryParams };
  delete queryParams.edit;
  await this.router.navigate([], { queryParams });
  }
}
