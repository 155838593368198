<div class="component component--donate">
  <div class="component__container component-spacing">
    <div class="component__wrapper">
      <div class="donate">
        <div class="donate__header">
          <h2 class="donate__heading">{{ heading }}</h2>
          <div class="donate__description" [innerHTML]="description"></div>
        </div>
        <div class="donate__photo" aria-hidden="true">
          <img *ngIf="!responsive" src="{{ background }}" alt="{{ backgroundCaption }}" loading="lazy"/>
          <picture *ngIf="responsive">
            <source *ngFor="let item of responsive" [srcset]="item.url" [media]="item.media" />
            <img [src]="responsive[0].url" [alt]="backgroundCaption" loading="lazy"/>
          </picture>
        </div>
        <div class="donate__body">

          <div class="donate__form">
            <div class="donate__frequency">
              <label>
                <input type="radio" name="frequency" checked="checked" (change)="handleSelectOption($event)"
                  value="one_time" aria-label="Donate One time" />
                <span aria-hidden="true">One Time</span>
              </label>
              <label>
                <input type="radio" name="frequency" (change)="handleSelectOption($event)" value="monthly"
                  aria-label="Donate Monthly" />
                <span aria-hidden="true">Monthly</span>
              </label>
            </div>

            <div class="donate__amount">
              <p>Select gift amount:</p>
              <div class="donate__amount__options">
                <label>
                  <input type="radio" name="amount" (change)="handleSelectOption($event)" value="25" aria-label="$25">
                  <span aria-hidden="true">$25</span>
                </label>
                <label>
                  <input type="radio" name="amount" (change)="handleSelectOption($event)" value="50" aria-label="$50">
                  <span aria-hidden="true">$50</span>
                </label>
                <label>
                  <input type="radio" name="amount" (change)="handleSelectOption($event)" value="100" aria-label="$100">
                  <span aria-hidden="true">$100</span>
                </label>
              </div>
              <div class="donate__amount__custom">
                <span>or</span>
                <div class="custom-amount-container">
                  <span aria-hidden="true">$</span>
                  <label for="custom-amount" class="visually-hidden">Enter own amount</label>
                  <input type="text" id="custom-amount" [(ngModel)]="customAmount" placeholder="Enter own amount"
                    (input)="handleCustomAmount($event)" />
                </div>
              </div>
            </div>

            <div class="donate__cta">
              <p>{{ form_desc }}</p>
              <button class="donate__button" (click)=handleSubmit()>Donate Now</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
