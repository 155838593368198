import { Injectable } from '@angular/core';
import { CTAButtonComponent } from '@components/planner/cta-button/cta-button.component';
import { NextStepComponent } from '@components/planner/next-step/next-step.component';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { AssessmentService } from './assessment.service';
import { ToolsPrimaryConfigService } from './tools-primary-config.service';

@Injectable({ providedIn: 'root' })
export class FallsRiskService {
  constructor(
    private tools: ToolsPrimaryConfigService,
    private kontent: KontentDeliveryService,
    private assessment: AssessmentService,
  ) { }

  async getData() {
    let data: any = {};
    let codename = this.tools.currentConfig.core.route.codename;

    const tool = this.tools.modular_content[codename];

    const resultCodename = tool.elements.summar_page_copy.value[0];

    const resultPages = await Promise.all([
      this.kontent.getItem(resultCodename, { depth: 4, language: 'default' }),
      this.kontent.getItem(resultCodename, { depth: 4, language: 'Spanish' })
    ]);

    const enResultPage = resultPages[0];
    let esResultPage = resultPages[1];

    if (esResultPage.item.elements.partner_grid.value.length === 0) esResultPage.item.elements.partner_grid = enResultPage.item.elements.partner_grid;

    Object.keys(enResultPage.modular_content).forEach(prop => {
      if (esResultPage.modular_content[`${prop}`] === undefined) {
        esResultPage.modular_content[`${prop}`] = enResultPage.modular_content[`${prop}`];
      }
    });

    [data.en, data.es] = await Promise.all([this.extractContent(enResultPage, tool, 'en'), this.extractContent(esResultPage, tool, 'es')]);

    return data;
  }

  async extractContent(resultPage: any, tool, lang) {
    let data:any = {};
    const { item, modular_content } = resultPage;
    const { elements } = item;
    
    const partnerGridItems = modular_content[`${elements.partner_grid.value[0]}`];

    const riskCategory = this.tools.customerActivity?.fallsRisk?.category || 'Unknown';
    let categorySummary, categoryName, reportContent, categoryNameContent, categoryCode;
    if (riskCategory === 'Normal') {
      categoryCode = modular_content.normal.system.codename;
      categorySummary = modular_content.normal.elements;
      categoryName = modular_content.normal.elements.category_name.value;
      reportContent = modular_content.normal_risk_falls_report.elements;
      categoryNameContent = `<span style='color: green'>${categoryName}</span>`;
    } else if (riskCategory === 'High - Medication') {
      categoryCode = modular_content.high___medication.system.codename;
      categorySummary = modular_content.high___medication.elements;
      categoryName = modular_content.high___medication.elements.category_name.value;
      reportContent = modular_content.high_risk___medication_risk_falls_report.elements;
      categoryNameContent = `<span style='color: red'>${categoryName}</span>`;
    } else if (riskCategory === 'High - Mixed') {
      categoryCode = modular_content.high___mixed.system.codename;
      categorySummary = modular_content.high___mixed.elements;
      categoryName = modular_content.high___mixed.elements.category_name.value;
      reportContent = modular_content.high_risk___mixed_risk_falls_report.elements;
      categoryNameContent = `<span style='color: red'>${categoryName}</span>`;
    } else if (riskCategory === 'High - Physical Therapy') {
      categoryCode = modular_content.high___physical_therapy.system.codename;
      categorySummary = modular_content.high___physical_therapy.elements;
      categoryName = modular_content.high___physical_therapy.elements.category_name.value;
      reportContent = modular_content.high_risk___physical_therapy_falls_report.elements;
      categoryNameContent = `<span style='color: red'>${categoryName}</span>`;
    } else {
      console.log('Unknown risk category: ' + this.tools.customerActivity?.fallsRisk?.category);
    }

    // insert score into category description
    const userAnsweredYesIds = Object.keys(this.tools.customerActivity?.fallsRisk?.answers).filter(a => this.tools.customerActivity?.fallsRisk?.answers[`${a}`] > 0);
    const fallsScore = Object.values(this.tools.customerActivity?.fallsRisk?.answers).reduce((sum: number, a: number) => sum + a);
    let categoryDescription = categorySummary.category_description.value.replace(/\{{(.+?)\}}/g, fallsScore);
    let yesQuestions = await Promise.all(userAnsweredYesIds.map(q => { return this.assessment.getQuestion((lang === 'es' ? `es-${q}` : q)) }));
    yesQuestions = yesQuestions.map(q => { return { label: q.question.label, hint: q.question.hint } });
    let whyItMattersReportContent = '';
    yesQuestions.forEach(q => {
      whyItMattersReportContent += `<p>${q.label}</p><ul><li>${q.hint}</li></ul>`
    });

    // populate prevention tips
    let preventionTips;
    if (riskCategory === 'Normal') {
      // expect only a single prevention tip
      preventionTips = [modular_content.normal_risk_tips];
    } else if (riskCategory.startsWith('High')) {
      const highRiskTips = categorySummary.tips.value; // array of codenames for high risk tips
      let highRiskTipsContent = [];
      highRiskTips.forEach(tip => {
        const tipContent = modular_content[`${tip}`];
        if (tipContent.elements.question_id.value !== '' && userAnsweredYesIds.includes(tipContent.elements.question_id?.value)) highRiskTipsContent.push(tipContent);
        if (tipContent.elements.question_id.value === '') highRiskTipsContent.push(tipContent);
      });
      // prevention tips are filtered on which questions a user answered Yes
      preventionTips = highRiskTipsContent;
    } else { 
      console.log('Unknown risk category: ' + this.tools.customerActivity?.fallsRisk?.category);
    }

    // populate next steps
    let nextSteps: NextStepComponent[] = [];
    if (categorySummary.next_steps.value.length > 0) {
      const nextStepCodeNames = categorySummary.next_steps.value;
      nextStepCodeNames.forEach(step => {
        const stepData = modular_content[`${step}`];
        let nextStep = {
          content: stepData.elements.content.value
        } as NextStepComponent;
        if (stepData.elements.cta.value.length > 0) {
          const stepCTAData = modular_content[`${stepData.elements.cta.value[0]}`];
          const ctaButton = {
            label: stepCTAData.elements.label.value,
            type: stepCTAData.elements.type.value[0].codename,
            link: stepCTAData.elements.link.value,
            configClass: stepCTAData.elements.class.value
          };
          nextStep.ctaButton = ctaButton;
        }
        if (stepData.elements.modal.value.length > 0) {
          const modalData = modular_content[`${stepData.elements.modal.value[0]}`];
          nextStep.modalData = modalData.elements;
        }
        nextStep.icon = stepData.elements.icon.value[0];
        nextSteps.push(nextStep);
      });
    }

    // Generate the data object for the falls-risk component to display

    // breakdown header
    data['breakdownHeader'] = {
        id: tool.system.id,
        codename: this.tools.currentConfig.core.route.codename,
        isSaved: (this.tools.customerActivity?.basic?.SavedContent || []).find(({ ContentId }) => ContentId === tool.system.id),
        backLink: {
            url: '', // to remove the My Planner link: this.tools.routes.find(({ type }) => type === 'home').route,
            text: '',
        },
        profileLink: {
            url: this.tools.routes.find(({ type }) => type === 'profile').route,
            text: 'Edit Answers',
        },
        headline: elements.title.value,
    };

    // falls risk date
    data['screeningDate'] = {
      label: elements.subtitle.value,
      date: new Date().toLocaleDateString(lang, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }).replace(/,/g, "")
    }

    // falls risk category
    data['category'] = {
      label: elements.falls_risk_label.value,
      categoryName,
      description: categoryDescription,
      categoryCode
    }
    // buttons and things
    data.bannerText = elements?.banner_text?.value;
    data.bannerCta = elements?.banner_cta?.value;
    data.bannerSubText = elements?.banner_subtext;
    data.print = elements?.print?.value;
    data.startOver = elements?.start_over?.value; 
    data.logIn = elements?.log_in?.value;
    data.logOut = elements?.log_out?.value;
    
    // next steps
    data['recommendations'] = {
      title: categorySummary.recommendations_title.value,
      content: categorySummary.recommendations_content.value,
      nextSteps
    }

    // what can you do to prevent falls?
    data['prevention'] = {
      title: categorySummary.tips_title.value,
      content: preventionTips
    }

    // resources
    data['resources'] = {
      title: categorySummary.resources_title.value,
      content: categorySummary.resources_content.value
    }

    // partner grid
    data['partnerGrid'] = partnerGridItems;

    // report
    data['report'] = {
      reportFileName: reportContent.report_file_name.value,
      title: reportContent.report_title.value,
      date: `${elements.subtitle.value} ${new Date().toLocaleDateString(lang, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }).replace(/,/g, "")}`,
      statement: reportContent.statement.value.replace(/\{{(score)\}}/g, fallsScore).replace(/\{{(risk_category)\}}/g, categoryNameContent),
      tips: {
        title: reportContent.tips_title.value,
        content: reportContent.tips_content.value
      },
      resources: {
        title: reportContent.resources_title.value,
        content: reportContent.resources_content.value
      },
      ...(fallsScore > 0 && { whyItMatters: {
        title: reportContent.why_it_matters_title.value,
        content: whyItMattersReportContent
      }}),
      closingStatement: {
        content: reportContent.closing_statement_content.value
      },
      ...(fallsScore > 3 && { provider: { // provider section for high risk
        title: reportContent.provider_statement_title.value,
        intro: reportContent.provider_statement_introduction.value.replace(/\{{(score)\}}/g, fallsScore),
        assessmentAreaTitle: reportContent.provider_assessment_area_title.value,
        assessmentAreaContent: whyItMattersReportContent, // is this correct..?
        closingStatement: reportContent.provider_statement_closing.value
      }}),
      partnerRecognitionContent: reportContent.partner_recognition_content.value,
      logos: {
        logo1: reportContent.logo_1.value[0],
        logo2: reportContent.logo_2.value[0]
      }
    }

    // email template data
    const emailData = modular_content[categorySummary.report_email_template.value[0]];
    data['emailTemplate'] = {
      from: emailData.elements.from.value,
      subject: emailData.elements.subject.value,
      body: emailData.elements.body.value,
      reportFileName: reportContent.report_file_name.value
    }

    return data;
  }
}