import { trigger, transition, style, sequence, animate, query, stagger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, Input, QueryList, IterableDiffers, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { interval, Subject } from 'rxjs';
import { buffer } from 'rxjs/operators';
import { ToDo, ToDoAction, ToDoComponent } from '../to-do/to-do.component';

@Component({
  selector: 'app-to-do-list',
  templateUrl: './to-do-list.component.html',
  styleUrls: ['./to-do-list.component.scss']
})
export class ToDoListComponent implements OnInit, DoCheck, OnDestroy {

  @Input() content: any;
  @Input() todos: ToDo[];
  @Input() scrollToTarget?: string; 
  @Input() source: "budget_calculator_plan" | "workforce_plan";
  @Output() onArchived: EventEmitter<ToDo> = new EventEmitter();
  @Output() onComplete: EventEmitter<ToDo> = new EventEmitter();
  @Output() onIncomplete: EventEmitter<ToDo> = new EventEmitter();
  @Output() onUnarchived: EventEmitter<ToDo> = new EventEmitter();

  // true = increase by 1, false = decrease by 1
  @Output() onUpdateListTotal: EventEmitter<ToDoAction> = new EventEmitter();

  // @ViewChildren('end') todoItems: QueryList<ElementRef>; //div marking end of Todo card 

  @ViewChildren(ToDoComponent) todoItems: QueryList<ToDoComponent>;
  
  private diff: any;

  public sortedTodos: {[goal: string]: ToDo[]}

  public done: boolean;

  private goals: any[];
  
  private receivedToDos: Set<string> = new Set();
  private viewedToDos:  Set<string> = new Set();
  private throttler: Subject<any> = new Subject<any>();


  constructor(private iterableDiffers: IterableDiffers,
     private kentico: KontentDeliveryService,
    private customerActivityService: CustomerActivityService,) { 
    this.goals = [];

        // buffer all emissions over a period, only push last values to CAD
        this.throttler
        .pipe(buffer((interval(250))))
        .subscribe(async(val) =>{
          const lastVal = val[val.length-1]
          if(lastVal){
            await this.customerActivityService.updateActivity(lastVal).toPromise()
          }
        });
  }

  ngOnDestroy(): void {

  }

  ngDoCheck(): void {
    let changes = this.diff.diff(this.todos)
    if (changes) {
       this.buildTodos()
    }
  }



  async ngOnInit() { 
    this.diff = this.iterableDiffers.find(this.todos).create();
    await this._getGoals();
    this.buildTodos();
  }

  ngAfterViewInit(){
    const options = {
      root: null, // default to viewport
      threshold: 1 // 100% of element must be in viewport
    };

    const observer = new IntersectionObserver((result) => {
      result.forEach(item => {
        // item has dimensions (not hidden) and is intersecting viewport
        if (item.boundingClientRect.height > 0 && item.boundingClientRect.width > 0 && item.isIntersecting) {
          this.addToList(item.target.id)
        }
      })
    }, options);

    this.todoItems.changes.subscribe(() => {
      this.todoItems.forEach(todo => {
        observer.observe(todo.todoDiv.nativeElement)
      });
    })
  }

 
  async pushTodoDataToCad() {
    const tdData = {};
    tdData['viewed'] = {};
     this.viewedToDos.forEach((todoName)=>{
      tdData['viewed'][todoName] = 'viewed'
    })
    const questionData = { 'tdData': tdData };
    if (this.viewedToDos.size > 0 )
      await this.throttler.next(questionData)

  }

   // Add unique items to set
  addToList(element: string) {
    const viewedLength = this.viewedToDos.size
    this.viewedToDos.add(element);
    //number of viewed questions changes, add to CAD
    if (this.viewedToDos.size > viewedLength) {
      this.pushTodoDataToCad()
    }

  }
  
  private async _getGoals(){
    const kenticoTags = await this.kentico.getTaxonomiesAndCache('awp_to_do_tags');

    const traverse = (tags: any[]) => {

      for(const tag of tags){

        if(tag.codename === 'budget_calculator_questions' || tag.codename === 'job_skills_checkup_questions'){

          traverse(tag.terms)

        }else if ((this.source === "budget_calculator_plan" && tag.codename === 'what_money_management_goals_are_important_to_you_') || (this.source === "workforce_plan" && tag.codename === 'what_job_goals_are_important_to_you__select_all_that_apply_')){
          this.goals = tag.terms.map((tag) => tag.name)
          break;
        }


      }


    }

    traverse(kenticoTags.terms)

  }

  public async buildTodos(){



    if(!this.goals.length){
      return
    }


    this.sortedTodos = {"Miscellaneous": []};

    this.todos.sort((a, b) => { // sort the todos in correct order based of their goals

      a.goals.sort((a1, b1) => this.goals.indexOf(a1) - this.goals.indexOf(b1));

      b.goals.sort((a2, b2) => this.goals.indexOf(a2) - this.goals.indexOf(b2));

      return this.goals.indexOf(a.goals[0]) - this.goals.indexOf(b.goals[0])
    })


    for(const todo of this.todos){

      if(!todo.goals || !todo.goals.length){
        todo.goals = ["Miscellaneous"]
      }


      if(!(todo.goals[0] in this.sortedTodos)){
        this.sortedTodos[todo.goals[0]] = []
      }

      this.sortedTodos[todo.goals[0]].push(todo)

    }

    // go through and sort the arrays based on order

    for(const key of Object.keys(this.sortedTodos)){

      this.sortedTodos[key].sort((a, b) => (a.order ?? Number.MAX_VALUE) - (b.order ?? Number.MAX_VALUE))

    }

  }

  public keepOrder(a, b){
    return 0
  }

  public track(item, index){
    return index;
  }

}
