import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { GlobalService } from '@services/global.service';


@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ServerErrorComponent implements OnInit {

  contentType: string = 'standard_page';
  components: any[];

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.kontentDeliveryService.getItem(null, {
      'system.type': this.contentType,
      'elements.url': '500',
      limit: 1,
    })
      .then(data => {
        this.components = [
          { componentType: 'header', ...(data.items[0]?.elements || {})  }
        ];

        this.globalService.loadFooter( this.components.length > 0 ? true : false );
      });
  }

}
