import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-amount-question',
  templateUrl: './amount-question.component.html',
  styleUrls: ['./amount-question.component.scss']
})
export class AmountQuestionComponent implements OnInit {
  @Input() label: string;
  @Input() typeLabel: string;
  @Input() form: FormGroup;
  @Input() formGroupName: string;
  @Input() monthlyLabel: string;
  @Input() quarterLabel: string;
  @Input() annualLabel: string;
  @Input() placeholder: string;
  @Input() tooltipText: string;
  @Input() disableInputs?: boolean = false;

  activeElementTooltip: Element;
  public showTooltip: boolean;

  constructor() { 
    this.showTooltip = false;
  }

  ngOnInit(): void {
    if(this.disableInputs){
      this.form.get(this.formGroupName).disable();
    }
  }

  openTooltip( event: Event ): void {
    let _currentTarget = event.currentTarget as Element | any;
    _currentTarget.classList.add('is-open');
    this.activeElementTooltip = _currentTarget;
    this.fillCurrentTooltip(true);
    event.stopPropagation();
  }

  closeTooltip( event: Event ): void {
    let _currentTarget = event.currentTarget as Element;

    _currentTarget.parentElement.parentElement.classList.remove('is-open');

    this.fillCurrentTooltip(false);
    event.stopPropagation();
  }

  fillCurrentTooltip(toFill) {
    if (toFill) {
      let _temp = document.querySelectorAll(`icon-is-active`);
      _temp.forEach(i => {
        i.classList.remove('icon-is-active');
      });
      this.activeElementTooltip.classList.add('icon-is-active');
    }
    else {
      this.activeElementTooltip.classList.remove('icon-is-active');
    }
  }

}
