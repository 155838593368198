import { AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Observable, of, from } from "rxjs";
import { delay, switchMap, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

export class NCOAValidators{

    static minDate(date: string) : ValidatorFn{
        return (control: AbstractControl) => {

            if(!control.value){
                return null;
            }

            const fieldDate = new Date(control.value);
            const validatorDate = new Date(date);


            return fieldDate.getTime() >= validatorDate.getTime() ? null : {
                'minDate':{
                    'minDate': validatorDate.toDateString(),
                    'actual': fieldDate.toDateString()
                }
            }
        }
    }

    static maxDate(date: string) : ValidatorFn{
        return (control: AbstractControl) => {

            if(!control.value){
                return null;
            }

            const fieldDate = new Date(control.value);
            const validatorDate = new Date(date);


            return fieldDate.getTime() <= validatorDate.getTime() ? null : {
                'maxDate':{
                    'maxDate': validatorDate.toDateString(),
                    'actual': fieldDate.toDateString()
                }
            }
        }
    }

    static isZipcode(): AsyncValidatorFn{

        return (control: FormControl): Observable<ValidationErrors | null> => {

            return of(control.value).pipe(
                delay(500),
                switchMap((val) => from(fetch(`${environment.ncoaApiBaseUrl}/location/getFromZip?zip=${val}`)
                .then((resp) => resp.json()))
                .pipe(map(json => json.success ? null : {'isZipcode': true})))
            )


        }

    }

    static isCurrency(currency: string){

    }

    static minSelect(select: number){
        return (control: any) => {

            if(!control.value){
                return null;
            }
            
            return control.value.length >= select ? null : {'minSelect': {'minSelect': select, 'actual': length}}
        }
    }

    static maxSelect(select: number){
        return (control: any) => {

            if(!control.value){
                return null;
            }

            return control.value.length <= select ? null : {'maxSelect': {'maxSelect': select, 'actual': length}}
        }
    }

}