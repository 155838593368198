<ng-container>

  <div class="page-top-controls">
    <a class="back-to-list-btn " (click)="backToList()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M7.26498 3.235C7.3352 3.30531 7.37465 3.40062 7.37465 3.5C7.37465 3.59938 7.3352 3.69469 7.26498 3.765L3.4056 7.625L13.5 7.625C13.5994 7.625 13.6948 7.66451 13.7651 7.73484C13.8355 7.80516 13.875 7.90054 13.875 8C13.875 8.09946 13.8355 8.19484 13.7651 8.26517C13.6948 8.33549 13.5994 8.375 13.5 8.375H3.4056L7.26498 12.235C7.33121 12.3061 7.36728 12.4001 7.36556 12.4973C7.36385 12.5944 7.32449 12.6871 7.25579 12.7558C7.18708 12.8245 7.09439 12.8639 6.99724 12.8656C6.90009 12.8673 6.80606 12.8312 6.73498 12.765L2.23498 8.265C2.16475 8.19469 2.12531 8.09938 2.12531 8C2.12531 7.90063 2.16475 7.80531 2.23498 7.735L6.73498 3.235C6.80529 3.16477 6.9006 3.12533 6.99998 3.12533C7.09935 3.12533 7.19466 3.16477 7.26498 3.235Z"
          fill="#0B4A5D" />
      </svg>
      <span>Back to List</span>
    </a>
  </div>
        <div *ngFor="let question of questionsArray">
            <ng-container *ngTemplateOutlet="questionTemplate; context: {question:(question)}"></ng-container>
        </div>


</ng-container>



<ng-template #questionTemplate let-question="question">
    <span class="question-label"> {{question.questionText}}</span>
    <div [ngSwitch]="question.answerType">

        <ng-container *ngSwitchCase="'date'" [ngSwitch]="question.key">
            <input [disabled]="true" class="form-field" *ngSwitchDefault [id]="question.key"
                (ngModelChange)="answerChanged(question.key)" type="date">
        </ng-container>

        <select [disabled]="true" class="form-field" *ngSwitchCase="'select'" [value]="question.answer"
            (change)="answerChanged(question.key)">
            <option class="select-default" [ngValue]="null" disabled></option>
            <option [disabled]="isCompleted" [value]="option.value" *ngFor="let option of question.options">
                {{option.text.en }}</option>
        </select>
        <section class="radio-group" *ngSwitchCase="'boolean'">
            <div class="container">
                <input type="radio" [checked]="booleanOption(question.answer,'true')" [attr.disabled]="true"
                    (ngModelChange)="answerChanged(question.key)" />
                <label>{{question.options[0].text['en'] }}</label>
            </div>

            <div class="container">
                <input type="radio" [checked]="booleanOption(question.answer,'false')" [attr.disabled]="true"
                    (ngModelChange)="answerChanged(question.key)" />
                <label>{{question.options[1].text['en'] }}</label>
            </div>
        </section>

        <app-multi-select [disabled]="true" [header]="" [min]="$any(question).min" [max]="$any(question).max"
            *ngSwitchCase="'multiSelect'" [question]="question" [items]="question.options"
            (checkBoxChangeEvent)="answerChanged(question.key)" [readonly]="true"></app-multi-select>

        <input [mask]="$any(question).mask" class="form-field" *ngSwitchCase="'shortText'" type="text"
            [attr.maxLength]="$any(question).maxLength" (blur)="answerChanged(question.key)">

        <textarea class="form-field" *ngSwitchCase="'longText'" [attr.maxLength]="$any(question).maxLength"
            (blur)="answerChanged(question.key)"></textarea>

        <input class="form-field" (keydown)="$event.keyCode !== 190 && $event.keyCode !== 189" *ngSwitchCase="'number'"
            step="1" type="number" [attr.min]="$any(question).min || '0'" [attr.max]="$any(question).max"
            [id]="question.key" (blur)="answerChanged(question.key)">

        <input class="form-field" *ngSwitchCase="'decimal'" step="0.01" type="number" [attr.min]="$any(question).min"
            [attr.max]="$any(question).max" [id]="question.key" (blur)="answerChanged(question.key)">

        <input class="form-field" *ngSwitchCase="'currency'" type="number" [id]="question.key"
            (blur)="answerChanged(question.key)">


        <!-- <div class="question-errors">
    
            <div>
    
                <ng-container [ngSwitch]="error.key">
    
                    <span *ngSwitchCase="'required'"></span>
    
                    <span *ngSwitchCase="'max'"></span>
    
                    <span *ngSwitchCase="'min'"></span>
    
                    <span *ngSwitchCase="'maxLength'"></span>
    
                    <span *ngSwitchCase="'minLength'"></span>
    
                    <span *ngSwitchCase="'minSelect'"></span>
    
                    <span *ngSwitchCase="'maxSelect'"></span>
    
                    <span *ngSwitchCase="'isPhoneNumber'"></span>
    
                    <span *ngSwitchCase="'isCurrency'"></span>
    
                    <span *ngSwitchCase="'isEmail'"></span>
    
                    <span *ngSwitchCase="'isZipcode'"></span>
    
                    <span *ngSwitchCase="'maxDate'"></span>
    
                    <span *ngSwitchCase="'minDate'"></span>
    
                </ng-container>
    
            </div>
    
        </div> -->
    </div>
</ng-template>