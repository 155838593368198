import { Component, OnInit, Input } from '@angular/core';

import { DataLayerService } from '@services/data-layer.service';
import { ArticleService } from '@services/article.service';

const userVotedArticles = (window as any).localStorage.getItem('userVotedArticles') || '[]';

@Component({
  selector: 'ncoa-article-feedback',
  templateUrl: './article-feedback.component.html',
  styleUrls: ['./article-feedback.component.scss']
})
export class ArticleFeedbackComponent implements OnInit {
  @Input() votes?: number = 0;
  @Input() article: string = '';
  @Input() slug: string = '';
  @Input() id: string = '';
  loading: boolean = false;

  voted: boolean = false;

  choice?: string = '';
  private userVotedArticles: ({
    id: (string), // article id
    vote: (string) // upvote|downvote
  })[] = JSON.parse(userVotedArticles);

  constructor(
    private dataLayerService: DataLayerService,
    private articleService: ArticleService
  ) { }

  ngOnInit(): void {
    const votedArticle = this.userVotedArticles.find(article => article.id === this.id);

    if ( votedArticle ) {
      this.choice = votedArticle.vote;
      this.voted = true;
    }
  }

  onChoiceClick(event, choice) {
    event.preventDefault();

    if ( this.choice === choice ) {
      return;
    }

    const revote = this.choice ? true : false;

    this.loading = true;

    this.choice = choice;

    // Update content
    this.articleService.sendArticleVote(this.id, this.choice, revote)
    .then( (votePercent: any) => {
      this.votes = votePercent;
      this.saveSession();
    })
    .catch(error => console.warn('voting error:', error))
    .finally(() => this.loading = false)
  }

  saveSession() {
    const votedArticleIndex = this.userVotedArticles.findIndex(article => article.id === this.id);

    // Save session
    if ( votedArticleIndex < 0 ) {
      this.userVotedArticles.push({
        id: this.id,
        vote: this.choice
      });
    }
    else {
      this.userVotedArticles[votedArticleIndex] = {
        id: this.id,
        vote: this.choice
      };
    }

    // Fire analytics
    this.dataLayerService.push({
      articleFeedback: this.choice,
      url: location.href
    }, false);

    (window as any).localStorage.setItem('userVotedArticles', JSON.stringify(this.userVotedArticles));
  }
}
