import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private routerService: Router) { }

  notFound() {
    this.routerService.navigate(['/404']);
  }

  serverError() {
    this.routerService.navigate(['/500']);
  }
}
