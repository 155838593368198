import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import fetch from 'node-fetch';
import { CustomerActivityService } from './customer-activity.service';
import { ToolsPrimaryConfigService } from './tools-primary-config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AssessmentService {

  answeredQuestions: QuestionResponse[] = [];
  private readonly assessmentsBaseUrl: string;
  private readonly questionsBaseUrl: string;
  private readonly ncoaApiBaseUrl: string;

  constructor(
    private userService: UserService,
    private cas: CustomerActivityService,
    private tools: ToolsPrimaryConfigService,
    private http: HttpClient
  ) {
    this.assessmentsBaseUrl = (environment as any)?.assessmentAssessmentsBaseUrl || '';
    this.questionsBaseUrl = (environment as any)?.assessmentQuestionsBaseUrl || '';
    this.ncoaApiBaseUrl = (environment as any)?.ncoaApiBaseUrl || '';
  }

  startAssessmentResponse(id: string, version: number): Observable<any> {
    const requestBody: AssessmentResponse = {
      assessment: { id, version },
      source: { id: 'angular_awp', name: 'angular_awp' },
      organizationId: { id: 'ncoa', name: 'ncoa' }
    };
    return this.userService.makeSignedCall('POST', 'assessments/response', {}, this.assessmentsBaseUrl, requestBody);
  }

  async getAssessment(id: string) {
    return await fetch(`${this.ncoaApiBaseUrl}/assessments/${id}`)
      .then(res => res.json());
  }

  async getQuestion(id: string) {
    return await fetch(`${this.ncoaApiBaseUrl}/questions/${id}`)
      .then(res => res.json());
  }

  generateFallsReport(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as any
    };
    return this.http.post(`${this.ncoaApiBaseUrl}/location/generateFallsReport`, data, httpOptions);
  }

  getAllAnsweredQuestions() {
    return this.answeredQuestions;
  }

  respondToQuestions(assessmentId: string, assessmentVersion: number, questionResponses: QuestionResponse[]) {
    if (questionResponses.length === 0) {
      return new Observable(subscriber => subscriber.next('done'));
    }
    this.answeredQuestions = [...this.answeredQuestions, ...questionResponses];
    const requestBody = {
      assessment: { id: assessmentId, version: assessmentVersion },
      questionResponses
    }
    return this.userService.makeSignedCall('POST', 'questions/response', {}, this.questionsBaseUrl, requestBody);
  }

  getUserProfile(assessmentId: string): Observable<any> {
    return this.userService.makeSignedCall('GET', `assessments/profile?ver=awa_awp&assessmentId=${assessmentId}`, {}, this.assessmentsBaseUrl);
  }

  async searchDrug(keyword) {
    return await fetch(`${this.ncoaApiBaseUrl}/medicare/drugSearch?q=${keyword}`)
      .then(res => res.json())
      .then(data => data.drugs);
  }

  async hasNewData(assessmentID) {
    const wait = (ms = 2000) => {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    };

    const pollingFunc = async () => {
      const response: any = await this.cas.getActivity()
        .take(1)
        .toPromise();

      if (response && response?.basic && response.basic?.completedAssessments) {
        return response;
      } else {
        return null;
      }
    };

    const medicareIsStillInProgress = (activity) => {
      if (activity?.healthcare && activity.healthcare?.forecast &&
          activity.healthcare.forecast?.medicareAdvantage &&
          activity.healthcare.forecast?.medigapPartD) {
        const { medicareAdvantage, medigapPartD } = activity.healthcare.forecast;

        if (typeof medicareAdvantage === 'string' || typeof medigapPartD === 'string') {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    let activity = await pollingFunc();
    let shouldStop = false;

    while (activity?.basic?.completedAssessments && !shouldStop) {
      if (assessmentID === 'medicareCostEstimator') {
        if (medicareIsStillInProgress(activity)) {
          shouldStop = false;
          await wait();
          activity = await pollingFunc();
        } else {
          shouldStop = true;
        }
      } else if (assessmentID === 'retirementIncomeBreakdown') {
        if (!activity?.finances?.employmentStatus) {
          shouldStop = false;
          await wait();
          activity = await pollingFunc();
        } else {
          shouldStop = true;
        }
      } else if (assessmentID === 'fallsRiskAssessment') {
        if (!activity?.fallsRisk?.category) {
          shouldStop = false;
          await wait();
          activity = await pollingFunc();
        } else {
          shouldStop = true;
        }
      } else {
        shouldStop = true;
      }
    }

    activity = await pollingFunc();
    this.tools.customerActivity = activity;

    return true;
  }
}

interface AssessmentResponse {
  assessment: {
    id: string,
    version: number
  },
  source: {
    id: string,
    name: string
  },
  organizationId: {
    id: string,
    name: string
  }
}

interface QuestionResponse {
  questionId: string,
  questionVersion: number,
  answer: Answer
}

interface Answer {
  text: string,
  value: any
}
