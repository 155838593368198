import { KeyValue } from '@angular/common';
import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import {GoogleCharts} from 'google-charts';

@Component({
  selector: 'ncoa-breakdown-graph',
  templateUrl: './breakdown-graph.component.html',
  styleUrls: ['./breakdown-graph.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreakdownGraphComponent implements OnInit, OnChanges {

  @Input() headline: string;
  @Input() body: string;
  @Input() twoColBreakdown: any;
  @Input() chartData: any;
  @Input() userIsWorking: boolean;

  totalAmount: any = null;
  hasHundred: boolean = false;

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => { return 0; }

  constructor() {}

  ngOnInit(): void {
    GoogleCharts.load(this.drawChart);
  }

  ngAfterViewInit(): void {
    this.checkIfHasHundred();
  }

  checkIfHasHundred() {
    if (this.chartData) {
      Object.keys(this.chartData).forEach(key => {
        const chartItem = this.chartData[key];
        if (typeof chartItem.value === 'string' && chartItem.value === "100"){
          this.hasHundred = true;
        } else
        if (typeof chartItem.value === 'number' && chartItem.value === 100){
          this.hasHundred = true;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartData) {
      this.chartData = this.chartData;
      this.checkIfHasHundred();

      let total = 0;
      Object.keys(this.chartData).forEach(key => {
        const data = this.chartData[key];
        total += parseInt(data.amount);
      });

      this.totalAmount = Number.isNaN( total ) || !total ? 0 : total;
    }
  }

  drawChart() {
    // Standard google charts functionality is available as GoogleCharts.api after load
    const dataHolder = document.querySelector('.pie-graph__graphics') as HTMLElement;
    const status = dataHolder.dataset.working === 'true';
    const assets = dataHolder.dataset.assets;
    const fixed = dataHolder.dataset.fixed;
    const variable = dataHolder.dataset.variable;
    const benefits = dataHolder.dataset.benefits;

    const pieData = status ? [
      ['Breakdown Label', 'Breakdown Amount'],
      ['Assets', assets ? parseInt(assets) : 0],
      ['Fixed Income', fixed ? parseInt(fixed) : 0],
      ['Variable Income', variable ? parseInt(variable) : 0],
    ] : [
      ['Breakdown Label', 'Breakdown Amount'],
      ['Fixed Income', fixed ? parseInt(fixed) : 0],
      ['Benefit Programs', benefits ? parseInt(benefits) : 0],
      ['Variable Income', variable ? parseInt(variable) : 0],
    ];

    const data = GoogleCharts.api.visualization.arrayToDataTable(pieData);

    const colors = status ? [
      '#0ED882', // assets
      '#0B4A5D', // fixed income
      '#DAE4E7', // variable income
    ] : [
      '#0B4A5D', // fixed income
      '#000000', // benefit programs
      '#DAE4E7', // variable income
    ];

    const options = {
      pieHole: 0.6,
      pieSliceText: 'none',
      legend: 'none',
      colors,
      chartArea:{
        left: 0,
        top: 0,
        width: '100%',
        height:'100%'
      },
      enableInteractivity: false
    };

    const pie_1_chart = new GoogleCharts.api.visualization.PieChart(document.getElementById('chart'));

    GoogleCharts.api.visualization.events.addListener(pie_1_chart, 'ready', () => {
      setTimeout(() => {
        pie_1_chart.getContainer().querySelector('table').parentNode.setAttribute('aria-hidden', 'true');
        pie_1_chart.getContainer().querySelector('svg').parentNode.removeAttribute('aria-label');
        pie_1_chart.getContainer().querySelector('svg').setAttribute('aria-label', 'Graphical representation of Your Retirement Income Breakdown');
        pie_1_chart.getContainer().querySelector('svg').setAttribute('role', 'img');
      }, 1000); // delay on tabular data representation
    });

    pie_1_chart.draw(data, options);
  }
}
