<form [formGroup]="form" (ngSubmit)="onSubmit($event)" *ngIf="!submitted || !success">
    <div *ngFor="let formElement of fields" class="field-wrapper">
        <ng-container [ngSwitch]="formElement.type">
            <!-- mark up -->
            <div *ngSwitchCase="'markup'" class="markup" [innerHTML]="formElement.markup">
            </div>
            <!-- fieldset -->
            <fieldset *ngSwitchCase="'fieldset'">
                <legend>{{formElement?.title}}</legend>
                <div class='fieldset' formGroupName="{{formElement.name}}">
                    <ng-container *ngFor="let field of formElement.children" [ngSwitch]="field.type">
                        <!-- input -->
                        <div *ngSwitchCase="'textfield'" class="input-half">
                            <label [for]="field.name">
                                {{field.title}}
                                <span class="required-indicator" *ngIf="field.required">*</span>
                            </label>
                            <input formControlName="{{field.name}}" type="text" placeholder="{{field.title}}" [class.is-invalid]="form.get(formElement.name + '.' + field.name).invalid && form.get(formElement.name+'.'+field.name).touched " />
                            <div class="invalid-feedback" *ngIf="form.get(formElement.name + '.' + field.name).invalid && submitted ">
                                {{requiredFieldError}}
                            </div>
                        </div>
                        <!-- email -->
                        <div *ngSwitchCase="'email'" class="email">
                            <label [for]="field.name">
                                {{field.title}}
                                <span class="required-indicator" *ngIf="field.required">*</span>
                            </label>
                            <input formControlName="{{field.name}}" type="email"  email placeholder="{{field.title}}" [class.is-invalid]="form.get(formElement.name + '.' + field.name).invalid && submitted " />
                            <div class="invalid-feedback" *ngIf="form.get(formElement.name + '.' + field.name).invalid && submitted ">
                                {{requiredFieldError}}
                             </div>
                        </div>
                        <!-- select -->
                        <div *ngSwitchCase="'select'" class="select">
                            <label [for]="field.name">
                                {{field.title}}
                                <span class="required-indicator" *ngIf="field.required">*</span>
                            </label>
                            <select formControlName="{{field.name}}" [class.is-invalid]="form.get(formElement.name + '.' + field.name).invalid && submitted ">
                                <option *ngFor="let opt of field.options" [value]="opt.value">{{opt.display}}
                                </option>
                            </select>
                            <div class="invalid-feedback" *ngIf="form.get(formElement.name + '.' + field.name).invalid && submitted ">
                                {{requiredFieldError}}
                            </div>
                        </div>
                        <!-- add additional field types here -->
                    </ng-container>
                </div>
            </fieldset>
            </ng-container>
 
        </div>
    <div class="submit-wrapper">
        <button type="submit" >{{submitCTA}}</button>
    </div>
</form>

<div *ngIf="submitted" class="submitted"  [ngStyle]="!submitted || !success ? {'display':'none'}:{'display':'block'}">
    <div [innerHtml]="submissionResponse">
    </div>
</div>