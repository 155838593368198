<a class="skip-main" [href]="skipLinkPath">Skip to Main Content</a>

<div class="header--global" role="banner" #headerRef>
  <div class="wrapper">
    <div class="header--global__content">
      <div class="header--brand" data-delay="2000ms">
        <a href="/" class="header--logo">
          <img [src]="logo?.url" [alt]="logo?.alt" height="40px" width="90px" class="global-nav__logo">
          <img [src]="logo?.url2" [alt]="logo?.alt" height="40px" width="70px" class="global-nav__logo-sticky">
        </a>
      </div>

      <div class="header--nav global-nav">
        <!-- DESKTOP -->
        <div class="header--nav__desktop">
          <div class="header--nav__section header--nav__section-top" data-delay="6000ms">
            <nav class="nav--top" role="navigation" aria-label="Top Navigation">
              <ul role="menu">
                <li *ngFor="let item of menu; let i = index" role="presentation">
                  <a [href]="item.url" [routerLinkActiveOptions]="{ exact: item.exact }"
                    routerLinkActive="nav--top--active" class="nav--top__link" role="menuitem"
                    [attr.aria-label]="item.text + ' | Item ' + ( i + 1 ) + ' of ' + menu.length + ' in Top Navigation'">
                    {{ item.text }}
                  </a>
                </li>
              </ul>
            </nav>

            <div class="font--control">
              <a href="javascript: void(0);" class="js--increase" role="button" aria-label="Decrease Font Size"
                (click)="onFontSizeToggleClick($event)" data-effect="decrease">A</a>
              <a href="javascript: void(0);" class="js--decrease" role="button" aria-label="Increase Font Size"
                (click)="onFontSizeToggleClick($event)" data-effect="increase">A</a>
            </div>

            <div class="site--search">
              <!-- <form action='/' method="post">
                <input type="search" placeholder="Search" aria-label="Type Search Keyword here" />
              </form> -->

              <button type="button" (click)="openSearch($event)" (focus)="closeDesktopNav()"
                aria-label="Open Search Form">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.625 8.5H9.0325L8.8225 8.2975C9.5575 7.4425 10 6.3325 10 5.125C10 2.4325 7.8175 0.25 5.125 0.25C2.4325 0.25 0.25 2.4325 0.25 5.125C0.25 7.8175 2.4325 10 5.125 10C6.3325 10 7.4425 9.5575 8.2975 8.8225L8.5 9.0325V9.625L12.25 13.3675L13.3675 12.25L9.625 8.5ZM5.125 8.5C3.2575 8.5 1.75 6.9925 1.75 5.125C1.75 3.2575 3.2575 1.75 5.125 1.75C6.9925 1.75 8.5 3.2575 8.5 5.125C8.5 6.9925 6.9925 8.5 5.125 8.5Z"
                    fill="#2C2C2C" />
                </svg>
                <span>Search</span>
              </button>

            </div>

          </div>
          <div class="header--nav__section header--nav__section-bottom" data-delay="4000ms">
            <nav class="nav--main" role="navigation" aria-label="Main Navigation">
              <div class="nav--marker"></div>
              <ul class="nav--main__links" (mouseleave)="closeSubMenu($event)" role="list">
                <li *ngFor="let link of links; let i = index" role="listitem">
                  <a [href]="link.url" [routerLinkActiveOptions]="{ exact: link.exact }"
                    routerLinkActive="nav--main--active" class="nav--main__link" (mouseenter)=toggleDesktopMenu($event)
                    (focus)=toggleDesktopMenu($event) id="{{i}}"
                    [attr.aria-label]="link.text + ' | Item ' + ( i + 1 ) + ' of ' + links.length + ' in Main Navigation'">
                    <span>{{ link.text }}</span>
                  </a>

                  <div class="nav--links__sub" [attr.aria-label]="'Sub Menu for ' + link.text" role="navigation">
                    <ul class="nav--links__sub-menu" role="menu">
                      <li class="nav--links__sub-parent" role="menuitem">
                        <h3 [attr.aria-label]="'Main Category: ' + link.text">{{link.text}}</h3>
                        <a [href]="link.url" [routerLinkActiveOptions]="{ exact: link.exact }"
                          routerLinkActive="nav--main--active" (focus)=keepDesktopMenu($event)
                          attr.aria-label="Find Content for {{link.text}}">
                          Find Content
                          <i></i>
                        </a>
                      </li>
                      <li class="nav--links__sub-item" *ngFor="let sub of link.sub; let i = index;" role="menuitem">
                        <a [href]="sub.url" routerLinkActive="nav--links__sub-active"
                          class="nav--links__sub-item__link" (focus)=keepDesktopMenu($event)
                          [attr.aria-label]="sub.text + ': A sub-category of ' + link.text + ' | Item ' + ( i + 1 ) + ' of ' + link.sub.length + ' in ' + link.text">
                          {{ sub.text }}
                          <i></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>

              <ul class="nav--main__donate">
                <li>
                  <a href="javascript: void(0)" (click)="openSearch( $event )" (focus)="closeDesktopNav()"
                    class="js-sticky-nav-trigger" aria-label="Open Search Form" role="button">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.625 8.5H9.0325L8.8225 8.2975C9.5575 7.4425 10 6.3325 10 5.125C10 2.4325 7.8175 0.25 5.125 0.25C2.4325 0.25 0.25 2.4325 0.25 5.125C0.25 7.8175 2.4325 10 5.125 10C6.3325 10 7.4425 9.5575 8.2975 8.8225L8.5 9.0325V9.625L12.25 13.3675L13.3675 12.25L9.625 8.5ZM5.125 8.5C3.2575 8.5 1.75 6.9925 1.75 5.125C1.75 3.2575 3.2575 1.75 5.125 1.75C6.9925 1.75 8.5 3.2575 8.5 5.125C8.5 6.9925 6.9925 8.5 5.125 8.5Z"
                        fill="white" />
                    </svg>
                    Search
                  </a>
                </li>
                <li >
                  <a [href]="donate?.url" [class]="donate?.class + ' js-donate'" class="" [title]="donate?.text" aria-label="Click here to Donate" 
                    (focus)="closeDesktopNav()">
                    {{ donate?.text }}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!-- END DESKTOP -->

        <!-- MOBILE -->

        <div class="header--nav__mobile--control">
          <a [href]="donate?.url" [title]="donate?.text" aria-label="Click here to Donate">{{donate?.text}}</a>
          <a href="javascript: void(0);" class="mobile--menu js-menu--trigger"
            (click)="toggleMobileMenu($event); $event.stopPropagation()" role="button"
            [attr.aria-label]="toggleMenuLabel">
            <span>
              <i></i>
              <i></i>
              <i></i>
            </span>
            <span></span>
          </a>
        </div>

        <div class="header--nav__mobile" aria-hidden="true">

          <div class="search--form">
            <form ngNoForm action='/search' method="GET" [ngClass]="isSearchInputFocused ? 'focused' : ''">
              <svg [ngClass]="isSearchInputFocused ? 'hide' : ''" width="17" height="17" viewBox="0 0 17 17" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.2083 10.8333H11.4842L11.2275 10.5858C12.1258 9.54083 12.6667 8.18417 12.6667 6.70833C12.6667 3.4175 9.99917 0.75 6.70833 0.75C3.4175 0.75 0.75 3.4175 0.75 6.70833C0.75 9.99917 3.4175 12.6667 6.70833 12.6667C8.18417 12.6667 9.54083 12.1258 10.5858 11.2275L10.8333 11.4842V12.2083L15.4167 16.7825L16.7825 15.4167L12.2083 10.8333ZM6.70833 10.8333C4.42583 10.8333 2.58333 8.99083 2.58333 6.70833C2.58333 4.42583 4.42583 2.58333 6.70833 2.58333C8.99083 2.58333 10.8333 4.42583 10.8333 6.70833C10.8333 8.99083 8.99083 10.8333 6.70833 10.8333Z"
                  fill="#2c2c2c" />
              </svg>
              <input type="search" placeholder="Search" aria-label="ex. Prescription Assistance" name="q"
                [ngClass]="isSearchInputFocused ? 'focused' : ''" (focus)="onSearchFocus()" (blur)="onSearchBlur()" />
              <button type="submit" aria-label="Submit Search" [ngClass]="isSearchInputFocused ? 'focused' : ''"
                (focus)="onSearchFocus()" (blur)="onSearchBlur()">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.2083 10.8333H11.4842L11.2275 10.5858C12.1258 9.54083 12.6667 8.18417 12.6667 6.70833C12.6667 3.4175 9.99917 0.75 6.70833 0.75C3.4175 0.75 0.75 3.4175 0.75 6.70833C0.75 9.99917 3.4175 12.6667 6.70833 12.6667C8.18417 12.6667 9.54083 12.1258 10.5858 11.2275L10.8333 11.4842V12.2083L15.4167 16.7825L16.7825 15.4167L12.2083 10.8333ZM6.70833 10.8333C4.42583 10.8333 2.58333 8.99083 2.58333 6.70833C2.58333 4.42583 4.42583 2.58333 6.70833 2.58333C8.99083 2.58333 10.8333 4.42583 10.8333 6.70833C10.8333 8.99083 8.99083 10.8333 6.70833 10.8333Z"
                    fill="#0B4A5D" />
                </svg>
              </button>
            </form>
          </div>

          <!-- PRIMARY NAV -->

          <nav class="global-nav__nav" role="navigation" aria-label="Main Navigation">
            <ul class="global-nav__links">
              <li class="global-nav__links-item" *ngFor="let link of links; let i = index">
                <a
                  href="javascript: void(0);"
                  routerLinkActive="global-nav__link--active" class="global-nav__link" id="item-{{i}}"
                  (click)=openSubMenu($event)
                  [attr.aria-label]="'Main Menu: ' + link.text + ' | Item ' + (i+1) + ' of ' + links.length">
                  {{ link.text }}
                </a>
              </li>
            </ul>
          </nav>

          <div class="global-nav__sub item-{{i}}" *ngFor="let link of links; let i = index">
            <a href="javascript: void(0);" class="js--back"
              (click)="toggleSubMenu($event); $event.stopPropagation()"><i></i>Back to Main Menu</a>

            <ul role="menu">
              <li class="global-nav__sub-parent" role="menuitem">
                <h3>{{ link.text }}</h3>
                <a [href]="link.url" [routerLinkActiveOptions]="{ exact: link.exact }"
                  routerLinkActive="global-nav__link--active" class="global-nav__link"
                  [attr.aria-label]="'Find Content for ' + link.text"
                  (click)="toggleMobileMenu( $event )">
                  Find Content <i></i>
                </a>

              </li>
              <li class="global-nav__sub-item" *ngFor="let item of link.sub; let i = index;" role="menuitem">
                <a [href]="item.url" [routerLinkActiveOptions]="{ exact: item.exact }"
                  routerLinkActive="global-nav__link--active" class="global-nav__link"
                  [attr.aria-label]="item.text + ' : A sub-menu of ' + link.text + ' | Item ' + ( i+1 ) + ' of ' + link.sub.length + ' of ' + link.text"
                  (click)="toggleMobileMenu( $event )">
                  {{ item.text }}
                  <i></i>
                </a>
              </li>
            </ul>
          </div>

          <!-- END PRIMARY NAV -->

          <!-- SECONDARY NAV -->
          <nav class="global-nav__menu" role="navigation" aria-label="Secondary Navigation">
            <ul class="global-nav__menu-link">
              <li *ngFor="let link of menu">
                <a [href]="link.url" [routerLinkActiveOptions]="{ exact: link.exact }"
                  routerLinkActive="global-nav__link--active" class="global-nav__link">
                  {{ link.text }}
                </a>
              </li>
            </ul>
          </nav>
          <!-- END SECONDARY NAV -->

          <!-- SOCIAL MEDIA -->
          <div class="global--social">
            <p>Find us on Social</p>

            <div class="global--social__media ">
              <div class="social--media" *ngFor="let media of socials">
                <a [href]="media.url" [title]="media.account"
                  [attr.aria-label]="'Visit ' + media.account + ' of NCOA'">
                  <span class="icon icon--{{ media.account }}"></span></a>
              </div>
            </div>
          </div>
          <!-- END SOCIAL MEDIA -->
          <!-- <button class="js-loop" (focus)="closeLoop()">Go back to Mobile Menu Toggle</button> -->
        </div>

        <!-- END MOBILE -->
      </div>
    </div>
  </div>
</div>

<!-- SEARCH OVERLAY -->
<div class="component component--search-overlay" #searchOverlayRef>
  <div class="wrapper">

    <div class="search-overlay--form">
      <form ngNoForm action="/search" method="GET">
        <div class="search-overlay--col">
          <div>
            <input type="text" name="q" placeholder="ex. Prescription Assistance" aria-label="Search for a Keyword" />
            <div class="submit"><input type="submit" value="Search" role="button" aria-label="Submit Search" /></div>
          </div>
        </div>
        <div class="search-overlay--col">
          <a href="javascript: void(0);" class="js-overlay--close" (click)="closeSearch( $event )" role="button"
            aria-label="Close Search">
            <span>
              <i></i>
              <i></i>
            </span>
            <span>Close</span>
          </a>
        </div>
      </form>
    </div>
  </div>
</div>
