<div *ngIf="!isLoggedIn" class="sign-in-banner">
  <div class="sign-in-banner__wrapper">
    <div class="main"  id = 'main-content'>
      <div class="head">
        <svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56C43.464 56 56 43.464 56 28Z" fill="#DAE4E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M28.3972 16.001C31.6119 16.001 34.2222 18.6087 34.2222 21.82C34.2222 25.0314 31.6203 27.6391 28.3972 27.6391C25.1826 27.6391 22.5723 25.0314 22.5723 21.82C22.5723 18.6087 25.1826 16.001 28.3972 16.001Z" fill="#0B4A5D"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M28.3975 28.6611C32.8628 28.6611 36.6901 31.8557 38.2764 36.3835C35.7165 38.8487 32.2333 40.3663 28.3975 40.3663C24.5617 40.3663 21.0785 38.8487 18.5186 36.3752C20.1049 31.8557 23.9323 28.6611 28.3975 28.6611Z" fill="#0B4A5D"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M41.4672 15.6318C44.1027 15.6318 46.243 17.77 46.243 20.4028C46.243 23.0356 44.1027 25.1738 41.4672 25.1738C38.8317 25.1738 36.6914 23.0356 36.6914 20.4028C36.6914 17.77 38.8233 15.6318 41.4672 15.6318Z" fill="#F3F6F7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3285 15.6318C17.964 15.6318 20.1043 17.77 20.1043 20.4028C20.1043 23.0356 17.964 25.1738 15.3285 25.1738C12.693 25.1738 10.5527 23.0356 10.5527 20.4028C10.5527 17.77 12.693 15.6318 15.3285 15.6318Z" fill="#F3F6F7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6372 36.3502L17.7211 36.107C18.5689 33.7006 19.9957 31.6547 21.8507 30.1706C23.1516 29.1393 24.6037 28.4349 26.1313 28.0828C24.9814 27.6635 23.9742 26.9424 23.2188 26.0117H15.2955C10.8386 26.0117 7.22949 29.6172 7.22949 34.0695V36.3418H17.6372V36.3502Z" fill="#F3F6F7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M39.0669 36.1065L39.1509 36.3496H49.5586V34.0773C49.5586 29.625 45.9495 26.0195 41.4926 26.0195H33.5609C32.8055 26.9502 31.7983 27.6713 30.6484 28.0906C32.176 28.4427 33.6281 29.1471 34.929 30.1784C36.8007 31.6457 38.2276 33.7 39.0669 36.1065Z" fill="#F3F6F7"/>
        </svg>
        <div>
          <h3 class="title">{{ title }}</h3>
          <p class="desc">{{ desc }}</p>
        </div>
      </div>
      <div class="btn-group">
        <a [href]="signUpCTA.url" class="cta cta--sign-up" [title]="signUpCTA.text">{{ signUpCTA.text }}</a>
        <span>or</span>
        <a [href]="signInCTA.url" class="cta cta--sign-in" [title]="signInCTA.text">{{ signInCTA.text }}</a>
      </div>
    </div>
  </div>
</div>
