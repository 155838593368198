import { Injectable } from '@angular/core';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { ToolsPrimaryConfigService } from './tools-primary-config.service';
import { UtilityService } from './utility.service';
import { AssessmentService } from './assessment.service';
import { take } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })

export class IncomeServiceService {

  constructor(
    private tools: ToolsPrimaryConfigService,
    private kontent: KontentDeliveryService,
    private assessment: AssessmentService,
    private utility: UtilityService,
  ) { }

  getBreakdownGraph() {

    const status = this.tools.customerActivity.finances.employmentStatus;
    const finances = this.tools.customerActivity.finances;
    const assetsBreakdown = finances.assetsBreakdown;
    const assets = finances.assets;
    const fixedIncome = finances.retirementIncomeBreakdown.fixed;
    const variableIncome = finances.retirementIncomeBreakdown.variable;
    const benefitsPrograms = finances.retirementIncomeBreakdown.benefitsPrograms;

    let snap = 0;
    let ssdi = 0;
    let ssi = 0;
    let tanf = 0;
    let ui = 0;
    let cashAssistance = 0;
    let benefitsProgramsTotal = 0;

    let investments = Number.isNaN( parseInt( assetsBreakdown.investments ) ) ? 0 : parseInt( assetsBreakdown.investments );
    // Fix to pull savings account, not monthly value
    let savings = Number.isNaN( parseInt( assets.savings ) ) ? 0 : parseInt( assets.savings );
    let checking = Number.isNaN( parseInt( assetsBreakdown.checking ) ) ? 0 : parseInt( assetsBreakdown.checking );

    let assetTotal = savings + checking;

    let annuities = Number.isNaN( parseInt( fixedIncome.Annuities ) ) ? 0 : parseInt( fixedIncome.Annuities );
    let pensions = Number.isNaN( parseInt( fixedIncome.Pensions ) ) ? 0 : parseInt( fixedIncome.Pensions );
    let socialSecurity = benefitsPrograms === undefined ? 0 : Number.isNaN( parseInt( benefitsPrograms['Social Security'] ) ) ? 0 : parseInt( benefitsPrograms['Social Security'] );
    let fixedIncomeTotal =  annuities + pensions + socialSecurity;

    let _401 = Number.isNaN( parseInt( variableIncome['401(k)/403(b)'] ) ) ? 0 : parseInt( variableIncome['401(k)/403(b)'] );
    let ira = Number.isNaN( parseInt( variableIncome.IRA ) ) ? 0 : parseInt( variableIncome.IRA );
    let other = Number.isNaN( parseInt( variableIncome.Other ) ) ? 0 : parseInt( variableIncome.Other );
    let thrift = Number.isNaN( parseInt( variableIncome['Thrift Savings Plan/457 Plans'] ) ) ? 0 : parseInt( variableIncome['Thrift Savings Plan/457 Plans'] );
    let monthlyIncomeSavings = Number.isNaN( parseInt( variableIncome['Monthly Income from Savings'] ) ) ? 0 : parseInt( variableIncome['Monthly Income from Savings'] );
    let variableIncomeTotal = investments + _401 + ira + other + thrift;

    // If user is retired, semi-retired, or unemployed
    if(benefitsPrograms) {
      snap = Number.isNaN( parseInt( benefitsPrograms['SNAP'] ) ) ? 0 : parseInt( benefitsPrograms['SNAP'] );
      ssdi = Number.isNaN( parseInt( benefitsPrograms['Social Security Disability Insurance (SSDI)'] ) ) ? 0 : parseInt( benefitsPrograms['Social Security Disability Insurance (SSDI)'] );
      ssi = Number.isNaN( parseInt( benefitsPrograms['Supplemental Security Insurance (SSI)'] ) ) ? 0 : parseInt( benefitsPrograms['Supplemental Security Insurance (SSI)'] );
      tanf = Number.isNaN( parseInt( benefitsPrograms['Temporary Assistance for Needy Families (TANF)'] ) ) ? 0 : parseInt( benefitsPrograms['Temporary Assistance for Needy Families (TANF)'] );
      ui = Number.isNaN( parseInt( benefitsPrograms['Unemployment Insurance (UI)'] ) ) ? 0 : parseInt( benefitsPrograms['Unemployment Insurance (UI)'] );
      cashAssistance = Number.isNaN( parseInt( benefitsPrograms['Cash Assistance'] ) ) ? 0 : parseInt( benefitsPrograms['Cash Assistance'] );
      benefitsProgramsTotal = snap + ssdi + ssi + tanf + ui + cashAssistance;
    }

    // WNA-remove assets from monthly calc
    let netTotal = fixedIncomeTotal + variableIncomeTotal + benefitsProgramsTotal;

    const userIsWorking = status === 'part_time' || status === 'full_time';

    if (userIsWorking) {
      netTotal = assetTotal + fixedIncomeTotal + variableIncomeTotal;
    } else {
      variableIncomeTotal += monthlyIncomeSavings;
      netTotal = benefitsProgramsTotal + fixedIncomeTotal + variableIncomeTotal;
    }

    const assetsPercentage =  Math.round( ( assetTotal / netTotal ) * 100 );
    const fixedIncomePercentage = Math.round( ( fixedIncomeTotal / netTotal ) * 100 );
    const variableIncomePercentage = Math.round( ( variableIncomeTotal / netTotal ) * 100 );
    const benefitsProgramsPercentage = Math.round( ( benefitsProgramsTotal / netTotal ) * 100 );

    // Assets
    const assetsObj = {
      label: 'Assets',
      value: Number.isNaN( assetsPercentage ) ? 0 : assetsPercentage,
      amount: assetTotal,
      primaryColor: '#0ED882',
      secondaryColor: '#E1F4EB',
      secondaryColorFade: 'rgba(225, 244, 235, 0.3)',
      textColor: '#2C2C2C',
      breakdown: [
        {
          label: 'Checking Accounts',
          value: this.parseToInt(assetsBreakdown.checking) || 0
        },
        {
          label: 'Savings Accounts',
          value:this. parseToInt(assets.savings) || 0
        }
      ]
    };

    // Fixed Income
    const fixedIncomeObj = {
      label: 'Fixed Income',
      value: Number.isNaN( fixedIncomePercentage ) ? 0 : fixedIncomePercentage,
      amount: fixedIncomeTotal,
      primaryColor: '#0B4A5D',
      secondaryColor: '#F3F6F7',
      secondaryColorFade: '#DAE4E7',
      textColor: '#ffffff',
      breakdown: [
        {
          label: 'Annuity(ies)',
          value: this.parseToInt(fixedIncome.Annuities) || 0
        },
        {
          label: 'Pension(s)',
          value: this.parseToInt(fixedIncome.Pensions) || 0
        },
        {
          label: 'Social Security',
          value: socialSecurity
        }
      ]
    };

    // Variable Income
    const variableIncomeObj = {
      label: 'Variable Income',
      value: Number.isNaN( variableIncomePercentage ) ? 0 : variableIncomePercentage,
      amount: variableIncomeTotal,
      primaryColor: '#DAE4E7',
      secondaryColorFade: '#F3F6F7',
      secondaryColor: '#FAFAFA',
      textColor: '#2C2C2C',
      breakdown: [
        {
          label: 'Investments',
          value: this.parseToInt(assetsBreakdown.investments) || 0
        },
        {
          label: '401(k)/403(b)',
          value: this.parseToInt(variableIncome['401(k)/403(b)']) || 0
        },
        {
          label: 'Individual Retirement Account (IRA)',
          value: this.parseToInt(variableIncome.IRA) || 0
        },
        {
          label: 'Thrift Savings Plan/457 Plans',
          value: this.parseToInt(variableIncome['Thrift Savings Plan/457 Plans']) || 0
        },
        {
          label: 'Other Retirement Accounts',
          value: this.parseToInt(variableIncome.Other) || 0
        }
      ],
    };

    if(!userIsWorking) {
      variableIncomeObj.breakdown.push({
        label: 'Monthly Income from Savings',
        value: monthlyIncomeSavings,
      });
    }

    // Benefits Program
    let benefitsIncomeObj = {
      label: 'Benefits Income',
      value: Number.isNaN( benefitsProgramsPercentage ) ? 0 : benefitsProgramsPercentage,
      amount: benefitsProgramsTotal,
      primaryColor: '#000000',
      secondaryColorFade: '#EFEFED',
      secondaryColor: '#FAFAFA',
      textColor: '#FFFFFF',
      breakdown: [
        {
          label: 'SNAP',
          value: snap
        },
        {
          label: 'Supplemental Security Insurance (SSI)',
          value: ssi
        },
        {
          label: 'Social Security Disability Insurance (SSDI)',
          value: ssdi
        },
        {
          label: 'Unemployment Insurance (UI)',
          value: ui
        },
        {
          label: 'Temporary Assistance for Needy Families (TANF)',
          value: tanf
        },
        {
          label: 'Cash Assistance',
          value: cashAssistance
        }
      ]
    }

    const chartData = userIsWorking ? {
      assets: assetsObj,
      fixedIncome: fixedIncomeObj,
      variableIncome: variableIncomeObj,
    } : {
      fixedIncome: fixedIncomeObj,
      benefitsIncome: benefitsIncomeObj,
      variableIncome: variableIncomeObj,
    };

    return {
      userIsWorking: userIsWorking,
      chartData: chartData,
    };
  }

  parseToInt(num){
    if(num) return parseInt(num);
    else return num;
  }

  async getContents() {
    const data = {};

    const codename = this.tools.currentConfig.core.route.codename;
    const tool = this.tools.modular_content[codename];
    const resultCodename = tool.elements.summar_page_copy.value[0];

    const resultPage = await this.kontent.getItem(resultCodename, { depth: 4 });

    if (resultPage) {
      const { item, modular_content } = resultPage;
      const { elements } = item;

      // breakdown header
      data['breakdownHeader'] = {
        id: tool.system.id,
        codename: this.tools.currentConfig.core.route.codename,
        isSaved: (this.tools.customerActivity?.basic?.SavedContent || []).find(({ ContentId }) => ContentId === tool.system.id),
        backLink: {
          url: this.tools.routes.find(({ type }) => type === 'home').route,
          text: 'My Planner',
        },
        profileLink: {
          url: this.tools.routes.find(({ type }) => type === 'profile').route,
          text: 'Edit Answers',
        },
        headline: elements.summary_header.value,
      };

      const status = this.tools.customerActivity.finances.employmentStatus;
      const userIsWorking = status === 'part_time' || status === 'full_time';

      const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };

      const twoColBreakdown = !userIsWorking ? {
        headline: elements.n2_column_block_comparison_heading.value,
        copy: elements.n2_column_block_comparison_copy.value,
        savings: `$${numberWithCommas(parseInt(this.tools.customerActivity.finances.assetsBreakdown.savings))}`,
        checking: `$${numberWithCommas(parseInt(this.tools.customerActivity.finances.assetsBreakdown.checking))}`,
      } : null;

      // breakdown graph
      data['breakdownGraph'] = {
        ...this.getBreakdownGraph(),
        headline: elements.top_section_header.value,
        body: elements.top_section_body.value,
        twoColBreakdown,
      };

      // breakdown partnership
      const partnerShipKey = elements.partnership.value[0];
      const partnerShipRaw = modular_content[partnerShipKey];

      data['breakdownPartnership'] = {
        text: partnerShipRaw.elements.intro.value,
        icon: {
          alt:  partnerShipRaw.elements.icon.value[0].description,
          src: partnerShipRaw.elements.icon.value[0].url,
        },
      };

      // recommended content
      const userProfileId = this.tools.rawConfig.elements.profile_assessment_id.value;
      const response = await this.assessment.getUserProfile(userProfileId)
        .pipe(take(1))
        .toPromise();

      if (elements.recommendation.value.length > 0) {
        const linkedCardGrid = modular_content[elements.recommendation.value[0]];
        const heading = linkedCardGrid.elements.headline.value;
        const intro = linkedCardGrid.elements.subhead.value;
        const recommendedCards = [];
        const promoCards = [];

        await Promise.all(linkedCardGrid.elements.promo_cards.value.map((key) => this.kontent.getItem(key).then(raw => raw)))
          .then((resArr: any) => {
            resArr.forEach((res) => {
              const background = {
                url: res.item.elements.background_image.value[0].url,
                alt: res.item.elements.background_image.value[0].description,
              };
              const eyebrow = res.item.elements.eyebrow.value;
              const heading = res.item.elements.headline.value;
              const cta = {
                label: res.item.elements.cta_text.value,
                url: res.item.elements.cta_manual_external_link.value,
              };

              if (res.item.elements.cta_internal_link.value.length > 0 && res.modular_content[res.item.elements.cta_internal_link.value[0]]) {
                const e = res.modular_content[res.item.elements.cta_internal_link.value[0]].elements;
                const s = res.modular_content[res.item.elements.cta_internal_link.value[0]].system;

                switch (s.type) {
                  case 'ncoa_article_content': {
                    cta.url = `${this.tools.rootURL}/resource/${e.url.value}`;
                    break;
                  }
                  case 'awa_benefits_tool___vertical_topic': {
                    cta.url = (this.tools.routes.find((route) => (
                      route.type === 'vertical-topic' &&
                      route.codename === s.codename
                    )) || { route: null }).route;
                    break;
                  }
                  case 'awa_benefits_tool___vertical': {
                    cta.url = (this.tools.routes.find((route) => (
                      route.type === 'vertical' &&
                      route.codename === s.codename
                    )) || { route: null }).route;
                    break;
                  }
                  case 'standard_page': {
                    cta.url = `${this.tools.rootURL}/pages/${e.url.value}`;
                    break;
                  }
                  case 'awa_benefits_tool___vertical_subtopic': {
                    cta.url = this.tools.routes.find((route) => (
                      route.type === 'vertical-subtopic-learn' &&
                      route.codename === s.codename
                    )).route;
                    break;
                  }
                }
              }

              if (cta.url) {
                promoCards.push({
                  type: 'promo',
                  background,
                  eyebrow,
                  heading,
                  cta,
                });
              }
            });
          });

        const employmentStatus = this.tools.customerActivity?.finances?.employmentStatus;
        const areasTopOfMind = this.tools.customerActivity?.basic?.areasTopOfMind;
        let resourcesRelatedToRetirement = this.tools.customerActivity?.basic?.resourcesRelatedToRetirement;
            resourcesRelatedToRetirement = typeof resourcesRelatedToRetirement == 'string' ? [resourcesRelatedToRetirement] : resourcesRelatedToRetirement;
        const financialConcerns = this.tools.customerActivity?.basic?.financialConcerns;
        const isCurrentlyReceivingBenefits = this.tools.customerActivity?.basic?.isCurrentlyReceivingBenefits;
        const currentMedicarePlans = this.tools.customerActivity?.basic?.currentMedicarePlans;
        const currentlyEnrolledMedicare = response?.profile?.currentlyEnrolledMedicare;

        const getManualCards = async () => {
          return await Promise.all(linkedCardGrid.elements.cards.value.map((key) => this.kontent.getItem(key).then(raw => raw)))
            .then((resArr: any) => {
              resArr.forEach((res) => {
                switch (res.item.system.type) {
                  case 'ncoa_article_content': {
                    // article
                    if (res.item.elements[this.tools.toolTaxonomyCodename].value.length > 0) {
                      const div = document.createElement('div');
                            div.innerHTML = res.item.elements.body_ckeditor.value;
                      const text = div.innerText;

                      const heading = res.item.elements.title.value;
                      const intro = `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`;
                      const url = `${this.tools.rootURL}/resource/${res.item.elements.url.value}`;

                      const taxonomies = res.item.elements[this.tools.toolTaxonomyCodename].value.map((taxonomy) => taxonomy.codename);
                      const verticals = taxonomies.map((taxonomy) => this.tools.getVertical(taxonomy).codename);
                      let vertical = this.utility.arrayMode(verticals);
                          vertical = this.tools.awpTaxonomyCatalog.find((v) => v.codename === vertical);

                      if (res.item.elements.awp_trusted_expert.value.length > 0) {
                        // trusted experts
                        recommendedCards.push({
                          id: res.item.system.id,
                          type: 'referral',
                          eyebrow: `${vertical.name} Expert`,
                          heading,
                          intro,
                          cta: {
                            label: 'View Details',
                            url,
                          },
                          photo: {
                            url: res.item.elements.expert_logo.value[0].url,
                            alt: res.item.elements.expert_logo.value[0].description,
                          },
                          bookmark: true,
                        });
                      } else {
                        // resource
                        recommendedCards.push({
                          id: res.item.system.id,
                          type: 'article',
                          breadcrumb: `${vertical.name} / Resource`,
                          heading,
                          intro,
                          cta: {
                            label: 'Read Resource',
                            url,
                          },
                        });
                      }
                    }
                    break;
                  }
                  case 'awa_benefits_tool___card__tool_': {
                    // tool
                    if (res.item.elements.tool.value.length > 0) {
                      const completedAssessments = localStorage.getItem('completedAssessments') ? JSON.parse(localStorage.getItem('completedAssessments')) : [];

                      const { elements: e, system: s } = res.modular_content[res.item.elements.tool.value[0]];

                      const heading = res.item.elements.headline.value;
                      const photo = {
                        url: res.item.elements.card_image.value[0].url,
                        alt: res.item.elements.card_image.value[0].description,
                      };

                      const cta = {
                        label: completedAssessments.find((assessment) => assessment === e.assessment_id.value) ? 'View Results' : 'Start Assessment',
                        url: this.tools.routes.find((route) => (
                          route.assessmentID === e.assessment_id.value
                        )).route,
                      };

                      switch (e.assessment_id.value) {
                        case 'generalAssessment':
                        case 'generalAssessmentAWP': {
                          recommendedCards.push({
                            id: s.id,
                            linkedID: s.id,
                            linkedCodename: s.codename,
                            type: 'tool',
                            heading,
                            cta,
                            photo,
                            variation: 'tools-retirement-single-col',
                          });
                          break;
                        }
                        default: {
                          recommendedCards.push({
                            id: s.id,
                            linkedID: s.id,
                            linkedCodename: s.codename,
                            type: 'tool',
                            heading,
                            cta,
                            photo,
                            variation: 'tools-medicare-single-col',
                            theme: 'dark',
                          });
                          break;
                        }
                      }
                    }
                    break;
                  }
                  case 'awa_benefits_tool___vertical_subtopic': {
                    // subtopic
                    const subTopicRoute = this.tools.routes.find((route) => (
                      route.type === 'vertical-subtopic-learn' &&
                      route.codename === res.item.system.codename
                    ));

                    if (subTopicRoute) {
                      const topicRoute = this.tools.routes.find((route) => (
                        subTopicRoute.parentTopic &&
                        route.codename === subTopicRoute.parentTopic
                      ));

                      if (topicRoute) {
                        const verticalRoute = this.tools.routes.find((route) => (
                          subTopicRoute.parentVertical &&
                          route.codename === subTopicRoute.parentVertical
                        ));

                        const eyebrow = `${verticalRoute.taxonomy.name} / ${topicRoute.text}`;
                        const heading = res.item.elements.title.value;
                        const cta = {
                          label: 'Learn More',
                          url: subTopicRoute.route,
                        };

                        recommendedCards.push({
                          id: res.item.system.id,
                          type: 'subtopic',
                          eyebrow,
                          heading,
                          cta,
                        });
                      }
                    }
                    break;
                  }
                }
              });

              promoCards.forEach((promoCard, index) => {
                const pos = (index + 1) * 3;
                if ((pos - 1) <= recommendedCards.length) {
                  recommendedCards.splice((pos - 1), 0, promoCard);
                }
              });

              if (recommendedCards.length > 0) {
                return {
                  location: false,
                  heading,
                  intro,
                  cards: recommendedCards,
                };
              } else {
                return null;
              }
            });
        };

        let personalizationParam;
        if (employmentStatus) {
          personalizationParam = `${employmentStatus.toLowerCase()}`;
        }

        if (areasTopOfMind) {
          const strings = areasTopOfMind.map((string) => string.toLowerCase());
          personalizationParam = `${personalizationParam},${strings.join(',')}`;
        }

        if (resourcesRelatedToRetirement) {
          const strings = resourcesRelatedToRetirement.map((string) => string.toLowerCase());
          personalizationParam = `${personalizationParam},${strings.join(',')}`;
        }

        if (financialConcerns) {
          const strings = financialConcerns.map((string) => string.toLowerCase());
          personalizationParam = `${personalizationParam},${strings.join(',')}`;
        }

        if (currentMedicarePlans) {
          personalizationParam = `${personalizationParam},${currentMedicarePlans}`;
        }

        if (typeof isCurrentlyReceivingBenefits !== 'undefined' || typeof currentlyEnrolledMedicare !== 'undefined') {
          if (isCurrentlyReceivingBenefits || currentlyEnrolledMedicare) {
            personalizationParam = `${personalizationParam},currentlyenrolledmedicare_true`;
          } else {
            personalizationParam = `${personalizationParam},currentlyenrolledmedicare_false`;
          }
        }

        if (personalizationParam) {
          const content = await this.kontent.getItem(null, {
            'system.type[in]': 'ncoa_article_content,awa_benefits_tool___card__tool_,awa_benefits_tool___vertical_subtopic',
            'elements.awp_personalization_terms[any]': personalizationParam,
            'limit': 500,
          });

          if (content && content?.items && content.items.length > 0) {
            const { items, modular_content } = content;
            const completedAssessments = this.tools.customerActivity.basic?.completedAssessments || [];

            items.forEach((item) => {
              switch (item.system.type) {
                case 'ncoa_article_content': {
                  const taxonomies = item.elements[this.tools.toolTaxonomyCodename].value;
                  const verticals = taxonomies.map(({ codename }) => this.tools.getVertical(codename));
                  const vertical = this.utility.arrayMode(verticals.map(({ name }) => name));

                  const div = document.createElement('div');
                        div.innerHTML = item.elements.body_ckeditor.value;
                  const text = div.innerText;
                  const intro = `${text.substr(0, 121).replace(/(\r\n|\n|\r)/gm, '').trim()}...`;

                  if (item.elements.awp_trusted_expert.value.length === 0) {
                    // resource page
                    recommendedCards.push({
                      id: item.system.id,
                      type: 'article',
                      heading: item.elements.title.value,
                      intro,
                      cta: {
                        url: `${this.tools.rootURL}/resource/${item.elements.url.value}`,
                        label: 'Read Resource',
                      },
                      breadcrumb: vertical,
                    })
                  } else {
                    // trusted expert page
                    recommendedCards.push({
                      id: item.system.id,
                      type: 'referral',
                      eyebrow: vertical,
                      heading: item.elements.title.value,
                      intro,
                      cta: {
                        label: 'View Details',
                        url: `${this.tools.rootURL}/resource/${item.elements.url.value}`,
                      },
                      bookmark: true,
                    });
                  }
                  break;
                }
                case 'awa_benefits_tool___card__tool_': {
                  const linkedTool = modular_content[item.elements.tool.value[0]];

                  recommendedCards.push({
                    id: item.system.id,
                    linkedID: linkedTool.system.id,
                    linkedCodename: linkedTool.system.codename,
                    type: 'tool',
                    heading: item.elements.headline.value,
                    intro: item.elements.description.value,
                    cta: {
                      label: completedAssessments.find((key) => key === linkedTool.elements.assessment_id.value)
                        ? 'Start Assessment'
                        : 'View Results',
                      url: (this.tools.routes.find((route) => (
                        route.assessmentID &&
                        route.assessmentID === linkedTool.elements.assessment_id.value
                      )) || { route: this.tools.rootURL }).route,
                    },
                    photo: item.elements.card_image.value.length > 0 ? {
                      url: item.elements.card_image.value[0].url,
                      alt: item.elements.card_image.value[0].description,
                    } : null,
                    variation: linkedTool.elements.assessment_id.value === 'generalAssessment' || linkedTool.elements.assessment_id.value === 'generalAssessmentAWP'
                      ? 'tools-retirement-single-col'
                      : 'tools-medicare-single-col',
                    theme: linkedTool.elements.assessment_id.value === 'generalAssessment' || linkedTool.elements.assessment_id.value === 'generalAssessmentAWP'
                      ? null
                      : 'dark',
                  });

                  break;
                }
                case 'awa_benefits_tool___vertical_subtopic': {
                  const subTopic = this.tools.routes.find((route) => (
                    route.type === 'vertical-subtopic-learn' &&
                    route.codename === item.system.codename
                  ));

                  if (subTopic) {
                    const topic = this.tools.routes.find((route) => (
                      route.type === 'vertical-topic' &&
                      route.codename === subTopic.parentTopic
                    ));

                    if (topic) {
                      const vertical = this.tools.routes.find((route) => (
                        route.type === 'vertical' &&
                        route.codename === subTopic.parentVertical
                      ));

                      recommendedCards.push({
                        type: 'subtopic',
                        eyebrow: `${vertical.text} / ${topic.text}`,
                        heading: item.elements.title.value,
                        cta: {
                          label: 'Learn More',
                          url: subTopic.route,
                        },
                      });
                    }
                  }

                  break;
                }
              }
            });

            promoCards.forEach((promoCard, index) => {
              const pos = (index + 1) * 3;
              if ((pos - 1) <= recommendedCards.length) {
                recommendedCards.splice((pos - 1), 0, promoCard);
              }
            });

            if (recommendedCards.length > 0) {
              data['recommended'] = {
                location: false,
                heading,
                intro,
                cards: recommendedCards,
              };
            }
          } else {
            const recommended3ColGrid: any = await getManualCards();
            if (recommended3ColGrid) {
              data['recommended'] = recommended3ColGrid;
            }
          }
        } else {
          const recommended3ColGrid: any = await getManualCards();
          if (recommended3ColGrid) {
            data['recommended'] = recommended3ColGrid;
          }
        }
      }
    }

    return data;
  }
}
