import { Injectable } from '@angular/core';
import { GlobalService } from '@services/global.service';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { SeoService } from '@services/seo.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ToolsPrimaryConfigService {
  private readonly contentType: string = 'awa_benefits_tool___configuration';
  private readonly CONTENT_ID = (environment as any).contentId;
  private readonly VERTICAL_TAXONOMY_ID = (environment as any).verticalTaxonomyId;
  private readonly TAXONOMY_ELEMENT_CODENAME = (environment as any).taxonomyElementCodename;
  private readonly collectionCodeName: string = 'age_well_planner';

  modular_content: any;
  rawConfig: any;
  customerActivity: any;
  singularVerticalTopic: boolean = false;

  rootURL: string = '/';
  routes: any[] = [];
  awpTaxonomy: any = null;
  toolTaxonomyCodename: string;
  awpTaxonomyCatalog: any[] = [];
  currentConfig: {
    core: any;
    navData: any;
    footerData: any;
    heroData: any;
    eabHeroData: any;
  } = null;

  // NAM-440
  private _language = new BehaviorSubject<string>('');
  public readonly language$ = this._language.asObservable();
  public get language(): string { return this._language.value || localStorage.getItem('language'); }
  public setLanguage(lang: 'en' | 'es'): void {
    this._language.next(lang);
    localStorage.setItem('language', lang);
  }

  constructor(
    private kontent: KontentDeliveryService,
    private globalService: GlobalService,
    private seoService: SeoService,
  ) { }

  getContent() {
    return this.kontent.getItem(null, {
      'system.collection': this.collectionCodeName,
      'order': 'system.last_modified[desc]',
      'depth': 10,
    });
  }

  async getTaxonomy() {
    // return this.kontent.getTaxonomies('awp_verticals_topics_subtopics');
    return await this.kontent.getTaxonomies(null, { 'system.id': this.VERTICAL_TAXONOMY_ID })
      .then((response: any) => {
        if (response?.taxonomies && response.taxonomies.length > 0) {
          return response.taxonomies[0];
        }
      });
  }

  async getConfig(casData: any, tools?: boolean) {
    this.customerActivity = casData;
    return await Promise.all([
      this.getTaxonomy(),
      this.getContent(),
    ]).then(([
      taxonomy,
      content,
    ]) => {

      this.toolTaxonomyCodename = this.TAXONOMY_ELEMENT_CODENAME;
      this.awpTaxonomy = (taxonomy as any).terms;
      this.awpTaxonomyCatalog = this.awpTaxonomy.map((vertical) => {
        const subtopics = [];

        const topics = vertical.terms.map((topic) => {
          topic.terms.forEach((subtopic) => {
            subtopics.push({
              codename: subtopic.codename,
              name: subtopic.name,
            });
          });

          return {
            codename: topic.codename,
            name: topic.name,
          };
        });

        return {
          codename: vertical.codename,
          name: vertical.name,
          topics: [
            {
              codename: vertical.codename,
              name: vertical.name,
            },
            ...topics,
            ...subtopics,
          ],
        };
      });

      const { items, modular_content } = content as any;

      // get  planner/private label by content Id
      // console.log('contentId', this.CONTENT_ID);
      const config = items.find((item) => item.system.id === this.CONTENT_ID);
      // console.log('items', items, config);
      // Get veritcal codenames from linked items
      const verticalListing = config.elements.verticals_listing.value;

      this.rawConfig = config;

      this.modular_content = modular_content;

      this.rootURL = `/${config.elements.url_slug.value}`;

      this.routes = [
        { type: 'home', route: `${this.rootURL}` },
        { type: 'sign-in', route: `/sign-in` },
        { type: 'sign-up', route: `/sign-up` },
        { type: 'verify-user', route: `/verify-user` },
        { type: 'forgot-password', route: `/forgot-password` },
        // { type: 'my-planner', route: `${this.rootURL}/my-planner` },
        { type: 'all-benefits', route: `${this.rootURL}/explore-benefits` },
        { type: 'resource', route: `${this.rootURL}/resource` },
        { type: 'pages', route: `${this.rootURL}/pages` },
        { type: 'contact-us', route: `${this.rootURL}/contact-us` },
        { type: 'saved-contents', route: `${this.rootURL}/saved-contents` },
        { type: 'profile', route: `${this.rootURL}/profile` },
        { type:'case-manager', route: `${this.rootURL}/case-manager`},
        { type: 'lead-gen', route: `${this.rootURL}/lead-gen` },
        { type: 'income-breakdown', route: `${this.rootURL}/income-breakdown` },
        { type: 'cost-estimator', route: `${this.rootURL}/cost-estimator` },
        { type: 'landing', route: `${this.rootURL}/landing` },
      ];

      if (tools) {
        this.rootURL = '';
        this.routes = [
          { type: 'home', route: '' },
          { type: 'sign-in', route: `/sign-in` },
          { type: 'sign-up', route: `/sign-up` },
          { type: 'verify-user', route: `/verify-user` },
          { type: 'forgot-password', route: `/forgot-password` },
          // { type: 'my-planner', route: `/my-planner` },
          // { type: 'all-benefits', route: `/explore-benefits` },
          { type: 'resource', route: `/resource` },
          { type: 'pages', route: `/pages` },
          { type: 'contact-us', route: `/contact-us` },
          { type: 'saved-contents', route: `/saved-contents`  },
          { type: 'profile', route: `/profile` },
          { type:'case-manager', route: `/case-manager`},
          { type: 'lead-gen', route: `/lead-gen` },
          { type: 'income-breakdown', route: `${this.rootURL}/income-breakdown` },
          { type: 'cost-estimator', route: `/cost-estimator` },
          { type: 'landing', route: `${this.rootURL}/landing` },
        ];
      }


      // TODO : determine assessments that are associated only to the planner/private label
      let assessmentPages = items.filter((item) => item.system.type === 'awa_benefits_tool___tool');

      const newAssessmentPages = []; 
      assessmentPages.forEach((page, index) => {
        assessmentPages[index].route = `${this.rootURL}/assessment/${this.globalService.makeAKebab(page.elements.title.value.toLowerCase())}`;
        newAssessmentPages.push(page);
        const newObj =  Object.assign({}, page);
        newObj.route = `/tools/${this.globalService.makeAKebab(page.elements.title.value.toLowerCase())}`;
        newAssessmentPages.push(newObj);


      });
    

      const assessmentRoutes = newAssessmentPages.map((page, index) => {

        return {
          assessmentID: page.elements.assessment_id.value,
          screeningCode: page.elements.screening_code?.value,
          type: 'assessment',
          codename: page.system.codename,
          route: page.route,
          isPrimary: config.elements.primary_assessment.value[0] === page.system.codename,
        }
      });

      this.routes.push(...assessmentRoutes);


      const educationalCards = items.filter((item) => item.system.type === 'awp___tool___educational_content_panel');

      const todoList = items.filter((item) => item.system.type === 'awp___component___todo_list')[0];

      const partnerGrids = Object.entries(modular_content).filter((item: any) => item[1].system.type === 'awp___partner_recognition_grid').map(item => { return item[1] });

      const assessmentFAQPages = Object.entries(modular_content).filter((item: any) => item[1].system.type === 'awa_benefits_tool_template___standard_page').map(item => { return item[1] });

      const verticalPages = [];
      const topicPages = [];

      // Build verticals ONLY linked to the planner/private label
      verticalListing.forEach((verticalCodeName) => {
        // Check for verticals assoacited with the experience
        const verticalRaw = items.filter((item) => item.system.codename === verticalCodeName);
        if (verticalRaw) {
          let page = verticalRaw[0];
          verticalPages.push(page); 

          const parentRoute = `${this.rootURL}/${page.elements.url_slug.value}`;
          const toolsRoute = `/tools/${page.elements.url_slug.value}`

          // TODO uncouple dependency on taxonomy wihtin routing.  Should only be used for content association
          const taxonomy = page.elements[this.toolTaxonomyCodename].value[0];

          const verticalRoute = {
            type: 'vertical',
            taxonomy,
            codename: page.system.codename,
            route: parentRoute,
            text: page.elements.title.value,
          };

          this.routes.push(verticalRoute);

          if (page.elements.benefits_category.value.length > 0) {
            // console.log('debug', 'page.elements.benefits_navigation.value', page.elements.benefits_navigation.value);

            this.routes.push({
              parentVertical: page.system.codename,
              taxonomy,
              navigationName: page.elements.benefits_navigation.value,
              type: 'vertical-benefits',
              codename: page.system.codename,
              route: `${parentRoute}/benefits`,
            });
          }

          // If Vertical Has Assessment
          let assessmentCode = ''
          if(page.elements.associated_tool.value.length > 0){
            let toolCodeName = page.elements.associated_tool.value[0];
            let toolData = modular_content[toolCodeName];
            let planCodeName = toolData.elements.associated_plan.value[0];
            let planData = modular_content[planCodeName]
            assessmentCode = toolData.elements.screening_code.value ?? toolData.elements.assessment_id.value;
            this.routes.push({
              parentVertical: page.system.codename,
              parentVerticalName: page.elements.title.value,
              parentVerticalDescription: page.elements.description.value,
              type: 'vertical-plan',
              codename: planData.system.codename,
              text: planData.elements.title.value,
              route: `${toolsRoute}/${planData.elements.url_slug.value}`,
              assessmentCode
            });
          }


          const topics = page.elements.vertical_topics.value;

          topics.forEach((topic) => {

            const taxonomy = modular_content[topic].elements[this.toolTaxonomyCodename].value[0];

            topicPages.push(modular_content[topic]);

            let type = 'vertical-topic'

            this.routes.push({
              parentVertical: page.system.codename,
              parentVerticalName: page.elements.title.value,
              parentVerticalDescription: page.elements.description.value,
              type,
              taxonomy,
              codename: topic,
              text: modular_content[topic].elements.title.value,
              route: `${parentRoute}/${modular_content[topic].elements.url_slug.value}`
            });

            modular_content[topic].elements.subtopics.value.forEach((subTopic) => {
              const topicRoute = modular_content[topic].elements.url_slug.value;
              const subTopicRoute = modular_content[subTopic].elements.url_slug.value;

              if(modular_content[subTopic].system.type === 'awp__subtopic_vertical_landing_page'){
                this.routes.push({
                  codename: subTopic,
                  taxonomy: modular_content[subTopic].elements[this.toolTaxonomyCodename].value[0],
                  type: 'vertical-subtopic-landing',
                  subTopicType: 'landing',
                  parentVertical: page.system.codename,
                  parentTopic: topic,
                  route: `/tools/${subTopicRoute}`,
                  text: modular_content[subTopic].elements.title.value,
                  slug: subTopicRoute,
                  parentVerticalName: verticalRoute.text,
                });

                if(modular_content[subTopic].elements.associated_tool.value.length > 0){
                 let toolCodeName = modular_content[subTopic].elements.associated_tool.value[0];
                 let toolData = modular_content[toolCodeName];
                 let planCodeName = toolData.elements.associated_plan.value[0];
                 let planData = modular_content[planCodeName];
                 this.routes.push({
                  assessmentCode: toolData.elements.screening_code.value,
                  parentVertical: modular_content[subTopic].system.codename,
                  parentVerticalDescription: modular_content[subTopic].elements.description.value,
                  parentVerticalName: modular_content[subTopic].elements.title.value,
                  codename: planData.system.codename,
                  text: planData.elements.title.value,
                  route: `/tools/${subTopicRoute}/${planData.elements.url_slug.value}`,
                  type: 'vertical-subtopic-plan',
                 });
                }
              } else {
                this.routes.push({
                  codename: subTopic,
                  taxonomy: modular_content[subTopic].elements[this.toolTaxonomyCodename].value[0],
                  type: 'vertical-subtopic-learn',
                  subTopicType: 'learn',
                  parentVertical: page.system.codename,
                  parentTopic: topic,
                  route: `${parentRoute}/${topicRoute}/${subTopicRoute}/learn`,
                  text: modular_content[subTopic].elements.title.value,
                  slug: subTopicRoute,
                  parentVerticalName: verticalRoute.text,
                });
              }

              if (modular_content[subTopic].elements.tools_tab_title?.value) {
                this.routes.push({
                  codename: subTopic,
                  taxonomy: modular_content[subTopic].elements[this.toolTaxonomyCodename].value[0],
                  type: 'vertical-subtopic-tools',
                  subTopicType: 'tools',
                  parentVertical: page.system.codename,
                  parentTopic: topic,
                  route: `${parentRoute}/${topicRoute}/${subTopicRoute}/tools`,
                });
              }

              if (modular_content[subTopic].elements.experts_tab_title?.value) {
                this.routes.push({
                  codename: subTopic,
                  taxonomy: modular_content[subTopic].elements[this.toolTaxonomyCodename].value[0],
                  type: 'vertical-subtopic-experts',
                  subTopicType: 'experts',
                  parentVertical: page.system.codename,
                  parentTopic: topic,
                  route: `${parentRoute}/${topicRoute}/${subTopicRoute}/experts`,
                });
              }
            });

          });
        }
      });

      // If there's only a vertical page
      // remove all verticals and topics
      // and remodel subtopic route
      if (verticalListing.length === 1) {
        this.singularVerticalTopic = true;

        const discardedTypes = ['vertical', 'vertical-benefits', 'vertical-topic'];
        this.routes = this.routes.filter((route) => discardedTypes.indexOf(route.type) === -1);

        this.routes.forEach((route, index) => {
          if (route.type === 'vertical-subtopic-learn') {
            // console.log('debug', `${this.rootURL}/${route.slug}`);
            this.routes[index].route = `${this.rootURL}/${route.slug}`;
          }
        });
      }

      const split: string[] = window.location.pathname.split('/');
      let currentRoute = null;

      const baseRoute = !tools ? split[2] : split[1];
      switch (baseRoute) {
        case 'resource':
          currentRoute = {
            type: 'resource',
            route: `${this.rootURL}/resource`,
          };
          break;
        case 'pages':
          currentRoute = {
            type: 'pages',
            route: `${this.rootURL}/pages`,
          };
          break;
        case 'lead-gen': {
          currentRoute = {
            type: 'lead-gen',
            route: `${this.rootURL}/lead-gen`,
          };
          break;
        }
        case 'landing': {
          if(split[3] === 'medicare-enrollment-help-awp'){
            currentRoute = {
              type: 'medicare-awp',
              route: `${this.rootURL}/landing/medicare-enrollment-help-awp`,
            };
          } else if (split[3] === 'medicare-enrollment-help'){
            currentRoute = {
              type: 'medicare',
              route: `${this.rootURL}/landing/medicare-enrollment-help`,
            };
          } else if(split[3] === 'annuity-contact'){
            currentRoute = {
              type: 'annuity',
              route: `${this.rootURL}/landing/annuity-contact`,
            };
          }
          break;
        }
        default:
          let _route = window.location.pathname;
          if (_route[(_route.length - 1)] === '/') {
            _route = _route.substr(0, (_route.length - 1));
          }
          
          if(_route === '/tools/employment'){

            const found = this.routes.find((item) => (item.route === '/age-well-planner/job-skills-checkup' || item.route === '/age-well-planner/employment'));
            currentRoute = Object.assign({},found);
            currentRoute.savedRoute = found.route;;
            currentRoute.route = found.route;
            break;
          }
          if(_route === '/tools/budget-checkup'){
            
            const found = this.routes.find((item) => item.route === '/tools/budget-checkup');
            currentRoute = Object.assign({},found);
            currentRoute.savedRoute = found.route ?? '';
            currentRoute.route = found.route ?? '';
            break;
          }
          if(_route === '/tools/case-manager'){
            const found = this.routes.find((item) => item.route === '/age-well-planner/case-manager');
            currentRoute = Object.assign({},found);
            currentRoute.savedRoute = found.route ?? '';
            currentRoute.route = found.route ?? '';
            break;
          }
          if(_route === '/tools'){
            // go home
            window.location.href = '/';
          }
          currentRoute = this.routes.find((item) => item.route === _route || item?.savedRoute === _route);
          break;
      }
      
      if (!currentRoute) {
        return null;
      }

      const url = this.rootURL;
      const isActive = [
        `${this.rootURL}`,
        `${this.rootURL}/`,
        `${this.rootURL}/profile`,
        `${this.rootURL}/case-manager`,
        `${this.rootURL}/explore-benefits`,
        `${this.rootURL}/saved-contents`,
        `${this.rootURL}/landing/medicare-enrollment-help-awp`,
      ].concat(assessmentRoutes.map(({ route }) => route)).indexOf(window.location.pathname) >= 0;

      const staticMenu = [
        {
          url,
          text: config.elements.home_navigation_name.value ? config.elements.home_navigation_name.value : 'My Planner',
          isHome: true,
          isActive,
        },
      ];

      const verticalRoutes = this.routes.filter((route) => (route.type === 'vertical'));
      const dynamicMenu = [];
      if (verticalRoutes.length > 1) {
        verticalRoutes.forEach((route) => {
          dynamicMenu.push({
            url: route.route,
            text: route.text,
            isHome: false,
            isActive: window.location.pathname.split('/')[2] === route.route.split('/')[2],
          });
        });
      } else {
        // console.log('debug', this.routes);
        const subtopicRoutes = this.routes.filter((route) => (route.type === 'vertical-subtopic-learn'));
        subtopicRoutes.forEach((route) => {
          dynamicMenu.push({
            url: route.route,
            text: route.text,
            isHome: false,
            isActive: window.location.pathname === route.route,
          });
        });
      }

      const socials = [];

      // Check for footer associated with the experience
      const footerCode = config.elements.footer.value[0];

      // Get raw footer content
      const footerRaw = items.filter((item) => item.system.codename === config.elements.footer.value[0]);

      const footerData: {
        mainNavigation: any[];
        subNavigation: any[];
        socialNavigation: any[];
      } = {
        mainNavigation: [],
        subNavigation: [],
        socialNavigation: [],
      };

      if (footerRaw) {
        const footer = footerRaw[0];
        const keys = footer.elements.footer_social_links.value;
        footerData['logo'] = {
          src: footer.elements.logo.value[0].url,
          alt: footer.elements.logo.value[0].description,
        };

        footerData['socialHeader'] = footer.elements.section_title.value;
        footerData['socialNavigation'] = [];

        keys.forEach((key) => {
          if (modular_content[key]) {
            const type = modular_content[key].elements.social_account.value[0].codename;
            const url = modular_content[key].elements.manual_url.value;
            const text = modular_content[key].elements.link_text.value;

            footerData['socialNavigation'].push({
              url,
              label: text,
              iconClass: `social-links__item social-links--${type}`,
            });

            socials.push({
              type,
              url,
              text,
            });
          }
        });

        footerData['copyright'] = footer.elements.copyright_information.value;

        footerData['mainNavigation'] = footer.elements.footer_primary_links.value.map((key) => {
          const linkItem = modular_content[key];
          const { value: label } = linkItem.elements.link_text;
          let { value: url } = linkItem.elements.manual_url;

          if (linkItem.elements.dynamic_url.value.length > 0) {
            const linkKey = linkItem.elements.dynamic_url.value[0];
            const content = modular_content[linkKey];
            if (content) {
              const { system, elements } = content;
              if (system.type === 'awa_benefits_tool_template___standard_page') {
                url = `${this.rootURL}/pages/${elements.url.value}`;
              } else if (system.type === 'ncoa_article_content') {
                url = `${this.rootURL}/resource/${elements.url.value}`;
              } else if (system.type === 'awa_benefits_tool___vertical') {
                url = (this.routes.find((route) => (
                  route.type === 'vertical' && route.codename === system.codename
                )) || { route: this.rootURL }).route;
              } else if (system.type === 'awa_benefits_tool___vertical_topic') {
                url = (this.routes.find((route) => (
                  route.type === 'vertical-topic' && route.codename === system.codename
                )) || { route: this.rootURL }).route;
              } else if (system.type === 'awa_benefits_tool___vertical_subtopic') {
                url = (this.routes.find((route) => (
                  route.type === 'vertical-subtopic-learn' && route.codename === system.codename
                )) || { route: this.rootURL }).route;
              }
            }
          }

          return {
            label,
            url,
          };
        });

        footerData['subNavigation'] = footer.elements.footer_secondary_links.value.map((key) => {
          const linkItem = modular_content[key];
          const { value: label } = linkItem.elements.link_text;

          let { value: url } = linkItem.elements.manual_url;

          if (linkItem.elements.dynamic_url.value.length > 0) {
            const linkKey = linkItem.elements.dynamic_url.value[0];
            const content = modular_content[linkKey];

            if (content) {
              const { system, elements } = content;

              if (system.type === 'awa_benefits_tool_template___standard_page') {
                url = `${this.rootURL}/pages/${elements.url.value}`;
              } else if (system.type === 'ncoa_article_content') {
                url = `${this.rootURL}/resource/${elements.url.value}`;
              } else if (system.type === 'awa_benefits_tool___vertical') {
                url = (this.routes.find((route) => (
                  route.type === 'vertical' && route.codename === system.codename
                )) || { route: this.rootURL }).route;
              } else if (system.type === 'awa_benefits_tool___vertical_topic') {
                url = (this.routes.find((route) => (
                  route.type === 'vertical-topic' && route.codename === system.codename
                )) || { route: this.rootURL }).route;
              } else if (system.type === 'awa_benefits_tool___vertical_subtopic') {
                url = (this.routes.find((route) => (
                  route.type === 'vertical-subtopic-learn' && route.codename === system.codename
                )) || { route: this.rootURL }).route;
              }
            }
          }

          return {
            label,
            url,
          };
        });
      }
      let homePageHeroCTA;
      if (config.elements.hero_cta_link.value.length > 0) {
        homePageHeroCTA = {
          label: config.elements.hero_cta_text.value,
          url: null,
        };
        const linkedContent = modular_content[config.elements.hero_cta_link.value[0]];

        switch (linkedContent.system.type) {
          case 'ncoa_article_content': {
            homePageHeroCTA.url = `${this.rootURL}/resource/${linkedContent.elements.url.value}`;
            break;
          }
          case 'awa_benefits_tool_template___standard_page': {
            homePageHeroCTA.url = `${this.rootURL}/pages/${linkedContent.elements.url.value}`;
            break;
          }
          case 'awa_benefits_tool___tool': {
            const assessmentRoute = assessmentRoutes.find((route) => (route.assessmentID === linkedContent.elements.assessment_id.value));
            if (assessmentRoute) {
              homePageHeroCTA.url = assessmentRoute.route;
            } else {
              homePageHeroCTA = null;
            }

            break;
          }
          case 'awa_benefits_tool___vertical': {
            const verticalRoute = this.routes.find((route) => (
              route.type === 'vertical' &&
              route.codename === linkedContent.system.codename
            ));

            if (verticalRoute) {
              homePageHeroCTA.url = verticalRoute.route;
            } else {
              homePageHeroCTA = null;
            }

            break;
          }
          case 'awa_benefits_tool___vertical_topic': {
            homePageHeroCTA.url = (this.routes.find((route) => (
              route.type === 'vertical-topic' &&
              route.codename === linkedContent.system.codename
            )) || { route: null }).route;
            break;
          }
          case 'awa_benefits_tool___vertical_subtopic': {
            homePageHeroCTA.url = (this.routes.find((route) => (
              route.type === 'vertical-subtopic-learn' &&
              route.codename === linkedContent.system.codename
            )) || { route: null }).route;
            break;
          }
        }
      }

      const primaryAssessment = assessmentRoutes.find((assessment) => assessment.isPrimary);

      let aboutUsURL;
      if (config.elements.about_us_page.value.length > 0) {
        const aboutUs = modular_content[config.elements.about_us_page.value[0]];
        const aboutUsSlug = aboutUs.elements.url.value;
        aboutUsURL = `${this.rootURL}/pages/${aboutUsSlug}`;
      }

      let secondary_logo = null;

      if(config.elements.secondary_logo.value.length > 0) {
        secondary_logo = {
          cta: {
            url: `${this.rootURL}`,
            text: config.elements.tool_title.value,
          },
          src: config.elements.secondary_logo.value[0].url,
          alt: config.elements.secondary_logo.value[0].description || config.elements.tool_title.value,
        }
      }

      let eabCTA;
      if (config.elements.eab_assessment_link.value.length > 0) {
        const eabHeroCTAKey = config.elements.eab_assessment_link.value[0];
        const eabHeroLinkedContent = modular_content[eabHeroCTAKey];

        if (eabHeroLinkedContent) {
          const url = `${this.rootURL}/assessment/${this.globalService.makeAKebab(eabHeroLinkedContent.elements.title.value)}`;
          eabCTA = {
            url,
            text: config.elements.eab_assessment_link_text.value,
          };
        }
      } 

      this.currentConfig = {
        core: {
          route: currentRoute,
          primaryAssessment: {
            url: primaryAssessment.route,
            eyebrow: config.elements.primary_assessment_eyebrow.value,
            text: config.elements.primary_assessment_link_text.value,
            assessmentID: primaryAssessment.assessmentID,
          },
          assessmentData: newAssessmentPages,
          verticalData: verticalPages,
          todoList: todoList,
          verticalTopicData: topicPages,
          educationalCards,
          partnerGrids,
          assessmentFAQPages
        },
        navData: {
          bannerCTA: !this.singularVerticalTopic ? {
            prefix: config.elements.prefix.value,
            url: config.elements.banner_cta_url.value,
            text: config.elements.banner_cta_text.value,
          } : null,
          mainLogo: {
            cta: {
              url: `${this.rootURL}`,
              text: config.elements.tool_title.value,
            },
            src: config.elements.logo.value[0].url,
            alt: config.elements.logo.value[0].description || config.elements.tool_title.value,
          },
          secondaryLogo: secondary_logo,
          signUpCTA: {
            url: this.routes.find((item) => item.type === 'sign-up').route,
            text: 'Create Account',
          },
          signInCTA: {
            url: this.routes.find((item) => item.type === 'sign-in').route,
            text: 'Log In',
          },
          savedCTA: {
            url: `${this.rootURL}/saved-contents`,
            text: 'Saved',
          },
          profileCTA: {
            url: `${this.rootURL}/profile`,
            text: 'Profile',
          },
          aboutUsCTA:{
            url: aboutUsURL,
            text: 'About NCOA',
          },
          socials,
          menus: staticMenu.concat(dynamicMenu),
          headerMenus: []
        },
        footerData,
        heroData: {
          background: {
            responsive: this.globalService.remodelImageSrc(config.elements.hero_image.value[0].url),
            url: config.elements.hero_image.value[0].url,
            alt: config.elements.hero_image.value[0].description,
          },
          logo: {
            url: config.elements.primary_logo_hero.value[0].url,
            alt: config.elements.primary_logo_hero.value[0].description,
          },
          headline: config.elements.hero_title.value,
          intro: config.elements.hero_description.value,
          cta: homePageHeroCTA.url ? homePageHeroCTA : null,
        },
        eabHeroData: {
          title: config.elements.eab_hero_title.value,
          desc: config.elements.eab_hero_desc.value,
          eyebrow: config.elements.eab_assessment_eyebrow.value,
          cta: eabCTA,
          backgroundImage: {
            sizes: this.globalService.remodelImageSrc(config.elements.eab_hero_bg.value[0].url),
            src: config.elements.eab_hero_bg.value[0].url,
            alt: config.elements.eab_hero_bg.value[0].description,
          },
        },
      };

      // seo metadata
      const SEOPayload = {
        metaTitle: config.elements.seo_metadata_example_to_include_in_any_type__meta_title.value,
        metaDesc: config.elements.seo_metadata_example_to_include_in_any_type__meta_description.value,
        ogSiteName: config.elements.seo_metadata_example_to_include_in_any_type__facebook_site_name.value,
        ogTitle: config.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value,
        ogDesc: config.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value,
        ogImage: config.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value.length > 0
          ? config.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value[0].url
          : null,
        twitterSiteName: config.elements.seo_metadata_example_to_include_in_any_type__twitter_site_name.value,
        twitterTitle: config.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value,
        twitterDesc: config.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value,
        twitterImage: config.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value.length > 0
          ? config.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value[0].url
          : null,
        pageType: 'page',
        fallbackImage: config.elements.hero_image.value.length > 0
          ? config.elements.hero_image.value[0].url
          : null,
        fallbackTitle: config.elements.tool_title.value,
      };
      this.globalService.saveCurrentURL(window.location.pathname);
      this.seoService.buildToolsSeoData(SEOPayload);
      return this.currentConfig;
    });
  }

  searchTree(tree, codename) {
    if (tree.codename === codename) {
      return tree;
    } else if (tree.terms != null) {
      let i = null;
      let res = null;

      for (i = 0; res === null && i < tree.terms.length; i++) {
        res = this.searchTree(tree.terms[i], codename);
      }

      return res;
    }
    return null;
  }

  getVertical(codename) {
    return this.awpTaxonomy.find((taxonomy) => this.searchTree(taxonomy, codename));
  }

  updateActiveNav(identifier, codename = null) {
    if (identifier === 'taxonomy-codename') {
      const targetRoute = this.routes.filter((route) => route.taxonomy)
        .find((route) => route.taxonomy.codename === codename)?.route;

      const newMenu = this.currentConfig.navData.menus;
      const targetIndex = newMenu.findIndex((menu) => menu.url === targetRoute);

      newMenu.forEach((_, index) => newMenu[index].isActive = false);
      newMenu[targetIndex].isActive = true;

      this.currentConfig.navData = {
        ...this.currentConfig.navData,
        menus: newMenu,
      };
    } else if (identifier === 'home') {
      const newMenu = this.currentConfig.navData.menus;
      const targetIndex = 0;

      newMenu.forEach((_, index) => newMenu[index].isActive = false);
      newMenu[targetIndex].isActive = true;

      this.currentConfig.navData = {
        ...this.currentConfig.navData,
        menus: newMenu,
      };
    }
  }
}
