import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormsService } from '@services/forms.service';

@Component({
  selector: 'ncoa-external-form',
  templateUrl: './external-form.component.html',
  styleUrls: ['./external-form.component.css']
})
export class ExternalFormComponent implements OnInit, AfterViewInit {
  embedCode = '';
  constructor(
    private sanitizer: DomSanitizer,
    private formsService: FormsService
    ) { }
  @Input() component: any;

  ngOnInit(): void {
    const html = htmlDecode(this.component.embedCode.value)
    this.embedCode= this.sanitizer.bypassSecurityTrustHtml(sanitize(html))  as string;
  }
  ngAfterViewInit() {
    this.formsService.processForms();
  }
}

function htmlDecode(str) {
  var doc = new DOMParser().parseFromString(str, "text/html");
  return doc.documentElement.textContent;
}
 function sanitize(content:string) {
    let cleanContent = content;
    while (cleanContent.includes('<script ')) {
      const start = cleanContent.indexOf('<script');
      const end = cleanContent.indexOf('</script>') + 9;
      const st = cleanContent.substring(start, end);
      cleanContent = cleanContent.replace(st, '');
    }
    return cleanContent;
  }