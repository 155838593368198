import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ncoa-template-minimal',
  templateUrl: './minimal.component.html',
  styleUrls: ['./minimal.component.scss']
})
export class MinimalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
