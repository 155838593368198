import { AsyncValidator, AsyncValidatorFn, FormControl, Validator, ValidatorFn, Validators } from "@angular/forms";
import { Definition, FullCondition } from "../Screening";

export abstract class AbstractQuestion<V>{
    public value: V | undefined;
    public key: string;
    public label: string;
    public required: boolean;
    public readonly: boolean;
    public hint?: string;
    public disabled?: boolean;
    public defaultAnswer: V;
    public answerType: string;
    public validators:  ValidatorFn[];
    public definitions: Definition;
    public fullCondition: FullCondition;
    public asyncValidators: AsyncValidatorFn[];
    private control: FormControl;
    public options: {[key: string]: V}[]

    constructor(value?: V, key?: string, label?: string, required?: boolean, hint?: string, defaultAnswer?, answerType?: string, validators?:  ValidatorFn[], asyncValidators?: AsyncValidatorFn[],  readonly?: boolean, disabled?: boolean){
        this.value = value;
        this.key = key ?? '';
        this.label = label ?? '';
        this.hint = hint ?? '';
        this.defaultAnswer = defaultAnswer;
        this.answerType = answerType ?? '';
        this.validators = validators ?? [];
        this.asyncValidators = asyncValidators ?? [];
        this.required = required ?? false;
        this.readonly = readonly ?? false;
        this.disabled = disabled ?? false;
        if(this.required){
            this.validators.push(Validators.required)
        }


    }

    public asFormControl(){
        return new FormControl({value: this.defaultAnswer, disabled: this.disabled}, {validators: this.validators, asyncValidators: this.asyncValidators, updateOn: 'change'});
    }

}

