import { Component, OnInit, Input } from '@angular/core';
import { logo, buttonLink, links, socialLinks, policyLinks } from './global-footer.types';

import { GlobalService } from '@services/global.service';

@Component({
  selector: 'ncoa-global-footer',
  templateUrl: './global-footer.component.html',
  styleUrls: ['./global-footer.component.scss']
})
export class GlobalFooterComponent implements OnInit {
  @Input() logo: logo;
  @Input() buttonLink: buttonLink;
  @Input() links: links[];
  @Input() socialTitle: string;
  @Input() socialLinks: socialLinks[];
  @Input() policyLinks: policyLinks[];
  copyright: string;

  constructor(private globalService: GlobalService) {  }

  ngOnInit(): void {
    const footerData = this.globalService.footerData;

    this.logo = footerData.logo;
    this.buttonLink = footerData.buttonLink;
    this.links = footerData.links;
    this.socialTitle = footerData.socialTitle;
    this.socialLinks = footerData.socialLinks;
    this.policyLinks = footerData.policyLinks;
    this.copyright = footerData.copyright;
  }
  getLinkCssClass(text: string): string {
    return 'footer-link-' +text.toLowerCase().replace(/ /g, '-');
  }
}
