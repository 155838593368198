import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataLayerService } from '@services/data-layer.service';
import { GlobalService } from '@services/global.service';
import { CaseManagerService, ClientInfo } from '@services/planner/case-manager.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { ProfileService } from '@services/planner/profile.service';
import { UserService } from '@services/planner/user.service';
import { title } from 'process';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-case-manager',
  templateUrl: './case-manager.component.html',
  styleUrls: ['./case-manager.component.scss']
})
export class CaseManagerComponent implements OnInit {

  templateView = 'home'

  clientList: any
  selectedClient: ClientInfo = null;
  data: any;
  logins: any;
  loginCount = 0;
  budgetViewedCount = 0
  budgetEditedCount = 0
  planResetCount = 0
  budgetViewed: any;
  budgetEdited: any;
  planReset: any;
  constructor(
    private profileService: ProfileService,
    private userService: UserService,
    private dataLayerService: DataLayerService,
    private route: ActivatedRoute,
    private caseManagerService: CaseManagerService,
    private router: Router,
    private cas: CustomerActivityService,
    private global: GlobalService,
  ) { }

  async ngOnInit() {
    const isLoggedIn = await this.userService.isLoggedIn().pipe(take(1)).toPromise();
    if (isLoggedIn) {
      try {
        const [data, clients] = await Promise.all([this.profileService.getData(), this.caseManagerService.getClients(this.userService.CADId)]);
        this.data = data;
        this.clientList = clients
        this.global.loadFooter(true);
      } catch (error) {
        console.error(error);
        window.location.href = '/404';
      }
    } else {
      window.location.href = '/404';
    }
  }

  viewBudgetCheckUp(client: ClientInfo) {
    this.selectedClient = client;
    this.templateView = 'budgetCheckUp';
  };

  async viewRecentActivity(client) {
    this.selectedClient = client;
    this.resetCount();
    const history = (await this.cas.getClientHistory(client.pk))[0]
    if (history.login) this.loginCount = history.login.length;
    if (history.analyzeBudgetViewed) this.budgetViewedCount = history.analyzeBudgetViewed.length;
    if (history.analyzeBudgetEdited) this.budgetEditedCount = history.analyzeBudgetEdited.length;
    if (history.planReset) this.planResetCount = history.planReset.length;
    this.logins = history?.login?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()) ?? [{ timestamp: 'User has never logged in' }]
    this.budgetViewed = history?.analyzeBudgetViewed?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()) ?? [{ timestamp: 'Never viewed budget' }];
    this.budgetEdited = history?.analyzeBudgetEdited?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()) ?? [{ timestamp: 'Never edited budget' }];
    this.planReset = history?.planReset?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()) ?? [{ timestamp: 'User never reset plan' }];
    this.templateView = 'recentActivity';
  }

  resetCount(){
    this.loginCount = 0;
    this.budgetEditedCount = 0;
    this.budgetViewedCount = 0;
    this.planResetCount = 0;
  }

  viewHome() {
    this.selectedClient = null;
    this.templateView = 'home';
    this.router.navigate([], {
      queryParams: {}
    });
  }

  formatTimestamp(timestamp: string | number): string {
    // Check if a timestamp is valid
    const date = new Date(timestamp);
    if (date.toLocaleString() === "Invalid Date") {
      return String(timestamp);
    }
  
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'shortGeneric'
    };
  
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  getDisplayName(client) {
    let displayName = [];
    if (client.firstName) {
      displayName.push(client.firstName);
    }
    if (client.lastName) {
      displayName.push(client.lastName);
    }
    return displayName.join(' ');

  }

  getTitleString(client) {
    let titleString = [];
    if (client.firstName) {
      titleString.push(client.firstName);
    }
    if (client.lastName) {
      titleString.push(client.lastName);
    }
    if (titleString.length === 0){
      return client.userAccountEmail
    }
    return titleString.join(' ') + ' - ' + client.userAccountEmail;
  }



}
