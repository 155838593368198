<div class="component component--partners" *ngIf="partners.length > 0">
  <div class="wrapper">
    <h2 class="partner--heading">{{ heading }}</h2>
    <ul class="partner">
      <li class="partner--logo" *ngFor="let partner of partners">
        <div class="partner--logo__thumb">
          <span>
            <img [src]="partner.logo" [alt]="partner?.alt" />
          </span>
        </div>
      </li>
    </ul>
  </div>
</div>
