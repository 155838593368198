<div class="todo-page-content" *ngIf="content && todo">

  <div class="page-top-controls">
    <a class="back-btn" (click)="backToList()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M7.26498 3.235C7.3352 3.30531 7.37465 3.40062 7.37465 3.5C7.37465 3.59938 7.3352 3.69469 7.26498 3.765L3.4056 7.625L13.5 7.625C13.5994 7.625 13.6948 7.66451 13.7651 7.73484C13.8355 7.80516 13.875 7.90054 13.875 8C13.875 8.09946 13.8355 8.19484 13.7651 8.26517C13.6948 8.33549 13.5994 8.375 13.5 8.375H3.4056L7.26498 12.235C7.33121 12.3061 7.36728 12.4001 7.36556 12.4973C7.36385 12.5944 7.32449 12.6871 7.25579 12.7558C7.18708 12.8245 7.09439 12.8639 6.99724 12.8656C6.90009 12.8673 6.80606 12.8312 6.73498 12.765L2.23498 8.265C2.16475 8.19469 2.12531 8.09938 2.12531 8C2.12531 7.90063 2.16475 7.80531 2.23498 7.735L6.73498 3.235C6.80529 3.16477 6.9006 3.12533 6.99998 3.12533C7.09935 3.12533 7.19466 3.16477 7.26498 3.235Z"
          fill="#0B4A5D" />
      </svg>
      <span>Back to List</span>
    </a>
  </div>


  <section class="page-header">

    <h1>{{ todo.title }}</h1>

    <section class="header-controls">

      <!-- <button
      class="todo-button-complete"
      (click)="onCompleteClick()"
      [ngClass]="{
        complete_todo_page:
          !todo.completed,
          incomplete_todo_page: todo.completed
      }"
    >
      {{
        !todo.completed
          ? content.mark_complete_cta
          : content.mark_incomplete_cta
      }}
      </button>

      <button
        class="todo-button archive-btn"
        (click)="onArchiveClick()"
      >
        {{
          !todo.archived
            ? content.archive_cta
            : content.unarchive_cta
        }}
      </button> -->

    </section>


  </section>



  <section class="page-content" *ngIf="!hideContent" #pageContent>
    <div class="page-content__wrapper">
      <div class="page-content__inner_wrapper" [innerHTML]="todo.content"></div>
    </div>
  </section>



  <section class="page-additional-content">

    <ng-container [ngSwitch]="todo.slug">

      <app-client-analyze-your-budget [client]="client" (hideContent)="onHideContent($event)"
        *ngSwitchCase="'analyze-your-budget'"></app-client-analyze-your-budget>

    </ng-container>


  </section>



  <section class="footer" *ngIf="showFooter">

    <div class="footer-controls">



    </div>


  </section>
  <div class="page-top-controls">
    <a class="back-btn" (click)="backToList()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M7.26498 3.235C7.3352 3.30531 7.37465 3.40062 7.37465 3.5C7.37465 3.59938 7.3352 3.69469 7.26498 3.765L3.4056 7.625L13.5 7.625C13.5994 7.625 13.6948 7.66451 13.7651 7.73484C13.8355 7.80516 13.875 7.90054 13.875 8C13.875 8.09946 13.8355 8.19484 13.7651 8.26517C13.6948 8.33549 13.5994 8.375 13.5 8.375H3.4056L7.26498 12.235C7.33121 12.3061 7.36728 12.4001 7.36556 12.4973C7.36385 12.5944 7.32449 12.6871 7.25579 12.7558C7.18708 12.8245 7.09439 12.8639 6.99724 12.8656C6.90009 12.8673 6.80606 12.8312 6.73498 12.765L2.23498 8.265C2.16475 8.19469 2.12531 8.09938 2.12531 8C2.12531 7.90063 2.16475 7.80531 2.23498 7.735L6.73498 3.235C6.80529 3.16477 6.9006 3.12533 6.99998 3.12533C7.09935 3.12533 7.19466 3.16477 7.26498 3.235Z"
          fill="#0B4A5D" />
      </svg>
      <span>Back to List</span>
    </a>
  </div>

</div>